import { AnyAction } from 'redux';
import newFileSystemActionTypes from './actions';

// INITIAL STATE
const initialState: FileSystemStates = {
    loadedFileSystems: [],
    loadedFileSystem: undefined,
};

// REDUCER

const fileSystemReducer = (state = { ...initialState }, action: AnyAction) => {
    if (action.type === newFileSystemActionTypes.LOAD_FILESYSTEM_LIST) {
        return { ...state, loadedFileSystems: action.payload };
    }
    if (action.type === newFileSystemActionTypes.LOAD_FILESYSTEM) {
        return { ...state, loadedFileSystem: action.payload };
    }

    return state;
};

export default fileSystemReducer;
