import * as React from 'react';
import { InsertDriveFile } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import ImageDisplayWithDialog from '../ImageDisplayWithDialog';
import PdfDisplayWithDialog from '../PdfDisplayWithDialog';

interface IImageFieldOptions {
    type: string;
    fileSource: string;
    responseData?: any;
    pwaLogo?:boolean;
    sxcss?:any;
}

export const UploadRenderView = ({ type, fileSource, responseData, pwaLogo, sxcss }: IImageFieldOptions) => {
    switch (type) {
        case 'image':
            return <ImageDisplayWithDialog imageSrc={fileSource} imageData={responseData} pwaLogo={pwaLogo} />;

        case 'video':
            return (
                <video data-testid={responseData?.originalName} height='100%' controls>
                    <source src={fileSource} type='video/mp4' />
                </video>
            );

        case 'pdf':
            return <PdfDisplayWithDialog fileName={responseData?.originalName} link={fileSource} sxcss={sxcss}/>;

        case 'other':
            return (
                <Paper
                    sx={{
                        height: '100%',
                        width: sxcss && sxcss.width ? sxcss.width : '300px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <InsertDriveFile />
                    <Typography>{responseData?.originalName}</Typography>
                </Paper>
            );

        default:
            return null;
    }
};
