import API from 'reactelements/src/config/api';
import { getLocationCoordinates } from '../../../../reactelements/src/hooks/useFileUpload/fileHelper';
import { createRingDetailsAction } from '../../../Models/NewCRM/actions';
import moment from 'moment';
import ObjectID from 'bson-objectid';
import { CRMData } from 'reactelements/src/modules/ViewEditCrm/types';
import { generatePOS } from '../LeadDetailPage';

export const createRingDetails = async (crmId: string, projectId: string, isOffline?: boolean) => {
    const userLocation = await getLocationCoordinates();
    if (userLocation.latitude === 0 && userLocation.longitude === 0) throw new Error('location not enabled');
    if (isOffline) {
        return createRingDetailsAction(crmId, projectId, {
            lon: userLocation?.longitude,
            lat: userLocation?.latitude,
        });
    } else {
        const res = await API.post(`/crm/ring?crmId=${crmId}&projectId=${projectId}`, {
            lon: userLocation?.longitude,
            lat: userLocation?.latitude,
        });
        return await res.json();
    }
};

export const offlineCRMMockEvent = (crmData: CRMData, projectId: string) => ({
    fraudDetection: {
        images: [],
    },
    tmp_invoice_info: {
        bonus: {
            checkin: false,
            report: false,
            customReportingFields: [],
        },
        checkOutAmount: {
            gross: 0,
        },
        invoiceManualChanges: {
            checkin: false,
            report: false,
        },
        deliveryCost: {
            amount: 0,
            invoiced: false,
        },
        discount: {
            gross: 0,
        },
        revenue: {
            withAddedTax: 0,
            withoutAddedTax: 0,
        },
        checkinBonus: 0,
        reportBonus: 0,
        genericBonusAmount: 0,
        genericBonusExplained: [],
        fixum: 0,
        fixumExplained: [],
        base: 0,
        total: 0,
        expenseTotal: 0,
        productsProvisionTotal: 0,
    },
    exportDetails: {
        processed: false,
    },
    creditNoteInfo: {
        creditNoteNumber: '',
    },
    meta: {
        pricing: {
            isCustomVatLocked: false,
        },
        promoterChangeDate: false,
        promoterCanDeleteEvent: false,
        hasDeliveryInfo: false,
    },
    crossBorderRules: {
        provisionReceipient: {
            vatRate: 0,
        },
        isCrossBorderActive: false,
        useNewCrossBorderLogic: false,
        vatRate: 0,
        priceVerified: false,
        B2BEnabled: false,
    },
    inventoryConfig: {
        sourceType: 'event_user',
    },
    eventState: 'LoggedIn',
    eventContract: [],
    contractInfo: [],
    eventType: 'Event',
    sales_report_checked_started: false,
    sales_report_checked_done: false,
    documents: [],
    invoice_status: 'ReadyToInvoice',
    briefing: '',
    customStatuses: [],
    rating: 0,
    ratingText: '',
    paymentStatus: 'Pending',
    adyenPaymentStatus: 'NotInitiated',
    sellout_id: '',
    _id: new ObjectID().str,
    agency_user: [
        {
            payment: {
                fixum: {
                    amount: 0,
                    per: 'event',
                    perHourHours: 0,
                },
                boost: {
                    amount: 0,
                    per: 'event',
                    apply: 'never',
                },
                eventExpenses: {
                    amount: 0,
                    per: 'event',
                },
                personalExpenses: {
                    amount: 0,
                    per: 'event',
                },
                projectExpenses: {
                    amount: 0,
                    per: 'event',
                },
                provision: 'no',
            },
            paymentType: 'project',
            _id: new ObjectID().str,
            user_id: window?.userabstraction?._id,
        },
    ],
    eventGroupId: new ObjectID().str,
    date: moment().toISOString(),
    eventStateHistory: [
        {
            meta: {
                eventDate: moment().toISOString(),
                groupDate: moment().toISOString(),
            },
            _id: new ObjectID().str,
            stateNew: 'Accepted',
            stateOld: '',
            historyType: 'eventState',
            date: moment().toISOString(),
            ip: '0.0.0.0',
            user: window?.userabstraction?._id,
        },
        {
            meta: {
                eventDate: moment().toISOString(),
                groupDate: moment().toISOString(),
            },
            _id: new ObjectID().str,
            stateNew: 'LoggedIn',
            stateOld: 'Accepted',
            historyType: 'eventState',
            date: moment().toISOString(),
            ip: '0.0.0.0',
            user: window?.userabstraction?._id,
        },
    ],
    point_of_sale: {
        _id: new ObjectID().str,
        ...generatePOS(crmData),
    },
    times: [],
    project_id: projectId,
    budgetEventType: '123456781234567812345678',
    created: moment().toISOString(),
    note: [],
    free_bonus_positions: [],
    files: [],
    __v: 0,
});