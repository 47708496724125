import React, { ReactNode } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { CircularProgress } from '@mui/material';
import { mapkey } from '../../conf/constants';
// import { isLatLngLiteral } from '@googlemaps/typescript-guards';

const Marker: React.FC<google.maps.MarkerOptions> = options => {
    const [marker, setMarker] = React.useState<google.maps.Marker>();

    React.useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker());
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    React.useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);

    return null;
};

interface MyMapComponentProps {
    lat: number;
    lng: number;
    children: ReactNode;
}

function MyMapComponent({ lat, lng, children }: MyMapComponentProps) {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();

    React.useEffect(() => {
        if (ref.current && !map) {
            setMap(
                new window.google.maps.Map(ref.current, {
                    center: { lat: lat, lng: lng },
                    zoom: 4,
                }),
            );
        }
    }, [ref, map]);

    // check the height conditions here?
    return (
        <>
            <div style={{ height: '100%', minHeight: '30vh' }} ref={ref} />
            {React.Children.map(children, child => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    const props = { map } as Partial<{ map: any }>;
                    return React.cloneElement(child, props);
                }
            })}
        </>
    );
}

const render = (status: Status) => {
    if (status === Status.FAILURE) return <>Failed to load the mao</>;
    return <CircularProgress />;
};

interface GoogleMapProps {
    lat: number;
    lng: number;
}

const GoogleMap = ({ lat, lng }: GoogleMapProps) => (
    <Wrapper apiKey={mapkey} render={render}>
        <MyMapComponent lat={lat} lng={lng}>
            <Marker position={{ lat: lat, lng: lng }} />
        </MyMapComponent>
    </Wrapper>
);

export default GoogleMap;
