import { newCustomReportDb } from './newCustomReportDexie';
import { getCustomReportsFetch, syncAllCustomReports } from './helpers';
import { Collection, IndexableType, Table } from 'dexie';
import { isDeviceOnline } from '../../Services/OnlineStatus';
import { createNewProjectWrapperForDexie } from '../NewProject/helpers';

const newCustomReportActionTypes = {
    NEW_CUSTOMREPORT: 'NEW_CUSTOMREPORT',
    LOAD_CUSTOMREPORT_LIST: 'LOAD_CUSTOMREPORT_LIST',
    LOAD_CUSTOMREPORT: 'LOAD_CUSTOMREPORT',
    UPDATE_CUSTOMREPORT_SYNC_DATA: 'UPDATE_CUSTOMREPORT_SYNC_DATA',
    CLEAR_LOAD_CUSTOMREPORT_LIST: 'CLEAR_LOAD_CUSTOMREPORT_LIST',
};

export default newCustomReportActionTypes;

export const createNewCustomReportAction = (customReport: DexieCustomReport) => {
    newCustomReportDb.customReports.add(customReport);
    return {
        type: newCustomReportActionTypes.NEW_CUSTOMREPORT,
        payload: customReport,
    };
};

export const loadCustomReportAction = async (dispatch: any, customReportId: string | null) => {
    if (customReportId !== null && customReportId) {
        const customReports = await newCustomReportDb.customReports.get(customReportId);
        return dispatch({
            type: newCustomReportActionTypes.LOAD_CUSTOMREPORT,
            payload: customReports,
        });
    } else {
        return dispatch({
            type: newCustomReportActionTypes.LOAD_CUSTOMREPORT,
            payload: undefined,
        });
    }
};

export const loadCustomReportListAction = (filterOptions: { name?: string; projectId?: string }) => async (
    dispatch: any,
) => {
    type DexieCustomReportQueryBuilder =
        | Collection<DexieCustomReport, IndexableType>
        | Table<DexieCustomReport, IndexableType>;
    let customReportQuery: DexieCustomReportQueryBuilder = newCustomReportDb.customReports;

    if (filterOptions?.name !== undefined) {
        const nameRegex = new RegExp('.*' + filterOptions.name + '.*', 'i');
        customReportQuery = customReportQuery.filter(cr => {
            // use between instead of filter to use compound index
            return nameRegex.test(cr.customReport.name);
        });
    }
    if (filterOptions?.projectId) {
        customReportQuery = customReportQuery.filter(
            (cusr: DexieCustomReport) => cusr?.customReport?.useinproject?.indexOf(filterOptions?.projectId) !== -1,
        );
    }

    const customReportsRes = await customReportQuery.toArray();

    if (customReportsRes.length > 0) {
        return dispatch({
            type: newCustomReportActionTypes.LOAD_CUSTOMREPORT_LIST,
            payload: await customReportQuery.toArray(),
        });
    }

    if (isDeviceOnline() && filterOptions?.projectId) {
        // we load from server
        console.log('Loading this customReports from the API');
        const customReportResponse = await getCustomReportsFetch(filterOptions.projectId, 0).catch(console.error);

        if (
            customReportResponse &&
            customReportResponse.success &&
            customReportResponse.count &&
            Array.isArray(customReportResponse.data) &&
            customReportResponse.count > 0
        ) {
            const customReports = customReportResponse.data.map((cRep: any) => createNewProjectWrapperForDexie(cRep));
            console.log('Dispatching customReports loaded from the API');
            return dispatch({
                type: newCustomReportActionTypes.LOAD_CUSTOMREPORT_LIST,
                payload: customReports,
            });
        }
    }

    return dispatch({
        type: newCustomReportActionTypes.LOAD_CUSTOMREPORT_LIST,
        payload: await customReportQuery.toArray(),
    });
};

export const clearLoadCustomReportListAction = () => {
    return {
        type: newCustomReportActionTypes.CLEAR_LOAD_CUSTOMREPORT_LIST,
    };
};

export const startCustomReportSyncAction = (projectIds: string) => (dispatch: any) => {
    dispatch({
        type: newCustomReportActionTypes.UPDATE_CUSTOMREPORT_SYNC_DATA,
        payload: {
            state: 'DOWNLOADING',
            lastUpdate: new Date(),
        },
    });
    syncAllCustomReports(dispatch, projectIds);
};
