import React, { useContext, useEffect, useState } from 'react';
import { Box, FormLabel, TextField } from '@mui/material';
import { ReportConfigurationContext } from '..';
import ToolTipComponent from '../ToolTipComponent';
import { t } from 'i18next';
import { requireHelper } from '../utils/requireHelper';
import { useTranslation } from 'react-i18next';
import { AnyAaaaRecord } from 'dns';
import API from '../../../config/api';
import LaunchIcon from '@mui/icons-material/Launch';
import { makeStyles } from '@mui/styles';

interface StringSmallFieldProps {
    field: CustomReportField;
    reportData: ReportData;
    type: React.HTMLInputTypeAttribute | undefined;
    onChangeHandler: any;
    eventIdObj: any;
}

// const useStyles = makeStyles({
//     greenlink: {
//         color: 'rgb(36,192,177)!important',
//         textDecoration: 'none',
//         fontWeight: 'bold',
//         cursor: 'pointer',
//         background: 'transparent',
//         border: 'none',
//         display: 'flex',
//         placeContent: 'center',
//         placeItems: 'center',
//         gap: '0.5rem',
//         // width: '100%',
//         height: '100%',
//     },
// });

function UrlFieldComponent({ eventIdObj, field, type, reportData, onChangeHandler }: StringSmallFieldProps) {
    const { t } = useTranslation();
    const [link, setLink] = useState('');
    let isTextArea = type === 'textarea';
    //console.log({ type, isTextArea });
    //console.log("field",eventIdObj);

    useEffect(() => {
        // Your code here
        let congigId = field.config._id;
        if (field.config.fieldObject?.url) {
            setLink(field.config.fieldObject?.url.text);
        }
        let paramArray: any = [];
        if (field.config.fieldObject?.url?.paramvalue && Object.keys(field.config.fieldObject.url.paramvalue).length > 0) {
            if (eventIdObj?.contextId) {
                API.post(`/getallcustomfields/value`, { eventid: eventIdObj?.contextId })
                    .then(res => res.json())
                    .then(res => {
                        //console.log(res);
                        if (res.error) {
                        }
                        if (res.length > 0) {
                            let posarray = res[0].point_of_sale;
                            let userarray = res[1].user;

                            let paramstring = '?';
                            let index = 0;
                            Object.keys(field.config.fieldObject?.url.paramvalue).forEach(function (key) {
                                //console.log("each param",field.config.fieldObject?.url.paramvalue[key]);
                                let param = JSON.parse(field.config.fieldObject?.url.paramvalue[key]);
                                //console.log("each param json",param);
                                paramArray.push(param);
                                paramstring =
                                    (index === 0 ? paramstring : paramstring + '&') +
                                    field.config.fieldObject?.url.param[index] +
                                    '=' +
                                    (param.pathname === 'PointOfSale'
                                        ? posarray[param.fieldAttributeName]
                                        : userarray[param.fieldAttributeName]);
                                index++;
                            });
                            setLink(field.config.fieldObject?.url.text + paramstring);
                            onChangeHandler(congigId, link);
                            //console.log(link);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            } else {
                let paramstring = '?';
                let index = 0;
                Object.keys(field.config.fieldObject?.url.paramvalue).forEach(function (key) {
                    // console.log("each param",field.config.fieldObject?.url.paramvalue[key]);
                    let param = JSON.parse(field.config.fieldObject?.url.paramvalue[key]);
                    // console.log("each param json",param);
                    paramArray.push(param);
                    paramstring =
                        (index === 0 ? paramstring : paramstring + '&') +
                        field.config.fieldObject?.url.param[index] +
                        '={{' +
                        param.fieldLabelName +
                        '}}';
                    index++;
                });
                setLink(field.config.fieldObject?.url.text + paramstring);
                onChangeHandler(congigId, link);
            }
        }
    }, []);
    //   const classes = useStyles();
    return (
        <Box display='flex' alignItems='center'>
            <a
                href={link}
                target='_blank'
                className={'greenLink'}
                style={{ display: 'contents', paddingRight: '10px' }}
                rel='noreferrer'
            >
                {/* <FormLabel id='slider-label' style={{paddingRight: '10px'}}> */}
                <span style={{ paddingRight: '5px' }}>
                    {' '}
                    {field.config.slug ? t(field.config.slug) : field.config.name}{' '}
                </span>
                {field.config?.toolTipTxt !== '' && (
                    <ToolTipComponent
                        enableHintOnClick={field.config.enableHintOnClick}
                        tooltipText={field.config.toolTipTxt}
                    />
                )}
                {/* </FormLabel> */}
                <LaunchIcon />
            </a>
        </Box>
    );
}

export default UrlFieldComponent;
