import React from 'react';

// currently here for testing
// we can change this another object

// the goal is we use this context in the reactelement libraries
// and if the component is used inside the PWA application when we get the value from the provide
// else it is undefined

// this info can then be used to control flow and render elements
export const PWAContext = React.createContext<PWAContextPayload | undefined>(undefined);

export function PWAContextWrapper(props: React.PropsWithChildren<PWAContextWrapperExtraProps>) {
    return (
        <PWAContext.Provider
            value={{
                areWeInPwa: 'yes',
                pwa: true,
                dispatch: props.reduxDispatch,
                selector: props.reduxSelector,
                pwaHelpers: props.pwaHelpers,
                isHybridSync: props.isHybridSync
            }}
        >
            {props.children}
        </PWAContext.Provider>
    );
}
