import { newProjectDb } from './newProjectsDexie';
import newProjectActionTypes, { createNewProjectAction } from './actions';
import DynTableConst from '../../Services/Constants';
import { loadProjectDocument } from '../Projects/ProjectActions';
import newSyncDataActionTypes from '../NewSync/actions';
import axios from 'axios';
import { setDbSyncedDown } from '../helpers';

const PaginationLimit = 50;

export const createNewProjectWrapperForDexie = (project: any) => ({
    _id: project._id,
    project: project,
    syncDate: new Date(),
    name: project.name,
});

export const syncAllProjects = async (dispatch: any, projectIds: string) => {
    try {
        // drop the projects in the database
        await newProjectDb.transaction('rw', newProjectDb.tables, async () => {
            await Promise.all(newProjectDb.tables.map(table => table.clear()));
        });

        // get the new projects and load them to the database
        let count = 1;
        for (let idx = 0; idx < count; idx += PaginationLimit) {
            const projectsResponse = await getProjectsFetch(projectIds, idx);
            if (!projectsResponse.success) throw new Error('Failed to sync projects');
            count = projectsResponse?.count || 0;
            // console.log({ idx, projectsResponse, count });

            Array.isArray(projectsResponse?.data) &&
                projectsResponse.data.forEach((proj: any) => {
                    proj && proj._id && dispatch(createNewProjectAction(createNewProjectWrapperForDexie(proj)));
                });

            dispatch({
                type: newSyncDataActionTypes.ADD_SYNC_LOG,
                payload: { action: 'Sync newProjectDb', idx, count },
            });
        }

        dispatch({
            type: newProjectActionTypes.UPDATE_PROJECT_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
        setDbSyncedDown('projectsDB');
        count > 0 && cacheAllProjectBriefingDocuments();
    } catch (error) {
        console.log(error);
        dispatch({
            type: newSyncDataActionTypes.ADD_SYNC_ERROR,
            payload: error || 'Error handled but no log available',
        });
        dispatch({
            type: newProjectActionTypes.UPDATE_PROJECT_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
    }
};

export const getProjectsFetch = async (projectIds: string, skip?: number): Promise<any> => {
    return axios
        .post(
            DynTableConst.EMPPLAN_HOST + `/projects/pwa/sync?limit=${PaginationLimit}&skip=${skip || 0}`,
            { _id: projectIds },
            {
                withCredentials: true, // for local dev
                // for local dev
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            },
        )
        .then(response => response.data)
        .catch(err => {
            console.error(err);
            return { foo: 'broken' };
        });
};

export const getSmallProjectsFetch = (): Promise<any> => {
    return axios
        .get(DynTableConst.EMPPLAN_HOST + `/projects/all/XXXX?active=true`, {
            withCredentials: true, // for local dev
            // for local dev
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
        })
        .then(response => response.data)
        .catch(err => {
            console.error(err);
            return { foo: 'broken' };
        });
};

const cacheAllProjectBriefingDocuments = async () => {
    // console.log('Started cacheAllProjectBriefingDocuments');
    if (!window.userabstraction?._id) return;
    // console.log('Started cacheAllProjectBriefingDocuments going on');
    const files: Array<string> = [];
    try {
        const briefingData = await loadProjectDocument(window.userabstraction._id);
        briefingData.forEach(projDoc => {
            projDoc.briefing.documents.forEach(doc => {
                files.push(doc._id);
            });
            projDoc.briefing.attachments.forEach(doc => {
                files.push(doc._id);
            });
        });
        // console.log('Started cacheAllProjectBriefingDocuments starting load', files);
        loadFiles(files);
    } catch (e) {
        // console.log('Error fetching all briefing documents');
    }
};

async function loadFiles(files: Array<string>) {
    for (const docId of files) {
        await fetch('/upload/filesystem/' + docId).catch(() => {});
        await fetch('/upload/files/get/' + docId).catch(() => {});
    }
}
