import React from 'react';
import { Box, Card, Grid, Typography } from '@mui/material';
import CircularProgressWithLabel from '../../components-ui/CircularProgressWithLabel';

interface TargetsCardProps {
    label: string;
    reach: number;
    goal: number;
}

function TargetsCard(props: TargetsCardProps) {
    return (
        <Card variant='outlined' sx={{ mt: 1, minHeight: 90, alignContent: 'center' }}>
            <Grid container>
                <Grid item xs='auto'>
                    <Box mx={3}>
                        <CircularProgressWithLabel value={(props.reach / props.goal) * 100} size={60} />
                    </Box>
                </Grid>
                <Grid item xs sx={{ alignContent: 'center' }}>
                    <Typography variant='body1'>{props.label}</Typography>
                    <Typography variant='body1' display='inline'>
                        {props.reach}/{props.goal}
                    </Typography>
                    <Typography variant='caption' display='inline' ml={1}>
                        based on quantity reported
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
}

export default TargetsCard;
