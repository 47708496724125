import * as React from 'react';
import { ReportConfigurationContext } from '../CustomReport';
import { CustomReportComponent } from '../CustomReport/customReportComponent';
import { isCustomReportDisabled } from '../CustomReport/utils/reportDisableControl';
import { formatReportResultsToSave, parseSavedReportResults } from '../CustomReport/utils/reportHelpers';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    InputAdornment,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import ProductSelect from '../ProductSelect';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers-pro';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useTranslation } from 'react-i18next';
import customReportValidator from '../CustomReport/utils/validator';
import { Product } from '../../utils/Product';
import API from '../../config/api';
import { generateRandomKey } from '../CustomReportDependentFields/keygen';
import { EmpplanSnackbarContext } from '../Snackbar';
import useCurrency from '../../hooks/useCurrency';
import { Delete, ExpandMore } from '@mui/icons-material';
import { PWAContext } from '../../PWA/pwaContext';
import Autocomplete from '@mui/material/Autocomplete';
import { formatCurrency } from '../EventOverviewGrid/utils/utils';

export interface TransactionReportProps {
    event: { _id: string; project_id: { _id: string } };
    isCrmOnlineEvent?: boolean;
    customReports?: Array<CustomReportConfig>;
    updateCallback: any;
    updateSaleObject?: any;
    eventState?: string;
    currentUserCanEditEventUser?: boolean;
    pwaOfflineOptions?: any;
    crmDataSave?: any;
    crmAdded?: any;
}

interface ProductSale {
    uid: string;
    qty: number;
    price: number;
    productId: Product | null;
    saleId: string;
    regexValidation?: boolean;
}

function TransactionReport({
    event,
    isCrmOnlineEvent = true,
    customReports,
    updateCallback, // call to update transaction table
    updateSaleObject,
    eventState,
    currentUserCanEditEventUser,
    pwaOfflineOptions,
    crmDataSave,
    crmAdded,
}: TransactionReportProps) {
    const { t } = useTranslation();
    const pwaContext = React.useContext(PWAContext);
    const snackbarTools = React.useContext(EmpplanSnackbarContext);
    const [currency] = useCurrency();

    const [formState, setFormState] = React.useState<'resetting' | 'ready' | 'saved' | 'changed' | 'saving' | 'error'>(
        'ready',
    );
    const [taskTracker, setTaskTracker] = React.useState<Array<any>>([]); // array of ongoing tasks like uploads, etc.
    const [formHasErrors, setFormHasErrors] = React.useState<boolean>(false); // custom report validation state

    const [saleData, setSaleData] = React.useState<any>({
        saleTime: moment(),
        note: '',
    });
    const [productData, setProductData] = React.useState<Array<ProductSale>>([
        {
            uid: generateRandomKey(),
            qty: 1,
            price: 0.0,
            productId: null,
            saleId: '',
        },
    ]);
    const [reportData, setReportData] = React.useState<ReportData>(parseSavedReportResults({}));

    const salesReportConfig: CustomReportConfig | undefined = saleData._id
        ? (saleData?._customReport?._configcreatedTime as unknown as CustomReportConfig)
        : customReports?.find(r => r.type === 'sales');

    const showSaleTime = salesReportConfig?.salesReportConfig?.showSaleTime;
    const multicartEnabled = salesReportConfig?.salesReportConfig?.multicartEnabled;
    const salePriceHide = salesReportConfig?.salesReportConfig?.sale_priceHide;
    const txnIdHide = salesReportConfig?.salesReportConfig?.transaction_idHide;
    const txnIdReq = salesReportConfig?.salesReportConfig?.transactionIdRequired;
    const noteHide = salesReportConfig?.salesReportConfig?.noteHide;
    const noteReq = salesReportConfig?.salesReportConfig?.noteRequired;
    const isSaleIdRegexValidationRequired = salesReportConfig?.salesReportConfig?.regex?.transactionValidation;
    const regex = salesReportConfig?.salesReportConfig?.regex?.regexName;
    const regexOption = salesReportConfig?.salesReportConfig?.regex?.regexOption || 'g';

    const IdNow:any = salesReportConfig?.customFields?.filter((item) => item.config.fieldType=='IDNow');
    const isAgeCheck = IdNow?.[0]?.config?.fieldObject?.isAgeCheck == true ? true : false;
    const [underAge, setUnderAge] = React.useState<boolean>(false);

    const resetForm = () => {
        setSaleData(() => ({
            saleTime: moment(),
            note: '',
        }));
        setReportData({});
        setProductData(() => [
            {
                uid: generateRandomKey(),
                qty: 1,
                price: 0.0,
                productId: null,
                saleId: '',
            },
        ]);
        setFormHasErrors(false);
        setFormState('resetting');
    };

    const updateSaleData = (key: string, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSaleData({
            ...saleData,
            [key]: event.target.value,
        });
        setFormState(s => 'changed');
    };

    const updateProductData = (key: 'qty' | 'price' | 'saleId' | 'productId', index: number) => (payload: any) => {
        const newarr: any[] = [...productData];
        if (key === 'productId') {
            newarr[index][key] = payload;
        } else {
            let value = payload?.target?.value;
            if (key === 'qty' || key === 'price') {
                value = value ? Number(value) : 0;
            }
            newarr[index][key] = value;
        }
        if (isSaleIdRegexValidationRequired && key === 'saleId') {
            const innerKey = 'regexValidation';
            if (regex) {
                const regexObj = new RegExp(regex, regexOption);
                if (payload?.target?.value && payload?.target?.value?.trim()) {
                    newarr[index][innerKey] = regexObj.test(payload.target.value);
                } else {
                    newarr[index][innerKey] = true;
                }
            } else {
                newarr[index][innerKey] = false;
            }
        }
        setProductData(newarr);
        setFormState(s => 'changed');
    };

    const generateDeleteProductHandler = (uid: string) => () => {
        const newarr: any[] = productData.filter(prod => prod.uid !== uid);
        setProductData(newarr);
    };

    const reportDataChangeHandler = (key: ReportFieldKey, value: ReportFieldValue) => {
        setReportData(s => ({ ...s, [key]: value }));
        setFormState(s => 'changed');
    };

    const checkIdNow = () => {
        setUnderAge(reportData[IdNow[0]?.config?._id]);
    }

    // used to prevent saving before uploads complete
    const taskControlHandler = {
        addTask: (s: string) => {
            setTaskTracker(t => [...t.filter(t => t !== s), s]);
        },
        removeTask: (s: string) => {
            setTaskTracker(t => t.filter(t => t !== s));
        },
    };

    // prepopulates the saleData with the sale object from angular that is to be edited
    React.useEffect(() => {
        if (
            updateSaleObject &&
            updateSaleObject._id &&
            !['expense', 'transactionFixedProduct'].includes(updateSaleObject?._customReport?._configcreatedTime?.type)
            // sometimes there is no customReport associated to a sale/transaction report
            // so we check if it's not the other since we use the same transport object for update
        ) {
            const tmpObj = { ...updateSaleObject };
            if (tmpObj.$$hashKey) delete tmpObj.$$hashKey;
            setSaleData(tmpObj);
            setProductData([
                {
                    uid: generateRandomKey(),
                    price: tmpObj.price,
                    qty: tmpObj.qty,
                    productId: tmpObj.productId,
                    saleId: tmpObj.saleId,
                },
            ]);
            setReportData(parseSavedReportResults(tmpObj._customReport?.reportResults || {}));
            setFormState('resetting');
        }
    }, [updateSaleObject]);

    // runs validations when form data is updated
    React.useEffect(() => {
        let customReportErrors = false;
        if (salesReportConfig?.customFields && reportData) {
            // run the validator to update the errors
            const { hasErrors } = customReportValidator(salesReportConfig, reportData, {
                productId: productData.map(p => p.productId?._id).filter(p => p) as string[],
            });
            customReportErrors = hasErrors;
            // console.log('Run error checks', { hasErrors });
        }
        const note = (saleData?.note as string) || '';
        const noteError = !!(noteReq && !note.trim());
        const txnIdError = !!(txnIdReq && !productData.every(v => v.saleId !== ''));
        const emptyProductsError = !productData.every(p => !!p.productId);
        const regexError = productData.some(eachProdData => eachProdData.regexValidation === false);
        // call the function to handle invalidated report state
        setFormHasErrors(customReportErrors || noteError || txnIdError || emptyProductsError || regexError);
        // eslint-disable-next-line react-hooks/exhaustive-deps

        if(isAgeCheck){
            checkIdNow();
        }
    }, [reportData, saleData, productData]);

    const reportContextData = {
        productId: productData.map(p => p.productId?._id).filter(p => p) as string[],
        isDisabled:
            isCustomReportDisabled('sales', eventState, currentUserCanEditEventUser) ||
            ['saving', 'error'].includes(formState),
    };

    const formSubmitHandler = async () => {
        setFormState('saving');
        if (pwaContext?.pwa) {
            if (saleData?._id) {
                delete saleData.product;
                delete saleData.fullitem;
                const postPayload = {
                    eventId: event._id,
                    ...saleData,
                    saleTime: saleData?.saleTime,
                };
                // console.log(postPayload, 'putPayload');

                if (salesReportConfig && salesReportConfig.type) {
                    postPayload._customReport = {
                        reportResults: formatReportResultsToSave(reportData, salesReportConfig),
                        _configcreatedTime: salesReportConfig,
                        _customReportConfig: salesReportConfig._id,
                    };

                    if ((postPayload?._customReport?._configcreatedTime as any)['$$hashKey']) {
                        delete (postPayload?._customReport?._configcreatedTime as any)['$$hashKey'];
                    }
                }

                for (let idx = 0; idx < productData.length; idx++) {
                    const reqObj = {
                        ...postPayload,
                        productId: productData[idx].productId?._id,
                        price: productData[idx].price ? Number(productData[idx].price) : 0,
                        qty: productData[idx].qty ? Number(productData[idx].qty) : 0,
                        saleId: productData[idx].saleId,
                    };

                    if (pwaContext.pwaHelpers?.salesItems?.update) {
                        await pwaContext.pwaHelpers?.salesItems?.update(reqObj, isCrmOnlineEvent);
                    }
                }

                resetForm();
                updateCallback && updateCallback();
                snackbarTools.createToast({ type: 'success', message: t`##SaleAdded` });
            } else {
                // we only send crm when creating and not during updating data
                let crmDexieId = '';
                if (salesReportConfig?.crmConfig?.isCRMForm && !crmAdded) {
                    try {
                        if (crmDataSave) {
                            const crmDataSaveResult = await crmDataSave();
                            if (crmDataSaveResult === false) {
                            snackbarTools.createToast({ type: 'error', message: t`##CRMDataAlreadyExists` });
                            resetForm();
                            return;
                            } else {
                                crmDexieId = crmDataSaveResult;
                            }
                        }
                    } catch (error) {
                        console.error(error);
                        resetForm();
                    }
                }

                let groupid = generateRandomKey();
                const postPayload = {
                    eventId: event._id,
                    ...saleData,
                    saleTime: saleData?.saleTime.toDate(),
                    groupId: groupid,
                    crmDexieId,
                };
                // console.log(postPayload, 'postPayload');

                if (salesReportConfig && salesReportConfig.type) {
                    postPayload._customReport = {
                        reportResults: formatReportResultsToSave(reportData, salesReportConfig),
                        _configcreatedTime: salesReportConfig,
                        _customReportConfig: salesReportConfig._id,
                    };

                    if ((postPayload?._customReport?._configcreatedTime as any)['$$hashKey']) {
                        delete (postPayload?._customReport?._configcreatedTime as any)['$$hashKey'];
                    }
                }
                let groupArray = [];
                for (let idx = 0; idx < productData.length; idx++) {
                    const reqObj = {
                        ...postPayload,
                        productId: productData[idx].productId?._id,
                        price: productData[idx].price ? Number(productData[idx].price) : 0,
                        qty: productData[idx].qty ? Number(productData[idx].qty) : 0,
                        saleId: productData[idx].saleId,
                    };
                    groupArray.push(reqObj);
                }
                postPayload.groupArray = groupArray;
                for (let idx = 0; idx < productData.length; idx++) {
                    const reqObj = {
                        ...postPayload,
                        productId: productData[idx].productId?._id,
                        price: productData[idx].price ? Number(productData[idx].price) : 0,
                        qty: productData[idx].qty ? Number(productData[idx].qty) : 0,
                        saleId: productData[idx].saleId,
                    };
                    if (pwaContext.pwaHelpers?.salesItems?.add) {
                        await pwaContext.pwaHelpers?.salesItems?.add(reqObj, isCrmOnlineEvent);
                    }
                }
                resetForm();
                updateCallback && updateCallback();
                snackbarTools.createToast({ type: 'success', message: t`##SaleAdded` });
            }
        } else {
            if (saleData?._id) {
                const putPayload = saleData;
                if (putPayload?._customReport?._configcreatedTime['$$hashKey']) {
                    delete putPayload?._customReport?._configcreatedTime['$$hashKey'];
                }
                if (salesReportConfig && putPayload._customReport) {
                    putPayload._customReport.reportResults = formatReportResultsToSave(
                        reportData,
                        salesReportConfig,
                        reportContextData,
                    );
                }
                // console.log('Update Payload PUT', {
                //     ...putPayload,
                //     productId: productData[0].productId?._id,
                //     price: productData[0].price,
                //     qty: productData[0].qty,
                //     saleId: productData[0].saleId,
                // });
                API.put(`salesItems/${saleData._id}`, {
                    ...putPayload,
                    productId: productData[0].productId?._id,
                    price: productData[0].price,
                    qty: productData[0].qty,
                    saleId: productData[0].saleId,
                })
                    .then(resp => resp.json())
                    .then((data: any) => {
                        if (data && !isNaN(data.qty) && data.eventId && data.productId) {
                            resetForm();
                            updateCallback && updateCallback();
                            snackbarTools.createToast({ type: 'success', message: t`##SaleAdded` });
                        } else {
                            console.error('Error updating the transaction', data);
                            setFormState('error');
                            updateCallback && updateCallback();
                            snackbarTools.createToast({ type: 'error', message: t`##SaleFailed` });
                        }
                    });
            } else {
                const postPayload = {
                    eventId: event._id,
                    ...saleData,
                };

                if (salesReportConfig && salesReportConfig.type) {
                    postPayload._customReport = {
                        reportResults: formatReportResultsToSave(reportData, salesReportConfig),
                        _configcreatedTime: salesReportConfig,
                        _customReportConfig: salesReportConfig._id,
                    };

                    if ((postPayload?._customReport?._configcreatedTime as any)['$$hashKey']) {
                        delete (postPayload?._customReport?._configcreatedTime as any)['$$hashKey'];
                    }
                }

                const postResults = [];

                for (let idx = 0; idx < productData.length; idx++) {
                    if (!productData[idx].productId?._id) continue;
                    // console.log('Save Payload POST', {
                    //     ...postPayload,
                    //     productId: productData[idx].productId?._id,
                    //     price: productData[idx].price,
                    //     qty: productData[idx].qty,
                    //     saleId: productData[idx].saleId,
                    // });

                    postResults[idx] = await API.post('salesItems', {
                        ...postPayload,
                        productId: productData[idx].productId?._id,
                        price: productData[idx].price,
                        qty: productData[idx].qty,
                        saleId: productData[idx].saleId,
                    })
                        .then(resp => resp.json())
                        .then((data: any) => {
                            if (data && !isNaN(data.qty) && data.eventId && data.productId) {
                                return true;
                            } else {
                                console.error('Error saving the transaction', data);
                                return false;
                            }
                        })
                        .catch(err => {
                            console.error('Error posting', err);
                            return false;
                        });
                }
                // console.log('POST Results:', postResults);
                if (postResults.every(res => res)) {
                    resetForm();
                    updateCallback && updateCallback();
                    snackbarTools.createToast({ type: 'success', message: t`##SaleAdded` });
                } else {
                    setFormState('error');
                    updateCallback && updateCallback();
                    snackbarTools.createToast({ type: 'error', message: t`##SaleFailed` });
                }
            }
        }
    };

    // prevents the page from closing till the upload and save is completed
    React.useEffect(() => {
        if (formState === 'resetting') {
            setFormState && setFormState('ready');
        }

        const beforeUnloadListener = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            // returning text does not work anymore on modern browsers
            return (event.returnValue = t('##DoYouWantToProceedNotSavedDataWillGetLost'));
        };

        if (taskTracker.length > 0 || formState === 'saving') {
            window.addEventListener('beforeunload', beforeUnloadListener, { capture: true });
        } else {
            window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
        }

        return () => {
            window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskTracker, formState]);

    const calculateEachProductGross = (item: any) => {
        const amount = item.qty * item.price;
        return amount ? formatCurrency(amount) : 0;
    };

    const calculateTotalGross = (items: any) => {
        let total = 0;
        items.forEach((element: any) => {
            total += element.qty * element.price;
        });
        return total ? formatCurrency(total) : 0;
    };

    return (
        <ReportConfigurationContext.Provider value={reportContextData}>
            <Grid container direction='column'>
                {showSaleTime && (
                    <Grid item px={2} xs={6}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <TimePicker
                                sx={{ width: '100%' }}
                                label={t('##Time')}
                                disabled={reportContextData.isDisabled}
                                value={saleData.saleTime ? moment(saleData.saleTime) : null}
                                onChange={newValue => {
                                    setSaleData({
                                        ...saleData,
                                        saleTime: newValue,
                                    });
                                    setFormState(s => 'changed');
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                )}
                <br />
                {productData.map((prodData, idx) => (
                    <Grid item px={2} xs={6} key={prodData.uid} my={1}>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls={`product-${prodData.uid}-content`}
                                id={`product-${prodData.uid}-header`}
                            >
                                <Typography>{prodData.productId?.name || t('##SelectProduct')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid item container spacing={2} alignItems='end'>
                                    <Grid item xs={6} sm={3}>
                                        <TextField
                                            fullWidth
                                            name='qty'
                                            label={t('##Qty')}
                                            variant='standard'
                                            type='number'
                                            disabled={reportContextData.isDisabled}
                                            value={prodData.qty}
                                            onChange={updateProductData('qty', idx)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        {pwaContext?.pwa ? (
                                            <Autocomplete
                                                options={pwaOfflineOptions}
                                                value={prodData.productId}
                                                disabled={reportContextData.isDisabled}
                                                getOptionLabel={(option: any) => option && option.name}
                                                onChange={(event, value) => {
                                                    updateProductData('productId', idx)(value);
                                                    value?.price !== undefined &&
                                                        updateProductData(
                                                            'price',
                                                            idx,
                                                        )({ target: { value: value.price } });
                                                }}
                                                renderOption={(props, option) => (
                                                    <React.Fragment key={option._id}>
                                                        <Box
                                                            {...props}
                                                            component='li'
                                                            display='flex'
                                                            flexDirection='column'
                                                        >
                                                            <Typography variant='body1' width='100%'>
                                                                {option.name}
                                                            </Typography>
                                                            {option.productGroup?.name && (
                                                                <Typography variant='body2' width='100%'>
                                                                    {t('##ProductGroup')}: {option.productGroup?.name}
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                    </React.Fragment>
                                                )}
                                                renderInput={params => (
                                                    <TextField {...params} variant='standard' label={t('##Products')} />
                                                )}
                                            />
                                        ) : (
                                            <ProductSelect
                                                scanner
                                                forTransaction
                                                disabled={reportContextData.isDisabled}
                                                initialValue={prodData.productId}
                                                projectId={event.project_id?._id}
                                                onChange={(selectedProduct: any) => {
                                                    updateProductData('productId', idx)(selectedProduct);
                                                    selectedProduct?.price !== undefined &&
                                                        updateProductData(
                                                            'price',
                                                            idx,
                                                        )({ target: { value: selectedProduct.price } });
                                                }}
                                            />
                                        )}
                                    </Grid>

                                    {!salePriceHide && (
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                name='price'
                                                label={t('##SalePrice')}
                                                variant='standard'
                                                type='number'
                                                inputProps={{ step: '0.01' }}
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        textAlign: 'right',
                                                    },
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>{currency?.symbol}</InputAdornment>
                                                    ),
                                                }}
                                                disabled={reportContextData.isDisabled}
                                                value={prodData.price}
                                                onChange={updateProductData('price', idx)}
                                            />
                                        </Grid>
                                    )}
                                    {!txnIdHide && (
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                name='saleId'
                                                label={t('##TransactionId')}
                                                variant='standard'
                                                required={
                                                    txnIdReq ||
                                                    (isSaleIdRegexValidationRequired &&
                                                        prodData?.regexValidation === false)
                                                }
                                                disabled={reportContextData.isDisabled}
                                                sx={{
                                                    '& .MuiFormLabel-asterisk': {
                                                        color: 'transparent',
                                                        '&::after': {
                                                            color: 'red',
                                                            content:
                                                                isSaleIdRegexValidationRequired &&
                                                                prodData?.regexValidation === false
                                                                    ? `"(${t('##RegexValidationFailed')})"`
                                                                    : `"(${t('##TheFieldIsRequired')})"`,
                                                        },
                                                    },
                                                }}
                                                value={prodData.saleId}
                                                onChange={updateProductData('saleId', idx)}
                                            />
                                        </Grid>
                                    )}
                                    {multicartEnabled && (
                                        <Grid item xs={12} textAlign='end'>
                                            <Typography>
                                                {calculateEachProductGross(prodData) !== 0 && (
                                                    <b>
                                                        {t('##ProductGrossTotal')} {calculateEachProductGross(prodData)}{' '}
                                                    </b>
                                                )}
                                            </Typography>
                                        </Grid>
                                    )}
                                    {productData.length > 1 && (
                                        <Grid item xs={12} textAlign='end'>
                                            <Tooltip title={t(`##DeleteField`)}>
                                                <Button
                                                    variant='outlined'
                                                    size='medium'
                                                    color='error'
                                                    startIcon={<Delete />}
                                                    onClick={generateDeleteProductHandler(prodData.uid)}
                                                >
                                                    {t('##Delete')}
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                ))}
                {!saleData?._id && multicartEnabled && (
                    <Grid item px={2}>
                        <Button
                            color='secondary'
                            size='small'
                            variant='contained'
                            sx={{ borderRadius: 30, mt: 1 }}
                            disabled={!productData.every(p => !!p.productId)}
                            onClick={() => {
                                setProductData([
                                    ...productData,
                                    {
                                        uid: generateRandomKey(),
                                        qty: 1,
                                        price: 0.0,
                                        productId: null,
                                        saleId: '',
                                    },
                                ]);
                            }}
                        >
                            {t('##AddProduct')}
                        </Button>
                    </Grid>
                )}
                {multicartEnabled && (
                    <Grid item xs={12} textAlign='end' px={2}>
                        {calculateTotalGross(productData) !== 0 && (
                            <Typography>
                                <b>
                                    {t('##Total')} {calculateTotalGross(productData)}
                                </b>
                            </Typography>
                        )}
                    </Grid>
                )}
                {salesReportConfig && formState !== 'resetting' && (
                    <Grid item>
                        <CustomReportComponent
                            reportData={reportData}
                            contextObjForUpload={{}}
                            onChangeHandler={reportDataChangeHandler}
                            taskControl={taskControlHandler}
                            reportConfig={salesReportConfig}
                            eventId={event._id}
                        />
                    </Grid>
                )}
                {!noteHide && (
                    <Grid item px={2}>
                        <TextField
                            fullWidth
                            name='note'
                            label={t('##Notes')}
                            variant='standard'
                            value={saleData.note}
                            required={noteReq}
                            disabled={reportContextData.isDisabled}
                            onChange={e => updateSaleData('note', e)}
                            sx={{
                                '& .MuiFormLabel-asterisk': {
                                    color: 'transparent',
                                    '&::after': {
                                        color: 'red',
                                        content: `"(${t('##TheFieldIsRequired')})"`,
                                    },
                                },
                            }}
                        />
                    </Grid>
                )}
            </Grid>

            <Box display='flex' justifyContent='flex-end' px={2} gap={2}>
                <Button
                    variant='text'
                    color='error'
                    sx={{ mt: 2 }}
                    onClick={() => {
                        // console.log('Reset form');
                        resetForm();
                    }}
                >
                    {t('##Cancel')}
                </Button>
                <Button
                    color='secondary'
                    variant='contained'
                    sx={{ mt: 2, borderRadius: 30 }}
                    disabled={
                        taskTracker.length > 0 ||
                        formState !== 'changed' ||
                        formHasErrors ||
                        reportContextData.isDisabled ||
                        underAge
                    }
                    onClick={() => {
                        // console.log('Submitting form', { saleData, reportData, taskTracker });
                        formSubmitHandler();
                    }}
                >
                    {saleData?._id ? t('##Update') : t('##AddTransaction')}
                </Button>
            </Box>
        </ReportConfigurationContext.Provider>
    );
}

export default TransactionReport;
