import { newEventDb } from './newEventsDexie';
import { createNewEventWrapperForDexie, eventStatesToSync, getEventsFetch, syncAllEvents } from './helpers';
import { circle, point, distance } from '@turf/turf';
import { Collection, IndexableType, Table } from 'dexie';
import { isDeviceOnline } from '../../Services/OnlineStatus';
import { addEventActionCollection } from '../NewEventActionCollection/actions';
import axios from 'axios';
import DynTableConst from '../../Services/Constants';
import { checkIsEventDate } from '../../../reactelements/src/pages/MyEvents/eventHelpers';
import { clearAllDbSyncStatuses } from '../helpers';

const newEventActionTypes = {
    NEW_EVENT: 'NEW_EVENT',
    LOAD_EVENT_LIST: 'LOAD_EVENT_LIST',
    LOAD_EVENT: 'LOAD_EVENT',
    UPDATE_EVENT_SYNC_DATA: 'UPDATE_EVENT_SYNC_DATA',
    CLEAR_EVENT_LIST: 'CLEAR_EVENT_LIST',
    CLEAR_LOAD_EVENT: 'CLEAR_LOAD_EVENT',
    LOAD_LOCATION_CONTACT: 'LOAD_LOCATION_CONTACT',
    CLEAR_LOCATION_CONTACT: 'CLEAR_LOCATION_CONTACT',
    EDIT_LOCATION_CONTACT: 'EDIT_LOCATION_CONTACT',
    LOADING_LOCATION_CONTACT: 'LOADING_LOCATION_CONTACT',
    ADD_PROMOTER_LOCATION_CONTACT_CHANNEL: 'ADD_PROMOTER_LOCATION_CONTACT_CHANNEL',
    MY_EVENTS_TODAY: 'MY_EVENTS_TODAY',
};

const locationContactChannelName = 'Promoter Location Contact Channel';

export default newEventActionTypes;

export const createNewEventAction = (event: DexieEvent) => {
    newEventDb.events.add(event);
    return {
        type: newEventActionTypes.NEW_EVENT,
        payload: event,
    };
};

export const loadEventAction = (eventId: string | null) => async (dispatch: any) => {
    if (eventId !== null && eventId) {
        const event = await newEventDb.events.get(eventId);

        if (!event && isDeviceOnline() && window.userabstraction._id) {
            // we load from server
            // console.log('Loading this event from the API');
            const eventResponse = await getEventsFetch({ skip: 0, limit: 1 }, window.userabstraction._id, {
                eventId: eventId,
            }).catch(console.error);
            if (
                eventResponse &&
                eventResponse.success &&
                eventResponse.count &&
                Array.isArray(eventResponse.data) &&
                eventResponse.count > 0
            ) {
                const events = eventResponse.data.map((event: any) => createNewEventWrapperForDexie(event));
                console.log('Dispatching event loaded from the API');
                return dispatch({
                    type: newEventActionTypes.LOAD_EVENT,
                    payload: events[0],
                });
            }
        }

        return dispatch({
            type: newEventActionTypes.LOAD_EVENT,
            payload: event,
        });
    } else {
        return dispatch({
            type: newEventActionTypes.LOAD_EVENT,
            payload: undefined,
        });
    }
};

export const updateNewEvent = (eventData: any) => {
    if (eventData._id) {
        newEventDb.events.update(eventData._id, {
            event: eventData.event,
            eventState: eventData.eventState,
        });
    }
};

export const closeEventAction = async (eventId: string) => {
    const mainEvent = await newEventDb.events.get(eventId);

    if (mainEvent) {
        const postReportEvent: any = {
            ...mainEvent,
            eventState: 'SalesReportUploaded',
            event: {
                ...mainEvent.event,
                eventState: 'SalesReportUploaded',
            },
        };
        updateNewEvent(postReportEvent);
        const actionPayload = {
            _id: mainEvent._id,
            type: 'SubmitReport',
        };
        addEventActionCollection(actionPayload);
    }
};

export const promoterDeleteEventAction = (filterOptions: { id: string | number }) => async (dispatch: any) => {
    await newEventDb.events
        .where('_id')
        .equals(filterOptions.id)
        .delete();
};

export const loadEventListAction = (
    filterOptions: {
        lat?: number;
        lon?: number;
        rangeInKm?: number;
        projectId?: string | null | undefined;
        page: number;
        limit: number;
        eventState?: string;
    },
    orderBy?: string,
) => async (dispatch: any) => {
    const limit = filterOptions.limit || 10;
    const page = filterOptions.page + 1;
    const offset = (page - 1) * limit;

    // console.log('loadEventListAction', isDeviceOnline());

    type DexieEventQueryBuilder = Collection<DexieEvent, IndexableType> | Table<DexieEvent, IndexableType>;
    let eventsQuery: DexieEventQueryBuilder = newEventDb.events;

    if (filterOptions?.lat && filterOptions?.lon && filterOptions?.rangeInKm) {
        // // incase you need to mock the location then do it here - for debugging
        // filterOptions.lon = 6.0;
        // filterOptions.lat = 51.0;

        console.log('Eventsfilter user lon lat', filterOptions?.lon, filterOptions?.lat);
        const boundingBox = circle([filterOptions?.lon, filterOptions?.lat], filterOptions?.rangeInKm, {
            steps: 4,
            units: 'kilometers',
        });
        const boxCoords = boundingBox.geometry.coordinates[0];

        console.log('Eventsfilter box', boxCoords);
        console.log('Eventsfilter maxmin lonlat', [boxCoords[1][0], boxCoords[2][1]], [boxCoords[3][0], boxCoords[0][1]]);

        eventsQuery = eventsQuery
            .where('[lon+lat]')
            .between([boxCoords[1][0], boxCoords[2][1]], [boxCoords[3][0], boxCoords[0][1]]);
    }

    if (filterOptions?.projectId) {
        // does not work anymore cause of chaining issues
        // eventsQuery = eventsQuery.where('projectId').equals(filterOptions.projectId);

        // use this if the above does not work, but maybe this does not work too
        eventsQuery = eventsQuery.filter(dexieEvent => dexieEvent.projectId === filterOptions.projectId);
    }
    if (filterOptions.eventState) {
        const arrEventState = filterOptions.eventState.split(',');
        eventsQuery = eventsQuery.filter(
            (dexieEvent: DexieEvent): boolean => arrEventState.indexOf(dexieEvent.eventState) !== -1,
        );
    }

    const countRes = await eventsQuery.count();

    if (countRes > 0) {
        let filteredEvents: Array<DexieEvent & { kms?: number }> = [];

        if (filterOptions?.lat && filterOptions?.lon && filterOptions?.rangeInKm) {
            // query all events in the bounding box
            filteredEvents = await eventsQuery.toArray();
            // inject distance into event
            filteredEvents = filteredEvents.map(event => {
                var from = point([filterOptions.lon as number, filterOptions.lat as number]);
                var to = point([event.lon, event.lat]);
                var kms = distance(from, to, { units: 'kilometers' });
                event.event.kms = kms;
                console.log('Eventsfilter event', { from: from.geometry.coordinates, to: to.geometry.coordinates, kms });
                return event;
            });
            // sort
            filteredEvents.sort((a, b) => a.event.kms - b.event.kms);
            // pagination
            filteredEvents = filteredEvents.slice(offset, offset + limit);
        } else if (orderBy === '-date') {
            filteredEvents = await eventsQuery.toArray();
            // sort by -date
            filteredEvents.sort((a, b) => new Date(b.event.date).getTime() - new Date(a.event.date).getTime());
            // pagination
            filteredEvents = filteredEvents.slice(offset, offset + limit);
        } else {
            filteredEvents = await eventsQuery.toArray();
            // sort by date
            filteredEvents.sort((a, b) => new Date(a.event.date).getTime() - new Date(b.event.date).getTime());
            // pagination
            filteredEvents = filteredEvents.slice(offset, offset + limit);
        }

        return dispatch({
            type: newEventActionTypes.LOAD_EVENT_LIST,
            payload: {
                events: filteredEvents,
                count: countRes,
            },
        });
    }

    if (isDeviceOnline()) {
        // device is online
        if (filterOptions.eventState && filterOptions.eventState.split(',').find(es => !eventStatesToSync.includes(es)) && window.userabstraction._id) {
            // if (true) {
            // we need to make the network call

            // console.log('Loading this event list from the API');
            const eventResponse = await getEventsFetch(
                { skip: offset, limit: limit },
                window.userabstraction._id,
                {
                    eventState: filterOptions.eventState,
                },
                orderBy,
            ).catch(console.error);
            if (eventResponse && eventResponse.success && eventResponse.count && Array.isArray(eventResponse.data)) {
                const events = eventResponse.data.map((event: any) => createNewEventWrapperForDexie(event));
                console.log('Dispatching event list loaded from the API');
                return dispatch({
                    type: newEventActionTypes.LOAD_EVENT_LIST,
                    payload: {
                        events: events,
                        count: eventResponse.count,
                    },
                });
            }
        }
    }

    // else send the empty array cause count is 0
    return dispatch({
        type: newEventActionTypes.LOAD_EVENT_LIST,
        payload: {
            events: [],
            count: countRes,
        },
    });
};

export const addEditLocationContactDetails = (payload: any) => (dispatch: any) => {
    dispatch(internalEventActionHandler({ type: newEventActionTypes.EDIT_LOCATION_CONTACT, payload: payload }));
};

export const clearEditLocationContactDetails = () => (dispatch: any) => {
    dispatch(internalEventActionHandler({ type: newEventActionTypes.EDIT_LOCATION_CONTACT, payload: null }));
}

export const clearEventListAction = () => {
    return {
        type: newEventActionTypes.CLEAR_EVENT_LIST,
    };
};

export const clearSingleEventAction = () => {
    return {
        type: newEventActionTypes.CLEAR_LOAD_EVENT,
    };
};

export const startEventSyncAction = (userId: string) => (dispatch: any) => {
    dispatch({
        type: newEventActionTypes.UPDATE_EVENT_SYNC_DATA,
        payload: {
            state: 'DOWNLOADING',
            lastUpdate: new Date(),
        },
    });
    clearAllDbSyncStatuses();
    syncAllEvents(dispatch, userId);
};


const internalEventActionHandler = ({ type, payload }: { type: string, payload: any }) => {
    return {
        type,
        payload,
    }
}

export const getTodaysEvents = () => async (dispatch: any) => {
    const data: DexieEvent[] = await newEventDb.events.toArray();
    const filteredData = data.filter(item => checkIsEventDate(item.event));
    dispatch(internalEventActionHandler({ type: newEventActionTypes.MY_EVENTS_TODAY, payload: filteredData || [] }));
};

export const clearTodaysEvents = () => {
    return {
        type: newEventActionTypes.MY_EVENTS_TODAY,
        payload: []
    }
}

export const getLocationContactDetails = (posId: string) => (dispatch: any) => {
    dispatch(internalEventActionHandler({ type: newEventActionTypes.LOADING_LOCATION_CONTACT, payload: false }));
    axios.get(DynTableConst.EMPPLAN_HOST + `/userTypes/all?pos=${posId}&queryCount=true`).then((response) => {
        if (response.data && response.data?.count > 0 && Array.isArray(response.data?.list) && response.data?.list.length > 0) {
            dispatch(internalEventActionHandler({ type: newEventActionTypes.LOAD_LOCATION_CONTACT, payload: response.data }));
        } else {
            dispatch(internalEventActionHandler({ type: newEventActionTypes.LOAD_LOCATION_CONTACT, payload: {} }));
        }
    }).catch((error: any) => {
        dispatch(internalEventActionHandler({ type: newEventActionTypes.LOAD_LOCATION_CONTACT, payload: {} }));
    });
};

export const clearLoadLocationContact = () => {
    return {
        type: newEventActionTypes.CLEAR_LOCATION_CONTACT,
        payload: {}
    }
}

export const createPromoterLocationContactChannel = (userId: string) => (dispatch: any) => {
    const body = {
        name: locationContactChannelName,
        locationContact: userId,
    };
    axios
        .post('/chatchannel/add/promoterLocationContactChannel', body)
        .then(res => {
            dispatch(internalEventActionHandler({ type: newEventActionTypes.ADD_PROMOTER_LOCATION_CONTACT_CHANNEL, payload: res.data }));
        })
        .catch(er => {
            dispatch(internalEventActionHandler({ type: newEventActionTypes.ADD_PROMOTER_LOCATION_CONTACT_CHANNEL, payload: {} }));
        });
}

export const clearPromoterLocationContactChannel = () => {
    return {
        type: newEventActionTypes.ADD_PROMOTER_LOCATION_CONTACT_CHANNEL,
        payload: {}
    }
}

export const returnDexieEvent = async (eventId: string) => {
    const event = await newEventDb.events.get(eventId);
    if (event) {
        return event.event;
    } else {
        return null;
    }
}