import { Autocomplete, AutocompleteProps, CircularProgress, TextField, TextFieldProps } from '@mui/material'
import { t } from 'i18next';
import { Fragment, useEffect, useState } from 'react'
import API, { RequestParams } from '../../../config/api';


export interface ExtendedRequestParams extends RequestParams {
    method?: string;
    body?: {};
}


export interface AsynchronousAutoCompleteProps<T extends { _id: string }> {
  reqParams: ExtendedRequestParams | ((searchText:string)=> ExtendedRequestParams),
  defaultValue: T | null,
  searchTextParamKey: string,
  autoCompleteProps?:AutocompleteProps<T|null,false,false,false> |any,
  textFieldProps?: TextFieldProps,
  getOptionsFromResponse?: (r: any) => Array<T>,
  characterThreshold?:number
  multiple?:boolean,
  limitMultiple?:number,
  getQueryStringFromSearchText?:(searchText:string)=>string
  disabledOptions?: Array<string>
}

/**
   @param autoCompleteProps can be any be any properties valid for Material AutoComplete.
   @param defaultValue this would be used to infer type for the values and options.
   @param searchTextParamKey this key would be used to attach to the url for searchText eg. localhost/products?[searchParamKey]='clp'
 */

export default function AsynchronousAutoComplete<T extends { _id: string }>(
  {
    reqParams,
    defaultValue,
    searchTextParamKey,
    autoCompleteProps,
    textFieldProps,
    characterThreshold,
    multiple,
    limitMultiple,
    getQueryStringFromSearchText,
    disabledOptions,
    getOptionsFromResponse = (r: any) => r }: AsynchronousAutoCompleteProps<T>) {

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<readonly T[]>([]);
 
  const [value, setValue] = useState<T | null|Array<T>>(defaultValue==null && multiple?[]:defaultValue);

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    let active = true;
    let threshold = 3;
    if(characterThreshold===0){
      threshold = 0
    }else if(characterThreshold){
      threshold = characterThreshold
    }
    
    if (inputValue.length < threshold) {
      return undefined;
    }

    //To avoid API call when loading the component with existing value
    if(inputValue === autoCompleteProps?.getOptionLabel?.(value)){
      return undefined;
    }

    const delayDebounceFn = setTimeout(() => {
      (async () => {
        setLoading(true);
        let res;
        let reqP = typeof reqParams === "function"?reqParams(getQueryStringFromSearchText?.(inputValue)||inputValue):reqParams;

        if(reqP.method?.toLowerCase()==='post'){
            res = await API.post(reqP.url, {...reqP.body || {},[searchTextParamKey]:getQueryStringFromSearchText?.(inputValue)||inputValue});
            res = await res.json();
        }else{
            res = await API.getReqestWithParams({ ...reqP, params: { ...reqP.params, [searchTextParamKey]: getQueryStringFromSearchText?.(inputValue)||inputValue } });
        }

        res = getOptionsFromResponse && getOptionsFromResponse(res)
        if (active && Array.isArray(res)) {
          setFilteredOptions(res as Array<T>);
        }
        setLoading(false);
      })().catch(() => {
        setLoading(false);
      });
    }, 500)

   
    
    
    

    return () => {
      active = false;
      clearTimeout(delayDebounceFn)
    };
  }, [inputValue]);


  useEffect(() => {
    if (!open) {
      setFilteredOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      multiple={multiple}
      fullWidth
      getOptionLabel={(option:any) => option?.name||''}
      {...autoCompleteProps}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      key={autoCompleteProps.name}
      onChange={(event: any, newValue: T | null,reason) => {
        autoCompleteProps?.onChange?.(newValue)
        setValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {

        setInputValue(newInputValue);
      }}
      options={filteredOptions}
      noOptionsText={
        inputValue.length < 3 ? t('##StartTypingToSearch') : loading ? `${t('##Loading')}...` : t('##NothingFound')
      }
      isOptionEqualToValue={(option: T|null, value: T|null) => {
        return option?._id === value?._id
      }}
      loading={loading}
      defaultValue={defaultValue===null && multiple?[]:defaultValue}
      getOptionDisabled={option =>
        (multiple && limitMultiple && (value as any[]).length >= limitMultiple) ||
        (disabledOptions && disabledOptions.length > 0 && disabledOptions.includes(option._id))
      }
      // value={value || null}
      renderInput={(params:any) => {
     
        return(
        <TextField variant='standard'
          {...params}
          {...textFieldProps}
          value = {params.inputProps?.value || ''}
          
          InputProps={{
            ...params.InputProps,
            ...textFieldProps?.InputProps,
            "data-testid":autoCompleteProps.name,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}}
    />
  );
}
