import { ExtendedColumnType, OverloadColumnType } from '../../../config/schema/ColumnTypes';
import { FC, useContext, useEffect, useState } from 'react';
import { ColumnsPanelContext, ISavedColumnContextState } from '../contexts/columns';
import { GridColDef, GridColumnVisibilityModel, GridPinnedColumns, GridRenderColumnsProps } from '@mui/x-data-grid-pro';
import { ObjectSchema, Schema, SchemaMap } from 'types-joi';
import { useGenerateColumnDefinitionFromSchema } from '../../../config/schema';
import joi from '../../../config/JoiExtension';
import { capitalize } from '@mui/material';
import Joi from 'joi';

export interface IViewConfig {
    schema: Schema<{}>;
    view: `${string}:${string}`;
    module: string;
    pinnedColumns?: GridPinnedColumns;
    filterable?: string[];
    nonFilterable?: string[];
    sortable?: string[];
    nonSortable?: string[];
    resizable?: string[];
    nonResizable?: string[];
    visibleColumns?: string[]; // keypath format string, with dot seperator
    hiddenColumns?: string[];
    columnWidth?: Record<string, number>;
    columnOrder?: string[] | { [key: number]: string };
    columnCollapsed?: string[];
    userTypes?: ExtendedColumnType;
    extendedTypes?: OverloadColumnType;
    editableColumns?: string[];
    customColumns?: Record<string, (...args: any) => any>;
    parent?: any;
}
function getColumnVisibilityModel(
    visibleColumns: string[] | undefined,
    hiddenColumns: string[] | undefined,
    columnsList: Record<string, any>,
): GridColumnVisibilityModel {
    let columnVisibilityModel: GridColumnVisibilityModel = {};
    if (visibleColumns) {
        // all columns hidden by default
        columnVisibilityModel = Object.fromEntries(Object.keys(columnsList).map(key => [key, false]));
    } else if (hiddenColumns) {
        // all columns visible by default
        columnVisibilityModel = Object.fromEntries(Object.keys(columnsList).map(key => [key, true]));
    }
    if (visibleColumns) {
        visibleColumns.forEach(keyPath => {
            columnVisibilityModel[keyPath] = true;
        });
    }
    if (hiddenColumns) {
        hiddenColumns.forEach(keyPath => {
            columnVisibilityModel[keyPath] = false;
        });
    }
    return columnVisibilityModel;
}
export function useGridBuilder<T extends any>(config: IViewConfig, genericFields: IGenericFields[]) {
    const { visibleColumns, hiddenColumns, columnWidth, columnOrder, columnCollapsed, userTypes, extendedTypes } = config;
    const { columnsList, restoreSavedState, setColumns } = useContext(ColumnsPanelContext);
    const [columnDefinitions, setColumnDefinitions] = useState<GridColDef[]>([]);
    const extendedSchema = useExtendSchemaWithGenericFields(config.schema, genericFields);
    const colDefs = useGenerateColumnDefinitionFromSchema(
        extendedSchema,
        {
            filterable: config.filterable ? new Set(config.filterable) : undefined,
            nonFilterable: config.nonFilterable ? new Set(config.nonFilterable) : undefined,
            sortable: config.sortable ? new Set(config.sortable) : undefined,
            nonSortable: config.nonSortable ? new Set(config.nonSortable) : undefined,
            resizable: config.resizable ? new Set(config.resizable) : undefined,
            nonResizable: config.nonResizable ? new Set(config.nonResizable) : undefined,
            editableColumns: config.editableColumns ? new Set(config.editableColumns) : undefined,
            customColumns: config.customColumns,
        },
        userTypes,
        extendedTypes,
    );

    useEffect(() => {
        setColumnDefinitions(colDefs);
        setColumns(colDefs);
    }, [colDefs]);
    useEffect(() => {
        if (Object.keys(columnsList || {}).length === 0) return;
        const columnVisibilityModel = getColumnVisibilityModel(visibleColumns, hiddenColumns, columnsList);
        // seclect only columns
        const columnsWidthModel = Object.fromEntries(
            Object.entries(columnWidth || {}).filter(([key]) => key in columnsList),
        );
        const columnsOrderModel = (
            Array.isArray(columnOrder) ? [...columnOrder] : visibleColumns ? [...visibleColumns] : []
        ).filter(key => key in columnsList);
        const collapsedColumnsModel = [...(columnCollapsed || [])].filter(key => key in columnsList);
        const state = {
            columnVisibilityModel,
            columnsWidthModel,
            columnsOrderModel,
            collapsedColumnsModel,
        } as ISavedColumnContextState;
        restoreSavedState(state);
    }, [columnsList]);
    return columnDefinitions;
}

export interface IGenericFields {
    fieldAttributeName: string;
    fieldLabelName: string;
}

export function useExtendSchemaWithGenericFields(schema: Schema<{}>, genericFields: IGenericFields[]): Schema<{}> {
    if (!genericFields || genericFields.length === 0) {
        return schema;
    }

    let obj: any = {};

    genericFields.forEach(field => {
        obj['genericFields.' + field.fieldAttributeName.toString()] = joi.string().label(field.fieldLabelName);
    });

    let newS = schema.append(obj);

    return newS;
}
