import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import DateRangePickerWrapper from './DateRangePickerWrapper';
import AutocompleteWrapper from './AutocompleteWrapper';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';

const FILTER_STEP_VALUE = 'fieldValue';
const FILTER_STEP_FIELD_NAMES = 'fieldNames';

const RenderInput = ({
    onBlur,
    params,
    isDateRangeVisible,
    openCalender,
    calenderCloseUpdate,
    deleteFromSteptwo,
    setOpenCalender,
    filteredList,
    isAutocompleteVisible,
    innerInputValue,
    innerValue,
    setInnerInputValue,
    setInnerValue,
    setMultiFieldValue,
    innerAutosuggestRef,
    filterBarRef,
    filterBarInputRef,
    filterStep,
    updateCursorPosition,
    filterType,
    loading,
    editMode,
    handleBackspaceCall,
    inputValue,
    setInputValue
}: any) => {
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = useState<[Moment | null, Moment | null]>([null, null]);
    React.useEffect(() => {
        if (!selectedDate.includes(null)) {
            //;
            calenderCloseUpdate(selectedDate);
        }
    }, [selectedDate]);
    
    const handlePaste = (event: { preventDefault: () => void; }) => {
        if(filterStep === FILTER_STEP_VALUE){

        }else{
            event.preventDefault();
            alert('Pasting is disabled for this field');
        }
        
       
    };
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <TextField
                {...params}
                // onBlur={onBlur}
                ref={filterBarRef}
                // label={t('##Search')}
                sx={{ pt: 1 }}
                variant='standard'
                inputRef={filterBarInputRef}
                onPaste={handlePaste} 
                onKeyDown={(event: any) => {
                    if (event.key === 'Backspace' || event.key === 'Delete') {
                        if (!inputValue || inputValue.length === 0) {
                            handleBackspaceCall();
                            event.stopPropagation();
                        } else {
                            event.stopPropagation();
                        }

                        //
                    }else if (event.key === 'Enter' ){
                        //alert(event.target.value);
                        // if( filterStep === FILTER_STEP_FIELD_NAMES){
                        //     setInputValue('');
                        //     event.stopPropagation();

                        // }
                        
                    }
                }}
               
                inputProps={{
                    ...params?.inputProps,

                    style: {
                        position: isDateRangeVisible || isAutocompleteVisible ? 'absolute' : 'relative',
                        minWidth: isDateRangeVisible || isAutocompleteVisible ? 'auto' : '150px',
                        flexGrow:
                            isDateRangeVisible || isAutocompleteVisible
                                ? '0'
                                : filterStep === FILTER_STEP_VALUE
                                ? '0'
                                : '1',
                        visibility: editMode
                            ? 'hidden'
                            : isDateRangeVisible || isAutocompleteVisible
                            ? 'hidden'
                            : 'visible',

                        borderTopRightRadius:
                            filterStep === FILTER_STEP_VALUE && !isDateRangeVisible && !isAutocompleteVisible
                                ? '50px'
                                : 'none',
                        borderBottomRightRadius:
                            filterStep === FILTER_STEP_VALUE && !isDateRangeVisible && !isAutocompleteVisible
                                ? '50px'
                                : 'none',
                        background:
                            filterStep === FILTER_STEP_VALUE && !isDateRangeVisible && !isAutocompleteVisible
                                ? '#949E9E'
                                : 'transparent',
                        color:
                            filterStep === FILTER_STEP_VALUE && !isDateRangeVisible && !isAutocompleteVisible
                                ? 'white'
                                : 'black',
                        marginTop:
                            filterStep === FILTER_STEP_VALUE && !isDateRangeVisible && !isAutocompleteVisible
                                ? '-4px'
                                : 'none',
                        marginLeft:
                            filterStep === FILTER_STEP_VALUE && !isDateRangeVisible && !isAutocompleteVisible
                                ? '2px'
                                : 'none',
                        paddingLeft:
                            filterStep === FILTER_STEP_VALUE && !isDateRangeVisible && !isAutocompleteVisible
                                ? '7px'
                                : 'none',
                    },
                }}
                InputProps={{
                    ...params?.InputProps,
                    onClick: e => updateCursorPosition(e),
                    onFocus: e => updateCursorPosition(e),
                    onChange: e => updateCursorPosition(e),
                    endAdornment: (
                        <React.Fragment>
                            {!editMode &&
                                filterStep === FILTER_STEP_VALUE &&
                                !isDateRangeVisible &&
                                !isAutocompleteVisible && (
                                    <CancelIcon
                                        style={{
                                            cursor: 'pointer',
                                            marginLeft: '-27px',
                                            zIndex: '999999999',
                                            color: 'white',
                                            marginTop: '-3px',
                                        }}
                                        onClick={deleteFromSteptwo}
                                    />
                                )}
                            {!editMode && (
                                <DateRangePickerWrapper
                                    params={params}
                                    value={[moment().add(-30, 'days'), moment()]}
                                    open={openCalender}
                                    isDateRangeVisible={isDateRangeVisible}
                                    onClose={() => {
                                        //  calenderClose();
                                        setOpenCalender(false);
                                    }}
                                    onChange={date => {
                                        if (!date.includes(null)) {
                                            setSelectedDate(date);
                                        }
                                    }}
                                    deleteFromSteptwo={deleteFromSteptwo}
                                    onOpen={() => {
                                        setOpenCalender(true);
                                    }}
                                />
                            )}

                            {!editMode && (
                                <AutocompleteWrapper
                                    filteredList={filteredList}
                                    isAutocompleteVisible={isAutocompleteVisible}
                                    innerInputValue={innerInputValue}
                                    innerValue={innerValue}
                                    setInnerInputValue={setInnerInputValue}
                                    setInnerValue={setInnerValue}
                                    setMultiFieldValue={setMultiFieldValue}
                                    deleteFromSteptwo={deleteFromSteptwo}
                                    innerAutosuggestRef={innerAutosuggestRef}
                                />
                            )}

                            {!editMode && (
                                <div
                                    style={{
                                        display:
                                            filterStep === FILTER_STEP_VALUE && filterType === 'autosugget'
                                                ? 'hidden'
                                                : 'visible',
                                    }}
                                >
                                    {loading ? <CircularProgress color='inherit' size={20} /> : null}
                                    {params?.InputProps?.endAdornment}
                                </div>
                            )}
                        </React.Fragment>
                    ),
                }}
            />
        </LocalizationProvider>
    );
};

export default RenderInput;
