import { newCRMActionsDb } from './newCRMActionDexie';
import ObjectID from 'bson-objectid';
import { tz } from 'moment-timezone';

// action types
export const newCRMActionTypes = {
    NEW_CRM_ACTION: 'NEW_CRM_ACTION',
    GET_ALL_CRM_ACTIONS: 'GET_ALL_CRM_ACTIONS',
    UPDATE_CRM_ACTIONS_SYNC_DATA: 'UPDATE_CRM_ACTIONS_SYNC_DATA',
};

// actions
export const addCRMAction = (payload: any, optionalPayload?: any) => {
    newCRMActionsDb.crmActions.add({
        _id: new ObjectID().str,
        action: {
            _id: payload._id,
            type: payload.type,
            payload: optionalPayload ? optionalPayload : {},
        },
        timeOfaction: JSON.stringify(tz().tz('Europe/Berlin')),
        lastChangeDate: new Date(),
        synced: false,
    });
};

export const getAllCRMActions = () => async (dispatch: any) => {
    const crmActions = await newCRMActionsDb.crmActions.toArray();

    return dispatch({
        type: newCRMActionTypes.GET_ALL_CRM_ACTIONS,
        payload: crmActions,
    });
};
