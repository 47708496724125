import { Resource, RestEndpoint } from "@rest-hooks/rest";
import { IListFetchParams } from "../../components-ui/table";
import { EligibilityMapping } from "../../utils/eligibilityMapping";





//const type =  'sellout'



export class DealerRankResource extends Resource implements EligibilityMapping {


  readonly _id: string = '';
  readonly id: string = '';
  

  //static get urlRoot() { return `${window.BASE_URL}/dealerank` };

  static listUrl(searchParams?: IListFetchParams) {
    return `${window.BASE_URL}/dealerrank?${new URLSearchParams(searchParams)}`;
  }

  pk() {
    return this._id;
  }
  key() {
    return this.pk();
  }

  static get key(){
    return "Dealer Rank Table"
  }






  static dummyList<T extends typeof Resource>(this: T) {
    return super.list().extend({
      schema: [this],
      async fetch(this: RestEndpoint, { count, startId = 0 }: { count: number, startId?: number }) {
        const array: any[] = []
        for (startId; startId < count; startId++) {
          array.push({ _id: String(startId) })
        }
        return array;
      }
    })



  }

  



  static total<T extends typeof Resource>(this: T) {
    const endpoint = super.detail()
    return endpoint.extend({
      schema: { count: 0 },
      url(searchQuery) {
        return `${window.BASE_URL}/dealerrank?${new URLSearchParams(searchQuery)}&queryCount=true`;
      },
      fetch(this: RestEndpoint, params) {
        return endpoint.fetch.call(this, params).then((response: any) => {
          return { count: response.count }
        })
      }
    })
  }

 
// Helper function to sort ranks
// let sorted = ['C','A','A+','B','B+'].sort((a,b)=> {
//   let [achar,asign] = a.split("");
//   let [bchar,bsign] = b.split("");
//   if(achar !== bchar){    
//       return achar>bchar?1:-1;
//   }else{
//       return asign>bsign?1:-1;
//   }
  
// });








}

