import { eventEarningsTypes } from "./action.types"


const initalState: earningsState = {
    earnings: {},
    loading: false,
}


export const eventEarningsReducer = (state = initalState, { type, payload }: any) => {

    switch (type) {
        case eventEarningsTypes.LOAD_EARNINGS:
            return { ...state, earnings: payload, loading: false }

        case eventEarningsTypes.LOADING:
            return { ...state, loading: payload }

        case eventEarningsTypes.CLEAR_EARNINGS:
            return {
                ...state,
                earnings: {},
                loading: false,
            }

        default:
            return state;
    }

}