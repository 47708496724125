import {Reducer} from "redux";

import {
    EventAreaLocationActions,
    EventAreaLocationsActionTypes,
    EventLocation,
    EventProjectAreaLocationStore,
    EventStateTypeContainer
} from "./EventAreaLocationsType";
import {SmallEvent} from "../Events/SmallEventType";

export const initialEventAreaLocationState:EventStateTypeContainer= {
    loading:false,
    projectAreaLocations: null,
    areaLocations:null,
    projectLocationVisits: null
};



export const EventAreaLocationReducer: Reducer<EventStateTypeContainer, EventAreaLocationActions> = (
    state = initialEventAreaLocationState,
    action
  ) => {
    /**
     * the server response returned by the action,
     * must be inserted into the state container store!
     */
    const sortInAreaInsideAreaLocationItems = (state:EventStateTypeContainer, locationsResponse:EventLocation[],
                                               projectId:string, areaId:string) => {
        let currentStoreObject:EventProjectAreaLocationStore|null= state.projectAreaLocations;
        if(currentStoreObject!==null) {
            if(currentStoreObject.hasOwnProperty(projectId)===false) {
                //@ts-ignore
                currentStoreObject[projectId]={}
            }
            //@ts-ignore
            if(currentStoreObject[projectId].hasOwnProperty(areaId) === false) {
                //@ts-ignore
                currentStoreObject[projectId][areaId] = locationsResponse;
            }
        } else {
            //is null
            //@ts-ignore
            currentStoreObject={};
            currentStoreObject[projectId]={};
            //@ts-ignore
            currentStoreObject[projectId][areaId] = locationsResponse;
        }
        return currentStoreObject;
    };

    const sortInOldLocationVisits = (state:EventStateTypeContainer, locationVisitsResponse:SmallEvent[],
                                     projectId:string, locationId:string) => {
         let currentStoreObject = state.projectLocationVisits;
         if(currentStoreObject!==null) {
             if(currentStoreObject.hasOwnProperty(projectId)===false) {
                 //@ts-ignore
                 currentStoreObject[projectId]={}
             }
             //@ts-ignore
             if(currentStoreObject[projectId].hasOwnProperty(locationId) === false) { //this if is maybe useless?
                 //@ts-ignore
                 currentStoreObject[projectId][locationId] = locationVisitsResponse;
             } else {
                 currentStoreObject[projectId][locationId]=locationVisitsResponse;
             }
         } else {
             currentStoreObject={};
             currentStoreObject[projectId]={};
             currentStoreObject[projectId][locationId]=locationVisitsResponse;
         }
         return currentStoreObject;
    };



    switch (action.type) {
        //TODO CHANGE THE STATE
        case EventAreaLocationsActionTypes.LOADING: {
            return {
                ...state,
                loading: action.loading
            };
        }
        case EventAreaLocationsActionTypes.GETBYEVENTID: {
            const areaId:string =action.areaId;
            const projectId:string =action.projectId;
            const locationServerResponse=action.areaLocations;
            let eventLocationStore = state.projectAreaLocations;
            if(typeof locationServerResponse!=="undefined" && locationServerResponse!==null && locationServerResponse.length!==0) {
                eventLocationStore = sortInAreaInsideAreaLocationItems(state, locationServerResponse, projectId, areaId);
            }
            return {
                ...state,
                projectAreaLocations:eventLocationStore,
                loading:false
            };
        }
        case EventAreaLocationsActionTypes.LOAD_LOCATION_VISIT: {
            const projectId:string = action.projectId;
            const location:string = action.location;
            const visitsServerResponse=action.events;
            let eventVisitStore = state.projectLocationVisits;
            if(typeof visitsServerResponse !== "undefined" && visitsServerResponse!==null ) {
                eventVisitStore=sortInOldLocationVisits(state, visitsServerResponse, projectId, location);
            }
            return {
                ...state,
                projectLocationVisits:eventVisitStore,
                loading:false
            }
        }
    }
    return state;
}
