import Dexie, { Table } from 'dexie';

class ProductsDB extends Dexie {
    products!: Table<DexieProduct>;

    constructor() {
        super('productsDB');
        this.version(1).stores({
            products: `_id, product.productType`,
        });
    }
}

export const newProductDb = new ProductsDB();
