import newProductActionTypes from './actions';

// INITIAL STATE
const initialState: ProductStates = {
    loadedProducts: [],
    loadedProduct: undefined,
    loadedProductsForAutoComplete: [],
    forTransaction: [],
    syncData: {
        state: 'READY',
        lastUpdate: new Date(),
    },
};

// REDUCER

const productReducer = (state = { ...initialState }, action: any) => {
    if (action.type === newProductActionTypes.LOAD_PRODUCT_LIST) {
        return { ...state, loadedProducts: action.payload };
    }
    if (action.type === newProductActionTypes.LOAD_REPORT_PRODUCTS_LIST) {
        return { ...state, loadedProductsForReport: action.payload };
    }
    if (action.type === newProductActionTypes.LOAD_PRODUCT) {
        return { ...state, loadedProduct: action.payload };
    }
    if (action.type === newProductActionTypes.LOAD_PRODUCTS_AUTO_COMPLETE) {
        return { ...state, loadedProductsForAutoComplete: action.payload };
    }
    if (action.type === newProductActionTypes.CLEAR_AUTO_COMPLETE_LOAD) {
        return { ...state, loadedProductsForAutoComplete: [] };
    }
    if (action.type === newProductActionTypes.LOAD_PRODUCTS_FOR_TRANSACTION) {
        return { ...state, forTransaction: action.payload };
    }
    if (action.type === newProductActionTypes.CLEAR_FOR_TRANSACTION) {
        return { ...state, forTransaction: [] };
    }
    if (action.type === newProductActionTypes.UPDATE_PRODUCT_SYNC_DATA) {
        return { ...state, syncData: action.payload };
    }

    return state;
};

export default productReducer;
