import React, { useContext, useState } from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import { PWASyncControlContext } from '../../Services/pwaSyncControlContext';

export const Disclaimer = () => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const { startFullSync, deviceHashInfo } = useContext(PWASyncControlContext);

    const disclaimerObj = {
        disclaimerRead: true,
    };

    React.useEffect(() => {
        const legalDisclaimer = localStorage.getItem('legalDisclaimer');
        legalDisclaimer === null && deviceHashInfo.status === 'Checked' && setOpen(true);
    }, [deviceHashInfo.status]);

    return (
        <>
            {open && (
                <Dialog
                    key='legalDisclaimerDialog'
                    fullWidth
                    open={open}
                    // PaperProps={{
                    //     style: {
                    //         backgroundColor: '#424242',
                    //         color: 'white',
                    //     },
                    // }}
                    sx={{
                        padding: '2px',
                    }}
                >
                    <DialogTitle>{t('##PWADisclaimerMainHeadline')}</DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom variant='h6'>
                            {t('##PWADisclaimer1Headline')}
                        </Typography>
                        <Typography gutterBottom>{t('##PWADisclaimer1Text')}</Typography>
                        <Typography gutterBottom variant='h6'>
                            {t('##PWADisclaimer2Headline')}
                        </Typography>
                        <Typography gutterBottom>{t('##PWADisclaimer2Text')}</Typography>
                        <Typography gutterBottom variant='h6'>
                            {t('##PWADisclaimer3Headline')}
                        </Typography>
                        <Typography gutterBottom>{t('##PWADisclaimer3Text')}</Typography>
                        <Typography gutterBottom variant='h6'>
                            {t('##PWADisclaimer4Headline')}
                        </Typography>
                        <Typography gutterBottom>{t('##PWADisclaimer4Text')}</Typography>
                        <Alert severity='info'>
                            <Link
                                target='_blank'
                                underline='hover'
                                rel='noreferrer'
                                href='https://www.empplan.de/training-center/empplan-app-quickstart/'
                            >
                                {t('##EmpplanAppQuickStartGuide')}
                            </Link>
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={() => {
                                localStorage.setItem('legalDisclaimer', JSON.stringify(disclaimerObj));
                                setOpen(false);
                                startFullSync();
                                // snackbarTools.createToast({ type: 'success', message: t('##Done') });
                            }}
                        >
                            {t('##MarkAllAsRead')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};
