import joi from "joi"
export const ObjectReduceExtension:joi.ExtensionFactory = (joi) => {

  return {
    base: joi.object(),
    type: 'object',
    messages: {
      'object.reduce': '"{{#label}}" is being reduced to invalid value',
    },
    rules: {
      single: {
        method(reducer:any, schema:joi.AnySchema) {
          return this.$_addRule({name: 'single'}).$_setFlag('objectReduce', {reducer, description:schema.describe(), schema});
        },
        validate(value, {schema,error}, args) {
          const flag = schema.$_getFlag('objectReduce');
          const result =  flag.reducer(value);
          const validated = flag.schema.validate(result, args)
          if (validated.error) return error('object.reduce', {value})
          return validated.value
        }
    },
  }
}
}