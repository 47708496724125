import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { t } from 'i18next';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { mapkey } from '../../../conf/constants';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { CustomLocationByDistri } from './PointOfSaleAutocomplete';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

interface Props {
    value: string;
    openLocationDialog?: (googlePlaceValue: { places: google.maps.places.AutocompletePrediction[] | null; value: string }) => void;
}

function AreaEventGoogleSearch({ value, openLocationDialog }: Props) {
    const { placesService, getPlacePredictions, placePredictions } = usePlacesService({ apiKey: mapkey });
    const [loading, setLoading] = useState<boolean>(false);

    const fetchOptions = (input: string) => {
        setLoading(true);
        try {
            getPlacePredictions({ input });
        } catch (err) {
            console.error(err);
        } finally {
            const finalPlaces: google.maps.places.PlaceResult[] = [];
            if (placePredictions.length > 0) {
                openLocationDialog && openLocationDialog({ places: placePredictions, value: input });
            } else {
                openLocationDialog && openLocationDialog({ places: null, value: input });
            }
            setLoading(false);
        }
    };
    return (
        <Box display='flex' flexDirection='column' gap={2}>
            <Typography>
                <strong>{t('##NoLocationInEmpplan')}</strong>
            </Typography>
            {loading ? (
                <LoadingButton loading fullWidth loadingPosition='start' startIcon={<SaveIcon />} variant='outlined'>
                    {t('##FindViaGooglePlaces')}
                </LoadingButton>
            ) : (
                <Button
                    fullWidth
                    color='secondary'
                    startIcon={<LocationOnIcon />}
                    onClick={() => {
                        fetchOptions(value);
                    }}
                    disabled={loading}
                    variant='contained'
                >
                    {t('##FindViaGooglePlaces')}
                </Button>
            )}
        </Box>
    );
}

export default AreaEventGoogleSearch;
