import * as React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    separator: {
        whiteSpace: 'pre-line',
        '&:after': {
            content: "', '",
        },
        '&:last-child': {
            '&:after': {
                content: "' '",
            },
        },
    },
}));
type ILocation = {
    name: string;
    address?: {
        streetnumber?: string;
        postal?: string;
        city?: string;
    };
};

export const getFullAddress = (location: ILocation): string => {
    const locationName = location?.name || '';
    const streetNumber = location?.address?.streetnumber || '';
    const postal = location?.address?.postal || '';
    const city = location?.address?.city || '';
    return locationName + ', ' + streetNumber + ', ' + postal + ' ' + city;
};

export const getShortAddress = (location: ILocation): string => {
    const locationName = location?.name || '';
    const city = location?.address?.city || '';
    return locationName + ', ' + city;
};

export function PointOfSaleView(props: any) {
    return (
        <span>
            <span style={{ whiteSpace: 'pre-wrap' }}>
                {props.shorten ? getShortAddress(props.event.point_of_sale) : getFullAddress(props.event.point_of_sale)}
            </span>
        </span>
    );
}
export function AreaView(props: any) {
    const { separator } = useStyles();
    // console.log({ event });
    const event = props.event;
    const shortVersion = props.shorten || false;
    return (
        <span>
            {event.area?.name ? (
                // First look for `event.area`
                <>
                    <span className={separator}>{event.area?.name}</span>
                    {!shortVersion && (
                        <>
                            {event.area?.channel && event.area?.channel?.brand?.name && (
                                <span className={separator}>{event.area?.channel?.brand?.name}</span>
                            )}
                            {Array.isArray(event.area?.channel) &&
                                event.area?.channel?.map(
                                    (channel: any) =>
                                        Array.isArray(channel) &&
                                        channel.map((brand: any) => (
                                            <>
                                                <span>{brand?.name}</span>
                                                {Array.isArray(brand) &&
                                                    brand.map((brand: any) => <span> {brand?.name}</span>)}
                                            </>
                                        )),
                                )}
                        </>
                    )}
                </>
            ) : (
                // If event.area not available, then go for `event.pointOfSaleProjectStructure.area`
                <>
                    <span className={separator}>{event.pointOfSaleProjectStructure?.area?.name}</span>
                </>
            )}
        </span>
    );
}
export function GoogleLocationView(props: any) {
    return (
        <span style={{ whiteSpace: 'pre-wrap' }}>
            {props.shorten ? getShortAddress(props.event.google) : getFullAddress(props.event.google)}
        </span>
    );
}
export function LocationView(props: { event: any; noHyperlink?: boolean; shorten?: boolean }) {
    const event = props.event;
    const noHyperlink = props.noHyperlink;
    const shorten = props.shorten;

    const useStyles = makeStyles({
        greenlink: {
            color: 'rgb(36,192,177)!important',
            textDecoration: 'none',
            fontWeight: 'bold',
            cursor: 'pointer',
            background: 'transparent',
            border: 'none',
            display: 'flex',
            placeItems: 'center',
            gap: '0.5rem',
            width: '100%',
            height: '100%',
        },
    });
    const classes = useStyles();
    let linkUrl = '';
    const isAdmin = window.user?.roles?.indexOf('EventAdd') !== -1;
    if (event.point_of_sale) {
        if (isAdmin) {
            linkUrl = '#!/posmanagement/pointofsale/' + event.point_of_sale._id + '/edit';
        } else {
            linkUrl =
                'https://maps.google.com/?q=' +
                ((event.point_of_sale.address?.postal && event.point_of_sale.address?.postal + ' ') || '') +
                ((event.point_of_sale.address?.city && event.point_of_sale.address?.city + ' ') || '') +
                ((event.point_of_sale.address?.streetnumber && event.point_of_sale.address?.streetnumber + ' ') || '');
        }
        return noHyperlink ? (
            <PointOfSaleView event={event} shorten={shorten} />
        ) : (
            <a href={linkUrl} target='_blank' className={classes.greenlink} rel='noreferrer'>
                <PointOfSaleView event={event} shorten={shorten} />
            </a>
        );
    } else if (event.area) {
        return <AreaView event={event} shorten={shorten} />;
    }
    if (event.google) {
        if (isAdmin) {
            linkUrl = '#!/posmanagement/pointofsale/' + event.google._id + '/edit';
        } else {
            linkUrl =
                'https://maps.google.com/?q=' +
                ((event.google.address?.postal && event.google.address?.postal + ' ') || '') +
                ((event.google.address?.city && event.google.address?.city + ' ') || '') +
                ((event.google.address?.streetnumber && event.google.address?.streetnumber + ' ') || '');
        }
        return noHyperlink ? (
            <GoogleLocationView event={event} shorten={shorten} />
        ) : (
            <a href={linkUrl} target='_blank' className={classes.greenlink} rel='noreferrer'>
                <GoogleLocationView event={event} shorten={shorten} />
            </a>
        );
    } else {
        return <span> - </span>;
    }
}
