import { newCustomReportResultDb } from './newCustomReportResultDexie';
import {
    createNewCustomReportResultWrapperForDexie,
    getCustomReportResultsFetch,
    syncAllCustomReportResults,
} from './helpers';
import ObjectID from 'bson-objectid';
import { Collection, IndexableType, Table } from 'dexie';
import { isDeviceOnline } from '../../Services/OnlineStatus';

const newCustomReportResultActionTypes = {
    NEW_CUSTOMREPORTRESULT: 'NEW_CUSTOMREPORTRESULT',
    UPDATE_CUSTOMREPORTRESULT: 'UPDATE_CUSTOMREPORTRESULT',
    LOAD_CUSTOMREPORTRESULT_LIST: 'LOAD_CUSTOMREPORTRESULT_LIST',
    LOAD_CUSTOMREPORTRESULT: 'LOAD_CUSTOMREPORTRESULT',
    UPDATE_CUSTOMREPORTRESULT_SYNC_DATA: 'UPDATE_CUSTOMREPORTRESULT_SYNC_DATA',
    CLEAR_LOAD_CUSTOMREPORTRESULT_LIST: 'CLEAR_LOAD_CUSTOMREPORTRESULT_LIST',
};

export default newCustomReportResultActionTypes;

export const createNewCustomReportResultAction = (customReportResult: DexieCustomReportResult) => {
    newCustomReportResultDb.customReportResults.add(customReportResult);
    return {
        type: newCustomReportResultActionTypes.NEW_CUSTOMREPORTRESULT,
        payload: customReportResult,
    };
};

export const initNewCustomReportResultAction = (customReportResult: any) => (dispatch: any) => {
    if (customReportResult) {
        if (!customReportResult._id) {
            customReportResult._id = new ObjectID().str;
        }
        newCustomReportResultDb.customReportResults.add({
            _id: customReportResult._id,
            customReportResult: customReportResult,
            syncDate: new Date(),
            event: customReportResult._event,
            syncPending: 1,
            isNewDoc: 1,
            syncError: 0,
            customReportId: customReportResult?._customReport?._refconfig,
        });
    }
};

export const updateNewCustomReportResultAction = (customReportResult: any) => (dispatch: any) => {
    if (customReportResult._id) {
        newCustomReportResultDb.customReportResults.update(customReportResult._id, {
            customReportResult: customReportResult,
            syncPending: 1,
        });
    }
};

export const loadAndInitCustomReportResultAction = (
    customReports: Array<any>,
    eventId: string,
    projectId: string,
) => async (dispatch: any) => {
    if (Array.isArray(customReports)) {
        // first we load all the saved reports
        const savedCRRs = await newCustomReportResultDb.customReportResults
            .filter(eachResult => eachResult.event === eventId)
            .toArray();

        if (savedCRRs.length === 0 && isDeviceOnline()) {
            const apiCRRs = await getCustomReportResultsFetch([eventId], 0);
            console.log({ apiCRRs });
            if (apiCRRs.success && apiCRRs.count && Array.isArray(apiCRRs.data)) {
                console.log('Using the API custom report results for this event');
                return dispatch({
                    type: newCustomReportResultActionTypes.LOAD_CUSTOMREPORTRESULT,
                    payload: apiCRRs.data.map((crr: any) => createNewCustomReportResultWrapperForDexie(crr)),
                });
            }
        }

        customReports.forEach(cr => {
            if (savedCRRs.find(scrr => scrr.customReportId === cr._id)) {
                // we do nothing since it's there
            } else {
                dispatch(
                    initNewCustomReportResultAction({
                        reportResults: {},
                        _customReport: {
                            _createdTimeConfig: cr.customReport,
                            _refconfig: cr.customReport._id,
                        },
                        _event: eventId,
                        _project: projectId,
                    }),
                );
            }
        });

        const finalSavedCRRs = await newCustomReportResultDb.customReportResults
            .filter(eachResult => eachResult.event === eventId)
            .toArray();

        return dispatch({
            type: newCustomReportResultActionTypes.LOAD_CUSTOMREPORTRESULT,
            payload: finalSavedCRRs,
        });
    }
    return dispatch({
        type: newCustomReportResultActionTypes.LOAD_CUSTOMREPORTRESULT,
        payload: undefined,
    });
};

export const loadCustomReportResultAction = (customReport: any, eventId?: string, optionalPayload?: any) => async (
    dispatch: any,
) => {
    const mainQuery = newCustomReportResultDb.customReportResults;
    if (customReport && eventId) {
        const reportResult = mainQuery.filter(
            eachResult => eachResult.customReportId === customReport._id && eachResult.event === eventId,
        );
        const final = await reportResult.toArray();
        const finalObj = final[0];
        if (finalObj && Object.keys(finalObj).length > 0) {
            return dispatch({
                type: newCustomReportResultActionTypes.LOAD_CUSTOMREPORTRESULT,
                payload: finalObj,
            });
        } else {
            return dispatch(initNewCustomReportResultAction(optionalPayload));
        }
    } else {
        return dispatch({
            type: newCustomReportResultActionTypes.LOAD_CUSTOMREPORTRESULT,
            payload: undefined,
        });
    }
};

export const loadCustomReportResultListAction = (filterOptions: { eventId?: string }) => async (dispatch: any) => {
    type DexieEventQueryBuilder =
        | Collection<DexieCustomReportResult, IndexableType>
        | Table<DexieCustomReportResult, IndexableType>;

    let reportResultQuery: DexieEventQueryBuilder = newCustomReportResultDb.customReportResults;

    if (filterOptions?.eventId !== undefined) {
        reportResultQuery = reportResultQuery.where('event').equals(filterOptions.eventId);
    }

    return dispatch({
        type: newCustomReportResultActionTypes.LOAD_CUSTOMREPORTRESULT_LIST,
        payload: await reportResultQuery.toArray(),
    });
};

export const clearLoadCustomReportResultList = () => {
    return {
        type: newCustomReportResultActionTypes.CLEAR_LOAD_CUSTOMREPORTRESULT_LIST,
    };
};

export const startCustomReportResultSyncAction = (projectIds: Array<string>) => (dispatch: any) => {
    dispatch({
        type: newCustomReportResultActionTypes.UPDATE_CUSTOMREPORTRESULT_SYNC_DATA,
        payload: {
            state: 'DOWNLOADING',
            lastUpdate: new Date(),
        },
    });
    syncAllCustomReportResults(dispatch, projectIds);
};
