import * as React from 'react';
import { useDispatch } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import MenuIcon from '@mui/icons-material/Menu';

import DynTableConst from '../../Services/Constants';
import SyncButton from '../../pages/Dashboard/SyncButton';
import { openMenu } from './../../Models/MenuStates/MenuActions';
import { Badge } from '@mui/material';
import { PWAScheduledTasksContext } from '../../Services/pwaScheduledTasksContext';

export default function() {
    const dispatch: any = useDispatch();
    const { unreadNotifications } = React.useContext(PWAScheduledTasksContext);

    return (
        <Box height={'65px'}>
            <AppBar position='fixed' sx={{ height: 'inherit' }} elevation={0}>
                <Toolbar sx={{ bgcolor: '#424242' }}>
                    <IconButton
                        color='inherit'
                        aria-label='Open drawer'
                        sx={{ mr: 2 }}
                        onClick={() => {
                            dispatch(openMenu());
                        }}
                    >
                        <Badge variant='dot' badgeContent={unreadNotifications}>
                            <MenuIcon />
                        </Badge>
                    </IconButton>

                    <Box
                        component='img'
                        sx={{
                            height: 10,
                            display: 'flex',
                            flexGrow: 1,
                        }}
                        src={DynTableConst.EMPPLAN_HOST + '/empplantheme/assets/img/empplan-svg-font-white.svg'}
                    />

                    <SyncButton />
                </Toolbar>
            </AppBar>
        </Box>
    );
}
