import API from '../../../config/api';
import { IEvent } from '../../../utils/event';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import EmpplanSnackbarWrapper, { EmpplanSnackbarContext } from '../../../modules/Snackbar';
import { useContext, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import DownloadIcon from '@mui/icons-material/Download';
import { PWAContext } from '../../../PWA/pwaContext';

interface Props {
    event: IEvent;
    setEvent: any;
    handleDeleteEventPwa?: (id: string | number) => void;
}

function EventDelete({ event, setEvent, handleDeleteEventPwa }: Props) {
    const { t } = useTranslation();
    const snackbarTools = useContext(EmpplanSnackbarContext);
    const pwaContext = useContext(PWAContext);

    const [loading, setLoading] = useState(false);

    const handleDelete = (e: IEvent) => {
        setLoading(true);
        API.post(`/event/${e._id}/deleteEvents/promoter`, {})
            .then(response => {
                return response.json();
            })
            .then(resData => {
                if (resData.status && resData.message) {
                    if (pwaContext?.pwa) {
                        handleDeleteEventPwa && handleDeleteEventPwa(e._id);
                    }
                    setEvent((e: any) => ({ ...e, eventState: 'Deleted' }));
                    snackbarTools.createToast({ type: 'success', message: t('##Deleted') });
                } else {
                    snackbarTools.createToast({ type: 'error', message: t('##ErrorWhileDeleting') });
                }
                setLoading(false);
            })
            .catch(() => {
                snackbarTools.createToast({ type: 'error', message: t('##ErrorWhileDeleting') });
                setLoading(false);
            });
    };
    return (
        <EmpplanSnackbarWrapper>
            {loading ? (
                <LoadingButton
                    loading
                    loadingPosition='start'
                    startIcon={<DownloadIcon />}
                    variant='text'
                    color='error'
                    size='small'
                >
                    <span>{t('##DeleteEvent')}</span>
                </LoadingButton>
            ) : (
                <Button
                    variant='text'
                    color='error'
                    size='small'
                    onClick={() => {
                        handleDelete(event);
                    }}
                >
                    {t('##DeleteEvent')}
                </Button>
            )}
        </EmpplanSnackbarWrapper>
    );
}

export default EventDelete;
