import React, { useContext } from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReportConfigurationContext } from '../index';
import ToolTipComponent from '../ToolTipComponent';
import { requireHelper } from '../utils/requireHelper';

export interface CheckBoxComponentProps {
    field: CustomReportField;
    reportData: ReportData;
    onChangeHandler: any;
}

export const CheckBoxComponent = ({ field, reportData, onChangeHandler }: CheckBoxComponentProps) => {
    const { t } = useTranslation();
    const { isDisabled } = useContext(ReportConfigurationContext);
    const requiredCondition = requireHelper(field, reportData);

    const value = (reportData[field.config._id] || []) as Array<string>;

    const handleCheckBoxChange = (e: any, key: ReportFieldKey) => {
        let checkboxValue = (reportData[key] || []) as Array<string>;
        if (e.target.checked) {
            checkboxValue = [...checkboxValue, e.target.name];
        } else {
            checkboxValue = checkboxValue.filter(item => item !== e.target.name);
        }
        onChangeHandler(key, checkboxValue);
    };

    return (
        <FormControl data-testid='checkBox'>
            <FormLabel>
                {field.config.slug ? t(field.config.slug) : field.config.name}{' '}
                {requiredCondition && (
                    <Box component='span' sx={{ color: 'red' }}>
                        {'  '}({t('##TheFieldIsRequired')})
                    </Box>
                )}
                {field.config?.toolTipTxt !== '' && (
                    <ToolTipComponent
                        enableHintOnClick={field.config.enableHintOnClick}
                        tooltipText={field.config.toolTipTxt}
                    />
                )}
            </FormLabel>
            <FormGroup>
                <div
                    style={{
                        display: field.showInSameLine ? 'flex' : undefined,
                        flexDirection: field.showInSameLine ? 'row' : undefined,
                    }}
                >
                    {field.config?.fieldObject?.options?.map((checkboxItem: string) => (
                        <FormControlLabel
                            key={checkboxItem}
                            label={t(checkboxItem)}
                            value={value?.includes(checkboxItem)}
                            control={
                                <Checkbox
                                    name={checkboxItem}
                                    data-testid={checkboxItem}
                                    checked={!!value?.includes(checkboxItem)}
                                    disabled={isDisabled || field.isReadonly}
                                    onChange={e => {
                                        e.stopPropagation();
                                        handleCheckBoxChange(e, field.config._id);
                                    }}
                                />
                            }
                        />
                    ))}
                </div>
                {field.hintText && value?.length ? (
                    <div
                        style={{
                            color: field.hintTextColor ? field.hintTextColor : undefined,
                        }}
                    >
                        {field.hintText}
                    </div>
                ) : null}
            </FormGroup>
        </FormControl>
    );
};
