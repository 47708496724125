import moment, { Moment } from 'moment';
import { getCurrentLocaleString } from '../config/translate';

export const defaultDateFormat = 'DD.MM.YYYY';
export const defaultDateTimeFormat = 'DD.MM.YYYY HH:mm';
export function formatDate(date: Moment | string | Date, format = defaultDateFormat): string {
    try{
        date = moment(date).locale(getCurrentLocaleString()).tz('Europe/Berlin');
        return date.format(format);
    }catch(e){
        return date.toString()
    }
    
}

export function formatDateTime(date: Moment | string | Date, format = defaultDateTimeFormat): string {
    try{
        date = moment(date).locale(getCurrentLocaleString()).tz('Europe/Berlin');
        return date.format(format);
    }catch(e){
        return date.toString()
    }

}
