import Dexie, { Table } from 'dexie';

class FileSystemsDB extends Dexie {
    fileSystems!: Table<DexieFileSystem>;

    constructor() {
        super('fileSystemsDB');
        this.version(1).stores({
            fileSystems: `_id, context.eventId`,
        });

        this.version(2).stores({
            fileSystems: `_id`,
        });
    }
}

export const newFileSystemDb = new FileSystemsDB();
