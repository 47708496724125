import {Box, Typography,Skeleton} from "@mui/material";
import {ensureLanguageInfo, useTranslationSafe} from "../../config/translate";
import {useEffect, useMemo, useState,} from "react";

export interface LanguageViewProps {
  value: string;
}
export function LanguageView(props: LanguageViewProps) {
  const [langInfo, setLangInfo] = useState<ILanguageInfo>();
  const {t} = useTranslationSafe()
  useEffect(() => {
    ensureLanguageInfo().then((lang)=> {
      // @ts-ignore
      setLangInfo(lang[props.value]);
    });
  },[])
    return useMemo(()=> <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '1rem',
      width: '100%',
    }}>
        {langInfo ? <img
        title={langInfo.country}
        loading={"lazy"}
        width={20}
        src={`https://flagcdn.com/w20/${langInfo.country_code.toLowerCase()}.png`}
        srcSet={ `https://flagcdn.com/w40/${langInfo.country_code.toLowerCase()}.png 2x`}
        alt=""
        />:<Skeleton><img
          width={20}
        /></Skeleton>}
      <Typography>{t('##'+props.value?.toUpperCase())}</Typography></Box>,[langInfo,props.value]);
}