import { newProjectLocationsDb } from './newProjectLocationsDexie';
import { createNewprojectLocationWrapperForDexie, getProjectLocationsFetch, syncAllProjectLocations } from './helpers';
import { Collection, IndexableType, Table } from 'dexie';
import { isDeviceOnline } from '../../Services/OnlineStatus';

const newProjectLocationActionTypes = {
    NEW_PROJECT_LOCATION: 'NEW_PROJECT_LOCATION',
    LOAD_PROJECT_LOCATION_LIST: 'LOAD_PROJECT_LOCATION_LIST',
    LOAD_PROJECT_LOCATION: 'LOAD_PROJECT_LOCATION',
    UPDATE_PROJECT_LOCATION_SYNC_DATA: 'UPDATE_PROJECT_LOCATION_SYNC_DATA',
    CLEAR_PROJECT_LOCATION_LIST: 'CLEAR_PROJECT_LOCATION_LIST',
    LOAD_BRANDS_OF_PROJECT: 'LOAD_BRANDS_OF_PROJECT',
    CLEAR_BRANDS_OF_PROJECT: 'CLEAR_BRANDS_OF_PROJECT',
};

export default newProjectLocationActionTypes;

type DexieProjectLocationQueryBuilder =
    | Collection<DexieProjectLocation, IndexableType>
    | Table<DexieProjectLocation, IndexableType>;

export const createNewProjectLocationAction = (project: DexieProjectLocation) => {
    newProjectLocationsDb.projectLocations.add(project);
    return {
        type: newProjectLocationActionTypes.NEW_PROJECT_LOCATION,
        payload: project,
    };
};

export const loadProjectLocationForBrandsAction = (projectId: string, pointOfSaleIds?: string) => async (
    dispatch: any,
) => {
    let projectLocations = await newProjectLocationsDb.projectLocations
        .filter(loc => loc.projectLocation?.projectId === projectId)
        .toArray();

    if (projectLocations.length === 0 && isDeviceOnline()) {
        const projLocRes = await getProjectLocationsFetch({ projectIds: projectId, pointOfSaleIds }, 0);
        if (projLocRes.success && projLocRes.count && Array.isArray(projLocRes.data)) {
            projectLocations = projLocRes.data.map((pl: any) => createNewprojectLocationWrapperForDexie(pl));
        }
    }

    // we dont have a distinct property is dexie ?
    const set = new Set();

    projectLocations.forEach((location: DexieProjectLocation) => {
        return set.add(location?.projectLocation?.channel?.brandId);
    });

    return dispatch({
        type: newProjectLocationActionTypes.LOAD_BRANDS_OF_PROJECT,
        payload: [...set],
    });
};

export const clearProjectLocationForBrandsAction = () => {
    return {
        type: newProjectLocationActionTypes.CLEAR_BRANDS_OF_PROJECT,
    };
};

export const loadProjectLocationsListAction = (filterOptions: { name?: string }) => async (dispatch: any) => {
    let projectLocations: DexieProjectLocationQueryBuilder = newProjectLocationsDb.projectLocations;

    return dispatch({
        type: newProjectLocationActionTypes.LOAD_PROJECT_LOCATION_LIST,
        payload: await projectLocations.toArray(),
    });
};

export const clearProjectLocationListAction = () => {
    return {
        type: newProjectLocationActionTypes.CLEAR_PROJECT_LOCATION_LIST,
    };
};

export const startProjectLocationSyncAction = (projectIds: string, pointOfSaleIds: string) => (dispatch: any) => {
    dispatch({
        type: newProjectLocationActionTypes.UPDATE_PROJECT_LOCATION_SYNC_DATA,
        payload: {
            state: 'DOWNLOADING',
            lastUpdate: new Date(),
        },
    });
    syncAllProjectLocations(dispatch, projectIds, pointOfSaleIds);
};
