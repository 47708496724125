import React, {useContext, useEffect, useRef} from 'react';
import {Autocomplete, Box, FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FieldConfigContext} from './index';

import {getValidOperatorsForFieldType} from './helpers';
import {
    chipFieldTypes,
    numberFieldTypes,
    productFieldTypes,
    productGroupFieldTypes,
    selectFieldTypes,
    stringFieldTypes,
    valueAtRefNotRequiredOperators,
} from './constants';
import ChipsViewEdit from '../elements/Chips/ChipsView';
import ProductSelect from '../ProductSelect';
import AsynchronousAutoComplete from '../elements/AsynchronousAutoComplete';
import {IProductGroup} from '../../utils/ProductGroup';
import eventEnum from "../../conf/eventConstants";
import API from '../../config/api';


export default function FieldConditionComponent({
                                                    currentValue,
                                                    onChange: onUpdate,
                                                    ftype,
                                                    feildValue,
                                                    disabledOptions
                                                }: {
    currentValue: FieldCondition;
    onChange: (newValue: FieldCondition) => void;
    ftype?: String;
    feildValue?: String;
    disabledOptions?: Array<string>
}) {
    const {t} = useTranslation();
    // note: do not directly modify the context directly,
    // it will modify in angular also and cause issues
    let reportContextObj = useContext(FieldConfigContext);
    let reportContext = reportContextObj?.payload;
    let allCustomFeilds: any = {};
    const [selectedChipsIdsInt, setselectedChipsIds] = React.useState<string[]>([]);
    const [fieldType, setFieldType] = React.useState('');
    const [currentfieldConfig, setCurrentfieldConfig] = React.useState<any>();
    const [currentfieldType, setCurrentfieldType] = React.useState('');
    const [selectedfieldType, setSelectedfieldType] = React.useState({});
    const initialRef = useRef(0);
    const [validOperators, setValidOperators] = React.useState<{
        mongooseOperator: string | number | readonly string[] | undefined; operator: string; slug: string
    }[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [eventLabels, setEventLabels] = React.useState<any>([]);
    const [teamsList, setTeamsList] = React.useState<any>([]);

    const [leadStatusList, setLeadStatusList] = React.useState<any>([]);
    const [crmLabels, setCRMlabels] = React.useState<any>([]);
    const [userList, setUserList] = React.useState<any>([]);

    let eventStates = React.useMemo(() => {
        return (eventEnum.eventState as string[]).map(state => ({name: t(`##${state}`), _id: state}));
    }, [t]);
    let invoiceStates = React.useMemo(() => {
        return (eventEnum.invoiceState as string[]).map(state => ({name: t(`##${state}`), _id: state}));
    }, [t])
    const getChipLabel = (el: IChipElement): string => t(el && el.name) || '';

    function changeSelectedChips(arg: IChipElement) {
        let tempCa: any = selectedChipsIdsInt.slice(); // We need to make a copy otherwise it will not update!
        if (arg._id) {
            if (tempCa.indexOf(arg._id.toString()) !== -1) {
                tempCa.splice(tempCa.indexOf(arg._id.toString()), 1);
            } else {
                tempCa.push(arg._id.toString());
            }
        }
        setselectedChipsIds(tempCa);
        //console.log("selectedChipsIdsInt",selectedChipsIdsInt);
        // const obj = { ...currentValue };
        // obj.condition.value = selectedChipsIdsInt;
        // onUpdate(obj);
        updateValue(selectedChipsIdsInt);
    }

    function deleteSelectedChips(arg: IChipElement) {
        let tempCa: any = selectedChipsIdsInt.slice(); // We need to make a copy otherwise it will not update!
        var index = tempCa.indexOf(arg._id);
        if (index > -1) {
            tempCa = selectedChipsIdsInt.slice(index);
        }
        setselectedChipsIds(tempCa);
        // const obj = { ...currentValue };
        // obj.condition.value = selectedChipsIdsInt;
        // onUpdate(obj);
        updateValue(selectedChipsIdsInt);
    }

    const handleAutoCompleteChange = (value: any, type: string) => {
        const obj = {...currentValue};
        obj.condition.value = value;
        onUpdate(obj);
    };
    const handleValueChange = (value: any, type: string) => {
        value = value.map((a: { _id: any; name: any }) => ({_id: a._id, name: a.name}))
        const obj = {...currentValue};
        obj.condition.value = value;
        onUpdate(obj);
    };

    // removes the current field
    reportContext = JSON.parse(JSON.stringify(reportContext));
    //console.log('reportContext', reportContext);

    //let fieldType = 'field';
    if (typeof reportContext.customReport !== 'undefined') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(() => {
            if (initialRef.current > 4) {
                return;
            }

            if (typeof reportContext.customReport !== 'undefined') {
                delete reportContext?.customReport[reportContext?.currentId];
                setFieldType(reportContext.customReport[currentValue.refField]?.config?.fieldType);
                const validOperator = getValidOperatorsForFieldType(reportContext.customReport[currentValue.refField]?.config?.fieldType);
                setValidOperators(validOperator);
                initialRef.current++;
            }
        }, [reportContext.customReport[currentValue.refField]?.config?.fieldType]);
    }

    //For Events and CRM Labels
    const fetchEventLabels = async () => {
        let usedForValue = 'Event';
        try {
            setLoading(true);
            const resp = await API.get(`/projects/customstatus/filter?order=custom_status&skip=0&project_id=all`);
            const result = await resp.json();
            if (result.success) {
                const customList = result.data.customs;
                if (Array.isArray(customList)) {
                    const filteredLabels = customList.filter((label: any) => {
                        const usedFor = label?.usedFor || [];
                        return usedFor.includes(usedForValue);
                    }).map((label: any) => ({
                        _id: label._id,
                        name: label.statusLabel
                            ? label.type === 'EMPPLAN'
                                ? t(label.slangText)
                                : t(label.statusLabel)
                            : t(label.custom_status),
                    }));
                    setEventLabels(filteredLabels);
                    
                    //For CRM custom labels
                    const crmFilteredLabels = customList.filter((label: any) => {
                        const usedFor = label?.usedFor || [];
                        return usedFor.includes('CRM');
                    }).map((label: any) => ({
                        _id: label._id,
                        name: label.statusLabel
                            ? label.type === 'EMPPLAN'
                                ? t(label.slangText)
                                : t(label.statusLabel)
                            : t(label.custom_status),
                    }));
                    setCRMlabels(crmFilteredLabels);
                }
            }
        } catch (error) {
            console.error('Error loading event labels', error);
        } finally {
            setLoading(false);
        }
    };

    //For LeadStatusesList
    const fetchLeadStatus = async () => {
        let usedForValue = 'Event';
        try {
            setLoading(true);
            const resp = await API.get(`/crmCustomLeadStatusList?active=true`);
            const result = await resp.json();

            if (Array.isArray(result)) {
                const filteredLabels = result.map((label: any) => ({
                    _id: label._id,
                    name: label.statusLabel
                        ? label.type === 'EMPPLAN'
                            ? t(label.slangText)
                            : t(label.statusLabel)
                        : t(label.custom_status),
                }));
                setLeadStatusList(filteredLabels);
            }
        } catch (error) {
            console.error('Error loading event labels', error);
        } finally {
            setLoading(false);
        }
    };

    //For UserList
    const fetchUserList = async () => {
        try {
            setLoading(true);
            const resp = await API.get(`/users/search/list`);
            const result = await resp.json();

            if (Array.isArray(result)) {
                const finallist = result?.map((item: any) => {
                    item.name = item?.firstName + ' ' + item?.lastName + ' (' + item?.email + ')';
                    return item;
                });
                setUserList(finallist);
            }
        } catch (error) {
            console.error('Error loading event labels', error);
        } finally {
            setLoading(false);
        }
    }

    const fetchTeamsList = async () => {
        try {
            setLoading(true);
            const resp = await API.get(`/teams`);
            const result = await resp.json();
            if (result) {
                setTeamsList(result);
            }
        } catch (error) {
            console.error('Error loading teams', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEventLabels();
        fetchTeamsList();
        fetchLeadStatus();
        fetchUserList();
    }, []);


    const updateOperator = (newValue: string) => {
        const obj = {...currentValue};
        obj.condition.operator = newValue;
        onUpdate(obj);
    };

    const updateValue = (newValue: any) => {
        const obj = {...currentValue};
        obj.condition.value = newValue;
        onUpdate(obj);
    };

    const updateField = (newValue: any, fieldType: any) => {
        console.log('newValue', newValue);
        const obj = {...currentValue};
        if (newValue === 'product') {
            const validOperator = getValidOperatorsForFieldType('product');
            setValidOperators(validOperator);
            setFieldType('product');
            obj.filterType = 'product';
        } else if (newValue === 'event_state') {
            const validOperator = getValidOperatorsForFieldType('eventState');
            setValidOperators(validOperator);
            setFieldType('eventState');
            obj.filterType = 'event_state';
        } else if (newValue === 'event_label') {
            const validOperator = getValidOperatorsForFieldType('eventLabel');
            setValidOperators(validOperator);
            setFieldType('eventLabel');
            obj.filterType = 'event_label';
        } else if (newValue === 'team') {
            const validOperator = getValidOperatorsForFieldType('teams');
            setValidOperators(validOperator);
            setFieldType('teams');
            obj.filterType = 'team';
        } else if (newValue === 'salesItem_invoice_status') {
            const validOperator = getValidOperatorsForFieldType('invoiceStatus');
            setValidOperators(validOperator);
            setFieldType('invoiceStatus');
            obj.filterType = 'salesItem_invoice_status';
        } else if (newValue === 'product_group') {
            const validOperator = getValidOperatorsForFieldType('productGroup');
            setValidOperators(validOperator);
            setFieldType('productGroup');
            obj.filterType = 'product_group';
        } else if (newValue === 'lead_status') {
            const validOperator = getValidOperatorsForFieldType('leadStatus');
            setValidOperators(validOperator);
            setFieldType('leadStatus');
            obj.filterType = 'lead_status';
        } else if (newValue === 'crm_label') {
            const validOperator = getValidOperatorsForFieldType('crmLabel');
            setValidOperators(validOperator);
            setFieldType('crmLabel');
            obj.filterType = 'crm_label';
        } else if (newValue === 'userId') {
            const validOperator = getValidOperatorsForFieldType('userID');
            setValidOperators(validOperator);
            setFieldType('userID');
            obj.filterType = 'userId';
        } else if (newValue === 'updated_by_user') {
            const validOperator = getValidOperatorsForFieldType('updateByUser');
            setValidOperators(validOperator);
            setFieldType('updateByUser');
            obj.filterType = 'updated_by_user';
        } else if (newValue === 'created_by') {
            const validOperator = getValidOperatorsForFieldType('createdByUser');
            setValidOperators(validOperator);
            setFieldType('createdByUser');
            obj.filterType = 'created_by';
        } else if (newValue === 'number_of_rings') {
            obj.filterType = 'number_of_rings';
        } else if (newValue === 'total_revenue_of_salesItem') {
            obj.filterType = 'salesItem_total_revenue';
        } else if (newValue === 'total_qty_of_salesItem') {
            obj.filterType = 'salesItem_total_qty';
        } else {
            if (!fieldType) {
                if (typeof reportContext.customReport !== 'undefined') {
                    delete reportContext?.customReport[reportContext?.currentId];
                    setFieldType(reportContext.customReport[currentValue.refField]?.config?.fieldType);
                    console.log("????", reportContext.customReport[currentValue.refField]?.config?.fieldType)
                    const validOperator = getValidOperatorsForFieldType(fieldType);
                    setValidOperators(validOperator);
                } else {
                    if ((typeof ftype !== 'undefined' && ftype === 'widget')) {
                        setFieldType('number');
                        const validOperator = getValidOperatorsForFieldType('number');
                        setValidOperators(validOperator);
                    } else {
                        setFieldType('stringSmall');
                        const validOperator = getValidOperatorsForFieldType('stringSmall');
                        setValidOperators(validOperator);
                    }
                }
            } else {
                setFieldType(fieldType);
                const validOperator = getValidOperatorsForFieldType(fieldType);
                setValidOperators(validOperator);
            }

        }


        if (allCustomFeilds[newValue]) {
            let objValue = allCustomFeilds[newValue];

            obj.refField = objValue;
            obj.refFields = objValue;
            obj.filterType = 'customfield'
        } else {
            obj.refField = newValue;
        }


        // reset values of operator and value
        if ((typeof ftype !== 'undefined' && ftype === 'widget')) {
            if (newValue === 'product' || newValue === 'event_state' || newValue === 'event_label' || newValue === 'team' || newValue === 'product_group' || newValue === 'salesItem_invoice_status' || newValue === 'lead_status' || newValue === 'crm_label' || newValue === 'userId' || newValue === 'updated_by_user' || newValue === 'created_by') {
                obj.condition.operator = '$in';
            } else {
                obj.condition.operator = '$eq';
            }
        } else {
            obj.condition.operator = '$eq';

        }

        obj.condition.value = '';

        onUpdate(obj);
    };

    // handle the null errors, if any when the object is undefined
    let availableFieldKeys = reportContext.customReport ? Object.keys(reportContext.customReport) : [];
    let availableFields: any = availableFieldKeys.map(keyName => ({
        id: keyName as string,
        name: reportContext.customReport[keyName].config?.name as string,
        config: reportContext.customReport[keyName].config,
    }));

    if (availableFieldKeys.length === 0) {
        if (typeof reportContext.customReport !== 'undefined') {
            //nothing to do
        } else {
            availableFieldKeys = reportContext;
            reportContext.forEach(function (keyName: {
                reportType: any;
                report: any;
                field: any;
            }) {
                //if (keyName?.field?.config.fieldType === 'number') {
                if (typeof feildValue !== 'undefined' && feildValue === 'number_of_events' && ['standard', 'transactionFixedProduct', 'sales', 'expense'].includes(keyName?.reportType)) {
                    //console.log("keyName?.field?.config",keyName?.field?.config)
                    availableFields.push({
                        id: keyName?.field?.config?._id as string,
                        name: keyName?.field?.config?.fieldType == 'number' ? (t("##SumOf") + " " + keyName?.field?.config?.name) : keyName?.field?.config?.name as string,
                        fieldType: keyName?.field?.config?.fieldType,
                        config: keyName?.field?.config
                    });

                    allCustomFeilds[keyName?.field?.config?._id] = {
                        id: keyName?.field?.config?._id as string,
                        name: keyName?.field?.config?.name as string,
                        customFieldId: keyName?.field?.config?._id,
                        reportType: keyName?.reportType,
                        reportId: keyName?.report,
                        config: keyName?.field?.config
                    }
                } else if (typeof feildValue !== 'undefined' && (feildValue === 'total_revenue_of_salesItem' || feildValue === 'total_qty_of_salesItem') && (keyName?.reportType === 'sales' || keyName?.reportType === 'transactionFixedProduct')) {
                    //console.log("keyName?.field?.config",keyName?.field?.config)
                    availableFields.push({
                        id: keyName?.field?.config?._id as string,
                        name: keyName?.field?.config?.name as string,
                        fieldType: keyName?.field?.config?.fieldType,
                        config: keyName?.field?.config
                    });
                    allCustomFeilds[keyName?.field?.config?._id] = {
                        id: keyName?.field?.config?._id as string,
                        name: keyName?.field?.config?.name as string,
                        customFieldId: keyName?.field?.config?._id,
                        reportType: keyName?.reportType,
                        reportId: keyName?.report,
                        config: keyName?.field?.config
                    }
                } else if (typeof feildValue !== 'undefined' && (feildValue === 'total_revenue_of_expenses' || feildValue === 'total_qty_of_expenses') && (keyName?.reportType === 'expense')) {
                    //console.log("keyName?.field?.config",keyName?.field?.config)
                    availableFields.push({
                        id: keyName?.field?.config?._id as string,
                        name: keyName?.field?.config?.name as string,
                        fieldType: keyName?.field?.config?.fieldType,
                        config: keyName?.field?.config
                    });
                    allCustomFeilds[keyName?.field?.config?._id] = {
                        id: keyName?.field?.config?._id as string,
                        name: keyName?.field?.config?.name as string,
                        customFieldId: keyName?.field?.config?._id,
                        reportType: keyName?.reportType,
                        reportId: keyName?.report,
                        config: keyName?.field?.config
                    }
                }


                //}
            });
        }
    }

    availableFields.sort((a: any, b: any) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()));
    console.log('availableFields', availableFields);
    console.log('value', currentValue.refField)
    console.log('value', currentValue)
    console.log('config', allCustomFeilds[currentValue?.refField?.customFieldId]?.config)
    //setFieldType(allCustomFeilds[currentValue?.refField?.customFieldId]?.config?.fieldType)

    React.useEffect(() => {
        const validOperator = getValidOperatorsForFieldType(fieldType);
        const conditionValue = currentValue?.condition?.value;
        console.log(fieldType, ")))))))))))000")
        setValidOperators(validOperator);
        if (typeof reportContext.customReport !== 'undefined') {
            delete reportContext?.customReport[reportContext?.currentId];
            setFieldType(reportContext.customReport[currentValue.refField]?.config?.fieldType);
            const validOperator = getValidOperatorsForFieldType(reportContext.customReport[currentValue.refField]?.config?.fieldType);
            setValidOperators(validOperator);
            setCurrentfieldConfig(reportContext.customReport[currentValue.refField]?.config);
        } else {
            if (currentValue?.refField === 'product') {
                const validOperator = getValidOperatorsForFieldType('product');
                setValidOperators(validOperator);
                setFieldType('product');
            } else if (currentValue?.refField === 'event_state') {
                const validOperator = getValidOperatorsForFieldType('eventState');
                setValidOperators(validOperator);
                setFieldType('eventState');
                setselectedChipsIds(Array.isArray(conditionValue) ? conditionValue : []);
            } else if (currentValue?.refField === 'event_label') {
                const validOperator = getValidOperatorsForFieldType('eventLabel');
                setValidOperators(validOperator);
                setFieldType('eventLabel');
                setselectedChipsIds(Array.isArray(conditionValue) ? conditionValue : []);
            } else if (currentValue?.refField === 'team') {
                const validOperator = getValidOperatorsForFieldType('teams');
                setValidOperators(validOperator);
                setFieldType('teams');
                setselectedChipsIds(Array.isArray(conditionValue) ? conditionValue : []);
            } else if (currentValue?.refField === 'salesItem_invoice_status') {
                const validOperator = getValidOperatorsForFieldType('invoiceStatus');
                setValidOperators(validOperator);
                setFieldType('invoiceStatus');
                setselectedChipsIds(Array.isArray(conditionValue) ? conditionValue : []);
            } else if (currentValue?.refField === 'product_group') {
                const validOperator = getValidOperatorsForFieldType('productGroup');
                setValidOperators(validOperator);
                setFieldType('productGroup');
            } else if (currentValue?.refField === 'lead_status') {
                const validOperator = getValidOperatorsForFieldType('leadStatus');
                setValidOperators(validOperator);
                setFieldType('leadStatus');
                setselectedChipsIds(Array.isArray(conditionValue) ? conditionValue : []);
            } else if (currentValue?.refField === 'crm_label') {
                const validOperator = getValidOperatorsForFieldType('crmLabel');
                setValidOperators(validOperator);
                setFieldType('crmLabel');
                setselectedChipsIds(Array.isArray(conditionValue) ? conditionValue : []);
            } else if (currentValue?.refField === 'userId') {
                const validOperator = getValidOperatorsForFieldType('userID');
                setValidOperators(validOperator);
                setFieldType('userID');
                setselectedChipsIds(Array.isArray(conditionValue) ? conditionValue : []);
            } else if (currentValue?.refField === 'updated_by_user') {
                const validOperator = getValidOperatorsForFieldType('updateByUser');
                setValidOperators(validOperator);
                setFieldType('updateByUser');
                setselectedChipsIds(Array.isArray(conditionValue) ? conditionValue : []);
            } else if (currentValue?.refField === 'created_by') {
                const validOperator = getValidOperatorsForFieldType('createdByUser');
                setValidOperators(validOperator);
                setFieldType('createdByUser');
                setselectedChipsIds(Array.isArray(conditionValue) ? conditionValue : []);
            } else {
                if (allCustomFeilds[currentValue?.refField?.customFieldId]?.config?.fieldType) {
                    console.log('lol')
                    setFieldType(allCustomFeilds[currentValue?.refField?.customFieldId]?.config?.fieldType)
                    const validOperator = getValidOperatorsForFieldType(allCustomFeilds[currentValue?.refField?.customFieldId]?.config?.fieldType);
                    setValidOperators(validOperator);
                } else {
                    if ((typeof ftype !== 'undefined' && ftype === 'widget')) {
                        const validOperator = getValidOperatorsForFieldType('number');
                        setValidOperators(validOperator);
                        setFieldType('number');
                    } else {
                        setValidOperators(validOperator);
                    }
                }

            }

        }

        if (reportContextObj?.wrapperType === 'targets') {
            setValidOperators([{slug: '##OperatorIN', operator: 'in', mongooseOperator: '$in'}]);
        }


    }, [availableFields.length]);


    //console.log('ftype', ftype);
    let staticFeilds: any = [];
    if (typeof feildValue !== 'undefined' && feildValue === 'number_of_events') {
        staticFeilds = [
            {
                id: 'total_revenue_of_salesItem',
                name: t('##TotalTransactionRevenue'),
            },
            {
                id: 'total_qty_of_salesItem',
                name: t('##TotalTransactionQty'),
            },
            {
                id: 'product',
                name: t('##Product'),
            },
            {
                id: 'product_group',
                name: t('##ProductGroup'),
            },
            {
                id: 'event_state',
                name: t('##eventState'),
            },
            {
                id: 'event_label',
                name: t('##CustomStatuses'),
            },
            {
                id: 'team',
                name: t('##Team'),
            },
        ];
    } else if (typeof feildValue !== 'undefined' && (feildValue === 'number_of_crm_entries' || feildValue === 'number_of_ring_actions')) {
        staticFeilds = [
            {
                id: 'product',
                name: t('##Product'),
            },
            {
                id: 'product_group',
                name: t('##ProductGroup'),
            },
            {
                id: 'event_state',
                name: t('##eventState'),
            },
            {
                id: 'event_label',
                name: t('##CustomStatuses'),
            },
            {
                id: 'lead_status',
                name: t('##LeadStatus'),
            },
            {
                id: 'crm_label',
                name: t('##CRMCustomStatuses'),
            },
            {
                id: 'userId',
                name: t('##AssignedUser'),
            },
            {
                id: 'updated_by_user',
                name: t('##UpdatedByUsers'),
            },
            {
                id: 'created_by',
                name: t('##CreatedByUser'),
            },
        ];
    } else if (typeof feildValue !== 'undefined' && feildValue === 'number_of_days') {
        staticFeilds = [{
            id: 'number_of_rings',
            name: t('##RingAction'),
        }];
    } else {
        staticFeilds = [
            {
                id: 'product',
                name: t('##Product'),
            },
            {
                id: 'product_group',
                name: t('##ProductGroup'),
            },
            {
                id: 'event_state',
                name: t('##eventState'),
            },
            {
                id: 'salesItem_invoice_status',
                name: t('##InvoiceStatus'),
            },
            {
                id: 'event_label',
                name: t('##CustomStatuses'),
            },
            {
                id: 'team',
                name: t('##Team'),
            },
        ];
    }

    if (typeof feildValue !== 'undefined' && feildValue === 'number_of_crm_entries'){
        staticFeilds.push({
            id: 'number_of_rings',
            name: t('##RingAction'),
        });
    }
    

    if (typeof ftype !== 'undefined' && ftype === 'widget') {
        availableFields = availableFields.concat(staticFeilds);
    }
    availableFields = availableFields.filter((value: any, index: number, self: any[]) =>
            index === self.findIndex((t) => (
                t.id === value.id && t.name === value.name
            ))
    )

    if (reportContextObj?.wrapperType === 'targets') {
        availableFields = [
            {
                id: 'product',
                name: t('##Product'),
            },
            {
                id: 'product_group',
                name: t('##ProductGroup'),
            }
        ]
    }

    const getDataByFieldType = (fieldType: string) => {
        switch (fieldType) {
            case 'teams':
                return teamsList;
            case 'eventLabel':
                return eventLabels;
            case 'eventState':
                return eventStates;
            case 'leadStatus':
                return leadStatusList;
            case 'crmLabel':
                return crmLabels;
            case 'userID':
                return userList;
            case 'updateByUser':
                return userList;
            case 'createdByUser':
                return userList;
            default:
                return invoiceStates;
        }
    };

    return (
        <Box display='flex' gap={2} alignItems='end' mb={0.5} p={1}>

            <Autocomplete
                id='field-selector'
                sx={{
                    minWidth: '130px',
                    width: '40%',
                }}
                options={availableFields}
                getOptionLabel={option => option.name}
                renderInput={params => <TextField {...params} label={t('##ChooseField')} variant='standard'/>}
                value={availableFields.find((f: any) => (typeof currentValue.refField === 'object' ? (f.id === currentValue.refField.id) : (f.id === currentValue.refField))) || null}
                onChange={(
                    event: React.SyntheticEvent<Element, Event>,
                    newValue: any | null,
                ) => {
                    const value = newValue?.id || '';
                    const config = newValue?.config;
                    const fieldType = newValue?.fieldType || config?.fieldType

                    setCurrentfieldConfig(config)
                    updateField(value, fieldType);
                }}
            />

            {validOperators && validOperators.length > 0 && (
                <Select
                    id='operator-select'
                    variant='standard'
                    sx={{
                        width: '20%',
                    }}
                    value={currentValue.condition.operator}
                    onChange={e => {
                        updateOperator(e.target.value);
                    }}
                >
                    {validOperators.map(option => {
                        return (
                            <MenuItem key={option.operator}
                                      value={(typeof ftype !== 'undefined' && ftype === 'widget') ? option.mongooseOperator : option.operator}>
                                {t(option.slug)}
                            </MenuItem>
                        );
                    })}
                </Select>
            )}
            {!valueAtRefNotRequiredOperators.includes(currentValue.condition.operator) && <>
                {((typeof ftype !== 'undefined' && ftype === 'widget' && fieldType && stringFieldTypes.includes(fieldType)) || stringFieldTypes.includes(fieldType) || stringFieldTypes.includes(fieldType)) ? (
                    <TextField
                        label={t('##ValueAtReference')}
                        variant='standard'
                        sx={{
                            minWidth: '130px',
                            width: '40%',
                        }}
                        value={currentValue.condition.value}
                        onChange={e => {
                            updateValue(e.target.value);
                        }}
                    />
                ) : null}

                {fieldType && chipFieldTypes.includes(fieldType) ? (
                    <>
                        <ChipsViewEdit
                            allowChipDelete={true}
                            allowChipClick={false}
                            allowChipAdd={true}
                            data={getDataByFieldType(fieldType)}
                            getChipLabel={getChipLabel || ((el: IChipElement): string => (el && el.name) || '')}
                            handleChipClick={arg => {

                                changeSelectedChips(arg);
                            }}
                            handleChipDelete={arg => {
                                deleteSelectedChips(arg);
                            }}
                            noDefaltValue={true}
                            default={selectedChipsIdsInt}
                            onChange={(value: any) => {
                                value = value.map((a: { _id: any; name: any }) => ({_id: a._id, name: a.name}))
                                updateValue(value);
                            }}
                            selectedChipsIds={selectedChipsIdsInt}
                            setBgColorForSelectedOnly={true}
                        />
                    </>
                ) : null}

                {fieldType && productFieldTypes.includes(fieldType) ? (
                    <div style={{width: '100%'}}>
                        <ProductSelect
                            initialValue={
                                reportContextObj?.wrapperType === 'targets'
                                    ? Array.isArray(currentValue?.condition?.value) &&
                                    currentValue?.condition?.value[0]
                                        ? currentValue?.condition?.value[0]
                                        : null
                                    : currentValue?.condition?.value || []
                            }
                            multi={reportContextObj?.wrapperType === 'targets' ? false : true}
                            onChange={(value: any) => {
                                let tmpVal: any = [];
                                if (Array.isArray(value)) {
                                    tmpVal = value;
                                } else {
                                    if (value?._id) tmpVal = [value];
                                }
                                handleAutoCompleteChange(
                                    tmpVal.map((a: { _id: any; name: any }) => ({_id: a._id, name: a.name})),
                                    'products',
                                );
                            }}
                            disabledOptions={disabledOptions}
                        />
                    </div>
                ) : null}

                {fieldType && numberFieldTypes.includes(fieldType) ? (
                    <TextField
                        type='number'
                        label={t('##ValueAtReference')}
                        variant='standard'
                        sx={{
                            minWidth: '130px',
                            width: '40%',
                        }}
                        value={currentValue.condition.value}
                        onChange={e => {
                            updateValue(e.target.value);
                        }}
                    />
                ) : null}


                {fieldType && productGroupFieldTypes.includes(fieldType) ? (
                    <AsynchronousAutoComplete
                        multiple={true}
                        limitMultiple={reportContextObj?.wrapperType === 'targets' ? 1 : undefined}
                        defaultValue={currentValue.condition.value ? currentValue.condition.value : []}
                        reqParams={{
                            url: `productgroup`,
                            params: {
                                loadsmall: true,
                            },
                        }}

                        searchTextParamKey='name'
                        autoCompleteProps={{
                            onChange: handleValueChange,
                            disableClearable: true,
                            getOptionLabel: (option: IProductGroup | null) => `${option?.name}`,
                        }}
                        textFieldProps={{
                            label: t('##ProductGroup'),
                            variant: 'standard',
                        }}
                        disabledOptions={disabledOptions}
                    />
                ) : null}

                {selectFieldTypes.includes(fieldType) ? (
                    <FormControl
                        sx={{
                            minWidth: '130px',
                            width: '40%',
                        }}
                    >
                        <InputLabel id='ValueAtReference-select'>{t('##ValueAtReference')}</InputLabel>
                        <Select
                            variant='standard'
                            id='ValueAtReference-select'
                            value={currentValue.condition.value}
                            onChange={e => {
                                updateValue(e.target.value as string);
                            }}
                        >
                            {allCustomFeilds[currentValue?.refField?.customFieldId] ?
                                allCustomFeilds[currentValue?.refField?.customFieldId]?.config?.fieldObject?.options.map(
                                    (option: string) => {
                                        return (
                                            <MenuItem value={option} key={option}>
                                                {option}
                                            </MenuItem>
                                        );
                                    },
                                ) : currentfieldConfig?.fieldObject?.options.map(
                                    (option: string) => {
                                        return (
                                            <MenuItem value={option} key={option}>
                                                {t(option)}
                                            </MenuItem>
                                        );
                                    },
                                )}
                        </Select>
                    </FormControl>
                ) : null}
            </>}

        </Box>
    );
}
