import React, { useEffect, useState } from 'react';
import EmpplanTheme from '../elements/ThemeWrapper/EmpplanTheme';
import { Autocomplete, Box, Button, Chip, Grid, TextField } from '@mui/material';
import {Controller, FieldValues, useForm, useWatch} from 'react-hook-form';
import EmpplanTextField from '../EmpplanFormComponents/EmpplanTextField';
import { useTranslation } from 'react-i18next';
import EmpplanSelect from '../EmpplanFormComponents/EmpplanSelect';
import API from '../../config/api';
import EmpplanCheckbox from '../EmpplanFormComponents/EmpplanCheckbox';

interface projectVisibility {
    _id:string;
    name:string;
}

export interface CustomModelFieldConfig {
    __v: number;
    created: string;
    pathname: string;
    position: {
        location: string;
        column: string;
    };
    fieldType: string;
    fieldAttributeName: string;
    fieldLabelName: string;
    brandRelation: string[];
    brandConfig: any[];
    fieldObject: {
        options?: string[];
        prefixString?: string;
        padding?: number;
        startNumber?: number;
    };
    _id: string;
    projectVisibility: string[] | projectVisibility[];
    isUsePWAFilter: boolean;
}

function CustomModelFieldsForm({
    modelName,
    showFieldTypes,
    position,
    data,
    onFormSubmit,
}:{
    modelName?: string,
    showFieldTypes?: boolean,
    position?: any,
    data?: CustomModelFieldConfig
    onFormSubmit?:any
}) {
    const { t } = useTranslation();

    const {
        handleSubmit,
        control,
        watch,
        formState: { errors, isValid },
        reset,
        setValue,
    } = useForm<any>({
        defaultValues: data || ({} as FieldValues | CustomModelFieldConfig),
        mode: 'all',
        reValidateMode: 'onChange',
    });

    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = React.useState(false);
    const [brandsData, setBrandsData] = React.useState<any>([]);
    const [loadings, setLoadings] = useState(false);
    const [projects, setProjects] = useState<any>([]);

    const flexGtSmValue : number = showFieldTypes ? 3 : 5;
    const type : any = watch('fieldType');
    const formValue : any = watch();

    formValue.brandRelation = [];
    formValue.projectVisibility = [];
    formValue.fieldObject={options:[]};

    const fieldTypes = [
        { label:'##Text' , value:'text' },
        { label:'##TextArea' , value:'textarea' },
        { label:'##Radio' , value:'Radio' },
        { label:'##Checkbox' , value:'Checkbox' }
    ];

    if (modelName === 'Userabstraction') {
        fieldTypes.push({ label: '##Sequence', value: 'sequence' });
    }

    useEffect(() => {
        (async () => {
            setLoading(true);
            const res = await API.get(`/brand/search/name`);
            const result = await res.json();
            if (Array.isArray(result) && result.length > 0) {
                const usersList = result;
                if (Array.isArray(usersList)) {
                    setBrandsData(usersList);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        })().catch(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        (async () => {
            setLoadings(true);
            try {
                const resp = await API.get(`/projects/all/XXXX?active=true`);
                const jsonResp = await resp.json();
                if (jsonResp.projects && Array.isArray(jsonResp.projects)) {
                    setProjects(jsonResp.projects);              
                } else {
                    console.error('error loading projects', 'invalid resp', jsonResp);
                }
            } catch (error) {
                console.error('error loading projects', error);
            }
        })().catch(() => {
            setLoadings(false);
        });
    }, []);

    useEffect(() => {
        const brandRelation:any = [];
        const projectVisibility:any = [];

        data?.brandRelation.forEach((item: any) => {
            if (brandsData.find((brand:any) => brand._id === item._id)) {
                brandRelation.push(item._id);
            } else {
                console.warn('Brand not found for:', item._id);
            }
        });
    
        data?.projectVisibility.forEach((item: any) => {
            if (projects.find((project:any) => project._id === item._id)) {
                projectVisibility.push(item._id);
            } else {
                console.warn('Project not found for:', item._id);
            }
        });

        setValue('brandRelation',brandRelation);
        setValue('projectVisibility',projectVisibility);
    }, [data, brandsData, projects, setValue]);

    const onSubmit = async (formValue: FieldValues | CustomModelFieldConfig) => {
        formValue.pathname = modelName;
        formValue.position = position

        if (data) {
            let res = await API.put(`api/customModelField/field/${formValue._id}`, formValue);
            onFormSubmit?.();
        } else {
            let res = await API.post(`api/customModelField/field`, formValue);
            onFormSubmit?.();
            reset();
        }
    };

    return (
        <EmpplanTheme>
            <Box width={{ lg: "100%", sm: "100%" }} >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justifyContent={'start'}>
                        <Grid item xs={12} display="flex" flexDirection="row" alignItems="start" padding={1}>
                            <Grid item xs={7} sx={{ mx: 1 }}>
                                <EmpplanTextField
                                    control={control}
                                    name='fieldLabelName'
                                    label={t('##FieldLabel')}
                                    rules={{ 
                                        required: `${t('##Required')}` 
                                    }}
                                />
                            </Grid>
                            <Grid item xs={flexGtSmValue} sx={{ mx: 1 }}>
                                <Controller
                                    name='fieldAttributeName'
                                    control={control}
                                    rules={{ 
                                        required: `${t('##Required')}`,
                                        pattern: {
                                            value: /^[A-Za-z_]+$/,
                                            message: `${t('##OnlyAlphabetsOrUnderscoreIsAllowed')}`,
                                        },
                                    }}
                                    render={({ field, fieldState }) => {
                                        const errorMessages = [];
                                        if (fieldState.error) {
                                            if (fieldState.error.type === 'required') {
                                                errorMessages.push(fieldState.error.message);
                                            } else if (fieldState.error.type === 'pattern') {
                                                errorMessages.push(fieldState.error.message + ' [ A-Z | a-z | _ ]');
                                            }
                                        }
                                        return (
                                            <TextField
                                                {...field}
                                                label={t('##CustomFieldModelAttributeName')}
                                                type='text'
                                                error={!!fieldState.error}
                                                helperText={errorMessages.join(' ')}
                                                fullWidth
                                                variant="standard"
                                                onChange={(e)=> {
                                                    field.onChange(e);
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            {showFieldTypes && <Grid item xs={2} sx={{ mx: 1 }}>
                                <EmpplanSelect
                                    control={control}
                                    name='fieldType'
                                    label={t('##CustomFieldType')}
                                    options={
                                        fieldTypes.map(ft => ({ value: ft.value, label: t(ft.label) }))}
                                    disabled={!!data?.fieldType}
                                />
                            </Grid>}
                        </Grid>
                        {type !== 'sequence' && <Grid item xs={12} display="flex" flexDirection="row" alignItems="start" padding={1}>
                            {(type == 'Dropdown' || type == 'Checkbox' || type == 'Radio') && (
                                <Grid item xs={3} sx={{ mx: 1 }}>
                                    <Controller
                                        name='fieldObject.options'
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => {
                                            return (
                                                <Autocomplete
                                                    multiple
                                                    disableClearable
                                                    options={[]}
                                                    freeSolo={true}
                                                    value={value}
                                                    inputValue={inputValue}
                                                    onChange={(event: any, newValue: any, reason: string) => {
                                                        onChange(newValue);
                                                        setInputValue('');
                                                    }}
                                                    onInputChange={(event, newInputValue) => {
                                                        setInputValue(newInputValue);
                                                    }}
                                                    renderInput={params => {
                                                        return (
                                                            <TextField
                                                                {...params}
                                                                label={t('##Options')}
                                                                variant={'standard'}
                                                                helperText={t('##PressEnterToGenerateFilterChip')}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
                                                                    ),
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                    renderTags={(value: any, getTagProps:any) =>
                                                        value.map((option: any, index: number) => (
                                                            <Chip label={option} onDelete={() => {
                                                                    const newValue = value.filter((val: any) => val !== option);
                                                                    onChange(newValue);
                                                                }}
                                                                {...getTagProps({ index })}
                                                            />
                                                        ))
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={3} sx={{ mx: 1 }}>
                                <EmpplanSelect
                                    multiple
                                    control={control}
                                    name='brandRelation'
                                    label={t('##Brands')}
                                    options={
                                        brandsData.map((ft:any) => ({ value: ft._id, label: ft.name }))}
                                />
                            </Grid>
                            <Grid item xs={3} sx={{ mx: 1 }}>
                                <EmpplanSelect
                                    multiple
                                    control={control}
                                    name='projectVisibility'
                                    label={t('##ProjectVisibility')}
                                    options={
                                        projects.map((ft:any) => ({ value: ft._id, label: t(ft.name) }))}
                                />
                            </Grid>
                            { (( data?.fieldType ? (data.fieldType === 'text' || data.fieldType === 'textarea')
                                : (type === 'text' || type === 'textarea')))  && (<Grid item xs={3} sx={{ mx: 1, mt: 1 }}>
                                <EmpplanCheckbox 
                                    control={control} 
                                    name='isUsePWAFilter'
                                    label={t('##UsePWAFilter')}
                                />
                            </Grid>)}
                        </Grid>}
                        {type == 'sequence' && <Grid item xs={12} display="flex" flexDirection="row" alignItems="start" padding={1}>
                            <Grid item xs={6} sx={{ mx: 1 }}>
                                <EmpplanTextField
                                    control={control}
                                    name='fieldObject.prefixString'
                                    label={t('##SequencePrefixString')}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ mx: 1 }}>
                                <EmpplanTextField
                                    control={control}
                                    type='number'
                                    name='fieldObject.padding'
                                    label={t('##SequencePadding')}
                                    rules={{ min: 0 }}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ mx: 1 }}>
                                <EmpplanTextField
                                    control={control}
                                    type='number'
                                    name='fieldObject.startNumber'
                                    label={t('##SequenceStartNumber')}
                                    rules={{ 
                                        min: 0,
                                        required: `${t('##Required')}` 
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ mx: 1 }}>
                                <TextField
                                    disabled
                                    type='text'
                                    label={t('##SequenceExample')}
                                    variant='standard'
                                    fullWidth
                                    value={formValue?.fieldObject?.padding && formValue?.fieldObject?.startNumber && formValue?.fieldObject?.prefixString + formValue?.fieldObject?.startNumber.toString().padStart(formValue?.fieldObject?.padding, '0')}
                                />
                            </Grid>
                        </Grid>}
                        <Grid item xs={12} display="flex" justifyContent="flex-end" padding={1}>
                            <Button
                                type='button'
                                variant='text'
                                color='primary'
                                sx={{ mx: 1 }}
                                onClick={() => onFormSubmit?.()}
                            >
                                {t('##Cancel') }
                            </Button>
                            <Button
                                type='submit'
                                variant='text'
                                color='primary'
                                sx={{ mx: 1 }}
                            >
                                {t('##Save') }
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </EmpplanTheme>
    );
}

export default CustomModelFieldsForm;