import * as React from 'react';

const CountDown = ({ onZeroCallback, label }: { onZeroCallback?: any; label?: string }) => {
    const [counter, setCounter] = React.useState(3);

    React.useEffect(() => {
        let counterDecrementTimeout: NodeJS.Timeout | null = null;
        if (counter > 0) {
            counterDecrementTimeout = setTimeout(() => {
                setCounter && setCounter(counter - 1);
            }, 999);
        }
        if (counter === 0) {
            setCounter && setCounter(counter - 1); // this is important so it does not invoke the below again
            onZeroCallback && onZeroCallback();
        }
        return () => {
            counterDecrementTimeout && clearTimeout(counterDecrementTimeout);
        };
    }, [counter]);

    return (
        <>
            {label} {counter > 0 ? counter : ''}
        </>
    );
};

export default CountDown;
