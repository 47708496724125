import { applyMiddleware, combineReducers, legacy_createStore as createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import { composeEnhancers } from './utils';

import { MenuReducer } from '../Models/MenuStates/MenuReducer';
import { MenuStates } from '../Models/MenuStates/MenuStatesTypes';
import { UserReducer } from '../Models/UserStates/UserReducer';
import { UserStates } from '../Models/UserStates/UserStatesTypes';
import { EventStateTypeContainer } from '../Models/Events/SmallEventType';
import { SmallEventReducer } from '../Models/Events/SmallEventReducer';
import { UserTodoStates } from '../Models/UserTodo/UserTodoTypes';
import { UserTodoReducer } from '../Models/UserTodo/UserTodoReducer';
import { ProjectInvitationReducer } from '../Models/ProjectInvitaions/ProjectInvitationReducer';
import { ProjectStateTypeStateContainer } from '../Models/ProjectInvitaions/ProjectInvitationTypes';
import { ProjectReducer } from '../Models/Projects/ProjectReducers';
import { ProjectNativeStateContainer } from '../Models/Projects/ProjectsTypes';
import { CustomReportReducer } from '../Models/CustomReport/CustomReportReducer';
import { CustomReportStateTypeContainer } from '../Models/CustomReport/CustomReportTypes';
import { EventStateTypeContainer as EVContainer } from '../Models/EventAreaLocations/EventAreaLocationsType';
import { EventAreaLocationReducer } from '../Models/EventAreaLocations/EventAreaLocationReducer';
import { GalleryState } from '../Models/Gallery/GalleryStateTypes';
import { GalleryReducer } from '../Models/Gallery/GalleryReducers';

// the new ones
import eventReducer from '../Models/NewEvent';
import projectReducer from '../Models/NewProject';
import customReportReducer from '../Models/NewCustomReport';
import productReducer from '../Models/NewProduct';
import productReportReducer from '../Models/NewProductReport';
import customModelFieldReducer from '../Models/NewCustomModelField';
import customReportResultReducer from '../Models/NewCustomReportResult';
import expenseReducer from '../Models/NewExpense';
import salesItemReducer from '../Models/NewSalesItem';
import crmItemReducer from '../Models/NewCRM';
import fileSystemReducer from '../Models/NewFileSystem';
import syncDataReducer from '../Models/NewSync';
import projectLocationReducer from '../Models/NewProjectLocations';
import { newCRMActionReducer } from '../Models/NewCRMActions';
import { newEventActionCollectionReducer } from '../Models/NewEventActionCollection';
import { eventEarningsReducer } from '../Models/NewEventEarnings/reducer';
import { CRMItemStates } from '../Models/NewCRM/types';

export interface IApplicationStore {
    Menu: MenuStates;
    User: UserStates;
    SmallEvent: EventStateTypeContainer;
    UserTodo: UserTodoStates;
    ProjectInvitation: ProjectStateTypeStateContainer;
    Project: ProjectNativeStateContainer;
    CustomReport: CustomReportStateTypeContainer;
    EventAreaLocations: EVContainer;
    Gallery: GalleryState;

    // the new ones
    event: EventStates;
    project: ProjectStates;
    projectLocation: ProjectLocationsStates;
    newEventAction: NewEventActionStates;
    newCRMAction: NewCRMActionStates;
    eventEarnings: earningsState;
    customReport: CustomReportStates;
    product: ProductStates;
    productReport: ProductReportStates;
    customModelField: CustomModelFieldStates;
    customReportResult: CustomReportResultStates;
    expense: ExpenseStates;
    salesItem: SalesItemStates;
    fileSystem: FileSystemStates;
    syncData: SyncDataStates;
    crmItem: CRMItemStates
}

const rootReducer = combineReducers<IApplicationStore>({
    Menu: MenuReducer,
    User: UserReducer,
    SmallEvent: SmallEventReducer,
    UserTodo: UserTodoReducer,
    ProjectInvitation: ProjectInvitationReducer,
    Project: ProjectReducer,
    CustomReport: CustomReportReducer,
    EventAreaLocations: EventAreaLocationReducer,
    Gallery: GalleryReducer,

    // the new ones
    event: eventReducer,
    project: projectReducer,
    projectLocation:projectLocationReducer,
    newEventAction:newEventActionCollectionReducer,
    newCRMAction:newCRMActionReducer,
    eventEarnings: eventEarningsReducer,
    customReport: customReportReducer,
    product: productReducer,
    productReport: productReportReducer,
    customModelField: customModelFieldReducer,
    customReportResult: customReportResultReducer,
    expense: expenseReducer,
    salesItem: salesItemReducer,
    fileSystem: fileSystemReducer,
    syncData: syncDataReducer,
    crmItem : crmItemReducer,
});

/**export const epicMiddleware = createEpicMiddleware<
    RootAction,
    RootAction,
    RootState,
    Services
    >({
    dependencies: services,
});**/

// configure middlewares
const middlewares = [thunk];
// compose enhancers
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

export default function configureStore(): Store<IApplicationStore> {
    //preloadstate === undefined
    const store = createStore(rootReducer, {}, enhancer);
    return store;
}
