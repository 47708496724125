import {Box,styled} from "@mui/material";

export const EquallySpacedRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}))

export const DivCenteredContent = styled(EquallySpacedRow)(({ theme }) => ({
  width: '100%',
  height: '100%',
  placeContent: 'center',
}))