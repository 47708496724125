import { newProjectDb } from './newProjectsDexie';
import { createNewProjectWrapperForDexie, getProjectsFetch, getSmallProjectsFetch, syncAllProjects } from './helpers';
import { Collection, IndexableType, Table } from 'dexie';
import { isDeviceOnline } from '../../Services/OnlineStatus';

const newProjectActionTypes = {
    NEW_PROJECT: 'NEW_PROJECT',
    LOAD_PROJECT_LIST: 'LOAD_PROJECT_LIST',
    LOAD_PROJECT: 'LOAD_PROJECT',
    UPDATE_PROJECT_SYNC_DATA: 'UPDATE_PROJECT_SYNC_DATA',
    CLEAR_PROJECT_LIST: 'CLEAR_PROJECT_LIST',
    LOAD_PROJECT_CUSTOM_STATUS_LIST: 'LOAD_PROJECT_CUSTOM_STATUS_LIST',
};

export default newProjectActionTypes;

export const createNewProjectAction = (project: DexieProject) => {
    newProjectDb.projects.add(project);
    return {
        type: newProjectActionTypes.NEW_PROJECT,
        payload: project,
    };
};

export const loadProjectAction = (projectId: string | null) => async (dispatch: any) => {
    if (projectId !== null && projectId) {
        const project = await newProjectDb.projects.get(projectId);

        if (!project && isDeviceOnline()) {
            // we load from server
            console.log('Loading this project from the API');
            const projectResponse = await getProjectsFetch(projectId).catch(console.error);

            if (
                projectResponse &&
                projectResponse.success &&
                projectResponse.count &&
                Array.isArray(projectResponse.data) &&
                projectResponse.count > 0
            ) {
                const projects = projectResponse.data.map((proj: any) => createNewProjectWrapperForDexie(proj));
                console.log('Dispatching project loaded from the API');
                return dispatch({
                    type: newProjectActionTypes.LOAD_PROJECT,
                    payload: projects[0],
                });
            }
        }

        return dispatch({
            type: newProjectActionTypes.LOAD_PROJECT,
            payload: project,
        });
    } else {
        return dispatch({
            type: newProjectActionTypes.LOAD_PROJECT,
            payload: undefined,
        });
    }
};

export const loadProjectListAction = (filterOptions: { name?: string; isLeads?: boolean }) => async (dispatch: any) => {
    type DexieEventQueryBuilder = Collection<DexieProject, IndexableType> | Table<DexieProject, IndexableType>;
    let projects: DexieEventQueryBuilder = newProjectDb.projects;

    if (filterOptions?.name !== undefined) {
        const nameRegex = new RegExp('.*' + filterOptions.name + '.*', 'i');
        projects = projects.filter(ev => {
            // use between instead of filter to use compound index
            return nameRegex.test(ev.project.name);
        });
    }

    if (filterOptions?.isLeads) {
        projects = projects.filter(project => project?.project?.eventSettings?.isCRMLeads);
    }

    const projectsRes = await projects.toArray();

    if (projectsRes.length === 0 && isDeviceOnline() && dispatch) {
        // we load from server
        console.log('Loading this project from the API');
        const projectResponse = await getSmallProjectsFetch().catch(console.error);

        if (projectResponse && projectResponse.success && Array.isArray(projectResponse.projects)) {
            const projects = projectResponse.projects.map((proj: any) => createNewProjectWrapperForDexie(proj));
            console.log('Dispatching project loaded from the API');
            return dispatch({
                type: newProjectActionTypes.LOAD_PROJECT_LIST,
                payload: projects,
            });
        }
    }

    dispatch?.({
        type: newProjectActionTypes.LOAD_PROJECT_LIST,
        payload: projectsRes,
    });
    return {
        success: true,
        count: projectsRes.length,
        projects: projectsRes.map(project => project.project),
    }
};

export const clearProjectListAction = () => {
    return {
        type: newProjectActionTypes.CLEAR_PROJECT_LIST,
    };
};

export const startProjectSyncAction = (projectIds: string) => (dispatch: any) => {
    dispatch({
        type: newProjectActionTypes.UPDATE_PROJECT_SYNC_DATA,
        payload: {
            state: 'DOWNLOADING',
            lastUpdate: new Date(),
        },
    });
    syncAllProjects(dispatch, projectIds);
};

export const loadProjectCustomStatus = (filterOptions: { projectId?: string }) => async (dispatch: any) => {
    type DexieEventQueryBuilder = Collection<DexieProject, IndexableType> | Table<DexieProject, IndexableType>;
    let projects: DexieEventQueryBuilder = newProjectDb.projects;

    if (filterOptions?.projectId) {
        projects = projects.filter(project => project._id === filterOptions.projectId);
    }

    const projectsRes = await projects.toArray();

    let projectCustomStatuses = projectsRes
        .map(project => (Array.isArray(project?.project?.customStatuses) ? project?.project?.customStatuses : []))
        .flat()
        .map((projStatus: any) => {
            if (projStatus.customStatusId?._id) {
                const customStatus = projStatus.customStatusId;
                // add necessary update to usedFor is applicable
                return customStatus;
            } else {
                return null;
            }
        })
        .filter((status: any) => status !== null);

    dispatch?.({
        type: newProjectActionTypes.LOAD_PROJECT_CUSTOM_STATUS_LIST,
        payload: projectCustomStatuses,
    });
    return projectCustomStatuses;
};
