import LaunchIcon from '@mui/icons-material/Launch';
import { makeStyles } from '@mui/styles';
import React, { useContext } from 'react';
import { Cell } from '../../components-ui/table/components-renderers/Cell';
import { ParamsContext } from '../../components-ui/table/contexts/params';
import { format } from 'date-fns';
import { Javascript } from '@mui/icons-material';
import { AnyARecord } from 'dns';

export type CustomActionCellProps = {
    link?: string;
    text?: string;
};

const useStyles = makeStyles({
    greenlink: {
        color: 'rgb(36,192,177)!important',
        textDecoration: 'none',
        fontWeight: 'bold',
        cursor: 'pointer',
        background: 'transparent',
        border: 'none',
        display: 'flex',
        placeContent: 'center',
        placeItems: 'center',
        gap: '0.5rem',
        width: '100%',
        height: '100%',
    },
});

/**
 *
 * @param link
 * Edit this component to allow react routing
 */
export function CustomActionCell({callbacks, row}: {callbacks:any;row: any}) {
    const classes = useStyles();
    return (
        <Cell   data-testid='cell'>
                <a onClick={(e) =>  callbacks?.onTriggerModalDetail?.(e, row)}  href="javascript:void(0)" className={classes.greenlink}>{format(new Date(row?.eventId?.date as Date), 'MMMM dd, yyyy')}</a>
        </Cell>
    );
}
