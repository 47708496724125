interface Coordinates {
    top: number;
    left: number;
    height: number;
}

export const getTextBoundingRect = (
    input: any,
    selectionStart: number = 0,
    selectionEnd: number = selectionStart,
    debug?: boolean,
): Coordinates => {
    // Basic parameter validation
    if (!input || !('value' in input)) {
        throw new Error('Invalid input element');
    }

    if (typeof selectionStart === 'string') {
        selectionStart = parseFloat(selectionStart);
    }

    if (typeof selectionStart !== 'number' || isNaN(selectionStart)) {
        selectionStart = 0;
    }

    if (selectionStart < 0) {
        selectionStart = 0;
    } else {
        selectionStart = Math.min(input.value.length, selectionStart);
    }

    if (typeof selectionEnd === 'string') {
        selectionEnd = parseFloat(selectionEnd);
    }

    if (typeof selectionEnd !== 'number' || isNaN(selectionEnd) || selectionEnd < selectionStart) {
        selectionEnd = selectionStart;
    }

    if (selectionEnd < 0) {
        selectionEnd = 0;
    } else {
        selectionEnd = Math.min(input.value.length, selectionEnd);
    }

    // If available (thus IE), use the createTextRange method
    if (isInputElement(input) && typeof input?.createTextRange === 'function') {
        const range = input.createTextRange();
        range.collapse(true);
        range.moveStart('character', selectionStart);
        range.moveEnd('character', selectionEnd - selectionStart);
        return range.getBoundingClientRect();
    }

    // createTextRange is not supported, create a fake text range
    const offset = getInputOffset(),
        width = getInputCSS('width', true),
        height = getInputCSS('height', true);
    let topPos = offset.top;
    let leftPos = offset.left;

    // Styles to simulate a node in an input field
    // use pre-wrap instead of wrap for white-space to support wrapping in textareas
    let cssDefaultStyles = 'white-space:pre-wrap;padding:0;margin:0;';
    const listOfModifiers = [
        'direction',
        'font-family',
        'font-size',
        'font-size-adjust',
        'font-variant',
        'font-weight',
        'font-style',
        'letter-spacing',
        'line-height',
        'text-align',
        'text-indent',
        'text-transform',
        'word-wrap',
        'word-spacing',
    ];

    topPos += parseFloat(getInputCSS('padding-top', true) as string);
    topPos += parseFloat(getInputCSS('border-top-width', true) as string);
    leftPos += parseFloat(getInputCSS('padding-left', true) as string);
    leftPos += parseFloat(getInputCSS('border-left-width', true) as string);
    leftPos += 1; // Seems to be necessary

    for (const property of listOfModifiers) {
        cssDefaultStyles += `${property}:${getInputCSS(property)};`;
    }
    // End of CSS variable checks

    const text = input.value,
        textLen = text.length,
        fakeClone = document.createElement('div');

    if (selectionStart > 0) {
        appendPart(0, selectionStart);
    }

    const fakeRange = appendPart(selectionStart, selectionEnd);

    if (textLen > selectionEnd) {
        appendPart(selectionEnd, textLen);
    }

    // Styles to inherit the font styles of the element
    fakeClone.style.cssText = cssDefaultStyles;

    // Styles to position the text node at the desired position
    fakeClone.style.position = 'absolute';
    fakeClone.style.top = `${topPos}px`;
    fakeClone.style.left = `${leftPos}px`;
    fakeClone.style.width = `${width}px`;
    fakeClone.style.height = `${height}px`;
    document.body.appendChild(fakeClone);

    const returnValue = fakeRange.getBoundingClientRect(); // Get rect

    if (!debug) {
        fakeClone.parentNode?.removeChild(fakeClone); // Remove temp
    }

    return returnValue;

    // Local functions for readability of the previous code
    function appendPart(start: number, end: number): HTMLSpanElement {
        const span = document.createElement('span');
        const tmpText = text.substring(start, end);

        span.style.cssText = cssDefaultStyles; // Force styles to prevent unexpected results

        // Add a space if it ends in a newline
        if (/[\n\r]$/.test(tmpText)) {
            span.textContent = tmpText + ' ';
        } else {
            span.textContent = tmpText;
        }

        fakeClone.appendChild(span);
        return span;
    }

    // Computing offset position
    function getInputOffset() {
        const body = document.body,
            win = window,
            docElem = document.documentElement,
            box = document.createElement('div');

        box.style.paddingLeft = box.style.width = '1px';
        body.appendChild(box);

        const isBoxModel = box.offsetWidth === 2;
        body.removeChild(box);

        const inputBox = input.getBoundingClientRect();
        const clientTop = docElem.clientTop || body.clientTop || 0;
        const clientLeft = docElem.clientLeft || body.clientLeft || 0;
        const scrollTop = win.pageYOffset || (isBoxModel && docElem.scrollTop) || body.scrollTop;
        const scrollLeft = win.pageXOffset || (isBoxModel && docElem.scrollLeft) || body.scrollLeft;

        return {
            top: inputBox.top + scrollTop - clientTop,
            left: inputBox.left + scrollLeft - clientLeft,
        };
    }

    function getInputCSS(prop: string, isNumber?: boolean): string | number {
        const val = window.getComputedStyle(input, null).getPropertyValue(prop);
        return isNumber ? parseFloat(val) : val;
    }
    function isInputElement(el: any): el is any {
        return el.tagName.toLowerCase() === 'input';
    }
};
