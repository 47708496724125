import React, { useState, useEffect } from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Typography,
} from '@mui/material';
import { t } from 'i18next';
import API from '../../../config/api';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import { Channel } from '../types';
import UserSelect from '../../../modules/UserSelect';
import { returnChatUserCanAddUser } from '../utils/helpers';
import { trackEvent } from '../../../components-app/GAListener';

interface AddEditProps {
    open: boolean;
    close: () => void;
    mode: 'add' | 'edit';
    fetchChannelList?: () => void;
    channel?: Channel;
    updateChannelMembers?: (value: { channelName: string; initialUsers: any[]; readOnly: boolean }) => void;
}

export const AddEditChannelDetails = ({
    open,
    close,
    mode,
    fetchChannelList,
    channel,
    updateChannelMembers,
}: AddEditProps) => {
    const [channelData, setChannelData] = useState({
        channelName: channel && channel.name ? channel.name : '',
        initialUsers: [],
        readOnly: channel && channel.readOnly ? channel.readOnly : false,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [userLoading, setUserloading] = useState<boolean>(false);

    const userListCall = (queryString: string) => {
        API.get(`/userTypes/all?${queryString}`)
            .then(result => {
                return result.json();
            })
            .then(data => {
                setChannelData({
                    ...channelData,
                    initialUsers: data,
                });
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setUserloading(false);
            });
    };

    useEffect(() => {
        if (mode === 'edit' && channel && Array.isArray(channel.members) && channel.members.length > 0) {
            setUserloading(true);
            const queryString = channel.members.map(id => `includeUsers=${id}`).join('&');
            userListCall(queryString);
        } else if (mode === 'add') {
            const user = window?.userabstraction?._id;
            if (user) {
                setUserloading(true);
                const query = `includeUsers=${user}`;
                userListCall(query);
            }
        }
    }, [channel, mode]);

    const addChannel = (postChannelData: { channelName: string; initialUsers: any[]; readOnly: boolean }) => {
        trackEvent('Messenger', 'Create Channel', 'Click');
        setLoading(true);
        const membersids = postChannelData?.initialUsers?.map((user: any) => (user._id ? user._id : user)) || [];
        API.post('/chatchannel', {
            name: postChannelData.channelName,
            members: membersids,
            readOnly: postChannelData.readOnly,
        })
            .then(res => {
                return res.json();
            })
            .then(() => {
                fetchChannelList && fetchChannelList();
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
                setChannelData({
                    channelName: '',
                    initialUsers: [],
                    readOnly: false,
                });
                close && close();
            });
    };

    const editChannel = (
        channelId: string | undefined,
        channelEditData: {
            channelName: string;
            initialUsers: any[];
            readOnly: boolean;
        },
    ) => {
        trackEvent('Messenger', 'Edit Channel', 'Click');
        if (channelId) {
            setLoading(true);
            const membersids = channelEditData?.initialUsers?.map((user: any) => (user._id ? user._id : user)) || [];
            const payload = { name: channelEditData.channelName, members: membersids, readOnly: channelEditData.readOnly };

            API.put(`/chatchannel/${channelId}`, payload)
                .then(result => {
                    return result.json();
                })
                .then(data => {
                    updateChannelMembers && updateChannelMembers({ ...channelEditData, initialUsers: membersids });
                    fetchChannelList && fetchChannelList();
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                    close && close();
                });
        } else {
            return;
        }
    };

    return (
        <Box>
            <Dialog
                PaperProps={{
                    sx: {
                        background: 'linear-gradient(#e8a2ff -200%, #ffffff 100%)',
                    },
                }}
                fullWidth
                fullScreen
                open={open}
            >
                <DialogTitle>{mode === 'add' ? t('##AddChannel') : t('##EditChannel')}</DialogTitle>
                <DialogContent style={{ maxWidth: '100%' }}>
                    <Box display='flex' flexDirection='column' gap={5} maxWidth='100%'>
                        {userLoading ? (
                            <>
                                <Typography>{t('##Loading')}</Typography>
                            </>
                        ) : (
                            <UserSelect
                                isDisabled={!returnChatUserCanAddUser(window?.user?.roles)}
                                multiple
                                labelSlug='##AddNewChannelMembers'
                                onChange={(value: any, _: any) =>
                                    setChannelData({
                                        ...channelData,
                                        initialUsers: value,
                                    })
                                }
                                initialValue={channelData.initialUsers}
                            />
                        )}

                        {!channel?.context && !channel?.context?.contextObject && (
                            <TextField
                                value={channelData.channelName}
                                required={true}
                                onChange={e =>
                                    setChannelData({
                                        ...channelData,
                                        channelName: e.target.value,
                                    })
                                }
                                variant='standard'
                                label={t('##EnterNewChannelName')}
                                fullWidth
                            />
                        )}

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={channelData.readOnly}
                                    value={channelData.readOnly}
                                    onChange={e => setChannelData({ ...channelData, readOnly: e.target.checked })}
                                />
                            }
                            label={t('##ReadOnlyChannel')}
                        />
                    </Box>
                </DialogContent>
                <DialogActions style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                    <Button
                        onClick={() => {
                            setChannelData({
                                channelName: '',
                                initialUsers: [],
                                readOnly: false,
                            });
                            close && close();
                        }}
                        color='error'
                    >
                        {t('##Cancel')}
                    </Button>
                    {loading ? (
                        <LoadingButton
                            loading
                            loadingPosition='start'
                            startIcon={<AddIcon />}
                            variant='text'
                            color='error'
                            size='small'
                            sx={{ height: '50px', width: '60%' }}
                        >
                            <span>{t('##Add')}</span>
                        </LoadingButton>
                    ) : (
                        <Button
                            color='secondary'
                            variant='contained'
                            disabled={channelData.channelName.length === 0}
                            sx={{ height: '50px', width: '60%' }}
                            onClick={() =>
                                mode === 'add' ? addChannel(channelData) : editChannel(channel?._id, channelData)
                            }
                        >
                            {mode === 'add' ? t('##Add') : t('##Save')}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Box>
    );
};
