import * as React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    ButtonGroup,
    Chip,
    Divider,
    FilledTextFieldProps,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    Link,
    MenuItem,
    Select,
    StandardTextFieldProps,
    Tab,
    Tabs,
    TextField,
    TextFieldVariants,
    Typography,
} from '@mui/material';
import API from '../../config/api';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import EmpplanTheme from '../elements/ThemeWrapper/EmpplanTheme';
import EmpplanSnackbarWrapper, { EmpplanSnackbarContext } from '../Snackbar';
import GenericFilePresentation from '../GenericFilePresentation';
import useCountries from '../../hooks/useCountries';
import { formatDateTime } from '../../utils/date';
import CancelIcon from '@mui/icons-material/Cancel';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HistoryIcon from '@mui/icons-material/History';
import CommentIcon from '@mui/icons-material/Comment';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import ReactSoldItemsTable from '../../pages/ReactSoldItemsTable';
import { EventOverviewFixedProductSimpleGrid } from '../EventOverviewFixedProductSimpleGrid';
import CRMHistoryComponent from './CRMHistoryComponent';
import CommentComponent from './CommentComponent';
import useModal from '../../components-ui/useModal';
import CreateAppointment from './CreateAppointment';
import { useState } from 'react';
import { UserEditCell } from '../CRMList/components-renders/UserEditCell';
import GenericCustomModelFields from '../GenericCustomModelFields';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { IEvent } from '../../utils/event';
import { GpsFixedSharp } from '@mui/icons-material';
import { getFileFromServer } from '../../hooks/useFileUpload/uploadHelper';
import useViewMagicSettings from '../../hooks/useViewMagicSettings';
import { CRMData } from './types';
import CommonHistory from '../CommonHistory';

interface ViewEditCrmProps {
    crmId: string | undefined;
    associatedby: any;
    callbacks: any;
    extraOptions: any;
    commonSelect: any;
}

function a11yProps(index: Number | String) {
    return {
        id: `simple-tab-${index}`,
        // 'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            {...other}
            // style={{height:"calc(100vh - 112px)"}}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

export const updateResidentialUnit = async (newCrmData: CRMData) => {
    if (!newCrmData) return;
    try {
        const payload = {
            unit: newCrmData.residentialUnit,
            streetName: newCrmData.address.streetName,
            streetNumber: newCrmData.address.streetNumber,
            zipCode: newCrmData.address.zipCode,
            city: newCrmData.address.city,
        };
        const serverResponse = await API.post('/crm/updateresidentialunit', payload);
        const data = await serverResponse.json();
        console.log(data);
    } catch (error) {
        console.error(error);
    }
};


function ViewEditCrm({ crmId, callbacks, extraOptions, commonSelect, associatedby }: ViewEditCrmProps) {
    const { t } = useTranslation();
    const snackbarTools = React.useContext(EmpplanSnackbarContext);

    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const { countries } = useCountries();

    const [crmData, setCrmData] = React.useState<CRMData | null>(null);
    const [crmFormData, setCrmFormData] = React.useState<CRMData | null>(null);

    const [loadings, setLoadings] = React.useState(false);
    const [filteredLabels, setFilteredLabels] = React.useState<any>([]);
    const [changedField, setChangedField] = React.useState<any>([]);
    const [addressChange, setAddressChange] = React.useState<boolean>(false);
    const [genericFields, setGenericFields] = useState({});
    const [rerenderComponent, setRerenderComponent] = useState<symbol>();
    const [signFileData, setSignFileData] = useState<any>(null);

    const [values, setValues] = React.useState(0);
    const [CustomModal, openModal, closeModal] = useModal();
    const [associatedbyUser, setAssociatedbyUser] = useState(associatedby ? associatedby : null);
    const isClientUser = window && window.userabstraction.usertype === 'client' ? true : false;
    const isAdmin = window.user?.roles?.indexOf('EventAdd') !== -1;
    const [automaticSave, setAutomaticSave] = useState<boolean>(false);
    const [leadStatusType, setLeadStatusType] = useState<any>([]);

    const { viewMagicSettings, updateViewMagicSettings } = useViewMagicSettings<{
        listFilters: any;
        page: number;
        rowsPerPage: number;
    }>({
        module: 'CRMlistMapview',
        view: 'CRMlistmapViewpage',
    });

    const crmFormDataChangeHandler = (attributePath: string, value: any) => {
        setCrmFormData(_.set({ ...crmFormData } as CRMData, attributePath, value));
    };

    const crmChangedField = (attributePath: string, value: any, fieldConfig: any) => {
        setChangedField((prevChangedField: any) => {
            const existingFieldIndex = prevChangedField.findIndex(
                (field: any) => field.fieldName === fieldConfig.fieldLabelName,
            );

            if (
                fieldConfig.fieldLabelName === '##Company' ||
                fieldConfig.fieldLabelName === '##StreetName' ||
                fieldConfig.fieldLabelName === '##StreetNumber' ||
                fieldConfig.fieldLabelName === '##StreetAppendix' ||
                fieldConfig.fieldLabelName === '##MoreAddressStuff' ||
                fieldConfig.fieldLabelName === '##Postal' ||
                fieldConfig.fieldLabelName === '##City' ||
                fieldConfig.fieldLabelName === '##Country'
            ) {
                setAddressChange(true);
            }

            if (existingFieldIndex !== -1) {
                const updatedChangedField = [...prevChangedField];
                updatedChangedField[existingFieldIndex] = {
                    ...updatedChangedField[existingFieldIndex],
                    newFieldData: value,
                };
                return updatedChangedField;
            } else {
                return [
                    ...prevChangedField,
                    {
                        fieldName: fieldConfig.fieldLabelName,
                        fieldType: fieldConfig.fieldType,
                        oldFieldData: _.get(crmData, attributePath),
                        newFieldData: value,
                    },
                ];
            }
        });
    };

    React.useEffect(() => {
        (async () => {
            setLoading(true);
            const res = await API.get(`/crmCustomLeadStatusList?active=true`);
            const leadStaList = await res.json();

            if (Array.isArray(leadStaList)) {
                setLeadStatusType(leadStaList);
                setLoading(false);
            }
        })().catch(() => {
            setLoading(false);
        });
    }, []);

    const getCRMData = (crmId: any, triggerResidentialUnitUpdate: boolean = false) => {
        setCrmData(null);
        setLoading(true);
        setSignFileData(null);

        if (crmId) {
            API.get(`/crm/${crmId}`)
                .then(res => res.json())
                .then(jsonData => {
                    setCrmData(jsonData);
                    if (triggerResidentialUnitUpdate) {
                        updateResidentialUnit(jsonData);
                    }
                    setCrmFormData(JSON.parse(JSON.stringify(jsonData)));
                    setSignFileData(null);
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setCrmData(null);
            setCrmFormData(null);
            setLoading(false);
        }
    };

    React.useEffect(() => {
        // console.log('refreshing page', crmId);
        getCRMData(crmId);
    }, [crmId, rerenderComponent]);

    const projectId = crmData?.projectId[0];
    const streetName = crmData?.address?.streetName ?? '';
    const channelName = crmData?.address?.streetNumber
        ? streetName + ' ' + crmData?.address?.streetNumber + crmData?.address?.streetAppendix
        : streetName + ' ' + moment(crmData?.createdAt).tz('Europe/Berlin').format('DD-MM-YYYY');

    const handleSave = () => {
        setSaving(true);
        let triggerResidentialUpdate = false;

        if (Number(crmData?.residentialUnit) !== Number(crmFormData?.residentialUnit)) {
            triggerResidentialUpdate = true;
        }
        
        if (crmFormData) {
            crmFormData.associatedby = associatedbyUser;
            crmFormData.genericFields = genericFields;
        }

        const toSaveFormData = { ...crmFormData };
        if (toSaveFormData?.address?._country === '') {
            toSaveFormData.address._country = null;
        }
        const cdata = {
            ...crmFormData,
            changedField: changedField,
        };

        API.put(`/crm/${crmId}`, cdata)
            .then(res => res.json())
            .then(jsonData => {
                if (jsonData?.success && jsonData.data._id) {
                    // setCrmData(jsonData.data);
                    // setCrmFormData(jsonData.data);
                    getCRMData(crmId, triggerResidentialUpdate);
                    if (addressChange) {
                        UpdateLatLong(crmId);
                        setAddressChange(false);
                    }
                    snackbarTools.createToast({ type: 'success', message: t`##CRMAdded` });
                } else {
                    console.error('failed to save crm', jsonData);
                    throw new Error('failed to save crm');
                }
                setEditMode(false);
            })
            .catch(error => {
                snackbarTools.createToast({ type: 'error', message: t`##CRMFailed` });
                console.error(error);
            })
            .finally(() => {
                setSaving(false);
            });
    };

    React.useEffect(() => {
        let usedForValue = 'CRM';
        (async () => {
            setLoadings(true);
            try {
                if (projectId) {
                    const resp = await API.get(`/projects/customstatus/filter?&skip=0&project_id=${projectId}`);
                    const result = await resp.json();
                    if (result.success) {
                        const customList = result.data.customs;
                        if (Array.isArray(customList)) {
                            const filteredLabels = customList.filter((label: any) => {
                                const usedFor = label?.usedFor || [];
                                return usedFor.includes(usedForValue);
                            });
                            setFilteredLabels(filteredLabels);
                            setLoadings(false);
                        }
                    } else {
                        setLoadings(false);
                    }
                }
            } catch (error) {
                console.error('error loading projects', error);
            }
        })().catch(() => {
            setLoadings(false);
        });
    }, [projectId]);

    const getGenericFields = () => {
        API.getReqestWithParams({ url: '/api/customModelField/field', params: { model: 'CRM' } })
            .then(result => {
                const fields: any = result;
                const transformedFields: Record<string, any> = {};
                fields.forEach((field: any) => {
                    transformedFields[field.fieldAttributeName] = crmData?.genericFields?.[field.fieldAttributeName];
                });
                setGenericFields(transformedFields);
                console.log('transformedFields', transformedFields);
            })
            .catch(e => {});
    };

    React.useEffect(() => {
        getGenericFields();
    }, [crmData]);

    React.useEffect(() => {
        if(crmData?.address?.loc?.[0] && crmData?.address?.loc?.[1]){
            updateViewMagicSettings({
                listFilters: {
                  ...viewMagicSettings?.settingObject?.listFilters,
                  latitude: crmData?.address?.loc?.[1],
                  longitude: crmData?.address?.loc?.[0],
                }, 
                page: 0, 
                rowsPerPage: 0
            });
        }        
    }, [crmData, viewMagicSettings?.settingObject?.listFilters]);

    const UpdateLatLong = (crmId: any) => {
        setLoadings(true);
        API.post(`crmlist/updateLatLong`, { ids: [crmId], addressChange: true })
            .then(res => res.json())
            .then(response => {
                if (response?.success) {
                    setLoadings(false);
                    snackbarTools.createToast({ type: 'success', message: t`##LatLongUpdated` });
                } else {
                    setLoadings(false);
                }
            })
            .catch(e => {
                //setDisableForm(false)
                setLoadings(false);
            });
    };

    const handleFollowUpStatus = (event: any, config: any) => {
        const newStatus = event.target.value;
        const newStatusData = leadStatusType?.find((lead:any) => newStatus === lead?._id);
        if (config.fieldAttributeName == 'status._id') {
            setCrmFormData((prevState: any) => {
                const updatedFormData = _.set({ ...prevState }, 'status', newStatusData);
                if (newStatus !== '##FollowUp') {
                    _.set(updatedFormData, 'followUp[0].date', null);
                }
                return updatedFormData;
            });
        } else {
            crmFormDataChangeHandler(config.fieldAttributeName, newStatus);
        }
        crmChangedField(config.fieldAttributeName, newStatus, config);
    };

    const handleDeleteChip = (chipValue: string, event: React.MouseEvent, config: any, fieldConfig: any) => {
        event.stopPropagation();
        const currentValue = _.get(crmFormData, config);
        if (Array.isArray(currentValue)) {
            const updatedValue = currentValue.filter(val => val !== chipValue);
            crmFormDataChangeHandler(config, updatedValue);
            crmChangedField(config, updatedValue, fieldConfig);
        }
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValues(newValue);
    };

    const refreshData = (selectedUser?: any, snackbarMessgeObj?: any) => {
        closeModal();
        getCRMData(crmId);
        if (selectedUser !== undefined) {
            setAssociatedbyUser(selectedUser);
        }
        if (snackbarMessgeObj !== undefined) {
            snackbarTools.createToast(snackbarMessgeObj);
        }
    };

    const setAssociatedbyUserHandler = (value: any) => {
        setAssociatedbyUser(value);
        const data = { ...crmFormData, userId: value };
        setCrmFormData(data as CRMData);
    };

    const formConfig = [
        {
            title: '##ClientsDetails',
            fields: [
                {
                    fieldLabelName: '##FormOfAddress',
                    fieldType: 'select',
                    fieldAttributeName: 'formOfAddress',
                    config: {
                        options: ['##MaleFormOfAddress', '##FemaleFormOfAddress', '##DiversFormOfAddress'].map(kv => ({
                            label: kv,
                            value: kv,
                        })),
                    },
                },
                {
                    fieldLabelName: '##FirstName',
                    fieldType: 'text',
                    fieldAttributeName: 'firstName',
                },
                {
                    fieldLabelName: '##LastName',
                    fieldType: 'text',
                    fieldAttributeName: 'lastName',
                },
                {
                    fieldLabelName: '##Email',
                    fieldType: 'text',
                    fieldAttributeName: 'email',
                },
                {
                    fieldLabelName: '##Mobile',
                    fieldType: 'text',
                    fieldAttributeName: 'mobile',
                },
                {
                    fieldLabelName: '##Phone',
                    fieldType: 'text',
                    fieldAttributeName: 'tel',
                },
                {
                    fieldLabelName: '##DateOfBirth',
                    fieldType: 'date',
                    fieldAttributeName: 'dateOfBirth',
                },
            ],
        },
        {
            title: '##ResidentialAddress',
            fields: [
                {
                    fieldLabelName: '##Company',
                    fieldType: 'text',
                    fieldAttributeName: 'address.company',
                },
                {
                    fieldLabelName: '##StreetName',
                    fieldType: 'text',
                    fieldAttributeName: 'address.streetName',
                },
                {
                    fieldLabelName: '##StreetNumber',
                    fieldType: 'text',
                    fieldAttributeName: 'address.streetNumber',
                },
                {
                    fieldLabelName: '##StreetAppendix',
                    fieldType: 'text',
                    fieldAttributeName: 'address.streetAppendix',
                },
                {
                    fieldLabelName: '##MoreAddressStuff',
                    fieldType: 'text',
                    fieldAttributeName: 'address.additionalAddressline',
                },
                {
                    fieldLabelName: '##Postal',
                    fieldType: 'text',
                    fieldAttributeName: 'address.zipCode',
                },
                {
                    fieldLabelName: '##City',
                    fieldType: 'text',
                    fieldAttributeName: 'address.city',
                },
                {
                    fieldLabelName: '##Country',
                    fieldType: 'select',
                    fieldAttributeName: 'address._country',
                    config: {
                        options: countries.map(c => ({ label: c.name, value: c._id })),
                    },
                },
                {
                    fieldLabelName: '##LatLong',
                    fieldType: 'latLong',
                    fieldAttributeName: 'address.loc',
                },
                {
                    fieldLabelName: '##ResidentialUnits',
                    fieldType: 'number',
                    fieldAttributeName: 'residentialUnit',
                },
            ],
        },
        {
            title: '##Other',
            fields: [
                {
                    fieldLabelName: '##LeadStatus',
                    fieldType: 'select',
                    fieldAttributeName: 'status._id',
                    config: {
                        options: (() => {
                            const hasValidSortOrder = leadStatusType.some(
                                (c: any) => c.sortOrder !== null && c.sortOrder !== 0
                            );
                    
                            const mappedOptions = leadStatusType.map((c: any) => ({
                                value: c._id,
                                label: c.statusLabel
                                    ? c.type === 'EMPPLAN'
                                        ? t('' + c.slangText)
                                        : t('' + c.statusLabel)
                                    : t('' + c.custom_status),
                            }));
                    
                            return hasValidSortOrder
                                ? mappedOptions
                                : mappedOptions.sort((a: { label: string }, b: { label: any }) => a.label.localeCompare(b.label));
                        })(),
                    }
                },
                {
                    fieldLabelName: '##FollowUpDate',
                    fieldType: 'followdate',
                    fieldAttributeName: 'followUp[0].date',
                    hideContent: 'status',
                },
                {
                    fieldLabelName: '##Labels',
                    fieldType: 'empplanselect',
                    fieldAttributeName: 'customStatuses',
                    config: {
                        options: filteredLabels.map((eachCountry: any) => ({
                            value: eachCountry._id,
                            label: eachCountry.statusLabel
                                ? eachCountry.type === 'EMPPLAN'
                                    ? t('' + eachCountry.slangText)
                                    : t('' + eachCountry.statusLabel)
                                : t('' + eachCountry.custom_status),
                        })),
                    },
                    fieldMultiple: 'multiple',
                },
                {
                    fieldLabelName: '##CreatedBy',
                    fieldType: 'label',
                },
                {
                    fieldLabelName: '##AssociatedTo',
                    fieldType: 'usereditcell',
                },
                {
                    fieldLabelName: '##Signature',
                    fieldType: 'uploads',
                    fieldAttributeName: 'signId',
                    config: {
                        remotePath: '/customreportresult/upload/data',
                    },
                },
                {
                    fieldLabelName: '##DataPolicyLink',
                    fieldType: 'textview',
                    fieldAttributeName: 'dataProtectionContent',
                    formatContent: (content: string) => (content ? content : ' '),
                },
                {
                    fieldLabelName: '##AcceptedAt',
                    fieldType: 'textview',
                    fieldAttributeName: 'dataProtectionAccepted',
                    formatContent: (content: string) => (content ? formatDateTime(content) : 'Not Available'),
                },
            ],
        },
    ];

    let crmEvents: string[] = [];

    if (crmData?.eventId && Array.isArray(crmData.eventId)) {
        const eventIds: IEvent[] = crmData.eventId as IEvent[];
        crmEvents = eventIds.map(event => event._id.toString());
    }

    const soldItemTableprops = {
        extraOptions: {
            events: crmEvents,
            isFromCrm: true,
            isClientUser: extraOptions?.isClientUser,
            forDetails: true,
        },
        callbacks: {
            onTriggerModalDetail: callbacks?.onTriggerModalDetail,
        },
    };

    const eventsTableprops = {
        data: {
            filters: {
                project_id: projectId,
            },
        },
        commonSelect: commonSelect,
        extraOptions: {
            events: crmEvents,
            isFromCrm: true,
            ...extraOptions,
        },
        callbacks: {
            reactSelect: callbacks?.reactSelect,
            updateEventsm: callbacks?.updateEventsm,
        },
    };

    return (
        <>
            <CustomModal />
            {(loading || saving) && <LinearProgress />}
            {!loading && crmData?._id === crmId && crmFormData?._id === crmId && (
                <Box sx={{ m: 4, paddingBottom: 6 }} key={crmData?._id}>
                    <Grid container alignItems='center' justifyContent='space-between'>
                        <Grid item>{/* <h2>{t`##CRM`}</h2> */}</Grid>
                        <Grid item>
                            {/* <ButtonGroup> */}
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={() => {
                                    openModal(
                                        <CreateAppointment
                                            crmData={crmData}
                                            associatedby={associatedbyUser}
                                            projectId={projectId}
                                            refreshData={refreshData}
                                        />,
                                        t('##AddNewEvent'),
                                    );
                                }}
                                sx={{
                                    marginRight: '10px !important',
                                }}
                            >
                                {t`##AddNewEvent`}
                            </Button>
                            <Button
                                variant='contained'
                                color={editMode ? 'secondary' : 'primary'}
                                onClick={() => {
                                    if (!editMode) {
                                        setEditMode(true);
                                    } else {
                                        handleSave();
                                    }
                                }}
                            >
                                {editMode ? t`##Save` : t`##Edit`}
                            </Button>
                            {editMode && (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => {
                                        setEditMode(false);
                                    }}
                                    sx={{
                                        marginLeft: '10px !important',
                                    }}
                                >
                                    {t`##Cancel`}
                                </Button>
                            )}
                            {/* </ButtonGroup> */}
                        </Grid>
                    </Grid>
                    {/* <pre>{JSON.stringify(crmData, null, 4)}</pre> */}
                    <Grid container spacing={4} my={2}>
                        {formConfig.map((formRow, idx) => (
                            <Grid container item key={idx} xs={12} md={6} lg={4} spacing={1} direction='column'>
                                {formRow.title && (
                                    <Grid item>
                                        <h3>{t(`${formRow.title}`)}</h3>
                                    </Grid>
                                )}
                                {formRow.fields.map((fieldConfig: any) => {
                                    if (fieldConfig.fieldType === 'textview') {
                                        let content: string = _.get(crmFormData, fieldConfig.fieldAttributeName);
                                        if (fieldConfig.formatContent) {
                                            content = fieldConfig.formatContent?.(content);
                                        }
                                        return (
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    maxRows={4}
                                                    variant='standard'
                                                    label={t(fieldConfig.fieldLabelName)}
                                                    disabled
                                                    value={content}
                                                    onChange={e => {
                                                        crmFormDataChangeHandler(
                                                            fieldConfig.fieldAttributeName,
                                                            e.target.value,
                                                        );
                                                        crmChangedField(
                                                            fieldConfig.fieldAttributeName,
                                                            e.target.value,
                                                            fieldConfig,
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        );
                                    }

                                    if (fieldConfig.fieldType === 'number') {
                                        return (
                                            <Grid item sx={{ display: 'flex' }}>
                                                <TextField
                                                    fullWidth
                                                    variant='standard'
                                                    type='number'
                                                    label={t(fieldConfig.fieldLabelName)}
                                                    disabled={!editMode || saving}
                                                    value={_.get(crmFormData, fieldConfig.fieldAttributeName)}
                                                    onChange={e => {
                                                        crmFormDataChangeHandler(
                                                            fieldConfig.fieldAttributeName,
                                                            e.target.value,
                                                        );
                                                        crmChangedField(
                                                            fieldConfig.fieldAttributeName,
                                                            e.target.value,
                                                            fieldConfig,
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        );
                                    }

                                    if (fieldConfig.fieldType === 'text') {
                                        return (
                                            <Grid item sx={{ display: 'flex' }}>
                                                <TextField
                                                    fullWidth
                                                    variant='standard'
                                                    label={t(fieldConfig.fieldLabelName)}
                                                    disabled={!editMode || saving}
                                                    value={_.get(crmFormData, fieldConfig.fieldAttributeName)}
                                                    onChange={e => {
                                                        crmFormDataChangeHandler(
                                                            fieldConfig.fieldAttributeName,
                                                            e.target.value,
                                                        );
                                                        crmChangedField(
                                                            fieldConfig.fieldAttributeName,
                                                            e.target.value,
                                                            fieldConfig,
                                                        );
                                                    }}
                                                />
                                                {(fieldConfig.fieldLabelName === '##Mobile' ||
                                                    fieldConfig.fieldLabelName === '##Phone') &&
                                                    _.get(crmFormData, fieldConfig.fieldAttributeName) && (
                                                        <a
                                                            href={`tel:${_.get(
                                                                crmFormData,
                                                                fieldConfig.fieldAttributeName,
                                                            )}`}
                                                            className={'greenLink'}
                                                        >
                                                            <CallIcon
                                                                sx={{
                                                                    marginTop: '8px',
                                                                }}
                                                            />
                                                        </a>
                                                    )}
                                                {fieldConfig.fieldLabelName === '##Email' &&
                                                    _.get(crmFormData, fieldConfig.fieldAttributeName) && (
                                                        <a
                                                            href={`mailto:${_.get(
                                                                crmFormData,
                                                                fieldConfig.fieldAttributeName,
                                                            )}`}
                                                            className={'greenLink'}
                                                        >
                                                            <EmailIcon
                                                                sx={{
                                                                    marginTop: '8px',
                                                                }}
                                                            />
                                                        </a>
                                                    )}
                                            </Grid>
                                        );
                                    }

                                    if (fieldConfig.fieldType === 'select') {
                                        return (
                                            <Grid item>
                                                <FormControl fullWidth variant='standard'>
                                                    <InputLabel id='select-label'>
                                                        {t(fieldConfig.fieldLabelName)}
                                                    </InputLabel>
                                                    <Select
                                                        labelId='select-label'
                                                        disabled={!editMode || saving}
                                                        id='select'
                                                        value={_.get(crmFormData, fieldConfig.fieldAttributeName)}
                                                        onChange={e => handleFollowUpStatus(e, fieldConfig)}
                                                        label={t(fieldConfig.fieldLabelName)}
                                                    >
                                                        <MenuItem value=''>
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {fieldConfig.config?.options?.map(
                                                            (option: { label: string; value: string }) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {t(option.label)}
                                                                </MenuItem>
                                                            ),
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        );
                                    }

                                    if (fieldConfig.fieldType === 'empplanselect') {
                                        return (
                                            <Grid item>
                                                <TextField
                                                    select
                                                    variant={'standard'}
                                                    fullWidth
                                                    label={t(fieldConfig.fieldLabelName)}
                                                    value={
                                                        (_.get(crmFormData, fieldConfig.fieldAttributeName) == null ||
                                                            typeof _.get(crmFormData, fieldConfig.fieldAttributeName) ===
                                                                'undefined') &&
                                                        fieldConfig?.fieldMultiple
                                                            ? []
                                                            : _.get(crmFormData, fieldConfig.fieldAttributeName)
                                                            ? _.get(crmFormData, fieldConfig.fieldAttributeName)
                                                            : ''
                                                    }
                                                    onChange={e => {
                                                        crmFormDataChangeHandler(
                                                            fieldConfig.fieldAttributeName,
                                                            e.target.value,
                                                        );
                                                        crmChangedField(
                                                            fieldConfig.fieldAttributeName,
                                                            e.target.value,
                                                            fieldConfig,
                                                        );
                                                    }}
                                                    SelectProps={{
                                                        multiple: fieldConfig.fieldMultiple,
                                                        MenuProps: {
                                                            sx: {
                                                                maxHeight: '40vh',
                                                            },
                                                        },
                                                        renderValue: fieldConfig.fieldMultiple
                                                            ? (selected: any) => (
                                                                  <Box
                                                                      sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                                                                  >
                                                                      {selected.map((val: any) => (
                                                                          <Chip
                                                                              key={val}
                                                                              label={
                                                                                  fieldConfig.config?.options.find(
                                                                                      (op: any) => op.value === val,
                                                                                  )?.label
                                                                              }
                                                                              clickable
                                                                              deleteIcon={
                                                                                  fieldConfig?.fieldMultiple ? (
                                                                                      <CancelIcon
                                                                                          onMouseDown={event =>
                                                                                              event.stopPropagation()
                                                                                          }
                                                                                      />
                                                                                  ) : undefined
                                                                              }
                                                                              onDelete={
                                                                                  fieldConfig?.fieldMultiple
                                                                                      ? e =>
                                                                                            handleDeleteChip(
                                                                                                val,
                                                                                                e,
                                                                                                fieldConfig.fieldAttributeName,
                                                                                                fieldConfig,
                                                                                            )
                                                                                      : undefined
                                                                              }
                                                                          />
                                                                      ))}
                                                                  </Box>
                                                              )
                                                            : undefined,
                                                    }}
                                                    disabled={!editMode || saving}
                                                    sx={{ width: '100%', marginBottom: '8px' }}
                                                >
                                                    {fieldConfig.config?.options
                                                        .sort((a: any, b: any) =>
                                                            (a.label || '').localeCompare(b.label || ''),
                                                        )
                                                        .map((option: any) => {
                                                            return (
                                                                <MenuItem
                                                                    key={option.value}
                                                                    value={option.value}
                                                                    disabled={!!option.disabled}
                                                                >
                                                                    <Box
                                                                        fontWeight={
                                                                            Array.isArray(
                                                                                _.get(
                                                                                    1,
                                                                                    fieldConfig.fieldAttributeName,
                                                                                ),
                                                                            ) &&
                                                                            _.get(
                                                                                crmFormData,
                                                                                fieldConfig.fieldAttributeName,
                                                                            ).find((v: any) => v === option.value) &&
                                                                            800
                                                                        }
                                                                    >
                                                                        {option.label}
                                                                    </Box>
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </TextField>
                                            </Grid>
                                        );
                                    }

                                    if (fieldConfig.fieldType === 'date') {
                                        return (
                                            <Grid item>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker
                                                        disableFuture
                                                        disabled={!editMode || saving}
                                                        format='DD.MM.YYYY'
                                                        openTo='day'
                                                        views={['year', 'month', 'day']}
                                                        value={
                                                            _.get(crmFormData, fieldConfig.fieldAttributeName)
                                                                ? moment(
                                                                      _.get(crmFormData, fieldConfig.fieldAttributeName),
                                                                  )
                                                                : null
                                                        }
                                                        onChange={(newValue: moment.MomentInput) => {
                                                            crmFormDataChangeHandler(
                                                                fieldConfig.fieldAttributeName,
                                                                new Date(moment(newValue).format('YYYY-MM-DD')),
                                                            );
                                                            crmChangedField(
                                                                fieldConfig.fieldAttributeName,
                                                                new Date(moment(newValue).format('YYYY-MM-DD')),
                                                                fieldConfig,
                                                            );
                                                        }}
                                                        label={t(fieldConfig.fieldLabelName)}
                                                        slotProps={{
                                                            textField: {
                                                                variant: 'standard', // Apply standard variant for bottom line
                                                                fullWidth: true,
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        );
                                    }

                                    if (fieldConfig.fieldType === 'followdate') {
                                        return (
                                            _.get(crmFormData, fieldConfig.hideContent) && _.get(crmFormData, fieldConfig.hideContent).isShowDateSelect && (
                                                <Grid item>
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <DateTimePicker
                                                            // minDate={
                                                            //     _.get(crmFormData, fieldConfig.fieldAttributeName)
                                                            //         ? moment(
                                                            //               _.get(
                                                            //                   crmFormData,
                                                            //                   fieldConfig.fieldAttributeName,
                                                            //               ),
                                                            //           )
                                                            //         : undefined
                                                            // }
                                                            disabled={!editMode || saving}
                                                            format='DD.MM.YYYY HH:mm'
                                                            openTo='day'
                                                            views={['year', 'month', 'day', 'hours', 'minutes']}
                                                            value={
                                                                _.get(crmFormData, fieldConfig.fieldAttributeName)
                                                                    ? moment(
                                                                          _.get(
                                                                              crmFormData,
                                                                              fieldConfig.fieldAttributeName,
                                                                          ),
                                                                      )
                                                                    : null
                                                            }
                                                            onChange={(newValue: moment.MomentInput) => {
                                                                console.log('newValue', newValue);
                                                                crmFormDataChangeHandler(
                                                                    fieldConfig.fieldAttributeName,
                                                                    newValue,
                                                                    // new Date(moment(newValue).format('YYYY-MM-DD HH:mm')),
                                                                );
                                                                crmChangedField(
                                                                    fieldConfig.fieldAttributeName,
                                                                    newValue,
                                                                    // new Date(moment(newValue).format('YYYY-MM-DD HH:mm')),
                                                                    fieldConfig,
                                                                );
                                                            }}
                                                            label={t(fieldConfig.fieldLabelName)}
                                                            slotProps={{
                                                                textField: {
                                                                    variant: 'standard', // Apply standard variant for bottom line
                                                                    fullWidth: true,
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            )
                                        );
                                    }

                                    if (fieldConfig.fieldType === 'uploads') {
                                        const value = _.get(crmFormData, fieldConfig.fieldAttributeName);

                                        if (!value || (Array.isArray(value) && value.length === 0)) return null; 
                                        
                                        getFileFromServer(value as string)
                                            .then(fileInfoRes => {
                                                setSignFileData(fileInfoRes);
                                            })
                                            .catch(err => {
                                                console.log('err', err);
                                            });

                                        if (signFileData && value) {
                                            if (Array.isArray(value) && value.length > 0) {
                                                return value.map(val => (
                                                    <Grid item>
                                                        {t(fieldConfig.fieldLabelName)}
                                                        <br />
                                                        <GenericFilePresentation
                                                            key={val}
                                                            file={val}
                                                            sxcss={{ width: '100%' }}
                                                        />
                                                        {signFileData && signFileData.created && (
                                                            <Typography color='text.secondary' variant='body2'>
                                                                {t('##Created')} :{' '}
                                                                {moment(signFileData.created)
                                                                    .tz('Europe/Berlin')
                                                                    .format('DD.MM.YYYY HH:mm')}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                ));
                                            } else {
                                                return (
                                                    <Grid item>
                                                        {t(fieldConfig.fieldLabelName)}
                                                        <br />
                                                        <GenericFilePresentation
                                                            key={value}
                                                            file={value}
                                                            sxcss={{ width: '100%' }}
                                                        />
                                                        {signFileData && signFileData.created && (
                                                            <Typography color='text.secondary' variant='body2'>
                                                                {t('##Created')} :{' '}
                                                                {moment(signFileData.created)
                                                                    .tz('Europe/Berlin')
                                                                    .format('DD.MM.YYYY HH:mm')}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                );
                                            }
                                        }
                                        return null;
                                    }

                                    if (fieldConfig.fieldType === 'label') {
                                        return (
                                            crmData?.createdBy && (
                                                <Grid item>
                                                    <Typography color='text.secondary' variant='body2'>
                                                        {t(fieldConfig.fieldLabelName)} {crmData?.createdBy?.firstName}{' '}
                                                        {crmData?.createdBy?.lastName}
                                                    </Typography>
                                                </Grid>
                                            )
                                        );
                                    }

                                    if (fieldConfig.fieldType === 'usereditcell') {
                                        return (
                                            <Grid item>
                                                <Typography color='text.secondary' variant='body2'>
                                                    {t(fieldConfig.fieldLabelName)}
                                                </Typography>
                                                <UserEditCell
                                                    row={crmData}
                                                    doAfterSave={false}
                                                    isDisabled={!editMode || saving}
                                                    onChangeUser={setAssociatedbyUserHandler}
                                                ></UserEditCell>
                                            </Grid>
                                        );
                                    }

                                    if (fieldConfig.fieldType === 'latLong') {
                                        return (
                                            <Grid item>
                                                <Typography color='text.secondary' variant='body2'>
                                                    {t(fieldConfig.fieldLabelName)} :{' '}
                                                    {crmData?.address?.loc?.[0] && crmData?.address?.loc?.[1] !== 0 ? (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <span>
                                                                {crmData?.address?.loc?.[1]} , {crmData?.address?.loc?.[0]}
                                                            </span>
                                                            <IconButton
                                                                aria-label='gps-location'
                                                                component='a'
                                                                target={'_blank'}
                                                                href={`https://maps.google.com/?q=${crmData?.address?.loc?.[1]},${crmData?.address?.loc?.[0]}`}
                                                            >
                                                                <GpsFixedSharp color={'secondary'} />
                                                            </IconButton>
                                                        </div>
                                                    ) : (
                                                        <Button
                                                            variant='contained'
                                                            onClick={() => UpdateLatLong(crmData?._id)}
                                                        >
                                                            {t('##UpdateLatLong')}
                                                        </Button>
                                                    )}
                                                </Typography>
                                            </Grid>
                                        );
                                    }

                                    return null;
                                })}
                            </Grid>
                        ))}
                    </Grid>

                    <Divider />

                    <Box display='flex' justifyContent='center' width='100%' sx={{ my: 2 }}>
                        <Tabs
                            value={values}
                            onChange={handleChange}
                            aria-label='icon tabs example'
                            sx={{ minWidth: 0, flexGrow: 1 }}
                        >
                            <Tab
                                label={t('##Events')}
                                icon={<CalendarTodayIcon />}
                                iconPosition='start'
                                aria-label='events'
                                {...a11yProps(0)}
                                // sx={{
                                //     minWidth: 0,
                                //     flexGrow: 1,
                                // }}
                            />
                            <Tab
                                label={t('##SoldProducts')}
                                icon={<AssessmentIcon />}
                                iconPosition='start'
                                aria-label='transactions'
                                {...a11yProps(1)}
                                // sx={{
                                //     minWidth: 0,
                                //     flexGrow: 1,
                                // }}
                            />
                            <Tab
                                label={t('##CRMHistory')}
                                icon={<HistoryIcon />}
                                iconPosition='start'
                                aria-label='crmhistory'
                                {...a11yProps(2)}
                            />
                            <Tab
                                label={t('##Comment')}
                                icon={<CommentIcon />}
                                iconPosition='start'
                                aria-label='comment'
                                {...a11yProps(3)}
                            />
                            <Tab
                                label={t('##MoreData')}
                                icon={<PlaylistAddIcon />}
                                iconPosition='start'
                                aria-label='genricFields'
                                {...a11yProps(4)}
                            />
                            <Tab
                                label={'History (Beta)'}
                                icon={<HistoryIcon />}
                                iconPosition='start'
                                aria-label='crmhistory'
                                {...a11yProps(5)}
                            />
                        </Tabs>
                    </Box>
                    <TabPanel key='maps' value={values} index={0}>
                        <Grid item xs={12}>
                            <EventOverviewFixedProductSimpleGrid {...eventsTableprops} />
                        </Grid>
                    </TabPanel>

                    {/* </TabPanel> */}
                    <TabPanel key='list' value={values} index={1}>
                        <Grid item xs={12}>
                            <ReactSoldItemsTable {...soldItemTableprops} />
                        </Grid>
                    </TabPanel>

                    <TabPanel key='history' value={values} index={2}>
                        <Grid item xs={12}>
                            <CRMHistoryComponent data={crmData?.crmChangeHistory} projectId={projectId} />
                        </Grid>
                    </TabPanel>

                    <TabPanel key='comment' value={values} index={3}>
                        {channelName !== null && crmData?._id && (
                            <Grid item xs={12}>
                                <CommentComponent
                                    assocObjectType='crm'
                                    assocObjectId={crmData?._id}
                                    assocObjectName={channelName}
                                    users={crmData?.userId}
                                />
                            </Grid>
                        )}
                    </TabPanel>

                    <TabPanel key='genricFields' value={values} index={4}>
                        <Grid container justifyContent={'start'} spacing={2} sx={{ p: 2 }}>
                            <GenericCustomModelFields
                                modelName={'CRM'}
                                customModelData={genericFields}
                                optionalCantAddNewFields={isAdmin ? false : true}
                                setGenericFields={setGenericFields}
                                setRerenderComponent={setRerenderComponent}
                                showFieldTypes={true}
                                disableMe={!(!editMode || saving)}
                            />
                        </Grid>
                    </TabPanel>

                    <TabPanel key='newhistory' value={values} index={5}>
                        <Grid item xs={12} mb={10}>
                            {crmData?._id && <CommonHistory model='CRM' modelRef={crmData?._id} />}
                        </Grid>
                    </TabPanel>
                </Box>
            )}
        </>
    );
}

function ViewEditCrmWrapper(props: ViewEditCrmProps) {
    return (
        <EmpplanTheme>
            <EmpplanSnackbarWrapper>
                <ViewEditCrm {...props} />
            </EmpplanSnackbarWrapper>
        </EmpplanTheme>
    );
}

export default ViewEditCrmWrapper;
