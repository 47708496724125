import React from 'react';
import { Box, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FieldConfigContext } from './index';
import ProductSelect from '../ProductSelect';

export default function ProductConditionComponent({
    currentValue,
    onChange: onUpdate,
}: {
    currentValue: ProductCondition;
    onChange: (newValue: ProductCondition) => void;
}) {
    const { t } = useTranslation();
    // note: do not directly modify the context directly,
    // it will modify in angular also and cause issues
    let reportContext: any = React.useContext(FieldConfigContext);
    reportContext = reportContext?.payload;

    // removes the current field
    reportContext = JSON.parse(JSON.stringify(reportContext));

    if (typeof reportContext.customReport !== 'undefined') {
        delete reportContext?.customReport[reportContext?.currentId];
    }

    const validOperators = [
        { slug: '##ProductOperatorEquals', operator: '=' },
        { slug: '##ProductOperatorNotEquals', operator: '!=' },
    ];

    const updateOperator = (newValue: string) => {
        const obj = { ...currentValue };
        obj.condition.operator = newValue;
        onUpdate(obj);
    };

    const updateProduct = (newValue: { _id: string; name: string } | null) => {
        const obj = { ...currentValue };
        obj.condition.product = newValue;
        onUpdate(obj);
    };

    // handle the null errors, if any when the object is undefined
    const availableFieldKeys = reportContext.customReport ? Object.keys(reportContext.customReport) : [];
    const availableFields = availableFieldKeys.map(keyName => ({
        name: reportContext.customReport[keyName].config?.name as string,
        id: keyName as string,
    }));

    availableFields.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    return (
        <Box display='flex' gap={9} alignItems='end' mb={0.5} p={1}>
            <Select
                id='operator-select'
                variant='standard'
                sx={{
                    width: '20%',
                }}
                value={currentValue.condition.operator}
                onChange={e => {
                    updateOperator(e.target.value);
                }}
            >
                {validOperators.map(option => {
                    return (
                        <MenuItem key={option.operator} value={option.operator}>
                            {t(option.slug)}
                        </MenuItem>
                    );
                })}
            </Select>
            <Box width={'50%'}>
                <ProductSelect
                    initialValue={currentValue.condition.product}
                    onChange={(
                        newValue: {
                            name: string;
                            _id: string;
                        } | null,
                    ) => {
                        const selectedProduct =
                            newValue?._id && newValue?.name ? { _id: newValue._id, name: newValue.name } : null;
                        updateProduct(selectedProduct);
                    }}
                />
            </Box>
        </Box>
    );
}
