import {User, UserActionTypes} from "../UserStates/UserStatesTypes";

export enum EventActionTypes  {
    CHECKIN= "EVENT/CHECKIN",
    CHECKOUT = "EVENT/CHECKOUT",
    ACCEPTEVENT = "EVENT/ACCEPT",
    REJECTEVENT = "EVENT/REJECT",
    GETTODAYSEVENTS = "EVENT/TODAYSEVENTS",
    LOADING = "EVENT/LOADING",
    EVENTDETAILPAGEID ="EVENT/DETAILPAGE/ID",
    GETONEEVENTBYID  = "EVENT/GET/ONE/BY/ID",
    GETALLMYEVENTS = "EVENT/GETALL/EVENTS",
    CREATE_LOCATION_VISIT_EVENT ="EVENT/PROMOTER/CREATE/LOCATION/VISIT"
};

export type EventUserType = {
    payment:any,
    paymentType:any,
    user_id: {
        firstName:string,
        lastName:string,
        usernameView:string,
        _id:string
    },
    _id:string
}

export type eventTimeSlots= {
    start:Date,
    stop:Date
}

export enum EventStates  {
    brandplanned= 'BrandPlanned',
    planned = 'Planned',
    published = 'Published',
    rejected = 'Rejected',
    accepted = 'Accepted',
    loggedin = 'LoggedIn',
    loggedout =  'LoggedOut',
    salesreport= 'SalesReport',
    salesreportuploaded = 'SalesReportUploaded',
    salesreportaccepted = 'SalesReportAccepted',
    salesreportrejected =  'SalesReportRejected',
    invoice = 'Invoiced',
    paid = 'Paid'
}

export enum EventInvoiceEnums {
    'Invoiced'='Invoiced',
    'ReadyToInvoice'='Invoiced',
}

export enum EventHistoryTypeEnums {
  "eventDate"="eventDate",
  "eventState"="eventState",

}

export type CreateEventLocationVisitResponse = {
    taskType:string,
    data:SmallEvent
}

export type CreateEventLocationCall= {
    day:string,
    hours:number,
    minutes:number,
    geoLoc?:GeolocationCoordinates,

}

export type EventHistoryItem ={
    date: Date,
    historyType: EventHistoryTypeEnums,
    ip:string,
    stateNew:string,
    stateOld:string,
    user:string,
    _id:string,
    loc?: { lat:Number,lng:Number}
}

export type EventDocuments = {
    _id:string,
    originalName:string,
    hashedName:string,
    fileExtension:string,
}

export type LargeEvent = {
    agency_user:EventUserType[],
    invoice_status?:EventInvoiceEnums,
    date:Date,
    eventState: EventStates,
    point_of_sale:any,
    area:any,
    eventType:string,
    _id:string,
    project_id: {
        number:null|string,
        name:string,
        _id:string,
        checkinCondition: {
            forceLocationShare:boolean
        }
    },
    briefing:string,
    times:eventTimeSlots[],
    eventStateHistory:EventHistoryItem[],
    free_bonus_positions:[],
    note:[string],
    documents:EventDocuments[]|null|[],
    eventGroupId:string,
    customStatuses:[],
    sales_report_checked_done:boolean,
    sales_report_checked_started:boolean,
    tmp_invoice_info:any,
    uploaded_sales_report: {
        fileExtension:string,
        hashedName:string,
        originalName:string,
        _id:string
    }
}

export type SmallEvent = {
    agency_user:EventUserType[],
    invoice_status?:EventInvoiceEnums,
    date:Date,
    eventState: EventStates,
    point_of_sale:any,
    area:any,
    eventType:string,
    _id:string,
    project_id: {
        number:null|string,
        name:string,
        _id:string
    },
    times:eventTimeSlots[]
}

export interface EventCheckInAction {
    type: EventActionTypes.CHECKIN,
    event: SmallEvent
}

export interface EventCheckoutAction{
    type: EventActionTypes.CHECKOUT,
    event: SmallEvent
}

export interface GetTodaysEvents {
    type:EventActionTypes.GETTODAYSEVENTS,
    todayEventList:SmallEvent[]|null
}

export interface EventStateTypeContainer {
    readonly smallEvent: SmallEvent | null;
    readonly loading: boolean;
    readonly todayEventList:SmallEvent[]|null;
    readonly currentDetailPageEvent:LargeEvent|null;
    readonly currentDetailPageEventId:string|null;
    readonly myEvents:SmallEvent[]|null;//all events for an specific user! Shouldnt be that much
}

export interface PromoterCreateLocationVisitAction {
    type: EventActionTypes.CREATE_LOCATION_VISIT_EVENT,
    response: CreateEventLocationVisitResponse
}

export interface AcceptEventAction {
    type:EventActionTypes.ACCEPTEVENT,
    event:SmallEvent
}

export interface RejectEventAction {
    type:EventActionTypes.REJECTEVENT,
    event:SmallEvent
}

export interface EventDetailPageIdAction {
    type:EventActionTypes.EVENTDETAILPAGEID,
    currentDetailPageEventId:string,
}

export interface EventGetOneByIdAction {
    type: EventActionTypes.GETONEEVENTBYID,
    currentDetailPageEvent:LargeEvent|null
}

export interface EventLoadingAction {
    type:EventActionTypes.LOADING,
    loading:boolean
}

export interface GetAllMyEventsAction {
    type:EventActionTypes.GETALLMYEVENTS,
    myEvents:SmallEvent[]|null
}


export type SmallEventActions =
    | EventCheckInAction
    | EventLoadingAction
    | GetTodaysEvents
    | AcceptEventAction
    | EventDetailPageIdAction
    | EventGetOneByIdAction
    | GetAllMyEventsAction
    | RejectEventAction
    | EventCheckoutAction
    | PromoterCreateLocationVisitAction;





