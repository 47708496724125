import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import { createContext, useContext, useEffect, useState } from 'react';

interface IUpdateSettingsArg<T> {
    _id: string;
    settingObject: T;
    version?: string;
}
interface IGetSettingsArg {
    view: string;
    module: string;
    version: string;
}
interface ICreateSettingsArg<T = Record<string, any>> {
    view: string;
    version: string;
    module: string;
    settingObject?: T;
}
export interface IViewSettings<T> {
    _id: string;
    version: string;
    view: string;
    module: string;
    settingObject: T;
    synced: boolean | undefined;
    isSettingsSynced: any
}
export async function updateSettings<T>({ _id, version, settingObject }: IUpdateSettingsArg<T>) {
    return fetch(`${window.BASE_URL}/viewSettings/get/list/${_id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ _id, version, settingObject }),
    }).then(res => res.json()) as Promise<IViewSettings<T>>;
}
export function createSettings<T>({ view, module, settingObject, version }: ICreateSettingsArg) {
    return fetch(`${window.BASE_URL}/viewSettings`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ view: `${view}:v${version}`, module, settingObject }),
    }).then(res => res.json()) as Promise<IViewSettings<T>>;
}
export async function getSettings<T>({ view, module, version }: IGetSettingsArg) {
    const response: IViewSettings<T>[] = await fetch(
        `${window.BASE_URL}/viewSettings/get/list?${new URLSearchParams({ view: `${view}:v${version}`, module })}`,
    ).then(res => res.json());
    return response.pop();
}
export function getOrCreateSettings<T>({ view, module, version }: IGetSettingsArg) {
    return getSettings<T>({ view, module, version }).then(settings => {
        if (settings) {
            return settings;
        }
        return createSettings<T>({ version, view, module, settingObject: {} });
    });
}

// const getSettingsKey = (moduleName: string, viewName: string) => `SETTINGS:${moduleName}::${viewName}`;
// @ts-ignore
window.settings = { getSettings, createSettings, updateSettings, getOrCreateSettings };
export function useViewSettings<T>({ view, module, version }: IGetSettingsArg) {
    const [viewSavedSettings, setViewSavedSettings] = useState<IViewSettings<T> | undefined>();
    useEffect(() => {
        if (!(view && module && version)) {
            console.error('useViewSettings: view, module, version are required');
            setViewSavedSettings(undefined);
        }
        getOrCreateSettings<T>({ version, view, module }).then(settings => {
            // @ts-ignore
            settings.isSettingsSynced = {
                toJSON: () => {
                    return undefined;
                },
            };
            setViewSavedSettings(settings);
        });
    }, [view, module, version]);
    return viewSavedSettings;
}
export function useUpdateViewSettings<T extends Object>({ _id, version, settingObject }: Partial<IViewSettings<T>>) {
    const [viewSavedSettings, setViewSavedSettings] = useState<IViewSettings<T> | undefined>(undefined);
    useEffect(() => {
        if (!(_id && settingObject)) {
            return;
        }
        updateSettings<T>({ _id, version, settingObject }).then(settings => {
            // @ts-ignore
            settings.isSettingsSynced = {
                toJSON: () => {
                    return undefined;
                },
            };
            setViewSavedSettings(settings);
        });
    }, [settingObject, _id]);
    return viewSavedSettings;
}
