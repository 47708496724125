import * as React from 'react';
import { Box } from '@mui/material';
import ReactMessenger from 'reactelements/src/pages/Messenger/Index';
import { useHistory, useParams } from 'react-router-dom';

function PwaMessenger() {
    const navigate = useHistory();
    const { messengerView, internalId } = useParams<{
        messengerView?: string;
        internalId?: string;
    }>();

    const navigateToHandler = (path: string) => {
        navigate.push('/messenger/' + path);
    };

    return (
        <Box sx={{ height: '100vh' }}>
            <ReactMessenger
                messengerView={(messengerView || 'Channels') as 'Channels' | 'Inbox' | 'Tasks' | undefined}
                internalId={internalId}
                navigateTo={navigateToHandler}
            />
        </Box>
    );
}

export default PwaMessenger;
