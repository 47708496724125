import React from 'react';
import API from 'reactelements/src/config/api';
import { isDeviceOnline } from './OnlineStatus';

const NOTIFICATION_CHECK_INTERVAL = 30; // in seconds

export const PWAScheduledTasksContext = React.createContext<PWAScheduledTasksContextPayload>({
    unreadNotifications: 0,
});

export function PWAScheduledTasksContextWrapper(
    props: React.PropsWithChildren<PWAScheduledTasksContextWrapperExtraProps>,
) {
    const [unreadNotifications, setUnreadNotification] = React.useState(0);

    React.useEffect(() => {
        // init count call here
        refreshUnreadNotificationCount(setUnreadNotification);
        // init count timeout here
        const unreadNotifInterval = setInterval(async () => {
            refreshUnreadNotificationCount(setUnreadNotification);
        }, NOTIFICATION_CHECK_INTERVAL * 1000);

        // remove timeout here
        return () => clearInterval(unreadNotifInterval);
    }, []);

    return (
        <PWAScheduledTasksContext.Provider value={{ unreadNotifications }}>
            {props.children}
        </PWAScheduledTasksContext.Provider>
    );
}

const refreshUnreadNotificationCount = async (setUnreadNotification: React.Dispatch<React.SetStateAction<number>>) => {
    if (isDeviceOnline()) {
        try {
            const res = await API.get('/chatchannel/unread/channels/user');
            const jsonRes = await res.json();
            // console.log('Unread api count', jsonRes);
            if (Array.isArray(jsonRes) && jsonRes[0] && typeof jsonRes[0].unread === 'number') {
                setUnreadNotification && setUnreadNotification(jsonRes[0].unread);
                return;
            }
            setUnreadNotification && setUnreadNotification(0);
        } catch (error) {
            console.error('failed to load unread notifications count', error);
            setUnreadNotification && setUnreadNotification(0);
        }
    }
};
