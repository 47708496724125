import { createContext, ReactNode, useState, useEffect } from 'react';
import {
    DataGridProProps,
    GridCellParams,
    GridColDef,
    GridColumnVisibilityModel,
    GridFilterModel,
    GridInitialState,
    GridRowParams,
    GridSortModel,
    MuiEvent,
} from '@mui/x-data-grid-pro';
import * as React$1 from 'react';
import moment from 'moment-timezone';
import { ISavedColumnContextState } from './columns';
import { IViewConfig } from '../hooks/build';
import { AnySchema, InterfaceFrom, Schema } from 'types-joi';
import { RequestParams } from '../../../config/api';
export type ISavedColDef = Pick<GridColDef, 'field' | 'headerName' | 'width' | 'headerAlign' | 'headerClassName'>;
export interface IGridState extends GridInitialState {
    columnWidthModel?: Record<string, number>;
    columnOrderModel?: string[];
    columnContext: ISavedColumnContextState;
}
export interface IParams {
    rerender?: Symbol;
    readonly reRenderTable?: () => void;
    buildConfig: IViewConfig;
    data: {
        filters?: Record<string, any>;
        rows?: InterfaceFrom<IViewConfig['schema']>[];
        url?: string;
        countUrl?: string;
        [key: string]: any;
        sendNewFilterData?: any;
    };
    resetTable?: boolean | 'true' | 'false';
    gridStyles?: Record<string, any>; // extra styles for grid, passed to `sx` attribute of muiDataGrid
    gridProps?: Partial<DataGridProProps>; // extra gridProps, passed to grid like {...gridProps}
    viewName?: string; // used in viewSettings
    moduleName?: string; // used in viewSettings
    callbacks?: {
        // `onCellClick` and `onRowClick` are used by grid and rest can be custom callbacks to be used by user implementation
        onCellClick?: (params: GridCellParams, event: MuiEvent<React$1.MouseEvent>, details?: any) => void;
        onRowClick?: (params: GridRowParams, event: MuiEvent<React$1.SyntheticEvent>, details?: any) => void;
        onFieldUpdate?: (oldRow: any, newRow: any, gridApiRef: any) => void;
        [key: string]: undefined | ((...any: any[]) => void);
    };
    // all the extra data that can be used by user implementation
    extraOptions: {
        touchBottom?: boolean; // remove multiple scroll bars
        [key: string]: any;
    };
    forcerender?: number;
    setSelectionRow?: any;
    editMode?: Boolean;
    onFilterChange?: (filterUrl: Object) => void;
    setRowCount?: any;
    commonSelect?: any;
}

export const ParamsContext = createContext<IParams>({} as any);
export const ParamsContextProvider = ({ children, params }: { children: ReactNode; params: IParams }) => {
    const [rerender, setRerender] = useState(params.rerender);
    const reRenderTable = () => {
        setRerender(Symbol());
    };

    const new_start_date = params.data.filters?.date_start;
    const new_stop_date = params.data.filters?.date_stops;

    const start_date = moment(new_start_date).startOf('day').toString();
    const stop_date = moment(new_stop_date).endOf('day').toString();

    if (params?.data?.filters?.date_start && params.data.filters?.date_stops) {
        params.data.filters = {
            ...params.data.filters,
            date_start: start_date,
            date_stops: stop_date,
        };
    }

    useEffect(() => {
        if (params.rerender) {
            setRerender(params.rerender);
        }
    }, [params.rerender]);

    return <ParamsContext.Provider value={{ ...params, reRenderTable, rerender }}>{children}</ParamsContext.Provider>;
};
