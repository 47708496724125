import { newCRMDb } from './NewCRM/newCRMItemDexie';
import { newCRMActionsDb } from './NewCRMActions/newCRMActionDexie';
import { newCustomModelFieldDb } from './NewCustomModelField/newCustomModelFieldDexie';
import { newCustomReportDb } from './NewCustomReport/newCustomReportDexie';
import { newCustomReportResultDb } from './NewCustomReportResult/newCustomReportResultDexie';
import { newEventDb } from './NewEvent/newEventsDexie';
import { newEventActionsDb } from './NewEventActionCollection/newEventActionCollectionDexie';
import { newExpenseDb } from './NewExpense/newExpenseDexie';
import { newFileSystemDb } from './NewFileSystem/newFileSystemDexie';
import { newProductDb } from './NewProduct/newProductDexie';
import { newProductReportDb } from './NewProductReport/newProductReportDexie';
import { newProjectDb } from './NewProject/newProjectsDexie';
import { newProjectLocationsDb } from './NewProjectLocations/newProjectLocationsDexie';
import { newSalesItemDb } from './NewSalesItem/newSalesItemDexie';

export const dexieDatabases = {
    crmDB: newCRMDb,
    customModelFieldsDB: newCustomModelFieldDb,
    customReportsDB: newCustomReportDb,
    customReportResultsDB: newCustomReportResultDb,
    eventsDB: newEventDb,
    eventActionsDB: newEventActionsDb,
    expensesDB: newExpenseDb,
    fileSystemsDB: newFileSystemDb,
    productsDB: newProductDb,
    productReportsDB: newProductReportDb,
    projectsDB: newProjectDb,
    ProjectLocationsDB: newProjectLocationsDb,
    salesItemsDB: newSalesItemDb,
    crmActionsDB: newCRMActionsDb,
};

export type dexieDatabaseNames = keyof typeof dexieDatabases;

export const syncDownIndexDbNames = [
    'crmDB',
    'eventsDB',
    'ProjectLocationsDB',
    'customModelFieldsDB',
    'customReportResultsDB',
    'customReportsDB',
    'expensesDB',
    'productReportsDB',
    'productsDB',
    'projectsDB',
    'salesItemsDB',
];
