import { InnerGridHeader } from "../../components-ui/dataView/innerGrid";
import { FC, memo, useEffect, useState } from "react";
import { Description } from "joi";
import joi from '../JoiExtension';
import { GridAlignment, GridColDef, GridEditCellProps, GridFilterOperator } from "@mui/x-data-grid-pro";
import { Cell } from "../../components-ui/table/components-renderers/Cell";
import { UserAbstractionSchema } from "../../resources/UserAbstraction/schema";
import {
  ColTypeValue,
  ColumnTypeName,
  ColumnTypes,
  ExtendedColumnType,
  ExtendedColumnTypeName,
  OverloadColumnType
} from "./ColumnTypes";
import { ObjectSchema, Schema } from "types-joi";
import { findInIterable } from "../../utils";
import { columnDefaults } from "../../components-ui/table/hooks/model";
import { useTranslationSafe } from "../translate";
import { keys } from "lodash";

const baseOfKeyPath = (keyPath: string) => keyPath.split('.').pop()
interface IuseGenerateColumnDefinitionFromSchema<T extends any> {
  (schema: Schema<T>, config: {
    filterable?: Set<string>;
    nonFilterable?: Set<string>;
    sortable?: Set<string>;
    nonSortable?: Set<string>;
    resizable?: Set<string>;
    nonResizable?: Set<string>;
    editableColumns?: Set<string>;
    customColumns?: { [k: string]: (...args: any) => any };
  }, typesOverride?: ExtendedColumnType, typeOverLoad?: OverloadColumnType,
  ): GridColDef[]
}
const isSortable = (keyPath: string, sortable?: Set<string>, nonSortable?: Set<string>) => {
  return nonSortable?.has(keyPath) ? false : sortable ? sortable.has(keyPath) : true;
}
const isFilterable = (keyPath: string, filterable?: Set<string>, nonFilterable?: Set<string>) => {
  return nonFilterable?.has(keyPath) ? false : filterable ? filterable.has(keyPath) : true;
}
const isResizable = (keyPath: string, resizable?: Set<string>, nonResizable?: Set<string>) => {
  return resizable?.has(keyPath) ? false : resizable ? resizable.has(keyPath) : true;
}
export const useGenerateColumnDefinitionFromSchema: IuseGenerateColumnDefinitionFromSchema<any> = (schema, config, userTypes, extendedTypes) => {
  const [colDefs, setColDefs] = useState<GridColDef[]>([])
  const i18n = useTranslationSafe()

  function getColumnTypeFromDescription(description: Description): [ExtendedColumnTypeName | ColumnTypeName, ColTypeValue] {
    const propType = description.type as 'object' | 'array' | 'string' | 'number' | 'boolean' | 'date'
    const propSubType = description.allow ? ['enum'] : [];
    const descriptionTags = description?.tags || [];
    // @ts-ignore
    const label = description?.flags?.label
    // debugger if label is ##Teams
    const rules: string[] = description?.rules?.map(({ name }: { name: string }) => name) || []
    // @ts-ignore
    const unit: string = description?.flags?.unit || '';
    const allTags = new Set([...propSubType, propType, ...descriptionTags, ...rules, unit, label]);
    const predicate = function ([colType, { tags: typeTags }]: any) {
      return [...typeTags].every(tag => allTags.has(tag))
    }
    const result = findInIterable(userTypes?.entries() || [], predicate) || findInIterable(ColumnTypes.entries(), predicate)
    // FIXM E: ANY!!@!
    if (result?.[0] && extendedTypes?.has(result[0] as any)) {
      Object.assign(result[1], extendedTypes.get(result[0] as any))
    }
    return result || ['default', ColumnTypes.get('default') as ColTypeValue]
  }


  function generateObjectColumnDefinitions([key, description]: [string, Description]): GridColDef {

    let fullColDef: GridColDef[] = generateColumnDefinitions([key, description])
    // @ts-ignore
    let reducedColDef = description?.flags?.objectReduce?.description ? generateColumnDefinition([key, description?.flags?.objectReduce.description as Description]) : generateColumnDefinition([key, description])
    // @ts-ignore
    const aggregator = description?.flags?.objectReduce?.reducer || ((arg: any) => arg);
    const [type, { value = null, Component = null, align = 'left', EditComponent = null,filterOperators,customKeyPath }] = getColumnTypeFromDescription(description);

    return {
      align: align as GridAlignment,
      headerAlign: align as GridAlignment,
      _extraInfo: {
        fullColDef,
        reducedColDef
      },
      resizable: isResizable(key, config.resizable, config.nonResizable),
      width: columnDefaults.width,
      sortable: isSortable(key, config.sortable, config.nonSortable),
      filterable: isFilterable(key, config.filterable, config.nonFilterable),
      editable: config.editableColumns?.has(key),
      filterOperators:filterOperators,
      // @ts-ignore
      headerName: i18n.t(description.flags?.label || key) || key,
      field: key,
      type,
      renderHeader: fullColDef.length ? (params) => {
        return <InnerGridHeader options={{
          title: params.colDef.headerName,
          allColumns: fullColDef,
          aggregateColumn: reducedColDef,
          keyPath: key
        }} />
      } : undefined,
      // @ts-ignore
      valueGetter: value ? (params) => value(params.row[baseOfKeyPath(key)]) : (params) => {
        return params.row[baseOfKeyPath(key) as string]
      },

      renderCell: Component ? function ({ value, row }) {
        const Com = Component as FC<{ value: any, row: any, options?: Record<string, any> }>
        return <Cell><Com key={type} row={row} value={value} options={{
          aggregator,
          allColumns: fullColDef,
          aggregateColumn: reducedColDef,
          keyPath: key
        }} /></Cell>
      } : undefined,




    } as GridColDef
  }

  function generateColumnDefinition([key, description]: [string, Description]): GridColDef {

    let type: string, value: any, Component: FC | null, EditComponent: FC | null, align: string,filterOperators:Array<GridFilterOperator>,customKeyPath:string|false;
    
    if (['object', 'array'].includes(description.type as string)) {
      [type, { value=null, Component=null, align='left', EditComponent = null,filterOperators=[],customKeyPath='' }] = getColumnTypeFromDescription(joi.any().label('minimal').describe())
    } else {
      [type, { value=null, Component=null, align='left', EditComponent = null,filterOperators=[],customKeyPath='' }] = getColumnTypeFromDescription(description)
    }

    const keyPath = customKeyPath?customKeyPath:baseOfKeyPath(key);

  
    let colDef: GridColDef = {
      align: align as GridAlignment,
      headerAlign: align as GridAlignment,
      width: columnDefaults.width,
      // @ts-ignore
      headerName: i18n.t(description.flags?.label || key) || key,
      field: key,
      resizable: isResizable(key, config.resizable, config.nonResizable),
      sortable: isSortable(key, config.sortable, config.nonSortable),
      filterable: isFilterable(key, config.filterable, config.nonFilterable),
      editable: config.editableColumns?.has(key),
      type:description.type,
      // @ts-ignore
      valueGetter: value ? (params) => value(params.row[keyPath]) : (params) => {
        // if(customKeyPath){
          try{
            let k = customKeyPath||key
            const v = eval(`params.row${k}`);
            // console.log(k,"((((",key)
            return v

          }catch(e){
            return ''
          }
        // }

        // return params.row[keyPath as string]
      },
      valueSetter: (params) => {
        if(customKeyPath){
          try{
            var row = params.row;
            eval(`row${customKeyPath}=params.value`)
          }catch(e){
            console.log(e)
            return { ...params.row, [keyPath as string]: params.value } 
          }

  
        }
        return { ...params.row, [keyPath as string]: params.value }
      },
      renderCell: Component ? ({ value, row }) => {
        const Com = Component as FC<{ value: any, row: any, options?: Record<string, any> }>
        return <Cell><Com key={type} row={row} value={value} /></Cell>
      } : undefined,
      
     
      
      


    }
  
    //Cannot set undefined in the above object. So going with this approach 
    if (EditComponent) {
      colDef.renderEditCell = (props: GridEditCellProps) => {
        const Com = EditComponent as FC<GridEditCellProps>
        return <Cell><Com {...props} /></Cell>
      }
    }

    if(filterOperators.length>0){
      colDef.filterOperators = filterOperators
    }
  
    return colDef
  }

  function getArrayColumnDefinitions(description: Description, colDef: ReturnType<typeof generateObjectColumnDefinitions>, keyPath: string): GridColDef {

    const [type, { value = null, Component = null, align = 'left' }] = getColumnTypeFromDescription(description)
    // const {Component} = ColumnTypes.get('array:generic') as ColTypeValue
    return {
      ...colDef,
      // @ts-ignore
      valueGetter: (params) => params.row[baseOfKeyPath(keyPath)],
      renderCell: ({ value, row }) => {
        const Com = Component as FC<{ row: any, value: any, options?: Record<string, any> }>
        return <Cell><Com key={'array'} row={row} value={value} options={{ colDef, keyPath }} /></Cell>
      }
    }
  }

  function generateColumnDefinitions([keyPath = '', description]: [string, Description]): GridColDef[] {
    if (description.type === 'array') {
      const item = description.items?.pop() as Description || joi.any().label('minimal').describe(); //FIXME: support multiple ITEM TYPES IN ARRAY
      if (!item) return []
      let colDef: GridColDef;
      if (['object', 'array'].includes(item.type as string)) {
        colDef = generateObjectColumnDefinitions([keyPath, item])
        // } else if (description.type ==description.items = Array(1) [Object]= 'array') {
        //   return generateArrayColumnDefinitions([keyPath, description], keyPath)
      } else {
        colDef = generateColumnDefinition([keyPath, item])
      }
      return [getArrayColumnDefinitions(description, colDef, keyPath)]
    }

    const keys: { [key: string]: Description } | undefined = description.keys
    if (!keys) {
      return [] // no column found in schema
    }
    const columns = Object.entries(keys).flatMap(([key, description]) => {
      if (['object'].includes(description?.type as string)) {
        return generateObjectColumnDefinitions([`${keyPath}.${key}`, description])
      } else if (description?.type === 'array') {
        return generateColumnDefinitions([`${keyPath}.${key}`, description])
      } else {
        return generateColumnDefinition([`${keyPath}.${key}`, description])
      }
    })
    return columns
  }
  
  function createCustomColumns() {
    return config?.customColumns
      && Object.entries(config.customColumns)?.map(([field, Component]) => {
        return {
          field: `custom.${field}`,
          headerName: i18n.t(field),
          resizable: isResizable(field, config.resizable, config.nonResizable),
          sortable: isSortable(field, config.sortable, config.nonSortable),
          filterable: isFilterable(field, config.filterable, config.nonFilterable),
          renderCell: ({ value, row }) => {
            return <Cell><Component key={field} row={row} value={value} /></Cell>
          }
        } as GridColDef
      })
  }
  useEffect(() => {

    const customColumns = createCustomColumns() || [];
    setColDefs([
    ...generateColumnDefinitions(['', removeKeysFromSchemaNotRequiredOnTable(schema.describe())]),
    ...customColumns,
    ])
  }, [i18n.t])

  return colDefs
}
// export function useColumnDefinition( ) {
//   // const val = useGenerateColumnDefinitionFromSchema(UserAbstractionSchema)
// }

function removeKeysFromSchemaNotRequiredOnTable(description: any) {

  let keyRemover = (desp: any) => {
    
     Object.keys(desp?.keys||{}).forEach(key => {
      
      if (desp.keys[key].tags && desp.keys[key].tags.includes('hideInTable')) {
        delete desp.keys[key]
      }
      if (desp.keys[key] && desp.keys[key].type === 'object') {
     
        desp.keys[key] = keyRemover(desp.keys[key])
      }


    })

    return desp
  }


 
  return keyRemover(description)

}


export const colDefs2 = 23//useGenerateColumnDefinitionFromSchema(UserAbstractionSchema);
// @ts-ignore
window.coldef = colDefs2