import {Reducer} from "redux";
import {MenuActions, MenuActionTypes, MenuStates} from "./MenuStatesTypes";

const initialMenuState: MenuStates = {
    open:false,
    moduleName:'',
    dashboardNavPosition:0,
    eventPageNavPosition:0,
};

export const MenuReducer: Reducer<MenuStates, MenuActions> = (
    state = initialMenuState,
    action
) => {
    switch (action.type) {
        case MenuActionTypes.OPENMENU: {
            return {
                ...state,
                open:true
            };
        }
        case MenuActionTypes.CLOSEMENU: {
            return {
                ...state,
                open:false
            };
        }
        case MenuActionTypes.DASHBOARDNAVCHANGE: {
            return {
                ...state,
                dashboardNavPosition:action.dashboardNavPosition
            }
        }
        case MenuActionTypes.MODULELOADED: {
            return {
                ...state,
                moduleName:action.moduleName
            }
        }
        case MenuActionTypes.EVENTNAVCHANGE: {
            return {
                ...state,
                eventPageNavPosition:action.eventPageNavPosition
            }
        }
    }
    return state;
};

//export const menuReducer: Reducer<MenuStates, Menu>
