import React from 'react';
import API from '../../config/api';
import { useTranslation } from 'react-i18next';

const modelConfigs = {
    CRM: ['status', 'address._country', 'customStatuses', 'customStatuses.x', 'userId'],
};
const allowedModels = Object.keys(modelConfigs);
type modelConfigKeys = keyof typeof modelConfigs;

const replaceIndexNumberWithXOnPath = function (path: string) {
    // customStatuses => customStatuses
    // customStatuses.0 => customStatuses.x
    // customStatuses.tmp => customStatuses.tmp
    // customStatuses.18.label => customStatuses.x.label
    // customStatuses.3.label.224 => customStatuses.x.label.x
    return path.replace(/\.\d+(\.|$)/g, '.x$1');
};

function useRefsLoader() {
    const { t } = useTranslation();
    const [refs, setRefs] = React.useState<{ [key: string]: string }>({});

    const [requestCounter, setRequestCounter] = React.useState<number>(0);
    const requestedRefs = React.useRef<Set<string>>(new Set());
    const allRefs = React.useRef<Set<string>>(new Set());

    const requestRef = (model: string, pathWithX: string, id: string) => {
        const refPath = `${model}/${pathWithX}/${id}`;
        if (allRefs.current.has(refPath)) {
            return;
        } else {
            allRefs.current.add(refPath);
        }
        // console.log('DEBUG - requestRef', refPath);
        if (!modelConfigs[model as modelConfigKeys]) {
            return;
        }
        const allowedModel = model as modelConfigKeys;
        if (!(allowedModels.includes(allowedModel) && modelConfigs[allowedModel]?.includes(pathWithX))) {
            return;
        }

        // check if loaded or requested
        if (
            refs[`${allowedModel}/${pathWithX}/${id}`] ||
            requestedRefs.current.has(`${allowedModel}/${pathWithX}/${id}`)
        ) {
            return;
        }
        // check if id is mongo id
        if (!id.match(/^[0-9a-fA-F]{24}$/)) {
            return;
        }
        // console.log('DEBUG - requestRef - match', `${allowedModel}/${pathWithX}/${id}`);
        requestedRefs.current.add(`${allowedModel}/${pathWithX}/${id}`);
        setRequestCounter(requestCounter + 1);
    };

    const fetchRefs = React.useCallback(async (refsToFetch: string[]) => {
        // console.log('DEBUG - refsToFetch', refsToFetch);
        API.post('/commonhistory/getrefs', { refs: refsToFetch })
            .then(res => res.json())
            .then(data => {
                if (data?.success && data.data) {
                    setRefs?.((refs: { [key: string]: string }) => ({ ...refs, ...data.data }));
                }
            })
            .catch(console.error);
    }, []);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            const refsToFetch = Array.from(requestedRefs.current);
            if (refsToFetch.length === 0) return;
            // remove refsToFetch from requestedRefs
            for (const ref of refsToFetch) {
                requestedRefs.current.delete(ref);
            }
            fetchRefs(refsToFetch);
        }, 2000);

        return () => {
            clearTimeout(timeout);
        };
    }, [requestCounter, fetchRefs]);

    const renderRef: (model: string, path: string, id: string) => React.ReactNode = (model, path, id) => {
        // console.log('DEBUG - renderRef', model, path, id);
        if (!(model && path && id)) {
            return id || '';
        }
        const pathWithX = replaceIndexNumberWithXOnPath(path);
        const refPath = `${model}/${pathWithX}/${id}`;

        let val = refs[refPath];
        if (!val) {
            if (!allRefs.current.has(refPath)) requestRef(model, pathWithX, id);
            val = id;
        }

        if (val?.startsWith?.('##')) {
            return t(val);
        }
        return val;
    };

    return [renderRef];
}

export default useRefsLoader;
