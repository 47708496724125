import React from 'react';
import { Box } from '@mui/material';
import TempExistingAreaEvents from './TempExistingAreaEvents';

function AreaEventsComponent({ event }: { event: any }) {
    const [selectedLocations, setSelectedLocations] = React.useState<any[]>([]);
    const allowPromoterToAddNewLocation = event?.project_id?.eventSettings?.allowAreaEventUsersToAddNewLocation || false;

    return (
        <Box>
            <TempExistingAreaEvents
                key={event._id + 'existingareaevents'}
                eventGroupId={event.eventGroupId._id || event.eventGroupId}
                areaId={event.area._id || event.area}
                projectId={event.project_id._id || event.project_id}
                projectData={event.project_id}
                value={selectedLocations}
                setValue={setSelectedLocations}
                allowPromoterToAddNewLocation={allowPromoterToAddNewLocation}
            />
        </Box>
    );
}

export default AreaEventsComponent;
