import * as React from 'react';
import { CustomReportComponent } from './customReportComponent';
import CustomReportParser from './CustomReportParser';
import { isCustomReportDisabled, ReportType } from './utils/reportDisableControl';
import { IProjectSchema } from '../../resources/projects';
import { IProductGroup } from '../../utils/ProductGroup';
import { VisitGuidelines } from '../../utils/VisitGuidelines';
import API from '../../config/api';
import { custom } from 'joi';


export const ReportConfigurationContext = React.createContext<ReportConfiguration>({ isDisabled: true });

export interface CustomReportWrapperProps {
    customReport?: CustomReportConfig;
    eventId?: string;
    eventState?: string;
    currentUserCanEditEventUser?: boolean;
    projectId?: string;
    reportType: ReportType;
    cbForButton?: (validationState: boolean, fixedTransactionsPending?: number) => void;
    onChangeCallback?:(data:ReportData,reportId:CustomReportConfig)=>void;
    project?: any; // Currently, this is only used for reportType - eventGroup. 
    

    isFieldHiddenCustomCondition?:isFieldHiddenCustomCondition;
    distributionArea?:string // this is an optional parameter used to fetch the visit Guidelines

    eventGroupProductGroups?:Array<string>, 
    userProductGroups?:Array<string>,
    locationProductGroups?:Array<string>,
   
    defaultToLocationProductGroups?:boolean,

    country?:string,
}

function CustomReportWrapper({
    reportType,
    customReport,
    eventId,
    eventState,
    currentUserCanEditEventUser,
    projectId,
    cbForButton,
    onChangeCallback,
    project,
    isFieldHiddenCustomCondition,
    distributionArea,
    eventGroupProductGroups,
    userProductGroups,
    locationProductGroups,
    defaultToLocationProductGroups,
    country,
}: CustomReportWrapperProps) {

    const [visitGuidelines, setVisitGuidelines] = React.useState<Array<VisitGuidelines>>([]);
    const [productGroups, setProductGroups] = React.useState<Array<IProductGroup>>([]);
    

    const fetchVisitGuidelines = async(pgIds:Array<string>)=>{

        if(customReport?.additionalOptions?.showVisitGuidelines){
            let paramString = `distributionArea=${distributionArea}&reportID=${customReport?._id}&`+ pgIds?.map(pgId=>`productGroups=${pgId}`).join('&')  
        
            let result:any  = await API.get("visitguideline/search/list",paramString);
            result  = await result.json()
            setVisitGuidelines(result?.list as Array<VisitGuidelines>)
        }
    };

    const fetchDetailedProductGroups = async(pgIds:Array<string>)=>{
           
            //Check weather we really need product Groups!!
            if(customReport?.customFields.find(cf=>cf.visibility.conditions.productGroups.some(pg=>pgIds.includes(pg._id)))){

                let result = await API.getReqestWithParams({url:"productgroup",params:{_id:pgIds}});
                setProductGroups(result as Array<IProductGroup>)
            }

        
        
    }

    React.useEffect(()=>{

        loadAdditionalData();
       
    //This is a workaround is implemented because the change is only triggered using shallow comparision and not the deep comparision.
    },[JSON.stringify(eventGroupProductGroups),userProductGroups,JSON.stringify(locationProductGroups)])

  

    const loadAdditionalData = async()=>{

        const filteredPgs = getFilteredProductGroupIds(customReport, eventGroupProductGroups, userProductGroups, locationProductGroups, defaultToLocationProductGroups)
        // console.log({locationProductGroups,eventGroupProductGroups,userProductGroups},{filteredPgs},customReport?.productGroupVisibilityConfig)
        if(filteredPgs && filteredPgs?.length>0){
          
            await Promise.all([fetchDetailedProductGroups(filteredPgs),fetchVisitGuidelines(filteredPgs)]);
        }
        
    }
    
    if (!(customReport && customReport._id)) {
        if (!eventId && reportType !== 'eventgroup') {
            console.error('eventId missing in implementation');
            return <></>;
        }
        console.log('customReport missing in implementation',eventId,reportType,customReport?._id);
        return <></>;
    }

   
    if (reportType === 'eventgroup') {
        let getReportURL = ``;
        let saveReportURL = ``;
        if (eventId) {
            getReportURL = `/customreportresult/eventgroup/${eventId}/${customReport._id}/get/report`;
            saveReportURL = `/customreportresult/`;
        }


        const contextObjForUpload = {
            contextType: 'events',
            contextId: eventId,
            eventState: eventState,
        };
        return (
            <ReportConfigurationContext.Provider
                value={{
                    isDisabled: isCustomReportDisabled(
                        // override the flow to use the event flow instead
                        customReport?.additionalOptions?.formFollowsEventFlow ? 'event' : 'eventgroup',
                        eventState,
                        currentUserCanEditEventUser,
                        project
                    ),
                    isFieldHiddenCustomCondition,
                }}
            >
                <CustomReportParser
                    contextObjForUpload={contextObjForUpload}
                    getReportURL={getReportURL}
                    validationCallback={cbForButton}
                    saveReportURL={saveReportURL}
                    baseCustomReportConfig={customReport}
                    generateSavePayload={(reportData, apiCustomReportData) => ({
                        _customReport: apiCustomReportData,
                        reportResults: reportData,
                        _eventGroup: eventId,
                        _project: projectId ? projectId : project?._id,
                    })}
                    productGroups = {productGroups}
                    onChangeCallback={onChangeCallback}
                />
            </ReportConfigurationContext.Provider>
        );
    } else if (reportType === 'event') {
        const getReportURL = `/customreportresult/${eventId}/${customReport._id}/get/report`;
        const saveReportURL = `/customreportresult/`;
        const contextObjForUpload = {
            contextType: 'events',
            contextId: eventId,
            eventState: eventState,
        };
        return (
            <ReportConfigurationContext.Provider
                value={{ isDisabled: isCustomReportDisabled(reportType, eventState, currentUserCanEditEventUser,project),isFieldHiddenCustomCondition, visitGuidelines,country}}
            >
                <CustomReportParser
                    contextObjForUpload={contextObjForUpload}
                    getReportURL={getReportURL}
                    validationCallback={cbForButton}
                    saveReportURL={saveReportURL}
                    baseCustomReportConfig={customReport}
                    generateSavePayload={(reportData, apiCustomReportData) => ({
                        _customReport: apiCustomReportData,
                        reportResults: reportData,
                        _event: eventId,
                        _project: projectId,
                    })}
                    eventId={eventId}
                    productGroups = {productGroups}
                />
            </ReportConfigurationContext.Provider>
        );
    }

    console.error('reportType missing in implementation');
    return <></>;
}

export default CustomReportWrapper;




export function getFilteredProductGroupIds(customReport: CustomReportConfig | undefined, eventGroupProductGroups: string[] | undefined, userProductGroups: string[] | undefined, locationProductGroups: string[] | undefined, defaultToLocationProductGroups?: boolean | undefined) {
   
        const { disableLocationProductGroupCheck, disableUserProductGroupCheck } = customReport?.productGroupVisibilityConfig || {};
        let filteredPgs: any = [];
      
        filteredPgs = eventGroupProductGroups?.filter(pg => {
            return (!disableUserProductGroupCheck ? userProductGroups?.find(upg => upg === pg) : true) && (!disableLocationProductGroupCheck ? locationProductGroups?.find(lpg => lpg === pg) : true);
        });
       

        if (filteredPgs?.length == 0 && defaultToLocationProductGroups) {
            filteredPgs = locationProductGroups?.filter(pg => userProductGroups?.find(upg => !disableUserProductGroupCheck ? userProductGroups?.find(upg => upg === pg) : true));

        };

        return filteredPgs;
    
}
