import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import MessageIcon from '@mui/icons-material/Message';
import GenericFilePresentation from 'reactelements/src/modules/GenericFilePresentation';
import AppsIcon from '@mui/icons-material/Apps';

function EventBriefing({
    eventBriefing,
    projectBriefing,
}: {
    eventBriefing: string;
    projectBriefing: {
        invitetext: string;
        documents: string[];
        attachments: string[];
        information: string;
    };
}) {
    const { t } = useTranslation();
    // console.log({ eventBriefing, projectBriefing });

    const eventSentences = eventBriefing ? eventBriefing.split('\n').filter(each => each.trim() !== '') : [];
    const projectSentences = projectBriefing
        ? projectBriefing.information?.split('\n').filter(each => each.trim() !== '')
        : [];

    if (
        eventBriefing.length === 0 &&
        projectBriefing.information?.length === 0 &&
        projectBriefing.documents?.length === 0 &&
        projectBriefing.attachments?.length === 0
    ) {
        return (
            <Box sx={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <Box display='flex' flexDirection='row' alignItems='center'>
                    <Typography variant='h5'>{t('##NoBriefingFound')}</Typography>
                    <SentimentSatisfiedAltIcon />
                </Box>
            </Box>
        );
    }

    return (
        <Box px={2} mb={10}>
            <Button
                disableRipple
                sx={theme => ({ color: theme.palette.text.primary, ml: 1, mt: 2, mb: 1 })}
                startIcon={<MessageIcon sx={{ mr: 2 }} />}
            >
                {t('##Briefing')}
            </Button>
            {projectSentences.length > 0 &&
                projectSentences.map((eachBrief: string, i) => {
                    return (
                        <Typography key={i} ml={1}>
                            {eachBrief}
                        </Typography>
                    );
                })}
            {eventSentences.length > 0 &&
                eventSentences.map((eachBrief: string, i) => {
                    return (
                        <Typography key={i} ml={1}>
                            {eachBrief}
                        </Typography>
                    );
                })}

            <Button
                disableRipple
                sx={theme => ({ color: theme.palette.text.primary, ml: 1, mt: 2 })}
                startIcon={<AppsIcon sx={{ mr: 2 }} />}
            >
                {t('##BriefingDocuments')}
            </Button>

            {Array.isArray(projectBriefing.documents) && (
                <Grid container key={'doc-event'} spacing={3}>
                    {projectBriefing.documents.map(docId => {
                        return (
                            <Grid key={docId} item xs='auto'>
                                <GenericFilePresentation key={docId} file={docId} />
                            </Grid>
                        );
                    })}
                </Grid>
            )}

            <Button
                disableRipple
                sx={theme => ({ color: theme.palette.text.primary, ml: 1, mt: 2 })}
                startIcon={<AppsIcon sx={{ mr: 2 }} />}
            >
                {t('##ProjectDocuments')}
            </Button>

            {Array.isArray(projectBriefing.attachments) && (
                <Grid container key={'doc-proj'} spacing={3}>
                    {projectBriefing.attachments.map(docId => {
                        return (
                            <Grid key={docId} item xs='auto'>
                                <GenericFilePresentation key={docId} file={docId} />
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </Box>
    );
}

export default EventBriefing;
