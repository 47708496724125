import API from 'reactelements/src/config/api';

export const SyncHashLocalstorageKey: string = 'pwaSyncHashID';

export const verifyDeviceSyncHash = async () => {
    try {
        const currentDeviceHash = localStorage.getItem(SyncHashLocalstorageKey) || '0';

        // we verify if this is the hash that the server hash
        const jsonData = await (await API.post('/user/synchashexchange', { deviceHash: currentDeviceHash })).json();
        if (jsonData?.success && typeof jsonData.deviceHash === 'number') {
            if (jsonData.verified) {
                return { verified: true, serverHash: jsonData.deviceHash as number, deviceHash: currentDeviceHash };
            } else {
                return { verified: false, serverHash: jsonData.deviceHash as number, deviceHash: currentDeviceHash };
            }
        } else {
            return { verified: false, deviceHash: currentDeviceHash };
        }
    } catch (error) {
        console.error('verifyDeviceSyncHash', error);
        return { verified: false };
    }
};

export const initializeDeviceSyncHash = async () => {
    try {
        localStorage.removeItem(SyncHashLocalstorageKey);

        // we ask server for a new hash for this device
        const newDeviceHash = new Date().getTime().toString();
        const jsonData = await (
            await API.post('/user/synchashexchange', { deviceHash: newDeviceHash, registerHash: true })
        ).json();

        if (jsonData?.success && jsonData.deviceHash === newDeviceHash) {
            localStorage.setItem(SyncHashLocalstorageKey, newDeviceHash);
            return newDeviceHash;
        } else {
            return false;
        }
    } catch (error) {
        console.error('initializeDeviceSyncHash', error);
        return false;
    }
};
