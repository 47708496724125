import React from 'react';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Drawer from '@mui/material/Drawer';

import InsertDriveFileSharpIcon from '@mui/icons-material/InsertDriveFileSharp';
import DashboardSharpIcon from '@mui/icons-material/DashboardSharp';
import CalendarTodaySharpIcon from '@mui/icons-material/CalendarTodaySharp';
import FaceSharpIcon from '@mui/icons-material/FaceSharp';
import PowerSettingsNewSharpIcon from '@mui/icons-material/PowerSettingsNewSharp';
import AppSettingsAltSharpIcon from '@mui/icons-material/AppSettingsAltSharp';
import ForumSharpIcon from '@mui/icons-material/ForumSharp';

import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import EmpplanIcon from 'reactelements/src/components-ui/EmpplanIcon';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import { DetectOnline } from 'reactelements/src/hooks/detectOnline';
import GenericFilePresentation from 'reactelements/src/modules/GenericFilePresentation';

import { closeMenu } from '../../Models/MenuStates/MenuActions';

import { createSyncHistoryEntry, emergencyClearAllIndexedDbs } from '../../Models/NewSync/helpers';

import { PWASyncControlContext } from '../../Services/pwaSyncControlContext';
import { PWAScheduledTasksContext } from '../../Services/pwaScheduledTasksContext';
import { checkAndLoadPWAServiceWorker } from '../../pwaServiceWorker';
import { Badge, ListItemSecondaryAction } from '@mui/material';
import { hasAccess } from 'reactelements/src/utils/helpers/roleHelper';
import { SyncHashLocalstorageKey } from '../../Services/pwaSyncControlHashHelpers';
import { clearAllDbSyncStatuses } from '../../Models/helpers';

const StyledListItemText = ({ primary }: { primary: string }) => (
    <ListItemText sx={theme => ({ color: theme.palette.common.white, textTransform: 'uppercase' })} primary={primary} />
);

const StyledListItemIcon = ({ children }: React.PropsWithChildren<{}>) => (
    <ListItemIcon sx={theme => ({ color: theme.palette.common.white })}>{children}</ListItemIcon>
);

const StyledDivider = () => <Divider sx={theme => ({ backgroundColor: theme.palette.primary.light })} />;

export const handlePWALogout = async () => {
    createSyncHistoryEntry('UserLoggedOutFromPWA', { msg: 'user logged out and EMPPLAN deleted local data' });
    // removing the disclaimer flag and table saved settings
    localStorage.removeItem(SyncHashLocalstorageKey);
    localStorage.removeItem('myEventsTableSettings');
    // localStorage.removeItem('isPromoterHybridMode');
    console.log('starting delete all dbs');
    // force remove all databases
    await emergencyClearAllIndexedDbs();
    clearAllDbSyncStatuses();
    // redirect to logout
    console.log('redirecting to logout');
    window.location.href = '/logout';
    // should be last other wise will show up on dashboard
    localStorage.removeItem('legalDisclaimer');
};

export default function DrawerMenu () {
    const history = useHistory();
    const dispatch: any = useDispatch();
    const { t } = useTranslation();
    const { isOnline } = DetectOnline();

    const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);
    const { pendingSyncUploadsCount, startUpSync, isSyncing } = React.useContext(PWASyncControlContext);
    const { unreadNotifications } = React.useContext(PWAScheduledTasksContext);

    const open = useSelector((s: any) => s?.Menu?.open);
    const user = useSelector((s: any) => s?.User?.user);

    const handleLogoutCheckClick = () => {
        if (pendingSyncUploadsCount > 0) {
            setLogoutDialogOpen(true);
            return;
        }
        handlePWALogout();
    };

    return (
        <>
            <Drawer
                ModalProps={{ disableScrollLock: true, keepMounted: true }}
                open={open}
                onClose={() => dispatch(closeMenu())}
                anchor='left'
            >
                <Box sx={{ width: 300 }} onClick={() => dispatch(closeMenu())} onKeyDown={() => dispatch(closeMenu())}>
                    <Box mt={4} mb={2} px={2}>
                        <GenericFilePresentation
                            key={'agencyLogo'}
                            file={window.agencySettings?.darklogo || ''}
                            disableInteraction
                            height='100%'
                            pwaLogo={true}
                        />
                    </Box>
                    <List>
                        <ListItemButton
                            key={'Dashboard'}
                            onClick={() => {
                                history.push('/');
                                checkAndLoadPWAServiceWorker();
                            }}
                        >
                            <StyledListItemIcon>
                                <DashboardSharpIcon />
                            </StyledListItemIcon>
                            <StyledListItemText primary={t('##Dashboard')} />
                        </ListItemButton>
                        <ListItemButton
                            key={'MyEvents'}
                            onClick={() => {
                                history.push('/myevents');
                                checkAndLoadPWAServiceWorker();
                            }}
                        >
                            <StyledListItemIcon>
                                <EmpplanIcon />
                            </StyledListItemIcon>
                            <StyledListItemText primary={t('##MyEvents')} />
                        </ListItemButton>
                    </List>
                    <StyledDivider />
                    <List>
                        {hasAccess(['yakiChat']) && (
                            <ListItemButton
                                disabled={!isOnline}
                                key={'Messenger'}
                                onClick={() => history.push('/messenger')}
                            >
                                <StyledListItemIcon>
                                    <ForumSharpIcon />
                                </StyledListItemIcon>
                                <StyledListItemText primary={t('##Messenger')} />
                                <ListItemSecondaryAction>
                                    <Badge badgeContent={unreadNotifications} sx={{ mr: 3 }} />
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        )}
                        {hasAccess(['Events']) && (
                            <ListItemButton
                                disabled={!isOnline}
                                key={'CalendarView'}
                                onClick={() => history.push('/calendar')}
                            >
                                <StyledListItemIcon>
                                    <CalendarTodaySharpIcon />
                                </StyledListItemIcon>
                                <StyledListItemText primary={t('##CalendarView')} />
                            </ListItemButton>
                        )}
                        {hasAccess(['Invoice Administration', 'ItemsOverview', 'CreditNoteItems'], 'or') && (
                            <ListItemButton
                                key={'Invoicing'}
                                onClick={() => history.push('/invoice')}
                                disabled={!isOnline}
                            >
                                <StyledListItemIcon>
                                    <InsertDriveFileSharpIcon />
                                </StyledListItemIcon>
                                <StyledListItemText primary={t('##Invoicing')} />
                            </ListItemButton>
                        )}
                    </List>
                    <StyledDivider />
                    <List>
                        <ListItemButton
                            key={'UserNameView'}
                            onClick={() => history.push('/myProfile')}
                            disabled={!isOnline}
                        >
                            <StyledListItemIcon>
                                <FaceSharpIcon />
                            </StyledListItemIcon>
                            <StyledListItemText primary={user !== null ? user?.userabstraction?.firstName : ''} />
                        </ListItemButton>

                        <ListItemButton
                            key={'PWASettings'}
                            onClick={() => {
                                history.push('/pwaSettings');
                                checkAndLoadPWAServiceWorker();
                            }}
                        >
                            <StyledListItemIcon>
                                <AppSettingsAltSharpIcon />
                            </StyledListItemIcon>
                            <StyledListItemText primary={t('##PWASettings')} />
                        </ListItemButton>
                    </List>
                    <StyledDivider />
                    <List>
                        <ListItemButton key={'Logout'} onClick={handleLogoutCheckClick} disabled={!isOnline}>
                            <StyledListItemIcon>
                                <PowerSettingsNewSharpIcon />
                            </StyledListItemIcon>
                            <StyledListItemText primary={t('##Logout')} />
                        </ListItemButton>
                    </List>
                </Box>
            </Drawer>
            <Dialog
                open={logoutDialogOpen}
                onClose={() => {
                    setLogoutDialogOpen(false);
                }}
                aria-labelledby='logout-dialog-title'
                aria-describedby='logout-dialog-description'
            >
                <DialogTitle id='logout-dialog-title'>{t('##SyncPending')}</DialogTitle>
                <DialogContent>
                    <Alert severity='warning'>
                        <AlertTitle>{t('##Warning')}</AlertTitle>
                        {t('##YouHaveUnsyncedData')} — <strong>{t('##PleaseSyncBeforeLogout')}</strong>
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={!isOnline}
                        onClick={() => {
                            setLogoutDialogOpen(false);
                            !isSyncing && startUpSync(handlePWALogout);
                        }}
                        autoFocus
                    >
                        Sync now
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
