import React, {useContext} from 'react';
import {Box, TextField} from '@mui/material';
import {ReportConfigurationContext} from '..';
import ToolTipComponent from '../ToolTipComponent';
import {requireHelper} from '../utils/requireHelper';
import {useTranslation} from 'react-i18next';
import {hasRegexErrorInCustomField} from "../utils/validator";

interface StringSmallFieldProps {
    field: CustomReportField;
    onChange: any;
    reportData: ReportData;
    type: React.HTMLInputTypeAttribute | undefined;
    inputProps?: any;
    InputProps?: any;
}


function TextFieldComponent({field, type, reportData, onChange, inputProps, InputProps}: StringSmallFieldProps) {
    const {t} = useTranslation();
    const {isDisabled, visitGuidelines,country} = useContext(ReportConfigurationContext);
    const requiredCondition = requireHelper(field, reportData);
    let isTextArea = type === 'textarea';


    const regexError = hasRegexErrorInCustomField(reportData[field.config._id]?.toString(), field.config,country)


    const error = (field.config.fieldObject?.lengthMinimum &&
            reportData[field.config._id]?.toString().length < field.config.fieldObject?.lengthMinimum) ||
        (field.required && reportData[field.config._id] === '') || !!regexError;

    const helperText = field.config.fieldObject?.lengthMinimum &&
    reportData[field.config._id]?.toString().length < field.config.fieldObject?.lengthMinimum
        ? 'Enter Minimum Required Character'
        : reportData[field.config._id] === '' && field.required
            ? 'Empty Value!'
            : regexError?regexError:''

    return (
        <Box display='flex' alignItems='center'>
            <TextField
                data-testid={'TextField' + field.config._id}
                fullWidth
                type={type}
                multiline={isTextArea}
                variant='standard'
                label={field.config.slug ? t(field.config.slug) : field.config.name}
                value={reportData[field.config._id] || ''}
                onChange={onChange}
                // This used for giving attributes that are existing in DOM
                inputProps={inputProps || {}}
                // This used for giving attributes that are not existing in DOM
                InputProps={InputProps || {}}
                disabled={isDisabled || field.isReadonly}
                error={error}
                helperText={helperText}
                required={requiredCondition}
                sx={{
                    '& .MuiFormLabel-asterisk': {
                        color: 'transparent',
                        '&::after': {
                            color: 'red',
                            content: `"(${t('##TheFieldIsRequired')})"`,
                        },
                    },
                }}
            />
            {field.config?.toolTipTxt !== '' && (
                <ToolTipComponent
                    enableHintOnClick={field.config.enableHintOnClick}
                    tooltipText={field.config.toolTipTxt}
                />
            )}
        </Box>
    );
}

export default TextFieldComponent;
