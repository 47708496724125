import joi from 'types-joi';
import joi_ from '../../../src/../node_modules/joi/lib/index.js'
import JoiPhoneNumber from 'joi-phone-number';
import {IbanExtension} from "./iban";
import {ObjectReduceExtension} from "./single";

function loadExtensions(joi:any) {
  joi = joi.extend(IbanExtension);
  joi = joi.extend(JoiPhoneNumber);
  joi = joi.extend(ObjectReduceExtension);
  return joi
}
export default loadExtensions(joi_) as typeof joi

