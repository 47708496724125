import React from 'react';
import { useTranslation } from 'reactelements/node_modules/react-i18next';

import { Box, Button, Typography } from '@mui/material';
import { PWASyncControlContext } from '../../Services/pwaSyncControlContext';
import { useHistory } from 'react-router-dom';

function SyncProgressHelpPage() {
    const { t } = useTranslation();
    const navigate = useHistory();
    const { uploadSyncData, modelSyncData } = React.useContext(PWASyncControlContext);

    return (
        <Box mt={2} ml={2}>
            {uploadSyncData?.syncErrors?.length > 0 && (
                <>
                    <Typography variant='h5'>{t('##SyncHelpGuide')}</Typography>
                    <Typography variant='body1'>
                        {t('##SyncHelpGuideDescriptionLine1')}
                        <br />
                        {t('##SyncHelpGuideDescriptionLine2')}
                        <br />
                        {t('##SyncHelpGuideDescriptionLine3')}
                        <br />
                        <Button
                            variant='text'
                            color='secondary'
                            onClick={() => {
                                navigator.clipboard
                                    .writeText(
                                        JSON.stringify({
                                            modelSyncData,
                                            uploadSyncData,
                                            version: window.pwaAppVersion,
                                            user: window.userabstraction,
                                        }),
                                    )
                                    .then(
                                        () => {
                                            /* success */
                                            console.log('Copied to clipboard');
                                        },
                                        () => {
                                            /* failure */
                                            console.log('Failed copying to clipboard');
                                        },
                                    );
                            }}
                        >
                            {t('##CopyLogs')}
                        </Button>

                        {/* <Button
                            variant='text'
                            color='secondary'
                            LinkComponent={Link}
                            target='_blank'
                            href={
                                'mailto:' +
                                `support@empplan.com` +
                                '?subject=' +
                                'There is an error syncing my data' +
                                '&body=' +
                                window.userabstraction._id +
                                ':' +
                                window.location.href +
                                '%0D%0A%0D%0A<paste your error log here>'
                                // JSON.stringify({ modelSyncData, uploadSyncData }) // we cannot do this cause of 2000 character limit
                            }
                        >
                            support@empplan.com
                        </Button> */}
                    </Typography>
                </>
            )}
            <Button
                color='secondary'
                sx={{ my: 2 }}
                onClick={() => {
                    navigate.push('/');
                }}
            >
                {t('##GoToMainPage')}
            </Button>
        </Box>
    );
}

export default SyncProgressHelpPage;
