import * as React from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import InfoIcon from '@mui/icons-material/Info';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import { DetectOnline } from 'reactelements/src/hooks/detectOnline';
import { ForumSharp } from '@mui/icons-material';

interface IProps {
    value: 'Reporting' | 'Briefing' | 'Earnings' | 'Messenger';
    onChange: (event: React.SyntheticEvent, value: 'Reporting' | 'Briefing' | 'Earnings' | 'Messenger') => void;
    isEventSyncPending: boolean;
    eventChannel?: any;
}

export const BottomFilters = ({ value, onChange, isEventSyncPending, eventChannel }: IProps) => {
    const { t } = useTranslation();
    const { isOnline } = DetectOnline();

    const BOTTOM_LABELS = [
        {
            value: 'Reporting',
            label: t('##Reporting'),
            icon: EventNoteIcon,
            disabled: false,
        },
        {
            value: 'Briefing',
            label: t('##Briefing'),
            icon: InfoIcon,
            disabled: false,
        },
    ];

    // we will not show earnings for subcontractors
    if (!window.userabstraction?.officialIds?.isSubcontractor) {
        BOTTOM_LABELS.push({
            value: 'Earnings',
            label: t('##Earnings'),
            icon: TrendingUpIcon,
            disabled: !isOnline || isEventSyncPending,
        });
    }

    if (eventChannel) {
        BOTTOM_LABELS.push({
            value: 'Messenger',
            label: t('##Messenger'),
            icon: ForumSharp,
            disabled: !isOnline || !eventChannel,
        });
    }

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={7}>
            <BottomNavigation showLabels value={value} onChange={onChange}>
                {BOTTOM_LABELS.map(eachLabel => {
                    return (
                        <BottomNavigationAction
                            key={eachLabel.value}
                            value={eachLabel.value}
                            label={eachLabel.label}
                            icon={<eachLabel.icon />}
                            sx={theme => ({
                                '&.Mui-selected': {
                                    color: theme.palette.secondary.main,
                                },
                            })}
                            disabled={eachLabel.disabled}
                        />
                    );
                })}
            </BottomNavigation>
        </Paper>
    );
};
