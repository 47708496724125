import { ControllerPropsWithoutRender, EmpplanFormField } from './utils';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    Controller,
    ControllerFieldState,
    ControllerProps,
    ControllerRenderProps,
    FieldValues,
    UseFormStateReturn,
    useController,
} from 'react-hook-form';
import {
    Box,
    Chip,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
    TextField,
    TextFieldProps,
} from '@mui/material';

interface EmpplanSelectProps<T extends { value: string; label: string; disabled?: boolean }>
    extends ControllerPropsWithoutRender {
    renderProps?: (
        field?: ControllerRenderProps<FieldValues, string>,
        fieldState?: ControllerFieldState,
        formState?: UseFormStateReturn<FieldValues>,
    ) => TextFieldProps;
    options: Array<T>;
    helperText?: string;
    label: string;
    disabled?: boolean;
    multiple?: boolean;
}

export default function EmpplanSelect<T extends { value: string; label: string; disabled?: boolean }>(
    props: EmpplanSelectProps<T>,
) {
    const { field } = useController({ ...props });

    const handleDeleteChip = (chipValue: string, event: React.MouseEvent) => {
        event.stopPropagation();
        if (Array.isArray(field.value)) {
            const updatedValue = field.value.filter(val => val !== chipValue);
            field.onChange(updatedValue);
        }
    };

    return (
        <Controller
            {...props}
            render={({ field, fieldState, formState }) => {
                const rProps = props?.renderProps?.(field, fieldState, formState);
                return (
                    <>
                        <TextField
                            select
                            variant={'standard'}
                            fullWidth
                            label={props.label}
                            {...field}
                            value={
                                (field.value == null || typeof field.value === 'undefined') && props.multiple
                                    ? []
                                    : field.value
                                    ? field.value
                                    : ''
                            }
                            SelectProps={{
                                multiple: props.multiple,
                                MenuProps: {
                                    sx: {
                                        maxHeight: '40vh',
                                    },
                                },
                                renderValue: props.multiple
                                    ? (selected: any) => (
                                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                              {selected.map((val: any) => (
                                                  <Chip
                                                      key={val}
                                                      label={props.options.find(op => op.value === val)?.label}
                                                      clickable
                                                      deleteIcon={
                                                          props?.multiple ? (
                                                              <CancelIcon onMouseDown={event => event.stopPropagation()} />
                                                          ) : undefined
                                                      }
                                                      onDelete={
                                                          props?.multiple ? e => handleDeleteChip(val, e) : undefined
                                                      }
                                                  />
                                              ))}
                                          </Box>
                                      )
                                    : undefined,
                            }}
                            error={!!fieldState?.error?.message}
                            disabled={props?.disabled}
                            {...rProps}
                            helperText={fieldState.error?.message || props.helperText}
                            sx={{ width: '100%', marginBottom: '8px', ...rProps?.sx }}
                        >
                            {props.options
                                .sort((a, b) => (a.label || '').localeCompare(b.label || ''))
                                .map((option: T, idx: number) => {
                                    return (
                                        <MenuItem key={option.value || idx} value={option.value} disabled={!!option.disabled}>
                                            <Box
                                                fontWeight={
                                                    Array.isArray(field.value) &&
                                                    field.value.find(v => v === option.value) &&
                                                    800
                                                }
                                            >
                                                {option.label}
                                            </Box>
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </>
                );
            }}
        ></Controller>
    );
}
