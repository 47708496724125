import React from 'react';
import { Controller, ControllerFieldState, ControllerRenderProps, FieldValues, UseFormStateReturn } from 'react-hook-form';
import { ControllerPropsWithoutRender } from './utils';
import { FormControl, FormHelperText, TextField, TextFieldProps } from '@mui/material';

interface EmpplanTextFieldProps extends ControllerPropsWithoutRender {
    renderProps?: (
        field?: ControllerRenderProps<FieldValues, string>,
        fieldState?: ControllerFieldState,
        formState?: UseFormStateReturn<FieldValues>,
    ) => TextFieldProps;

    helperText?: string;
    label: string;
    disabled?: boolean;
    type?: React.HTMLInputTypeAttribute | undefined;
    onBlur?: (e: any) => void;
    onChange?:(e:any)=>void
}

export default function EmpplanTextField(props: EmpplanTextFieldProps) {
    return (
        <Controller
            {...props}
            render={({ field, fieldState, formState }) => {
                const rProps = props?.renderProps?.(field, fieldState, formState);

                return (
                    <>
                        <TextField
                            key={props.name}
                            fullWidth
                            type={props.type ? props.type : 'text'}
                            variant='standard'
                            label={props.label}
                            {...field}
                            error={!!fieldState?.error?.message}
                            disabled={props?.disabled}
                            inputProps={{ type: props.type ? props.type : 'text' }}
                            {...rProps}
                            helperText={fieldState.error?.message || props.helperText}
                            sx={{ width: '100%', marginBottom: '8px', ...rProps?.sx }}
                            onBlur={(e)=> {
                                field.onBlur()
                                props?.onBlur?.(e);
                            }}
                            onChange={(e)=> {
                                field.onChange(e)
                                props?.onChange?.(e);
                            }}
                        ></TextField>
                    </>
                );
            }}
        ></Controller>
    );
}
