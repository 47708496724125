import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { FilterListSharp } from '@mui/icons-material';
import { FilterChip } from './filterChips';
import { EmpplanFilterConfig } from './filterLine';
import useViewMagicSettings from '../../hooks/useViewMagicSettings';
import { RequestParams } from '../../config/api';
import { PWAContext } from '../../PWA/pwaContext';

export interface IEmpFilterLine {
    filterConfig: EmpplanFilterConfig;
    setFilterUrl: React.Dispatch<React.SetStateAction<RequestParams>>;
    page: number;
    rowsPerPage: number;
    setDefault: number | undefined;
    disableClearable?: boolean;
    skipViewSettings?: boolean;
    injectOptionValue?: any[]; //add value to filterline
    updatedFilterViewSettingsReady?: (state: boolean) => void;
}

export default function FilterLine({
    filterConfig,
    setFilterUrl,
    page,
    rowsPerPage,
    setDefault,
    disableClearable,
    skipViewSettings,
    injectOptionValue,
    updatedFilterViewSettingsReady,
}: IEmpFilterLine) {
    const pwaContext = React.useContext(PWAContext);
    const { viewMagicSettings, updateViewMagicSettings, viewSettingsReady, isFirstLoad } = useViewMagicSettings<{
        listFilters: any;
        page: number;
        rowsPerPage: number;
    }>({
        module: filterConfig.module,
        view: filterConfig.name,
    });

    React.useEffect(() => {
        updatedFilterViewSettingsReady?.(viewSettingsReady);
    }, [viewSettingsReady]);

    const ref = React.useRef<HTMLDivElement>(null);
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Toolbar style={{ borderBottom: '3px solid rgb(36,192,177)', minHeight: '50px' }}>
                {!pwaContext?.pwa && (
                    <span ref={ref}>
                        <FilterListSharp style={{ marginTop: '7px', marginRight: '5px' }} />
                    </span>
                )}
                <FilterChip
                    page={page}
                    rowsPerPage={rowsPerPage}
                    viewMagicSettings={viewMagicSettings}
                    updateViewMagicSettings={updateViewMagicSettings}
                    isFirstLoad={isFirstLoad}
                    viewSettingsReady={viewSettingsReady}
                    anchorEl={ref.current}
                    filterConfig={filterConfig}
                    setFilterUrl={setFilterUrl}
                    disableClearable={disableClearable}
                    skipViewSettings={skipViewSettings}
                    injectOptionValue={injectOptionValue}
                />
            </Toolbar>
        </Box>
    );
}

// Example of filter config

//let projectConfig: AutosuggestConfig = {
//     _id: generateRandomKey(),
//     label: t('##Project'), //filter field name with transalation
//     name: 'project', //filter field value
//     operators: [
//         { _id: generateRandomKey(), name: t('##IsEqualTo'), value: '=', mongoOperator: '$eq' },
//         { _id: generateRandomKey(), name: t('##IsNotEqualTo'), value: '!=', mongoOperator: '$ne' },
//     ],
//     type: 'autosuggest' as 'autosuggest', //autosuggest , daterange , select, text
//     url: 'project', //if autosuggest
//     multiple: false, //multiple checkbox selection from dropdown
//     isHidden: () => {
//         return false;
//     },
//     callback: () => {
//         return true;
//     },
//     projectId: '', //project ID optional
//     inputParam: 'name',
// };

// let channelConfig: AutosuggestConfig = {
//     _id: generateRandomKey(),
//     label: t('##Channel'), //filter field name with transalation
//     name: 'channel', //filter field value
//     operators: [
//         { _id: generateRandomKey(), name: t('##IsEqualTo'), value: '=', mongoOperator: '$eq' },
//         { _id: generateRandomKey(), name: t('##IsNotEqualTo'), value: '!=', mongoOperator: '$ne' },
//     ],
//     type: 'autosuggest' as 'autosuggest', //autosuggest , daterange , select, text
//     url: 'channel', //if autosuggest
//     multiple: true, //multiple checkbox selection from dropdown
//     isHidden: () => {
//         return false;
//     },
//     callback: () => {
//         return true;
//     },
//     projectId: '', //project ID
//     inputParam: 'name',
//     valueField: 'name',
// };

// let areaConfig: AutosuggestConfig = {
//     _id: generateRandomKey(),
//     label: t('##Area'), //filter field name with transalation
//     name: 'distributionarea', //filter field value
//     operators: [
//         { _id: generateRandomKey(), name: t('##IsEqualTo'), value: '=', mongoOperator: '$eq' },
//         { _id: generateRandomKey(), name: t('##IsNotEqualTo'), value: '!=', mongoOperator: '$ne' },
//     ],
//     type: 'autosuggest' as 'autosuggest', //autosuggest , daterange , select , numberrange , nearby
//     url: 'distributionarea', //if autosuggest
//     multiple: true, //multiple checkbox selection from dropdown
//     isHidden: () => {
//         return false;
//     },
//     callback: () => {
//         return true;
//     },
//     projectId: '', //project ID
//     inputParam: 'name',
// };

// let dateConfig: EmplanFilterField = {
//     _id: generateRandomKey(),
//     label: t('##Date'), //filter field name with transalation
//     name: 'date', //filter field value
//     operators: [
//         { _id: generateRandomKey(), name: t('##IsEqualTo'), value: '=', mongoOperator: '$eq' },
//         { _id: generateRandomKey(), name: t('##IsNotEqualTo'), value: '!=', mongoOperator: '$ne' },
//     ],
//     type: 'daterange' as 'daterange', //autosuggest , daterange , select , numberrange , nearby
//     isHidden: () => {
//         return false;
//     },
//     callback: () => {
//         return true;
//     },
// };

// let textConfig: EmplanFilterField = {
//     _id: generateRandomKey(),
//     label: t('##User'), //filter field name with transalation
//     name: 'user', //filter field value
//     operators: [
//         { _id: generateRandomKey(), name: t('##IsEqualTo'), value: '=', mongoOperator: '$eq' },
//         { _id: generateRandomKey(), name: t('##IsNotEqualTo'), value: '!=', mongoOperator: '$ne' },
//     ],
//     type: 'text' as 'text', //autosuggest , daterange , select , numberrange , nearby
//     isHidden: () => {
//         return false;
//     },
//     callback: () => {
//         return true;
//     },
// };

// let selectConfig: selectConfig = {
//     _id: generateRandomKey(),
//     label: t('##InvoiceType'), //filter field name with transalation
//     name: 'invoicetype', //filter field value
//     operators: [
//         { _id: generateRandomKey(), name: t('##IsEqualTo'), value: '=', mongoOperator: '$eq' },
//         { _id: generateRandomKey(), name: t('##IsNotEqualTo'), value: '!=', mongoOperator: '$ne' },
//     ],
//     options: () => {
//         return [
//             { _id: generateRandomKey(), name: t('##Invoiced'), value: 'true' },
//             { _id: generateRandomKey(), name: t('##NotInvoiced'), value: 'false' },
//         ];
//     },
//     type: 'select' as 'select', //autosuggest , daterange , select , numberrange , nearby
//     isHidden: () => {
//         return false;
//     },
//     callback: () => {
//         return true;
//     },
// };

// const FieldNames = [projectConfig, channelConfig, dateConfig, areaConfig, selectConfig, textConfig];

//     const FilterConfig: EmpplanFilterConfig = {
//         name: 'filterviewname',
//         module: 'filterforcalender',
//         items: FieldNames,
//         defaultValue: {},
//     };

//const [filterUrl, setFilterUrl] = React.useState<RequestParams>({ url: '', params: {} });
// const [page, setPage] = React.useState(0);
// const [rowsPerPage, setRowsPerPage] = React.useState(10);
