import Dexie, { Table } from 'dexie';

class CRMActionDB extends Dexie {
    crmActions!: Table<DexieCRMAction>;

    constructor() {
        super('crmActionsDB');

        this.version(1).stores({
            crmActions: `_id, lastChangeDate`,
        });
    }
}

export const newCRMActionsDb = new CRMActionDB();
