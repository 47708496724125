import { Delete } from '@mui/icons-material';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';

export default function DisplayElementWrapper({
    children,
    height,
    handleDelete,
    isReadOnly,
    link,
    name,
}: {
    children?: JSX.Element | JSX.Element[];
    height: string;
    handleDelete?: () => void;
    isReadOnly?: boolean;
    link?: string;
    name?: string;
}) {
    const { t } = useTranslation();
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);

    return (
        <Box position='relative' height='100%' className='imagedeletebox'>
            {children}
            {handleDelete && (
                <IconButton
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        color: 'white',
                        backgroundColor: '#454545',
                    }}
                    disabled={isReadOnly}
                    onClick={() => {
                        setOpenDeleteDialog(true);
                    }}
                >
                    <Delete fontSize='small' />
                </IconButton>
            )}
            {link && (
                <IconButton
                    sx={{
                        position: 'absolute',
                        right: handleDelete ? '40px' : 0,
                        top: 0,
                        color: 'white',
                        backgroundColor: '#454545',
                    }}
                    component={Link}
                    href={link}
                    download={name || true}
                >
                    <DownloadForOfflineRoundedIcon fontSize='small' />
                </IconButton>
            )}
            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle>{t('##Delete')}</DialogTitle>
                <DialogContent>
                    <Typography>{t('##DoYouWantToDeleteThisDocument?')}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenDeleteDialog(false);
                        }}
                    >
                        {t('##No')}
                    </Button>
                    <Button
                        onClick={() => {
                            setOpenDeleteDialog(false);
                            handleDelete && handleDelete();
                        }}
                    >
                        {t('##Yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
