import { Box, Button, Grid, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import React, { useContext } from 'react';
import API from '../../../config/api';
import { useTranslation } from 'react-i18next';
import { ReportConfigurationContext } from '..';
import { PWAContext } from '../../../PWA/pwaContext';
import { requireHelper } from '../utils/requireHelper';

interface CloseEventProps {
    eventIdObj?: {
        contextType?: string;
        contextId?: string;
        eventState?: string;
    };
    field: CustomReportField;
    reportData: ReportData;
    onChange: any;
}

function CloseEventComponent({ eventIdObj, field, reportData, onChange }: CloseEventProps) {
    const { isDisabled } = React.useContext(ReportConfigurationContext);
    const pwaContext = useContext(PWAContext);
    const requiredCondition = requireHelper(field, reportData);

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({
        defaultValues: {
            reason: '',
        },
    });
    const { t } = useTranslation();
    const onSubmit = (data: any) => {
        const eventId = eventIdObj?.contextId;
        if (pwaContext?.pwa) {
            if (pwaContext?.pwaHelpers?.events?.closeEvent) {
                // need to add here snackbar
                pwaContext.pwaHelpers.events.closeEvent(eventId);
                window.location.href = '/app#/myevents';
            }
        } else {
            API.post(`/salesItems/report/upload/${eventId}`, { SignedForm: true }).then(() => {
                // add here snackbar
                window.location.reload();
            });
        }
    };

    const lengthMin = field.config?.fieldObject?.lengthMinimum;
    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item lg={8}>
                        <TextField
                            fullWidth
                            multiline
                            variant='standard'
                            size='medium'
                            error={
                                (requiredCondition && !reportData[field.config?._id]) ||
                                (reportData[field.config?._id] && lengthMin && reportData[field.config?._id].length < lengthMin)
                            }
                            label={t('##CloseEventWithoutChecks')}
                            {...register('reason')}
                            value={reportData[field.config?._id] || ''}
                            onChange={onChange}
                            disabled={isDisabled || field.isReadonly} 
                            required={requiredCondition}
                            helperText={
                                field.config.fieldObject?.lengthMinimum &&
                                    reportData[field.config._id]?.toString().length < field.config.fieldObject?.lengthMinimum
                                    ? 'Enter Minimum Required Character'
                                    : reportData[field.config._id] === '' && field.required
                                        ? 'Empty Value!'
                                        : ''
                            }
                            sx={{
                                '& .MuiFormLabel-asterisk': {
                                    color: 'transparent',
                                    '&::after': {
                                        color: 'red',
                                        content: `"(${t('##TheFieldIsRequired')})"`,
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            type='submit'
                            variant='contained'
                            disabled={
                                isDisabled ||
                                field.isReadonly ||
                                !reportData[field.config?._id] ||
                                (reportData[field.config?._id] && lengthMin && reportData[field.config?._id].length < lengthMin)
                            }
                        >
                            {t('##CloseEvent')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}

export default CloseEventComponent;
