import {createContext, forwardRef, ReactNode, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {GridApi, GridColDef, GridColumnVisibilityModel, GridInitialState, useGridApiRef} from "@mui/x-data-grid-pro";

import * as React from 'react';
import {columnDefaults} from "../hooks/model";
import _ from "lodash";
import {debounce} from "../../../utils";
import {IViewSettings, updateSettings} from "../../../utils/settings";
import {IGridState} from "./params";
import joi from '../../../config/JoiExtension'

export interface ICustomContext {
    hiddenColumns: Set<string>;
    collapsedColumnsModel: Set<string>;
    columnsWidthModel: Record<string, number>;

    updateColumnWidth: (keyPath: string, width: number) => void;
    updateColumnWidthModel: (model: Record<string, number>) => void;
    showColumn: (field: string) => void;
    hideColumn: (field: string) => void;
    setVisibilityModel: (model: GridColumnVisibilityModel) => void;
    columnTree: Record<string, any>;
    setColumnCollapsed: (columnKey: string, collapsed: boolean) => void;
    toggleColumnCollapsed: (columnKey: string) => void;
    columnsList: Record<string,{colDef:GridColDef,subtree?:Record<string, any>}>,
    setColumns: (columns: GridColDef[]) => void;
    gridApiRef: any;
    restoreSavedState: (state:ISavedColumnContextState) => void;
    gridStateUpdated?: Symbol;
    getGridState: () => ISavedColumnContextState;
}
export interface ISavedColumnContextState {
    columnVisibilityModel: GridColumnVisibilityModel;
    collapsedColumnsModel: string[];
    columnsWidthModel: { [key: string]: number };
    gridState: Omit<GridInitialState, 'preferencePanel'|'detailPanel'>
    columnsOrderModel: string[];
    filterableColumns?: string[];
    sortableColumns?: string[];
}
export const JoiSavedSettingsContext = joi.object().keys({
    columnVisibilityModel: joi.object().label('columnVisibilityModel'),
    collapsedColumnsModel: joi.array().items(joi.string()).label('collapsedColumnsModel'),
    columnsWidthModel: joi.object().label('columnsWidthModel'),
    gridState: joi.object().label('gridState'),
    columnsOrderModel: joi.array().items(joi.string()).label('columnsOrderModel'),
}).required().label('JoiSavedSettingsContext');
function applyColumnWidthModel(gridApi: GridApi, columnWidthModel: Record<string, number>,collapsedColumnModel:string[]) {;
    Object.entries(columnWidthModel).forEach(([key, width]) => {
        if(key in gridApi.state.columns.lookup) {
            gridApi.setColumnWidth(key, width);
        }
        })
}
function applyColumnOrder(gridApi: GridApi, columnOrder: string[]) {
    columnOrder.forEach((field, index) => {
        if(field in gridApi.state.columns.lookup) {
            gridApi.setColumnIndex(field, index);
        }
    })
    if('__check__' in gridApi.state.columns.lookup) {
        gridApi.setColumnIndex('__check__', 0)
    }
}
export const ColumnsPanelContext = createContext<ICustomContext>({} as ICustomContext)
export function ColumnsPanelContextProvider({children}: {children: ReactNode}) {
    const gridApiRef  = useGridApiRef();
    const [defaultVisibilityMode, setDefaultVisibilityMode] = useState<'showAll'|'hideAll'>('showAll');
    const [hiddenColumns, setHiddenColumns] = useState<Set<string>>(()=>new Set());
    const [collapsedColumnsModel, setCollapsedColumnsModel] = useState<Set<string>>(new Set());
    const [columnsWidthModel, setColumnsWidthModel] = useState<Record<string, number>>({});

    const [columnsList, setColumnsList] = useState<Record<string,{colDef:GridColDef,subtree?:Record<string, any>}>>({});
    const [columnTree,setColumnTree] = useState<Record<string, any>>({});

    const [gridStateUpdated, _setGridStateUpdated] = useState<Symbol>();
    const setStateUpdated = ()=> _setGridStateUpdated(Symbol());
    const getGridState =() :ISavedColumnContextState=> ({
        gridState:_.omit(gridApiRef.current?.exportState?.(),'preferencePanel','detailsPanel'),
        columnsOrderModel: gridApiRef.current?.state.columns.all,
        columnVisibilityModel: context.getVisibilityModel(),
        collapsedColumnsModel: Array.from(collapsedColumnsModel),
        columnsWidthModel: columnsWidthModel
    })
    useEffect(()=>{
        // if(defaultVisibilityMode === 'showAll'){
        //     setHiddenColumns(new Set());
        // }else{
        //     setHiddenColumns(new Set(Object.keys(columnsList)));
        // }
    },[columnsWidthModel])
    const stateChangeHandler = () => {
        if (!gridApiRef.current.state) {
            return;
        }
        const columnWidthModel:Record<string, number> = Object.entries(gridApiRef.current.state.columns.lookup).reduce((acc, [key, column]) => {
            if (column.resizable && column.width && column.width !== columnDefaults.width) {
                if(collapsedColumnsModel.has(key)) {
                    acc[`${key}[collapsed]`] = column.width
                } else {
                    acc[key] = column.width;
                }
            }
            return acc;
        }, columnsWidthModel);
        context.updateColumnWidthModel(columnWidthModel);
        setStateUpdated();
    };
    useEffect(() => {
        const unsubscribe = gridApiRef.current?.subscribeEvent?.('stateChange', stateChangeHandler);
        return () => {
            unsubscribe();
        };
    }, [gridApiRef]);
    useEffect(() => {
        gridApiRef.current?.setColumnVisibilityModel?.(context.getVisibilityModel());
    }, [hiddenColumns])
    useEffect(() => {
        setStateUpdated();
    }, [collapsedColumnsModel])
    useEffect(() => {
        setStateUpdated();
    }, [columnsWidthModel])
    window.f = {columns: columnsList, columnTree, columnsWidthModel, setColumnsWidthModel, setColumns: setColumnsList, setColumnTree, hiddenColumns, setHiddenColumns, c:collapsedColumnsModel, setCollapsedColumnsModel}
    const context = useMemo(() => ({
        hiddenColumns,
        collapsedColumnsModel,
        hideColumn:(keyPath:string) =>{
            setHiddenColumns(model => {
                if(model.has(keyPath)) return model;

                model.add(keyPath)
                return new Set(model)
            })
        },
        showColumn:(keyPath:string) =>{
            setHiddenColumns(model => {
                if(!model.has(keyPath)) return model;
                model.delete(keyPath)
                return new Set(model)
            })
        },
        setVisibilityModel:(model: GridColumnVisibilityModel) => {

            Object.entries(model).forEach(([keyPath, visible]) => {
                if(visible) {
                    hiddenColumns.delete(keyPath)
                } else {
                    hiddenColumns.add(keyPath)
                }
            })
        },
        setColumnCollapsed:(columnKey:string,collapsed:boolean) =>{
            setCollapsedColumnsModel(model => {
                if(collapsed) {
                    model.add(columnKey)
                } else {
                    model.delete(columnKey)
                }
                return new Set<string>(model)
            })
        },
        toggleColumnCollapsed:(columnKey:string) =>{
            setCollapsedColumnsModel(model => {
                if(model.has(columnKey)) {
                    model.delete(columnKey)
                } else {
                    model.add(columnKey)
                }
                return new Set<string>(model)
            })
        },
        columnsWidthModel,
        updateColumnWidth:(keyPath:string,width:number) =>{
            setColumnsWidthModel(model => ({
                ...model,
                [keyPath]: width
            }))
        },
        updateColumnWidthModel:(newWidthProps:Record<string, number>) =>{
            setColumnsWidthModel(model => ({
                ...model,
                ...newWidthProps
            }))
        },
        setColumns:(colDefs:GridColDef[]) =>{
                    const allColumns = {} as typeof columnsList;
                    function getTree(columns:(GridColDef & {_extraInfo?:{fullColDef:GridColDef[]}})[]) {
                        let totalWidth = 0;
                      return columns.reduce((tree, col) => {
                          if(col._extraInfo?.fullColDef) {
                              const subTree = getTree(col._extraInfo?.fullColDef);
                              tree[col.field] = subTree;
                              allColumns[col.field] = {colDef:col, subtree: subTree};
                          } else {
                              tree[col.field] = null;
                              allColumns[col.field] = {colDef:col};
                          }
                          // tree[col.field] = col._extraInfo?.fullColDef ? getTree(col._extraInfo?.fullColDef) : null;
                          return tree;
                      }, {} as any)
                  }
                  const tree = getTree(colDefs);
                  setColumnTree(tree);
                  setColumnsList(allColumns)
        },
        columnsList,
        gridApiRef,
        columnTree,
        setCollapsedColumnsModel,
        restoreSavedState: (savedState: ISavedColumnContextState) => {
            if(gridApiRef.current.state) {
                if (savedState.columnsWidthModel) {
                    applyColumnWidthModel(gridApiRef.current, columnsWidthModel, savedState.collapsedColumnsModel || [...collapsedColumnsModel]);
                }
                if (savedState.columnsOrderModel) {
                    applyColumnOrder(gridApiRef.current, savedState.columnsOrderModel);
                }
                if (savedState.columnVisibilityModel) {
                    // ALERT: setColumnVisibilityModel must be called after applyColumnWidthModel, as that function will set visibility true for the column
                    gridApiRef.current.setColumnVisibilityModel(savedState.columnVisibilityModel);
                }
            }
            context.setVisibilityModel(savedState.columnVisibilityModel || {});
            context.setCollapsedColumnsModel(new Set(savedState.collapsedColumnsModel));
            context.updateColumnWidthModel(savedState.columnsWidthModel);
        },
        getVisibilityModel: () => {
            return Object.fromEntries(Object.entries(columnsList).map(([key, col]) => [key, !hiddenColumns.has(key)]))
        },
        getGridState,
        gridStateUpdated
    }), [columnsList,setColumnsList,gridStateUpdated,hiddenColumns,columnsWidthModel, collapsedColumnsModel,columnTree])
    return <ColumnsPanelContext.Provider value={context}>{children}</ColumnsPanelContext.Provider>
}

