//https://bareynol.github.io/mui-theme-creator/

// import { ThemeOptions } from "@mui/material";

export const themeOptions: any = {
    palette: {
        type: 'light',
        primary: {
            main: '#424242',
            light: '#858484',
            contrastText: '#fff',
        },
        secondary: {
            main: '#27b3ae',
            contrastText: '#fff',
        },
        success: {
            main: '#4caf11',
            contrastText: '#fff',
        },
    },
    components: {
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        opacity: 0.5,
                    },
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                badge: {
                    backgroundColor: '#d10b88',
                    color: 'white',
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#424242',
                },
            },
        },
    },
};

/**
export const themeOptions: ThemeOptions = {
  palette: {
    type: 'dark',
    primary: {
      main: '#424242',
      light: '#858484',
      contrastText: '#fff',
    },
    secondary: {
      main: '#24c0b1',
    },
  },
};
**/
