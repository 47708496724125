import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React from 'react';
import API from '../../config/api';

interface TargetDefinitionsAutocompleteProps {
    value: TargetDefinitionsProfile | null;
    onChange: (profile: TargetDefinitionsProfile) => void;
    projectId: string;
}

function TargetDefinitionsAutocomplete({ value, onChange, projectId }: TargetDefinitionsAutocompleteProps) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<readonly TargetDefinitionsProfile[]>([]);
    const loading = open && options.length === 0;

    React.useEffect(() => {
        let active = true;

        if (!loading && options.length > 0) {
            return undefined;
        }

        (async () => {
            const response = await API.get(`/targetdefinitionsprofile?project=${projectId}`);
            const jsonData = await response.json();
            if (active && jsonData.success) {
                setOptions(jsonData.data);
            }
        })();

        return () => {
            active = false;
        };
    }, []);

    return (
        <Autocomplete
            id='TargetDefinitionsProfile-autocomplete'
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            getOptionLabel={option => option.name}
            options={options}
            loading={loading}
            value={value}
            onChange={(e, value) => value && onChange(value)}
            renderInput={params => (
                <TextField
                    {...params}
                    label='Select target profile'
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

export default TargetDefinitionsAutocomplete;
