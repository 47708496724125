import { Box, Button, Collapse, CssBaseline, Fab, Grid, Paper, TablePagination, TextField, Typography } from '@mui/material';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EventCard from 'reactelements/src/pages/MyEvents/components/EventCard';
import React, { useEffect, useState } from 'react';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import PwaWrapper from 'reactelements/src/PWA/pwaWrapper';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch } from 'react-redux';
import { clearEventListAction, loadEventListAction, promoterDeleteEventAction } from '../../Models/NewEvent/actions';
import { Dispatch } from 'redux';
import BottomFilters from 'reactelements/src/pages/MyEvents/components/BottomFilters';
import { getLocationCoordinates } from 'reactelements/src/hooks/useFileUpload/fileHelper';
import { clearProjectListAction, loadProjectListAction } from '../../Models/NewProject/actions';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import { AddSharp } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { isDeviceOnline } from '../../Services/OnlineStatus';

function MyEvents() {
    const dispatch: Dispatch<any> = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const Events = useSelector((store: any) => store?.event?.loadedEvents);
    const Projects: any = useSelector((store: any) => store?.project?.loadedProjects);
    const Count = useSelector((store: any) => store.event?.loadedEventsCount);
    const savedTableSettings = JSON.parse(localStorage.getItem('myEventsTableSettings') || '{}');

    const [activeAccordion, setActiveAccordion] = useState<string | false>(
        savedTableSettings.activeAccordion ? savedTableSettings.activeAccordion : false,
    );
    const [page, setPage] = useState(savedTableSettings && savedTableSettings.page ? savedTableSettings.page : 0);
    const [rowsPerPage, setRowsPerPage] = useState(
        savedTableSettings && savedTableSettings.limit ? savedTableSettings.limit : 10,
    );
    const [filters, setFilters] = useState<any>({
        projectId: savedTableSettings.projectId ? savedTableSettings.projectId : null,
        eventState: savedTableSettings.eventState ? savedTableSettings.eventState : 'Accepted',
    });
    const [location, setLocation] = useState({
        lat: savedTableSettings.lat ? savedTableSettings.lat : 0,
        lon: savedTableSettings.lon ? savedTableSettings.lon : 0,
        rangeInKm: savedTableSettings.rangeInKm ? savedTableSettings.rangeInKm : 100,
    });

    const [showFilters, setShowFilters] = useState<boolean>(
        savedTableSettings.openFilter ? savedTableSettings.openFilter : false,
    );

    const handleActiveAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setActiveAccordion(isExpanded ? panel : false);
    };

    const [orderBy, setOrderBy] = useState(savedTableSettings.orderBy ? savedTableSettings.orderBy : 'date');

    const getLocation = async () => {
        try {
            const location = await getLocationCoordinates();
            setLocation({
                lat: location.latitude,
                lon: location.longitude,
                rangeInKm: 100,
            });
        } catch (e) {
            alert('Enable GPS');
        }
    };

    const handleDeleteEventPwa = (id: string | number) => {
        dispatch(promoterDeleteEventAction({ id: id }));
        setPage(0);
    };

    useEffect(() => {
        dispatch(loadProjectListAction({}));
        return () => {
            dispatch(clearEventListAction());
            dispatch(clearProjectListAction());
        };
    }, []);

    useEffect(() => {
        if (filters.eventState === 'SalesReportUploaded,SalesReportAccepted') {
            setShowFilters(false);
            setFilters({
                projectId: null,
                eventState: filters.eventState,
            });
            setLocation({ lat: 0, lon: 0, rangeInKm: 100 });
        }
    }, [filters.eventState]);

    useEffect(() => {
        dispatch(
            loadEventListAction(
                {
                    projectId: filters.projectId?._id,
                    page: page,
                    limit: rowsPerPage,
                    eventState: filters.eventState,
                    lon: location.lon,
                    lat: location.lat,
                    rangeInKm: location.rangeInKm,
                },
                orderBy,
            ),
        );
    }, [page, rowsPerPage, filters, location]);

    useEffect(() => {
        /** effect for saving table settings
         * we will save this following info and render it whenever user revisit this page
         * we will destroy this once user logged out!
         * **/
        const saveSettings = {
            projectId: filters.projectId,
            page,
            limit: rowsPerPage,
            eventState: filters.eventState,
            lon: location.lon,
            lat: location.lat,
            rangeInKm: location.rangeInKm,
            openFilter: showFilters,
            activeAccordion,
            orderBy,
        };
        localStorage.setItem('myEventsTableSettings', JSON.stringify(saveSettings));
    }, [page, rowsPerPage, filters, location, activeAccordion, showFilters, orderBy]);

    useEffect(() => {
        // this will reset the page to previous page when eventState of last event changes on table
        // override the settings
        if (Count > 0 && page > 0 && Count <= rowsPerPage * page) {
            setPage(page - 1);
        }
    }, [Count, rowsPerPage, page]);

    const setBottomFilter = (value: string) => {
        setFilters({ ...filters, eventState: value });
        if (value === 'SalesReportUploaded,SalesReportAccepted') {
            setOrderBy('-date');
        } else {
            setOrderBy('date');
        }
        setPage(0);
    };

    return (
        <PwaWrapper>
            <CssBaseline></CssBaseline>
            <Box>
                <Paper
                    elevation={5}
                    sx={theme => ({
                        background: `linear-gradient(#24c0b1 -200%, #ffffff 100%);`,
                    })}
                >
                    <Grid container px={3}>
                        <Grid item xs={12} py={3}>
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <Box>
                                    <Typography variant='h6' fontWeight={600}>
                                        {t('##MyEvents')}
                                    </Typography>
                                </Box>
                                <Box>
                                    {filters.projectId || location.lat ? (
                                        <Button
                                            variant='text'
                                            color='error'
                                            size='small'
                                            onClick={() => {
                                                setShowFilters(false);
                                                setFilters({
                                                    projectId: null,
                                                    eventState: filters.eventState,
                                                });
                                                setLocation({ lat: 0, lon: 0, rangeInKm: 100 });
                                                setPage(0);
                                            }}
                                        >
                                            {t('##Clear')}
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                    <Button
                                        variant='text'
                                        color='error'
                                        disabled={filters.eventState === 'SalesReportUploaded,SalesReportAccepted'}
                                        size='small'
                                        onClick={() => {
                                            getLocation();
                                            setPage(0);
                                        }}
                                    >
                                        <MyLocationIcon
                                            style={{
                                                color: location?.lat !== 0 ? 'rgb(36,192,177)' : 'grey',
                                            }}
                                        ></MyLocationIcon>
                                    </Button>
                                    <Fab
                                        size='small'
                                        color='secondary'
                                        aria-label='add'
                                        disabled={filters.eventState === 'SalesReportUploaded,SalesReportAccepted'}
                                        onClick={() => {
                                            setShowFilters(!showFilters);
                                        }}
                                    >
                                        {showFilters ? <CloseOutlinedIcon /> : <FilterListOutlinedIcon />}
                                    </Fab>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs={12} item component={Collapse} in={showFilters} width='100%' unmountOnExit>
                            {Array.isArray(Projects) && Projects.length > 0 && (
                                <Box pb={3}>
                                    <Autocomplete
                                        options={Projects}
                                        value={filters.projectId}
                                        getOptionLabel={(option: any) => option && option.name}
                                        onChange={(event, value) => {
                                            setFilters({ ...filters, projectId: value });
                                            setPage(0);
                                        }}
                                        renderInput={params => (
                                            <TextField {...params} variant='standard' label={t('##Projects')} />
                                        )}
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <Box>
                {Array.isArray(Events) && Events.length > 0 && Array.isArray(Projects) && Projects.length > 0 ? (
                    Events.map((event: DexieEvent) => {
                        return (
                            <EventCard
                                key={event._id}
                                eventData={{
                                    ...event.event,
                                    project_id: Projects.find((p: DexieProject) => p._id === event.projectId)?.project,
                                }}
                                activeAccordian={activeAccordion}
                                handleActiveAccordianChange={handleActiveAccordionChange}
                                handleDeleteEventPwa={handleDeleteEventPwa}
                                setBottomFilters={setBottomFilter}
                            />
                        );
                    })
                ) : (
                    <Box
                        mt='30px'
                        display='flex'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                        minHeight={200}
                        bgcolor='#f8f8f8'
                        borderRadius={8}
                        boxShadow={2}
                        p={3}
                    >
                        <Typography variant='h6' color='primary' align='center'>
                            {t('##NoEventsToDisplayLine1')}
                        </Typography>
                        <Typography variant='body2' color='text.secondary' align='center' mt={2}>
                            {t('##NoEventsToDisplayLine2')}
                        </Typography>
                    </Box>
                )}
                {location.lat !== 0 && (
                    <Typography variant='body2' color='primary' align='center' my={1}>
                        {t('##LocationFilterActiveInfoText')}
                    </Typography>
                )}
                <Box width={'100%'} mb={10}>
                    {Array.isArray(Events) && Events.length > 0 && (
                        <Paper>
                            <TablePagination
                                component='div'
                                count={Count}
                                page={page}
                                onPageChange={(event, newPage) => {
                                    setPage(newPage);
                                }}
                                labelRowsPerPage={t('##EventsPerPage')}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={event => {
                                    setRowsPerPage(parseInt(event.target.value, 10));
                                    setPage(0);
                                }}
                            />
                        </Paper>
                    )}
                </Box>
            </Box>

            {/* section to add events for new PWAfieldManagers */}
            {window?.user?.roles?.includes?.('FieldmanagerPWA') && isDeviceOnline() && (
                <Fab
                    color='secondary'
                    sx={{ position: 'fixed', bottom: 135, right: 16, zIndex: 10 }}
                    hidden={true}
                    onClick={() => {
                        history.push('/event/add');
                    }}
                >
                    <AddSharp />
                </Fab>
            )}

            <BottomFilters
                bottom={0}
                position='fixed'
                value={filters.eventState}
                onChange={(event, value) => {
                    setBottomFilter(value);
                }}
            ></BottomFilters>
        </PwaWrapper>
    );
}

export default MyEvents;
