import { newSalesItemDb } from './newSalesItemDexie';
import {
    createNewSalesItemWrapperForDexie,
    deleteSalesItemsFetch,
    getSalesItemsFetch,
    syncAllSalesItems,
} from './helpers';
import ObjectID from 'bson-objectid';
import { isDeviceOnline } from '../../Services/OnlineStatus';

const newSalesItemActionTypes = {
    NEW_SALESITEM: 'NEW_SALESITEM',
    LOAD_SALESITEM_LIST: 'LOAD_SALESITEM_LIST',
    LOAD_SALESITEM: 'LOAD_SALESITEM',
    UPDATE_SALESITEM_SYNC_DATA: 'UPDATE_SALESITEM_SYNC_DATA',
    DELETE_SALESITEM: 'DELETE_SALESITEM',
};

export default newSalesItemActionTypes;

export const createNewSalesItemAction = (salesItem: DexieSalesItem) => {
    newSalesItemDb.salesItems.add(salesItem);
    return {
        type: newSalesItemActionTypes.NEW_SALESITEM,
        payload: salesItem,
    };
};

/**
 * 
 * @param salesItem 
 * @param isCrmEventOnline => we will not sync this sales item here if that is crm event
 * we will sync at crmAction controller
 * @returns 
 */
export const newSalesItemPostAction = async (salesItem: any, isCrmEventOnline: boolean = true) => {
    if (salesItem) {
        if (!salesItem._id) {
            salesItem._id = new ObjectID().str;
        }
        newSalesItemDb.salesItems.add({
            _id: salesItem._id,
            salesItem,
            event: salesItem.eventId,
            isNewDoc: isCrmEventOnline ? 1 : 0,
            syncPending: isCrmEventOnline ? 1 : 0,
            syncDate: new Date(),
            syncError: 0,
        });
    }
    return {
        type: newSalesItemActionTypes.NEW_SALESITEM,
        payload: salesItem,
    };
};

/**
 *
 * @param salesItem
 * @param isCrmEventOnline => we will not sync this sales item here if that is crm event
 * we will sync at crmAction controller
 * @returns
 */
export const newSalesItemPutAction = async (salesItem: any, isCrmEventOnline: boolean = true) => {
    if (salesItem?._id) {
        newSalesItemDb.salesItems.update(salesItem._id, {
            salesItem: salesItem,
            syncPending: isCrmEventOnline ? 1 : 0,
        });
    }
    return {
        type: newSalesItemActionTypes.NEW_SALESITEM,
        payload: salesItem,
    };
};

export const newSalesItemDeleteAction = async (salesItem: any, successCallback?: any, errorCallback?: any) => {
    try {
        if (!salesItem?._id) throw new Error('no _id in salesitem on function call');
        // find the salesItem in the database
        const dexiesalesItem = await newSalesItemDb.salesItems.get(salesItem._id);
        if (!dexiesalesItem) throw new Error('Cannot find salesitem in database');
        // we check if doc is already on the server
        if (!dexiesalesItem.isNewDoc) {
            if (!isDeviceOnline()) throw new Error('Cannot perform action when device is offline');
            const data = await deleteSalesItemsFetch(salesItem._id);
            if (!data || data?.errors) {
                throw new Error('Failed to delete from server');
            }
        }
        // we delete from local database now
        await newSalesItemDb.salesItems.delete(salesItem._id);
        successCallback && successCallback();
    } catch (error) {
        console.log(error);
        errorCallback && errorCallback();
    }
    return {
        type: newSalesItemActionTypes.DELETE_SALESITEM,
        payload: salesItem,
    };
};

export const loadSalesItemAction = async (dispatch: any, salesItemId: string | null) => {
    if (salesItemId !== null && salesItemId) {
        const salesItems = await newSalesItemDb.salesItems.get(salesItemId);
        return dispatch({
            type: newSalesItemActionTypes.LOAD_SALESITEM,
            payload: salesItems,
        });
    } else {
        return dispatch({
            type: newSalesItemActionTypes.LOAD_SALESITEM,
            payload: undefined,
        });
    }
};

export const loadSalesItemListAction = (filterOptions: { eventId: string }) => async (dispatch: any) => {
    if (filterOptions?.eventId !== undefined) {
        const salesItems = await newSalesItemDb.salesItems
            .where('event')
            .equals(filterOptions.eventId)
            .toArray();

        if (salesItems.length === 0 && isDeviceOnline()) {
            const apiSalesItem = await getSalesItemsFetch([filterOptions.eventId], 0);
            if (apiSalesItem.success && apiSalesItem.count && Array.isArray(apiSalesItem.data)) {
                return dispatch({
                    type: newSalesItemActionTypes.LOAD_SALESITEM_LIST,
                    payload: apiSalesItem.data.map((sItem: any) => createNewSalesItemWrapperForDexie(sItem)),
                });
            }
        }

        return dispatch({
            type: newSalesItemActionTypes.LOAD_SALESITEM_LIST,
            payload: salesItems,
        });
    } else {
        return dispatch({
            type: newSalesItemActionTypes.LOAD_SALESITEM_LIST,
            payload: [],
        });
    }
};

export const clearSalesItemListAction = (dispatch: any) => {
    return dispatch({
        type: newSalesItemActionTypes.LOAD_SALESITEM_LIST,
        payload: [],
    });
};

export const startSalesItemSyncAction = () => (dispatch: any) => {
    dispatch({
        type: newSalesItemActionTypes.UPDATE_SALESITEM_SYNC_DATA,
        payload: {
            state: 'DOWNLOADING',
            lastUpdate: new Date(),
        },
    });
    syncAllSalesItems(dispatch);
};
