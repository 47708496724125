import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import React, { useContext } from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useTranslation } from 'react-i18next';
import { PWAContext } from '../../../PWA/pwaContext';
import { DetectOnline } from '../../../hooks/detectOnline';

interface BottomFiltersProps {
    value: any;
    onChange?: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
    bottom: number;
    position: 'fixed' | 'absolute';
}

function BottomFilters({ value, onChange, bottom, position }: BottomFiltersProps) {
    const { t } = useTranslation();
    const pwaContext = useContext(PWAContext);
    const { isOnline } = DetectOnline();

    const BOTTOM_FILTERS = [
        {
            filterValue: 'Accepted',
            label: '##Accepted',
            Icon: DateRangeIcon,
            isDisabled: false,
        },
        {
            filterValue: 'LoggedIn,LoggedOut,SalesReportRejected',
            label: '##InProgress',
            Icon: AssignmentIcon,
            isDisabled: false,
        },
        {
            filterValue: 'SalesReportUploaded,SalesReportAccepted',
            label: '##Done',
            Icon: EventAvailableIcon,
            isDisabled: pwaContext?.pwa && !isOnline,
        },
    ];

    return (
        <Paper sx={{ position: position, bottom: bottom, left: 0, right: 0 }} elevation={7}>
            <BottomNavigation showLabels value={value} onChange={onChange}>
                {BOTTOM_FILTERS.map(({ filterValue, label, Icon, isDisabled }) => (
                    <BottomNavigationAction
                        disabled={isDisabled}
                        key={filterValue}
                        value={filterValue}
                        label={t(label)}
                        icon={<Icon />}
                        sx={theme => ({
                            '&.Mui-selected': {
                                color: theme.palette.secondary.main,
                            },
                        })}
                    />
                ))}
            </BottomNavigation>
        </Paper>
    );
}

export default BottomFilters;
