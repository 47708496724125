import React from 'react';
import API from '../../config/api';
import {
    Box,
    Typography,
    Card,
    CardContent,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    LinearProgress,
    useMediaQuery,
    Paper,
} from '@mui/material';
import { formatDateTime } from '../../utils/date';
import useRefsLoader from './useRefsLoader';
import { useTranslation } from 'react-i18next';

function CommonHistory({ model, modelRef }: CommonHistoryProps) {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [history, setHistory] = React.useState<CommonHistoryDocument[]>([]);
    const [historyCount, setHistoryCount] = React.useState<number>(0);
    const [page, setPage] = React.useState<number>(0);
    const isMobile = useMediaQuery('(max-width:600px)');


    const [renderRef] = useRefsLoader();

    React.useEffect(() => {
        setLoading(true);
        const HistoryPerPageLimit = 10;
        API.get(
            `/commonhistory?model=${model}&modelRef=${modelRef}&limit=${HistoryPerPageLimit}&skip=${
                page * HistoryPerPageLimit
            }`,
        )
            .then(res => res.json())
            .then(data => {
                if (data?.success && Array.isArray(data.data)) {
                    if (page > 0) {
                        setHistory([...history, ...data.data]);
                    } else {
                        setHistory(data.data);
                    }
                    setHistoryCount?.(data.count ?? 0);
                }
            })
            .catch(console.error)
            .finally(() => {
                setLoading(false);
            });
    }, [model, modelRef, page]);

    return (
        <Box>
            {history.map(item => (
                <Card key={item._id} style={{ marginBottom: '16px' }}>
                    <CardContent>
                        <Typography variant='body2'>
                            On {formatDateTime(item.created)} by{' '}
                            {`${item.user?.firstName} ${item.user?.lastName} (${item.user?.email})`}
                        </Typography>
                        {!isMobile ? (
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <strong>Path</strong>
                                        </TableCell>
                                        <TableCell>
                                            <strong>{t`##From`}</strong>
                                        </TableCell>
                                        <TableCell>
                                            <strong>{t`##To`}</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {item.changes.map((change, idx) => {
                                        const stringPath = change.path.join('.');
                                        return (
                                            <TableRow key={idx}>
                                                <TableCell>{stringPath}</TableCell>
                                                <TableCell sx={theme => ({ color: theme.palette.error.main })}>
                                                    {renderRef(model, stringPath, change.from)}
                                                </TableCell>
                                                <TableCell sx={theme => ({ color: theme.palette.success.main })}>
                                                    {renderRef(model, stringPath, change.to)}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        ) : (
                            // Mobile View: Display as a stacked list
                            <Box>
                                {item.changes.map((change, idx) => {
                                    const stringPath = change.path.join('.');
                                    return (
                                        <Paper key={idx} sx={{ padding: 2, marginBottom: 1 }}>
                                            <Typography variant='subtitle2'><strong>Path:</strong> {stringPath}</Typography>
                                            <Typography variant='body2' sx={theme => ({ color: theme.palette.error.main })}>
                                                <strong>{t`##From:`}</strong> {renderRef(model, stringPath, change.from)}
                                            </Typography>
                                            <Typography variant='body2' sx={theme => ({ color: theme.palette.success.main })}>
                                                <strong>{t`##To:`}</strong> {renderRef(model, stringPath, change.to)}
                                            </Typography>
                                        </Paper>
                                    );
                                })}
                            </Box>
                        )}
                    </CardContent>
                </Card>
            ))}
            {loading && <LinearProgress />}
            {historyCount > history.length && (
                <Button
                    disabled={loading || historyCount <= history.length}
                    onClick={() => setPage(page + 1)}
                >
                    {t`##LoadMore`}
                </Button>
            )}
        </Box>
    );
}

export default CommonHistory;
