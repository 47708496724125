import * as React from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import API from '../../../config/api';
import { PWAContext } from '../../../PWA/pwaContext';
import { EmpplanSnackbarContext } from '../../../modules/Snackbar';
import { useTranslation } from 'react-i18next';
import LaunchIcon from '@mui/icons-material/Launch';

function DeleteEventComponent({ eventId, setRunningSave }: { eventId: string, setRunningSave: (value: boolean) => void }) {

    const [isEventModified, setIsEventModified] = React.useState(false);
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const eventState = ['Published', 'Rejected', 'Accepted', 'BrandPlanned', 'Pending'];
    const pwaContext = React.useContext(PWAContext);
    const snackbarTools = React.useContext(EmpplanSnackbarContext);
    const { t } = useTranslation();

    async function checkEventModification() {
        try {
            if (eventId && pwaContext?.pwaHelpers?.events?.returnDexieEvent) {
                const event = await pwaContext?.pwaHelpers?.events?.returnDexieEvent(eventId);
                if (event) {
                    if (event.eventState === 'LoggedIn') {
                        setIsLoggedIn(true);
                    }
                    setIsEventModified(eventState.includes(event?.eventState));
                }
            }
        } catch (error) {
            setIsEventModified(false);
            console.error('Error checking event modification:', error);
        }
    }

    React.useEffect(() => {
        checkEventModification();
    }, [eventId]);

    const deleteEvent = (_id: any) => {
        setRunningSave(true);
        API.post(`/event/${_id}/deleteEvents/promoter`, {})
            .then(response => {
                return response.json();
            })
            .then(resData => {
                if (resData.status && resData.message) {
                    snackbarTools.createToast({ type: 'success', message: t('##Deleted') });
                    if (pwaContext?.pwaHelpers?.startSync?.start) {
                        pwaContext.pwaHelpers?.startSync?.start();
                    }
                } else {
                    snackbarTools.createToast({ type: 'error', message: t('##ErrorWhileDeleting') });
                }
            })
            .catch(() => {
                snackbarTools.createToast({ type: 'error', message: t('##ErrorWhileDeleting') });
            })
            .finally(() => {
                setRunningSave(false);
            });
    };

    return (
        <>
            {isLoggedIn
                ? <a
                    style={{ marginLeft: 'auto', color: 'rgb(36 192 177)' }}
                    href={
                        pwaContext?.areWeInPwa === 'yes' && pwaContext.pwa
                            ? `#/event/${eventId}`
                            : `/#!/event/${eventId}`
                    }
                >
                    <LaunchIcon style={{ marginLeft: 'auto', paddingTop: '3px' }} />
                </a>
                :
                isEventModified &&
                <CancelIcon
                    style={{ marginLeft: 'auto', color: '#ed6c02' }}
                    onClick={() => deleteEvent(eventId)}
                    sx={{ ml: 1 }}
                />
            }
        </>
    )
}


export default DeleteEventComponent;