import React, { useContext, useState, useRef } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    FormLabel,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ToolTipComponent from '../ToolTipComponent';
import { ReportConfigurationContext } from '../index';
import { requireHelper } from '../utils/requireHelper';
import CloseIcon from '@mui/icons-material/Close';
import useFileUpload from '../../../hooks/useFileUpload';
import { sanitizeUploadFieldValue } from './UploadField';
import { trackEvent } from '../../../components-app/GAListener';

interface IProps {
    field: CustomReportField;
    reportData: ReportData;
    contextObjForUpload?: {
        contextType?: string;
        contextId?: string;
    };
    setUploadStatus?: (status: boolean) => void;
    endpoint: string;
    onChange: (value: string) => void;
}

function SignatureComponent({ field, reportData, contextObjForUpload, setUploadStatus, endpoint, onChange }: IProps) {
    const { t } = useTranslation();
    const { isDisabled } = useContext(ReportConfigurationContext);
    const requiredCondition = requireHelper(field, reportData);

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const downloadCanvasRef = useRef<HTMLCanvasElement | null>(null);

    const [isDrawing, setIsDrawing] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [isDrawn, setIsDrawn] = useState<boolean>(false);

    const value = sanitizeUploadFieldValue(reportData[field.config._id] as string);

    const [isLoading, currentValue, onImageChange, isChanged, displayElement, errorElement, loadingElement] =
        useFileUpload({
            initialValue: value,
            remotePath: endpoint,
            onChange: value => onChange(value[0] || ''),
            setUploadStatus: setUploadStatus,
            contextObjForUpload: {
                ...contextObjForUpload,
                preferMetaGpsLocationData:
                    field.config?.fieldObject?.config?.preferMetaGpsLocationData &&
                    !field.config?.fieldObject?.config?.uploadTypeCapture,
            },
            options: {
                forceGps: field.config?.fieldObject?.config?.forceGps,
                isReadonly: isDisabled,
            },
        });

    const startSign = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
        setIsDrawn(true);
        setIsDrawing(true);
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        if (context && canvas) {
            context.strokeStyle = 'black';
            context.lineWidth = 2;
            context.lineJoin = 'round';
            context.lineCap = 'round';

            let x, y;
            if (isTouchEvent(e)) {
                x = e.touches[0].clientX - canvas.getBoundingClientRect().left;
                y = e.touches[0].clientY - canvas.getBoundingClientRect().top;
            } else {
                x = e.nativeEvent.offsetX;
                y = e.nativeEvent.offsetY;
            }

            context.beginPath();
            context.moveTo(x, y);
        }
    };

    const endSign = () => {
        setIsDrawing(false);
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        if (context) {
            context.closePath();
        }
    };

    const sign = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
        if (!isDrawing) {
            return;
        }
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        if (context && canvas) {
            let x, y;

            if (isTouchEvent(e)) {
                x = e.touches[0].clientX - canvas.getBoundingClientRect().left;
                y = e.touches[0].clientY - canvas.getBoundingClientRect().top;
            } else {
                x = e.nativeEvent.offsetX;
                y = e.nativeEvent.offsetY;
            }

            context.lineTo(x, y);
            context.stroke();
        }
    };

    const clearSign = () => {
        setIsDrawn(false);
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        if (context && canvas) {
            context.clearRect(0, 0, canvas.width, canvas.height);
        }
        trackEvent('Signature', 'Signature ClearSignCanvas', 'ClearSignCanvas');
    };

    const isTouchEvent = (
        e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>,
    ): e is React.TouchEvent<HTMLCanvasElement> => {
        return 'touches' in e;
    };

    const convertToImage = () => {
        if (!isDrawn) {
            return;
        }
        const canvas = canvasRef.current;
        const downloadCanvas = downloadCanvasRef.current;

        if (canvas && downloadCanvas) {
            const canvasContext = canvas.getContext('2d');
            const downloadContext = downloadCanvas.getContext('2d');

            const isCanvasEmpty = canvasContext
                ?.getImageData(0, 0, canvas.width, canvas.height)
                .data.every(value => value === 0);

            if (isCanvasEmpty) {
                alert(t('##CanvasIsEmpty'));
                clearSign();
                console.log('No drawing on the canvas. Save action rejected.');
                return;
            }

            if (downloadContext) {
                // Swap width and height for a 90-degree rotation
                const newWidth = canvas.height;
                const newHeight = canvas.width;

                // Set the dimensions of the download canvas
                downloadCanvas.width = newWidth;
                downloadCanvas.height = newHeight;

                // Set a white background
                downloadContext.fillStyle = 'white';
                downloadContext.fillRect(0, 0, newWidth, newHeight);

                // Rotate the canvas 90 degrees
                downloadContext.translate(newWidth, 0);
                downloadContext.rotate(Math.PI / 2);

                // Copy the content from the main canvas to the download canvas
                downloadContext.drawImage(canvas, 0, 0);

                // Reset the transformation
                downloadContext.setTransform(1, 0, 0, 1, 0, 0);
            }

            // Convert the download canvas to a Blob and create a File
            downloadCanvas.toBlob(blob => {
                if (blob) {
                    const signatureFile = new File([blob], 'signature.png', { type: 'image/png' });
                    onImageChange([signatureFile]);
                }
            }, 'image/png');
        }
        setOpenDialog(false);
        setIsDrawn(false);
        trackEvent('Signature', 'Signature EndSignFlow', 'EndSignFlow');
    };

    return (
        <Box display='flex' gap={1} flexDirection='column'>
            <FormLabel id='radio-buttons-group-label1'>
                {field.config.slug ? t(field.config.slug) : field.config.name}
                {!!requiredCondition && (
                    <Box component='span' sx={{ color: 'red' }}>
                        {'  '}({t('##TheFieldIsRequired')})
                    </Box>
                )}
            </FormLabel>
            {errorElement}
            <Box height='150px' display='flex' alignItems='center' gap={1}>
                {!(value || displayElement.length > 0) && (
                    <>
                        <Button
                            disabled={isDisabled}
                            variant='contained'
                            color='secondary'
                            onClick={() => {
                                setOpenDialog(true);
                                trackEvent('Signature', 'Signature StartingSignFlow', 'StartingSignFlow');
                            }}
                        >
                            {t('##StartSignProcess')}
                        </Button>
                        <Dialog open={openDialog} fullWidth fullScreen disableEscapeKeyDown>
                            <DialogTitle
                                sx={{
                                    backgroundColor: '#424242',
                                }}
                            >
                                <Grid
                                    color='white'
                                    container
                                    direction='row'
                                    justifyContent='space-between'
                                    alignItems='center'
                                >
                                    {t('##Signature')}
                                    <IconButton
                                        onClick={() => {
                                            setIsDrawn(false);
                                            setOpenDialog(false);
                                            trackEvent('Signature', 'Signature CancelSignFlow', 'CancelSignFlow');
                                        }}
                                    >
                                        <CloseIcon sx={{ color: 'white' }} />
                                    </IconButton>
                                </Grid>
                            </DialogTitle>
                            <DialogContent dividers sx={{ display: 'flex', gap: 1 }}>
                                <Box display='flex' flexDirection='column-reverse' alignItems='center' gap={2}>
                                    <div></div>
                                    <Typography
                                        style={{
                                            writingMode: 'vertical-lr',
                                            transform: 'scale(-1, -1)',
                                        }}
                                    >
                                        {t('##SignHere')}
                                    </Typography>
                                </Box>

                                <Box
                                    style={{
                                        overflow: 'hidden',
                                        padding: 0,
                                        margin: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        borderRight: '1px solid black',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            position: 'relative',
                                            boxSizing: 'border-box',
                                        }}
                                    >
                                        <canvas
                                            ref={canvasRef}
                                            onMouseDown={startSign}
                                            onMouseUp={endSign}
                                            onMouseMove={sign}
                                            onTouchStart={startSign}
                                            onTouchEnd={endSign}
                                            onTouchMove={sign}
                                            width={window.innerWidth - 2}
                                            height={window.innerHeight - 56}
                                        ></canvas>
                                    </div>
                                    {/* for donwloading and making background white */}
                                    <canvas
                                        ref={downloadCanvasRef}
                                        style={{ display: 'none' }}
                                        width={window.innerWidth - 2}
                                        height={window.innerHeight - 56}
                                    ></canvas>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button color='error' onClick={clearSign}>
                                    {t('##Clear')}
                                </Button>
                                <Button
                                    disabled={isDrawn === false}
                                    variant='contained'
                                    color='secondary'
                                    onClick={convertToImage}
                                >
                                    {t('##Save')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
                {displayElement.length === 1 && (
                    <React.Fragment key='image_render_area'>{displayElement[0]}</React.Fragment>
                )}
                {field.config?.toolTipTxt !== '' && (
                    <ToolTipComponent
                        key='image_tooltip'
                        enableHintOnClick={field.config.enableHintOnClick}
                        tooltipText={field.config.toolTipTxt}
                    />
                )}
            </Box>
        </Box>
    );
}

export default SignatureComponent;
