import * as React from 'react';
import useFileUpload from '../../../hooks/useFileUpload';
import { ReportConfigurationContext } from '../index';
import ToolTipComponent from '../ToolTipComponent';
import { requireHelper } from '../utils/requireHelper';
import UploadArea from '../../../hooks/useFileUpload/UploadArea';
import { useContext } from 'react';
import { Box, FormControl, FormLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface UploadFieldProps {
    field: CustomReportField; // the field data
    endpoint: string; // the current value
    onChange: (value: string) => void; // updates the value after upload is complete
    // add the key to the parent control so that the form submit can be stopped incase upload is pending
    setUploadStatus?: (status: boolean) => void;
    reportData: ReportData;
    contextObjForUpload?: {
        contextType?: string;
        contextId?: string;
    };
    notcustomReport?: boolean;
    offline?: boolean;
    filename?: string;
}

/**
 * Why does this exist?
 * offline attribute is used for get offline image value
 */
export const sanitizeUploadFieldValue = function (val: string | undefined) {
    if (val) {
        if (val === 'false') {
            return '';
        }
        return val;
    } else {
        return '';
    }
};
/**
 * notcustomReport is used for non customreport form field
 * offline attribute is used for get offline image value
 */
function UploadField({ contextObjForUpload, field, onChange, endpoint, setUploadStatus, reportData }: UploadFieldProps) {
    const { t } = useTranslation();
    const requiredCondition = requireHelper(field, reportData);
    const { isDisabled } = useContext(ReportConfigurationContext);
    const value = sanitizeUploadFieldValue(reportData[field.config._id] as string);

    const [isLoading, currentValue, onImageChange, isChanged, displayElement, errorElement, loadingElement] =
        useFileUpload({
            initialValue: value,
            remotePath: endpoint,
            onChange: value => onChange(value[0] || ''),
            setUploadStatus: setUploadStatus,
            contextObjForUpload: {
                ...contextObjForUpload,
                preferMetaGpsLocationData:
                    field.config?.fieldObject?.config?.preferMetaGpsLocationData &&
                    !field.config?.fieldObject?.config?.uploadTypeCapture,
            },
            options: {
                forceGps: field.config?.fieldObject?.config?.forceGps,
                isReadonly: isDisabled,
                skipFrontendMinify: field.config?.fieldObject?.config?.skipFrontendMinify,
            },
        });

    const fieldHeight = '150px';

    return (
        <>
            {(value || displayElement.length > 0) && (
                <FormLabel id='radio-buttons-group-label'>
                    {field.config.slug ? t(field.config.slug) : field.config.name}
                    {!!requiredCondition && (
                        <Box component='span' sx={{ color: 'red' }}>
                            {'  '}({t('##TheFieldIsRequired')})
                        </Box>
                    )}
                    {field.config?.toolTipTxt !== '' && (
                        <ToolTipComponent
                            enableHintOnClick={field.config.enableHintOnClick}
                            tooltipText={field.config.toolTipTxt}
                        />
                    )}
                </FormLabel>
            )}
            {errorElement}
            <Box height={fieldHeight} display='flex' alignItems='center'>
                {!(value || displayElement.length > 0) && (
                    <UploadArea
                        key={'image_upload_area'}
                        fieldId={field.config._id}
                        label={field.config.name}
                        onFileDrop={(files: Array<File>) => {
                            if (files?.length) {
                                onImageChange(files);
                            }
                        }}
                        options={{
                            isReadonly: field.isReadonly || isDisabled,
                            enableCapture: field.config?.fieldObject?.config?.uploadTypeCapture,
                            isRequired: requiredCondition,
                        }}
                    />
                )}
                {displayElement.length === 1 && (
                    <React.Fragment key='image_render_area'>{displayElement[0]}</React.Fragment>
                )}
                {field.config?.toolTipTxt !== '' && (
                    <ToolTipComponent
                        key='image_tooltip'
                        enableHintOnClick={field.config.enableHintOnClick}
                        tooltipText={field.config.toolTipTxt}
                    />
                )}
            </Box>
        </>
    );
}

export default UploadField;
