import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import API from '../../config/api';
import { useTranslation } from 'react-i18next';
import { SmallProject } from '../../pages/Connector/Plugins/EventStatisticsExport';

interface AsynchronousAutocompleteProps {
    label: string;
    preloadOptions?: boolean;
    helperTextSlug?: string;
    onChangeHandler?: any;
    filters: {
        filterProject?: string;
    };
    urlGenerator: (input: string) => string;
    initialValue?: SmallProject;
    multiple?: boolean;
    isDisabled?: boolean;
}

const userUrlGenerator = (searchText?: string, options?: any) => {
    /**
     * may be in future
     */
    // let filterClient = '';
    // if (options?.filterProject) {
    //     if (options.filterProject?._id) {
    //         filterClient = filterClient + '&projectId=' + options.filterProject._id;
    //     } else {
    //         filterClient = filterClient + '&projectId=' + options.filterProject;
    //     }
    // }
    const urlPath = `projects/all/XXXX?name=${searchText}&active=true`;
    return urlPath;
};

function AsynchronousAutocomplete({
    label,
    onChangeHandler,
    filters,
    urlGenerator,
    initialValue,
    preloadOptions,
}: AsynchronousAutocompleteProps) {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [filteredProjects, setFilteredProjects] = React.useState<SmallProject[]>([]);
    const [value, setValue] = React.useState<SmallProject | null>(initialValue || null);
    const [inputValue, setInputValue] = React.useState('');

    React.useEffect(() => {
        if (initialValue) {
            setValue(initialValue);
        }
    }, [initialValue]);


    React.useEffect(() => {
        let active = true;

        if (inputValue.length < 3 && !preloadOptions) {
            return undefined;
        }

        (async () => {
            setLoading(true);
            const res = await API.get(urlGenerator(inputValue));
            const result = await res.json();
            const projectsList: SmallProject[] = result.projects;

            if (active && Array.isArray(projectsList)) {
                if (!inputValue && projectsList.length === 1 && initialValue?._id !== projectsList[0]?._id) {
                    setValue(projectsList[0]);
                    onChangeHandler(projectsList[0]);
                }
                setFilteredProjects(projectsList);
                setLoading(false);
            }
        })().catch(() => {
            setLoading(false);
        });

        return () => {
            active = false;
        };
    }, [inputValue]);

    React.useEffect(() => {
        if (!open) {
            setFilteredProjects([]);
        }
    }, [open]);

    return (
        <Autocomplete
            fullWidth
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={value}
            onChange={(event: any, newValue: any) => {
                setValue(newValue);
                onChangeHandler && onChangeHandler(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            getOptionLabel={option => `${option.name}`}
            options={filteredProjects}
            noOptionsText={
                inputValue.length < 3 ? t('##StartTypingToSearch') : loading ? `${t('##Loading')}...` : t('##NothingFound')
            }
            loading={loading}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    variant='standard'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

interface ProjectSelectProps {
    onChange?: any;
    projectId?: string;
    initialValue?: SmallProject;
    label?: string;
    preloadOptions?: boolean;
}

function ProjectSelect({ onChange, projectId, initialValue, label, preloadOptions }: ProjectSelectProps) {
    const { t } = useTranslation();

    return (
        <AsynchronousAutocomplete
            label={label || t('##SearchByProject')}
            onChangeHandler={onChange}
            filters={{ filterProject: projectId }}
            urlGenerator={userUrlGenerator}
            initialValue={initialValue}
            preloadOptions={preloadOptions}
        />
    );
}

export default ProjectSelect;
