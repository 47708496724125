import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    IconButton,
    Alert,
    Stack,
    Typography,
    Tooltip,
    TextField,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    clearLoadLocationContact,
    clearSingleEventAction,
    getLocationContactDetails,
    loadEventAction,
    updateNewEvent,
} from '../../Models/NewEvent/actions';
import PwaWrapper from 'reactelements/src/PWA/pwaWrapper';
import { COLORS_MAP } from 'reactelements/src/pages/MyEvents/eventHelpers';
import { innerSectionsEventDetails, isReportTypeVisible, isUserCanAddLocationContact } from './helpers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LabelChip } from 'reactelements/src/components-app/label.chip';
import * as React from 'react';
import { clearLoadCustomReportListAction, loadCustomReportListAction } from '../../Models/NewCustomReport/actions';
import {
    loadCustomReportResultAction,
    loadAndInitCustomReportResultAction,
} from '../../Models/NewCustomReportResult/actions';
import CustomReportParserPWA from './customReportParserPWA';
import { isCustomReportDisabled } from 'reactelements/src/modules/CustomReport/utils/reportDisableControl';
import { ReportConfigurationContext } from 'reactelements/src/modules/CustomReport';
import LocationDetailView from 'reactelements/src/modules/eventGroupEdit/components/LocationDetailView';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { loadProjectAction } from '../../Models/NewProject/actions';
import ProductReportParserPWA from './ProductReportParserPWA';
import ExpenseProductReportParserPWA from './ExpenseProductReportParserPWA';
import { clearProductReportListAction, loadProductReportListAction } from '../../Models/NewProductReport/actions';
import { clearSalesItemListAction, loadSalesItemListAction } from '../../Models/NewSalesItem/actions';
import TransactionReportParserPWA from './TransactionReportParserPWA';
import { addEventActionCollection } from '../../Models/NewEventActionCollection/actions';
import EventDetailLoginComponent from './EventDetailLoginComponent';
import { ArrowBack, CheckCircleOutline, ForumSharp, SyncOutlined } from '@mui/icons-material';
import { EmpplanSnackbarContext } from 'reactelements/src/modules/Snackbar';
import { getLocationCoordinates } from 'reactelements/src/hooks/useFileUpload/fileHelper';
import DeviceDetect from 'reactelements/src/hooks/deviceDetect';
import { formatDate } from 'reactelements/src/utils/date';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import { BottomFilters } from './BottomFilters';
import EventBriefing from './EventBriefingBox';
import EventEarnings from './EventEarnings';
import useEventSyncStatus from './useEventSyncStatus';
import { loadExpenseListAction } from '../../Models/NewExpense/actions';
import SyncIcon from '@mui/icons-material/Sync';
import moment from 'moment';
import FlagIcon from '@mui/icons-material/Flag';
import { clearCustomModelFieldList, loadCustomModelFieldListAction } from '../../Models/NewCustomModelField/actions';
import { DetectOnline } from 'reactelements/src/hooks/detectOnline';
import TargetDefinitionsWrapper from 'reactelements/src/modules/TargetDefinitionsProfile/TargetDefinitions';
import LocationContactComponent from '../LocationContact/LocationContactComponent';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import eventEnum from 'reactelements/src/conf/eventConstants';
import { LoadingButton } from '@mui/lab';
import { LocationView } from 'reactelements/src/components-app/location-view';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ReactAssociatedChat from 'reactelements/src/pages/Messenger/components/ReactAssociatedChat';
import { Channel } from 'reactelements/src/pages/Messenger/types';
import API from 'reactelements/src/config/api';
import ReportAvailable from 'reactelements/src/modules/CustomReportParentWrapper/ReportAvailableHook';
import { LeadDetailTransactions } from './LeadDetailsTransactions';
import { trackEvent } from 'reactelements/src/components-app/GAListener';
import { saveAllSalesItemFromCrmEvent } from '../../Models/NewCRM/actions';
import EditIcon from '@mui/icons-material/Edit';

const findEventChatChannels = async (eventId: string) => {
    try {
        const params = new URLSearchParams({
            context: JSON.stringify({ contextObject: 'event', contextObjectId: eventId }),
        }).toString();
        return API.get('/chatchannel?' + params)
            .then(res => res.json())
            .then(jsonData => {
                console.log('chat channels jsonData', jsonData);
                if (Array.isArray(jsonData)) {
                    return jsonData as Channel[];
                } else {
                    return null;
                }
            });
    } catch (error) {
        console.error(error);
        return null;
    }
};

function EventDetailPage() {
    const singleEvent: DexieEvent = useSelector((store: any) => store?.event?.loadedEvent);
    const locationContact: any = useSelector((store: any) => store?.event?.locationContact);
    const locationContactLoading: any = useSelector((store: any) => store?.event?.loadingLocationContact);
    const customReports: any = useSelector((store: any) => store?.customReport?.loadedCustomReports);
    const transactionFixedProductReportConfig =
        Array.isArray(customReports) && customReports.find(cr => cr?.customReport?.type === 'transactionFixedProduct');
    const expenseReportConfig =
        Array.isArray(customReports) && customReports.find(cr => cr?.customReport?.type === 'expense');
    const reportResults: any = useSelector((store: any) => store?.customReportResult?.loadedCustomReportResult);
    const transactionReportConfig =
        Array.isArray(customReports) && customReports.find(cr => cr?.customReport?.type === 'sales');
    const project: DexieProject = useSelector((store: any) => store?.project?.loadedProject);
    const user = useSelector((store: any) => store?.User?.user);
    const customModelFields = useSelector((store: any) => store?.customModelField?.loadedCustomModelFields);
    const salesItems = useSelector((store: any) => store?.salesItem?.loadedSalesItems);
    const expenseItems = useSelector((store: any) => store?.expense?.loadedExpenses);
    const loadedProductReports = useSelector((s: any) => s?.productReport?.loadedProductReports);

    const { eventId, reportType, leadId } = useParams<{
        eventId: string;
        reportType?: string;
        leadId?: string;
    }>();
    const dispatch: any = useDispatch();
    const navigate = useHistory();
    const snackBarTools = React.useContext(EmpplanSnackbarContext);
    const { t } = useTranslation();
    const { isOnline } = DetectOnline();
    const expenseRef = useRef<any>(null);
    const transactionRef = useRef<any>(null);

    const [eventSyncPendingCount] = useEventSyncStatus(singleEvent?._id);
    const isEventSyncPending = eventSyncPendingCount > 0;

    const [value, setValue] = useState<'Reporting' | 'Briefing' | 'Earnings' | 'Messenger'>('Reporting');
    const [open, setOpen] = useState<boolean>(false);
    const [disableReportButton, setDisableReportButton] = useState(false);
    const [currentUserCanEditEventUser, setCurrentUserCanEditEventUser] = useState<boolean>(false);
    const [fixedProductErrorCount, setFixedProductErrorCount] = useState<number>(0);
    const [innerSection, setInnerSection] = useState<string | null>(null);
    const [mountSubmitButton, setMountSubmitButton] = useState<boolean>(false);
    const [eventReportStates, setEventReportStates] = useState<{ [key: string]: string }>({});
    const [eventChatChannel, setEventChatChannel] = useState<Channel | null>(null);
    const [allFormErrors, setAllFormErrors] = useState<Record<string, boolean>>({});
    const [pageScrollHeight, setPageScrollHeight] = useState(0);
    const [pinReportingAlertToTop, setPinReportingAlertToTop] = useState(false);

    const isCurrentUserTheEventUser = singleEvent?.event?.agency_user[0]?.user_id?._id === user?.userabstraction?._id;
    const { isReportAvailable } = ReportAvailable();

    const isCheckedInOrHigher = React.useMemo(() => {
        return eventEnum?.orHigherStates?.CheckedInAndHigher?.includes(singleEvent?.eventState);
    }, [singleEvent]);
    // console.log({ isCurrentUserTheEventUser });

    const handleValidationResult = (index: number, hasErrors: boolean) => {
        setAllFormErrors(prev => ({ ...prev, [index]: hasErrors }));
    };

    useEffect(() => {
        const error = Object.keys(allFormErrors)?.filter(key => allFormErrors[key])?.length > 0;
        setDisableReportButton(error);
    }, [allFormErrors]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setMountSubmitButton(true);
        }, 3000);
        dispatch(loadEventAction(eventId));
        dispatch(loadSalesItemListAction({ eventId: eventId }));
        loadExpenseListAction(dispatch, { eventId: eventId });

        return () => {
            clearTimeout(timer);
            dispatch(clearSingleEventAction());
        };
    }, [eventId]);

    useEffect(() => {
        if (reportType) {
            setInnerSection(reportType);
        } else {
            setInnerSection(null);
        }
    }, [reportType]);

    useEffect(() => {
        if (singleEvent?.projectId) {
            dispatch(loadCustomReportListAction({ projectId: singleEvent.projectId }));
            dispatch(loadProjectAction(singleEvent.projectId));
            dispatch(loadCustomModelFieldListAction({ pathname: 'PointOfSale', projectId: singleEvent.projectId }));
        }
        return () => {
            dispatch(clearLoadCustomReportListAction());
            dispatch(loadProjectAction(null));
            dispatch(clearCustomModelFieldList());
        };
    }, [singleEvent?.projectId]);

    useEffect(() => {
        if (user && user?.user?.roles && Array.isArray(user?.user?.roles)) {
            const roles = user.user.roles;
            if (roles.includes('EventEdit') || roles.includes('currentUserCanEditEventUser')) {
                setCurrentUserCanEditEventUser(true);
            }
        }
    }, [user]);

    useEffect(() => {
        // find the channel for this event
        if (isOnline && eventId && project?.project?.supportAdmins?.length) {
            findEventChatChannels(eventId)
                .then(jsonData => {
                    if (Array.isArray(jsonData) && jsonData[0]?._id) {
                        setEventChatChannel(jsonData[0] as Channel);
                    }
                })
                .catch(console.error);
        }
    }, [project]);

    useEffect(() => {
        if (singleEvent?.event?.point_of_sale?._id) {
            dispatch(getLocationContactDetails(singleEvent.event.point_of_sale._id));
        }
        return () => {
            dispatch(clearLoadLocationContact());
        };
    }, [singleEvent?.event?.point_of_sale]);

    useEffect(() => {
        if (singleEvent?._id && customReports?.length > 0) {
            isCheckedInOrHigher &&
                dispatch(
                    loadAndInitCustomReportResultAction(
                        customReports.filter((cr: any) => cr?.customReport?.type === 'standard'),
                        singleEvent._id,
                        singleEvent?.projectId,
                    ),
                );
        }
        if (singleEvent?._id && transactionFixedProductReportConfig?._id) {
            dispatch(loadSalesItemListAction({ eventId: singleEvent?._id }));
            dispatch(
                loadProductReportListAction({
                    customReportId: transactionFixedProductReportConfig?._id,
                    projectId: singleEvent.projectId,
                }),
            );
        }
        return () => {
            dispatch(clearProductReportListAction);
            dispatch(loadCustomReportResultAction(null));
            // dispatch(clearSalesItemListAction);
        };
    }, [customReports, singleEvent?.eventState, transactionFixedProductReportConfig?._id, singleEvent?._id]);

    const handleLogOut: () => void = async () => {
        let currentLocation = { latitude: 0, longitude: 0 };
        try {
            currentLocation = await getLocationCoordinates();
        } catch (error) {
            // sentry ?
            trackEvent('EventLogOut', 'LocationPermissionDenied', JSON.stringify(error));
            console.error(error, 'Denied User Location From Browser');
        }

        const mainEvent = {
            ...singleEvent.event,
            project_id: project._id,
            eventState: 'LoggedOut',
        };
        let { isMobile } = DeviceDetect();
        const payload = {
            device: navigator.userAgent,
            isMobile: isMobile,
            latitude: currentLocation.latitude,
            longitude: currentLocation.longitude,
            event: mainEvent,
        };

        addEventActionCollection(
            {
                _id: mainEvent._id,
                type: 'CheckOut',
            },
            payload,
        );

        finalSubmit();
    };

    const updateEventAndDispatch = async (
        eventState: 'SalesReportUploaded' | 'SalesReportAccepted',
        isCrmEvent: boolean = false,
    ) => {
        const postReportEvent: DexieEvent = {
            ...singleEvent,
            eventState,
            event: {
                ...singleEvent.event,
                eventState,
            },
        };
        updateNewEvent(postReportEvent);
        dispatch(loadEventAction(eventId));
        if (isCrmEvent) {
            await saveAllSalesItemFromCrmEvent(eventId, leadId, project._id);
        } else {
            const actionPayload = {
                _id: singleEvent._id,
                type: eventState === 'SalesReportUploaded' ? 'SubmitReport' : 'AcceptReport',
            };
            addEventActionCollection(actionPayload, eventState === 'SalesReportAccepted' ? postReportEvent : null);
        }
    };

    const finalSubmit = (crmEvent: boolean = false) => {
        setDisableReportButton(true);
        
        // if we turn on this setting from CRM config in project, we will accept salesReport directly to server
        // admins action will be executed
        const reportAcceptMode = project?.project?.eventSettings?.crmReportAcceptMode;
        if (reportAcceptMode && singleEvent.eventState !== 'SalesReportRejected') {
            updateEventAndDispatch('SalesReportAccepted', crmEvent);
        } else {
            updateEventAndDispatch('SalesReportUploaded', crmEvent);
        }

        snackBarTools.createToast({ type: 'success', message: t('##Success') });
        if (leadId) {
            // navigate.push(`/leadDetail/${leadId}/${singleEvent?.projectId}`);
            navigate.push(`/dashboard/customers/list`);
        } else {
            navigate.push('/myevents');
        }
    };

    const handleReportSubmit = (isCrmEvent: boolean = false) => {
        if (!isCheckedInOrHigher) {
            snackBarTools.createToast({ type: 'error', message: t('##Error') });
            return;
        }
        if (isCrmEvent) {
            setDisableReportButton(true);
            finalSubmit(true);
        } else if (singleEvent.eventState === 'LoggedIn') {
            setDisableReportButton(true);
            handleLogOut();
        } else if (singleEvent.eventState === 'LoggedOut' || singleEvent.eventState === 'SalesReportRejected') {
            setDisableReportButton(true);
            finalSubmit();
        } else {
            snackBarTools.createToast({ type: 'error', message: t('##Error') });
            return;
        }
    };

    const handleBackTrack = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        navigate.goBack();
    };

    const calculateCounter = (list: any[], section: string): number => {
        if (section === 'customReports') {
            const reports = list.filter(r => r.customReport?.type === 'standard');
            const customReportsFiledsCount = reports.reduce((total, obj) => {
                const reportAvailable = isReportAvailable(
                    obj?.customReport,
                    window?.userabstraction,
                    currentUserCanEditEventUser,
                    [], // TODO: dummy placeholder
                    singleEvent?.event?.point_of_sale?.locationGroups ?? []
                );
                if (reportAvailable && obj?.customReport?.customFields && Array.isArray(obj.customReport?.customFields)) {
                    return total + obj.customReport?.customFields.length;
                } else {
                    return total + 0;
                }
            }, 0);
            return customReportsFiledsCount;
        } else if (section === 'expenses') {
            const set = new Set();
            list.map(exp => {
                if (exp?.expense?.productId) {
                    set.add(exp?.expense?.productId);
                }
            });
            return [...set].length;
        } else if (section === 'transactions') {
            const transactions = salesItems.filter(
                ({ salesItem: s }: any) =>
                    !(
                        s._customReport &&
                        s._customReport._configcreatedTime &&
                        s._customReport._configcreatedTime.type === 'transactionFixedProduct'
                    ),
            );
            const set = new Set();
            transactions.map((txn: DexieSalesItem) => {
                if (txn?.salesItem?.productId) {
                    set.add(txn?.salesItem?.productId);
                }
            });
            return [...set].length;
        }
        return 0;
    };

    const showReportingStateAlertBox = value === 'Reporting' && innerSection === 'EventReport';
    const updateEventReportStates = (payload: { [key: string]: string }) => {
        setEventReportStates(s => ({ ...s, ...payload }));
    };

    React.useEffect(() => {
        // cause this causing unmounting of the page when scrolling after view port
        if (!leadId) {
            const scrollListener = () => {
                setPageScrollHeight && setPageScrollHeight(() => window.scrollY);
            };

            window.addEventListener('scroll', scrollListener);

            return () => {
                window.removeEventListener('scroll', scrollListener);
            };
        }
    }, []);

    React.useEffect(() => {
        if (!leadId) {
            if (!pinReportingAlertToTop && pageScrollHeight > 111) {
                setPinReportingAlertToTop && setPinReportingAlertToTop(true);
            }
            if (pinReportingAlertToTop && pageScrollHeight < 65) {
                setPinReportingAlertToTop && setPinReportingAlertToTop(false);
            }
        }
    }, [pageScrollHeight, pinReportingAlertToTop]);

    return (
        <PwaWrapper>
            {singleEvent?._id && project?._id ? (
                <>
                    <Box
                        key='eventReportingStatus'
                        hidden={!(showReportingStateAlertBox && pinReportingAlertToTop)}
                        sx={{ position: 'fixed', top: 65, width: '100%', borderRadius: 0, zIndex: 9 }}
                    >
                        {Object.values(eventReportStates).find(val => ['changed', 'submitting'].includes(val)) ? (
                            <Alert severity='error' icon={<SyncOutlined fontSize='inherit' />}>
                                {t`##EventReportingSaving`}
                            </Alert>
                        ) : (
                            <Alert severity='success' icon={<CheckCircleOutline fontSize='inherit' />}>
                                {t`##EventReportingReady`}
                            </Alert>
                        )}
                    </Box>

                    {!leadId && (
                        <>
                            <Box height='5px' sx={{ background: COLORS_MAP[singleEvent.eventState] }}></Box>
                            <Accordion
                                disableGutters
                                expanded={open}
                                onChange={(event, expanded) => setOpen(expanded)}
                                sx={theme => ({
                                    background: `linear-gradient(${
                                        COLORS_MAP[singleEvent.eventState]
                                    } -200%, #ffffff 100%);`,
                                })}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon color='secondary' />}
                                    aria-controls={singleEvent._id + 'content'}
                                    id={singleEvent._id}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Stack pl={1.5} direction='row' alignItems='center' gap={1}>
                                                <IconButton
                                                    color='secondary'
                                                    edge='start'
                                                    aria-label='back'
                                                    onClick={handleBackTrack}
                                                >
                                                    <ArrowBack />
                                                </IconButton>
                                                <Typography variant='h6'>{formatDate(singleEvent?.event?.date)}</Typography>
                                                <LabelChip>{singleEvent.eventState}</LabelChip>
                                            </Stack>
                                        </Grid>
                                        {!open && (
                                            <Grid item xs={12}>
                                                <Stack pl={1.5} direction='row' alignItems='center' gap={1}>
                                                    {eventSyncPendingCount !== -1 && (
                                                        <IconButton color='secondary' edge='start' aria-label=''>
                                                            <SyncIcon
                                                                sx={theme => ({
                                                                    color: !isEventSyncPending
                                                                        ? theme.palette.success.main
                                                                        : theme.palette.warning.main,
                                                                })}
                                                            />
                                                        </IconButton>
                                                    )}
                                                    <LocationView event={singleEvent?.event} noHyperlink shorten />
                                                </Stack>
                                            </Grid>
                                        )}
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {project && (
                                        <Grid container spacing={2}>
                                            {Array.isArray(singleEvent?.event.times) &&
                                                singleEvent?.event.times.length > 0 && (
                                                    <Grid item xs={12}>
                                                        <Button sx={{ ml: 1 }} startIcon={<AccessTimeIcon sx={{ mr: 2 }} />}>
                                                            <Box display='flex' flexWrap='wrap' gap={1} alignItems='center'>
                                                                {singleEvent?.event.times.map((times: any, idx: number) => {
                                                                    return (
                                                                        <React.Fragment key={times._id}>
                                                                            <Typography>
                                                                                {moment(times.start).format('HH:mm')} -{' '}
                                                                                {moment(times.stop).format('HH:mm')}
                                                                                {idx !==
                                                                                    singleEvent?.event?.times?.length -
                                                                                        1 && <>,&nbsp;</>}
                                                                            </Typography>
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </Box>
                                                        </Button>
                                                    </Grid>
                                                )}
                                            <Grid item container xs={12}>
                                                <Grid item xs={10} color='secondary'>
                                                    {singleEvent?.event?.area ? (
                                                        <Button
                                                            sx={{ color: 'black', ml: 1 }}
                                                            startIcon={<LocationOnOutlinedIcon sx={{ mr: 2 }} />}
                                                        >
                                                            <LocationView event={singleEvent?.event} />
                                                        </Button>
                                                    ) : (
                                                        <LocationDetailView
                                                            data={
                                                                singleEvent?.event?.point_of_sale
                                                                    ? singleEvent?.event?.point_of_sale
                                                                    : singleEvent.event.google
                                                                    ? singleEvent?.event?.google
                                                                    : {}
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                                {isOnline &&
                                                    window?.user?.roles?.includes?.('FieldmanagerPWA') &&
                                                    singleEvent?.event?.point_of_sale?._id && (
                                                        <Grid item xs={2}>
                                                            <IconButton
                                                                onClick={() =>
                                                                    navigate.push(
                                                                        `/${eventId}/${singleEvent.event.point_of_sale._id}/locationDetails`,
                                                                    )
                                                                }
                                                                color='secondary'
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    )}
                                            </Grid>
                                            {Array.isArray(customModelFields) && customModelFields.length > 0 && (
                                                <Grid item xs={12}>
                                                    <Box ml={7} display='flex' flexDirection='column' gap={2}>
                                                        {customModelFields.map((field: DexieCustomModelField) => {
                                                            return (
                                                                <React.Fragment key={field._id}>
                                                                    {singleEvent?.event?.point_of_sale?.genericFields &&
                                                                        field.customModelField?.fieldLabelName &&
                                                                        field.customModelField?.fieldAttributeName &&
                                                                        singleEvent?.event?.point_of_sale?.genericFields[
                                                                            field.customModelField?.fieldAttributeName
                                                                        ] && (
                                                                            <TextField
                                                                                label={
                                                                                    field.customModelField?.fieldLabelName
                                                                                }
                                                                                variant='standard'
                                                                                InputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                sx={{
                                                                                    whiteSpace: 'nowrap',
                                                                                }}
                                                                                value={
                                                                                    singleEvent?.event?.point_of_sale
                                                                                        ?.genericFields[
                                                                                        field.customModelField
                                                                                            .fieldAttributeName
                                                                                    ]
                                                                                }
                                                                            />
                                                                        )}
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </Box>
                                                </Grid>
                                            )}
                                            {singleEvent?.event?.customStatuses.length > 0 && (
                                                <Grid item xs={12}>
                                                    <Button
                                                        sx={{ color: 'black', ml: 1 }}
                                                        startIcon={<FlagIcon sx={{ mr: 2 }} />}
                                                    >
                                                        {t('##CustomStatuses')}
                                                    </Button>
                                                    <br />
                                                    <Box ml={7} display='flex' gap={1} flexWrap='wrap'>
                                                        {singleEvent?.event?.customStatuses.length > 0 ? (
                                                            singleEvent?.event?.customStatuses.map((option: any) => {
                                                                return (
                                                                    <React.Fragment key={option?._id}>
                                                                        {option.isPromoterVisible && (
                                                                            <LabelChip
                                                                                css={{
                                                                                    backgroundColor: option.color
                                                                                        ? option.color
                                                                                        : 'rgb(36,192,177)',
                                                                                }}
                                                                            >
                                                                                {option.statusLabel
                                                                                    ? option.type === 'EMPPLAN'
                                                                                        ? t('' + option.slangText)
                                                                                        : t('' + option.statusLabel)
                                                                                    : t('' + option.custom_status)}
                                                                            </LabelChip>
                                                                        )}
                                                                    </React.Fragment>
                                                                );
                                                            })
                                                        ) : (
                                                            <Typography>{t('##NoCustomStatuses')}</Typography>
                                                        )}
                                                    </Box>
                                                </Grid>
                                            )}
                                            {isOnline &&
                                                (locationContactLoading ? (
                                                    <Typography key={'event-location-contacts-list-loading'} ml={7}>
                                                        {t('##Loading')}
                                                    </Typography>
                                                ) : (
                                                    Object.keys(locationContact).length > 0 && (
                                                        <LocationContactComponent
                                                            key={'event-location-contacts-list'}
                                                            contactList={locationContact?.list}
                                                            eventId={singleEvent._id}
                                                            editMode={window?.user?.roles?.includes?.('FieldmanagerPWA')}
                                                        />
                                                    )
                                                ))}

                                            {isOnline &&
                                                !eventChatChannel &&
                                                project?.project?.supportAdmins?.length > 0 && (
                                                    <Grid item xs={12}>
                                                        <Button
                                                            sx={{ color: 'black', ml: 1 }}
                                                            startIcon={<ForumSharp sx={{ mr: 2 }} />}
                                                            onClick={() => {
                                                                findEventChatChannels(eventId)
                                                                    .then(jsonData => {
                                                                        if (jsonData === null) {
                                                                            throw new Error('failed to get chat channels');
                                                                        }
                                                                        if (Array.isArray(jsonData)) {
                                                                            if (jsonData[0]?._id) {
                                                                                setEventChatChannel(jsonData[0] as Channel);
                                                                                setValue('Messenger');
                                                                            } else {
                                                                                API.post('/chatchannel/eventsupport', {
                                                                                    name: `${
                                                                                        singleEvent?.event?.point_of_sale
                                                                                            ?.name ||
                                                                                        singleEvent?.event?.area?.name
                                                                                    } ${moment(
                                                                                        singleEvent?.event?.date,
                                                                                    ).format('DD.MM.YYYY')}`,
                                                                                    context: {
                                                                                        contextObject: 'event',
                                                                                        contextObjectId: eventId,
                                                                                    },
                                                                                    members: [
                                                                                        window.userabstraction?._id,
                                                                                        ...project.project.supportAdmins,
                                                                                    ],
                                                                                })
                                                                                    .then(res => res.json())
                                                                                    .then(jsonData => {
                                                                                        if (jsonData && jsonData._id) {
                                                                                            setEventChatChannel(jsonData);
                                                                                            setValue('Messenger');
                                                                                        }
                                                                                    });
                                                                            }
                                                                        }
                                                                    })
                                                                    .catch(console.error);
                                                            }}
                                                        >
                                                            {t`##ContactProjectAdmins`}
                                                        </Button>
                                                    </Grid>
                                                )}

                                            {!singleEvent?.event?.area &&
                                                isUserCanAddLocationContact(window?.user?.roles) && (
                                                    <Grid item xs={12}>
                                                        <Button
                                                            sx={{ color: 'black', ml: 1 }}
                                                            startIcon={<PersonAddIcon sx={{ mr: 2 }} />}
                                                            onClick={() => {
                                                                navigate.push(`/${eventId}/addLocationContact`);
                                                            }}
                                                        >
                                                            {t('##AddLocationContact')}
                                                        </Button>
                                                    </Grid>
                                                )}
                                            <Grid item xs={12}>
                                                <Button
                                                    sx={{ color: 'black', ml: 1 }}
                                                    startIcon={<BookmarkIcon sx={{ mr: 2 }} />}
                                                >
                                                    {project.name}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )}

                    {showReportingStateAlertBox &&
                        !pinReportingAlertToTop &&
                        (Object.values(eventReportStates).find(val => ['changed', 'submitting'].includes(val)) ? (
                            <Alert severity='error' icon={<SyncOutlined fontSize='inherit' />}>
                                {t`##EventReportingSaving`}
                            </Alert>
                        ) : (
                            <Alert severity='success' icon={<CheckCircleOutline fontSize='inherit' />}>
                                {t`##EventReportingReady`}
                            </Alert>
                        ))}

                    <Box display={value === 'Reporting' && innerSection === 'EventReport' ? undefined : 'none'}>
                        {Array.isArray(customReports) && Array.isArray(reportResults) && customReports.length > 0 && (
                            <Box p={1} mt={2}>
                                {customReports
                                    .filter(r => r.customReport?.type === 'standard')
                                    .map((report: DexieCustomReport, idx: number) => {
                                        return (
                                            isReportAvailable(
                                                report?.customReport,
                                                window?.userabstraction,
                                                currentUserCanEditEventUser,
                                                [], // TODO: dummy placeholder
                                                singleEvent?.event?.point_of_sale?.locationGroups ?? [],
                                            ) && (
                                                <Box key={report._id}>
                                                    <ReportConfigurationContext.Provider
                                                        value={{
                                                            isDisabled: isCustomReportDisabled(
                                                                'event',
                                                                singleEvent.eventState,
                                                                currentUserCanEditEventUser,
                                                            ),
                                                        }}
                                                    >
                                                        <CustomReportParserPWA
                                                            customReport={report?.customReport}
                                                            validationCallback={hasErrors =>
                                                                handleValidationResult(idx, hasErrors)
                                                            }
                                                            reportResults={
                                                                Array.isArray(reportResults) &&
                                                                reportResults.find(
                                                                    (crr: any) =>
                                                                        crr.customReportId === report?.customReport._id,
                                                                )
                                                            }
                                                            updateReportState={updateEventReportStates}
                                                            eventAndProject={{
                                                                eventId: eventId,
                                                                projectId: singleEvent?.projectId,
                                                            }}
                                                            productGroupsInfo={{
                                                                locationProductGroups:
                                                                    singleEvent?.event?.point_of_sale
                                                                        ?.associatedProductGroups,
                                                                eventGroupProductGroups:
                                                                    singleEvent?.event?.eventGroupId?.fieldManagerConfig
                                                                        ?.plannedProductGroups,
                                                                userProductGroups:
                                                                    // uses current user instead of event user for more recent data
                                                                    user?.userabstraction?.relations?.productGroups,
                                                            }}
                                                        />
                                                    </ReportConfigurationContext.Provider>
                                                </Box>
                                            )
                                        );
                                    })}
                            </Box>
                        )}
                    </Box>

                    {innerSection === 'TransactionReport' &&
                        transactionReportConfig?.customReport &&
                        project &&
                        !leadId &&
                        singleEvent?._id && (
                            <Box ref={transactionRef} p={1} mt={2}>
                                <TransactionReportParserPWA
                                    eventAndProject={{
                                        eventId: singleEvent._id,
                                        projectId: project,
                                        eventState: singleEvent.eventState,
                                        pointOfSale: singleEvent?.event?.point_of_sale,
                                    }}
                                    customReport={transactionReportConfig?.customReport}
                                    reference={transactionRef}
                                />
                            </Box>
                        )}

                    {innerSection === 'transactionReportCRM' && project && singleEvent?._id && leadId && (
                        <LeadDetailTransactions
                            eventAndProject={{
                                eventId: singleEvent._id,
                                projectId: singleEvent?.projectId,
                                eventState: singleEvent.eventState,
                                pointOfSale: singleEvent?.event?.point_of_sale,
                                leadId: leadId,
                            }}
                            customReport={transactionReportConfig?.customReport || []}
                            handleReportSubmit={handleReportSubmit}
                        />
                    )}

                    {transactionFixedProductReportConfig?.customReport && singleEvent?._id && (
                        <Box p={1} mt={2} display={innerSection === 'ProductReport' ? undefined : 'none'}>
                            <ProductReportParserPWA
                                customReportConfig={transactionFixedProductReportConfig?.customReport}
                                eventAndProject={{
                                    eventId: singleEvent._id,
                                    projectId: singleEvent?.projectId,
                                    eventState: singleEvent.eventState,
                                }}
                                validationCallback={count => {
                                    setFixedProductErrorCount(count);
                                }}
                            />
                        </Box>
                    )}

                    {innerSection === 'Expenses' && expenseReportConfig?.customReport && project && singleEvent?._id && (
                        <Box ref={expenseRef} p={1} mt={2}>
                            <ExpenseProductReportParserPWA
                                customReportConfig={expenseReportConfig?.customReport}
                                eventAndProject={{
                                    eventId: singleEvent._id,
                                    projectId: project,
                                    eventState: singleEvent.eventState,
                                }}
                                reference={expenseRef}
                            />
                        </Box>
                    )}

                    {value === 'Reporting' &&
                        innerSection === null &&
                        isCurrentUserTheEventUser &&
                        Array.isArray(customReports) && (
                            <>
                                <EventDetailLoginComponent eventId={singleEvent._id} />

                                {isCheckedInOrHigher && (
                                    <Box mt='10px'>
                                        {innerSectionsEventDetails.map(item => {
                                            return (
                                                <React.Fragment key={item}>
                                                    {isReportTypeVisible(
                                                        item,
                                                        item === 'EventReport'
                                                            ? customReports
                                                            : item === 'TransactionReport'
                                                            ? transactionReportConfig
                                                            : item === 'ProductReport'
                                                            ? transactionFixedProductReportConfig
                                                            : item === 'Expenses'
                                                            ? expenseReportConfig
                                                            : null,
                                                    ) ? (
                                                        <Accordion expanded={false}>
                                                            <AccordionSummary
                                                                expandIcon={
                                                                    <IconButton
                                                                        onClick={() =>
                                                                            navigate.push(`/event/${eventId}/` + item)
                                                                        }
                                                                        color='secondary'
                                                                    >
                                                                        <ArrowForwardIcon />
                                                                    </IconButton>
                                                                }
                                                                aria-controls={singleEvent._id + 'content'}
                                                            >
                                                                <Stack
                                                                    width='100%'
                                                                    direction='row'
                                                                    justifyContent='space-between'
                                                                    alignItems='center'
                                                                    spacing={2}
                                                                >
                                                                    <Button
                                                                        color='secondary'
                                                                        onClick={() =>
                                                                            navigate.push(`/event/${eventId}/` + item)
                                                                        }
                                                                    >
                                                                        {t('##' + item)}
                                                                    </Button>
                                                                    <Typography
                                                                        sx={{
                                                                            color: 'white',
                                                                            background:
                                                                                (item === 'EventReport' &&
                                                                                    disableReportButton) ||
                                                                                (item === 'ProductReport' &&
                                                                                    fixedProductErrorCount > 0)
                                                                                    ? '#E2685D'
                                                                                    : '#757575',
                                                                            px: 1,
                                                                            borderRadius: '3px',
                                                                        }}
                                                                        fontSize='small'
                                                                    >
                                                                        {item === 'EventReport' &&
                                                                            calculateCounter(
                                                                                customReports,
                                                                                'customReports',
                                                                            ) +
                                                                                ' ' +
                                                                                t('##Items')}
                                                                        {item === 'TransactionReport' &&
                                                                            Array.isArray(salesItems) &&
                                                                            calculateCounter(salesItems, 'transactions') +
                                                                                ' ' +
                                                                                t('##Reported')}
                                                                        {item === 'ProductReport' &&
                                                                            Array.isArray(loadedProductReports) &&
                                                                            loadedProductReports.length +
                                                                                ' ' +
                                                                                t('##Products')}
                                                                        {item === 'Expenses' &&
                                                                            Array.isArray(expenseItems) &&
                                                                            calculateCounter(expenseItems, 'expenses') +
                                                                                ' ' +
                                                                                t('##Reported')}
                                                                    </Typography>
                                                                </Stack>
                                                            </AccordionSummary>
                                                        </Accordion>
                                                    ) : null}
                                                </React.Fragment>
                                            );
                                        })}
                                    </Box>
                                )}

                                {isCheckedInOrHigher &&
                                    !['SalesReportUploaded', 'SalesReportAccepted', 'Accepted'].includes(
                                        singleEvent.eventState,
                                    ) && (
                                        <Box mt='20px' mx={2} display='flex' alignItems='center'>
                                            {mountSubmitButton ? (
                                                <Button
                                                    disabled={disableReportButton || fixedProductErrorCount > 0}
                                                    onClick={() => handleReportSubmit()}
                                                    variant='contained'
                                                    color='secondary'
                                                    sx={{
                                                        width: '100%',
                                                        height: '55px',
                                                        borderRadius: '50px',
                                                    }}
                                                >
                                                    {t('##ConfirmDailyReportWithoutaSignedForm')}
                                                </Button>
                                            ) : (
                                                <LoadingButton
                                                    variant='contained'
                                                    sx={{
                                                        width: '100%',
                                                        height: '55px',
                                                        borderRadius: '50px',
                                                    }}
                                                    loading
                                                >
                                                    <span>{t('##ConfirmDailyReportWithoutaSignedForm')}</span>
                                                </LoadingButton>
                                            )}
                                        </Box>
                                    )}

                                {singleEvent._id && (
                                    <Alert severity='info' sx={{ mt: 2, mb: 10, mx: 2 }}>
                                        {t('##YourEventId')}
                                        <Tooltip title='click to copy'>
                                            <strong
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(singleEvent._id!);
                                                    snackBarTools.createToast({ type: 'success', message: t('##Copied') });
                                                }}
                                            >
                                                {' '}
                                                {singleEvent._id}
                                            </strong>
                                        </Tooltip>
                                    </Alert>
                                )}
                            </>
                        )}

                    {singleEvent && value === 'Briefing' && (
                        <EventBriefing
                            eventBriefing={singleEvent?.event?.briefing}
                            projectBriefing={project?.project?.briefing}
                        />
                    )}

                    {singleEvent && value === 'Earnings' && (
                        <>
                            <EventEarnings eventId={singleEvent?._id} />
                            <TargetDefinitionsWrapper
                                type='event'
                                payload={singleEvent.event}
                                triggerUpdateCount={eventSyncPendingCount}
                            />
                        </>
                    )}

                    {singleEvent && value === 'Messenger' && eventChatChannel && (
                        <ReactAssociatedChat disableChannelNameLink channel={eventChatChannel} cb={() => {}} />
                    )}

                    {innerSection === null && (
                        <BottomFilters
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                            isEventSyncPending={isEventSyncPending}
                            eventChannel={eventChatChannel}
                        />
                    )}
                </>
            ) : (
                <Box
                    mt='30px'
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    minHeight={200}
                    bgcolor='#f8f8f8'
                    borderRadius={8}
                    boxShadow={2}
                    p={3}
                >
                    <Typography variant='h6' color='primary' align='center'>
                        Oops! Could not the load event
                    </Typography>
                    <Typography variant='body2' color='text.secondary' align='center' mt={2}>
                        Please refresh or check later.
                    </Typography>
                </Box>
            )}
        </PwaWrapper>
    );
}

export default EventDetailPage;
