import * as React from 'react';
import { useTranslation } from 'react-i18next';
import API from '../config/api';
import { getCurrentLocaleString } from '../config/translate';

interface Currency {
    label?: string;
    symbol: String;
    value?: 'Euro' | 'UsDollar' | 'SwissFranc';
}

export const apiLoadAgencySettings: () => Promise<{ currency: string }> = async () => {
    console.log('Loading currency from empplan settings');
    if (window.empplanSettings) {
        return window.empplanSettings;
    }
    return API.get('/settings/empplan/by/market')
        .then(res => res.json())
        .then(data => {
            if (data && data.currency) {
                window.empplanSettings = data;
                return data;
            }
            return data;
        })
        .catch(err => {
            console.error('Error loading empplan settings. Using Euro as default', err);
            return { currency: 'Euro' };
        });
};

export const getCurrencySymbol = (locale: string, currency: string) => {
    const options = {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    };
    return (0).toLocaleString(locale, options).replace(/\d/g, '').trim();
};

export default function useCurrency() {
    const { t } = useTranslation();

    const currencyList: Currency[] = [
        {
            label: t('##CurrencyEuro'),
            symbol: t('##CurrencySignEuro'),
            value: 'Euro',
        },
        {
            label: t('##CurrencyUsDollar'),
            symbol: t('##CurrencySignUsDollar'),
            value: 'UsDollar',
        },
        {
            label: t('##CurrencySwissFranc'),
            symbol: t('##CurrencySignSwissFranc'),
            value: 'SwissFranc',
        },
    ];

    const [currency, setCurrency] = React.useState<Currency>(currencyList[0]);

    const loadCurrency = async () => {
        let empplanSettings = window.empplanSettings;
        if (!(empplanSettings && empplanSettings.currency)) empplanSettings = await apiLoadAgencySettings();

        let OriginalLocale = getCurrentLocaleString();
        if (OriginalLocale === 'D-Raw') {
            OriginalLocale = 'de';
        }

        switch (empplanSettings.currency) {
            case 'UsDollar':
                setCurrency && setCurrency(currencyList[1]);
                break;
            case 'SwissFranc':
                setCurrency && setCurrency(currencyList[2]);
                break;
            case 'Euro':
                setCurrency && setCurrency(currencyList[0]);
                break;
            case 'euro':
                setCurrency && setCurrency(currencyList[0]);
                break;
            case 'us':
                setCurrency && setCurrency(currencyList[1]);
                break;
            case 'chf':
                setCurrency && setCurrency(currencyList[2]);
                break;
            default:
                const currency = getCurrencySymbol(OriginalLocale, empplanSettings.currency);
                setCurrency && setCurrency({ symbol: currency });
                break;
        }
    };

    React.useEffect(() => {
        loadCurrency();
    }, []);

    return [currency];
    // return [currency, currencyList];
}
