import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ReactGA from 'reactelements/node_modules/react-ga4';
import { hashremember } from './Services/HashStorage';
import AppRouter from './router';
import configureStore from './redux/Store';
import { Provider } from 'react-redux';
import DynTableConst from './Services/Constants';
import EmpplanSnackbarWrapper from 'reactelements/src/modules/Snackbar';
import { CssBaseline } from '@mui/material';
import { checkAndLoadPWAServiceWorker, initPWAServiceWorker } from './pwaServiceWorker';
import { AssociatedUser } from '../reactelements/src/utils/event';
import pwaVersion from './commit.json';

import { ErrorBoundary } from '@sentry/react';
import { ReportAllToGlitchService } from './Components/PWASettings';
import { initStoragePersistence } from './Components/storagePersistance';
import { CountryList } from 'reactelements/src/pages/MyEvents/components/types';

type ShareOptions = { title: string; text: string; url: string };

type NavigatorShare = (options: ShareOptions) => Promise<{}>;

declare global {
    interface Window {
        AppRouter?: any;
        Promise?: any;
        stream: MediaStream;
        reactGA?: typeof ReactGA;
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
        registrationServiceWorker?: ServiceWorkerRegistration;
        user: {
            roles: string[];
            [key: string]: any;
        };
        userabstraction: AssociatedUser;
        React1?: any;
        React1Dom?: any;
        Lib?: any;
        BASE_URL: string;
        env: 'development' | 'production';
        language_info_map?: {
            [key: string]: ILanguageInfo;
        };
        language_info_map_loaded: boolean;
        countries: CountryList[];
        f: any;
        Empsetting: any;
        empplanSettings?: any;
        isloggingOut?: boolean;
        routeBeforeSync?: string;
        agencySettings?: AgencySettings;
        pwaAppVersion?: any;
        socketio?: SocketIOClient.Socket;
        leadListScrollTimeStamp?: number;
    }
}

// To add to window
if (!window.Promise) {
    window.Promise = Promise;
}

window.reactGA = ReactGA;
if (!window.location.href.includes('localhost')) {
    window.reactGA.initialize('G-SCYGJE2BYH');
}

// initialize / add service worker!
initPWAServiceWorker();

// initialize persistant storage
initStoragePersistence();

//at first store the hash immediatly after the page starts rendering
var initHash = new hashremember().checkHash();

// window.addEventListener('error', (error: any) => {
//     console.error(error, ' ERROR HANDLER ');
//     if (window.reactGA) {
//         //window.reactGA
//     }
// });

window.BASE_URL = DynTableConst.EMPPLAN_HOST;
window.pwaAppVersion = pwaVersion;

export const AppWrapper = () => {
    const store = configureStore();

    React.useEffect(() => {
        const windowMessageHandler = (event: any) => {
            if (event.data && event.data.action) {
                switch (event.data.action) {
                    case 'calendarClick':
                        console.log('event open click', event.data);
                        if (event.data.eventId) window.location.href = `/app#/event/${event.data.eventId}`;
                        checkAndLoadPWAServiceWorker();
                        break;
                }
            }
        };
        window.addEventListener('message', windowMessageHandler);

        return () => {
            window.removeEventListener('message', windowMessageHandler);
        };
    }, []);

    return (
        <>
            <ErrorBoundary
                fallback={sentryErrorObject => (
                    <p>
                        <ReportAllToGlitchService
                            label='##PWACrashErrorText'
                            additionalObject={{
                                errorMessage: sentryErrorObject?.error?.message,
                                errorName: sentryErrorObject?.error?.name,
                                errorStack: sentryErrorObject?.error?.stack,
                                eventId: sentryErrorObject?.eventId,
                                componentStack: sentryErrorObject?.componentStack,
                            }}
                        />
                    </p>
                )}
            >
                <Provider store={store}>
                    <CssBaseline></CssBaseline>
                    <EmpplanSnackbarWrapper>
                        <AppRouter />
                    </EmpplanSnackbarWrapper>
                </Provider>
            </ErrorBoundary>
        </>
    );
};

ReactDOM.render(<AppWrapper />, document.getElementById('root'));
