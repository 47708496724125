import {Rating} from "@mui/material";
import * as React from "react";

interface RatingsViewProps {
  value: number
}
export function RatingsView({value}: RatingsViewProps) {
  return <Rating
    readOnly={true}
    value={value}
    />
}