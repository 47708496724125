export const HomeSVG = ({ dark, height }: { dark?: boolean; height?: number }) => {
    const backgroundColor = dark ? '#424242' : 'white';
    const calculatedHeight = height ?? 74;
    const calculatedWidth = (65 / 74) * calculatedHeight;

    return (
        <svg
            width={calculatedWidth}
            height={calculatedHeight}
            viewBox='0 0 65 74'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <circle cx='32.5' cy='32.5' r='32.5' fill={backgroundColor} />
            <path
                d='M60.384 39.089C61.317 35.338 61.421 31.539 60.811 27.899L55.048 33.71L60.384 39.089Z'
                fill='#00A4C0'
            />
            <path
                d='M12.092 35.575L5.711 42.01C6.106 43.09 6.57 44.162 7.108 45.221C8.359 47.679 9.903 50.031 11.586 52.219C14.696 56.266 18.209 59.922 21.831 63.475L30.795 54.437L12.092 35.575Z'
                fill='#00A4C0'
            />
            <path d='M4.375 27.792C3.765 31.495 3.888 35.354 4.839 39.157L10.243 33.709L4.375 27.792Z' fill='#00A4C0' />
            <path
                d='M34.495 54.436L43.282 63.297C45.53 61.054 47.764 58.798 49.965 56.508C54.035 52.273 57.458 47.527 59.509 41.939L53.198 35.575L34.495 54.436Z'
                fill='#1479BC'
            />
            <path
                d='M30.796 12.984L23.353 5.48C14.076 8.595 7.49899 16.027 5.05099 24.744L12.092 31.845L30.796 12.984Z'
                fill='#18B2AC'
            />
            <path
                d='M53.198 31.843L60.128 24.857C57.632 16.042 50.939 8.549 41.94 5.476L34.495 12.984L53.198 31.843Z'
                fill='#00A4C0'
            />
            <path
                d='M26.619 4.591C26.496 4.617 26.374 4.643 26.251 4.671L32.645 11.118L39.05 4.66C38.333 4.5 37.602 4.37 36.862 4.266C32.074 3.612 27.855 4.338 26.619 4.591Z'
                fill='#00A4C0'
            />
            <path d='M41.4945 65.1486L32.6451 56.2993L23.795 65.1493L32.6444 73.9987L41.4945 65.1486Z' fill='#18B2AC' />
            <path
                d='M51.116 33.709L32.413 14.849L13.709 33.709L22.133 42.204L28.181 36.107C27.78 35.399 27.547 34.583 27.547 33.71C27.547 31.022 29.724 28.844 32.412 28.844C35.1 28.844 37.278 31.022 37.278 33.71C37.278 36.398 35.1 38.577 32.412 38.577C31.552 38.577 30.745 38.35 30.044 37.958L23.983 44.069L32.412 52.57L51.116 33.709Z'
                fill='#00A4C0'
            />
        </svg>
    );
};
