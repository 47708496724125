import {Project, ProjectDocument} from "../ProjectInvitaions/ProjectInvitationTypes";

export enum ProjectTypes {
    LOADINGPROJECT = "Project/LOAD/BY/ID",
    LOADING = "Projects/Loading",
    LOAD_USERS_PROJECT_DOCUMENTS="Projects/Load/Users/Documents"
};




export interface Interface {

}

export interface ProjectNativeLoadingAction {
    type:ProjectTypes.LOADING,
    loading:boolean
}

export interface ProjectLoadByIdAction{
    type: ProjectTypes.LOADINGPROJECT ,
    project: Project|null
}

export interface loadProjectDocuments {
    type:ProjectTypes.LOAD_USERS_PROJECT_DOCUMENTS,
    projectDocuments:ProjectDocument[]|null
}


/**
 * NO ACTIONS NEEDED ONLY LOAD PROJECT
 */
export interface ProjectNativeStateContainer {
    readonly projects: Project[]| null; //the state of projects will be managed by the
    readonly lastLoaded: Project|null; //container to store the last loaded project
    readonly loading: boolean;
    readonly  projectDocuments: ProjectDocument[]|null;

}


export type ProjectNativeActions =
    | ProjectNativeLoadingAction
    | loadProjectDocuments
    | ProjectLoadByIdAction ;
