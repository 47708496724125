import { useCallback } from 'react';
export function debounce<T extends (...args: any[]) => any>(fn: T | undefined, wait: number | undefined) {
    let timeout: number;
    let arg: any[] | undefined;
    const cb = function (...args: any[]) {
        arg = args;
        window.clearTimeout(timeout);
        timeout = window.setTimeout(() => {
            fn?.(...args);
        }, wait);
    };
    const result: typeof cb & { cancel: () => void; flush: () => void } = cb as any;
    result.cancel = () => {
        window.clearTimeout(timeout);
        arg = undefined;
    };
    result.flush = () => {
        if (Array.isArray(arg)) {
            fn?.(...arg);
        }
        result.cancel();
    };
    return result;
}
export function useDebounce<T extends (...args: any[]) => any>(fn: T | undefined, deps: any[] = [], wait: number = 500) {
    const debounced = debounce(fn, wait);

    return useCallback((...args) => debounced(...args), [fn, wait, ...deps]);
}

export function stringToColor(string:string='') {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < (string?.length); i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}
export function copyToClipboard(text:string) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
}

export function findInIterable<T>(iterator:IterableIterator<T>|any[],predicate:(item:T) => boolean) {
    let result :T|undefined;
    for (const item of iterator) {
        if (predicate(item)) {
            result = item;
            break;
        }
    }
    return result;
}