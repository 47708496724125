import {FC} from "react";
import {DivCenteredContent} from "../styled";
import {stringToColor} from "../../utils";
import {Chip} from "@mui/material";
import {useTranslationSafe} from "../../config/translate";

export interface IEnumsViewProps {
  value: string;
}
export const EnumsView: FC<IEnumsViewProps> = ({ value }) => {
  const {t} = useTranslationSafe()
  return value ? <Chip
            label={t(value)}
            sx={{
              backgroundColor:  stringToColor(value)+'08'
            }}
          /> : null
}