
export const innerSectionsEventDetails = ['EventReport', 'TransactionReport', 'ProductReport', 'Expenses'];

export const isReportTypeVisible: (reportType: string, report: any) => boolean = (reportType: string, report: any) => {
    if (reportType === 'EventReport') {
        if (report && Array.isArray(report) && report.length > 0) {
            const stdReports = report.filter(r => r.customReport?.type === 'standard')
            if (stdReports.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else if (reportType === 'ProductReport' || reportType === 'Expenses' || reportType === 'TransactionReport') {
        if (report && report?.customReport) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const isUserCanAddLocationContact = (roles: string[]) => {
    return roles && roles.includes('AddLocationContact');
}