import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TransactionReport from 'reactelements/src/modules/TransactionReport';
import CustomerDataForm from 'reactelements/src/modules/CustomerDataForm';
import { clearForTransaction, loadForTransactionsAction } from '../../Models/NewProduct/actions';
import {
    clearProjectLocationForBrandsAction,
    loadProjectLocationForBrandsAction,
} from '../../Models/NewProjectLocations/actions';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import { loadSalesItemListAction, clearSalesItemListAction } from '../../Models/NewSalesItem/actions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import API from 'reactelements/src/config/api';
import { EmpplanSnackbarContext } from 'reactelements/src/modules/Snackbar';
// import { PWAContext } from 'reactelements/src/PWA/pwaContext';
import { clearCrmDataForEditing, loadCrmDataForEditing, loadCrmItemListAction, newCrmItemPutAction } from '../../Models/NewCRM/actions';
import axios from 'axios';
import { CRMData } from 'reactelements/src/modules/ViewEditCrm/types';
import { TransactionReportProps } from './TransactionReportParserPWA';
import { LeadHeader } from '../DashboardItems/LeadHeader';
import EventDetailTransactionList from './EventDetailTransactionList';
import { PWASyncControlContext } from '../../Services/pwaSyncControlContext';
import { ICustomStatuses } from '../../../reactelements/src/modules/SideBarContentForEvents/types';

interface IProps extends TransactionReportProps {
    handleReportSubmit: (isCrmEvent?: boolean) => void;
}

export const LeadDetailTransactions = ({
    eventAndProject: { eventId, projectId, eventState, leadId, pointOfSale },
    customReport,
    handleReportSubmit,
}: IProps) => {
    const dispatch = useDispatch<any>();
    const { t } = useTranslation();
    const { isHybridSync } = useContext(PWASyncControlContext);
    const snackBarTools = React.useContext(EmpplanSnackbarContext);
    const leadDetailRef = useRef<any>(null);

    const mainCRMData: CRMData = useSelector((store: any) => store?.crmItem?.crmItemForEditing);
    const dexieProducts = useSelector((store: any) => store?.product?.forTransaction);
    const brands = useSelector((store: any) => store?.projectLocation?.loadedBrandsOfProject);
    const salesItems: DexieSalesItem[] = useSelector((store: any) => store?.salesItem?.loadedSalesItems);

    const [expandedPanel, setExpandedPanel] = useState<null | string>('panel1');
    const [crmResetCount, setCrmResetCount] = useState<number>(0);
    const [updateSaleObject, setUpdateSaleObject] = useState(undefined);

    const currentUserCanEditEventUser =
        window.user?.roles?.includes('EventEdit') || window.user?.roles?.includes('currentUserCanEditEventUser');

    React.useEffect(() => {
        // if reloaded we need to fetch crm data online with leadId
        if (!mainCRMData) {
            dispatch(loadCrmDataForEditing(undefined, leadId));
        }
        return () => {
            // dispatch clear crmEdit items
            dispatch(clearCrmDataForEditing());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (projectId) {
            dispatch(loadProjectLocationForBrandsAction(projectId));
            dispatch(loadSalesItemListAction({ eventId: eventId }));
            loadCrmItemListAction(dispatch, { eventId: eventId });
        }

        return () => {
            dispatch(clearProjectLocationForBrandsAction());
        };
    }, [eventId, projectId]);

    useEffect(() => {
        if (Array.isArray(brands) && brands.length > 0) {
            dispatch(
                loadForTransactionsAction({
                    productType: 'PromotionProducts',
                    brandsArray: brands,
                }),
            );
        }

        return () => {
            dispatch(clearForTransaction());
        };
    }, [brands]);

    const updateCb = () => {
        clearSalesItemListAction(dispatch);
        dispatch(loadSalesItemListAction({ eventId }));
        setExpandedPanel('panel2');
    };

    const updateCrmDataToServer = async (data: CRMData, leadStatus?: ICustomStatuses) => {
        try {
            if (isHybridSync) {
                const response = await axios.put(`/crm/${leadId}`, data);
                if (response.data && response.data.success) {
                    snackBarTools.createToast({ type: 'success', message: t('##Success') });
                    dispatch(loadCrmDataForEditing(response.data.data));
                    setExpandedPanel(null);
                    handleReportSubmit();
                } else {
                    // show snackbar
                    snackBarTools.createToast({ type: 'error', message: t('##Failed') });
                }
            } else {
                if (leadId) {
                    newCrmItemPutAction({ ...data, _id: leadId, status: leadStatus ?? data.status })
                        .then(() => {
                            dispatch(loadCrmDataForEditing(undefined, leadId));
                            setExpandedPanel(null);
                            handleReportSubmit(true);
                        })
                        .catch(err => {
                            snackBarTools.createToast({ type: 'error', message: t('##Failed') });
                            console.error(err);
                        });
                } else {
                    throw new Error('LeadId not Found!!');
                }
            }
        } catch (error) {
            snackBarTools.createToast({ type: 'error', message: t('##Failed') });
            console.error(error);
        }
    };

    const handleChangeAccordion = (panel: any) => (event: any, isExpanded: any) => {
        setExpandedPanel(isExpanded ? panel : '');
    };

    const handleUpdateSaleObj = (obj: any) => {
        const newobj = { ...obj };
        if (newobj?._id && !(newobj._customReport?._configcreatedTime?.type === 'transactionFixedProduct')) {
            if (typeof newobj.productId === 'string') {
                const prod = allProducts.find(prod => prod._id === newobj.productId);
                newobj.productId = prod;
            }
        }
        setUpdateSaleObject(newobj);
        setExpandedPanel('panel1');
        leadDetailRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const allProducts = Array.isArray(dexieProducts) ? dexieProducts.map((each: DexieProduct) => each.product) : [];
    const transactionSalesitems = Array.isArray(salesItems)
        ? salesItems.filter(
              ({ salesItem: s }: any) =>
                  !(
                      s._customReport &&
                      s._customReport._configcreatedTime &&
                      s._customReport._configcreatedTime.type === 'transactionFixedProduct'
                  ),
          )
        : [];

    return mainCRMData ? (
        <React.Fragment key='edit-lead'>
            <LeadHeader crmData={mainCRMData} svgPin='txn' />
            <Grid container direction='column' sx={{ minHeight: '90vh', marginTop: '130px' }}>
                <Grid item xs={12}>
                    <Box key='edit-form' ref={leadDetailRef}>
                        <Accordion
                            expanded={expandedPanel === 'panel1'}
                            onChange={handleChangeAccordion('panel1')}
                            elevation={0}
                            sx={{
                                boxShadow: 'none',
                                border: 'none',
                                '&.MuiAccordion-root:before': {
                                    backgroundColor: 'white',
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon color='secondary' />}
                                aria-controls='panel1-content'
                                id='panel1-header'
                            >
                                <b>{t('##Products')}</b>
                            </AccordionSummary>
                            <AccordionDetails>
                                {allProducts.length > 0 ? (
                                    <>
                                        <TransactionReport
                                            key='transaction-report-crm'
                                            updateCallback={updateCb}
                                            eventState={eventState}
                                            event={{
                                                _id: eventId,
                                                project_id: projectId,
                                            }}
                                            isCrmOnlineEvent={isHybridSync}
                                            customReports={[customReport] as CustomReportConfig[]}
                                            currentUserCanEditEventUser={currentUserCanEditEventUser}
                                            pwaOfflineOptions={allProducts}
                                            updateSaleObject={updateSaleObject}
                                        />
                                        <EventDetailTransactionList
                                            key='event-transaction-list-lead'
                                            handleUpdateSaleObj={handleUpdateSaleObj}
                                            soldItems={transactionSalesitems}
                                            eventId={eventId}
                                            setReRenderList={updateCb}
                                            type='sales'
                                            products={allProducts}
                                        />
                                    </>
                                ) : (
                                    <Typography>{t('##NoProductsFound')}</Typography>
                                )}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expandedPanel === 'panel2'}
                            onChange={handleChangeAccordion('panel2')}
                            elevation={0}
                            sx={{
                                boxShadow: 'none',
                                border: 'none',
                                '&.MuiAccordion-root:before': {
                                    backgroundColor: 'white',
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon color='secondary' />}
                                aria-controls='panel2-content'
                                id='panel2-header'
                            >
                                <b>{t('##Client')}</b>
                            </AccordionSummary>
                            <AccordionDetails>
                                <CustomerDataForm
                                    key='customer-data-form-edit'
                                    customReports={[customReport] as CustomReportConfig[]}
                                    crmItems={[{ crmItem: mainCRMData, _id: mainCRMData?._id }]}
                                    expandedPanel={expandedPanel}
                                    setExpandedPanel={setExpandedPanel}
                                    setCrmData={updateCrmDataToServer}
                                    pointOfSale={pointOfSale}
                                    reRender={crmResetCount}
                                    isDirectCrm={false}
                                    googleData={false}
                                    crmAdded={false}
                                    projectId={projectId}
                                    forEditingCB={() => setExpandedPanel(null)}
                                    leadDetails={{
                                        forLeadDetails: true,
                                        disableButton: salesItems.length === 0,
                                    }}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    ) : null;
};
