import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { PWAContext } from '../../../reactelements/src/PWA/pwaContext';
import {
    checkInEventHandler,
    handleDateChangePWA,
    checkIsEventDate,
} from '../../../reactelements/src/pages/MyEvents/eventHelpers';
import { loadEventAction } from '../../Models/NewEvent/actions';
import { getLocationCoordinates } from '../../../reactelements/src/hooks/useFileUpload/fileHelper';
import moment from 'moment';
import LinearProgress from '@mui/material/LinearProgress';
import DeviceDetect from '../../../reactelements/src/hooks/deviceDetect';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import { EmpplanSnackbarContext } from '../../../reactelements/src/modules/Snackbar';

function EventDetailLoginComponent({ eventId }: any) {
    const event: DexieEvent = useSelector((store: any) => store?.event?.loadedEvent);
    const project: DexieProject = useSelector((store: any) => store?.project?.loadedProject);
    const pwaContext = useContext(PWAContext);
    const { t } = useTranslation();
    const snackbartools = useContext(EmpplanSnackbarContext);

    const dispatch: any = useDispatch();

    const [isEventDate, setIsEventDate] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [rejectedMessage, setRejectedMessage] = useState<string | null>(null);
    const includeArray = ['Accepted', 'LoggedIn', 'LoggedOut', 'SalesReportRejected'];

    useEffect(() => {
        // cause we need this on every eventChange
        const isEvent = checkIsEventDate(event.event);
        if (isEvent) {
            setIsEventDate(true);
        } else {
            setIsEventDate(false);
        }
        if (
            event.eventState === 'SalesReportRejected' &&
            Array.isArray(event?.event?.eventStateHistory) &&
            event?.event?.eventStateHistory.length > 0
        ) {
            const state = event?.event?.eventStateHistory.findLast(
                (history: any) => history.stateNew === 'SalesReportRejected',
            );
            if (state?.message && typeof state?.message === 'string' && state?.message?.length > 0) {
                setRejectedMessage(state.message);
            }
        }
    }, [event.event]);

    const handleCheckIn = () => {
        setLoading(true);
        const mainEvent = {
            ...event.event,
            project_id: project,
        };
        checkInEventHandler(mainEvent, pwaContext)
            .then((response: any) => {
                if (response.success) {
                    dispatch(loadEventAction(eventId));
                } else if (response.success === false) {
                    snackbartools.createToast({ type: 'error', message: response.message });
                }
            })
            .catch((error: any) => {
                snackbartools.createToast({ type: 'error', message: error.message || 'Error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleLogOut = async () => {
        setLoading(true);
        const mainEvent = {
            ...event.event,
            project_id: project._id,
            eventState: 'LoggedOut',
        };

        if (pwaContext?.pwaHelpers?.events?.update) {
            pwaContext?.pwaHelpers?.events?.update({
                _id: mainEvent._id,
                event: mainEvent,
                eventState: 'LoggedOut',
            });
        }

        if (pwaContext?.pwaHelpers?.eventActions?.add) {
            let currentLocation = { latitude: 0, longitude: 0 }
            try {
                currentLocation = await getLocationCoordinates();
            } catch (error) {
                console.error(error);
            }
            let { isMobile } = DeviceDetect();

            const payload = {
                device: navigator.userAgent,
                isMobile: isMobile,
                latitude: currentLocation.latitude,
                longitude: currentLocation.longitude,
                event: mainEvent,
            };

            pwaContext?.pwaHelpers?.eventActions?.add(
                {
                    _id: mainEvent._id,
                    type: 'CheckOut',
                },
                payload,
            );
        }
        dispatch(loadEventAction(eventId));
        setLoading(false);
    };

    const handleChangeDatePromoter = (isEventDate: boolean) => {
        if (isEventDate) {
            handleCheckIn();
        } else {
            setLoading(true);
            const anotherEvent = {
                ...event.event,
                project_id: project,
            };
            handleDateChangePWA(anotherEvent, moment().startOf('day'), pwaContext, true)
                .then((result: any) => {
                    if (result.success) {
                        const newEvent = { ...event.event, date: moment(), project_id: project };
                        checkInEventHandler(newEvent, pwaContext).then((result: any) => {
                            if (result.success) {
                                dispatch(loadEventAction(eventId));
                            } else {
                                snackbartools.createToast({ type: 'error', message: result?.message || '##ErrorCheckIn' });
                            }
                        });
                    } else {
                        snackbartools.createToast({ type: 'error', message: result.message || 'Error' });
                    }
                })
                .catch((error: any) => {
                    snackbartools.createToast({ type: 'error', message: error.message || 'Error' });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const user = event?.event?.agency_user[0]?.user_id?.firstName + ' ' + event?.event?.agency_user[0]?.user_id?.lastName;
    const canChangeDate = event?.event?.meta
        ? event?.event?.meta.promoterChangeDate
            ? event?.event?.meta.promoterChangeDate
            : false
        : false;

    const Wrapper = (props: React.PropsWithChildren<{}>) => {
        return (
            <>
                {loading && <LinearProgress />}
                <Paper
                    elevation={2}
                    sx={{
                        p: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '180px',
                        mb: '10px',
                        mt: '10px',
                    }}
                >
                    {props.children}
                </Paper>
            </>
        );
    };

    if (!includeArray.includes(event.eventState)) {
        return <></>;
    } else if (event.eventState === 'SalesReportRejected') {
        return (
            <Wrapper>
                <Box
                    textAlign='center'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    flexDirection='column'
                    gap={2}
                >
                    <Typography textAlign='center'>
                        <b>{user + ' '}</b>
                        {t('##YourSalesReportWasRejectedReviewItAndMakeNeededChangesAndUploadItAgain')}
                    </Typography>
                    {rejectedMessage && (
                        <Typography textAlign='center'>
                            <b>{t('##Remarks')}</b>:&nbsp;{rejectedMessage}
                        </Typography>
                    )}
                </Box>
            </Wrapper>
        );
    } else if (event.eventState === 'Accepted') {
        if (!isEventDate && canChangeDate) {
            return (
                <Wrapper>
                    <Box
                        textAlign='center'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                        gap={2}
                    >
                        <Typography>
                            <b>{user + ' '}</b>
                            {t('##PleaseLogInToYourEventOrAddSalesReport')}
                        </Typography>
                        <Button
                            disabled={loading}
                            onClick={() => handleChangeDatePromoter(isEventDate)}
                            variant='contained'
                            color='secondary'
                        >
                            {t('##LoginEventNow')}
                        </Button>
                    </Box>
                </Wrapper>
            );
        } else if (isEventDate) {
            return (
                <Wrapper>
                    <Box
                        textAlign='center'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                        gap={2}
                    >
                        <Typography>
                            <b>{user + ' '}</b>
                            {t('##PleaseLogInToYourEventOrAddSalesReport')}
                        </Typography>
                        <Button disabled={loading} onClick={handleCheckIn} variant='contained' color='secondary'>
                            {t('##LoginEvent')}
                        </Button>
                    </Box>
                </Wrapper>
            );
        } else {
            return (
                <Wrapper>
                    <Box textAlign='center' display='flex' justifyContent='center' alignItems='center'>
                        <Typography textAlign='center'>
                            <b>{user + ' '}</b>
                            {t('##ToLoginComeBackOnTheEventDate')}
                        </Typography>
                    </Box>
                </Wrapper>
            );
        }
    } else if (event.eventState === 'LoggedIn') {
        if (isEventDate) {
            return (
                <Wrapper>
                    <Box
                        textAlign='center'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                        gap={2}
                    >
                        <Typography textAlign='center'>
                            <b>{user + ' '}</b>
                            {t('##YouCanLogOutAndLoginAsManyTimesYouNeedOnOneEvent')}
                        </Typography>
                        <Button disabled={loading} onClick={handleLogOut} variant='contained' color='secondary'>
                            {t('##LogoutEvent')}
                        </Button>
                    </Box>
                </Wrapper>
            );
        } else {
            return <></>;
        }
    } else if (event.eventState === 'LoggedOut') {
        if (isEventDate) {
            return (
                <Wrapper>
                    <Box
                        textAlign='center'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                        gap={2}
                    >
                        <Typography>
                            <b>{user + ' '}</b>
                            {t('##PleaseLogInToYourEventOrAddSalesReport')}
                        </Typography>
                        <Button disabled={loading} onClick={handleCheckIn} variant='contained' color='secondary'>
                            {t('##LoginEvent')}
                        </Button>
                    </Box>
                </Wrapper>
            );
        } else {
            return <></>;
        }
    } else {
        return <></>;
    }
}

export default EventDetailLoginComponent;
