import React from 'react';

import Button from '@mui/material/Button';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import SyncIcon from '@mui/icons-material/Sync';

import { DetectOnline } from 'reactelements/src/hooks/detectOnline';

import { SyncDisabled, SyncProblemOutlined } from '@mui/icons-material';
import { PWASyncControlContext } from '../../Services/pwaSyncControlContext';

export const circularAnimationCss = {
    animation: 'spin 2s linear infinite',
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(360deg)',
        },
        '100%': {
            transform: 'rotate(0deg)',
        },
    },
};

function SyncButton() {
    const { isOnline } = DetectOnline();

    const { isSyncing, uploadSyncData, pendingSyncUploadsCount, startFullSync } = React.useContext(PWASyncControlContext);

    // so that users cannot double click the sync button
    const [clickCooldown, setClickCooldown] = React.useState(false);
    React.useEffect(() => {
        if (clickCooldown) {
            setTimeout(() => {
                setClickCooldown && setClickCooldown(false);
            }, 2000);
        }
    }, [clickCooldown]);

    if (!isOnline) return <Button disabled color='warning' endIcon={<SyncDisabled color='warning' />}></Button>;

    const getEndIconForAppBar = () => {
        if (uploadSyncData?.syncErrors?.length > 0) return <SyncProblemOutlined color='error' />;
        return isSyncing ? <SyncIcon sx={{ ...circularAnimationCss, color: '#ffffff' }} /> : <CloudSyncIcon />;
    };

    return (
        <Button
            disabled={isSyncing || clickCooldown}
            variant='text'
            color='inherit'
            endIcon={getEndIconForAppBar()}
            onClick={() => {
                setClickCooldown(true);
                startFullSync();
            }}
        >
            {!isSyncing ? pendingSyncUploadsCount || null : null}
        </Button>
    );
}

export default SyncButton;
