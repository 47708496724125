import {Reducer} from "redux";
import {ProjectNativeActions, ProjectNativeStateContainer, ProjectTypes} from "./ProjectsTypes";
import {Project} from "../ProjectInvitaions/ProjectInvitationTypes";

export const initialEventContainerStateProjectInvitation:ProjectNativeStateContainer = {
    loading:false,
    projects:null,
    lastLoaded:null,
    projectDocuments:null,
};

function sortInProjectIntoList(project:Project, state: ProjectNativeStateContainer):Project[]|null{
    let tempProject=state.projects;
    if(tempProject=== null) {
        tempProject=[];
    }
    let replaceIndex=null;
    tempProject.map((project:Project, index)=> {
        if(project._id === project._id) {
            replaceIndex=index;
        }
    });
    if(replaceIndex===null) {
        tempProject.push(project);
    } else {
        tempProject[replaceIndex] = project;
    }
    return tempProject;
}

export const ProjectReducer: Reducer<ProjectNativeStateContainer, ProjectNativeActions> = (
    state = initialEventContainerStateProjectInvitation,
    action
) => {

    switch (action.type) {
        case ProjectTypes.LOADING: {
            return {
                ...state,
                loading: action.loading
            };
        }
        case ProjectTypes.LOADINGPROJECT: {
            let replacedProject=null;
            if(action.project !== null) {
                replacedProject = sortInProjectIntoList(action.project, state);
            }
            return {
                ...state,
                projects: replacedProject,
                lastLoaded: action.project,
                loading:false
            };
        }
        case ProjectTypes.LOAD_USERS_PROJECT_DOCUMENTS: {
            let projectDocuments=null;
            if(action.projectDocuments!==null) {
                projectDocuments=action.projectDocuments;
            }
            return {
                ...state,
                projectDocuments:projectDocuments,
                loading:false
            };
        }

    }
    return state;
};
