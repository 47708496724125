// renders object as simple text rows
import {Paper, TableContainer, Table, TableCell, TableRow, TableHead, TableBody, Box} from '@mui/material';
import React, {FC, useRef, useState} from "react";
import {useTranslationSafe} from "../../config/translate";
interface SimpleObjectViewProps {
  value: Record<string, any>
}
export function SimpleObjectView(props: SimpleObjectViewProps) {

  const anchorEl = useRef<HTMLDivElement>(null);
  const [hover, setHover] = useState(false);
  const {t} = useTranslationSafe()
  const entries = Object.entries(props.value||{});
  if(!props.value) return null;

  return  <Box
    ref={anchorEl}
    sx={{
      // position: 'relative',
      overflow: 'hidden',
      height:'100%',
      width:'100%',
      display:'flex',
      flexDirection:'column',
      gap:'1px',
    }}>
    <Paper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      elevation={hover?5:0}
      sx={{
        // transform: `translateY(-${anchorEl.current?.offsetHeight}px)`,
        width: anchorEl.current?.offsetWidth+'px',
        height: entries.length*1.5+'em',
        minHeight: entries.length*1.5+'em',
        overflow: hover?'auto':'hidden',
        display:'flex',
        flexDirection:'column',
        zIndex: hover?1000:0,
        position: hover?'fixed':'unset',
        // outline: hover?'2px solid black':'none',
      }}
    >
      <TableContainer sx={{
        overflow: 'hidden',
        '&::-webkit-scrollbar': {
          width: '2px',
          backgroundColor: 'rgba(0, 0, 0, 0.12)'
        },
        '&::-webkit-scrollbar-thumb': {
          width: '6px',
          backgroundColor: 'gray',
        }}} component={'div'}>
        <Table  size={'small'}>
          <TableBody>
            {entries.map(([key, value], index)  => (
              <TableRow
                key={index}
                sx={{ maxWidth:'10px','&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component={'th' as any} sx={{padding:'0px 0px !important',backgroundColor:'#f5f5f5'}} align="center">
                  {key}
                </TableCell>
                <TableCell sx={{padding:'0px 0px 0px 4px !important', }} scope="row">
                  {t(value?.toString())}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  </Box>
}