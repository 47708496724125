import { User } from "../../../modules/UserSelect";
import { Channel } from "../types";

function YouTubeGetID(url: string) {
    let newUrl = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return (newUrl[2] !== undefined) ? newUrl[2].split(/[^0-9a-z_\-]/i)[0] : newUrl[0];
}

export const urlify = (text: string) => {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
        return url;
    });
}

export const detectSomeUrl = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return urlRegex.test(text);
}

export const isYoutubeLink = (message: string) => {
    if (message) {
        const vid = YouTubeGetID(message);
        if (vid === message) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
}

export const getYouTubeLink = (message: string) => {
    const vid = YouTubeGetID(message);
    return 'https://www.youtube.com/embed/' + vid;
};

export const returnFileType = (fileExtension: string) => {

    let fileTypeCategory = 'other';

    if (fileExtension.startsWith('image/')) {
        fileTypeCategory = 'image';
    } else if (fileExtension === 'application/pdf') {
        fileTypeCategory = 'pdf';
    } else if (fileExtension.startsWith('video/')) {
        fileTypeCategory = 'video';
    }

    return fileTypeCategory;
};

export const returnUserCanSeeMessage = (roles: string[]) => {
    return Array.isArray(roles) ? roles.includes('ChatMessagesView') : false;
};

export const returnUserCanSendMessage = (roles: string[], channel: Channel) => {

    if (channel && channel?.readOnly) {
        if (roles.includes('admin') || window?.userabstraction?._id?.toString() === channel.user?.toString()) {
            return true;
        } else {
            return false;
        }

    } else {
        return Array.isArray(roles) ? roles.includes('ChatMessagesAdd') : false;
    }

};

// will be on deleting the message
export const returnUserCanEditMessage = (roles: string[]) => {
    return Array.isArray(roles) ? roles.includes('ChatMessagesEdit') : false;
};

export const returnChatUserCanAddUser = (roles: string[]) => {
    return Array.isArray(roles) ? (roles.includes('ChatUsersAdd') || roles.includes('ChatUsersEdit')) : false;
};

export const returnChatUserCanViewUsers = (roles: string[]) => {
    return Array.isArray(roles) ? roles.includes('ChatUsersView') : false;
};

export const returnChatChannelAdd = (roles: string[]) => {
    return Array.isArray(roles) ? roles.includes('ChatChannelAdd') : false;
};

export const returnChatChannelEdit = (roles: string[]) => {
    return Array.isArray(roles) ? roles.includes('ChatChannelEdit') : false;
};

export const returnChatChannelMemberOf = (members: string[] | undefined, userId: string | undefined, roles?: string[]) => {
    if (roles?.includes('admin')) {
        return true;
    } else {
        return Array.isArray(members) && userId ? members.includes(userId) : false;
    }
}

export const returnUserCanCreateEvent = (roles: string[]) => {
    return Array.isArray(roles) ? roles.includes('EventAdd') : false;
}

export const returnUserCanCreateCRM = (roles: string[]) => {
    return Array.isArray(roles) ? roles.includes('CRMAdd') : false;
}