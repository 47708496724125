import Dexie, { Table } from 'dexie';

class CustomReportsDB extends Dexie {
    customReports!: Table<DexieCustomReport>;

    constructor() {
        super('customReportsDB');
        this.version(1).stores({
            customReports: `_id, customReport.type`,
        });
    }
}

export const newCustomReportDb = new CustomReportsDB();
