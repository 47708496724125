import { newProductReportDb } from './newProductReportDexie';
import { createNewProductReportWrapperForDexie, getProductReportsFetch, syncAllProductReports } from './helpers';
import { isDeviceOnline } from '../../Services/OnlineStatus';

const newProductReportActionTypes = {
    NEW_PRODUCTREPORT: 'NEW_PRODUCTREPORT',
    LOAD_PRODUCTREPORT_LIST: 'LOAD_PRODUCTREPORT_LIST',
    LOAD_PRODUCTREPORT: 'LOAD_PRODUCTREPORT',
    UPDATE_PRODUCTREPORT_SYNC_DATA: 'UPDATE_PRODUCTREPORT_SYNC_DATA',
};

export default newProductReportActionTypes;

export const createNewProductReportAction = (productReport: DexieProductReport) => {
    newProductReportDb.productReports.add(productReport);
    return {
        type: newProductReportActionTypes.NEW_PRODUCTREPORT,
        payload: productReport,
    };
};

export const loadProductReportAction = async (dispatch: any, productReportId: string | null) => {
    if (productReportId !== null && productReportId) {
        const productReports = await newProductReportDb.productReports.get(productReportId);
        return dispatch({
            type: newProductReportActionTypes.LOAD_PRODUCTREPORT,
            payload: productReports,
        });
    } else {
        return dispatch({
            type: newProductReportActionTypes.LOAD_PRODUCTREPORT,
            payload: undefined,
        });
    }
};

export const loadProductReportListAction = (filterOptions: { customReportId: string; projectId: string }) => async (
    dispatch: any,
) => {
    if (filterOptions?.customReportId && filterOptions?.projectId) {
        const productReports = await newProductReportDb.productReports
            .filter(
                cr =>
                    cr.productReport?.customReportId === filterOptions.customReportId &&
                    cr.productReport?.projectId === filterOptions.projectId,
            )
            .toArray();

        if (productReports.length === 0 && isDeviceOnline()) {
            const apiPRs = await getProductReportsFetch(
                { projectIds: filterOptions.projectId, customReportId: filterOptions.customReportId },
                0,
            );
            if (apiPRs.success && apiPRs.count && Array.isArray(apiPRs.data)) {
                return dispatch({
                    type: newProductReportActionTypes.LOAD_PRODUCTREPORT_LIST,
                    payload: apiPRs.data.map((pr: any) => createNewProductReportWrapperForDexie(pr)),
                });
            }
        }

        return dispatch({
            type: newProductReportActionTypes.LOAD_PRODUCTREPORT_LIST,
            payload: productReports,
        });
    } else {
        return dispatch({
            type: newProductReportActionTypes.LOAD_PRODUCTREPORT_LIST,
            payload: [],
        });
    }
};

export const clearProductReportListAction = (dispatch: any) => {
    return dispatch({
        type: newProductReportActionTypes.LOAD_PRODUCTREPORT_LIST,
        payload: [],
    });
};

export const startProductReportSyncAction = (projectIds: string) => (dispatch: any) => {
    dispatch({
        type: newProductReportActionTypes.UPDATE_PRODUCTREPORT_SYNC_DATA,
        payload: {
            state: 'DOWNLOADING',
            lastUpdate: new Date(),
        },
    });
    syncAllProductReports(dispatch, projectIds);
};
