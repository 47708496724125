import { CountryList } from 'reactelements/src/pages/MyEvents/components/types';

export enum UserLanguages {
    DE="de",
    EN="en",
    RU="ru",
    FR="fr",
    NL="nl"
}

export type UserAddress = {
    additionalAddressline: string,
    address: string,
    city: string,
    company?: string,
    country?: {}
    loc?: Number[]
    zipCode: string,
    _country?: CountryList | string;
    streetName?: string;
    streetNumber?: number;
    palceId?: string;
    streetAppendix?: string;
}

export interface LegalReturn {
    legalCaseOpen:boolean,
    dsgvo:boolean,
    contractId?:string,
    dsgvoAcceptRoute:string,
}

export type Userabstraction = {
        language:UserLanguages,
        team:String[],
        email:String,
        usertype:String,
        firstName:String,
        lastName:String,
        mobile:String,
        tel:String,
        fax:String,
        dateOfBirth:Date,
        userNumber:null|String,
        username:null|String,
        nationality:null|String,
        formOfAddress:null|String,
        _userrights:null|String,
        _user:{
            _id:string,
        },
        languageSkills:String[],
        mobilitySkills:String[],
        personalSkills:String[],
        last_seen:Date,
        usernameView:null|String,
        residentialAddress:UserAddress,
        id:string,
        _id:string

};

export type MeanUser = {
    name:String,
    firstName:String,
    lastName:String,
    email:String,
    roles:String[],
    username:String,
    last_login:Date|null,
};

export type User = {
    user: MeanUser,
    userabstraction: Userabstraction
}

export enum UserActionTypes  {
    LOGIN = "USER/LOGIN",
    LOGOUT = "USER/LOGOUT",
    LOADME = "USER/LOADME",
    LOADING = "USER/LOADING",
    LOADUSERBYID = "USER/LOADING/BYID",
    GETTODO = "USER/LOAD/TODO",
    GETUSERBYID= "USER/OTHERS/LOAD/ID",
    ADDUSERTOLOAD = "USER/ADDTO/LOAD",
};

export interface UserLoginAction {
    type: UserActionTypes.LOGIN,
    user: User
}

export interface UserLogoutAction{
    type: UserActionTypes.LOGOUT,
    user: null
}

export interface UserLoadingAction {
    type:UserActionTypes.LOADING,
    userLoading:boolean
}

export interface UserLoadMeAction {
    type: UserActionTypes.LOADME,
    user: User| null,
    legal: LegalReturn | null
}

export interface UserGetById {
    type: UserActionTypes.GETUSERBYID,
    user: Userabstraction
}

export interface AddUserIdToLoad {
    type:UserActionTypes.ADDUSERTOLOAD,
    userId:string
}

export type fuckingString ={
    id:string;
}

export interface UserStates {
    readonly loadUses: string[] | null;
    readonly userLoading: boolean;
    readonly user: User | null;
    readonly users:Userabstraction[] | null;
    readonly userIdsToLoad:string[] | null;
    readonly legal: LegalReturn | null;
}




export type UserActions =
    | UserLoginAction
    | UserLogoutAction
    | UserLoadMeAction
    | AddUserIdToLoad
    | UserGetById
    | UserLoadingAction
