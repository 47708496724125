import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearReportProductsListByIdsAction, loadReportProductsListByIdsAction } from '../../Models/NewProduct/actions';
import TransactionProductForm from 'reactelements/src/modules/TransactionFixedProductParser/TransactionProductForm';
import { SalesItem } from 'reactelements/src/utils/salesitem';
import { Product } from 'reactelements/src/utils/Product';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'reactelements/node_modules/react-i18next';

interface ProductReportParserPWAProps {
    customReportConfig: CustomReportConfig;
    eventAndProject: {
        eventId: string;
        projectId: string;
        eventState: string;
    };
    validationCallback: (count: number) => void;
}

function ProductReportParserPWA({ customReportConfig, eventAndProject, validationCallback }: ProductReportParserPWAProps) {
    const dispatch: any = useDispatch();
    const { t } = useTranslation();

    const loadedProductReports = useSelector((s: any) => s?.productReport?.loadedProductReports);
    const user = useSelector((store: any) => store?.User?.user);
    const productReports = Array.isArray(loadedProductReports)
        ? loadedProductReports.filter(pr => !!pr.productReport).map(pr => pr.productReport)
        : [];
    const loadedProducts = useSelector((s: any) => s?.product?.loadedProductsForReport);
    const loadedSales = useSelector((s: any) => s?.salesItem?.loadedSalesItems);
    const [accordionExpanded, setAccordionExpanded] = React.useState<string | boolean>(false);
    const [currentUserCanEditEventUser, setCurrentUserCanEditEventUser] = useState<boolean>(false);

    const isAccordionOpenOneAtATime = customReportConfig?.transactionConfig?.openOneAtATime !== false ? true : false;

    React.useEffect(() => {
        if (productReports.length > 0) {
            dispatch(loadReportProductsListByIdsAction({ ids: productReports?.map(pr => pr.productId) || null }));
        }
        return () => {
            dispatch(clearReportProductsListByIdsAction);
        };
    }, [loadedProductReports]);

    useEffect(() => {
        if (user && user?.user?.roles && Array.isArray(user?.user?.roles)) {
            const roles = user.user.roles;
            if (roles.includes('EventEdit') || roles.includes('currentUserCanEditEventUser')) {
                setCurrentUserCanEditEventUser(true);
            }
        }
    }, [user]);

    const [requiredProducts, setRequiredProducts] = React.useState<Array<string>>([]);

    useEffect(() => {
        validationCallback(requiredProducts.length);
    }, [requiredProducts]);

    const requiredTracker = {
        remove: (slug: string) => setRequiredProducts(s => s.filter(p => p !== slug)),
        add: (slug: string) => setRequiredProducts(s => [...s, slug]),
    };

    const handleChangeAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setAccordionExpanded(isExpanded ? panel : false);
    };

    const getSaleFromExistingSales: (productId: string) => SalesItem | undefined = productId => {
        const foundDexieSalesItem = loadedSales.find(
            ({ salesItem: s }: any) =>
                !!(
                    ((s.productId as Product)._id === productId || s.productId === productId) &&
                    s._customReport &&
                    s._customReport._configcreatedTime &&
                    s._customReport._configcreatedTime.type === 'transactionFixedProduct'
                ),
        );
        if (foundDexieSalesItem)
            return {
                ...foundDexieSalesItem.salesItem,
                productId: (foundDexieSalesItem.salesItem.productId as Product)._id
                    ? (foundDexieSalesItem.salesItem.productId as Product)._id
                    : foundDexieSalesItem.salesItem.productId,
                isNewDoc: foundDexieSalesItem.isNewDoc,
            } as SalesItem;
    };

    let prodReportsToRender = [];

    if (Array.isArray(loadedProducts) && Array.isArray(productReports)) {
        const productReportProducts = productReports.map(pr => pr.productId);
        prodReportsToRender = loadedProducts.filter(dexieProd => productReportProducts.includes(dexieProd._id));
    }

    return (
        <>
            {prodReportsToRender.length > 0 ? (
                productReports.map(productReport => {
                    const product =
                        Array.isArray(loadedProducts) &&
                        loadedProducts.find(dexieProd => dexieProd._id === productReport.productId);
                    return (
                        product && (
                            <TransactionProductForm
                                key={productReport._id}
                                mandatory={productReport.mandatory}
                                product={product.product}
                                customReportConfig={customReportConfig}
                                savedTransactionData={getSaleFromExistingSales(productReport.productId)}
                                savePayload={{
                                    eventId: eventAndProject.eventId,
                                    productId: productReport.productId,
                                }}
                                // updateCallback={updateCallback}
                                eventState={eventAndProject.eventState} // used to control if form is disabled, same as event report
                                currentUserCanEditEventUser={currentUserCanEditEventUser} // used to control if form is disabled, same as event report
                                requiredTracker={requiredTracker}
                                expandedAccordion={isAccordionOpenOneAtATime ? accordionExpanded : undefined}
                                handleChangeAccordion={isAccordionOpenOneAtATime ? handleChangeAccordion : undefined}
                            />
                        )
                    );
                })
            ) : (
                <Typography>{t('##NoProductsToReport')}</Typography>
            )}
        </>
    );
}

export default ProductReportParserPWA;
