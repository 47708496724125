import { newCustomReportDb } from './newCustomReportDexie';
import newCustomReportActionTypes, { createNewCustomReportAction } from './actions';
import DynTableConst from '../../Services/Constants';
import newSyncDataActionTypes from '../NewSync/actions';
import axios from 'axios';
import { setDbSyncedDown } from '../helpers';

const PaginationLimit = 20;

export const createNewCustomReportWrapperForDexie = (customReport: any) => ({
    _id: customReport._id,
    customReport: customReport,
    syncDate: new Date(),
});

export const syncAllCustomReports = async (dispatch: any, projectIds: string) => {
    try {
        // drop the projects in the database
        await newCustomReportDb.transaction('rw', newCustomReportDb.tables, async () => {
            await Promise.all(newCustomReportDb.tables.map(table => table.clear()));
        });

        // get the new projects and load them to the database
        let count = 1;
        for (let idx = 0; idx < count; idx += PaginationLimit) {
            const customReportsResponse = await getCustomReportsFetch(projectIds, idx);
            if (!customReportsResponse.success) throw new Error('Failed to sync custom reports');
            count = customReportsResponse?.count || 0;
            // console.log({ idx, projectsResponse, count });

            Array.isArray(customReportsResponse?.data) &&
                customReportsResponse.data.forEach((customReport: any) => {
                    customReport &&
                        customReport._id &&
                        dispatch(createNewCustomReportAction(createNewCustomReportWrapperForDexie(customReport)));
                });

            dispatch({
                type: newSyncDataActionTypes.ADD_SYNC_LOG,
                payload: { action: 'Sync newCustomReportDb', idx, count },
            });
        }

        dispatch({
            type: newCustomReportActionTypes.UPDATE_CUSTOMREPORT_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
        setDbSyncedDown('customReportsDB');
    } catch (error) {
        console.log(error);
        dispatch({
            type: newSyncDataActionTypes.ADD_SYNC_ERROR,
            payload: error || 'Error handled but no log available',
        });
        dispatch({
            type: newCustomReportActionTypes.UPDATE_CUSTOMREPORT_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
    }
};

export const getCustomReportsFetch = async (projectIds: string, skip: number): Promise<any> => {
    return axios
        .post(
            DynTableConst.EMPPLAN_HOST + `/customreportconfig/pwa/sync?limit=${PaginationLimit}&skip=${skip}`,
            { project_id: projectIds },
            {
                withCredentials: true, // for local dev
                // for local dev
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            },
        )
        .then(response => response.data)
        .catch(err => {
            console.error(err);
            return { foo: 'broken' };
        });
};
