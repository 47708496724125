import React from 'react';
import { useTranslation } from 'react-i18next';
import { UploadFile } from '@mui/icons-material';

interface UploadAreaProps {
    fieldId: string; // unique field identifier
    onFileDrop?: (files: Array<File>) => void;
    label?: string;
    multiple?: boolean;
    options?: {
        isReadonly?: boolean;
        isRequired?: boolean;
        enableCapture?: boolean;
        accept?: string;
    };
}

function UploadArea({ onFileDrop, fieldId, label, multiple, options }: UploadAreaProps) {
    const { t } = useTranslation();
    const [isDragActive, setIsDragActive] = React.useState(false);
    const [isHover, setIsHover] = React.useState(false);

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragActive(false);
        if (!options?.isReadonly && event.dataTransfer.files) {
            const files = Array.from(event.dataTransfer.files);
            onFileDrop && onFileDrop(files);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = Array.from(event.target.files);
            onFileDrop && onFileDrop(files);
        }
    };

    return (
        <div
            style={{
                width: '100%',
                border:
                    isDragActive || isHover
                        ? '2px dashed #2591ef'
                        : options?.isRequired && !options?.isReadonly
                        ? '2px solid red'
                        : '2px dashed #00000042',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '16px',
                cursor: options?.isReadonly ? 'not-allowed' : 'pointer',
                backgroundColor: isDragActive || isHover ? '#f0f8ff' : 'transparent',
            }}
            onDragOver={e => {
                e.preventDefault();
                if (!options?.isReadonly) setIsDragActive(true);
            }}
            onDragLeave={() => setIsDragActive(false)}
            onDrop={handleDrop}
            onMouseOver={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <div
                style={{
                    display: 'none',
                }}
            >
                <input
                    type='file'
                    multiple={multiple}
                    accept={options?.accept}
                    capture={options?.enableCapture ? 'environment' : undefined}
                    disabled={options?.isReadonly}
                    onChange={handleInputChange}
                    id={'file-upload-input' + fieldId}
                />
            </div>
            <label
                htmlFor={'file-upload-input' + fieldId}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}
            >
                <div
                    style={{
                        background: 'aliceblue',
                        borderRadius: '50%',
                        padding: '8px',
                        marginBottom: '8px',
                    }}
                >
                    <UploadFile fontSize='medium' sx={{ background: 'aliceblue', borderRadius: '50%', padding: '8px' }} />
                </div>
                <div style={{ color: 'black', marginBottom: '4px' }}>
                    {label} {options?.isRequired && <span style={{ color: 'red' }}>({t('##TheFieldIsRequired')})</span>}
                </div>
                <div style={{ color: 'gray' }}>
                    {isDragActive ? t('##DropFilesHere') : t('##ClickToUploadOrDragAndDrop')}
                </div>
            </label>
        </div>
    );
}

export default UploadArea;
