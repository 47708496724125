import { DataGridPro, GridColDef, GridRowIdGetter } from '@mui/x-data-grid-pro';
import * as React from 'react';
import { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { ContextProviders, ContextProviders2 } from './contexts';
import { IParams, ParamsContext } from './contexts/params';
import { PaginationContext } from './contexts/pagination';
import { LoaderContext } from './contexts/loader';
import { ConfigContext } from './contexts/config';
import { IDataHook } from './hooks/data';
import LoadingOverlay from '../../modules/xGridDashboard/components/LoadingOverlay';
import { IModelHook } from './hooks/model';
import { CustomNoRowsOverlay } from './slots/no-rows-loader';
import { SuspenseLoader } from '../suspense-loader';
import { useDefaultParams } from './utils/validate';
import { Resource, RestEndpoint } from '@rest-hooks/rest';
import { useController, useDLE } from 'rest-hooks';
import { useSearchQuery } from './utils';
import { ColumnsPanel } from './slots/ColumnsPanel';
import { IGenericFields, useGridBuilder } from './hooks/build';
import { useTranslation } from 'react-i18next';
import { makeBtoAOrId } from './utils/makeId';
import { ArrowForwardIos, Close, JoinRight, ModeEdit } from '@mui/icons-material';

function DataGridComponent({ useDataHook, useModelHook }: { useDataHook: IDataHook; useModelHook: IModelHook }) {
    const { rows, totalCount, getRowId, getRowClassName, getRowHeight } = useDataHook();
    const { colDefs } = useModelHook(rows);
    const { t } = useTranslation();
    const { gridProps, gridStyles, callbacks, rerender } = useContext(ParamsContext);
    const { setPage, setPageSize, pageSize, page, rowsPerPageOptions, pagination } = useContext(PaginationContext);
    const { loading } = useContext(LoaderContext);
    const {
        setColumnVisibilityModel,
        columnVisibilityModel,
        gridApiRef,
        onFilterModelChanged,
        onSortModelChanged,
        fallbackGridProps,
        configGridProps,
        configGridStyles,
    } = useContext(ConfigContext);



    return (
        <DataGridPro
            apiRef={gridApiRef}
            {...fallbackGridProps}
            {...gridProps}
            {...configGridProps}
            components={{
                LoadingOverlay,
                //ColumnsPanel: ColumnsPanel,
                NoResultsOverlay: CustomNoRowsOverlay,
                NoRowsOverlay: CustomNoRowsOverlay,
                DetailPanelCollapseIcon: Close,
                DetailPanelExpandIcon: ModeEdit,


                ...gridProps?.components,
            }}
            localeText={{
                columnMenuShowColumns: t('##DataGridColumnManagement'),
                columnMenuHideColumn: t('##DataGridHide'),
                pinToLeft: t('##DataGridPinToLeft'),
                pinToRight: t('##DataGridPinToRight'),
            }}
            sx={{ ...gridStyles, ...configGridStyles }}
            onRowClick={callbacks?.onRowClick}
            onCellClick={callbacks?.onCellClick}
            getRowId={getRowId}
            rows={rows}
            rowCount={totalCount || rows?.length}
            columns={colDefs}
            getRowClassName={getRowClassName}
            getRowHeight={getRowHeight}
            pagination={pagination}
            page={page}
            pageSize={pageSize}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            loading={loading}
            onFilterModelChange={onFilterModelChanged}
            onSortModelChange={onSortModelChanged}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            columnVisibilityModel={columnVisibilityModel}
            experimentalFeatures={{ newEditingApi: true } as any}
            disableSelectionOnClick
            keepNonExistentRowsSelected={true}
            editMode={'cell'}
            processRowUpdate={(newRow: any, oldRow: any) => {
                try {
                    const updatedRow = { ...newRow, isNew: false };
                    if (callbacks?.onFieldUpdate) {
                        if (Object.keys(newRow).find(k => k === 'isNew')) {
                            delete newRow.isNew;
                        }
                        callbacks?.onFieldUpdate(newRow, oldRow, gridApiRef);
                    }

                    return updatedRow;
                } catch (e) {
                    const updatedRow = { ...oldRow, isNew: false };
                    console.log(e);
                    return updatedRow;
                }
            }}
        />
    );
}
function DataGridComponent2<T extends object>({
    GridResource,
    genericFields,
}: {
    GridResource: IGridResource<T> | any;
    genericFields?: IGenericFields[];
}) {
    // const { colDefs, getRowClassName, getRowHeight } = useModelHook();
    const {
        gridProps,
        gridStyles,
        callbacks,
        data: { filters },
        buildConfig,
        rerender,
    } = useContext(ParamsContext);
    const { t } = useTranslation();
    const { setPage, setPageSize, pageSize, page, rowsPerPageOptions, pagination } = useContext(PaginationContext);
    const { setLoading } = useContext(LoaderContext);
    const { searchQuery } = useSearchQuery();
    const { data: dummyRows = [], loading: dummyLoading } = useDLE(GridResource.dummyList(), { count: pageSize });
    const {
        data: { count: totalCount },
    } = useDLE(GridResource.total(), searchQuery);

    const emptyArray = useMemo(() => [], []);
    const { data: rows = emptyArray, loading: dataLoading } = useDLE(GridResource.list(), searchQuery);
    const [gridData, setGridData] = useState<InstanceType<typeof GridResource>[]>([]);





    const gridColDefs = useGridBuilder(buildConfig, genericFields||[]);

    const columnPanel = function(){
        return <ColumnsPanel gridColDefs={gridColDefs}></ColumnsPanel>
    }

    //FIXME : refactor this, move it somewhere else
    //HELP: cannot understand
    const { invalidate } = useController();
    useEffect(() => {
        if (rerender) {
            invalidate(GridResource.list(), searchQuery).then(() => {});
            invalidate(GridResource.total(), searchQuery);
            gridApiRef.current.setSelectionModel([]);
        }
    }, [rerender]);

    useEffect(() => {
        // console.log({ rows, dataLoading });
        if (!dataLoading) {
            setGridData(rows);

            if(GridResource.rowMapper){
                GridResource.rowMapper(rows,filters).then((rowss:any)=> {

                    setGridData(rowss)
                })
            }
        } else if (dataLoading && !dummyLoading) {
            setGridData(dummyRows);
        }
        setLoading(dataLoading);
    }, [rows, dataLoading, dummyLoading]);
    const {
        setColumnVisibilityModel,
        columnVisibilityModel,
        gridApiRef,
        onFilterModelChanged,
        onSortModelChanged,
        fallbackGridProps,
        configGridProps,
        configGridStyles,
        sortModel,
    } = useContext(ConfigContext);
    return (
        <>

            <DataGridPro
                apiRef={gridApiRef}
                localeText={{
                    columnMenuShowColumns: t('##DataGridColumnManagement'),
                    columnMenuHideColumn: t('##DataGridHide'),
                    pinToLeft: t('##DataGridPinToLeft'),
                    pinToRight: t('##DataGridPinToRight'),
                }}
                {...fallbackGridProps}
                {...gridProps}
                {...configGridProps}
                components={{
                    LoadingOverlay,
                    ColumnsPanel:columnPanel,
                    NoResultsOverlay: CustomNoRowsOverlay,
                    NoRowsOverlay: CustomNoRowsOverlay,
                    DetailPanelCollapseIcon: Close,
                    DetailPanelExpandIcon: ArrowForwardIos,
                    
                    ...gridProps?.components,
                }}
                initialState={{
                    pinnedColumns: buildConfig?.pinnedColumns,
                }}
                sx={{ ...gridStyles, ...configGridStyles }}
                onRowClick={callbacks?.onRowClick}
                onCellClick={callbacks?.onCellClick}
                getRowId={
                    ((row: Resource | any) => {
                        return (row.pk && row.pk()) || row._id || row.id;
                    }) as GridRowIdGetter
                }
                rows={gridData}
                rowCount={totalCount}
                columns={gridColDefs as GridColDef[]}
                // getRowClassName={getRowClassName}
                // getRowHeight={getRowHeight}
                pagination={pagination}
                page={page}
                pageSize={pageSize}
                rowsPerPageOptions={rowsPerPageOptions}
                onPageChange={setPage}
                onPageSizeChange={setPageSize}
                loading={false}
                onFilterModelChange={onFilterModelChanged}
                onSortModelChange={onSortModelChanged}
                sortModel={sortModel}
                onColumnVisibilityModelChange={setColumnVisibilityModel}
                columnVisibilityModel={columnVisibilityModel}
                experimentalFeatures={{ newEditingApi: true, columnGrouping: true } as any}
                keepNonExistentRowsSelected={true}
                editMode={'cell'}
                disableSelectionOnClick
                filterMode='server'
                getRowClassName={params => `row--${params.row.active}`}

                processRowUpdate={async (newRow: any, oldRow: any) => {
                    try {
                        const updatedRow = { ...newRow, isNew: false };
                        if (callbacks?.onFieldUpdate) {
                            if (Object.keys(newRow).find(k => k === 'isNew')) {
                                delete newRow.isNew;
                            }

                            await callbacks?.onFieldUpdate(newRow, oldRow, gridApiRef);
                        }

                        return updatedRow;
                    } catch (e) {
                        const updatedRow = { ...oldRow, isNew: false };
                        // console.log(oldRow,"909090")
                        // console.log(e)
                        // return updatedRow;
                    }
                }}
            />
        </>
    );
}

/**
 *  This implementation use data from parameters using Params.rows
 *  filtering/sorting  is done on client side
 */
function DataGridComponentClientControlled({ data }: { data: any[] }) {
    const { gridProps, gridStyles, callbacks, buildConfig } = useContext(ParamsContext);
    const { setPage, setPageSize, pageSize, page, rowsPerPageOptions, pagination } = useContext(PaginationContext);
    const { setLoading } = useContext(LoaderContext);
    const { t } = useTranslation();
    // @ts-ignore
    const gridColDefs = useGridBuilder(buildConfig);
    const {
        setColumnVisibilityModel,
        columnVisibilityModel,
        gridApiRef,
        onFilterModelChanged,
        onSortModelChanged,
        fallbackGridProps,
        configGridProps,
        configGridStyles,
        sortModel,
    } = useContext(ConfigContext);

    useEffect(() => {
        setLoading(false);
    }, []);
    return (
        <DataGridPro
            apiRef={gridApiRef}
            localeText={{
                columnMenuShowColumns: t('##DataGridColumnManagement'),
                columnMenuHideColumn: t('##DataGridHide'),
                pinToLeft: t('##DataGridPinToLeft'),
                pinToRight: t('##DataGridPinToRight'),
            }}
            {...fallbackGridProps}
            filterMode={'client'}
            sortingMode={'client'}
            paginationMode={'client'}
            {...gridProps}
            {...configGridProps}
            components={{
                LoadingOverlay,
                ColumnsPanel,
                NoResultsOverlay: CustomNoRowsOverlay,
                NoRowsOverlay: CustomNoRowsOverlay,
                ...gridProps?.components,
            }}
            initialState={{
                pinnedColumns: buildConfig?.pinnedColumns,
                ...gridProps?.initialState,
            }}
            sx={{ ...gridStyles, ...configGridStyles }}
            onRowClick={callbacks?.onRowClick}
            onCellClick={callbacks?.onCellClick}
            getRowId={((row: any) => row._id || row.id || makeBtoAOrId(row)) as GridRowIdGetter}
            rows={data || []}
            rowCount={data?.length || 0}
            columns={gridColDefs as GridColDef[]}
            pagination={pagination}
            page={page}
            pageSize={pageSize}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            loading={false}
            onFilterModelChange={onFilterModelChanged}
            onSortModelChange={onSortModelChanged}
            sortModel={sortModel}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            columnVisibilityModel={columnVisibilityModel}
            keepNonExistentRowsSelected={true}
        />
    );
}

interface IDataGridProps {
    params: IParams;
    useDataHook: IDataHook;
    useModelHook: IModelHook;
}
interface IDataGridProps2<T extends object> {
    params: IParams;
    data: IGridResource<T> | T[];
    genericFields?: IGenericFields[];
}
/**
 * @description -  Give it required `useData` and `useColumnDefinitions` hooks.
 */
export function XDataGridPro(props: IDataGridProps) {
    return (
        <Suspense fallback={<SuspenseLoader />}>
            <ContextProviders params={useDefaultParams(props.params)}>
                <DataGridComponent useDataHook={props.useDataHook} useModelHook={props.useModelHook} />
            </ContextProviders>
        </Suspense>
    );
}
/**
 * @description -  Give it required `useData` and `useColumnDefinitions` hooks.
 */
export function XDataGridPro2<T extends object>(props: IDataGridProps2<T>) {
    const params = useDefaultParams(props.params);
    if (Array.isArray(props.data)) {
        return (
            <Suspense fallback={<SuspenseLoader />}>
                <ContextProviders2 params={params}>
                    <DataGridComponentClientControlled data={props.data} />
                </ContextProviders2>
            </Suspense>
        );
    } else {
        return (
            <Suspense fallback={<SuspenseLoader />}>
                <ContextProviders2 params={params} GridResource={props.data}>
                    <DataGridComponent2 GridResource={props.data} genericFields={props.genericFields} />
                </ContextProviders2>
            </Suspense>
        );
    }
}

export interface IListFetchParams extends Record<string, string> {
    skip: string;
    limit: string;
    [key: string]: string;
}
abstract class IGridResourceClass extends Resource {
    static total: () => RestEndpoint<any, { count: number }, any>;

    static dummyList: () => RestEndpoint<
        (this: RestEndpoint, params: { count: number; start?: number }) => Promise<any>,
        any[],
        any
    >;

    static rowMapper?:(rows:Array<any>,filter:any)=>Promise<any>

    abstract id: string | undefined;
    abstract pk: () => string | undefined;
}
export type IGridResource<T extends object> = { new (...args: any): T } & typeof IGridResourceClass;
