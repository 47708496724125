import * as React from 'react';
import { useEffect, useState } from 'react';

const isConnectionStable = () => {
    try {
        const deviceNavigator: any = window.navigator;
        if (deviceNavigator?.connection?.effectiveType) {
            if (['4g', '3g'].includes(deviceNavigator.connection.effectiveType)) {
                console.log('Current network connection:', deviceNavigator.connection.effectiveType);
                return true;
            }
            console.log('Bad network connection:', deviceNavigator.connection.effectiveType);
            return false;
        }
        // navigator connection stats `effectiveType` not supported
        return true;
    } catch (error) {
        // if any errors while checking
        return true;
    }
};

export const DetectOnline = () => {
    const [isOnline, setOnline] = useState<boolean>(window.navigator.onLine);

    useEffect(() => {
        const updateOnline = () => {
            setOnline(window.navigator.onLine);
        };

        window.addEventListener('offline', updateOnline);
        window.addEventListener('online', updateOnline);

        return () => {
            window.removeEventListener('offline', updateOnline);
            window.removeEventListener('online', updateOnline);
        };
    }, []);

    return { isOnline, isConnectionStable };
};
