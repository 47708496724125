import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { useForm, SubmitHandler } from 'reactelements/node_modules/react-hook-form';
import EmpplanTextField from '../../../reactelements/src/modules/EmpplanFormComponents/EmpplanTextField';
import { t } from 'reactelements/node_modules/i18next';
import { useHistory, useParams } from 'react-router-dom';
import AddLocationContactHeader from './AddLocationContactHeader';
import API from '../../../reactelements/src/config/api';
import { useSelector, useDispatch } from 'react-redux';
import { clearEditLocationContactDetails, loadEventAction } from '../../Models/NewEvent/actions';
import { EmpplanSnackbarContext } from '../../../reactelements/src/modules/Snackbar';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

interface FormInputs {
    firstName: string;
    lastName: string;
    email: string;
    mobile: number;
}

function AddLocationContactForm() {
    const singleEvent: DexieEvent = useSelector((store: any) => store?.event?.loadedEvent);
    const contact = useSelector((store: any) => store?.event?.editLocationContact);
    const navigate = useHistory();
    const dispatch = useDispatch<any>();
    const { eventId, mode } = useParams<{ eventId: string; mode?: string }>();
    const snackBarTools = useContext(EmpplanSnackbarContext);

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        reset,
    } = useForm<any>({
        defaultValues: {},
    });

    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    useEffect(() => {
        dispatch(loadEventAction(eventId));
        return () => {
            dispatch(clearEditLocationContactDetails());
        };
    }, []);

    useEffect(() => {
        if (mode === 'edit' && !contact) {
            navigate.push(`/event/${eventId}`);
        }
        if (mode === 'edit' && contact) {
            reset({
                firstName: contact.firstName,
                lastName: contact.lastName,
                email: contact.email,
                mobile: contact.mobile,
            });
        }
    }, [contact]);

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
    };

    const checkEmailExists = async (email: string) => {
        try {
            if (mode === 'edit' && email === contact.email) {
                return true;
            }
            const encodedMail = btoa(email);
            const emailAvailability = await API.get(`/userManagement/emailAvailable/${encodedMail}`);
            const result: { available: boolean } = await emailAvailability.json();
            return result?.available;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const onSubmit: SubmitHandler<FormInputs> = async data => {
        try {
            setLoadingSave(true);
            const isEmailValid = validateEmail(data.email);
            const isEmailExists = await checkEmailExists(data.email);
            const pos = singleEvent?.event?.point_of_sale;

            if (!isEmailValid) {
                setError('email', {
                    type: 'manual',
                    message: t('##InvalidEmail'),
                });
            } else if (!isEmailExists) {
                setError('email', {
                    type: 'manual',
                    message: t('##EmailExists'),
                });
            } else {
                if (pos) {
                    let userAbsPayload;
                    if (mode === 'edit') {
                        userAbsPayload = {
                            ...contact,
                            ...data,
                        };
                    } else {
                        userAbsPayload = {
                            email: data.email,
                            usertype: 'locationcontact',
                            right: null,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            mobile: data?.mobile || null,
                            tel: null,
                            fax: null,
                            dateOfBirth: null,
                            userNumber: null,
                            username: null,
                            residentialAddress: {
                                country: {},
                            },
                            billingAddress: {},
                            bankAccount: {
                                bic: null,
                                iban: null,
                            },
                            qualifications: [],
                            personalSkills: [],
                            body: {
                                modifications: [],
                                height: null,
                                weight: null,
                                shoeSize: null,
                                confectionSizeOuterClothing: null,
                                confectionSizeCasualWear: null,
                                confectionSizePants: null,
                            },
                            event: {
                                preferences: [],
                                favouredTime: [],
                                favouredLocation: null,
                                favouredLocationDistance: '',
                            },
                            mobilitySkills: [],
                            languageSkills: [],
                            business: {
                                options: [],
                            },
                            language: 'en',
                            nationality: null,
                            officialIds: {
                                taxId: null,
                                VATID: null,
                                socialSecurityId: null,
                                passportId: null,
                            },
                            payment: {
                                fixum: {
                                    amount: 0,
                                    per: 'event',
                                    perHourHours: 0,
                                },
                                provision: 'no',
                                boost: {
                                    amount: 0,
                                    per: 'day',
                                    apply: 'always',
                                },
                                projectExpenses: {
                                    amount: 0,
                                    per: 'day',
                                },
                                eventExpenses: {
                                    amount: 0,
                                    per: 'day',
                                },
                                personalExpenses: {
                                    amount: 0,
                                    per: 'day',
                                },
                            },
                            locations: [pos],
                            projects: [],
                            relations: {
                                brands: [],
                                productGroups: [],
                            },
                            genericFields: {},
                            // _userrights: "" this will be the no-login user right after update script
                        };
                    }
                    const userRightResponse = await API.post(`/userManagement/addUser`, userAbsPayload);
                    const userRightResult = await userRightResponse.json();
                    if (userRightResult && userRightResult.success === true) {
                        snackBarTools.createToast({ type: 'success', message: t('##LocationContactAdded') });
                    } else {
                        snackBarTools.createToast({ type: 'error', message: t('##FailedToAddLocationContact') });
                    }
                    navigate.goBack();
                } else {
                    snackBarTools.createToast({ type: 'error', message: t('##FailedToAddLocationContact') });
                    console.log('No Location Found!!');
                }
            }
        } catch (error) {
            snackBarTools.createToast({ type: 'error', message: t('##FailedToAddLocationContact') });
            console.log(error);
        } finally {
            setLoadingSave(false);
        }
    };

    return (
        <Box>
            {/* conditionally render this header if need to use form only in some other place */}
            <AddLocationContactHeader mode={mode} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container justifyContent={'start'} spacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        <EmpplanTextField
                            name='firstName'
                            label={t('##FirstName')}
                            control={control}
                            rules={{
                                required: `${t('##Required')}`,
                            }}
                            helperText={`${t('##Required')}`}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <EmpplanTextField
                            name='lastName'
                            label={t('##LastName')}
                            control={control}
                            rules={{
                                required: `${t('##Required')}`,
                            }}
                            helperText={`${t('##Required')}`}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <EmpplanTextField
                            name='email'
                            label={t('##Email')}
                            control={control}
                            type='email'
                            rules={{
                                required: `${t('##Required')}`,
                            }}
                            helperText={`${t('##Required')}`}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <EmpplanTextField name='mobile' label={t('##Mobile')} control={control} type='number' />
                    </Grid>

                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        {loadingSave ? (
                            <LoadingButton
                                loading
                                loadingPosition='start'
                                startIcon={<SaveIcon />}
                                variant='outlined'
                                fullWidth
                            >
                                {mode === 'edit' ? t('##EditLocationContact') : t('##AddLocationContact')}
                            </LoadingButton>
                        ) : (
                            <Button type='submit' variant='contained' color='secondary' fullWidth disabled={loadingSave}>
                                {mode === 'edit' ? t('##EditLocationContact') : t('##AddLocationContact')}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}

export default AddLocationContactForm;
