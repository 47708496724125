import React from 'react';
import {
    Box,
    Fab,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Paper,
    Select,
    Tooltip,
} from '@mui/material';
import { Delete, Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import FieldConditionComponent from './FieldConditionComponent';
import ProductConditionComponent from './ProductConditionComponent';
import { generateRandomKey } from './keygen';

export function GroupConditionComponent({
    currentValue,
    onChange: setParentData,
    ftype,
    feildValue
}: {
    currentValue: GroupCondition;
    onChange: (newValue: GroupCondition) => void;
    ftype?: string
    feildValue?: string
}) {
    const { t } = useTranslation();
    const [addmenuOpen, setAddMenuOpen] = React.useState<null | HTMLElement>(null);
    const addNewCondition = (type: FieldVisibilityConditionType) => {
        const conditions = [...currentValue.conditions];
        switch (type) {
            case 'field':
                conditions.push({
                    type: 'field',
                    rKey: generateRandomKey(),
                    ftype:ftype,
                    feildValue:feildValue,
                    refField: '',
                    condition: {
                        value: '',
                        operator: '=',
                    },
                });
                break;

            case 'group':
                conditions.push({
                    type: 'group',
                    ftype:ftype,
                    rKey: generateRandomKey(),
                    operator: (typeof ftype !== 'undefined' && ftype === 'widget') ? '$and' : 'AND',
                    conditions: [],
                });
                break;

            case 'product':
                conditions.push({
                    type: 'product',
                    rKey: generateRandomKey(),
                    condition: {
                        operator: '=',
                        product: null,
                    },
                });
                break;

            default:
                console.log('Lol what? this should not reach here');
                break;
        }
        setParentData({ ...currentValue, conditions });
    };

    const generateUpdateFieldConditionHandler =
        (index: number) => (newValue: FieldCondition | GroupCondition | ProductCondition) => {
            const conditions = [...currentValue.conditions];
            conditions[index] = newValue;
            setParentData({ ...currentValue, conditions });
        };

    const generateDeleteFieldConditionHandler = (key: string) => () => {
        const conditions = currentValue.conditions.filter(c => c.rKey !== key);
        setParentData({ ...currentValue, conditions });
    };

    return (
        <Paper elevation={12} sx={{ padding: 2 }}>
            <Grid container>
                <Grid item alignSelf='center' marginRight={2}>
                    <Fab
                        color='secondary'
                        size='small'
                        aria-label='add'
                        onClick={event => {
                            setAddMenuOpen(event.currentTarget);
                        }}
                    >
                        <Add />
                    </Fab>
                    <Menu
                        id='add-condition-menu'
                        aria-labelledby='add-condition-button'
                        anchorEl={addmenuOpen}
                        open={!!addmenuOpen}
                        onClose={() => {
                            setAddMenuOpen(null);
                        }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem
                            key='field'
                            onClick={() => {
                                addNewCondition('field');
                                setAddMenuOpen(null);
                            }}
                        >
                            {t('##Field')}
                        </MenuItem>
                        <MenuItem
                            key='group'
                            onClick={() => {
                                addNewCondition('group');
                                setAddMenuOpen(null);
                            }}
                        >
                            {t('##Group')}
                        </MenuItem>
                        {(typeof ftype === 'undefined' || ftype !== 'widget' ) && <MenuItem
                            key='product'
                            onClick={() => {
                                addNewCondition('product');
                                setAddMenuOpen(null);
                            }}
                        >
                            {t('##Product')}
                        </MenuItem>}
                    </Menu>
                </Grid>

                <Grid item>
                    <FormControl fullWidth>
                        <InputLabel id='demo-simple-select-label'>{t('##GroupOperator')}</InputLabel>
                        <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={(typeof ftype !== 'undefined' && ftype === 'widget') ?   currentValue.operator === 'AND' ? '$and' :  currentValue.operator   :  currentValue.operator }
                            disabled={currentValue.conditions.length < 2}
                            label='Operator'
                            sx={{ width: '150px' }}
                            onChange={e => {
                                const obj = { ...currentValue };
                                obj.operator = e.target.value;
                                setParentData(obj);
                            }}
                        >
                            <MenuItem key='and' value={(typeof ftype !== 'undefined' && ftype === 'widget') ? '$and' : 'AND'}>
                                {t('##And')}
                            </MenuItem>
                            <MenuItem key='or' value={(typeof ftype !== 'undefined' && ftype === 'widget') ? '$or': 'OR'}>
                                {t('##Or')}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Box paddingLeft={4}>
                {currentValue.conditions.map((fieldCondition, index) => (
                    <Grid container>
                        <Grid item xs={11}>
                            {fieldCondition.type === 'group' && (
                                <GroupConditionComponent
                                    key={fieldCondition.rKey}
                                    currentValue={fieldCondition}
                                    ftype={ftype}
                                    onChange={generateUpdateFieldConditionHandler(index)}
                                />
                            )}
                            {fieldCondition.type === 'field' && (
                                <FieldConditionComponent
                                    key={fieldCondition.rKey}
                                    currentValue={fieldCondition}
                                    ftype={ftype }
                                    feildValue={feildValue}
                                    onChange={generateUpdateFieldConditionHandler(index)}
                                />
                            )}
                            {fieldCondition.type === 'product' && (typeof ftype === 'undefined' || ftype !== 'widget' ) && (
                                <ProductConditionComponent
                                    key={fieldCondition.rKey}
                                    currentValue={fieldCondition}
                                    onChange={generateUpdateFieldConditionHandler(index)}
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={1}
                            textAlign='center'
                            alignSelf={fieldCondition.type === 'field' ? 'center' : undefined}
                        >
                            <Tooltip title={fieldCondition.type === 'group' ? t(`##DeleteGroup`) : t(`##DeleteField`)}>
                                <IconButton onClick={generateDeleteFieldConditionHandler(fieldCondition.rKey)}>
                                    <Delete color='error' fontSize='large' />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                ))}
            </Box>
        </Paper>
    );
}
