import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Box,
    IconButton,
    TextField,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Fab,
    InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import API from '../../../config/api';
import { returnUserCanSendMessage, returnFileType } from '../utils/helpers';
import { PWAContext } from '../../../PWA/pwaContext';
import { FileStructure } from '../pages/Channels';
import { Channel } from '../types';
import { LoadingButton } from '@mui/lab';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { UploadRenderView } from '../../../modules/CustomReport/UploadRenderView';
import SendIcon from '@mui/icons-material/Send';
import DeviceDetect from '../../../hooks/deviceDetect';

interface ChatSectionPros {
    channel: Channel;
    setReloadMessages: any;
    shortChat?: boolean;
}

function ChatSection({ channel, setReloadMessages, shortChat = false }: ChatSectionPros) {
    const { t } = useTranslation();
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const Fabstyle = {
        // Adding media query..
        '@media (max-width: 500px)': {
            height: '35px',
        },
    };

    const [textMessage, setTextMessage] = useState<string>('');
    const [uploadStatus, setUploadStatus] = useState<'ready' | 'uploading' | 'error'>('ready');
    const [fileData, setFileData] = useState<FileStructure | null>(null);
    const [fileStringValue, setFileStringValue] = useState('');
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [uploadingFileToServer, setUploadingFilesToServer] = useState<boolean>(false);
    const pwaContext = useContext(PWAContext);
    const [isMobile, setIsMobile] = useState(DeviceDetect().isMobile);
    useEffect(() => {
        if (DeviceDetect().isMobile === false) {
            const handleResize = () => {
                if (window.innerWidth < 900) {
                    setIsMobile(true);
                } else {
                    setIsMobile(false);
                }
            };
            handleResize();
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    });

    const sendMessage = (
        e:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.KeyboardEvent<HTMLButtonElement>
            | null
            | React.FormEvent<HTMLFormElement>
            | React.KeyboardEvent<HTMLDivElement>,
        message: string,
        type: 'mail' | 'text' | 'file',
    ) => {
        e?.preventDefault();
        const trimmedMessage = message.trim();
        if (trimmedMessage.length === 0) {
            return;
        }
        const payload = {
            message: trimmedMessage,
            type: [type],
            channel: channel?._id,
            members: channel?.members,
            readBy: window?.userabstraction?._id ? [window?.userabstraction?._id] : [],
        };

        API.post('/chatmessages', payload)
            .then(res => {
                return res.json();
            })
            .then(data => {
                setTextMessage('');
                setReloadMessages(data);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const createOwnFieldModel = (fileInfo: File | null) => {
        if (fileInfo) {
            return {
                name: fileInfo.name,
                type: fileInfo.type,
                lastModifiedDate: fileInfo.lastModified || Date.now(),
                size: fileInfo.size,
            };
        }
    };

    const removeScroll = () => {
        document.body.style.height = '100vh';
    };
    const addScroll = () => {
        document.body.style.height = '100%';
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files ? event?.target?.files[0] : null;
        if (file) {
            setUploadStatus('uploading');
            const reader = new FileReader();
            reader.onload = loadEvent => {
                const base64Data = loadEvent.target?.result;
                const fileName = createOwnFieldModel(file);
                setFileData({
                    src: base64Data,
                    fileName: fileName,
                });
                setPreviewOpen(true);
            };
            reader.readAsDataURL(file);
        }
    };
    const sendFileToServer = (
        base64Data: string | ArrayBuffer | null | undefined,
        fileName: { name: string; type: string; lastModifiedDate: number; size: number } | undefined,
    ) => {
        setUploadingFilesToServer(true);
        const remotePath = '/chatmessages/attachment/upload';
        const postData = {
            base64: base64Data,
            name: fileName,
        };
        API.post(remotePath, postData)
            .then(result => {
                return result.json();
            })
            .then(data => {
                return sendMessage(null, data.filename, 'file');
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setUploadingFilesToServer(false);
                setPreviewOpen(false);
                setUploadStatus('ready');
                setFileData(null);
            });
    };

    const handleAddClick = () => {
        // Trigger the hidden file input element
        fileInputRef?.current?.click();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage(e, textMessage, 'text');
        }
    };

    return (
        <>
            {returnUserCanSendMessage(window?.user?.roles, channel) && (
                <Box
                    width='100%'
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    bottom={!isMobile ? '64px' : pwaContext?.pwa ? 0 : 44}
                    py={pwaContext?.pwa ? 1 : 2}
                    gap={0.5}
                    sx={{
                        backgroundColor: 'white',
                        paddingTop: '25px',
                        paddingBottom: '25px',
                        position: isMobile ? (shortChat ? '' : 'fixed') : 'sticky',
                        boxShadow: '0px 2px 16px 1px rgba(0,0,0,0.12)',
                    }}
                >
                    <Box>
                        <Box display='none'>
                            <input
                                type='file'
                                id='fileInput'
                                value={fileStringValue}
                                accept='*/*'
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                                onClick={() => {
                                    setFileStringValue('');
                                }}
                                ref={fileInputRef}
                            />
                        </Box>
                    </Box>
                    <form
                        style={{
                            display: 'flex',
                            width: '100%',
                            padding: '0 15px',
                            gap: '10px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        onSubmit={e => sendMessage(e, textMessage, 'text')}
                    >
                        <TextField
                            onChange={e => setTextMessage(e.target.value)}
                            onFocus={removeScroll}
                            onBlur={addScroll}
                            value={textMessage}
                            fullWidth
                            multiline
                            maxRows={10}
                            onKeyDown={handleKeyDown}
                            size='small'
                            placeholder={t('##TypeYourMessagesHere')}
                            sx={{
                                position: 'relative',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'whitesmoke',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'whitesmoke',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'whitesmoke',
                                    },
                                },
                            }}
                            InputProps={{
                                style: {
                                    borderRadius: 20,
                                    backgroundColor: 'white',
                                },
                                endAdornment: (
                                    <InputAdornment position='end' sx={{ position: 'absolute', bottom: 18, right: 0 }}>
                                        {uploadStatus === 'uploading' ? (
                                            <CircularProgress size={25} />
                                        ) : (
                                            <IconButton component='span' onClick={handleAddClick}>
                                                <AttachFileIcon color='secondary' />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Fab
                            type='submit'
                            disabled={uploadStatus === 'uploading' || textMessage.trim().length === 0}
                            onClick={e => sendMessage(e, textMessage, 'text')}
                            size='small'
                            color='secondary'
                            sx={Fabstyle}
                        >
                            <SendIcon fontSize='small' />
                        </Fab>
                    </form>
                </Box>
            )}
            {fileData && (
                <Dialog open={previewOpen} fullWidth>
                    <DialogTitle>{t('##FilePreview')}</DialogTitle>
                    <DialogContent>
                        {fileData && fileData.fileName && fileData.src && (
                            <Box display='flex' justifyContent='center' alignItems='center' height={200}>
                                <UploadRenderView
                                    type={returnFileType(fileData.fileName.type)}
                                    fileSource={fileData.src as string}
                                    responseData={{
                                        ...fileData.fileName,
                                        originalName: fileData.fileName.name,
                                    }}
                                />
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setPreviewOpen(false);
                                setUploadStatus('ready');
                                setFileData(null);
                            }}
                        >
                            {t('##Close')}
                        </Button>
                        {uploadingFileToServer ? (
                            <LoadingButton
                                loading
                                loadingPosition='start'
                                startIcon={<SendIcon />}
                                variant='text'
                                color='error'
                                size='small'
                            >
                                <span>{t('##Send')}</span>
                            </LoadingButton>
                        ) : (
                            <Button onClick={() => sendFileToServer(fileData.src, fileData.fileName)}>
                                {t('##Send')}
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

export default ChatSection;
