import { newCRMDb } from './newCRMItemDexie';
import newCrmItemActionTypes, { createNewCrmItemAction } from './actions';
import DynTableConst from '../../Services/Constants';
import newSyncDataActionTypes from '../NewSync/actions';
import axios from 'axios';
import { DexieCRMItem } from './types';
import { CRMData } from 'reactelements/src/modules/ViewEditCrm/types'
import API from '../../../reactelements/src/config/api';
import { setDbSyncedDown } from '../helpers';

const PaginationLimit = 20;

export const createNewCrmItemWrapperForDexie = (crmItem: any) =>
    ({
        _id: crmItem._id,
        crmItem: crmItem,
        syncDate: new Date(),
        event: crmItem.eventId[0],
        syncPending: 0,
        isNewDoc: 0,
        lon: crmItem?.address?.loc?.[0] ?? 0,
        lat: crmItem?.address?.loc?.[1] ?? 0,
    } as DexieCRMItem);

export const syncAllCrmItems = async (dispatch: any, projectIds?: string[]) => {
    try {
        // drop the projects in the database
        await newCRMDb.transaction('rw', newCRMDb.tables, async () => {
            await Promise.all(newCRMDb.tables.map(table => table.clear()));
        });

        // get the new crm and load them to the database
        let count = 1;
        for (let idx = 0; idx < count; idx += PaginationLimit) {
            const crmItemsResponse = await getCrmItemsFetch({ projectIds, userId: window.userabstraction._id }, idx);
            if (!crmItemsResponse.success) throw new Error('Failed to sync crm items');
            count = crmItemsResponse?.count || 0;
            // console.log({ idx, crmItemsResponse, count });

            Array.isArray(crmItemsResponse?.data) &&
                crmItemsResponse.data.forEach((crmItem: any) => {
                    crmItem && crmItem._id && dispatch(createNewCrmItemAction(createNewCrmItemWrapperForDexie(crmItem)));
                    // extract the leadStatus
                });

            dispatch({
                type: newSyncDataActionTypes.ADD_SYNC_LOG,
                payload: { action: 'Sync newCRMDb', idx, count },
            });
        }

        dispatch({
            type: newCrmItemActionTypes.UPDATE_CRMITEM_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
        setDbSyncedDown('crmDB');
    } catch (error) {
        console.log(error);
        dispatch({
            type: newSyncDataActionTypes.ADD_SYNC_ERROR,
            payload: error || 'Error handled but no log available',
        });
        dispatch({
            type: newCrmItemActionTypes.UPDATE_CRMITEM_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
    }
};

export const callCRMProjectRelatedEndpointsForServiceWorkerSync = async (projectIds: string[]) => {
    console.log('call endpoints for service worker sync', projectIds);

    await API.get('/crmCustomLeadStatusList').catch(err => console.error('Error caching custom lead status for CRM: ', err));

    for (let index = 0; index < projectIds.length; index++) {
        // const projectId = projectIds[index];
        // // await API.get(`/projects/customstatus/filter?&skip=0&project_id=${projectId}&limit=500`).catch(err => {
        // //     console.error('Error caching custom status for CRM:', projectId, err);
        // // });
    }
};

export const loadLocationsFromCrm = async (searchTerm: string) => {
    try {
        const crmItems = await newCRMDb.crmItems.toArray();

        let filteredResults: CRMData[] = [];

        // Multi-field fuzzy search
        const searchTerms = searchTerm.toLowerCase().split(" ");

        const allItems = crmItems.map(item => item.crmItem);
        // Consider pagination or cursor for better performance

        filteredResults = allItems.filter(item => {
            return searchTerms.every(term => {
                const regex = new RegExp(".*" + term.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") + ".*", "i");
                return (
                    regex.test(item.address.company || "") ||
                    regex.test(item.address.city || "") ||
                    regex.test(item.address.zipCode || "") ||
                    (!isNaN(Number(term)) && item.address.streetNumber === parseInt(term)) ||
                    regex.test(item.address.streetName || "") ||
                    regex.test(item.address.streetAppendix || "") ||
                    regex.test(item.address.additionalAddressline || "")
                );
            })
        });

        return filteredResults;
    } catch (error) {
        console.error("Error loading locations from CRM:", error);
        return [];
    }
}

export const extractUniqueObjects = (arr: CRMData[]) => {
    try {
        const statusMap = new Map();

        for (const item of arr) {
            if (item?.status && item.status?._id && !statusMap.has(item.status._id)) {
                statusMap.set(item.status._id, item.status);
            }
        };

        return Array.from(statusMap.values());
    } catch (error) {
        return [];
    }
};

export const loadLeadStatusFromCrm = async () => {
    try {
        // update this functions
        const crmItems = await newCRMDb.crmItems.toArray();
        const filteredResults: CRMData[] = crmItems.map(item => item.crmItem);
        const result = extractUniqueObjects(filteredResults);
        return result;
    } catch (error) {
        console.error("Error loading leadLists from CRM:", error);
        return [];
    }
};

export const getCrmItemsFetch = async (
    payload:
        | {
              eventIds?: string[];
              projectIds?: string[];
              userId?: string;
          }
        | undefined,
    skip: number,
): Promise<any> => {
    // console.log('getCrmItemsFetch', payload);
    const requestPayload = payload || {};
    return axios
        .post(DynTableConst.EMPPLAN_HOST + `/crm/pwa/sync?limit=${PaginationLimit}&skip=${skip}`, requestPayload, {
            withCredentials: true, // for local dev
            // for local dev
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
        })
        .then(response => response.data)
        .catch(err => {
            console.error(err);
            return { foo: 'broken' };
        });
};

export const deleteCrmItemsFetch = async (crmItemId: string): Promise<any> => {
    return axios
        .delete(DynTableConst.EMPPLAN_HOST + `/crm/${crmItemId}`, {
            withCredentials: true, // for local dev
            // for local dev
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
        })
        .then(response => response.data)
        .catch(err => {
            console.error(err);
            return { foo: 'broken' };
        });
};

export const checkOfflineUniqueness = async (uniqueCheckObj: any) => {
    try {
        const uniqueCheckKeys = Object.keys(uniqueCheckObj);

        if (uniqueCheckKeys.length > 0) {
            const matchedItems = await newCRMDb.crmItems
                .filter(dexieCrm =>
                    uniqueCheckKeys.every(chkKey => {
                        const c1 = String((dexieCrm.crmItem as Record<string, any>)[chkKey]);
                        const c2 = String(uniqueCheckObj[chkKey]);
                        return c1 === c2;
                    }),
                )
                .toArray();

            if (matchedItems.length > 0) {
                return false;
            }
        }

        return true;
    } catch (error) {
        console.error('checkOfflineUniqueness', error);

        return true;
    }
};
