import API from 'reactelements/src/config/api';

export const emergencyClearAllIndexedDbs = async () => {
    window.isloggingOut = true;
    for await (const db of pwaIndexedDbNames) {
        while (true) {
            try {
                await deleteIdbDatabaseForSure(db.name);
                break;
            } catch (error) {
                console.error('trying delete again', db.name);
            }
        }
        console.log('deleted db', db.name);
    }
};

export const createSyncHistoryEntry = async (action: string, optionalPayload?: Record<string, any>) => {
    try {
        let reqPayload: any = {
            action,
            userabstraction: window.userabstraction?._id,
            actionHash: {},
        };

        if (optionalPayload) reqPayload.actionHash = { ...optionalPayload };

        reqPayload.actionHash.deviceTime = new Date().toISOString();
        reqPayload.actionHash.version = window.pwaAppVersion?.version;

        API.post('/pwa/synchistory', reqPayload).catch(console.error);
    } catch (error) {
        console.error(error);
    }
};

const deleteIdbDatabaseForSure = (name: string) => {
    return new Promise((res, rej) => {
        const deleteRequest = indexedDB.deleteDatabase(name);

        deleteRequest.onsuccess = event => {
            console.log(`Database '${name}' deleted successfully.`);
            res(name);
        };

        // Handle errors (essential)
        deleteRequest.onerror = event => {
            console.error(`Error deleting database '${name}'`);
            rej();
        };
    });
};

const pwaIndexedDbNames = [
    {
        name: 'eventsDB', // we de;ete events first so we lost all relations
    },
    {
        name: 'ProjectLocationsDB',
    },
    {
        name: 'customModelFieldsDB',
    },
    {
        name: 'customReportResultsDB',
    },
    {
        name: 'customReportsDB',
    },
    {
        name: 'emplHash',
    },
    {
        name: 'emplLogin',
    },
    {
        name: 'eventActionsDB',
    },
    {
        name: 'expensesDB',
    },
    {
        name: 'fileSystemsDB',
    },
    {
        name: 'productReportsDB',
    },
    {
        name: 'productsDB',
    },
    {
        name: 'projectsDB',
    },
    {
        name: 'salesItemsDB',
    },
    {
        name: 'crmDB',
    },
    {
        name: 'syncDataDB',
    },
    {
        name: 'crmActionsDB'
    },
];
