import React from 'react';
import { SvgIcon } from '@mui/material';

function EmpplanIcon({ width, height, dark }: { height?: number; width?: number; dark?: boolean }) {
    if (dark) {
        return (
            <SvgIcon>
                <svg
                    width={width || '24'}
                    height={height || '24'}
                    viewBox='0 0 23 28'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <g clip-path='url(#clip0_507_2439)'>
                        <path
                            d='M22.3592 14.0356C22.7291 12.5352 22.7704 11.0156 22.5285 9.55957L20.2432 11.884L22.3592 14.0356Z'
                            fill='#9E9F9E'
                        />
                        <path
                            d='M3.20899 12.63L0.678589 15.204C0.835227 15.636 1.01923 16.0648 1.23257 16.4884C1.72866 17.4716 2.34093 18.4124 3.00833 19.2876C4.24161 20.9064 5.63469 22.3688 7.071 23.79L10.6257 20.1748L3.20899 12.63Z'
                            fill='#B4B5B4'
                        />
                        <path
                            d='M0.148735 9.51685C-0.0931611 10.998 -0.0443852 12.5416 0.332735 14.0628L2.4757 11.8836L0.148735 9.51685Z'
                            fill='#9E9F9E'
                        />
                        <path
                            d='M12.0929 20.1744L15.5774 23.7188C16.4688 22.8216 17.3547 21.9192 18.2276 21.0032C19.8415 19.3092 21.1989 17.4108 22.0122 15.1756L19.5096 12.63L12.0929 20.1744Z'
                            fill='#777776'
                        />
                        <path
                            d='M10.626 3.59364L7.67444 0.592041C3.99563 1.83804 1.38751 4.81084 0.416748 8.29764L3.20887 11.138L10.626 3.59364Z'
                            fill='#868686'
                        />
                        <path
                            d='M19.5096 11.1373L22.2577 8.34285C21.2679 4.81685 18.6138 1.81965 15.0452 0.590454L12.0929 3.59365L19.5096 11.1373Z'
                            fill='#B4B5B4'
                        />
                        <path
                            d='M8.96966 0.236431C8.92089 0.246831 8.87251 0.257231 8.82373 0.268431L11.3593 2.84723L13.8992 0.264031C13.6149 0.200031 13.325 0.148031 13.0315 0.106431C11.1329 -0.155169 9.4598 0.135231 8.96966 0.236431Z'
                            fill='#8A8B8A'
                        />
                        <path
                            d='M14.8685 24.4595L11.3593 20.9198L7.84975 24.4598L11.359 27.9996L14.8685 24.4595Z'
                            fill='#8A8B8A'
                        />
                        <path
                            d='M18.6839 11.8836L11.2672 4.3396L3.8501 11.8836L7.19065 15.2816L9.58899 12.8428C9.42998 12.5596 9.33758 12.2332 9.33758 11.884C9.33758 10.8088 10.2009 9.9376 11.2668 9.9376C12.3327 9.9376 13.1964 10.8088 13.1964 11.884C13.1964 12.9592 12.3327 13.8308 11.2668 13.8308C10.9258 13.8308 10.6058 13.74 10.3278 13.5832L7.92427 16.0276L11.2668 19.428L18.6839 11.8836Z'
                            fill='#696969'
                        />
                    </g>
                    <defs>
                        <clipPath id='clip0_507_2439'>
                            <rect width='23' height='28' fill='white' />
                        </clipPath>
                    </defs>
                </svg>
            </SvgIcon>
        );
    }
    return (
        <SvgIcon>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width={width || '24'}
                height={height || '24'}
                viewBox='0 0 23 28'
                fill='none'
            >
                <path
                    d='M22.3592 14.0356C22.7291 12.5352 22.7704 11.0156 22.5285 9.55957L20.2432 11.884L22.3592 14.0356Z'
                    fill='white'
                />
                <path
                    d='M3.20899 12.63L0.678589 15.204C0.835227 15.636 1.01923 16.0648 1.23257 16.4884C1.72866 17.4716 2.34093 18.4124 3.00833 19.2876C4.24161 20.9064 5.63469 22.3688 7.071 23.79L10.6257 20.1748L3.20899 12.63Z'
                    fill='white'
                />
                <path
                    d='M0.148735 9.51685C-0.0931611 10.998 -0.0443852 12.5416 0.332735 14.0628L2.4757 11.8836L0.148735 9.51685Z'
                    fill='white'
                />
                <path
                    d='M12.0929 20.1744L15.5774 23.7188C16.4688 22.8216 17.3547 21.9192 18.2276 21.0032C19.8415 19.3092 21.1989 17.4108 22.0122 15.1756L19.5096 12.63L12.0929 20.1744Z'
                    fill='white'
                />
                <path
                    d='M10.626 3.59364L7.67444 0.592041C3.99563 1.83804 1.38751 4.81084 0.416748 8.29764L3.20887 11.138L10.626 3.59364Z'
                    fill='white'
                />
                <path
                    d='M19.5096 11.1373L22.2577 8.34285C21.2679 4.81685 18.6138 1.81965 15.0452 0.590454L12.0929 3.59365L19.5096 11.1373Z'
                    fill='white'
                />
                <path
                    d='M8.96966 0.236431C8.92089 0.246831 8.87251 0.257231 8.82373 0.268431L11.3593 2.84723L13.8992 0.264031C13.6149 0.200031 13.325 0.148031 13.0315 0.106431C11.1329 -0.155169 9.4598 0.135231 8.96966 0.236431Z'
                    fill='white'
                />
                <path d='M14.8685 24.4595L11.3593 20.9198L7.84975 24.4598L11.359 27.9996L14.8685 24.4595Z' fill='white' />
                <path
                    d='M18.6839 11.8836L11.2672 4.3396L3.8501 11.8836L7.19065 15.2816L9.58899 12.8428C9.42998 12.5596 9.33758 12.2332 9.33758 11.884C9.33758 10.8088 10.2009 9.9376 11.2668 9.9376C12.3327 9.9376 13.1964 10.8088 13.1964 11.884C13.1964 12.9592 12.3327 13.8308 11.2668 13.8308C10.9258 13.8308 10.6058 13.74 10.3278 13.5832L7.92427 16.0276L11.2668 19.428L18.6839 11.8836Z'
                    fill='white'
                    fill-opacity='0.6'
                />
            </svg>
        </SvgIcon>
    );
}

export default EmpplanIcon;
