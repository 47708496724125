import { DateRangePicker, DateRangePickerToolbar } from '@mui/x-date-pickers-pro/DateRangePicker';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Chip } from '@mui/material';
import { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';
import useMediaQuery from '@mui/material/useMediaQuery';

interface DatePickerWrapperType {
    value: any;
    open: boolean;
    isDateRangeVisible: boolean;
    params: any;
    onClose: () => void;
    onOpen: () => void;
    onChange: (date: [Moment | null, Moment | null]) => void;
    deleteFromSteptwo: () => void;
    disableRemoveFilter?: boolean;
    editMode?: any;
    setEditMode?: any;
    triggerClick?: boolean;
}

export const DateRangePickerWrapper = ({
    value,
    open,
    isDateRangeVisible,
    params,
    onOpen,
    onClose,
    onChange,
    deleteFromSteptwo,
    disableRemoveFilter,
}: DatePickerWrapperType) => {
    const matches = useMediaQuery('(min-width:992px)');
    const ref = useRef<HTMLDivElement>(null);
    const [mobilecalOpen, setMobileCalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<[Moment | null, Moment | null]>(value);
    const [calOpen, setCalOpen] = useState(false);

    const { t } = useTranslation();
    const handleDone = () => {
        setMobileCalOpen(false);

        if (!selectedDate.includes(null)) {
            onChange(selectedDate);
            onClose();
            setMobileCalOpen(false);
        }
    };

    // const [domContentLoaded, setDomContentLoaded] = useState(false);

    // useEffect(() => {
    //     const handleDomContentLoaded = () => {
    //         setDomContentLoaded(true);
    //     };

    //     if (document.readyState === 'complete' || document.readyState === 'interactive') {
    //         // Document is already loaded
    //         setDomContentLoaded(true);
    //     } else {
    //         document.addEventListener('DOMContentLoaded', handleDomContentLoaded);
    //     }

    //     return () => {
    //         document.removeEventListener('DOMContentLoaded', handleDomContentLoaded);
    //     };
    // }, []);
    // let isVisible = useOnScreen(ref, domContentLoaded);
    // useEffect(() => {
    //     if (domContentLoaded && isVisible) {
    //         setMobileCalOpen(true);
    //     } else {
    //         setMobileCalOpen(false);
    //     }
    // }, [domContentLoaded, isVisible]);
    // const inputRef = useRef<any>();

    const shortcutsItems: any = [
        {
            label: t('##ThisWeek'),
            getValue: () => {
                return [moment().startOf('week'), moment().endOf('week')];
            },
        },
        {
            label: t('##LastWeek'),
            getValue: () => {
                return [moment().startOf('week').subtract(7, 'days'), moment().endOf('week').subtract(7, 'days')];
            },
        },
        {
            label: t('##LastMonth'),
            getValue: () => {
                return [
                    moment().subtract(1, 'month').startOf('month'), 
                    moment().subtract(1, 'month').endOf('month')
                ];
            },
        },
        {
            label: t('##Last7Days'),
            getValue: () => {
                return [moment().add(-7, 'days'), moment()];
            },
        },
        {
            label: t('##CurrentMonth'),
            getValue: () => {
                return [moment().startOf('month'), moment().endOf('month')];
            },
        },
        {
            label: t('##NextMonth'),
            getValue: () => {
                return [moment().endOf('month').add(1, 'day'), moment().endOf('month').add(1, 'day').endOf('month')];
            },
        },
        { label: t('##Reset'), getValue: () => [null, null] },
    ];
    return (
        <div
            style={{
                ...(isDateRangeVisible && {
                    visibility: 'visible',
                    width: 'auto',
                    height: 'auto',
                }),
                ...(!isDateRangeVisible && {
                    visibility: 'hidden',
                    width: '0',
                    height: '0',
                }),
            }}
        >
            {!matches ? (
                <MobileDateRangePicker
                    autoFocus={true}
                    ref={ref}
                    open={mobilecalOpen}
                    onOpen={() => setMobileCalOpen(true)}
                    onClose={() => handleDone()}
                    slots={{
                        field: SingleInputDateRangeField,
                    }}
                    slotProps={{
                        textField: {
                            sx: {
                                width: isDateRangeVisible ? 260 : 0,
                                paddingRight: isDateRangeVisible ? '10px' : 0,
                            },
                            size: 'small',
                            InputProps: {
                                disableUnderline: true,
                                sx: {
                                    borderRadius: 50,
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    marginTop: '-6px',
                                },
                            },
                        },
                    }}
                    defaultValue={selectedDate}
                    onChange={date => {
                        setSelectedDate(date);
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                ></MobileDateRangePicker>
            ) : (
                <DateRangePicker
                    defaultRangePosition={'end'}
                    defaultValue={selectedDate}
                    open={calOpen}
                    onOpen={() => setCalOpen(true)}
                    onClose={() => setCalOpen(false)}
                    slots={{
                        field: SingleInputDateRangeField,
                        // textField: textFieldProps => {
                        // delete textFieldProps.inputProps?.value;
                        // return (
                        //     <TextField
                        //         {...textFieldProps}
                        //         ref={textFieldProps.ref}
                        //         sx={{
                        //             width: 360,
                        //             paddingRight: '10px',
                        //         }}
                        //         variant='standard'
                        //         hiddenLabel
                        //         size='small'
                        //         autoFocus
                        //         InputProps={{
                        //             ...textFieldProps.InputProps,
                        //             disableUnderline: true,
                        //             sx: {
                        //                 borderRadius: 50,
                        //                 borderTopLeftRadius: 0,
                        //                 borderBottomLeftRadius: 0,
                        //                 marginTop: '-2px',
                        //             },
                        //             endAdornment: (
                        //                 <div style={{ display: 'flex' }}>
                        //                     <span
                        //                         style={{
                        //                             display: 'flex',
                        //                             background: 'rgb(36, 192, 177)',
                        //                             borderRadius: '50px',
                        //                             paddingLeft: '10px',
                        //                             color: 'white',
                        //                             paddingRight: '10px',
                        //                             cursor: 'pointer',
                        //                         }}
                        //                         onClick={handleDone}
                        //                     >
                        //                         {t('##Apply')}{' '}
                        //                         <CheckIcon
                        //                             style={{
                        //                                 display: 'inline',
                        //                                 cursor: 'pointer',
                        //                                 color: 'white',
                        //                             }}
                        //                         />
                        //                     </span>
                        //                     &nbsp;
                        //                     {!disableRemoveFilter && (
                        //                         <CancelIcon
                        //                             style={{ display: 'inline', cursor: 'pointer', color: '#949E9E' }}
                        //                             onClick={deleteFromSteptwo}
                        //                         />
                        //                     )}
                        //                 </div>
                        //             ),
                        //         }}
                        //     />
                        //     );
                        // },
                    }}
                    slotProps={{
                        shortcuts: {
                            items: shortcutsItems,
                        },
                        popper: { placement: 'bottom-end' },
                        textField: {
                            sx: {
                                paddingRight: '10px',
                            },
                            size: 'small',
                            inputProps:{
                                sx: {
                                    fontSize:'0.8rem',
                                    minWidth:'170px'
                                },
                            },
                            InputProps: {
                                disableUnderline: true,
                                sx: {
                                    borderRadius: 50,
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    marginTop: '-6px',
                                },
                                endAdornment: (
                                    <div style={{ display: 'flex', width: isDateRangeVisible ? 'auto' : 0 }}>
                                        <span
                                            style={{
                                                display: isDateRangeVisible ? 'flex' : 'none',
                                                background: 'rgb(36, 192, 177)',
                                                borderRadius: '50px',
                                                paddingLeft: '10px',
                                                color: 'white',
                                                paddingRight: '10px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleDone}
                                        >
                                            {t('##Apply')}{' '}
                                            <CheckIcon
                                                style={{
                                                    display: isDateRangeVisible ? 'inline' : 'none',
                                                    cursor: 'pointer',
                                                    color: 'white',
                                                }}
                                            />
                                        </span>
                                        &nbsp;
                                        {!disableRemoveFilter && (
                                            <CancelIcon
                                                style={{
                                                    display: isDateRangeVisible ? 'inline' : 'none',
                                                    cursor: 'pointer',
                                                    color: '#949E9E',
                                                }}
                                                onClick={deleteFromSteptwo}
                                            />
                                        )}
                                    </div>
                                ),
                            },
                        },
                    }}
                    onChange={date => {
                        setSelectedDate(date);
                    }}
                    name='allowedRange'
                />
            )}
            {/* <SingleInputDateRangeField label='Departure - Return' /> */}
            {/* <SingleInputDateRangeField
                {...params}
                value={value}
                open={open}
                onClose={onClose}
                onOpen={onOpen}
                disableFuture={true}
                disableHighlightToday={true}
                renderInput={(
                    startProps: JSX.IntrinsicAttributes & TextFieldProps,
                    endProps: {
                        inputProps: { value: any };
                        InputProps: { value: any };
                        onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
                    },
                ) => {
                    const startValue = startProps?.inputProps?.value;
                    const endValue = endProps?.inputProps?.value;
                    delete startProps?.inputProps?.value;
                    delete endProps?.inputProps?.value;
                    delete startProps?.InputProps?.value;
                    delete endProps?.InputProps?.value;
                    return (
                        <React.Fragment>
                            <TextField
                                {...startProps}
                                style={{ border: 'none', padding: '0px' }}
                                inputProps={{
                                    ...startProps.inputProps,
                                    style: { display: 'none' },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <Chip
                                            key={1}
                                            label={startValue + ' - ' + endValue}
                                            clickable
                                            style={{
                                                borderTopLeftRadius: '0px',
                                                borderBottomLeftRadius: '0px',
                                                marginTop: '1px',
                                            }}
                                            onClick={endProps.onClick}
                                            onDelete={deleteFromSteptwo}
                                        />
                                    ),
                                }}
                            />
                        </React.Fragment>
                    );
                }}
                onChange={onChange}
            /> */}
        </div>
    );
};

export default DateRangePickerWrapper;
