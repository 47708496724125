import React, { useContext } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    IconButton,
    Link,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import MapSharpIcon from '@mui/icons-material/MapSharp';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { LocationView, PointOfSaleView } from '../../../components-app/location-view';
import EventDate from './EventDate';
import EventCheckIn from './EventCheckIn';
import { useTranslation } from 'react-i18next';
import { eventStatesToAllowEditDate, COLORS_MAP, checkIsEventDate } from '../eventHelpers';
import EventDelete from './EventDelete';
import { DetectOnline } from '../../../hooks/detectOnline';
import { PWAContext } from '../../../PWA/pwaContext';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import { formatDate } from '../../../utils/date';
import moment from 'moment';
import AreaEventsComponent from './AreaEventsComponent';
import eventEnum from '../../../conf/eventConstants';
import { LabelChip } from '../../../components-app/label.chip';
import FlagIcon from '@mui/icons-material/Flag';

interface EventCardProps {
    eventData: any;
    activeAccordian: string | false;
    handleActiveAccordianChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    handleDeleteEventPwa?: (id: string | number) => void;
    setBottomFilters?: (value: string) => void;
}

function EventCard({
    eventData,
    activeAccordian,
    handleActiveAccordianChange,
    handleDeleteEventPwa,
    setBottomFilters,
}: EventCardProps) {
    const { t } = useTranslation();
    const isAccordianExpanded = activeAccordian === eventData?._id;

    const [event, setEvent] = React.useState<any>(eventData);
    const pwaContext = useContext(PWAContext);
    const promoterCanDeleteEvent = React.useMemo(() => {
        return event.meta?.promoterCanDeleteEvent && eventStatesToAllowEditDate.includes(event?.eventState);
    }, [event]);

    const promoterCanChangeDate = React.useMemo(() => {
        return event.meta?.promoterChangeDate && eventStatesToAllowEditDate.includes(event?.eventState);
    }, [event]);

    const isCheckedInOrHigher = React.useMemo(() => {
        return eventEnum?.orHigherStates?.CheckedInAndHigher?.includes(event?.eventState);
    }, [event]);

    const isReportingOnAreaEvents = React.useMemo(() => {
        return event?.area ? event?.project_id?.eventSettings?.reportingOnAreaEvents ?? true : true;
    }, [event]);

    const firstCheckedTimes = React.useMemo(() => {
        const checkin = event.eventStateHistory.find((item: any) => item.stateNew === 'LoggedIn');
        return checkin && checkin?.date ? moment(checkin.date) : null;
    }, [event]);

    const { isOnline } = DetectOnline();
    const isEventDateToday = checkIsEventDate(event);

    if (event?.eventState === 'Deleted') return null;

    return (
        <Accordion
            sx={{
                borderLeft: !isAccordianExpanded ? '5px solid transparent' : '',
                borderLeftColor:
                    !isAccordianExpanded && COLORS_MAP[event?.eventState] ? COLORS_MAP[event?.eventState] : 'transparent',
                background: isAccordianExpanded ? `linear-gradient(${COLORS_MAP[event?.eventState]} -200%, #ffffff 100%);` : '',
            }}
            expanded={isAccordianExpanded}
            onChange={handleActiveAccordianChange(eventData?._id)}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1bh-content' id='panel1bh-header'>
                {isAccordianExpanded ? (
                    <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignItems='center'
                        flexWrap='wrap'
                        gap={2}
                    >
                        <Box display='flex' flexDirection='row' alignItems='center' onClick={e => e.stopPropagation()}>
                            <DateRangeOutlinedIcon sx={{ mr: 1 }} />
                            {promoterCanChangeDate ?
                                <EventDate event={event} setEvent={setEvent} /> :
                                <Typography>{formatDate(event?.date)}</Typography>
                            }
                        </Box>
                        {/* this event is created by promoter and has no access to editing the planned times */}
                        {/* also we don't need to show default planned time for this event */}
                        <LabelChip>{event.eventState}</LabelChip>
                    </Box>
                ) : (
                    <Box display='flex'>
                        <Typography sx={{ alignSelf: 'center', mr: 3 }}>
                            {formatDate(event?.date)}
                            {eventData.kms && (
                                <>
                                    <br />
                                    <Button disabled variant='text' size='small' color='secondary'>
                                        {Number(eventData.kms).toFixed(1)} km
                                    </Button>
                                </>
                            )}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary', alignSelf: 'center', mr: 1 }}>
                            <LocationView event={event} noHyperlink />
                        </Typography>
                    </Box>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction='column' rowSpacing={2}>
                    <Grid item>
                        {event.briefing === 'GENERATED BY PROMOTER FOR REPORT' ? (
                            firstCheckedTimes && <Box display='flex'>
                                <AccessTimeIcon sx={{ mr: 1 }} />
                                <Typography>
                                    {moment(firstCheckedTimes).format('HH:mm')}
                                </Typography>
                            </Box>
                        ) : (
                            Array.isArray(event.times) &&
                            event.times.length > 0 &&
                            event.times.map((times: any) => {
                                return (
                                    <Box display='flex' key={times._id}>
                                        <AccessTimeIcon sx={{ mr: 1 }} />
                                        <Typography>
                                            {moment(times.start).format('HH:mm')} -{' '}
                                            {moment(times.stop).format('HH:mm')}
                                        </Typography>
                                    </Box>
                                );
                            })
                        )}
                    </Grid>
                    <Grid item>
                        <Box display='flex'>
                            {event?.area ? <MapSharpIcon sx={{ mr: 1 }} /> : <LocationOnOutlinedIcon sx={{ mr: 1 }} />}
                            <Typography sx={{ width: '75%', color: 'text.secondary', alignSelf: 'center' }}>
                                {/* the 75% is there so that the accordian can be closed without accidently clicking the location link */}
                                <LocationView event={event} />
                            </Typography>
                        </Box>
                    </Grid>

                    {pwaContext?.pwa && event?.customStatuses?.length > 0 && (
                        <Grid item>
                            <Box display='flex' alignItems='center'>
                                <FlagIcon sx={{ mr: 1 }} />
                                <Box display='flex' gap={1} flexWrap='wrap'>
                                    {event?.customStatuses.map((option: any) => {
                                        return (
                                            <React.Fragment key={option?._id}>
                                                {option?.isPromoterVisible && (
                                                    <LabelChip
                                                        css={{
                                                            backgroundColor: option.color
                                                                ? option.color
                                                                : 'rgb(36,192,177)',
                                                        }}
                                                    >
                                                        {option.statusLabel
                                                            ? option.type === 'EMPPLAN'
                                                                ? t('' + option.slangText)
                                                                : t('' + option.statusLabel)
                                                            : t('' + option.custom_status)}
                                                    </LabelChip>
                                                )}
                                            </React.Fragment>
                                        );
                                    })
                                    }
                                </Box>
                            </Box>
                        </Grid>
                    )}

                    <Grid item>
                        <Box display='flex'>
                            <BookmarkBorderOutlinedIcon sx={{ mr: 1 }} />
                            <Typography>{event?.project_id?.name}</Typography>
                        </Box>
                    </Grid>

                    {event.area && isEventDateToday && isCheckedInOrHigher && (
                        <Grid container item width='100%'>
                            <Grid item xs='auto'>
                                <LocationOnOutlinedIcon sx={{ mr: 1, mt: 3 }} />
                            </Grid>
                            <Grid item xs>
                                {isOnline ? (
                                    <AreaEventsComponent event={event} />
                                ) : (
                                    <Button fullWidth variant='contained' disabled endIcon={<OfflineBoltIcon />}>
                                        {t('##Offline')}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    )}
                    <Grid item>
                        <EventCheckIn event={event} setEvent={setEvent} setBottom={setBottomFilters} />
                    </Grid>

                    <Grid container item direction='row-reverse' justifyContent='space-between' alignItems='center'>
                        <Grid item>
                            <IconButton
                                LinkComponent={Link}
                                sx={{ display: (event.area && event.eventState === 'Accepted') || !isReportingOnAreaEvents ? 'none' : undefined }}
                                target={pwaContext?.areWeInPwa === 'yes' && pwaContext.pwa ? undefined : `_blank`}
                                href={
                                    pwaContext?.areWeInPwa === 'yes' && pwaContext.pwa
                                        ? `#/event/${event._id}`
                                        : `/#!/event/${event._id}`
                                }
                                aria-label='openevent'
                                color='secondary'
                            >
                                <OpenInNewOutlinedIcon sx={{ mr: 1 }} />
                            </IconButton>
                        </Grid>

                        {eventData.kms && (
                            <Grid item>
                                <Button disabled variant='text' color='secondary' startIcon={<NearMeOutlinedIcon />}>
                                    {Number(eventData.kms).toFixed(1)} km
                                </Button>
                            </Grid>
                        )}

                        {event.area && (
                            <Grid item>
                                <Button disabled variant='text' color='secondary' size='small'>
                                    {t('##ThisIsAnAreaEvent')}
                                </Button>
                            </Grid>
                        )}

                        <Grid item>
                            {isOnline
                                ? promoterCanDeleteEvent && (
                                      <EventDelete
                                          event={event}
                                          setEvent={setEvent}
                                          handleDeleteEventPwa={handleDeleteEventPwa}
                                      />
                                  )
                                : promoterCanDeleteEvent && (
                                      <Button
                                          startIcon={<OfflineBoltIcon />}
                                          disabled
                                          variant='text'
                                          color='secondary'
                                          size='small'
                                      >
                                          {t('##DeleteEvent')}
                                      </Button>
                                  )}
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

export default EventCard;
