import { Box, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReactAssociatedChat from '../../../../reactelements/src/pages/Messenger/components/ReactAssociatedChat';
import { Channel } from '../../../../reactelements/src/pages/Messenger/types';
import DynTableConst from '../../../Services/Constants';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { LeadHeader } from '../LeadHeader';
import { useSelector, useDispatch } from 'react-redux';
import { CRMData } from 'reactelements/src/modules/ViewEditCrm/types';
import { clearCrmDataForEditing, loadCrmDataForEditing } from '../../../Models/NewCRM/actions';

export const LeadsMessenger = () => {
    const { leadId } = useParams<{ leadId: string }>();
    const dispatch: any = useDispatch();
    const navigate = useHistory();
    const [channel, setChannel] = useState<Channel | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const mainCRMData: CRMData = useSelector((store: any) => store?.crmItem?.crmItemForEditing);

    const getOrCreateChannel = async () => {
        // find channel if already exists
        try {
            setLoading(true);
            const obj = JSON.stringify({
                contextObject: 'crm',
                contextObjectId: leadId,
            });
            // we will find the channel with the lead id
            // id exists we can use the channel
            const res = await axios.get(`${DynTableConst.EMPPLAN_HOST}/chatchannel?context=${obj}`);
            if (res.data && Array.isArray(res.data) && res.data.length > 0) {
                setChannel(res.data[0]);
            } else {
                // else we will create a new channel

                const channelName = mainCRMData.firstName || mainCRMData.lastName
                    ? `${mainCRMData.firstName} ${mainCRMData.lastName} ${mainCRMData.address?.streetName} ${mainCRMData.address?.streetNumber}${mainCRMData.address?.streetAppendix ? '-' + mainCRMData.address?.streetAppendix : ''}, ${mainCRMData.address?.city}`.trim()
                    : `${mainCRMData.address?.streetName} ${mainCRMData.address?.streetNumber}${mainCRMData.address?.streetAppendix ? '-' + mainCRMData.address?.streetAppendix : ''}, ${mainCRMData.address?.city}`.trim();

                const payload = {
                    name: channelName,
                    context: {
                        contextObject: 'crm',
                        contextObjectId: leadId,
                    },
                    members: [window?.userabstraction?._id],
                };
                const data = await axios.post(`${DynTableConst.EMPPLAN_HOST}/chatchannel/eventsupport`, payload);
                if (data.data && data.data._id) {
                    setChannel(data.data);
                }
                // else channel will be null
                // mose cases occur with user right issues
                // promoter does not have channeAdd and channelMembersView right by default
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // if reloaded we need to fetch crm data online with leadId
        if (!mainCRMData) {
            dispatch(loadCrmDataForEditing(undefined, leadId));
        }
        return () => {
            // dispatch clear crmEdit items
            dispatch(clearCrmDataForEditing());
        };
    }, []);

    useEffect(() => {
        if(mainCRMData) {
            getOrCreateChannel();
        }
    }, [mainCRMData])

    return (
        <>
            {mainCRMData && <LeadHeader key='lead-header' crmData={mainCRMData} svgPin='msg' />}

            {loading ? (
                <Backdrop
                    key='lead-loading'
                    sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                    open={loading}
                >
                    <CircularProgress color='inherit' />
                </Backdrop>
            ) : channel ? (
                <Box key='lead-chat' mt={'82px'}>
                    <ReactAssociatedChat
                        // disableChannelNameLink
                        writeAtBottom
                        channel={channel}
                        cb={() => navigate.goBack()}
                    />
                </Box>
            ) : (
                <Typography key='lead-contact-admin'>Contact Admin</Typography>
            )}
        </>
    );
};
