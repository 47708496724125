import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Avatar, IconButton, TextField, Fab, InputAdornment, Grid } from '@mui/material';
import { Channel, Message, SingleChannelUnreadCount } from '../types';
import moment from 'moment';
import GroupIcon from '@mui/icons-material/Group';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Inbox from './Inbox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeviceDetect from '../../../hooks/deviceDetect';
import AddIcon from '@mui/icons-material/Add';
import { returnChatChannelAdd, returnChatChannelMemberOf, returnUserCanSeeMessage } from '../utils/helpers';
import { PWAContext } from '../../../PWA/pwaContext';
import { AddEditChannelDetails } from '../components/AddEditChannelDetails';
import { trackEvent } from '../../../components-app/GAListener';
import { CloseSharp, SearchSharp } from '@mui/icons-material';
import ChatSection from '../components/ ChatSection';
import ChannelHeader from '../components/ChannelHeader';
import ChannelMenu from '../components/ChannelMenu';
import { ReactMessengerContext } from '../Index';

interface ChannelProps {
    allChannels: Channel[];
    setChannelOpen?: (value: boolean) => void;
    fetchChannel?: () => void;
    unreadDetails?: SingleChannelUnreadCount[];
}

export interface FileStructure {
    src: string | ArrayBuffer | null | undefined;
    fileName: { name: string; type: string; lastModifiedDate: number; size: number } | undefined;
}

function Channels({ allChannels, setChannelOpen, fetchChannel, unreadDetails }: ChannelProps) {
    const reactMessengerContext = React.useContext(ReactMessengerContext);
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(DeviceDetect().isMobile);
    useEffect(() => {
        if (DeviceDetect().isMobile === false) {
            const handleResize = () => {
                if (window.innerWidth < 900) {
                    setIsMobile(true);
                } else {
                    setIsMobile(false);
                }
            };
            handleResize();
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    });
    const [openChannel, setOpenChannel] = useState<{ status: boolean; currentChannel: Channel | null }>({
        status: false,
        currentChannel: null,
    });
    const [channelFilterText, setChannelFilterText] = useState<string>('');
    const [reloadMesssages, setReloadMessages] = useState<Message | null>(null);
    const [addChannelOpen, setAddChannelOpen] = useState<boolean>(false);
    const [selectMode, setSelectMode] = useState<boolean>(false);
    const pwaContext = useContext(PWAContext);

    const changeChannel = (result: Channel) => {
        trackEvent('Messenger', 'Open Channel', 'Change Channel');
        reactMessengerContext?.navigateTo('Channels/' + result?._id || '');
    };

    // useEffect(() => {
    //     if (!isMobile) {
    //         setOpenChannel({
    //             status: true,
    //             currentChannel: allChannels[0],
    //         });
    //         setChannelOpen && setChannelOpen(true);
    //         fetchChannel && fetchChannel();
    //         //     endRef.current?.scrollIntoView({ behavior: 'smooth' });
    //     }
    // }, []);

    const returnEachChannelCount = (channelId: string, details: SingleChannelUnreadCount[]) => {
        const unreadObject = details.find(unreadDetials => unreadDetials._id === channelId);
        if (unreadObject && unreadObject.countOfMessage) {
            return unreadObject.countOfMessage;
        }
        return undefined;
    };

    React.useEffect(() => {
        if (reactMessengerContext.internalId && reactMessengerContext.internalId !== openChannel.currentChannel?._id) {
            const foundChannel = allChannels.find(ch => ch._id === reactMessengerContext.internalId);
            if (foundChannel) {
                setOpenChannel({ status: true, currentChannel: foundChannel });
                setChannelOpen && setChannelOpen(true);
            } else {
                setOpenChannel({ status: false, currentChannel: null });
                setChannelOpen && setChannelOpen(false);
            }
        } else {
            setOpenChannel({ status: false, currentChannel: null });
            setChannelOpen && setChannelOpen(false);
            // fetchChannel && fetchChannel();
        }
    }, [reactMessengerContext.internalId, allChannels]);

    return (
        <Box height='90vh'>
            <Grid container spacing={0}>
                {(openChannel.status === false || !isMobile) && (
                    <Grid
                        item
                        xs={12}
                        md={3}
                        style={{
                            height: !isMobile ? 'calc(100vh - 150px)' : '',
                            overflowY: !isMobile ? 'scroll' : 'auto',
                            borderRight: !isMobile ? '1px solid lightgrey' : '',
                        }}
                    >
                        <Box>
                            <Box p={2}>
                                <TextField
                                    fullWidth
                                    id='channel-search-input'
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: 50,
                                            },
                                        },
                                        width: !isMobile ? 'calc(100% - 40px)' : '100%',
                                    }}
                                    size='small'
                                    margin='dense'
                                    placeholder={t`##SearchByChannel`}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <SearchSharp />
                                            </InputAdornment>
                                        ),
                                        endAdornment: channelFilterText ? (
                                            <InputAdornment position='end' key='channel-filter-text-clear'>
                                                <IconButton
                                                    onClick={() => {
                                                        setChannelFilterText('');
                                                    }}
                                                >
                                                    <CloseSharp />
                                                </IconButton>
                                            </InputAdornment>
                                        ) : undefined,
                                    }}
                                    onFocus={() => {
                                        //setChannelOpen && setChannelOpen(true);
                                    }}
                                    onBlur={() => {
                                        //setChannelOpen && setChannelOpen(false);
                                    }}
                                    value={channelFilterText}
                                    onChange={e => setChannelFilterText(e.target.value)}
                                />
                                {!isMobile && (
                                    <Fab
                                        onClick={() => setAddChannelOpen(true)}
                                        color='secondary'
                                        sx={{
                                            marginLeft: '5px',
                                            marginTop: '10px',
                                            minHeight: '35px',
                                            width: '35px',
                                            height: '35px',
                                        }}
                                    >
                                        <AddIcon />
                                    </Fab>
                                )}
                            </Box>
                            {allChannels.map(channel => {
                                try {
                                    if (
                                        channelFilterText.length > 0 &&
                                        !channel.name?.toLowerCase().includes(channelFilterText.toLowerCase())
                                    ) {
                                        return null;
                                    }
                                } catch (error) {}
                                return (
                                    <React.Fragment key={channel._id}>
                                        {returnChatChannelMemberOf(
                                            channel.members,
                                            window?.userabstraction?._id,
                                            window?.user?.roles,
                                        ) && (
                                            <Box p={2}>
                                                <Box
                                                    display='flex'
                                                    sx={{
                                                        cursor: 'pointer',
                                                    }}
                                                    justifyContent='center'
                                                    alignItems='center'
                                                    onClick={e => {
                                                        changeChannel(channel);
                                                    }}
                                                >
                                                    <Avatar>
                                                        <GroupIcon />
                                                    </Avatar>
                                                    <Box sx={{ width: '100%', px: 1 }}>
                                                        {channel.name && (
                                                            <Typography
                                                                style={{
                                                                    color:
                                                                        openChannel?.currentChannel?.name === channel.name
                                                                            ? '#27b3ae'
                                                                            : 'black',
                                                                }}
                                                                fontSize='medium'
                                                                gutterBottom
                                                            >
                                                                <strong>{channel.name}</strong>
                                                            </Typography>
                                                        )}
                                                        {channel.readOnly && (
                                                            <Box display='flex' gap={1}>
                                                                <VisibilityIcon
                                                                    sx={{ color: '#d10b88' }}
                                                                    fontSize='small'
                                                                />
                                                                <Typography color={'#d10b88'} fontSize='small'>
                                                                    {t('##ReadOnlyChannel')}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                    <Box
                                                        display='flex'
                                                        justifyContent='flex-end'
                                                        gap={1}
                                                        alignItems='center'
                                                    >
                                                        <Typography fontSize='small' align='right'>
                                                            <small>{moment(channel.created).format('DD.MM.YYYY')}</small>
                                                        </Typography>
                                                        {Array.isArray(unreadDetails) &&
                                                            unreadDetails.length > 0 &&
                                                            (() => {
                                                                const channelCount = returnEachChannelCount(
                                                                    channel._id as string,
                                                                    unreadDetails,
                                                                );
                                                                if (channelCount !== undefined) {
                                                                    return (
                                                                        <Box
                                                                            sx={{
                                                                                backgroundColor: '#d32294',
                                                                                color: 'white',
                                                                                borderRadius: '50%',
                                                                                width: '22px',
                                                                                height: '22px',
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                            }}
                                                                        >
                                                                            <Typography fontSize='small' align='right'>
                                                                                {channelCount}
                                                                            </Typography>
                                                                        </Box>
                                                                    );
                                                                }
                                                            })()}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </Box>
                    </Grid>
                )}

                {openChannel.status === false && returnChatChannelAdd(window?.user?.roles) && (
                    <Fab
                        onClick={() => setAddChannelOpen(true)}
                        color='secondary'
                        sx={{
                            position: pwaContext?.pwa ? 'fixed' : 'absolute',
                            bottom: pwaContext?.pwa ? '100px' : '170px',
                            right: pwaContext?.pwa ? '10px' : '30px',
                        }}
                    >
                        <AddIcon />
                    </Fab>
                )}
                {addChannelOpen && (
                    <AddEditChannelDetails
                        mode='add'
                        open={addChannelOpen}
                        close={() => setAddChannelOpen(false)}
                        fetchChannelList={fetchChannel}
                    />
                )}

                {openChannel && openChannel.currentChannel?._id && (
                    <Grid item xs={12} md={9}>
                        <Box>
                            <Box display='flex' justifyContent='space-between' p={1} alignItems='center'>
                                <Box display='flex' alignItems='center' gap={1}>
                                    <IconButton
                                        onClick={() => {
                                            reactMessengerContext.navigateTo('Channels');
                                        }}
                                        sx={{ display: !isMobile ? 'none' : 'auto' }}
                                    >
                                        <ArrowBackIcon color='secondary' />
                                    </IconButton>
                                    <Avatar>
                                        <GroupIcon />
                                    </Avatar>
                                    <Box>
                                        <ChannelHeader channel={openChannel.currentChannel} />
                                    </Box>
                                </Box>
                                <ChannelMenu
                                    openChannel={openChannel}
                                    setSelectMode={setSelectMode}
                                    fetchChannel={fetchChannel}
                                    setChannelOpen={setChannelOpen}
                                    setOpenChannel={setOpenChannel}
                                />
                            </Box>
                            <Box
                                sx={{
                                    overflowY: 'auto',
                                    height: pwaContext?.pwa
                                        ? '90vh'
                                        : isMobile
                                        ? 'calc(100vh - 130px)'
                                        : 'calc(100vh - 150px)',
                                }}
                            >
                                {returnUserCanSeeMessage(window?.user?.roles) ? (
                                    <Inbox
                                        inboxChannelId={openChannel.currentChannel._id as string}
                                        reloadMessage={reloadMesssages}
                                        selectMode={[selectMode, setSelectMode]}
                                        setreloadMessage={setReloadMessages}
                                        isReadOnly={openChannel.currentChannel.readOnly}
                                        setOpenChannel={changeChannel}
                                    />
                                ) : (
                                    <Typography>{t('##NotEnoughRights')}</Typography>
                                )}
                            </Box>
                            <ChatSection setReloadMessages={setReloadMessages} channel={openChannel.currentChannel} />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

export default Channels;
