import React from 'react';
import { GridOverlay } from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import { useStyles } from './LoadingOverlayStyles';

const LoadingOverlay = () => {
  const classes = useStyles();

  return (
    <GridOverlay>
      <div className={classes.overlay}>
        <LinearProgress color={'secondary'} />
      </div>
    </GridOverlay>
  );
};

export default LoadingOverlay;
