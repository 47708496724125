import {Grid, Box, Typography, Divider, Popper, Paper, Popover} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useTheme} from "@mui/material";
import {GridCellValue, GridColDef} from "@mui/x-data-grid-pro";
import React, {FC, useContext, useEffect, useMemo, useRef, useState} from "react";
import {ColumnsPanelContext} from "../table/contexts/columns";
export interface ArrayGridProps {
  value: any[];
  options?: {
    keyPath: string
    colDef: GridColDef
  }
}

const baseOfKeyPath = (keyPath:string) => keyPath.split('.').pop()
export function ArrayGrid(props: ArrayGridProps) {
  const {columnsWidthModel} = useContext((ColumnsPanelContext))
  const anchorEl = useRef<HTMLDivElement>(null);
  const gridRowHeight = 52;
  const [hover, setHover] = useState(false);
  const columnDef = props.options?.colDef as GridColDef;
  return useMemo(()=> props.value && props.options?.colDef ?(<>
    <Box
      ref={anchorEl}
      sx={{
      // position: 'relative',
      overflow: 'hidden',
      height:'100%',
      width:'100%',
      display:'flex',
      flexDirection:'column',
      gap:'1px',
    }}>
        <Paper
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          elevation={hover?3:0}
          sx={{
            // transform: `translateY(-${anchorEl.current?.offsetHeight}px)`,
            filter: hover?'unset':'brightness(0.96)',
            width: anchorEl.current?.offsetWidth+'px',
            height: 'auto',
            maxHeight: gridRowHeight*6+'px',
            overflowX: 'hidden',
            overflowY: hover?'auto':'hidden',
            display:'flex',
            flexDirection:'row',
            flexWrap:'wrap',
            justifyContent:'center',
            zIndex: hover?1000:0,
            position: hover?'fixed':'unset',
            '&::-webkit-scrollbar': {
              width: '4px',
              backgroundColor: 'rgba(0, 0, 0, 0.12)'
            },
            transform: hover?'scale(1.01)':'unset',
            '&::-webkit-scrollbar-thumb': {
              width: '6px',
              backgroundColor: 'gray',
            }
            // outline: hover?'2px solid black':'none',
        }}
        >
          {props.value?.map(val=>({[baseOfKeyPath(props.options?.keyPath as string) as string]:val})).map((value, index) => {
            let cellRender:any;
            let cellValue:any
            if(columnDef.valueGetter) {
              // @ts-ignore
              cellValue = columnDef.valueGetter({row:value,colDef:columnDef});
            }
            if(columnDef.renderCell) {
              const CellRenderer = columnDef.renderCell as FC<{value:any}>;
              cellRender = ()=> <CellRenderer value={cellValue} />;
            }
            return <Box key={columnDef.field+'.'+index} sx={{
              padding:'4px',
              height:`${gridRowHeight}px`,
              width: columnDef.type === 'object:grid' ? '100%' : 'unset',
              lineHeight:'1.5em',
              overflow:'hidden',
              boxSizing:'border-box',
              backgroundColor: '#fff',
              display:'flex',
              alignContent:'center',
              alignItems:'center',
            }} >
              {cellRender ? cellRender() : cellValue?.toString()}
              <Box component={'span'} sx={{
                position:'absolute',
                right:'-0.1em',
                userSelect:'none',
                backgroundColor: '#fff',
                color:'gray',
                width:'3em',
                height:'3em',
                display:'flex',
                borderRadius:'50%',
                placeItems:'center',
                placeContent:'center',
                opacity:0.3,
              }}>
                {index+1}/{props.value.length}
              </Box>
            </Box>
          })
          }
        </Paper>

    </Box>
    </>
  ):null, [props.value, props.options, hover,columnsWidthModel[props.options?.keyPath as string]])
}