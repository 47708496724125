import * as React from 'react';
import { Box, Button } from '@mui/material';
// import axios from 'reactelements/node_modules/axios';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import CircularProgressWithLabel from 'reactelements/src/components-ui/CircularProgressWithLabel';
import axios from 'axios';

const ReportGlitchButton = ({ payload }: { payload: string }) => {
    const { t } = useTranslation();
    const [stage, setStage] = React.useState<'ready' | 'reporting' | 'done' | 'failed'>('ready');
    const [uploadProgress, setUploadProgress] = React.useState<number | undefined>();

    const reportError = async () => {
        try {
            var urlencoded = new URLSearchParams();
            urlencoded.append('secret', 'hidden');
            urlencoded.append('errorLog', payload);

            await axios.post('https://glitch.empplan.io/api/uploadErrorLog', urlencoded, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                onUploadProgress(progressEvent) {
                    // console.log({ progressEvent });
                    setUploadProgress && setUploadProgress(progressEvent.progress);
                },
            });
            setStage('done');
        } catch (error) {
            setStage('failed');
        }
    };

    return (
        <Box display='flex' gap={2}>
            {(stage === 'ready' || stage === 'failed') && (
                <Button
                    color="secondary"
                    variant="contained"
                    key='ready-stage'
                    onClick={() => {
                        setStage('reporting');
                        reportError();
                    }}
                >
                    {t('##UploadSyncLogError')}
                </Button>
            )}
            {stage === 'reporting' && <CircularProgressWithLabel key='uploading-stage' value={uploadProgress || 0} />}
            {stage === 'done' && (
                <Button variant='contained' key='done-stage' color='success' disabled>
                    {t('##Reported')}
                </Button>
            )}
        </Box>
    );
};

export default ReportGlitchButton;
