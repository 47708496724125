import ObjectID from 'bson-objectid';
import axios from 'axios';
import { tz } from 'moment-timezone';
import _ from 'lodash';
import { Collection, IndexableType, Table } from 'dexie';

import { newCRMDb } from './newCRMItemDexie';
import { deleteCrmItemsFetch, syncAllCrmItems } from './helpers';
import { isDeviceOnline } from '../../Services/OnlineStatus';
import { CRMData } from 'reactelements/src/modules/ViewEditCrm/types';
import { DexieCRMItem, leadStatusChangePayload } from './types';
import DynTableConst from '../../Services/Constants';
import { newCRMActionsDb } from '../NewCRMActions/newCRMActionDexie';
import { ICustomStatuses } from '../../../reactelements/src/modules/SideBarContentForEvents/types';
import { newSalesItemDb } from '../NewSalesItem/newSalesItemDexie';

const newCrmItemActionTypes = {
    NEW_CRMITEM: 'NEW_CRMITEM',
    LOAD_CRMITEM_LIST: 'LOAD_CRMITEM_LIST',
    LOAD_CRMITEM: 'LOAD_CRMITEM',
    UPDATE_CRMITEM_SYNC_DATA: 'UPDATE_CRMITEM_SYNC_DATA',
    DELETE_CRMITEM: 'DELETE_CRMITEM',
    UPDATE_EDIT_CRMITEM: 'UPDATE_EDIT_CRMITEM',
};

export default newCrmItemActionTypes;

export const createNewCrmItemAction = (crmItem: DexieCRMItem) => {
    newCRMDb.crmItems.add(crmItem);
    return {
        type: newCrmItemActionTypes.NEW_CRMITEM,
        payload: crmItem,
    };
};

export const newCrmItemPostAction = async (crmItem: any) => {
    if (crmItem) {
        if (!crmItem._id) {
            crmItem._id = new ObjectID().str;
        }
        newCRMDb.crmItems.add({
            _id: crmItem._id,
            crmItem,
            event: crmItem.eventId[0],
            syncDate: new Date(),
            isNewDoc: 1,
            syncPending: 1,
            lon: crmItem?.address?.loc?.[0] ?? 0,
            lat: crmItem?.address?.loc?.[1] ?? 0,
            syncError: 0,
        });
    }
    return {
        type: newCrmItemActionTypes.NEW_CRMITEM,
        payload: crmItem,
    };
};

export const newCrmItemPutAction = async (crmItem: CRMData) => {
    if (crmItem?._id) {
        newCRMDb.crmItems.update(crmItem._id, {
            crmItem: crmItem,
            syncPending: 1,
        });
    }
    return {
        type: newCrmItemActionTypes.NEW_CRMITEM,
        payload: crmItem,
    };
};

export const newCrmItemDeleteAction = async (crmItem: any, successCallback?: any, errorCallback?: any) => {
    try {
        if (!crmItem?._id) throw new Error('no _id in salesitem on function call');
        // find the crmItem in the database
        const dexiecrmItem = await newCRMDb.crmItems.get(crmItem._id);
        if (!dexiecrmItem) throw new Error('Cannot find salesitem in database');
        // we check if doc is already on the server
        // if (!dexiecrmItem.isNewDoc) {
        //     if (!isDeviceOnline()) throw new Error('Cannot perform action when device is offline');
        //     const data = await deleteSalesItemsFetch(crmItem._id);
        //     if (!data || data?.errors) {
        //         throw new Error('Failed to delete from server');
        //     }
        // }
        if (!isDeviceOnline()) throw new Error('Cannot perform action when device is offline');
        const data = await deleteCrmItemsFetch(crmItem._id);
        if (!data || data?.errors) {
            throw new Error('Failed to delete from server');
        }
        // we delete from local database now
        await newCRMDb.crmItems.delete(crmItem._id);
        successCallback && successCallback();
    } catch (error) {
        console.log(error);
        errorCallback && errorCallback();
    }
    return {
        type: newCrmItemActionTypes.DELETE_CRMITEM,
        payload: crmItem,
    };
};

export const loadCrmItemAction = async (dispatch: any, crmItemId: string | null) => {
    if (crmItemId !== null && crmItemId) {
        const crmItems = await newCRMDb.crmItems.get(crmItemId);
        return dispatch({
            type: newCrmItemActionTypes.LOAD_CRMITEM,
            payload: crmItems,
        });
    } else {
        return dispatch({
            type: newCrmItemActionTypes.LOAD_CRMITEM,
            payload: undefined,
        });
    }
};

export const loadCrmDataForEditing = (data?: CRMData, leadId?: string) => async (dispatch: any) => {
    if (data) {
        return dispatch({
            type: newCrmItemActionTypes.UPDATE_EDIT_CRMITEM,
            payload: data,
        });
    }
    try {
        if (window?.navigator?.onLine) {
            const response = await axios.get(`${DynTableConst.EMPPLAN_HOST}/crm/${leadId}`);
            if (response.data && response.data._id) {
                return dispatch({
                    type: newCrmItemActionTypes.UPDATE_EDIT_CRMITEM,
                    payload: response.data,
                });
            } else {
                console.log(response, 'response not ok!');
                return dispatch({
                    type: newCrmItemActionTypes.UPDATE_EDIT_CRMITEM,
                    payload: undefined,
                });
            }
        } else {
            const doc = await newCRMDb.crmItems.get({ _id: leadId });
            console.log('fetching from Dexie: ', doc);
            return dispatch({
                type: newCrmItemActionTypes.UPDATE_EDIT_CRMITEM,
                payload: doc?.crmItem,
            });
        }
    } catch (error) {
        console.error(error);
        return dispatch({
            type: newCrmItemActionTypes.UPDATE_EDIT_CRMITEM,
            payload: undefined,
        });
    }
};

export const clearCrmDataForEditing = () => (dispatch: any) => {
    return dispatch({
        type: newCrmItemActionTypes.UPDATE_EDIT_CRMITEM,
        payload: undefined,
    });
};

export const loadCrmItemListAction = async (
    dispatch: any,
    filterOptions: {
        crmId?: string;
        projectId?: string;
        eventId?: string;
        streetnumber?: number;
        streetname?: string;
        city?: string;
        zipCode?: string;
        leadstatus?: string;
        leadstatus_op?: '$eq' | '$ne';
        searchbyaddress?: string;
        streetAppendix?: string;
    },
    pagination?: { page: number; limit: number },
    actionOptions?: { skipCount?: boolean },
) => {
    const skip = (pagination?.page ?? 0) * (pagination?.limit ?? 10);
    const limit = pagination?.limit ?? 500;

    type DexieCRMItemQueryBuilder = Collection<DexieCRMItem, IndexableType> | Table<DexieCRMItem, IndexableType>;
    let crmItemsQuery: DexieCRMItemQueryBuilder = newCRMDb.crmItems;

    if (filterOptions.crmId) {
        const data = await crmItemsQuery.get(filterOptions.crmId);
        const crmItems = [data];
        dispatch?.({
            type: newCrmItemActionTypes.LOAD_CRMITEM_LIST,
            payload: crmItems,
        });

        return { items: crmItems.map(c => c?.crmItem), count: 1 };
    }

    if (Object.keys(filterOptions).length > 0) {
        crmItemsQuery = crmItemsQuery.filter(crmDoc => {
            if (filterOptions.crmId) {
                if (crmDoc._id !== filterOptions.crmId) return false;
            }
            if (filterOptions.leadstatus && filterOptions.leadstatus_op) {
                const leadStatuses = filterOptions.leadstatus.split(',');
                if (!crmDoc.crmItem?.status?._id) return false;
                if (filterOptions.leadstatus_op === '$eq') {
                    if (!leadStatuses.includes(crmDoc.crmItem?.status?._id)) return false;
                }
                if (filterOptions.leadstatus_op === '$ne') {
                    if (leadStatuses.includes(crmDoc.crmItem?.status?._id)) return false;
                }
            }
            if (filterOptions.projectId) {
                const filterResult = crmDoc.crmItem?.projectId?.includes?.(filterOptions.projectId) || false;
                if (filterResult === false) return false;
            }
            if (filterOptions.streetnumber) {
                const filterResult = crmDoc.crmItem?.address?.streetNumber === filterOptions.streetnumber;
                if (filterResult === false) return false;
            }
            if (filterOptions.streetAppendix) {
                const filterResult = crmDoc.crmItem?.address?.streetAppendix === filterOptions.streetAppendix;
                if (filterResult === false) return false;
            }
            if (typeof filterOptions.streetname === 'string') {
                const filterResult = (crmDoc.crmItem?.address?.streetName ?? '') === filterOptions.streetname;
                if (filterResult === false) return false;
            }
            if (typeof filterOptions.city === 'string') {
                const filterResult = (crmDoc.crmItem?.address?.city ?? '') === filterOptions.city;
                if (filterResult === false) return false;
            }
            if (typeof filterOptions.zipCode === 'string') {
                const filterResult = (crmDoc.crmItem?.address?.zipCode ?? '') === filterOptions.zipCode;
                if (filterResult === false) return false;
            }
            if (filterOptions.searchbyaddress) {
                const [street, zipCode, city] = filterOptions.searchbyaddress.split(',');
                if (city) {
                    if (crmDoc.crmItem?.address?.city !== city) {
                        return false;
                    }
                }
                if (zipCode) {
                    if (crmDoc.crmItem?.address?.zipCode !== zipCode) {
                        return false;
                    }
                }
                if (street) {
                    let docStreet = crmDoc.crmItem?.address?.streetName;
                    if (crmDoc.crmItem?.address?.streetNumber) docStreet += ' ' + crmDoc.crmItem?.address?.streetNumber;
                    if (docStreet !== street) {
                        return false;
                    }
                }
            }
            return true;
        });
    }

    const crmItems = await crmItemsQuery.offset(skip).limit(limit).toArray();
    const crmItemsCount = actionOptions?.skipCount ? 0 : await crmItemsQuery.count();

    // if (crmItems.length === 0 && isDeviceOnline()) {
    //     const apiCrmItem = await getCrmItemsFetch({ eventIds: [filterOptions.eventId] }, 0);
    //     if (apiCrmItem.success && apiCrmItem.count && Array.isArray(apiCrmItem.data)) {
    //         return dispatch?.({
    //             type: newCrmItemActionTypes.LOAD_CRMITEM_LIST,
    //             payload: apiCrmItem.data.map((sItem: any) => createNewCrmItemWrapperForDexie(sItem)),
    //         });
    //     }
    // }

    dispatch?.({
        type: newCrmItemActionTypes.LOAD_CRMITEM_LIST,
        payload: crmItems,
    });

    return { items: crmItems.map(c => c.crmItem), count: crmItemsCount };
};

export const clearCrmItemListAction = (dispatch: any) => {
    return dispatch({
        type: newCrmItemActionTypes.LOAD_CRMITEM_LIST,
        payload: [],
    });
};

export const startCrmItemSyncAction = (projectIds: string[]) => (dispatch: any) => {
    dispatch({
        type: newCrmItemActionTypes.UPDATE_CRMITEM_SYNC_DATA,
        payload: {
            state: 'DOWNLOADING',
            lastUpdate: new Date(),
        },
    });
    syncAllCrmItems(dispatch, projectIds);
};

export const createRingDetailsAction = async (crmId: string, projectId: string, payload: any) => {
    const crmItem = await newCRMDb.crmItems.get({ _id: crmId });
    if (crmItem?.crmItem?._id === crmId) {
        if (!Array.isArray(crmItem.crmItem.ringDetails)) crmItem.crmItem.ringDetails = [];
        crmItem.crmItem.ringDetails?.push({
            _id: new ObjectID().str,
            date: tz().tz('Europe/Berlin').toISOString(),
            projectId: projectId,
            userId: window.userabstraction._id as string,
        });
        await newCRMDb.crmItems.put(crmItem);
        await newCRMActionsDb.crmActions.add({
            _id: new ObjectID().str,
            lastChangeDate: new Date(),
            action: {
                _id: crmId,
                type: 'Ring',
                payload: {
                    payload,
                    projectId,
                    crmId,
                },
            },
            synced: false,
            timeOfaction: tz().tz('Europe/Berlin').toISOString(),
        });
        return {
            success: true,
            data: crmItem.crmItem,
        };
    } else {
        throw new Error('cannot find crm entry in dexie');
    }
};

export const changeLeadStatusAction = async (
    crmId: string,
    projectId: string,
    payload: leadStatusChangePayload,
    statusData: ICustomStatuses,
) => {
    try {
        payload = JSON.parse(JSON.stringify(payload));
        const crmItem = await newCRMDb.crmItems.get({ _id: crmId });
        if (crmItem?.crmItem?._id === crmId) {
            const newCrmItem = {
                ...crmItem.crmItem,
                ...payload,
                status: statusData, // dexie needs the populated status
            };
            crmItem.crmItem = newCrmItem;
            await newCRMDb.crmItems.put(crmItem);
            await newCRMActionsDb.crmActions.add({
                _id: new ObjectID().str,
                lastChangeDate: new Date(),
                action: {
                    _id: crmId,
                    type: 'Status',
                    payload: {
                        payload,
                        projectId,
                        crmId,
                    },
                },
                synced: false,
                timeOfaction: tz().tz('Europe/Berlin').toISOString(),
            });
            return {
                success: true,
                data: newCrmItem,
            };
        } else {
            return {
                success: false,
            };
        }
    } catch (error) {
        return {
            success: false,
        };
    }
};

export const updateLeadCustomStatusesAction = async (crmId: string, newCustomStatuses: string[]) => {
    try {
        const crmItem = await newCRMDb.crmItems.get({ _id: crmId });
        if (crmItem?.crmItem?._id === crmId) {
            const newCrmItem = {
                ...crmItem.crmItem,
                customStatuses: newCustomStatuses,
            };
            crmItem.crmItem = newCrmItem;
            await newCRMDb.crmItems.put(crmItem);

            // get doc where action._id equals crmId and action.type equals CustomStatusUpdate
            let existingCrmStatusAction =
                (
                    await newCRMActionsDb.crmActions
                        .filter(action => action.action?._id === crmId && action.action?.type === 'CustomStatusUpdate')
                        .toArray()
                )?.[0] ?? null;

            if (existingCrmStatusAction) {
                existingCrmStatusAction.action.payload.customStatuses = newCustomStatuses;
                await newCRMActionsDb.crmActions.put(existingCrmStatusAction);
            } else {
                await newCRMActionsDb.crmActions.add({
                    _id: new ObjectID().str,
                    lastChangeDate: new Date(),
                    action: {
                        _id: crmId,
                        type: 'CustomStatusUpdate',
                        payload: {
                            customStatuses: newCustomStatuses,
                        },
                    },
                    synced: false,
                    timeOfaction: tz().tz('Europe/Berlin').toISOString(),
                });
            }
            return {
                success: true,
                data: newCrmItem,
            };
        } else {
            return {
                success: false,
            };
        }
    } catch (error) {
        return {
            success: false,
        };
    }
};

export const createCrmUpdateAction = async (crmId: string, updatePayload: any) => {
    const allowedKeys = window.empplanSettings?.offlineSettings?.crmEditableFields ?? [];

    const filteredPayload: any = {};
    for (const key of allowedKeys) {
        const value = _.get(updatePayload, key);
        if (value !== undefined) {
            _.set(filteredPayload, key, value);
        }
    }

    const crmItem = await newCRMDb.crmItems.get({ _id: crmId });
    if (crmItem?.crmItem?._id === crmId) {
        if (allowedKeys.length === 0) {
            return {
                success: true,
                data: crmItem.crmItem,
            };
        }

        _.merge(crmItem.crmItem, filteredPayload);

        await newCRMDb.crmItems.put(crmItem);
        await newCRMActionsDb.crmActions.add({
            _id: new ObjectID().str,
            lastChangeDate: new Date(),
            action: {
                _id: crmId,
                type: 'Update',
                payload: filteredPayload,
            },
            synced: false,
            timeOfaction: tz().tz('Europe/Berlin').toISOString(),
        });
        return {
            success: true,
            data: crmItem.crmItem,
        };
    } else {
        throw new Error('cannot find crm entry in dexie');
    }
};

export const saveAllSalesItemFromCrmEvent = async (eventId: string, crmId?: string, projectId?: string) => {
    try {
        if (!crmId) throw new Error('crmId is required');
        const salesItemsDixie = await newSalesItemDb.salesItems.where('event').equals(eventId).toArray();
        const salesItems = salesItemsDixie.map(s => ({ ...s.salesItem }));
        const existingAction = await newCRMActionsDb.crmActions.get(eventId);

        const actionData: any = {
            _id: eventId,
            lastChangeDate: new Date(),
            action: {
                _id: crmId,
                type: 'CRMSales',
                projectId: projectId,
                payload: salesItems,
            },
            synced: false,
            timeOfaction: tz().tz('Europe/Berlin').toISOString(),
        };

        if (existingAction) {
            await newCRMActionsDb.crmActions.update(eventId, actionData);
        } else {
            await newCRMActionsDb.crmActions.add(actionData);
        }
        localStorage.removeItem(crmId);
    } catch (error) {
        console.error('Error saving CRM sales item:', error);
    }
};
