import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Chip, Stack, TextField, FormLabel } from '@mui/material';
import { ReportConfigurationContext } from '..';
import ToolTipComponent from '../ToolTipComponent';
import { t } from 'i18next';
import { requireHelper } from '../utils/requireHelper';
import { useTranslation } from 'react-i18next';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { getLocationCoordinates } from '../../../hooks/useFileUpload/fileHelper';
import { useConfirm } from '../../../components-ui/useConfirm';
import AutoCompletePlaceGeo from './AutoCompletePlacesGeo';
import { DetectOnline } from '../../../hooks/detectOnline';

interface StringSmallFieldProps {
    field: CustomReportField;
    onChange: any;
    reportData: ReportData;
    type: React.HTMLInputTypeAttribute | undefined;
    inputProps?: any;
    InputProps?: any;
}

function GplacesFieldComponent({ field, type, reportData, onChange, inputProps, InputProps }: StringSmallFieldProps) {
    const { t } = useTranslation();
    const { isDisabled } = useContext(ReportConfigurationContext);
    //const [placesList, setPlacesList] = React.useState<any>([]);
    const [placesList, setPlacesList] = React.useState<{ [key: string]: any }>({});
    let existdata = reportData ? reportData[field?.config?._id] : [];
    const [placesListall, setPlacesListall] = React.useState<any>(existdata || []);
    const [location, setLocation] = React.useState<any>('');

    const [latlong, setLatLong] = React.useState<{ lat: any; long: any }>({ lat: 0, long: 0 });
    const [isnearby, setIsnearby] = React.useState(false);
    const [preloading, setPreloading] = React.useState(false);
    const [cancelOnly, setCancelOnly] = React.useState(false);

    //const requiredCondition = requireHelper(field, reportData);
    const [clear, setClear] = React.useState(false);
    const [ConfirmationDialog, confirm] = useConfirm();
    //for pwa
    const { isOnline } = DetectOnline();

    const addLocation = () => {
        if (Object.keys(placesList).length !== 0) {
            let pall = placesListall;
            if (!placesListall.includes(placesList)) {
                pall.push(placesList);
            }
            setPlacesListall(pall);
            //console.log(placesListall);
            onChange(field?.config?._id, placesListall);
            setPlacesList({});
            setLocation({});
            setClear(true);
            setIsnearby(false);
        }
    };

    const handleDelete = async (itm: any) => {
        setCancelOnly(false);
        if (await confirm(t('##Delete'), t`##DoYouWantToProceed`)) {
            let placesarr = placesListall;
            const index = placesarr.indexOf(itm);
            placesarr.splice(index, 1);
            setPlacesList(placesarr);
            onChange(field?.config?._id, placesListall);
        }
    };
    const setCurrentLocation = async () => {
        setPreloading(true);
        let currentLocation: any = {
            latitude: 0,
            longitude: 0,
            isGpsoffinFeild: true,
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        currentLocation = await getLocationCoordinates().catch(async err => {
            console.log('error gps', err);
            setPreloading(false);
            setCancelOnly(true);
            if (err) {
                if (await confirm(t('##ErrorWhileFetchGPSSignal'), t`##PleaseActivateGPSSettinginBrowser`)) {
                }
            }
            return null;
        });

        setLatLong({
            lat: currentLocation.latitude,
            long: currentLocation.longitude,
        });
        setIsnearby(true);
        setPreloading(false);
    };

    return (
        <div>
            <ConfirmationDialog cancelOnly={cancelOnly}></ConfirmationDialog>
            <FormLabel id='gplaces-buttons-group-label'>
                {field.config.slug ? t(field.config.slug) : field.config.name}
                <br></br>
                <Box display='flex' alignItems='center'>
                    <MyLocationIcon
                        onClick={() => {
                            if (isDisabled) return;
                            setCurrentLocation();
                        }}
                        style={{
                            pointerEvents: isOnline ? 'all' : 'none',
                            cursor: 'pointer',
                            padding: '5px 5px 0px 0px',
                            marginTop: '5px',
                            color: isDisabled ? 'grey' : isOnline ? (isnearby ? 'rgb(36,192,177)' : 'grey') : 'grey',
                        }}
                    ></MyLocationIcon>

                    <div style={{ width: '80%' }}>
                        <AutoCompletePlaceGeo
                            defaultValue={location}
                            setListFilters={setPlacesList}
                            istime={field.config.fieldObject?.GooglePlaces?.isTimeEnable}
                            latlong={latlong}
                            clear={clear}
                            isnearby={isnearby}
                            preloading={preloading}
                            setIsnearby={setIsnearby}
                            setClear={setClear}
                            isDisabled={isDisabled}
                            isOnline={isOnline}
                        />
                    </div>
                    <Button
                        disabled={isDisabled || !isOnline}
                        variant='contained'
                        color='secondary'
                        size='small'
                        sx={{ ml: 1, whiteSpace: 'nowrap' }}
                        onClick={addLocation}
                    >
                        {t('##AddLocation')}
                    </Button>
                    {field.config?.toolTipTxt !== '' && (
                        <ToolTipComponent
                            enableHintOnClick={field.config.enableHintOnClick}
                            tooltipText={field.config.toolTipTxt}
                        />
                    )}
                </Box>
                <br></br>
                <Stack direction='column' spacing={1}>
                    {placesListall.map(
                        (
                            place: {
                                formatted: any;
                            },
                            i: any,
                        ) => (
                            <Chip
                                key={i}
                                label={place.formatted}
                                variant='outlined'
                                disabled={isDisabled}
                                onDelete={() => handleDelete(place)}
                            />
                        ),
                    )}
                </Stack>
            </FormLabel>
        </div>
    );
}

export default GplacesFieldComponent;
