import React from 'react';
import { Alert, AlertProps, Portal, Snackbar } from '@mui/material';

export const EmpplanSnackbarContext = React.createContext<{ createToast: ({ type, message }: ToastPayload) => void }>({
    createToast: () => {
        console.log('Something wrong with toast implementation');
    },
});

interface EmpplanSnackbarProps {}

interface ToastPayload {
    type: AlertProps['severity'];
    message: string | JSX.Element;
}

function EmpplanSnackbarWrapper(props: React.PropsWithChildren<EmpplanSnackbarProps>) {
    const [openToast, setOpenToast] = React.useState<ToastPayload | null>(null);
    const handleCloseToast = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(null);
    };

    const createToast = ({ type, message }: ToastPayload) => {
        // console.log('creating toast', { type, message });
        setOpenToast({ type, message });
    };

    return (
        <EmpplanSnackbarContext.Provider value={{ createToast }}>
            {props.children}
            <Portal>
                {openToast?.type && (
                    <Snackbar
                        open={!!openToast}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        autoHideDuration={5000}
                        onClose={handleCloseToast}
                    >
                        <Alert onClose={handleCloseToast} severity={openToast?.type} sx={{ width: '100%' }}>
                            {openToast?.message}
                        </Alert>
                    </Snackbar>
                )}
            </Portal>
        </EmpplanSnackbarContext.Provider>
    );
}

export default EmpplanSnackbarWrapper;
