import React from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addEditLocationContactDetails, createPromoterLocationContactChannel } from '../../Models/NewEvent/actions';

function LocationContactComponent({
    contactList,
    eventId,
    editMode = false,
}: {
    contactList: any;
    eventId?: string;
    editMode?: boolean;
}) {
    const { t } = useTranslation();
    const dispatch: any = useDispatch();
    const navigate = useHistory();
    // const locationContactChannel: any = useSelector((store: any) => store?.event?.promoterLocationContactChannel);

    const navigateToMessanger = (userid: string) => {
        dispatch(createPromoterLocationContactChannel(userid));
        window?.reactGA?.event({
            action: 'Promoter added channel with location contact',
            label: 'PromoterLocationContactChannelCreate',
            category: 'Messenger',
        });
        navigate.push('/messenger');
    };

    const navigateToLocationContactEdit = (contact: any) => {
        if (editMode) {
            dispatch(addEditLocationContactDetails(contact));
            navigate.push(`/${eventId}/addLocationContact/edit`);
        }
    };

    return (
        <Grid item xs={12}>
            <Button sx={{ color: 'black', ml: 1 }} startIcon={<PersonIcon sx={{ mr: 2 }} />}>
                {t('##LocationContacts')}
            </Button>
            <br />
            <Box ml={7}>
                {Array.isArray(contactList) &&
                    contactList.length > 0 &&
                    contactList.map((contact: any) => (
                        <Box key={contact._id} display='flex' justifyContent='space-between' alignItems='center'>
                            <Typography
                                onClick={() => navigateToLocationContactEdit(contact)}
                                color={editMode ? 'secondary' : ''}
                                sx={{ cursor: editMode ? 'pointer' : '' }}
                            >
                                {contact?.firstName + ' ' + contact?.lastName}
                            </Typography>
                            <Box display='flex' gap={2} justifyContent='center' alignItems='center'>
                                <IconButton color='secondary' onClick={() => navigateToMessanger(contact._id)}>
                                    <ChatBubbleIcon />
                                </IconButton>

                                {contact.email && contact.email !== '' && (
                                    <IconButton
                                        color='secondary'
                                        onClick={() => (window.location.href = `mailto:${contact.email}`)}
                                    >
                                        <EmailIcon />
                                    </IconButton>
                                )}
                                {contact.tel && contact.tel !== '' && (
                                    <IconButton
                                        color='secondary'
                                        onClick={() => (window.location.href = `tel:${contact.tel}`)}
                                    >
                                        <LocalPhoneIcon />
                                    </IconButton>
                                )}
                                {contact.mobile && contact.mobile !== '' && (
                                    <IconButton
                                        color='secondary'
                                        onClick={() => (window.location.href = `tel:${contact.mobile}`)}
                                    >
                                        <PhoneAndroidIcon />
                                    </IconButton>
                                )}
                            </Box>
                        </Box>
                    ))}
            </Box>
        </Grid>
    );
}

export default LocationContactComponent;
