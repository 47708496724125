import React, { useEffect, useRef, useState, useContext } from 'react';
import {
    Box,
    LinearProgress,
    Stack,
    IconButton,
    Collapse,
    Checkbox,
    Grid,
    alpha,
    Button,
    Typography,
} from '@mui/material';
import API from '../../../config/api';
import { Channel, Message } from '../types';
import SingleMessageBox from '../components/SingleMessageBox';
import { PWAContext } from '../../../PWA/pwaContext';
import { ArrowBackSharp, DeleteSharp, TagSharp } from '@mui/icons-material';
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';
import { EmpplanSnackbarContext } from '../../../modules/Snackbar';
import { useTranslation } from 'react-i18next';
import DeviceDetect from './../../../hooks/deviceDetect';

interface InboxTypes {
    inboxChannelId: string;
    reloadMessage?: null | Message;
    setreloadMessage?: (data: Message | null) => void;
    selectMode?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    disableScrolling?: boolean;
    isReadOnly?: boolean;
    setOpenChannel?: (result: Channel) => void;
}

function Inbox({
    inboxChannelId,
    reloadMessage,
    setreloadMessage,
    selectMode,
    disableScrolling,
    isReadOnly,
    setOpenChannel,
}: InboxTypes) {
    const snackbarTools = useContext(EmpplanSnackbarContext);
    const { t } = useTranslation();
    const [parentSelectMode, setParentSelectMode] = selectMode || [undefined, undefined];
    const [messages, setMessages] = useState<Message[]>([]);
    const [selectedMessages, setSelectedMessages] = useState<Message[]>([]);
    const [messageLoading, setMessageLoading] = useState<boolean>(false);
    const [hasMoreMessages, setHasMoreMessages] = useState<boolean>(true);
    const [scrollPending, setScrollPending] = useState<boolean>(false);
    const messageContainerRef = useRef<HTMLDivElement | null>(null);
    const pwaContext = useContext(PWAContext);
    const socket = window.socketio;
    const [isMobile, setIsMobile] = useState(DeviceDetect().isMobile);
    useEffect(() => {
        if (DeviceDetect().isMobile === false) {
            const handleResize = () => {
                if (window.innerWidth < 900) {
                    setIsMobile(true);
                } else {
                    setIsMobile(false);
                }
            };
            handleResize();
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    });

    const fetchMessages = async (skip: number) => {
        if (messageLoading) return;
        setMessageLoading(true);
        // console.log('Loading more messages');

        try {
            const response = await API.get(`/chatmessages?channel=${inboxChannelId}&limit=15&skip=${skip}`);
            const newMessages = await response.json();

            if (!Array.isArray(newMessages)) throw new Error('bad response');

            if (skip === 0) {
                setMessages(prevMsg => [...newMessages]);
                setScrollPending(true);
            } else {
                setMessages(prevMsg => [...newMessages, ...prevMsg]);
            }

            if (newMessages.length < 15) {
                setHasMoreMessages(false);
            }
        } catch (error) {
            console.error('failed to fetch messages', error);
        }

        setMessageLoading(false);
    };

    useEffect(() => {
        socket &&
            socket.on('chat:message', (message: Message) => {
                if (inboxChannelId === message.channel) {
                    fetchMessages(0);
                }
            });

        return () => {
            socket && socket.off('chat:message');
        };
    }, [socket, inboxChannelId]);

    const scrollMessagesToBottom = () => {
        messageContainerRef.current?.scrollTo({
            top: messageContainerRef.current.scrollHeight,
            left: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (reloadMessage) {
            setMessages(prevMsg => [...prevMsg, reloadMessage]);
            setScrollPending(true);
            setreloadMessage && setreloadMessage(null);
        }
    }, [reloadMessage]);

    useEffect(() => {
        fetchMessages(0);
        setreloadMessage && setreloadMessage(null);
    }, [inboxChannelId]);

    useEffect(() => {
        // updating the read status whenever message changes
        const userId = window?.userabstraction?._id;
        messages.forEach((message: Message) => {
            if (userId && !message.readBy?.includes(userId)) {
                API.put(`/chatmessages/read/${message._id}`, {})
                    .then(() => {
                        // we will do nothing here as of now..
                    })
                    .catch(err => {
                        console.log(
                            err,
                            'failed to mark as read, no action needed. will update the read status in the next message update',
                        );
                    });
            }
        });
    }, [messages]);

    useEffect(() => {
        if (scrollPending) {
            scrollMessagesToBottom();
            setScrollPending(false);
        }
    }, [scrollPending]);

    useEffect(() => {
        setSelectedMessages && setSelectedMessages([]);
    }, [parentSelectMode]);

    const handleLoadMoreMessages = () => {
        fetchMessages(messages.length);
    };

    const toggleMessageSelection = (message: Message) => {
        const found = selectedMessages.find(m => m._id === message._id);
        if (found) {
            // then we remove it
            const newSelection = selectedMessages.filter(m => m._id !== message._id);
            setSelectedMessages(newSelection);
        } else {
            // then we add it
            const newSelection = [...selectedMessages, message];
            setSelectedMessages(newSelection);
        }
    };

    const handleDeleteSelected = (selectedMessages: Message[]) => {
        (async function () {
            try {
                for await (const message of selectedMessages) {
                    await fetch('/chatmessages/' + message._id, {
                        method: 'DELETE',
                        credentials: 'include',
                        headers: {
                            Accept: 'application/json, text/plain, */*',
                        },
                    });
                }
                snackbarTools.createToast({ type: 'success', message: t`##DeleteMessagesSuccess` });
            } catch (error) {
                console.error(error);
                snackbarTools.createToast({ type: 'error', message: t`##DeleteMessagesFailed` });
            }
            fetchMessages(0);
        })();
    };

    const handleCopySelected = (selectedMessages: Message[]) => {
        try {
            const copyContent = selectedMessages.map(m => m.message).join('\n');
            navigator.clipboard.writeText(copyContent).then(
                () => {
                    /* success */
                    // console.log('Copied to clipboard');
                    snackbarTools.createToast({ type: 'success', message: t`##CopyMessagesSuccess` });
                },
                () => {
                    /* failure */
                    console.log('Failed copying to clipboard');
                    snackbarTools.createToast({ type: 'error', message: t`##CopyMessagesFailed` });
                },
            );
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Box
            component='div'
            ref={messageContainerRef}
            height={pwaContext?.pwa ? '80vh' : '90%'}
            sx={{ overflowX: 'none', overflowY: disableScrolling ? 'none' : 'scroll' }}
            // onScroll={() => {
            //     if (messageContainerRef.current?.scrollTop === 0 && hasMoreMessages) {
            //         console.log('Triggering load more messages');
            //         handleLoadMoreMessages();
            //     }
            // }}
        >
            {messageLoading && <LinearProgress />}
            {parentSelectMode && (
                <Box
                    key='bottom-message-helper'
                    sx={theme => ({
                        position: 'absolute',
                        top: pwaContext?.pwa ? '65px' : '0px',
                        width: isMobile ? '100%' : '75%',
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                    })}
                >
                    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} height='50px'>
                        <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
                            <IconButton
                                color='inherit'
                                aria-label='close'
                                sx={{ ml: 2 }}
                                onClick={() => {
                                    setParentSelectMode && setParentSelectMode(false);
                                }}
                            >
                                <ArrowBackSharp />
                            </IconButton>
                            {selectedMessages.length > 0 && (
                                <Typography key='message-select-count'>
                                    {selectedMessages.length} {t`##Selected`}
                                </Typography>
                            )}
                        </Stack>
                        <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                            <IconButton
                                color='inherit'
                                aria-label='copy'
                                disabled={
                                    selectedMessages.length === 0 || !!selectedMessages.find(m => m.type?.includes('file'))
                                }
                                onClick={() => {
                                    handleCopySelected(selectedMessages);
                                    setParentSelectMode && setParentSelectMode(false);
                                }}
                            >
                                <ContentCopySharpIcon />
                            </IconButton>
                            <IconButton color='inherit' aria-label='create-channel' disabled>
                                <TagSharp />
                            </IconButton>
                            <IconButton
                                color='inherit'
                                aria-label='delete'
                                disabled={
                                    selectedMessages.length === 0 ||
                                    !!selectedMessages.find(m => m.sender._id !== window.userabstraction._id)
                                }
                                onClick={() => {
                                    handleDeleteSelected(selectedMessages);
                                    setParentSelectMode && setParentSelectMode(false);
                                }}
                            >
                                <DeleteSharp />
                            </IconButton>
                        </Stack>
                    </Stack>
                </Box>
            )}
            {hasMoreMessages && <Button fullWidth onClick={handleLoadMoreMessages}>{t`##LoadMoreMessages`}</Button>}
            {messages.map(message => {
                return (
                    <Grid container key={message._id}>
                        <Grid item xs='auto'>
                            <Collapse orientation='horizontal' in={parentSelectMode}>
                                <Checkbox
                                    sx={{ mt: '30px' }}
                                    checked={!!selectedMessages.find(m => m._id === message._id)}
                                    onChange={() => {
                                        toggleMessageSelection(message);
                                    }}
                                />
                            </Collapse>
                        </Grid>
                        <Grid
                            item
                            xs
                            sx={theme => ({
                                transition: 'background 0.3s',
                                background: selectedMessages.find(m => m._id === message._id)
                                    ? alpha(theme.palette.secondary.main, 0.3)
                                    : undefined,
                                '&:hover': {
                                    background: alpha(theme.palette.secondary.main, 0.5),
                                },
                            })}
                        >
                            <div onClick={parentSelectMode ? () => toggleMessageSelection(message) : undefined}>
                                <SingleMessageBox
                                    message={message}
                                    isReadOnly={isReadOnly}
                                    setOpenChannel={setOpenChannel}
                                />
                            </div>
                        </Grid>
                    </Grid>
                );
            })}
        </Box>
    );
}

export default Inbox;
