import axios, { AxiosProgressEvent } from 'axios';
import API from '../../config/api';

export const uploadFile: (
    payload: FileReaderType,
    remotePath: string,
    handleUploadProgress?: (arg0: AxiosProgressEvent) => void,
    mapFilenameFromUploadResponse?: (jsonResponse: any) => string | undefined,
) => Promise<string> = async (payload, remotePath, handleUploadProgress, mapFilenameFromUploadResponse) => {
    const response = await axios.post(remotePath, payload, {
        withCredentials: true, // for local dev
        // for local dev
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
        },
        onUploadProgress(progressEvent) {
            // console.log({ progressEvent });
            handleUploadProgress && handleUploadProgress(progressEvent);
        },
    });
    // console.log('Upload response', { response });
    if (response.status !== 200) {
        console.log('Error uploading file', { response });
        throw new Error('Failed to upload');
    }
    const responseJson: { filename?: string } = response.data;

    if (mapFilenameFromUploadResponse) {
        const filename = mapFilenameFromUploadResponse(responseJson);
        if (!filename) {
            console.log('Error uploading file - no string from mapFilenameFromUploadResponse using response data', {
                response,
            });
            throw new Error('Failed to upload');
        }
        return filename;
    } else {
        if (!responseJson.filename) {
            console.log('Error uploading file - no filename in response data', { response });
            throw new Error('Failed to upload');
        }
        return responseJson.filename;
    }
};

export const uploadFileDexie: (
    payload: FileReaderType,
    remotePath: string,
    pwaContext: PWAContextPayload,
) => Promise<string> = async (payload, remotePath, pwaContext) => {
    return new Promise((resolve, reject) => {
        if (pwaContext && pwaContext.pwa && pwaContext.pwaHelpers.createNewFileSystemAction) {
            pwaContext.dispatch(
                pwaContext.pwaHelpers.createNewFileSystemAction(
                    {
                        fileSystemPayload: payload,
                        context: {
                            eventId: payload.context.contextId,
                            reportType: payload.context.contextType,
                            reportId: 'teststring',
                            remotePath: remotePath,
                        },
                    },
                    async (newFileId: string) => {
                        resolve('dexie.' + newFileId);
                    },
                ),
            );
        }
        reject('Where is the pwa context? it should not have reached here');
    });
};

export const getFileFromServer = (fileId: string) => {
    return API.get(`/upload/filesystem/${fileId}`)
        .then(response => response.json())
        .then(jsonResponse => {
            if (!jsonResponse && jsonResponse._id) {
                console.error('Invalid filesystem response at getFileFromServer', { jsonResponse });
                throw new Error('Failed to get file from server - getFileFromServer');
            }
            return jsonResponse;
        });
};

export const getFileSystemDataFromDexie = async (fileId: string, pwaContext: any) => {
    if (pwaContext?.pwaHelpers?.getFileSystemDataFromDexie) {
        // get file and return
        return await pwaContext.pwaHelpers.getFileSystemDataFromDexie(fileId.split('.')[1]);
    }
    throw new Error('Failed to get file from dexie - getFileFromDexie');
};

export const deleteFileFromServer = (path: string) => {
    try {
        API.delete(path);
    } catch (err) {
        console.error('Failed to delete file from server', err);
    }
};

export const MINIFY_IMAGES_LOCALSTORAGE_KEY = 'minifyImages';
export const shouldImageOptimize = (options?: { skipFrontendMinify?: boolean }) => {
    // console.log("minify options", options);
    if (options?.skipFrontendMinify) {
        return false;
    }
    if (window.empplanSettings?.imageOptimizePWA) {
        const val = localStorage.getItem(MINIFY_IMAGES_LOCALSTORAGE_KEY);
        if (val === null || val === 'true') return true;
    }
    return false;
};
