import {SmallEvent} from "../Events/SmallEventType";


export enum UserTodoActionTypes  {
    LOADING= "USERTODO/LOADING",
    GETMYTODOS="USERTODO/GET/MYTODO"
};

export interface UserTodoLoading {
    type:UserTodoActionTypes.LOADING,
    loading:boolean
}

export interface GetMyTodoAction {
    type: UserTodoActionTypes.GETMYTODOS,
    todos:SmallEvent[]|null
}


/**export interface LoadingTodoAction{
    type: MenuActionTypes.MODULELOADED,
    todos:string
}**/



export interface UserTodoStates {
    readonly loading: boolean;
    readonly todos:SmallEvent[]|null;
}


export type UserTodoAction =
    | GetMyTodoAction
    | UserTodoLoading
