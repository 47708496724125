import React from 'react';
import { eventPendingSyncDataCount } from '../../Models/NewSync/actions';

function useEventSyncStatus(eventId?: string) {
    const [syncCount, setSyncCount] = React.useState(-1);

    const refreshCount = () => {
        if (eventId) {
            eventPendingSyncDataCount(eventId).then(count => {
                setSyncCount && setSyncCount(count || 0);
            });
        }
    };

    React.useEffect(() => {
        setSyncCount(-1);
        refreshCount();
        const eventSyncCheckInterval = setInterval(() => {
            // console.log('Refreshing pending event sync count', eventId);
            refreshCount();
        }, 5000);

        return () => {
            // console.log('Clearing event sync check timeout', eventId);
            eventSyncCheckInterval && clearInterval(eventSyncCheckInterval);
        };
    }, [eventId]);

    return [syncCount];
}

export default useEventSyncStatus;
