import {
    DataGridProProps,
    GridApi,
    GridApiRef,
    GridColumnResizeParams,
    GridColumnVisibilityModel,
    GridFilterModel,
    GridInitialState,
    GridSortModel,
    GridState,
    GridStateColDef,
    useGridApiRef,
} from '@mui/x-data-grid-pro';
import { createContext, memo, Ref, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IGridState, ISavedColDef, ParamsContext } from './params';
import { createSettings, getOrCreateSettings, IViewSettings, updateSettings } from '../../../utils/settings';
import { columnDefaults } from '../hooks/model';
import { mongooseSortStringToGridSortModel } from '../utils';
import { ColumnsPanelContext, ICustomContext, ISavedColumnContextState, JoiSavedSettingsContext } from './columns';

/**
 *  This Interface will contain all the current configuration of the grid
 */
export interface IGridConfig {
    gridApiRef: GridApiRef;
    fallbackGridProps: Partial<DataGridProProps>; // hardcoded default grid props
    filterModel: GridFilterModel;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel; // which columns are hidden or visible

    onFilterModelChanged: (filterModel: GridFilterModel) => void;
    onSortModelChanged: (sortModel: GridSortModel) => void;
    columnWidthModel: { [key: string]: number }; // user defined column widths
    setColumnVisibilityModel: (arg: GridColumnVisibilityModel) => void;
    searchQuery: URLSearchParams;
    viewSavedSettings: IViewSettings<IGridState>;
    configGridStyles: Record<string, any>; // extra styles for grid, passed to `sx` attribute of muiDataGrid
    configGridProps: Partial<DataGridProProps>; // extra gridProps, passed to grid like {...gridProps}
}
const fallbackGridProps: Partial<DataGridProProps> = {
    columnBuffer: 50,
    paginationMode: 'server',
    filterMode: 'server',
    sortingMode: 'server',
};

export const useSyncViewSettings = (gridApiRef: React.MutableRefObject<GridApi>) => {
    const settingsVersion = '2.0';
    const gridApi = gridApiRef.current.state ? gridApiRef.current : undefined;
    const [viewSavedSettings, setViewSavedSettings] = useState<IViewSettings<ISavedColumnContextState>>();
    const { viewName, moduleName } = useContext(ParamsContext);
    const { restoreSavedState, gridStateUpdated, getGridState } = useContext(ColumnsPanelContext);

    function loadSettings(settings: IViewSettings<ISavedColumnContextState>) {
        const validate = JoiSavedSettingsContext.validate(settings.settingObject);
        if (!validate.error) {
            restoreSavedState(settings.settingObject);
        } else {
            console.error('error loading grid settings', validate.error);
        }
        setViewSavedSettings(settings);
    }

    const getSettingsKey = () => `SETTINGS:${moduleName}::${viewName}`;

    useEffect(() => {
        const localSaved = localStorage.getItem(getSettingsKey());
        if (localSaved) {
            const settings = JSON.parse(localSaved) as IViewSettings<ISavedColumnContextState>;
            settings.synced = false;
            loadSettings(settings);
        }
        // get the saved settings for this view and module
        if (viewName && moduleName) {
            getOrCreateSettings<ISavedColumnContextState>({
                version: settingsVersion,
                view: viewName as string,
                module: moduleName as string,
            }).then(settings => {
                settings.synced = true;
                loadSettings(settings);
            });
        }
    }, [viewName, moduleName]);

    const stateChangeHandler = () => {
        const gridState = getGridState();
        if (!viewSavedSettings?.synced) return;
        const settings = { _id: viewSavedSettings._id, settingObject: gridState };
        localStorage.setItem(getSettingsKey(), JSON.stringify(settings));
        updateSettings<ISavedColumnContextState>({ _id: viewSavedSettings?._id as string, settingObject: gridState }).then(
            settings => {
                settings.synced = true;
                localStorage.setItem(getSettingsKey(), JSON.stringify(settings));
            },
        );
    };

    useEffect(() => {
        const stateChangeTimeout = setTimeout(stateChangeHandler, 1500);
        return () => {
            clearTimeout(stateChangeTimeout);
        };
    }, [gridApi, gridStateUpdated]);

    return { viewSavedSettings };
};

export const ConfigContext = createContext<IGridConfig>({} as IGridConfig);
export const ConfigContextProvider = ({ children }: any) => {
    const viewSavedSettings = {};
    const { gridApiRef } = useContext(ColumnsPanelContext);
    const gridApi = gridApiRef.current.state ? gridApiRef.current : undefined;
    const [sortModel, setSortModel] = useState<GridSortModel>([] as GridSortModel);
    const [filterModel, setFilterModel] = useState<GridFilterModel>({} as GridFilterModel);

    const [configGridStyles, setConfigGridStyles] = useState<{ [key: string]: string }>({});
    const [configGridProps, setConfigGridProps] = useState<Partial<DataGridProProps>>({});
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});
    useSyncViewSettings(gridApiRef);
    const { extraOptions, data } = useContext(ParamsContext);
    useEffect(() => {
        const element = gridApi?.rootElementRef?.current;
        // don't break in Node.js (SSR), jest/jsdom, and browsers that don't support ResizeObserver
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (window.ResizeObserver == null) return;
        if (extraOptions.touchBottom && element && window.ResizeObserver) {
            const resizeObserver = new ResizeObserver(entries => {
                // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
                window.requestAnimationFrame(() => {
                    if (!Array.isArray(entries) || !entries.length) {
                        return;
                    }
                    const gridTop = element.getBoundingClientRect().top;
                    const style = {
                        height: window.innerHeight - gridTop > 0 ? 'calc(100vh - ' + gridTop + 'px)' : '400px',
                        minHeight: '400px',
                    };
                    const props = {
                        autoHeight: false,
                    };
                    if (style.height !== configGridStyles.height) {
                        setConfigGridStyles(style);
                        setConfigGridProps(props);
                    }
                });
            });
            resizeObserver.observe(element);
            return () => {
                resizeObserver.unobserve(element);
            };
        }
    }, [extraOptions.touchBottom, gridApi]);
    useEffect(() => {
        // work to remove sortModel when filter?.order is abset
        const sortString = data?.filters?.order as string;
        if (sortString) {
            setSortModel(sortModel => [...sortModel, ...mongooseSortStringToGridSortModel(sortString)]);
        }
    }, [data?.filters?.order]);
    const contextValue = useMemo(
        () =>
            ({
                fallbackGridProps,
                filterModel,
                sortModel,
                onSortModelChanged: setSortModel,
                onFilterModelChanged: setFilterModel,
                gridApiRef,
                columnVisibilityModel,
                viewSavedSettings,
                setColumnVisibilityModel,
                configGridStyles,
                configGridProps,
            } as IGridConfig),
        [filterModel, sortModel, columnVisibilityModel, gridApi, configGridStyles, configGridProps],
    );
    return <ConfigContext.Provider value={contextValue}>{children}</ConfigContext.Provider>;
};

/***** UTIL METHODS *****/

const extractSavableFields = (columns: GridStateColDef[]) =>
    columns.map(({ hide, field, headerName, width, headerAlign, headerClassName }) => ({
        field,
        headerName,
        width,
        headerAlign,
        headerClassName,
        hide,
    }));
