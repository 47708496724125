import React from 'react';
import TargetDefinitionsControl from './TargetDefinitionsHandler';
import TargetDefinitionsAutocomplete from './TargetDefinitionsAutocomplete';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Divider,
    IconButton,
    Paper,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import API from '../../config/api';
import { ExpandMore, RefreshSharp, EmojiEventsSharp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { hasEventAddRole } from '../../utils/helpers/roleHelper';
import { useConfirm } from '../../components-ui/useConfirm';
import EmpplanSnackbarWrapper, { EmpplanSnackbarContext } from '../Snackbar';
import { PWAContext } from '../../PWA/pwaContext';
import { DetectOnline } from '../../hooks/detectOnline';
import TargetsCard from '../SuccessDashboard/Targets';

interface TargetDefinitionsProps {
    payload?: any; // this is the event/eventgroup
    type: 'event' | 'eventgroup';
    triggerUpdateCount?: number;
}

function TargetDefinitionsWrapper(props: TargetDefinitionsProps) {
    return (
        <EmpplanSnackbarWrapper>
            <TargetDefinitions {...props} />
        </EmpplanSnackbarWrapper>
    );
}

function TargetDefinitions({ payload, type, triggerUpdateCount }: TargetDefinitionsProps) {
    const { t } = useTranslation();
    const { isOnline } = DetectOnline();
    const snackbarTools = React.useContext(EmpplanSnackbarContext);
    const [targetProfile, setTargetProfile] = React.useState<TargetDefinitionsProfile | null>(null);
    const [changed, setChanged] = React.useState<boolean>(true);
    const [accordianExpended, setAccordianExpended] = React.useState(false);
    const [refreshCount, setRefreshCount] = React.useState(0);
    const [ConfirmationDialog, confirm] = useConfirm();
    const isAdmin = hasEventAddRole();

    React.useEffect(() => {
        if (targetProfile === null) {
            // init
            if (payload?.meta?.targets?._id) {
                setTargetProfile(payload?.meta?.targets);
            }
        }
    }, [payload]);

    if (!payload && !type) {
        return <div>TargetDefinitions - bad implementation</div>;
    }

    const handleSaveTargets = async () => {
        try {
            const response = await API.post(`/targetdefinitionsprofile/savetargets`, {
                _id: payload._id,
                targets: targetProfile,
                type: type,
            });
            const jsonData = await response.json();
            if (jsonData?.success && jsonData?.data) {
                setChanged && setChanged(false);
                snackbarTools.createToast({ type: 'success', message: `##Success` });
            }
        } catch (error) {
            // handle error here
            console.error(error);
            snackbarTools.createToast({ type: 'error', message: `##Failed` });
        }
    };

    const handleApplyAll = async () => {
        try {
            const response = await API.post(`/targetdefinitionsprofile/applytargets/${payload._id}`, {});
            const jsonData = await response.json();
            if (jsonData?.success && jsonData?.data) {
                snackbarTools.createToast({ type: 'success', message: `##Success` });
            } else {
                throw new Error('failed to apply targets');
            }
        } catch (error) {
            // handle error here
            console.error(error);
            snackbarTools.createToast({ type: 'error', message: `##Failed` });
        }
    };

    return (
        <Box px={type === 'event' ? 2 : undefined}>
            {type === 'event' && targetProfile && (
                <Box mx={2} display='flex' justifyContent='space-between' alignItems='center' gap={1}>
                    <Box display='flex' alignItems='center' gap={1}>
                        <EmojiEventsSharp />
                        <Typography variant='h5' fontWeight={500}>
                            {t('##Targets')}
                        </Typography>
                    </Box>
                    <Tooltip title={t('##Refresh')}>
                        <IconButton
                            disabled={!isOnline}
                            onClick={() => {
                                setRefreshCount(s => s + 1);
                            }}
                        >
                            <RefreshSharp />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}

            {type === 'eventgroup' && isAdmin && (
                <TargetDefinitionsAutocomplete
                    key='TargetDefinitionsAutocomplete'
                    projectId={payload?.project_id?._id || payload?.project_id}
                    value={targetProfile}
                    onChange={async (profile: TargetDefinitionsProfile) => {
                        if (await confirm(t('##Change'), t('##AreYouSureYouWantToChangeTheSelectedTargetProfile'))) {
                            setTargetProfile(profile);
                            setChanged(true);
                        }
                    }}
                />
            )}

            {type === 'eventgroup' && <ConfirmationDialog key='TargetProfileChangeConfirmationDialog' />}

            {targetProfile && isAdmin && (
                <Accordion
                    key='targets-defs-accordian'
                    sx={{ my: 1 }}
                    expanded={type === 'eventgroup' || accordianExpended}
                    defaultExpanded={type === 'eventgroup'}
                    onChange={
                        type === 'eventgroup'
                            ? () => {}
                            : (e, isExpanded) => {
                                  setAccordianExpended(isExpanded);
                              }
                    }
                >
                    <AccordionSummary
                        aria-controls='targets-defs-content'
                        id='targets-defs-header'
                        expandIcon={type === 'event' ? <ExpandMore /> : undefined}
                    >
                        <Typography>
                            {t`##Edit`} {t`##Targets`}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TargetDefinitionsControl
                            targets={targetProfile.targets}
                            onChange={targets => {
                                setTargetProfile((t: any) => ({ ...t, targets: targets }));
                                setChanged(true);
                            }}
                        />
                        <Stack mt={2} direction='row' justifyContent='flex-end' spacing={2}>
                            {targetProfile && isAdmin && (
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={handleSaveTargets}
                                >{t`##Save`}</Button>
                            )}
                            {targetProfile && isAdmin && type === 'eventgroup' && (
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    disabled={changed || targetProfile === null}
                                    onClick={handleApplyAll}
                                >
                                    {t`##ApplyAllEvents`}
                                </Button>
                            )}
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            )}

            {type === 'event' && targetProfile && targetProfile._id && (
                <Box>
                    <TargetGoals
                        eventId={payload._id}
                        eventTargetData={targetProfile}
                        triggerUpdateCount={(triggerUpdateCount || 0) + refreshCount}
                    />
                </Box>
            )}
        </Box>
    );
}

export default TargetDefinitionsWrapper;

interface TargetGoalsProps {
    eventId: string;
    eventTargetData?: TargetDefinitionsProfile;
    triggerUpdateCount?: number;
}

function TargetGoals({ eventId, eventTargetData, triggerUpdateCount }: TargetGoalsProps) {
    const { t } = useTranslation();
    const snackbarTools = React.useContext(EmpplanSnackbarContext);
    const [targets, setTargets] = React.useState<Array<any> | null>(null);
    const pwaContext = React.useContext(PWAContext);

    // console.log({ triggerUpdateCount });

    const refreshTargets = async () => {
        try {
            const response = await API.post(`/targetdefinitionsprofile/calculate/${eventId}`, {});
            const jsonData = await response.json();
            if (jsonData.success && Array.isArray(jsonData.data)) {
                setTargets && setTargets(jsonData.data);
                snackbarTools.createToast({ type: 'success', message: `##Success` });
            }
        } catch (error) {
            // handle error
            console.error(error);
            snackbarTools.createToast({ type: 'error', message: `##Failed` });
        }
    };

    React.useEffect(() => {
        if (eventTargetData?._id) {
            setTargets && setTargets(eventTargetData.targets);
        }
    }, []);

    React.useEffect(() => {
        // use triggerUpdateCount here to refreshTargets
        if ((targets && triggerUpdateCount && triggerUpdateCount > 0) || pwaContext?.pwa) {
            refreshTargets();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerUpdateCount]);

    return (
        <>
            {Array.isArray(targets) &&
                targets.map(targetData => {
                    const current = Number(targetData?.value) || 0;
                    const goal = Number(targetData?.targetGoal) || 0;

                    return (
                        <TargetsCard
                            key={targetData?.reactId}
                            label={`${targetData.rule?.filters?.conditions[0]?.filterType} - ${targetData.rule?.filters?.conditions[0]?.condition?.value[0]?.name}`}
                            reach={current}
                            goal={goal}
                        />
                    );
                })}
        </>
    );
}
