import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import {
    Alert,
    AlertTitle,
    Box,
    Paper,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Switch,
    Stack,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { createSyncHistoryEntry, emergencyClearAllIndexedDbs } from '../../Models/NewSync/helpers';
import { reportAllDataFromDatabasesToGlitchService } from '../../Models/NewSync/actions';
import { PWASyncControlContext } from '../../Services/pwaSyncControlContext';
import { MINIFY_IMAGES_LOCALSTORAGE_KEY, shouldImageOptimize } from 'reactelements/src/hooks/useFileUpload/uploadHelper';
import ReportGlitchButton from '../../Services/ReportGlitchButton';
import { isStoragePersisted, showEstimatedQuota } from '../storagePersistance';
import { clearAllDbSyncStatuses, downloadAllIndexdbDatabases, getAllSyncStatuses } from '../../Models/helpers';
import { DetectOnline } from '../../../reactelements/src/hooks/detectOnline';

const paperStyle = { my: 2, p: 1 };

export const CRMDoubleTapMapSettingLocalstorageKey = 'CRMDoubleTapMapSetting';
export const CRMMapDebugSettingLocalstorageKey = 'CRMMapDebugSetting';
export const CRMMapShowLeadListSettingLocalstorageKey = 'CRMMapShowLeadListSetting';

const loadStorageDetails = async () => {
    try {
        const persisted = await isStoragePersisted();
        const estimate = await showEstimatedQuota();
        return { persisted, estimate };
    } catch (error) {
        console.error('loadStorageDetails', error);
    }
    return { persisted: false, estimate: undefined };
};

const readBoolFromLocalstorage = (key: string, defaultValue?: boolean) => {
    const val = localStorage.getItem(key);
    if (!val && typeof defaultValue === 'boolean') return defaultValue;
    if (val === 'true') return true;
    return false;
};

function PWASettings() {
    const { t } = useTranslation();
    const { isOnline } = DetectOnline();
    const agencyAllowHybrid = !!window.empplanSettings?.hybridPWA;
    const { isHybridSync, handleHybridModeToggle, uploadSyncData, modelSyncData } = React.useContext(PWASyncControlContext);

    const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = React.useState(false);

    const [CRMDoubleTapMapSetting, setCRMDoubleTapMapSetting] = React.useState<boolean>(
        readBoolFromLocalstorage(CRMDoubleTapMapSettingLocalstorageKey, false),
    );
    const [CRMMapDebugSetting, setCRMMapDebugSetting] = React.useState<boolean>(
        readBoolFromLocalstorage(CRMMapDebugSettingLocalstorageKey, false),
    );
    const [CRMMapShowLeadListSetting, setCRMMapShowLeadListSetting] = React.useState<boolean>(
        readBoolFromLocalstorage(CRMMapShowLeadListSettingLocalstorageKey, true),
    );
    const [minifyImageSetting, setMinifyImageSetting] = React.useState<boolean>(shouldImageOptimize());

    const [storageDetails, setStorageDetails] = React.useState<{
        persisted: boolean;
        estimate: StorageEstimate | undefined;
    }>({
        persisted: false,
        estimate: undefined,
    });

    React.useEffect(() => {
        loadStorageDetails().then(setStorageDetails).catch(console.error);
    }, []);

    return (
        <Box mt={2} mx={2}>
            <Typography variant='h5'>{t('PWASettings')}</Typography>

            {agencyAllowHybrid && (
                <Paper key='OfflineMode' sx={paperStyle}>
                    <Alert severity='info'>
                        <AlertTitle>Hybrid Mode</AlertTitle>
                        <strong>Turn Off If You Want To Use Offline Mode</strong>
                    </Alert>
                    <Stack direction='row' spacing={1} alignItems='center'>
                        <Typography>{t('##OfflineMode')}</Typography>
                        <Switch
                            id='OfflineModeSwitch'
                            checked={isHybridSync}
                            disabled={!isOnline}
                            color='secondary'
                            onChange={handleHybridModeToggle}
                        />
                        <Typography>{t('##HybridMode')}</Typography>
                    </Stack>
                    {!isOnline && (
                        <Typography key='YouNeedToBeOnlineToToggleThis' variant='caption'>
                            {t('##YouNeedToBeOnlineToToggleThis')}
                        </Typography>
                    )}
                </Paper>
            )}

            {window.empplanSettings?.imageOptimizePWA && (
                <Paper key='MinifyImages' sx={paperStyle}>
                    <Alert severity='info'>
                        <AlertTitle>{t('##MinifyImages')}</AlertTitle>
                        <strong>{t('##MinifyImagesInfo')}</strong>
                    </Alert>
                    <Stack direction='row' spacing={1} alignItems='center'>
                        <Switch
                            id='MinifyImagesSwitch'
                            checked={minifyImageSetting}
                            color='secondary'
                            onChange={() => {
                                if (minifyImageSetting) {
                                    localStorage.setItem(MINIFY_IMAGES_LOCALSTORAGE_KEY, 'false');
                                    setMinifyImageSetting(false);
                                } else {
                                    localStorage.setItem(MINIFY_IMAGES_LOCALSTORAGE_KEY, 'true');
                                    setMinifyImageSetting(true);
                                }
                            }}
                        />
                        <Typography>
                            {minifyImageSetting ? t('##MinifyImagesModeOn') : t('##MinifyImagesModeOff')}
                        </Typography>
                    </Stack>
                </Paper>
            )}

            {true && (
                <Paper key='MapDebugging' sx={paperStyle}>
                    <Alert severity='info'>
                        <AlertTitle>{t('##MapDebugging')}</AlertTitle>
                        <strong>{t('##MapDebuggingInfo')}</strong>
                    </Alert>
                    <Stack direction='row' spacing={1} alignItems='center'>
                        <Switch
                            id='MapDebuggingSwitch'
                            checked={CRMDoubleTapMapSetting}
                            color='secondary'
                            onChange={() => {
                                if (CRMDoubleTapMapSetting) {
                                    localStorage.setItem(CRMDoubleTapMapSettingLocalstorageKey, 'false');
                                    setCRMDoubleTapMapSetting(false);
                                } else {
                                    localStorage.setItem(CRMDoubleTapMapSettingLocalstorageKey, 'true');
                                    setCRMDoubleTapMapSetting(true);
                                }
                            }}
                        />
                        <Typography>
                            {CRMDoubleTapMapSetting ? t('##CRMDoubleTapMapOn') : t('##CRMDoubleTapMapOff')}
                        </Typography>
                    </Stack>
                    <Stack direction='row' spacing={1} alignItems='center'>
                        <Switch
                            id='MapDebuggingSwitch'
                            checked={CRMMapDebugSetting}
                            color='secondary'
                            onChange={() => {
                                if (CRMMapDebugSetting) {
                                    localStorage.setItem(CRMMapDebugSettingLocalstorageKey, 'false');
                                    setCRMMapDebugSetting(false);
                                } else {
                                    localStorage.setItem(CRMMapDebugSettingLocalstorageKey, 'true');
                                    setCRMMapDebugSetting(true);
                                }
                            }}
                        />
                        <Typography>{CRMMapDebugSetting ? t('##CRMMapDebugOn') : t('##CRMMapDebugOff')}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={1} alignItems='center'>
                        <Switch
                            id='MapDebuggingSwitch'
                            checked={CRMMapShowLeadListSetting}
                            color='secondary'
                            onChange={() => {
                                if (CRMMapShowLeadListSetting) {
                                    localStorage.setItem(CRMMapShowLeadListSettingLocalstorageKey, 'false');
                                    setCRMMapShowLeadListSetting(false);
                                } else {
                                    localStorage.setItem(CRMMapShowLeadListSettingLocalstorageKey, 'true');
                                    setCRMMapShowLeadListSetting(true);
                                }
                            }}
                        />
                        <Typography>
                            {CRMMapShowLeadListSetting
                                ? t('##DetailListResidentialUnitsOn')
                                : t('##DetailListResidentialUnitsOff')}
                        </Typography>
                    </Stack>
                </Paper>
            )}

            <Paper sx={paperStyle}>
                <Alert severity='error'>
                    <AlertTitle>Delete everything</AlertTitle>
                    This will delete all data saved locally —{' '}
                    <strong>do not click this unless support team has asked!</strong>
                </Alert>

                <Button
                    variant='outlined'
                    color='error'
                    startIcon={<DeleteOutlineOutlinedIcon />}
                    sx={{ mt: 1 }}
                    onClick={() => {
                        setDeleteConfirmDialogOpen(true);
                    }}
                >
                    Delete everything
                </Button>
            </Paper>

            {window?.userabstraction?.email === 'support@empplan.com' && (
                <Paper sx={paperStyle}>
                    <Alert severity='warning' sx={{ mb: 1 }}>
                        <AlertTitle>{t('##UploadSyncLogError')}</AlertTitle>
                        <strong>{t('##ThisWillUploadYourLocalDataIntoEmpplan')}</strong>
                        <br />
                        <strong>This box is limited to support user for debugging</strong>
                    </Alert>
                    <ReportGlitchButton
                        key='glitch-button'
                        payload={JSON.stringify({
                            modelSyncData,
                            uploadSyncData,
                            version: window.pwaAppVersion,
                            user: window.userabstraction,
                            origin: window.location.origin,
                            syncTimestamps: getAllSyncStatuses(),
                        })}
                    />
                </Paper>
            )}

            {uploadSyncData?.syncErrors?.length > 0 && (
                <Paper sx={paperStyle}>
                    <Alert severity='warning'>
                        <AlertTitle>{t`##ReportAllDbToEmpplanTitle`}</AlertTitle>
                        <strong>{t`##ReportAllDbToEmpplanHelptext`}</strong>
                    </Alert>
                    <SendAllDatabasesToGlitchService />
                </Paper>
            )}

            <Typography key='persist-storage' variant='overline' width='100%' textAlign='center'>
                {t`##StoragePersisted`}: {String(storageDetails.persisted)} || {storageDetails.estimate?.usage} /{' '}
                {storageDetails.estimate?.quota}
            </Typography>
            <br />
            <Typography key='app-build-info' variant='overline' width='100%' textAlign='center'>
                {t`##EMPPLANVERSION`}: {window.pwaAppVersion?.version}
            </Typography>

            {/* Delete confim dialog */}
            <Dialog
                open={deleteConfirmDialogOpen}
                onClose={() => {
                    setDeleteConfirmDialogOpen(false);
                }}
                aria-labelledby='delete-all-confirm-dialog-title'
                aria-describedby='delete-all-confirm-dialog-description'
            >
                <DialogTitle id='delete-all-confirm-dialog-title'>
                    {'Are you sure you want to delete everything?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText color='error' id='delete-all-confirm-dialog-description'>
                        We hope you know what you are doing.
                        <br />
                        You will be logged out after this action completes
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={window.isloggingOut}
                        color='error'
                        onClick={async () => {
                            createSyncHistoryEntry('UserDeletedIndexDBDataManually', {
                                msg: 'data deleted from pwa settings',
                            });
                            await emergencyClearAllIndexedDbs();
                            clearAllDbSyncStatuses();
                            // should remove all
                            localStorage.removeItem('myEventsTableSettings');
                            // localStorage.removeItem('isPromoterHybridMode');
                            window.location.href = '/logout';
                            localStorage.removeItem('legalDisclaimer');
                        }}
                    >
                        DELETE ALL DATA
                    </Button>
                    <Button
                        onClick={() => {
                            setDeleteConfirmDialogOpen(false);
                        }}
                        autoFocus
                    >
                        Go back
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default PWASettings;

interface ReportAllToGlitchServiceProps {
    label?: string;
    additionalObject?: any;
}
export const ReportAllToGlitchService = ({ label, additionalObject }: ReportAllToGlitchServiceProps) => {
    const { t } = useTranslation();
    const { isHybridSync, uploadSyncData, modelSyncData } = React.useContext(PWASyncControlContext);

    const [reportDataState, setReportDataState] = React.useState<{
        state: 'ready' | 'uploading' | 'done' | 'error';
        hash: string | null;
        progress?: number;
        errorCount?: number;
    }>({ state: 'ready', hash: null, progress: 0 });

    const updateProgress = (progress: number) => {
        setReportDataState(s => ({ ...s, progress }));
    };

    return (
        <React.Fragment key='ReportAllToGlitchService'>
            {label && (
                <p key='report-glitch-label'>
                    {t(label)}
                    <br />
                    <br />
                </p>
            )}

            <Button
                key='ReportAllToGlitchServiceButton'
                variant='outlined'
                color='secondary'
                disabled={reportDataState.state !== 'ready'}
                sx={{ mt: 1 }}
                onClick={() => {
                    setReportDataState?.({ state: 'uploading', hash: null });
                    reportAllDataFromDatabasesToGlitchService(
                        updateProgress,
                        JSON.stringify({
                            uploadSyncData,
                            modelSyncData,
                            isHybridSync,
                            version: window.pwaAppVersion,
                            user: window.userabstraction,
                            origin: window.location.origin,
                            additionalObject,
                            syncTimestamps: getAllSyncStatuses(),
                        }),
                    )
                        .then(({ hash, errorCount }: { hash: string; errorCount: number }) => {
                            setReportDataState?.({ state: 'done', hash, errorCount });
                        })
                        .catch(err => {
                            console.error('Error exporting data from indexedDB to glitch', err);
                            setReportDataState?.({ state: 'error', hash: null });
                        });
                }}
            >
                Start
            </Button>

            {reportDataState.state === 'uploading' && (
                <Box key='ReportAllToGlitchServiceOngoing'>
                    Reporting in progress - {((reportDataState.progress || 0) * 100).toFixed(2)} %
                </Box>
            )}
            {reportDataState.state === 'done' && (
                <Box key='ReportAllToGlitchServiceDone'>
                    Done reporting - Hash: {reportDataState.hash} / {reportDataState.errorCount || 0} errors
                </Box>
            )}
            {reportDataState.state === 'error' && (
                <Box key='ReportAllToGlitchServiceError'>Failed to report data - refresh and try again</Box>
            )}
        </React.Fragment>
    );
};

const SendAllDatabasesToGlitchService = ({ label, additionalObject }: ReportAllToGlitchServiceProps) => {
    const { t } = useTranslation();

    const reduxStore = useSelector((store: any) => store);
    const { isHybridSync, uploadSyncData, modelSyncData } = React.useContext(PWASyncControlContext);

    const [reportDataState, setReportDataState] = React.useState<{
        state: 'ready' | 'uploading' | 'done' | 'error';
        hash: string | null;
    }>({ state: 'ready', hash: null });

    return (
        <React.Fragment key='SendAllDatabasesToGlitchService'>
            {label && (
                <p key='report-glitch-label'>
                    {t(label)}
                    <br />
                    <br />
                </p>
            )}

            <Button
                key='SendAllDatabasesToGlitchServiceButton'
                variant='outlined'
                color='secondary'
                disabled={reportDataState.state !== 'ready'}
                sx={{ mt: 1 }}
                onClick={() => {
                    setReportDataState?.({ state: 'uploading', hash: null });
                    downloadAllIndexdbDatabases({ uploadSyncData, modelSyncData, isHybridSync, reduxStore }).then(
                        ({ hash }) => {
                            if (hash) {
                                setReportDataState?.({ state: 'done', hash });
                            } else {
                                console.error('Error uploading data from indexedDB to glitch');
                                setReportDataState?.({ state: 'error', hash: null });
                            }
                        },
                    );
                }}
            >
                Start
            </Button>

            {reportDataState.state === 'uploading' && (
                <Box key='SendAllDatabasesToGlitchServiceOngoing'>Upload in progress</Box>
            )}
            {reportDataState.state === 'done' && (
                <Box key='SendAllDatabasesToGlitchServiceDone'>Done sending - Hash: {reportDataState.hash}</Box>
            )}
            {reportDataState.state === 'error' && (
                <Box key='SendAllDatabasesToGlitchServiceError'>Failed to report data - refresh and try again</Box>
            )}
        </React.Fragment>
    );
};
