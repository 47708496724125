import { newProductDb } from './newProductDexie';
import newProductActionTypes, { createNewProductAction } from './actions';
import DynTableConst from '../../Services/Constants';
import newSyncDataActionTypes from '../NewSync/actions';
import axios from 'axios';
import { setDbSyncedDown } from '../helpers';

const PaginationLimit = 500;

export const syncAllProducts = async (dispatch: any, projectIds: string) => {
    try {
        // drop the products/expenses in the database
        await newProductDb.transaction('rw', newProductDb.tables, async () => {
            await Promise.all(newProductDb.tables.map(table => table.clear()));
        });

        // get the new products and load them to the database
        let count = 1;
        for (let idx = 0; idx < count; idx += PaginationLimit) {
            const productsResponse = await getProductsFetch('products', projectIds, idx);
            if (!Array.isArray(productsResponse?.products) || typeof productsResponse?.count !== 'number')
                throw new Error('Failed to sync products');
            count = productsResponse?.count || 0;
            // console.log({ idx, productsResponse, count });

            Array.isArray(productsResponse?.products) &&
                productsResponse.products.forEach((product: any) => {
                    product &&
                        product._id &&
                        dispatch(
                            createNewProductAction({
                                _id: product._id,
                                product: product,
                                syncDate: new Date(),
                            }),
                        );
                });

            dispatch({
                type: newSyncDataActionTypes.ADD_SYNC_LOG,
                payload: { action: 'Sync newProductDb product', idx, count },
            });
        }

        // get the new expenses and load them to the database
        count = 1;
        for (let idx = 0; idx < count; idx += PaginationLimit) {
            const expenseProductResponse = await getProductsFetch('Expenses', projectIds, idx);
            if (!Array.isArray(expenseProductResponse?.products) || typeof expenseProductResponse?.count !== 'number')
                throw new Error('Failed to sync expenses');
            count = expenseProductResponse?.count || 0;
            // console.log({ idx, productsResponse, count });

            Array.isArray(expenseProductResponse?.products) &&
                expenseProductResponse.products.forEach((product: any) => {
                    product &&
                        product._id &&
                        dispatch(
                            createNewProductAction({
                                _id: product._id,
                                product: product,
                                syncDate: new Date(),
                            }),
                        );
                });

            dispatch({
                type: newSyncDataActionTypes.ADD_SYNC_LOG,
                payload: { action: 'Sync newProductDb expense', idx, count },
            });
        }

        dispatch({
            type: newProductActionTypes.UPDATE_PRODUCT_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
        setDbSyncedDown('productsDB');
    } catch (error) {
        console.log(error);
        dispatch({
            type: newSyncDataActionTypes.ADD_SYNC_ERROR,
            payload: error || 'Error handled but no log available',
        });
        dispatch({
            type: newProductActionTypes.UPDATE_PRODUCT_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
    }
};

export const getProductsFetch = async (
    productType: 'products' | 'Expenses',
    projectIds: string,
    skip: number,
): Promise<any> => {
    const postUrl =
        productType === 'products'
            ? DynTableConst.EMPPLAN_HOST +
              `/product/pwa/sync?productType=${productType}&limit=${PaginationLimit}&skip=${skip}`
            : DynTableConst.EMPPLAN_HOST +
              `/product/pwa/sync?productType=${productType}&limit=${PaginationLimit}&skip=${skip}`;
    return axios
        .post(
            postUrl,
            { project_id: projectIds },
            {
                withCredentials: true, // for local dev
                // for local dev
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            },
        )
        .then(response => response.data)
        .catch(err => {
            console.error(err);
            return { foo: 'broken' };
        });
};
