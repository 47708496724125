const VALIDATED_FOR_VAT_STRING = '##ValidatedForVAT';

export const vatIdOrNot = function (userab: any) {
    try {
        return userab.business?.options.indexOf(VALIDATED_FOR_VAT_STRING) !== -1;
    } catch (err) {
        return false;
    }
}

export const isVatIdValid = function (vatId: any) {
    if (vatId && typeof vatId === 'number' && !isNaN(vatId)) {
        vatId = '' + vatId;
    }
    if (typeof vatId === typeof 'string') {
        vatId = vatId.trim();
    } else {
        return false;
    }
    return vatId !== '';
}

export const isVatIdValidAndInUse = function (userab: any) {
    const innerUser =
        (userab &&
            userab.business &&
            userab.business.options &&
            vatIdOrNot(userab) &&
            userab.officialIds &&
            isVatIdValid(userab.officialIds.vatId)) === true;
    return (
        innerUser ||
        (userab && vatIdOrNot(userab) && userab.officialIds && isVatIdValid(userab.officialIds.vatId)) === true
    );
}

