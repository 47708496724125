import DynTableConst from "../../Services/Constants";
import { eventEarningsTypes } from "./action.types"
import axios from 'axios';


export const addToEventEarnings = (payload: any) => {
    return {
        type: eventEarningsTypes.LOAD_EARNINGS,
        payload: payload
    }
}


export const getEventEarningsAction = (eventId: string) => (dispatch: any) => {
    axios.post(DynTableConst.EMPPLAN_HOST + '/api/invoices/get/user/new/items', {
        eventids: [{ eventId: { _id: eventId } }],
        noSalesItemsFilter: true
    }).then((res) => {
        if (res.data && res.data?.eventEarnings) {
            dispatch(addToEventEarnings(res.data));
        } else {
            dispatch(addToEventEarnings({}));
        }
    }).catch((err) => {
        dispatch(addToEventEarnings({}));
    })
}


export const clearEventEarnings = () => {
    return {
        type: eventEarningsTypes.CLEAR_EARNINGS,
        payload: {}
    }
}