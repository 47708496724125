import Dexie from 'dexie';

interface IHashstorage {
    id?:number;
    hash: String;
    date:Date;
}


class HashStorage extends Dexie {
    //typescript needs here an ! to instantiate it only in the super/parent class
    public hash!: Dexie.Table<IHashstorage, number>;    
    constructor() {
      super("emplHash");
      //init the tables
      this.version(1).stores({
        hash: '++id, hash, date',
      });
    }   
}

export class hashremember {
    private locationhash:String;
    private storage: HashStorage;
    constructor() {
        this.locationhash=window.location.hash;
        this.storage= new HashStorage();
    }
    checkHash() {
        if(this.locationhash.startsWith('#!/')) {
            this.initStorage(this.locationhash);
            window.location.hash='';
        }
    }

    initStorage(hash:String) {
        this.storage.hash.get(1).then((result: any) => {
            if(typeof result === "undefined") {
                this.addFirstHash(hash);
            } else {
              //update hash!
              this.updateHash(hash);
            }
        });
      }

      clearHashStorage() {
          this.storage.hash.delete(1);
      }
    
      /**
       * adds fallback language into the store
       */
      addFirstHash(hash: String) {
        this.storage.hash.put({hash:hash, date: new Date()})
        .then(() => {
          return this.storage.hash.get(1);
        }).then((result: any) =>{
            console.log('stored hash');
        }).catch((e) => {
            console.log('error dexie login url-hash pack! ', e);
        });
      }
    
      /**
       * changes the default language key 1
       */
      updateHash(hash: String) {
           this.storage.hash.update(1, {hash: hash, date:new Date()})
          .then(()=> {
              return this.storage.hash.get(1);
          }).then((result: any) => {
            console.log('updated hash', result);
          }).catch((e) => {
            console.error('Error store login url-hash non critical: ', e);
          })
      }

      getHash() {
        return this.storage.hash.get(1);
      }
}

