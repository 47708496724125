import API from '../../config/api';
import { getLocationCoordinates } from '../../hooks/useFileUpload/fileHelper';
import moment from 'moment';
import * as turf from '@turf/turf';
import { t } from 'i18next';

export const eventStatesToAllowEditDate = ['Published', 'Rejected', 'Accepted', 'BrandPlanned', 'Pending'];

export const handleEventDateChange = async (event: any, newDate: moment.Moment) => {
    const eventGroupEditPayload: any = {};
    const isAdmin = window.user?.roles?.indexOf('EventAdd') !== -1;
    //Promoter can change eventdate, if not yet checked-in
    if (isAdmin) {
        if (event?.eventGroupId?.events.length === 1) {
            if (eventStatesToAllowEditDate.includes(event?.eventState)) {
                eventGroupEditPayload.eventDate = newDate;
                let editInfo: any = {};
                editInfo.old_event_date = event.date;
                editInfo.agency_user = event.agency_user;
                editInfo._id = event._id;
                editInfo.old_event_date = event.date;
                editInfo.old_event_state = event.eventState;
                editInfo.eventTypes = [];
                editInfo.eventTypes.push(event.budgetEventType);
                eventGroupEditPayload.editEventInfo = [];
                eventGroupEditPayload.editEventInfo.push(editInfo);
                eventGroupEditPayload.createEventInfo = [];
                eventGroupEditPayload.removeEventInfo = [];
            } else {
                return { success: false, message: t('##EventCantBeChangedEventState') };
            }
        } else {
            return { success: false, message: t('##FailedMultipleEventsinEventGroup') };
        }
    } else {
        if (event?.meta?.promoterChangeDate) {
            if (eventStatesToAllowEditDate.includes(event?.eventState) && event?.eventGroupId?.events.length === 1) {
                eventGroupEditPayload.eventDate = newDate;
                let editInfo: any = {};
                editInfo.old_event_date = event.date;
                editInfo.agency_user = event.agency_user;
                editInfo._id = event._id;
                editInfo.old_event_date = event.date;
                editInfo.old_event_state = event.eventState;
                editInfo.eventTypes = [];
                editInfo.eventTypes.push(event.budgetEventType);
                eventGroupEditPayload.editEventInfo = [];
                eventGroupEditPayload.editEventInfo.push(editInfo);
                eventGroupEditPayload.createEventInfo = [];
                eventGroupEditPayload.removeEventInfo = [];
            } else {
                return { success: false, message: t('##EventCantBeChangedEventState') };
            }
        } else {
            return { success: false, message: t('##FailedShouldChangeDateFalse') };
        }
    }

    // handle the date change
    try {
        const response = await API.put('event/group/' + event.eventGroupId._id, eventGroupEditPayload).then(res =>
            res.json(),
        );
        if (response.success && response.data?.editEventInfo?.length > 0) {
            // all good
        } else {
            throw new Error(t('##FailedToDateChange'));
        }
    } catch (err) {
        return { success: false, message: t('##FailedToDateChange') };
    }

    return { success: true };
};

export const checkUsersLocationInsideRadius = (
    userLocation: { lon: any; lat: any },
    eventLocation: { lon: any; lat: any },
    radius: number,
    location: [number?, number?]
) => {
    if (!location || !Array.isArray(location) || location.length === 0) {
        return true;
    }
    const userPoint = turf.point([userLocation.lon, userLocation.lat]);
    const eventPoint = turf.point([eventLocation.lon, eventLocation.lat]);
    const distance = turf.distance(userPoint, eventPoint, { units: 'kilometers' });
    return distance <= radius;
};

export const handleDateChangePWA = (
    event: any,
    newValue: moment.Moment,
    pwaContext: any,
    isCheckIn?: boolean | undefined,
) => {
    const project = event.project_id?.project ? event.project_id?.project : event.project_id;
    const newEvent = JSON.parse(JSON.stringify(event));

    return new Promise(async (resolve, reject) => {
        if (
            !project ||
            !eventStatesToAllowEditDate.includes(event?.eventState) ||
            event?.eventGroupId?.events.length > 1
        ) {
            return resolve({ success: false, message: t('##EventCantBeChangedEventState') });
        }
        if (
            isCheckIn &&
            project?.checkinCondition &&
            project?.checkinCondition?.forceLocationShare === true &&
            project?.checkinCondition?.forceNearByLocation === true &&
            project?.checkinCondition?.nearByKm
        ) {
            const currentLocation = await getLocationCoordinates();
            const eventLocation = {
                lon: newEvent?.point_of_sale?.location?.[0],
                lat: newEvent?.point_of_sale?.location?.[1],
            };
            const userLocation = {
                lon: currentLocation.longitude,
                lat: currentLocation.latitude,
            };
            if (
                !newEvent.area &&
                !checkUsersLocationInsideRadius(userLocation, eventLocation, project?.checkinCondition?.nearByKm, newEvent?.point_of_sale?.location)
            ) {
                return resolve({ success: false, message: t('##NotInsideLocation') });
            }
        }
        if (project.period && project?.period?.end) {
            const projectEndDate = moment(project.period.end);
            const newDate = moment(newValue);
            if (projectEndDate.isBefore(newDate)) {
                return resolve({ success: false, message: t('##EventCantBeChangedEventState') });
            }
        }
        const payload: any = {};

        newEvent.date = newValue.toDate();
        newEvent.eventGroupId.eventDate = newValue.toDate();

        let editInfo: any = {};
        editInfo.agency_user = event.agency_user;
        editInfo._id = event._id;
        editInfo.old_event_date = event.date;
        editInfo.old_event_state = event.eventState;
        editInfo.eventTypes = [];
        editInfo.eventTypes.push(event.budgetEventType);
        payload.editEventInfo = [];
        payload.editEventInfo.push(editInfo);
        payload.createEventInfo = [];
        payload.removeEventInfo = [];
        payload.eventDate = newValue;

        // dispatch here the edited event for updating dexie
        newEvent.project_id = project._id;

        if (pwaContext.pwaHelpers?.events?.update) {
            pwaContext.pwaHelpers?.events?.update({
                _id: newEvent._id,
                event: newEvent,
                eventState: newEvent.eventState,
            });
            // dispatch here to add to new collection
            if (pwaContext.pwaHelpers?.eventActions?.add) {
                pwaContext.pwaHelpers?.eventActions?.add(
                    {
                        _id: newEvent.eventGroupId._id,
                        type: 'DateChange',
                    },
                    JSON.stringify(payload),
                );
            }
        } else {
            return resolve({ success: false, message: t('##EventCantBeChangedEventState') });
        }
        return resolve({ success: true });
    });
};

// copy pasta from angular: packages/custom/events/public/controllers/EventDetailPageController.js
export const checkIsEventDate = function (event: any) {
    //check if the event is today!
    //TODO: maybe we need to do this check on the server side!
    var now = Date.now(); //TODO: load server date.

    var eventDate = new Date(event.date);
    var eventStop = new Date(event.date);
    eventDate.setHours(0);
    eventDate.setMinutes(0);
    eventStop.setHours(23);
    eventStop.setMinutes(59);

    var uxtStartE = Date.parse(eventDate.toISOString());
    var uxtStopE = Date.parse(eventStop.toISOString());

    if (uxtStartE <= now && uxtStopE >= now) {
        //&& projectEnd >= eventDate
        return true;
    } else {
        return false;
    }
};

const updateDexies = (
    pwaContext: any,
    newEvent: any,
    currentLocation: { latitude: number; longitude: number },
    isMobile: boolean,
) => {
    pwaContext.pwaHelpers?.events?.update({
        _id: newEvent._id,
        event: newEvent,
        eventState: 'LoggedIn',
    });

    if (pwaContext.pwaHelpers?.eventActions?.add) {
        const payload = {
            device: navigator.userAgent,
            isMobile: isMobile,
            latitude: currentLocation.latitude,
            longitude: currentLocation.longitude,
            event: newEvent,
        };

        pwaContext.pwaHelpers?.eventActions?.add(
            {
                _id: newEvent._id,
                type: 'CheckIn',
            },
            payload,
        );
    }
};

export const checkInEventHandler = async (event: any, pwaContext?: any) => {
    let shouldForceLocation = false;

    const mainProject = event?.project_id?.project ? event?.project_id?.project : event?.project_id;

    if (mainProject?.checkinCondition?.forceLocationShare === true) {
        shouldForceLocation = true;
    }

    let currentLocation = { latitude: 0, longitude: 0 };
    try {
        currentLocation = await getLocationCoordinates();
    } catch (err) {
        if (shouldForceLocation) {
            // show dialog warning or toast - ##PleaseShareYourLocationToProceed
            return { success: false, message: t('##PleaseShareYourLocationToProceed') };
        }
    }

    var isMobile = false; //initiate as false
    // device detection
    if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
            navigator.userAgent,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            navigator.userAgent.substr(0, 4),
        )
    ) {
        isMobile = true;
    }

    try {
        // PUT
        // /event/64674dd6da792e00164accf4/flow?device=Mozilla%2F5.0+(X11;+Linux+x86_64)+AppleWebKit%2F537.36+(KHTML,+like+Gecko)+Chrome%2F113.0.0.0+Safari%2F537.36&isMobile=false&latitude=15.2832187&longitude=73.986191
        // body has event object
        if (pwaContext && pwaContext?.pwa) {
            const newEvent = JSON.parse(JSON.stringify(event));
            const project = newEvent?.project_id?.project ? newEvent?.project_id?.project : newEvent?.project_id;
            const projectId = newEvent.project_id?.project ? newEvent.project_id?.project._id : newEvent.project_id;
            newEvent.eventState = 'LoggedIn';
            newEvent.project_id = projectId;

            // need to add here the condition
            if (pwaContext.pwaHelpers?.events?.update) {
                if (
                    project?.checkinCondition &&
                    project?.checkinCondition?.forceLocationShare === true &&
                    project?.checkinCondition?.forceNearByLocation === true &&
                    project?.checkinCondition?.nearByKm
                ) {
                    const eventLocation = {
                        lon: newEvent?.point_of_sale?.location?.[0],
                        lat: newEvent?.point_of_sale?.location?.[1],
                    };
                    const userLocation = {
                        lon: currentLocation.longitude,
                        lat: currentLocation.latitude,
                    };

                    if (
                        newEvent.area ||
                        checkUsersLocationInsideRadius(userLocation, eventLocation, project?.checkinCondition?.nearByKm, newEvent?.point_of_sale?.location)
                    ) {
                        updateDexies(pwaContext, newEvent, currentLocation, isMobile);
                        return { success: true };
                    } else {
                        return { success: false, message: t('##YouAreNotInLocation') };
                    }
                } else {
                    updateDexies(pwaContext, newEvent, currentLocation, isMobile);
                    return { success: true };
                }
            } else {
                return { success: false, message: t('##AnErrorOccured') };
            }
        } else {
            const response = await API.put(
                `/event/${event._id}/flow?device=${navigator.userAgent}&isMobile=${isMobile ? 'true' : 'false'}` +
                    `&latitude=${currentLocation.latitude}&longitude=${currentLocation.longitude}`,
                { ...event, eventState: 'LoggedIn' },
            ).then(res => res.json());

            if (response.emperr) {
                // any other errors as response.emperr
                return { success: false, message: response.emperr };
            }

            if (response.showalert === 'nearby') {
                // response.showalert==='nearby'
                // show dialog warning or toast - ##PleaseComeCloser
                return { success: false, message: t('##PleaseComeCloser') };
            }

            if (response._id) {
                return { success: true, data: response };
            } else {
                return { success: false };
            }
        }
    } catch (err) {
        console.log('Error checking in', err);

        return { success: false };
    }
};

export const COLORS_MAP: any = {
    BrandPlanned: '#de0062',
    Planned: '#FFC926',
    Published: '#99ACB8',
    Accepted: '#59A17E',
    Rejected: '#DE5D53',
    LoggedIn: '#D65AF4',
    LoggedOut: '#883B9B',
    SalesReport: '#7A55D6',
    SalesReportUploaded: '#7A55D6',
    SalesReportRejected: '#DE5D53',
    SalesReportAccepted: '#23ABA6',
    label_jobs_dark: 'rgb(66,66,66)',
    team: '#e0e0e0',
    blocked: '#DE5D53',
    legallydeleted: '#a33615',
    application: '#f8d516',
    Invoiced: '#23ABA6',
    ReadyToInvoice: '#5CB85B',
    Cancelled: '#DE5D53',
    label_event_state_creditable: '#4CAF50',
    Pending: '#424242',
    New: '#9662D0',
    Paid: '#59A17E',
    Sold: '#59A17E',
    WaitingForApproval: '',
    Deleted: 'DE5D53',
    VideoApplication: '#59A17E',
    WUNDERWORKER: '#D65AF4',
};
