import { Box, Typography, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ItemCard from './ItemCard';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { DetectOnline } from '../../../reactelements/src/hooks/detectOnline';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import { formatCurrency } from '../../../reactelements/src/modules/EventOverviewGrid/utils/utils';

function EventDetailTransactionList({ eventId, soldItems, handleUpdateSaleObj, setReRenderList, type, products }: any) {
    const {t} = useTranslation();

    const [activeAccordion, setActiveAccordion] = useState<string | false>(false);
    const handleActiveAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setActiveAccordion(isExpanded ? panel : false);
    };
    let aggregatedItems: { salesItem: { groupId: any } }[] = [];
    if(type !== 'expense'){
        const groupByKey= (array: any[], key: string | number)=> {
            return array
              .reduce((hash, obj) => {
                if(obj[key] === undefined) return hash; 
                return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
              }, {})
         }
    
    
    let groupIds: any[] = [];
            let innerSalesItems: any[] =[];
            soldItems.forEach((element: { salesItem: { groupId: any } }) => {
                if(element.salesItem){
                    innerSalesItems.push(element.salesItem);
                    let groupID = element.salesItem?.groupId;
                    if (!groupIds.includes(groupID)) {
                        groupIds.push(groupID);
                        //aggregatedItems.push(element);
                    }
                }
                
            });

           if(innerSalesItems.length > 0){
           let groupbysales = groupByKey(innerSalesItems,'productId');
           

           Object.keys(groupbysales).forEach(key => {
            // eslint-disable-next-line valid-typeof
            if(Array.isArray(groupbysales[key])){
                let qty = 0;
                
                groupbysales[key].forEach((element:any) => {
                    qty = qty + parseInt(element.qty);
                });
                groupbysales[key][0].aqty = 0;
                groupbysales[key][0].aqty = qty;
                groupbysales[key] = groupbysales[key][0];
               
            }else{
                groupbysales[key].aqty = 0;
                groupbysales[key].aqty = groupbysales[key].qty;
            }
            aggregatedItems.push(groupbysales[key]);
          });
        }
    }

    //console.log("inner sold items",soldItems);
    const { isOnline } = DetectOnline();
    const [aggreagate, setAggreagate] = useState<boolean>(false);
    const [onlineItems, setOnlineItems] = useState<any>({});

    
     

    useEffect(() => {
        //load data from server
        if (aggreagate) {
            if(type !== 'expense'){
                const groupByKey= (array: any[], key: string | number)=> {
                    return array
                      .reduce((hash, obj) => {
                        if(obj[key] === undefined) return hash; 
                        return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
                      }, {})
                 }
            let aggregatedItems: { salesItem: { groupId: any } }[] = [];
            let groupIds: any[] = [];
            let innerSalesItems: any[] =[];
            soldItems.forEach((element: { salesItem: { groupId: any } }) => {
                if(element.salesItem){
                    innerSalesItems.push(element.salesItem);
                    let groupID = element.salesItem?.groupId;
                    if (!groupIds.includes(groupID)) {
                        groupIds.push(groupID);
                        //aggregatedItems.push(element);
                    }
                }
            });

            if(innerSalesItems.length > 0){
                let groupbysales = groupByKey(innerSalesItems,'productId');
                Object.keys(groupbysales).forEach(key => {
                    // eslint-disable-next-line valid-typeof
                    groupbysales[key].productgroup = [];
                    if(Array.isArray(groupbysales[key])){
                        let qty = 0;
                        
                        groupbysales[key].forEach((element:any) => {
                            qty = qty + parseInt(element.qty);
                        });
                        groupbysales[key][0].productgroup = groupbysales[key];
                        groupbysales[key][0].aqty = 0;
                        groupbysales[key][0].aqty = qty;
                        groupbysales[key] = groupbysales[key][0];
                       
                    }else{
                        groupbysales[key].aqty = 0;
                        groupbysales[key].aqty = groupbysales[key].qty;
                    }
                   
                   
                    aggregatedItems.push(groupbysales[key]);
                  });
            }
           

           
        }
           //console.log("groupsale",aggregatedItems);




            //console.log('aggregatedItems', aggregatedItems);
        }
        // if(aggreagate){
        //     let request = {
        //         "eventids": [
        //             {
        //                 "eventId": {
        //                     "_id": eventId
        //                 }
        //             }
        //         ],
        //         "noSalesItemsFilter": true
        //     }

        // //     API.post(`/api/invoices/get/user/new/items`, request)
        // //         .then(response => {
        // //             return response.json();
        // //         })
        // //         .then(resData => {
        // //             setOnlineItems(resData);
        // //             setLoading(false);
        // //         })
        // //         .catch((e) => {
        // //             console.log(e);
        // //             setLoading(false);
        // //         });

        //  }
        //console.log('aggreagate', aggreagate);
    }, [aggreagate]);

    const handleSetView = (arg: string) => {
        if(type !== 'expense'){
            if (arg === 'added') {
                setAggreagate(false);
            } else {
                setAggreagate(true);
            }
        }
    };


    const handleTotalGross = (items: any) => {
        let innerTotal = 0;
        items.forEach((eachItem: any) => {
            const saleItem = eachItem.salesItem;
            innerTotal += saleItem.qty * saleItem.price;
        });
        return formatCurrency(innerTotal);
    };

    return (
        <>
            <Box marginTop={'25px'} padding={'5px'}>
                <Box
                    borderBottom={1}
                    padding={'3px'}
                    borderColor='grey.500'
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Stack direction='row' alignItems='center'>
                        <EqualizerIcon style={{ color: 'grey' }}></EqualizerIcon>
                        <Typography fontWeight={700} color={'grey'}>
                            {type === 'expense' ? t('##Expenses') : t('##ReportedTransaction')}
                        </Typography>
                    </Stack>
                    {aggreagate && type !== 'expense' ? (
                        <Typography onClick={() => handleSetView('added')} color='#23ABA6'>
                            {t('##Aggregated')}
                        </Typography>
                    ) : (
                        <Typography onClick={() => handleSetView('aggregate')} color='#23ABA6'>
                                {t('##AsAdded')}
                        </Typography>
                    )}
                </Box>
                {aggreagate && type !== 'expense'
                    ? aggregatedItems.map((singleItem: any, idx: number) => {
                          return (
                              <React.Fragment key={idx}>
                                  <ItemCard
                                      key={eventId}
                                      aggregate={aggreagate}
                                      item={singleItem}
                                      onlineItems={onlineItems}
                                      isOnline={isOnline}
                                      fullitem={singleItem}
                                      activeAccordian={activeAccordion}
                                      handleActiveAccordianChange={handleActiveAccordionChange}
                                      handleUpdateSaleObj={handleUpdateSaleObj}
                                      setReRenderList={setReRenderList}
                                      type={type}
                                      products={products}
                                  />
                              </React.Fragment>
                          );
                      })
                    : soldItems.map((singleItem: any, idx: number) => {
                          if (singleItem.salesItem || singleItem?.expense) {
                              return (
                                  <React.Fragment key={idx}>
                                      <ItemCard
                                          key={eventId}
                                          aggregate={aggreagate}
                                          item={singleItem?.salesItem ? singleItem?.salesItem : singleItem?.expense}
                                          onlineItems={onlineItems}
                                          isOnline={isOnline}
                                          fullitem={singleItem}
                                          activeAccordian={activeAccordion}
                                          handleActiveAccordianChange={handleActiveAccordionChange}
                                          handleUpdateSaleObj={handleUpdateSaleObj}
                                          setReRenderList={setReRenderList}
                                          type={type}
                                          products={products}
                                      />
                                  </React.Fragment>
                              );
                          } else {
                              return <React.Fragment key={idx}></React.Fragment>;
                          }
                      })}
                <br />
                {type !== 'expense' && (
                    <Typography textAlign='right'>
                        <b>
                            {t('##Total')} {handleTotalGross(soldItems)}
                        </b>
                    </Typography>
                )}
            </Box>
        </>
    );
}

export default EventDetailTransactionList;
