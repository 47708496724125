import {Reducer} from "redux";
import {User, Userabstraction, UserActions, UserActionTypes, UserStates} from "./UserStatesTypes";

export const initialUserState:UserStates = {
    user:null,
    userLoading:false,
    userIdsToLoad: null,
    loadUses: null,
    users:null,
    legal:null
};

export const UserReducer: Reducer<UserStates, UserActions> = (
    state = initialUserState,
    action
) => {
    switch (action.type) {
        //TODO CHANGE THE STATE
        case UserActionTypes.LOADING: {
            return {
                ...state,
                userLoading: true
            };
        };
        case UserActionTypes.LOADME: {
            return {
                ...state,
                user:action.user,
                legal:action.legal,
                userLoading:false
            };
        };
        case UserActionTypes.ADDUSERTOLOAD: {
            let userIdsToLoad =state.userIdsToLoad;
            if(state.userIdsToLoad===null) {
                userIdsToLoad=[];
            }
            if(userIdsToLoad!==null) {
                if(userIdsToLoad.indexOf(action.userId)===-1){
                    userIdsToLoad.push(action.userId)
                }
            }
              return {
                  ...state,
                  userIdsToLoad: userIdsToLoad
              }
        };

        case UserActionTypes.GETUSERBYID: {
            let userList:Userabstraction[]|null;
            userList=null;
            /**if(userList!==null) {
                userList.push(action.user);
            }**/
            if(state.users!==null) {
                return {
                    ...state,
                    users: state.users.concat([action.user]),
                    userLoading: false,
                };
            } else if(state.users===null) {
                return {
                    ...state,
                    users: [action.user],
                    userLoading: false,
                };
            }
        };

    }
    return state;
}
