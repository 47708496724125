import Dexie, { Table } from 'dexie';

class SyncDataDB extends Dexie {
    syncData!: Table<DexieSyncData>;

    constructor() {
        super('syncDataDB');
        this.version(1).stores({
            syncData: `_id`,
        });

        this.version(2).stores({
            syncData: `_id, user`,
        });
    }
}

export const newSyncDataDb = new SyncDataDB();
