export enum MenuActionTypes  {
    OPENMENU = "MENU/OPEN",
    CLOSEMENU = "MENU/CLOSE",
    DASHBOARDNAVCHANGE ="DASHBOARDNAV/CHANGE",
    EVENTNAVCHANGE="MENU/EVENT/CHANGE",
    MODULELOADED="MODULE/LOADED"
};

export interface EventPageNavigationChange {
    type: MenuActionTypes.EVENTNAVCHANGE,
    eventPageNavPosition:number,
}

export interface DashboardNavigationChange {
    type: MenuActionTypes.DASHBOARDNAVCHANGE,
    dashboardNavPosition:number
}

export interface ModuleLoaded {
    type: MenuActionTypes.MODULELOADED,
    moduleName:string
}

export interface MenuOpenActions {
    type: MenuActionTypes.OPENMENU
}

export interface MenuCloseActions {
    type: MenuActionTypes.CLOSEMENU
}

export interface MenuStates {
    readonly open: Boolean;
    readonly moduleName:string;
    readonly dashboardNavPosition:number;
    readonly eventPageNavPosition:number;
}


export type MenuActions =
    | MenuOpenActions
    | MenuCloseActions
    | DashboardNavigationChange
    | ModuleLoaded
    | EventPageNavigationChange
