import { IParams, ParamsContextProvider } from './params';
import { PaginationContextProvider } from './pagination';
import React from 'react';
import { LoaderContextProvider } from './loader';
import { ConfigContextProvider } from './config';
import { ThemeOptions, ThemeProvider, useTheme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useCurrentLocale } from '../../../config/translate';
import * as gridLocale from '@mui/x-data-grid-pro';
import * as coreLocale from '@mui/material/locale';
import { IGridResource } from '../index';
import { CacheProvider } from 'rest-hooks';
import { ResourceControllerContextProvider } from './resource';
import { Resource } from '@rest-hooks/rest';
import { ColumnsPanelContextProvider } from './columns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import type {} from '@mui/x-date-pickers/themeAugmentation';

export const ContextProviders = ({ children, params }: { children: React.ReactNode; params: IParams }) => {
    const existingTheme = useTheme() as ThemeOptions;
    // here we are loading internal components of grid to support localization
    const locale = useCurrentLocale().join('');
    // @ts-ignore
    const coreLocaleOptions = coreLocale[locale];
    // @ts-ignore
    const gridLocaleOptions = gridLocale[locale];
    const theme = createTheme(existingTheme, coreLocaleOptions, gridLocaleOptions);
    return (
        <CacheProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <ColumnsPanelContextProvider>
                    <ParamsContextProvider params={params}>
                        <ThemeProvider theme={theme}>
                            <LoaderContextProvider>
                                <PaginationContextProvider>
                                    <ConfigContextProvider>{children}</ConfigContextProvider>
                                </PaginationContextProvider>
                            </LoaderContextProvider>
                        </ThemeProvider>
                    </ParamsContextProvider>
                </ColumnsPanelContextProvider>
            </LocalizationProvider>
        </CacheProvider>
    );
};
export const ContextProviders2 = <T extends object>({
    children,
    params,
    GridResource,
}: {
    children: React.ReactNode;
    params: IParams;
    GridResource?: IGridResource<T>;
}) => {
    const existingTheme = useTheme() as ThemeOptions;
    // here we are loading internal components of grid to support localization
    const locale = useCurrentLocale().join('');
    // @ts-ignore
    const coreLocaleOptions = coreLocale[locale];
    // @ts-ignore
    const gridLocaleOptions = gridLocale[locale];
    const theme = createTheme(existingTheme, coreLocaleOptions, gridLocaleOptions);

    console.log({ children, params, GridResource });
    return (
        <CacheProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <ColumnsPanelContextProvider>
                    <ParamsContextProvider params={params}>
                        <ThemeProvider theme={theme}>
                            <LoaderContextProvider>
                                <PaginationContextProvider>
                                    <ConfigContextProvider>
                                        <ResourceControllerContextProvider GridResource={GridResource}>
                                            {children}
                                        </ResourceControllerContextProvider>
                                    </ConfigContextProvider>
                                </PaginationContextProvider>
                            </LoaderContextProvider>
                        </ThemeProvider>
                    </ParamsContextProvider>
                </ColumnsPanelContextProvider>
            </LocalizationProvider>
        </CacheProvider>
    );
};
