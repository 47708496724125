import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
} from '@mui/material';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import { useState } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

interface IToolTip {
    tooltipText: string;
    enableHintOnClick: boolean;
}

function ToolTipComponent({ tooltipText, enableHintOnClick }: IToolTip): any {
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const handleOpenDialog = () => {
        if (!enableHintOnClick) {
            return;
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <Box component='span'>
            <Tooltip
                title={
                    <Fragment>
                        <div
                            dangerouslySetInnerHTML={{ __html: t(tooltipText, { interpolation: { escapeValue: false } }) }}
                        ></div>
                    </Fragment>
                }
                sx={{ padding: 0 }}
            >
                <IconButton onClick={handleOpenDialog} data-testid={'emptooltip ' + tooltipText}>
                    <HelpOutlinedIcon fontSize='small' />
                </IconButton>
            </Tooltip>
            {/* Dialog for the tooltip*/}
            <Dialog fullWidth open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{t(`##Help`)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t(tooltipText)}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} autoFocus>
                        {t(`##OK`)}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default ToolTipComponent;
