import { AnyAction } from 'redux';
import newProductReportActionTypes from './actions';

// INITIAL STATE
const initialState: ProductReportStates = {
    loadedProductReports: [],
    loadedProductReport: undefined,
    syncData: {
        state: 'READY',
        lastUpdate: null,
    },
};

// REDUCER

const productReportReducer = (state = { ...initialState }, action: AnyAction) => {
    if (action.type === newProductReportActionTypes.LOAD_PRODUCTREPORT_LIST) {
        return { ...state, loadedProductReports: action.payload };
    }
    if (action.type === newProductReportActionTypes.LOAD_PRODUCTREPORT) {
        return { ...state, loadedProductReport: action.payload };
    }
    if (action.type === newProductReportActionTypes.UPDATE_PRODUCTREPORT_SYNC_DATA) {
        return { ...state, syncData: action.payload };
    }

    return state;
};

export default productReportReducer;
