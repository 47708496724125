import Dexie, { Table } from 'dexie';

class CustomModelFieldsDB extends Dexie {
    customModelFields!: Table<DexieCustomModelField>;

    constructor() {
        super('customModelFieldsDB');
        this.version(1).stores({
            customModelFields: `_id, customModelField.pathname`,
        });
    }
}

export const newCustomModelFieldDb = new CustomModelFieldsDB();
