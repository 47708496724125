import React, {useRef} from 'react';
import { getOrCreateViewMagicSettingsAPI, updateViewMagicSettingsAPI, ViewMagicSettings } from './viewMagicSettingAPI';

interface UseViewMagicSettingsProps {
    view?: string;
    module?: string;
    useReference?: boolean;
}

function useViewMagicSettings<T>({ view, module,useReference }: UseViewMagicSettingsProps) {
    // console.log('[DEBUG] useViewMagicSettingsProps', { view, module });

    const [viewMagicSettings, setViewMagicSettings] = React.useState<ViewMagicSettings<T> | null>(null);
    const refViewMagicSettings = useRef<ViewMagicSettings<T> | null>(null);

    const [viewSettingsReady, setViewSettingsReady] = React.useState<boolean>(false);
    const [isFirstLoad, setIsFirstLoad] = React.useState<boolean>(false);

    const [lastSettingObj, setLastSettingObj] = React.useState<any>('NA');

    const getCorrectVMC = ()=>{
        if(useReference){
            return refViewMagicSettings.current
        }
        return viewMagicSettings
    }

    React.useEffect(() => {
        if (getCorrectVMC()?.view === view && getCorrectVMC()?.module === module) {
            // we do nothing
        } else {
            setLastSettingObj('NA');
            setViewSettingsReady(false);
            if(useReference){
                refViewMagicSettings.current = null;
            }else{
                setViewMagicSettings(null);
            }

            view &&
                module &&
                getOrCreateViewMagicSettingsAPI({ view, module, setIsFirstLoad })
                    .then(settings => {
                        if (settings._id) {
                            if(useReference){
                                refViewMagicSettings.current = settings;
                            }else{
                                setViewMagicSettings(settings);

                            }

                        } else {
                            throw new Error('Failed to fetch magic settings');
                        }
                    })
                    .catch(console.error)
                    .finally(() => setViewSettingsReady(true));
        }
    }, [view, module]);

    const updateViewMagicSettings = (settingObject: T) => {
        try {
            if (JSON.stringify(settingObject) === lastSettingObj) {
                return;
            }
        } catch (error) {
            console.error(error);
        }
        let vmc = getCorrectVMC();
        if (vmc?._id) {
            // console.log('[DEBUG] updateViewMagicSettings', {
            //     ...getCorrectVMC.settingObject,
            //     ...settingObject,
            // });
            updateViewMagicSettingsAPI({
                _id: vmc._id,
                settingObject: {
                    ...vmc?.settingObject,
                    ...settingObject,
                },
            })
                .then(settings => {
                    if (settings?._id) {
                        if(useReference){
                            refViewMagicSettings.current = settings;
                        }else{
                            setViewMagicSettings(settings)

                        }
                    }
                })
                .finally(() => {
                    setViewSettingsReady(true);
                });
            setLastSettingObj(JSON.stringify(settingObject));
        }
    };

    return { viewMagicSettings, updateViewMagicSettings, viewSettingsReady,refViewMagicSettings:refViewMagicSettings.current, isFirstLoad };
}

export default useViewMagicSettings;
