import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { newSalesItemDeleteAction } from '../../Models/NewSalesItem/actions';
import { newExpenseItemDeleteAction } from '../../Models/NewExpense/actions';
import CustomReportReadOnly from '../CustomReports/CustomReportReadOnly';
import { EventStatusChipCell } from 'reactelements/src/modules/EventOverviewGrid/components-renders/event-status';
import { useTranslation } from 'reactelements/node_modules/react-i18next';


function ItemCard({
    item,
    aggregate,
    onlineItems,
    isOnline,
    fullitem,
    activeAccordian,
    handleActiveAccordianChange,
    handleUpdateSaleObj,
    setReRenderList,
    type,
    products
}: any) {
    const { t } = useTranslation();
    const isAccordianExpanded = activeAccordian === item?._id;
    //console.log("loadedProducts",loadedProducts);
    const product = Array.isArray(products) && products.find(prod => prod._id === item.productId);
    //console.log("loadedProduct",loadedProducts);
    // item.product = product;
    // item.fullitem = fullitem;
    let reportResults = item?._customReport?.reportResults || {};
    //console.log('modified item', item);

    let aggreagatedProducts: any = {};
    if (aggregate) {
        // item.groupArray.forEach((element: any) => {
        //     let singleproduct =
        //         Array.isArray(loadedProducts) && loadedProducts.find(dexieProd => dexieProd._id === element.productId);
        //     aggreagatedProducts[element.productId] = singleproduct;
        // });
    }
    // console.log('loadedProducts', product);
    // console.log('aggregate in item', aggregate);
    // console.log('onlineItems', onlineItems);
    // console.log('isOnline', isOnline);

    // useEffect(() => {
    //     if (aggregate) {
    //         let aggreagatedProducts: any = {};
    //         item.groupArray.forEach((element: any) => {
    //             let singleproduct =
    //                 Array.isArray(loadedProducts) && loadedProducts.find(dexieProd => dexieProd._id === element.productId);
    //             aggreagatedProducts[element.productId] = singleproduct;
    //             element.product = singleproduct;
    //             element.fullitem = fullitem;
    //         });
    //     }
    //     console.log('aggreagatedProducts in item', aggreagatedProducts);
    // }, [aggregate]);

    const handleDeleteSaleObj = (item: any) => {
        //if (!isOnline) {
            const sucessCallback = () =>{
                let sym = Symbol('');
                setReRenderList(sym);
            }
            if(type !== 'expense'){
                
                newSalesItemDeleteAction(item,sucessCallback);
            }else{
                newExpenseItemDeleteAction(item,sucessCallback);
            }
            
           
        // } else {
            
        //     API.delete('/salesItems/remove/single-delete?id='+item._id)
        //         .then(response => {
        //             return response.json();
        //         })
        //         .then(resData => {
        //             //console.log(resData);
        //             dispatch(newSalesItemDeleteAction(item));
        //             let sym = Symbol('');
        //             setReRenderList(sym);
        //         })
        //         .catch(e => {
        //             console.log(e);
        //         });
        // }
    };
    return (
        <>
            <Accordion expanded={isAccordianExpanded} onChange={handleActiveAccordianChange(item?._id)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1bh-content' id='panel1bh-header'>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Box display='flex'>
                                <Typography
                                    color='#23ABA6'
                                    sx={{
                                        width: '70%',
                                        flexShrink: 0,
                                    }}
                                >
                                    {product?.name}
                                </Typography>
                            </Box>
                        </Grid>
                        {!activeAccordian && item?.invoice_status &&
                            <Grid item xs={2}>
                                <EventStatusChipCell row={{ eventState: item?.invoice_status }} />
                            </Grid>
                        }
                        <Grid item xs='auto'>
                            <Box display='flex'>
                                <Typography
                                    style={{ background: 'grey', fontSize: '11px', color: 'white', padding: '3px 8px' }}
                                    sx={{ alignSelf: 'left' }}
                                >
                                    {aggregate ? item.aqty : item.qty}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    {aggregate && type !== 'expense' && item.productgroup && item.productgroup.length > 0 ? (
                        item.productgroup.map((singleItem: any, idx: number) => {
                            return (
                                <Grid container spacing={3} key={idx}>
                                    <Grid item xs={8}>
                                        <Grid container spacing={12}>
                                            <Grid item xs={12}>
                                                {singleItem.saleTime && (
                                                    <Typography sx={{ alignSelf: 'left', mr: 3 }}>
                                                        {t`##SaleTime`}:{' '}
                                                        {
                                                            new Date(String(singleItem.saleTime))
                                                                .toTimeString()
                                                                .split(' ')[0]
                                                        }
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid paddingTop={'2px !important'} item xs={12}>
                                                <Typography sx={{ alignSelf: 'left', mr: 3 }}>
                                                    {product && product?.product?.name}
                                                    <span>x</span> {singleItem.qty}
                                                </Typography>
                                            </Grid>
                                            {activeAccordian && item?.invoice_status &&
                                                <Grid paddingTop={'2px !important'} item xs={12}>
                                                    <EventStatusChipCell row={{ eventState: item?.invoice_status }} />
                                                </Grid>
                                            }
                                            <Grid paddingTop={'2px !important'} item xs={12}>
                                                {/* <Typography sx={{ alignSelf: 'left', mr: 3 }}>
                                                    {t`##TransactionID`}: {singleItem.saleId}
                                                </Typography> */}
                                            </Grid>
                                            <Grid paddingTop={'2px !important'} item xs={12}>
                                                {/* <Typography sx={{ alignSelf: 'left', mr: 3 }}>
                                                        Custom Report: {item.saleId}
                                                </Typography> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item xs='auto'>
                                        {isOnline ? (
                                            <EditIcon
                                                onClick={() => handleUpdateSaleObj(singleItem)}
                                                style={{ cursor: 'pointer', color: 'rgb(35, 171, 166)' }}
                                            />
                                        ) : fullitem.syncPending === 1 ? (
                                            <EditIcon
                                                onClick={() => handleUpdateSaleObj(singleItem)}
                                                style={{ cursor: 'pointer', color: 'rgb(35, 171, 166)' }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </Grid>
                                    <Grid item xs='auto'>
                                        {isOnline ? (
                                            <DeleteIcon
                                                onClick={() => handleDeleteSaleObj(singleItem)}
                                                style={{ cursor: 'pointer', color: 'rgb(211, 47, 47)' }}
                                            />
                                        ) : fullitem.syncPending === 1 ? (
                                            <DeleteIcon
                                                onClick={() => handleDeleteSaleObj(singleItem)}
                                                style={{ cursor: 'pointer', color: 'rgb(211, 47, 47)' }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </Grid> */}
                                </Grid>
                            );
                        })
                    ) : (
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <Grid container spacing={12}>
                                    <Grid item xs={12}>
                                        {item.saleTime && (
                                            <Typography sx={{ alignSelf: 'left', mr: 3 }}>
                                                {t`##SaleTime`}:{' '}
                                                {new Date(String(new Date(item.saleTime))).toTimeString().split(' ')[0]}
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid paddingTop={'2px !important'} item xs={12}>
                                        <Typography sx={{ alignSelf: 'left', mr: 3 }}>
                                            {product?.name} <span>x</span> {aggregate ? item.aqty : item.qty}
                                        </Typography>
                                    </Grid>
                                    <Grid paddingTop={'2px !important'} item xs={12}>
                                        {item.saleId && (
                                            <Typography sx={{ alignSelf: 'left', mr: 3 }}>
                                                {t`##TransactionID`}: {item.saleId}
                                            </Typography>
                                        )}
                                    </Grid>
                                    {activeAccordian && item?.invoice_status &&
                                        <Grid paddingTop={'2px !important'} item xs={12}>
                                            <EventStatusChipCell row={{ eventState: item?.invoice_status }} />
                                        </Grid>
                                    }
                                    <Grid paddingTop={'2px !important'} item xs={12}>
                                        {Object.keys(reportResults).length > 0 &&
                                            item?._id &&
                                            item?._customReport?._configcreatedTime && (
                                                <CustomReportReadOnly
                                                    reportData={reportResults}
                                                    reportConfig={item?._customReport?._configcreatedTime}
                                                />
                                            )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs='auto'>
                                {!aggregate && isOnline ? (
                                    <EditIcon
                                        onClick={() => handleUpdateSaleObj(item)}
                                        style={{ cursor: 'pointer', color: 'rgb(35, 171, 166)' }}
                                    />
                                ) : !aggregate && fullitem.syncPending === 1 ? (
                                    <EditIcon
                                        onClick={() => handleUpdateSaleObj(item)}
                                        style={{ cursor: 'pointer', color: 'rgb(35, 171, 166)' }}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Grid>
                            <Grid item xs='auto'>
                                {!aggregate && isOnline ? (
                                    <DeleteIcon
                                        onClick={() => handleDeleteSaleObj(item)}
                                        style={{ cursor: 'pointer', color: 'rgb(211, 47, 47)' }}
                                    />
                                ) : !aggregate && fullitem.syncPending === 1 ? (
                                    <DeleteIcon
                                        onClick={() => handleDeleteSaleObj(item)}
                                        style={{ cursor: 'pointer', color: 'rgb(211, 47, 47)' }}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </AccordionDetails>
            </Accordion>
        </>
    );
}

export default ItemCard;
