import * as React from 'react';
import { DataGridPro, GridColumnVisibilityModel, GridSelectionModel, GridSortModel } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { IParams, ParamsContext } from '../../components-ui/table/contexts/params';
import { useColumnDefinitions } from './modal';
import { getRowClasses } from '../EventOverviewGrid/utils/utils';
import { ClassNameMap, makeStyles } from '@mui/styles';
import API from '../../config/api';
import useViewMagicSettings from '../../hooks/useViewMagicSettings';
import { useContext, useState } from 'react';
// import { apiLoadAgencySettings } from '../../hooks/useCurrency';
import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import {apiLoadAgencySettings} from "../../hooks/useCurrency";

const useRowStyleClasses = makeStyles({
    notInTime: {
        backgroundColor: '#F6D4D1',
    },
    onTime: {
        backgroundColor: '#D8F0EF',
    },
    afterTime: {
        backgroundColor: '#ffc926a1',
    },
});

const getOverviewData = async (setData: any, urlParams: URLSearchParams, callback?: any) => {
    API.get(`/event/clients/overview`, urlParams)
        .then(res => res.json())
        .then(data => {
            // console.log('[grid DEBUG] overview data', { data });
            if (Array.isArray(data)) {
                setData && setData(data);
            } else {
                setData && setData([]);
            }

            callback && callback();
        })
        .catch(err => {
            console.error('error fetching eventOverview data', err);
        });
};

const getOverviewCountData = async (setCount: any, urlParams: URLSearchParams) => {
    API.get(`/event`, urlParams)
        .then(res => res.json())
        .then(data => {
            // console.log('[grid DEBUG] overview count data', { data });
            if (Array.isArray(data) && data[0]?.count) {
                setCount && setCount(data[0]?.count);
            } else {
                setCount && setCount(0);
            }
        })
        .catch(err => {
            console.error('error fetching eventOverview count data', err);
        });
};

export interface EventOverviewFixedProductSimpleGridViewSetting {
    columnsVisibilityModel?: GridColumnVisibilityModel;
    columnOrder?: string[];
    columnWidth?: any;
    columnNames?: any;
}

export function EventOverviewFixedProductSimpleGrid(props: Partial<IParams>) {
    const { t } = useTranslation();
    const classes: ClassNameMap = useRowStyleClasses();

    const [reRender, setReRender] = useState<undefined | Symbol>();
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridSelectionModel>([]);
    const [allselctedrow, setAllselctedrow] = React.useState<any>([]);
    const gridRefDummy = React.useRef<any>();

    // this is left here to maintain continuity with the other new table
    const params = {
        ...props,
        data: {
            url: `/event/clients/overview`,
            countUrl: `/event`,
            filters: { ...props.data?.filters, tabletype: 'new' },
        },
        moduleName: 'event-overview-fixed-product-grid',
        gridStyles: {
            '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'white',
            },
        },
        gridProps: {
            columnVisibilityModel: { bonuses: false },
            checkboxSelection: true,
            disableSelectionOnClick: true,
            autoHeight: true,
            selectionModel: rowSelectionModel,
            onSelectionModelChange: (selectionModel: any, { api }: any) => {
                setRowSelectionModel(selectionModel);
                gridRefDummy.current = api;
            },
            ...props.gridProps,
        },
        extraOptions: { ...props.extraOptions },
    } as unknown as IParams;

    React.useEffect(() => {
        if (gridRefDummy.current) {
            const rows = gridRefDummy.current?.getSelectedRows().values() || [];
            let finalarray = [...rows];
            if (finalarray.length > 0) {
                rowSelectionModel.forEach((selecteditem: any) => {
                    finalarray.forEach(element => {
                        if (element && element.hasOwnProperty('_id') && element._id === selecteditem) {
                            allselctedrow[selecteditem] = element;
                        }
                    });
                });
            }

            let sendarray: any[] = [];
            rowSelectionModel.forEach((senditem: any) => {
                let sitem = {};
                sitem = allselctedrow[senditem];
                sendarray.push(sitem);
            });

            props.callbacks?.reactSelect?.(sendarray, gridRefDummy.current, setReRender);
        }
    }, [rowSelectionModel]);

    // console.log('[grid DEBUG] parent filters', params.data.filters);

    const { viewMagicSettings, updateViewMagicSettings } =
        useViewMagicSettings<EventOverviewFixedProductSimpleGridViewSetting>({
            view: params.data.filters?.project_id,
            module: 'event-overview-client-grid',
        });
    const [updatedSettingObject, setUpdatedSettingObject] = React.useState<EventOverviewFixedProductSimpleGridViewSetting>(
        {},
    );

    const [dataReady, setDataReady] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any[]>([]);
    const [dataCount, setDataCount] = React.useState<number>(0);

    // console.log('[grid DEBUG] useViewMagicSettings', { viewMagicSettings, updatedSettingObject });
    const { columns, ready: columnsReady } = useColumnDefinitions(params, viewMagicSettings, data);

    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);

    // console.log('[grid DEBUG] pagination', { page, pageSize });
    // console.log('[grid DEBUG] readyState', { ready });

    const [pageFilters, setPageFilters] = React.useState<any>({});
    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);

    React.useEffect(() => {
        // fetch the eventOverview data
        const urlParams = new URLSearchParams();
        if (pageFilters) {
            for (const key in pageFilters) {
                if (Object.prototype.hasOwnProperty.call(pageFilters, key)) {
                    const element = pageFilters[key];
                    if (element) urlParams.set(key, element);
                }
            }
        }
        urlParams.set('limit', pageSize.toString());
        urlParams.set('skip', (page * pageSize).toString());

        // handle table sort model
        const sortParams = sortModel.length ? gridSortModelToMongooseSortString(sortModel) : '';
        if (sortParams) {
            urlParams.set('order', sortParams);
        }

        setDataReady(false);
        const postFetchCallback = () => {
            setDataReady(true);
        };
        let timeout = setTimeout(() => {
            // console.log('[grid DEBUG] fetching data');
            getOverviewData(setData, urlParams, postFetchCallback);
        }, 1000);
        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [page, pageSize, sortModel, pageFilters]);

    React.useEffect(() => {
        const fetchAgencyConfig = async () => {
            if (!window.empplanSettings) {
                await apiLoadAgencySettings();
            }
        };
        fetchAgencyConfig();
    }, []);

    React.useEffect(() => {
        // fetch the eventOverview data total count
        const urlParams = new URLSearchParams();
        if (pageFilters) {
            for (const key in pageFilters) {
                if (Object.prototype.hasOwnProperty.call(pageFilters, key)) {
                    const element = pageFilters[key];
                    if (element) urlParams.set(key, element);
                }
            }
        }
        urlParams.set('countOnly', 'true');

        let timeout = setTimeout(() => {
            // console.log('[grid DEBUG] fetching data count');
            getOverviewCountData(setDataCount, urlParams);
        }, 5000);
        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [pageFilters]);

    React.useEffect(() => {
        props?.callbacks?.updateEventsm?.(data);
    }, [data]);

    React.useEffect(() => {
        // handles the page filters refresh - checks for deep changes in the object
        // console.log('[grid DEBUG] filter effect');
        if (params.data.filters && props.extraOptions?.isFromCrm !== true) {
            if (Object.keys(pageFilters).length !== Object.keys(params.data.filters).length) {
                // console.log('[grid DEBUG] filter effect - updated');
                setPageFilters(params.data.filters);
                return;
            }
            let isUpdated = false;
            Object.keys(pageFilters).forEach(filterKey => {
                if (
                    params.data.filters &&
                    JSON.stringify(pageFilters[filterKey]) !== JSON.stringify(params.data.filters[filterKey])
                ) {
                    isUpdated = true;
                }
            });
            if (isUpdated) {
                setPageFilters(params.data.filters);
                setPage(0);
                // console.log('[grid DEBUG] filter effect - updated');
            }
        }else{
            let filters : any = {
                events: props.extraOptions?.events.length > 0 ? props.extraOptions?.events : 'noEvent',
                tabletype: 'new'
            }
            if (Object.keys(pageFilters).length !== Object.keys(filters).length) {
                // console.log('[grid DEBUG] filter effect - updated');
                setPageFilters(filters);
                return;
            }
            let isUpdated = false;
            Object.keys(pageFilters).forEach(filterKey => {
                if (
                    params.data.filters &&
                    JSON.stringify(pageFilters[filterKey]) !== JSON.stringify(filters[filterKey])
                ) {
                    isUpdated = true;
                }
            });
            if (isUpdated) {
                setPageFilters(filters);
                setPage(0);
                // console.log('[grid DEBUG] filter effect - updated');
            }
        }
    }, [params.data.filters]);

    React.useEffect(() => {
        // update the view settings
        // only works after ready - table is fully loaded
        let timeout: any = null;
        if (columnsReady) {
            timeout = setTimeout(() => {
                updateViewMagicSettings && updateViewMagicSettings(updatedSettingObject);
            }, 2000);
        }
        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [columnsReady, updatedSettingObject]);

    React.useEffect(() => {
        // used and required by the export so that header names are available at the time of export
        // does not affect frontend
        setUpdatedSettingObject &&
            setUpdatedSettingObject(o => {
                let columnNames = columns.reduce((pre, cur) => ({ ...pre, [cur.field]: cur.headerName }), {});
                columnNames = {
                    ...columnNames,
                    area: 'DistributionArea',
                    bonuses: 'Bonuses',
                    customStatuses: 'CustomStatuses',
                    date: 'Date',
                    eventStatus: 'EventStatus',
                    eventType: 'EventType',
                    locationGroups: 'LocationGroups',
                    plannedTimes: 'PlannedTimes',
                    pointOfSale: 'PointOfSale',
                    project: 'Project',
                    provision: 'Provision',
                    salesItemRevenue: 'SalesRevenue',
                    stateHistory: 'StateHistory',
                    timeCheckedIn: 'TimeCheckedIn',
                    user: 'User',
                };
                return {
                    ...o,
                    columnNames,
                };
            });
    }, [columns]);

    // console.log('Grid loading', columnsReady, viewMagicSettings, dataReady);
    // console.log({ data });
    return (
        <Box>
            {props.extraOptions?.isFromCrm && 
                <AppBar position='static'>
                    <Toolbar>
                        <Typography variant='h6' noWrap component='div' sx={{ display: { xs: 'none', sm: 'block' } }}>
                            {t('##EventOverview')} ({dataCount} {t('##Events')})
                        </Typography>
                    </Toolbar>
                </AppBar>
            }
            <div style={{ height: '80vh' }}>
                <DataGridPro
                    loading={!(columnsReady && viewMagicSettings && dataReady)}
                    localeText={{
                        columnMenuShowColumns: t('##DataGridColumnManagement'),
                        columnMenuHideColumn: t('##DataGridHide'),
                        pinToLeft: t('##DataGridPinToLeft'),
                        pinToRight: t('##DataGridPinToRight'),
                    }}
                    columns={columns}
                    rows={data}
                    rowCount={dataCount}
                    getRowId={(row: any) => row._id}
                    getRowClassName={params => classes[getRowClasses(params.row)]}
                    checkboxSelection
                    selectionModel={rowSelectionModel}
                    onSelectionModelChange={params.gridProps?.onSelectionModelChange}
                    pagination
                    paginationMode='server'
                    page={page}
                    pageSize={pageSize}
                    onPageChange={setPage}
                    onPageSizeChange={setPageSize}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    onColumnVisibilityModelChange={params => {
                        // console.log('[grid DEBUG] onColumnVisibilityModelChange', params);
                        setUpdatedSettingObject && setUpdatedSettingObject(o => ({ ...o, columnsVisibilityModel: params }));
                    }}
                    onColumnOrderChange={params => {
                        // console.log('[grid DEBUG] onColumnOrderChange before', updatedSettingObject.columnOrder);
                        const columnKeys = updatedSettingObject.columnOrder || columns.map(col => col.field);
                        const filteredKeys = columnKeys.filter(key => key !== params.field);
                        filteredKeys.splice(params.targetIndex - 1, 0, params.field);
                        // console.log('[grid DEBUG] onColumnOrderChange after', filteredKeys);
                        setUpdatedSettingObject && setUpdatedSettingObject(o => ({ ...o, columnOrder: filteredKeys }));
                    }}
                    onColumnWidthChange={params => {
                        // console.log('[grid DEBUG] onColumnWidthChange', params);
                        const columnWidths = updatedSettingObject.columnWidth || {};
                        columnWidths[params.colDef.field] = params.width;
                        setUpdatedSettingObject && setUpdatedSettingObject(o => ({ ...o, columnWidth: columnWidths }));
                    }}
                    onSortModelChange={model => {
                        // console.log('[grid DEBUG] onSortModelChange', { model });
                        setSortModel(model);
                    }}
                    disableSelectionOnClick
                    filterMode='server'
                    sortingMode='server'
                    disableMultipleColumnsFiltering
                    disableMultipleColumnsSorting
                    keepNonExistentRowsSelected
                />
            </div>
        </Box>
        
    );
}

export const gridSortModelToMongooseSortString = (sortModel: GridSortModel) => {
    return sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field.replace('.', '')}`).join(' ');
};
