import React, { useContext } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReportConfigurationContext } from '../index';
import ToolTipComponent from '../ToolTipComponent';
import { requireHelper } from '../utils/requireHelper';

export interface DropDownComponentProps {
    field: CustomReportField;
    reportData: ReportData;
    onChangeHandler: any;
}

export const DropDownComponent = ({ field, reportData, onChangeHandler }: DropDownComponentProps) => {
    const { t } = useTranslation();
    const { isDisabled } = useContext(ReportConfigurationContext);
    const requiredCondition = requireHelper(field, reportData);

    return (
        <Box display='flex' alignItems='center'>
            <FormControl fullWidth data-testid='dropdown'>
                <InputLabel id='select-label'>
                    {field.config.slug ? t(field.config.slug) : field.config.name}
                    {!!requiredCondition && (
                        <Box component='span' sx={{ color: 'red' }}>
                            {'  '}({t('##TheFieldIsRequired')})
                        </Box>
                    )}
                </InputLabel>
                <Select
                    data-testid='selectTest'
                    labelId='select-label'
                    id='simple-select'
                    variant='standard'
                    label={field.config.name}
                    value={reportData[field.config._id] || ''}
                    disabled={isDisabled || field.isReadonly}
                    required={requiredCondition}
                    onChange={e => {
                        let isFilter = field?.config?.isFilterable || false;
                        let filterData = {
                            name: field.config.name,
                            id: field.config._id,
                            value: e.target.value,
                            options: field.config?.fieldObject?.options,
                        };
                        onChangeHandler(field.config._id, e.target.value, isFilter, filterData);
                    }}
                >
                    {field.config?.fieldObject?.options?.map((value: string) => {
                        return (
                            <MenuItem data-testid={value} key={value} value={value}>
                                {t(value)}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            {field.config?.toolTipTxt !== '' && (
                <ToolTipComponent
                    enableHintOnClick={field.config.enableHintOnClick}
                    tooltipText={field.config.toolTipTxt}
                />
            )}
        </Box>
    );
};
