import { Button, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GoogleMap from '../GoogleMap';

interface EXIFParserProps {
    imageData?: VFileSystemEntry;
}

const calculateCoordinatesFromImageMetadata = (imageGPSData: any) => {
    if (imageGPSData && imageGPSData.GPSLatitude && imageGPSData.GPSLongitude) {
        var lat = imageGPSData.GPSLatitude;
        var lng = imageGPSData.GPSLongitude;
        /**
         * sometimes the lat is null,null,null
         */
        if (lat[0] === null && lat[1] === null && lat[2] === null) {
            return; //hide map in this case
        }
        //Convert the latitude and longitude to signed floating point values for Google Maps (and backend also)
        const latitude = parseFloat(lat[0]) + parseFloat(lat[1]) / 60 + parseFloat(lat[2]) / 3600;
        const longitude = parseFloat(lng[0]) + parseFloat(lng[1]) / 60 + parseFloat(lng[2]) / 3600;
        return [latitude, longitude];
    }
};

function EXIFParser({ imageData }: EXIFParserProps) {
    const { t } = useTranslation();
    const [proMode, setProMode] = React.useState(false);

    const exifKeys = Object.keys(imageData?.metaData?.exif || {});
    const imageKeys = Object.keys(imageData?.metaData?.image || {});
    const gpsKeys = Object.keys(imageData?.metaData?.gps || {});
    const makernoteKeys = Object.keys(imageData?.metaData?.makernote || {});

    const eventDistance = imageData?.imageExtra?.eventDistance;

    let gpsCoordinates = calculateCoordinatesFromImageMetadata(imageData?.metaData?.gps);
    if (!gpsCoordinates) {
        // if it's not resolved from the image metadata then we try using the context
        if (imageData?.context?.gpsLocation?.latitude && imageData?.context?.gpsLocation?.longitude) {
            gpsCoordinates = [imageData?.context?.gpsLocation?.latitude, imageData?.context?.gpsLocation?.longitude];
        }
    }

    return (
        <Grid container>
            {eventDistance && (
                <Grid container item xs={12} my={2}>
                    <Grid item sm={6} xs={12}>
                        {t('##Distance')}
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        xs={12}
                        sx={{
                            background: eventDistance > 4 ? '#DE5D53 !important' : '#59A17E !important',
                        }}
                    >
                        {eventDistance?.toFixed(2)}
                    </Grid>
                </Grid>
            )}

            {gpsCoordinates && (
                <Grid item xs={12} my={2} height='400px'>
                    <GoogleMap lat={gpsCoordinates[0]} lng={gpsCoordinates[1]} />
                    {/* DEBUG GPS Coordinates : {JSON.stringify(gpsCoordinates, null, 4)} */}
                </Grid>
            )}
            <Grid
                item
                xs={12}
                component={Button}
                onClick={() => {
                    setProMode(!proMode);
                }}
            >
                {t('##ExpertMode')} ? {proMode && t('##On')}
            </Grid>
            {proMode && (
                <>
                    {exifKeys.length && (
                        <Grid item xs={12} my={2} component='h3'>
                            EXIF Data
                        </Grid>
                    )}
                    {exifKeys.map(key => (
                        <Grid key={key} container item xs={12}>
                            <Grid item sm={6} xs={12}>
                                {key}
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                {JSON.stringify(imageData?.metaData?.exif[key])}
                            </Grid>
                        </Grid>
                    ))}
                    {imageKeys.length && (
                        <Grid item xs={12} my={2} component='h3'>
                            Image Data
                        </Grid>
                    )}
                    {imageKeys.map(key => (
                        <Grid key={key} container item xs={12}>
                            <Grid item sm={6} xs={12}>
                                {key}
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                {JSON.stringify(imageData?.metaData?.image[key])}
                            </Grid>
                        </Grid>
                    ))}
                    {gpsKeys.length && (
                        <Grid item xs={12} my={2} component='h3'>
                            GPS Data
                        </Grid>
                    )}
                    {gpsKeys.map(key => (
                        <Grid key={key} container item xs={12}>
                            <Grid item sm={6} xs={12}>
                                {key}
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                {JSON.stringify(imageData?.metaData?.gps[key])}
                            </Grid>
                        </Grid>
                    ))}
                    {imageData?.context?.gpsLocation?.latitude && (
                        <>
                            <Grid item sm={6} xs={12} my={2} component='h3'>
                                Upload - GPSLatitude
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                {imageData?.context?.gpsLocation?.latitude}
                            </Grid>
                        </>
                    )}
                    {imageData?.context?.gpsLocation?.longitude && (
                        <>
                            <Grid item sm={6} xs={12}>
                                Upload - GPSLongitude
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                {imageData?.context?.gpsLocation?.longitude}
                            </Grid>
                        </>
                    )}
                    {makernoteKeys.length && (
                        <Grid item xs={12} my={2} component='h3'>
                            Makernote Data
                        </Grid>
                    )}
                    {makernoteKeys.map(key => (
                        <Grid key={key} container item xs={12}>
                            <Grid item sm={6} xs={12}>
                                {key}
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                {JSON.stringify(imageData?.metaData?.makernote[key])}
                            </Grid>
                        </Grid>
                    ))}
                </>
            )}
            {/* <br /> */}
            {/* DEbug imageData: <pre>{JSON.stringify(imageData, null, 4)}</pre> */}
        </Grid>
    );
}

export default EXIFParser;
