import { useContext, useEffect, useState } from 'react';
import { ParamsContext } from '../contexts/params';
import { PaginationContext } from '../contexts/pagination';
import { GridColTypeDef, GridSortModel } from '@mui/x-data-grid-pro';
import { formatCurrency } from '../../../modules/EventOverviewGrid/utils/utils';
import { ConfigContext } from '../contexts/config';

export const gridSortModelToMongooseSortString = (sortModel: GridSortModel) => {
    return sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field.replace('.', '')}`).join(' ');
};
export const mongooseSortStringToGridSortModel = (mongooseSortString: string): GridSortModel => {
    return mongooseSortString.split(' ').map(str => {
        if (str.startsWith('-')) {
            return {
                field: str.slice(1),
                sort: 'desc',
            };
        } else {
            return {
                field: str,
                sort: 'asc',
            };
        }
    });
};
export function useSearchQuery() {
    const {
        data: { filters },
    } = useContext(ParamsContext);
    const { page, pageSize } = useContext(PaginationContext);
    const [searchQuery, setSearchQuery] = useState('');
    const [countSearchQuery, setCountSearchQuery] = useState('');
    const { sortModel, filterModel } = useContext(ConfigContext);

    useEffect(() => {
        const params = new URLSearchParams();
        const sortParams = sortModel.length ? gridSortModelToMongooseSortString(sortModel) : '';
        if (filters) {
            Object.keys(filters).forEach(key => {
                if (filters[key]) {
                    params.set(key, filters[key]);
                }
            });
        }

        if (sortParams) {
            params.set('order', sortParams);
        }

        filterModel?.items?.forEach(filter => {
            let val = typeof filter.value === 'object' ? filter.value._id : filter.value;
            if (val) {
                params.set(filter.columnField.slice(1), val);
            }
        });
        const countParams = new URLSearchParams(Object.fromEntries(params));
        countParams.set('countOnly', 'true');
        setCountSearchQuery(countParams.toString());
        params.set('limit', String(pageSize));
        params.set('skip', String(pageSize * page));
        setSearchQuery(params.toString());
        console.log({ searchQuery });
    }, [filters, pageSize, page, sortModel, filterModel]);

    return { searchQuery, countSearchQuery };
}
export const asCurrency: GridColTypeDef = {
    type: 'number',
    valueGetter: (params: any) => formatCurrency(params.value),
    align: 'right',
};
