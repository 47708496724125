import { EmailView } from '../../components-ui/dataView/email';
import { MongoIdView } from '../../components-ui/dataView/mongoid';
import { LanguageView } from '../../components-ui/dataView/language';
import { PhoneView } from '../../components-ui/dataView/phoneNumber';
import { LongLatView, LatLongView } from '../../components-ui/dataView/longLat';
import { ArrayGrid } from '../../components-ui/dataView/arrayGrid';
import { RatingsView } from '../../components-ui/dataView/ratings';
import { formatCurrency } from '../../modules/EventOverviewGrid/utils/utils';
import { ObjectView } from '../../components-ui/dataView/object';
import { SimpleObjectView } from '../../components-ui/dataView/simpleObject';
import { IdentifierView } from '../../components-ui/dataView/identifier';
import { DisplayPic } from '../../components-ui/dataView/displaypic';
import { ExternalLink } from '../../components-ui/dataView/externalLink';
import { LabelChip } from '../../components-ui/dataView/label';
import { TextView } from '../../components-ui/dataView/text';
import { DateView } from '../../components-ui/dataView/date';
import { BooleanView } from '../../components-ui/dataView/boolean';
import { FC, memo, ReactComponentElement } from 'react';
import { getGridStringOperators, GridCellValue, GridFilterOperator, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { DateAgeView } from '../../components-ui/dataView/dateAge';
import { EnumsView } from '../../components-ui/dataView/enums';
import ColorView from '../../components-ui/dataView/color';
import ColorPicker from '../../components-ui/dataView/dataEditors/colorPicker';

const valueComparator = (a: any, b: any) => a?.value === b?.value;
const m = (component: FC<any>) => memo(component, valueComparator);
type JoiTypes =
    | 'any'
    | 'alternatives'
    | 'array'
    | 'boolean'
    | 'binary'
    | 'date'
    | 'function'
    | 'link'
    | 'number'
    | 'object'
    | 'string'
    | 'symbol';

export type ExistingColumnTypes =
    | 'string:email'
    | 'string:mongoid'
    | 'string:language'
    | 'string:phoneNumber'
    | 'array:longLat'
    | 'array:latLong'
    | 'array:generic'
    | 'number:rating'
    | 'number:currency'
    | 'number:generic'
    | 'object:objectReducer'
    | 'object:minimal'
    | 'object:grid'
    | 'string:identifier'
    | 'string:displayPic'
    | 'string:link'
    | 'string:label'
    | 'string:generic'
    | 'string:color'
    | 'date:generic'
    | 'boolean:generic'
    | 'default';

export type ColumnTypeName = `${JoiTypes}:${string}` | 'default';
export type ExtendedColumnTypeName = `${JoiTypes}::${string}`;
export type ExtendedColumnType = Map<ExtendedColumnTypeName, ColTypeValue>;
export type OverloadColumnType = Map<ExistingColumnTypes, Pick<ColTypeValue, 'value' | 'Component'>>;
export interface ColTypeValue {
    align?: 'left' | 'right' | 'center';
    tags: Set<string>;
    value?: (value: GridValueGetterParams['value']) => GridCellValue;
    Component?: FC<any>;
    EditComponent?: FC<any>;
    filterOperators?: Array<GridFilterOperator>;
    customKeyPath?: string;
}

export const ColumnTypes: Map<ColumnTypeName, ColTypeValue> = new Map([
    [
        'string:email',
        {
            tags: new Set(['string', 'email'] as const),
            Component: m(EmailView),
        },
    ],
    [
        'string:enums',
        {
            tags: new Set(['string', 'enum'] as const),
            Component: m(EnumsView),
        },
    ],
    [
        'string:mongoid',
        {
            tags: new Set(['string', 'mongoid'] as const),
            Component: m(MongoIdView),
        },
    ],
    [
        'string:language',
        {
            tags: new Set(['string', 'language']),
            Component: m(LanguageView),
        },
    ],
    [
        'string:phoneNumber',
        {
            tags: new Set(['string', 'phoneNumber']),
            Component: m(PhoneView),
        },
    ],
    [
        'array:longLat',
        {
            tags: new Set(['array', 'longLat']),
            Component: m(LongLatView),
        },
    ],
    [
        'array:latLong',
        {
            tags: new Set(['array', 'latLong']),
            Component: m(LatLongView),
        },
    ],
    [
        'array:generic',
        {
            tags: new Set(['array']),
            Component: m(ArrayGrid),
        },
    ],
    [
        'number:rating',
        {
            tags: new Set(['number', 'rating', 'min', 'max']),
            Component: m(RatingsView),
        },
    ],
    [
        'number:currency',
        {
            tags: new Set(['number', 'currency']),
            value: (value: number) => formatCurrency(value || 0),
            align: 'right',
        },
    ],
    [
        'number:generic',
        {
            tags: new Set(['number']),
            align: 'right',
        },
    ],
    [
        'object:objectReducer',
        {
            tags: new Set(['object', 'objectReducer']),
            Component: m(ObjectView),
        },
    ],
    [
        'object:minimal',
        {
            tags: new Set(['object', 'minimal']),
            Component: m(SimpleObjectView),
        },
    ],
    [
        'object:grid',
        {
            tags: new Set(['object']),
            Component: m(ObjectView),
        },
    ],
    [
        'string:identifier',
        {
            Component: m(IdentifierView),
            tags: new Set(['string', 'identifier']),
        },
    ],
    [
        'string:displayPic',
        {
            tags: new Set(['string', 'link', 'displayPic']),
            Component: m(DisplayPic),
        },
    ],
    [
        'string:link',
        {
            Component: m(ExternalLink),
            tags: new Set(['string', 'link']),
        },
    ],
    [
        'string:label',
        {
            Component: m(LabelChip),
            tags: new Set(['string', 'label']),
        },
    ],
    [
        'string:color',
        {
            tags: new Set(['string', 'color']),
            Component: m(ColorView),
            EditComponent: m(ColorPicker),
        },
    ],
    [
        'string:generic',
        {
            tags: new Set(['string']),
            Component: m(TextView),
        },
    ],

    [
        'date:age',
        {
            tags: new Set(['date', 'age']),
            value: (value: Date) =>
                value
                    ? Math.floor((new Date().getTime() - new Date(value).getTime()) / 1000 / 60 / 60 / 24 / 365)
                    : undefined,
            align: 'right',
        },
    ],
    [
        'date:generic',
        {
            tags: new Set(['date']),
            Component: m(DateView),
        },
    ],
    [
        'boolean:generic',
        {
            tags: new Set(['boolean']),
            Component: BooleanView,
        },
    ],
    [
        'default',
        {
            tags: new Set(['json']),
            value: (value: any) => JSON.stringify(value),
        },
    ],
]);
// const g3:KeyOfMap<typeof ColumnTypes> = 'string:email'
// export type IExtendedColType = Map<Exclude<ColumnTypeName,keyof typeof ColumnTypes>,ColTypeValue>
// const g: IExtendedColType = ColumnTypes
