import * as React from 'react';
import EmpplanTheme from '../elements/ThemeWrapper/EmpplanTheme';
import { ReportConfigurationContext } from '../CustomReport';
import { CustomReportComponent } from '../CustomReport/customReportComponent';
import { isCustomReportDisabled, ReportType } from '../CustomReport/utils/reportDisableControl';
import { formatReportResultsToSave, parseSavedReportResults } from '../CustomReport/utils/reportHelpers';
import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import ProductSelect from '../ProductSelect';
import { useTranslation } from 'react-i18next';
import customReportValidator from '../CustomReport/utils/validator';
import { Product } from '../../utils/Product';
import API from '../../config/api';
import { EmpplanSnackbarContext } from '../../modules/Snackbar';
import useCurrency from '../../hooks/useCurrency';
import EmpplanGenericUpload from '../EmpplanGenericUpload';
import { PWAContext } from '../../PWA/pwaContext';
import Autocomplete from '@mui/material/Autocomplete';

export interface TransactionExpenseFormProps {
    event: { _id: string; project_id: { _id: string } };
    customReports?: Array<CustomReportConfig>;
    updateExpenseCallback: any;
    updateSaleObject?: any;
    eventState?: string;
    currentUserCanEditEventUser?: boolean;
    pwaOfflineOptions?: any;
    // cbForButton?: (validationState?: boolean, fixedTransactionsPending?: number) => void;
}

interface PartialSale {
    note: string;
}

interface ProductSale {
    qty: number;
    price: number;
    productId: Product | null;
}

const initialSaleData: PartialSale = {
    note: '',
};

const initialProductData: ProductSale = {
    qty: 1,
    price: 0.0,
    productId: null,
};

function TransactionExpenseForm({
    event,
    customReports,
    updateExpenseCallback, // call to update transaction table
    updateSaleObject,
    eventState,
    currentUserCanEditEventUser,
    pwaOfflineOptions,
}: TransactionExpenseFormProps) {
    const { t } = useTranslation();
    const pwaContext = React.useContext(PWAContext);
    const snackbarTools = React.useContext(EmpplanSnackbarContext);
    const [currency] = useCurrency();

    const [formState, setFormState] = React.useState<
        'none' | 'ready' | 'saved' | 'changed' | 'preSaving' | 'saving' | 'error'
    >('ready');
    const [taskTracker, setTaskTracker] = React.useState<Array<any>>([]); // array of ongoing tasks like uploads, etc.
    const [formHasErrors, setFormHasErrors] = React.useState<boolean>(false); // custom report validation state

    const [saleData, setSaleData] = React.useState<any>(initialSaleData);
    const [hideprice, setHideprice] = React.useState<any>(false);
    const [productData, setProductData] = React.useState<ProductSale>(initialProductData);
    const [reportData, setReportData] = React.useState<ReportData>(parseSavedReportResults({}));

    const [uploadResetCount, setUploadResetCount] = React.useState<number>(0);
    const [uploadReadyState, setUploadReadyState] = React.useState<UseFileUploadManualControlReadyState>({
        completedState: true,
        holdState: 'hold',
    });

    const [expenseDocs, setExpenseDocs] = React.useState<string[]>([]);
    // const [manualSaveHandler, setManualSaveHandler] = React.useState<
    //     (() => Promise<Array<{ result: 'success' | 'failed'; filename?: string }>>) | null
    // >(null);

    // console.log('[Debug] expenseDocs:', expenseDocs);

    const salesReportConfig: CustomReportConfig | undefined = saleData?._id
        ? (saleData?._customReport?._configcreatedTime as unknown as CustomReportConfig)
        : customReports?.find(r => r.type === 'expense');

    const resetForm = () => {
        setSaleData(() => ({
            note: '',
        }));
        setReportData({});
        setProductData({
            qty: 1,
            price: 0.0,
            productId: null,
        });
        setExpenseDocs([]);
        setUploadResetCount(c => c + 1);
        setHideprice(false);
        setFormState('none');
        setFormHasErrors(false);
        setUploadReadyState({
            completedState: true,
            holdState: 'hold',
        });
    };

    React.useEffect(() => {
        if (formState === 'none') setFormState('ready');
    }, [formState]);

    const updateSaleData = (key: string) => (event: any) => {
        const value = event?.target?.value;
        setSaleData((s: any) => ({
            ...s,
            [key]:  value ? value : '',
        }));
        setFormState(s => 'changed');
    };

    const updateProductData = (key: 'qty' | 'price' | 'saleId') => (event: any) => {
        let value = event.target.value;

        if (key === 'qty' || key === 'price') {
            value = value ? Number(value) : 0;
        }
        setProductData((s: any) => ({
            ...s,
            [key]: value,
        }));
        setFormState(s => 'changed');
    };

    const reportDataChangeHandler = (key: ReportFieldKey, value: ReportFieldValue) => {
        setReportData(s => ({ ...s, [key]: value }));
        setFormState(s => 'changed');
    };

    // used to prevent saving before uploads complete
    const taskControlHandler = {
        addTask: (s: string) => {
            setTaskTracker(t => [...t.filter(t => t !== s), s]);
        },
        removeTask: (s: string) => {
            setTaskTracker(t => t.filter(t => t !== s));
        },
    };

    // TODO: useEffect to handle update sale data and set setSaleData, setProductData, setReportData
    React.useEffect(() => {
        if (
            updateSaleObject &&
            updateSaleObject._id &&
            updateSaleObject._customReport &&
            updateSaleObject._customReport._configcreatedTime &&
            updateSaleObject._customReport._configcreatedTime.type === 'expense'
        ) {
            const tmpObj = { ...updateSaleObject };
            if (tmpObj.$$hashKey) delete tmpObj.$$hashKey;
            if (tmpObj.productId?.hasExpenseProvisionRule) {
                setHideprice(true);
            } else {
                setHideprice(false);
            }
            setSaleData(tmpObj);
            setProductData({
                price: tmpObj.price,
                qty: tmpObj.qty,
                productId: tmpObj.productId,
            });
            setReportData(parseSavedReportResults(tmpObj._customReport.reportResults));
            setExpenseDocs(tmpObj.expenseDocs);
            setUploadResetCount(c => c + 1);
            // setFormState('ready');
            setFormState('none');
            setFormHasErrors(false);
            setUploadReadyState({
                completedState: true,
                holdState: 'hold',
            });
        }
    }, [updateSaleObject]);

    React.useEffect(() => {
        if (formState === 'preSaving' && uploadReadyState.completedState) {
            formSubmitHandler();
        }
    }, [formState, uploadReadyState.completedState]);

    React.useEffect(() => {
        // recalaulate the qty whenever the report data changes
        if (salesReportConfig?.customFields && reportData) {
            // run the validator to update the errors
            const { hasErrors } = customReportValidator(salesReportConfig, reportData, {
                productId: saleData?.productId?._id,
            });
            // call the function to handle invalidated report state
            setFormHasErrors(hasErrors);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData]);

    const formSubmitHandler = async () => {
        if (formState === 'saving') return;
        setFormState('saving');
        if (!salesReportConfig) return;
        if (pwaContext?.pwa) {
            if (saleData?._id) {
                delete saleData.product;
                delete saleData.fullitem;
                const postPayload = {
                    eventId: event._id,
                    ...saleData,
                    _customReport: {
                        reportResults: formatReportResultsToSave(reportData, salesReportConfig),
                        _configcreatedTime: salesReportConfig,
                        _customReportConfig: salesReportConfig._id,
                    },
                };
                if ((postPayload?._customReport?._configcreatedTime as any)['$$hashKey']) {
                    delete (postPayload?._customReport?._configcreatedTime as any)['$$hashKey'];
                }
                let requestobj: object = {};
                requestobj = {
                    ...postPayload,
                    productId: productData?.productId?._id,
                    price: productData.price ? Number(productData.price) : 0,
                    qty: productData.qty ? Number(productData.qty) : 0,
                    expenseDocs,
                };

                // we need may be to map the report uploads if any
                if (pwaContext.pwaHelpers?.expenses?.update) {
                    await pwaContext.pwaHelpers?.expenses?.update(requestobj);
                    updateExpenseCallback && updateExpenseCallback(false);
                    snackbarTools.createToast({ type: 'success', message: t`##ExpenseAdded` });
                    resetForm();
                } else {
                    console.log('Failed to update salesitem to dexie');
                    snackbarTools.createToast({ type: 'error', message: t`##ExpenseFailed` });
                }
            } else {
                const postPayload = {
                    eventId: event._id,
                    ...saleData,
                    _customReport: {
                        reportResults: formatReportResultsToSave(reportData, salesReportConfig),
                        _configcreatedTime: salesReportConfig,
                        _customReportConfig: salesReportConfig._id,
                    },
                };
                if ((postPayload?._customReport?._configcreatedTime as any)['$$hashKey']) {
                    delete (postPayload?._customReport?._configcreatedTime as any)['$$hashKey'];
                }
                let requestobj: object = {};
                requestobj = {
                    ...postPayload,
                    productId: productData?.productId?._id,
                    price: productData.price ? Number(productData.price) : 0,
                    qty: productData.qty ? Number(productData.qty) : 0,
                    expenseDocs,
                };

                // we need may be to map the report uploads if any
                if (pwaContext.pwaHelpers?.expenses?.add) {
                    await pwaContext.pwaHelpers?.expenses?.add(requestobj);
                    updateExpenseCallback && updateExpenseCallback(false);
                    snackbarTools.createToast({ type: 'success', message: t`##ExpenseAdded` });
                    resetForm();
                } else {
                    console.log('Failed to update salesitem to dexie');
                    snackbarTools.createToast({ type: 'error', message: t`##ExpenseFailed` });
                }
            }
        } else {
            if (saleData?._id) {
                // const expenseDocs = manualSaveHandler ? (await manualSaveHandler()).map(f => f.filename) : [];
                const putPayload = saleData;
                if ((putPayload?._customReport?._configcreatedTime as any)['$$hashKey']) {
                    delete (putPayload?._customReport?._configcreatedTime as any)['$$hashKey'];
                }
                if (putPayload._customReport) {
                    putPayload._customReport.reportResults = formatReportResultsToSave(reportData, salesReportConfig);
                    API.put(`expense/${saleData._id}`, {
                        ...putPayload,
                        expenseDocs,
                        productId: productData?.productId,
                        price: productData.price,
                        qty: productData.qty,
                    })
                        .then(resp => resp.json())
                        .then((data: any) => {
                            // console.log('go here', data);
                            if (data && data.qty && data.eventId && data.productId) {
                                resetForm();
                                updateExpenseCallback && updateExpenseCallback(false);
                                snackbarTools.createToast({ type: 'success', message: t`##ExpenseAdded` });
                            } else {
                                console.log('Error updating the transaction', { data });
                                setFormState('error');
                                snackbarTools.createToast({ type: 'error', message: t`##ExpenseFailed` });
                            }
                        });
                } else {
                    console.error('Failed to update report date when updating transaction', { saleData });
                }
            } else {
                const postPayload = {
                    eventId: event._id,
                    ...saleData,
                    _customReport: {
                        reportResults: formatReportResultsToSave(reportData, salesReportConfig),
                        _configcreatedTime: salesReportConfig,
                        _customReportConfig: salesReportConfig._id,
                    },
                };
                if ((postPayload?._customReport?._configcreatedTime as any)['$$hashKey']) {
                    delete (postPayload?._customReport?._configcreatedTime as any)['$$hashKey'];
                }
                let requestobj: object = {};
                requestobj = {
                    ...postPayload,
                    productId: productData?.productId,
                    price: productData.price,
                    qty: productData.qty,
                    expenseDocs,
                };

                API.post('expense', requestobj)
                    .then(resp => resp.json())
                    .then(data => {
                        if (data && data.qty && data.eventId && data.productId) {
                            resetForm();
                            // console.log(updateExpenseCallback);
                            //updateSaleObject = data
                            updateExpenseCallback && updateExpenseCallback(false);
                            snackbarTools.createToast({ type: 'success', message: t`##ExpenseAdded` });
                        } else {
                            console.log('Error saving the transaction', { data });
                            setFormState('error');
                            snackbarTools.createToast({ type: 'error', message: t`##ExpenseFailed` });
                        }
                    })
                    .catch(err => {
                        console.log('Error creating expense', err);
                        setFormState('error');
                    });
            }
        }
    };

    const reportConfigurationContextValue = {
        isDisabled:
            isCustomReportDisabled('expense', eventState, currentUserCanEditEventUser) ||
            ['saving', 'error'].includes(formState),
    };

    return salesReportConfig && formState !== 'none' ? (
        <EmpplanTheme>
            <ReportConfigurationContext.Provider value={reportConfigurationContextValue}>
                <Grid container direction='column'>
                    <Grid key={0} item container px={2} spacing={2}>
                        <Grid item xs={3} sm={2}>
                            <TextField
                                name='qty'
                                disabled={reportConfigurationContextValue.isDisabled}
                                label={t('##Qty')}
                                variant='standard'
                                type='number'
                                value={productData.qty}
                                onChange={updateProductData('qty')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            {pwaContext?.pwa ? (
                                <Autocomplete
                                    options={pwaOfflineOptions}
                                    value={productData.productId}
                                    disabled={reportConfigurationContextValue.isDisabled}
                                    getOptionLabel={(option: any) => option && option.name}
                                    onChange={(event, value) => {
                                        if (value?.hasExpenseProvisionRule) {
                                            setHideprice(true);
                                        } else {
                                            setHideprice(false);
                                        }
                                        setProductData((s: any) => ({
                                            ...s,
                                            productId: value,
                                            price: value?.price || 0,
                                        }));
                                        setFormState(s => 'changed');
                                    }}
                                    renderInput={params => (
                                        <TextField {...params} variant='standard' label={t('##SearchByProduct')} />
                                    )}
                                />
                            ) : (
                                <ProductSelect
                                    forTransaction
                                    initialValue={productData.productId}
                                    disabled={reportConfigurationContextValue.isDisabled}
                                    projectId={event.project_id._id}
                                    onChange={(selectedProduct: any) => {
                                        if (selectedProduct?.hasExpenseProvisionRule) {
                                            setHideprice(true);
                                        } else {
                                            setHideprice(false);
                                        }
                                        setProductData((s: any) => ({
                                            ...s,
                                            productId: selectedProduct,
                                            price: selectedProduct?.price || 0,
                                        }));
                                        setFormState(s => 'changed');
                                    }}
                                    type='expense'
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                fullWidth
                                name='price'
                                label={t`##NetAmount`}
                                variant='standard'
                                type={'number'}
                                style={hideprice ? { display: 'none' } : { display: 'block' }}
                                disabled={reportConfigurationContextValue.isDisabled}
                                inputProps={{ step: '0.01' }}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        textAlign: 'right',
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            style={hideprice ? { display: 'none' } : { background: '' }}
                                            position='end'
                                        >
                                            {currency?.symbol}
                                        </InputAdornment>
                                    ),
                                }}
                                value={productData.price}
                                onChange={updateProductData('price')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <CustomReportComponent
                            reportData={reportData}
                            contextObjForUpload={{}}
                            onChangeHandler={reportDataChangeHandler}
                            taskControl={taskControlHandler}
                            reportConfig={salesReportConfig}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        py={3}
                        sx={{
                            '&.MuiGrid-item': {
                                maxWidth: '100%',
                            },
                        }}
                    >
                        <EmpplanGenericUpload
                            value={expenseDocs}
                            resetCount={uploadResetCount}
                            onChange={value => {
                                setExpenseDocs(value);
                                setFormState(s => (['preSaving', 'saving'].includes(s) ? s : 'changed'));
                            }}
                            setUploadStatus={status => {
                                // if (status) {
                                //     setFormState('saving');
                                // } else {
                                //     setFormState('changed');
                                // }
                            }}
                            remotePath='/expense/upload/data'
                            multiple
                            // manual
                            // manualSaveHandler={setManualSaveHandler}
                            options={{
                                fieldName: t('##ExpenseDocs'),
                                isReadonly: reportConfigurationContextValue.isDisabled,
                            }}
                            manualControl={{ readyState: uploadReadyState, setReadyState: setUploadReadyState }}
                        />
                    </Grid>
                    <Grid item xs={12} px={2}>
                        <TextField
                            fullWidth
                            name='note'
                            label={t('##Notes')}
                            variant='standard'
                            disabled={reportConfigurationContextValue.isDisabled}
                            value={saleData.note}
                            onChange={updateSaleData('note')}
                        />
                    </Grid>
                </Grid>
                <Grid mt={2} display='flex' justifyContent='flex-end' gap={4}>
                    <Button
                        variant='text'
                        color='error'
                        sx={{ mt: 2 }}
                        onClick={() => {
                            resetForm();
                        }}
                    >
                        {t('##Cancel')}
                    </Button>
                    <Button
                        color='secondary'
                        variant='contained'
                        sx={{ mt: 2 }}
                        disabled={
                            taskTracker.length > 0 ||
                            formState !== 'changed' ||
                            formHasErrors ||
                            !productData.productId ||
                            reportConfigurationContextValue.isDisabled
                        }
                        onClick={() => {
                            // formSubmitHandler();
                            setUploadReadyState(s => ({ ...s, holdState: 'resume' }));
                            setFormState('preSaving');
                        }}
                    >
                        {saleData?._id ? t('##Update') : t('##Add')}
                    </Button>
                </Grid>
            </ReportConfigurationContext.Provider>
        </EmpplanTheme>
    ) : null;
}

export default TransactionExpenseForm;
