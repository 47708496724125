import { newCustomReportResultDb } from './newCustomReportResultDexie';
import newCustomReportResultActionTypes, { createNewCustomReportResultAction } from './actions';
import DynTableConst from '../../Services/Constants';
import newSyncDataActionTypes from '../NewSync/actions';
import axios from 'axios';
import { setDbSyncedDown } from '../helpers';

const PaginationLimit = 20;

export const createNewCustomReportResultWrapperForDexie = (customReportResult: any) =>
    ({
        _id: customReportResult._id,
        customReportResult: customReportResult,
        syncDate: new Date(),
        event: customReportResult._event,
        syncPending: 0,
        isNewDoc: 0,
        customReportId: customReportResult?._customReport?._refconfig,
    } as DexieCustomReportResult);

export const syncAllCustomReportResults = async (dispatch: any, eventIds: Array<string>) => {
    try {
        // drop the projects in the database
        await newCustomReportResultDb.transaction('rw', newCustomReportResultDb.tables, async () => {
            await Promise.all(newCustomReportResultDb.tables.map(table => table.clear()));
        });

        // get the new projects and load them to the database
        let count = 1;
        for (let idx = 0; idx < count; idx += PaginationLimit) {
            const customReportResultsResponse = await getCustomReportResultsFetch(eventIds, idx);
            if (!customReportResultsResponse.success) throw new Error('Failed to sync custom reports');
            count = customReportResultsResponse?.count || 0;
            // console.log({ idx, projectsResponse, count });

            Array.isArray(customReportResultsResponse?.data) &&
                customReportResultsResponse.data.forEach((customReportResult: any) => {
                    customReportResult &&
                        customReportResult._id &&
                        dispatch(
                            createNewCustomReportResultAction(
                                createNewCustomReportResultWrapperForDexie(customReportResult),
                            ),
                        );
                });

            dispatch({
                type: newSyncDataActionTypes.ADD_SYNC_LOG,
                payload: { action: 'Sync newCustomReportResultDb', idx, count },
            });
        }

        dispatch({
            type: newCustomReportResultActionTypes.UPDATE_CUSTOMREPORTRESULT_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
        setDbSyncedDown('customReportResultsDB');
    } catch (error) {
        console.log(error);
        dispatch({
            type: newSyncDataActionTypes.ADD_SYNC_ERROR,
            payload: error || 'Error handled but no log available',
        });
        dispatch({
            type: newCustomReportResultActionTypes.UPDATE_CUSTOMREPORTRESULT_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
    }
};

export const getCustomReportResultsFetch = async (eventIds: Array<string>, skip: number): Promise<any> => {
    return axios
        .post(
            DynTableConst.EMPPLAN_HOST + `/customreportresult/pwa/sync?limit=${PaginationLimit}&skip=${skip}`,
            { eventIds },
            {
                withCredentials: true, // for local dev
                // for local dev
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            },
        )
        .then(response => response.data)
        .catch(err => {
            console.error(err);
            return { foo: 'broken' };
        });
};
