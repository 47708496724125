import {User, UserActionTypes} from "../UserStates/UserStatesTypes";


export enum ProjectInvitationTypes  {
    LOADMYINVITATIONS = "ProjectInvites/GET/MY",
    LOADING = "ProjectInvites/Loading",
    REJECTINVITATION = "ProjectInvites/Reject",
};

interface IGenericFields {
    [key: string]:string;
}
export type GenericFieldsType = any;

export type PointOfSale = {
    active:boolean,
    address: {
        city:string,
        additional:string,
        company:string,
        country:string,
        postal:string,
        streetnumber:string,
    },
    location?:number[],
    associatedUser:[any],
    brandNumber:[any],
    created:Date,
    distributionAreas:[string],
    genericFields: GenericFieldsType,
    _id:string,
    id:string,
    map:null|any,
    name:string,
    notes:string,
    opening_hours:{
        times:[any],
        weekDay:0
    },

}

export type ProjectDocument = {
    briefing: {
        invitetext: string,
        documents:VFileSystem[]
        attachments:VFileSystem[],
        information: string,
    },
    documents:VFileSystem[],
    files:VFileSystem[],
    _id:string,
    name:string,
    created:string
}


export enum CustomReportConfigFieldTypes  {
    "reportOnlyText"="reportOnlyText", //dont display into reporting, only visible on pdf reporting
    "stringSmall"="stringSmall",
    "stringBig"="stringBig",
    "number"="number",
    "upload"="upload",
    "ManualTimeTracking"="ManualTimeTracking"
};

export enum CustomReportReportTypes  {
    "standard"="standard",

};

export type ProjectCustomReportConfigItem = {
    created:Date,
    description:string,
    fieldType:CustomReportConfigFieldTypes,
    name:string,
    _id:string,
};

export type ProjectCustomReportItem = {
    canHasBonus:boolean,
    config:ProjectCustomReportConfigItem,
    pdf:boolean,
    required:boolean,
    _id:string
};

export type ProjectCustomReportAttributes = {
    created:Date,
    customFields:ProjectCustomReportItem[],
    description:string,
    name:string,
    type:CustomReportReportTypes,
    user:string, //user created report
    _id:string,
}

export type Project = {
    active:boolean,
    briefing:{
        attachments:[any],
        documents:[any],
        information:string,
        invitetext:string,

    },
    created:Date,
    customReports:ProjectCustomReportAttributes[],
    customStatus:[any],
    documents:[any],
    files:[any],
    name:string,
    newPaymentPlaceHolder:any,
    number:string,
    notes:string|null,
    payment:any,
    period: {
        start: string,
        end: null|Date
    },
    pointOfSales:any,
    pointOfSalesConfig:[{
       channel:{
           brandId:string,
           channelId: {
               active:boolean,
               brand:[string]
           },
       },
        country: {
            iso:string,
            name:string,
            _id:string,
        },
        distributionAreas: [
            {
                distributionAreaId:string,
                pointOfSales:[PointOfSale]
            }
        ]
    } ],
    projectStatus: [{
        status:string,
        created:Date,
        user:string,
    }],
    users:any,
    _id:string,
    reportActivation?: {
        useDigital?:boolean,
        hideUpload?:boolean,
        showTransactions?:boolean,
    },
    checkinCondition: {
        forceLocationShare:boolean,
        forceNearByLocation:boolean,
        nearByKm: boolean
    }
}


export type typeSuccessResponse = {
    successTwo:boolean,
    project: Project
}


export interface ProjectInvitationLoadAction {
    type: ProjectInvitationTypes.LOADMYINVITATIONS,
    projects: Project[]|null
}



export interface ProjectStateTypeStateContainer {
    readonly projects: Project[]| null;
    readonly loading: boolean;
    //readonly responseSuccess:typeSuccessResponse[]//?? MAYBE NOT AN GOOD IDEA
}



export interface ProjectLoadingAction {
    type:ProjectInvitationTypes.LOADING,
    loading:boolean
}

export interface RejectInvitation {
    type: ProjectInvitationTypes.REJECTINVITATION,

}




export type ProjectInvitationActions =
    | ProjectLoadingAction
    | ProjectInvitationLoadAction ;





