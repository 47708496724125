import * as React from 'react';

import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import { useLocation } from 'react-router';
import DynTableConst from '../../Services/Constants';
import { hasAccess } from 'reactelements/src/utils/helpers/roleHelper';

const IframeHolder = () => {
    const location = useLocation();
    const [iframeSrc, setIframeSrc] = React.useState('');

    React.useEffect(() => {
        // console.log('updated location', location.pathname);
        switch (location.pathname) {
            case '/calendar':
                if (hasAccess(['Events'])) {
                    setIframeSrc(DynTableConst.EMPPLAN_HOST + '?angular=true&ts=' + new Date().toString() + '#!/events');
                } else {
                    setIframeSrc('');
                }
                break;

            case '/messenger':
                if (hasAccess(['yakiChat'])) {
                    setIframeSrc(
                        DynTableConst.EMPPLAN_HOST + '?angular=true&ts=' + new Date().toString() + '#!/yakichat/chats',
                    );
                } else {
                    setIframeSrc('');
                }
                break;

            case '/myProfile':
                setIframeSrc(DynTableConst.EMPPLAN_HOST + '?angular=true&ts=' + new Date().toString() + '#!/myprofile');
                break;

            case '/invoice':
                if (hasAccess(['Invoice Administration', 'ItemsOverview', 'CreditNoteItems'], 'or')) {
                    setIframeSrc(
                        DynTableConst.EMPPLAN_HOST +
                            '?angular=true&ts=' +
                            new Date().toString() +
                            '#!/invoices/itemsoverview',
                    );
                } else {
                    setIframeSrc('');
                }
                break;

            default:
                setIframeSrc('');
        }
    }, [location]);

    return (
        <Box
            sx={{
                display: 'flex',
                minHeight: '100vh',
            }}
        >
            <Grid container spacing={0} alignItems={'flex-start'}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            minHeight: '100vh',
                        }}
                    >
                        <Box
                            sx={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                component='main'
                                sx={{
                                    flex: 1,
                                    background: 'white',
                                    //background: '#262626'
                                }}
                            >
                                <Grid
                                    container
                                    alignItems={'flex-start'}
                                    justifyContent={'flex-start'}
                                    sx={theme => ({
                                        [theme.breakpoints.up('sm')]: {
                                            minHeight: 'calc(100vh + 200px)',
                                        },
                                    })}
                                    spacing={0}
                                >
                                    <Grid
                                        item
                                        sx={{
                                            minWidth: '100%',
                                            paddingLeft: '0px',
                                            paddingRight: '0px',
                                        }}
                                        alignItems={'flex-start'}
                                        justifyContent={'flex-start'}
                                    >
                                        {iframeSrc ? (
                                            <Box
                                                component='iframe'
                                                sx={{
                                                    minHeight: '100vh',
                                                    display: 'flex',
                                                    minWidth: '100vw',
                                                    borderWidth: 0,
                                                }}
                                                key={iframeSrc}
                                                src={iframeSrc}
                                            ></Box>
                                        ) : (
                                            'Invalid iframe route'
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default IframeHolder;
