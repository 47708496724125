import React, { useState, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { t } from 'reactelements/node_modules/i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Box, Typography, Button, Link, CircularProgress, Backdrop } from '@mui/material';
import EmpplanTextField from 'reactelements/src/modules/EmpplanFormComponents/EmpplanTextField';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import MessageIcon from '@mui/icons-material/Message';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import { EmpplanSnackbarContext } from 'reactelements/src/modules/Snackbar';
import { CountryList, FormInputs } from 'reactelements/src/pages/MyEvents/components/types';
import EmpplanSelect from 'reactelements/src/modules/EmpplanFormComponents/EmpplanSelect';
import API from 'reactelements/src/config/api';
import { PointOfSale } from '../../../reactelements/src/utils/event';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

interface FormTypes {
    address: {
        country: string;
        company: string;
        streetnumber: string;
        postal: string;
        city: string;
        telephone: string;
        email: string;
    };
    notes: string;
    name: string;
}
export const EditLocation = () => {
    const { eventId, posId } = useParams<{ eventId: string; posId: string }>();
    const snackbarTools = React.useContext(EmpplanSnackbarContext);

    const navigate = useHistory();
    const [countryList, setCountryList] = useState<CountryList[]>([]);
    const [posData, setPosData] = useState<PointOfSale | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);

    const {
        handleSubmit,
        control,
        formState: { errors, isDirty },
        setValue,
        reset,
    } = useForm<any>({
        defaultValues: {
            address: {
                country: posData?.address?.country,
                company: posData?.address?.company,
                streetnumber: posData?.address?.streetnumber,
                postal: posData?.address?.postal,
                city: posData?.address?.city,
                telephone: posData?.address?.phone,
                email: posData?.address?.email,
                additional: posData?.address?.additional,
            },
            notes: posData?.notes,
            name: posData?.name,
        },
    });

    const fetchAllCountryList = async () => {
        try {
            const countryListResponse = await API.get('/countries/all');
            const countryListData = await countryListResponse.json();
            setCountryList(countryListData);
        } catch (error) {
            console.error(error);
            // failed to fetch all country list
        }
    };

    const fetchPOS = async () => {
        setLoading(true);
        if (!posId) throw new Error('point_of_sale_id not found');
        try {
            const data = await API.get(`/pointofsale/${posId}`);
            const point_of_sale = await data.json();
            setPosData(point_of_sale);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllCountryList();
        fetchPOS();
    }, [eventId, posId]);

    useEffect(() => {
        if (posData) {
            reset({
                address: {
                    country: posData?.address?.country,
                    company: posData?.address?.company,
                    streetnumber: posData?.address?.streetnumber,
                    postal: posData?.address?.postal,
                    city: posData?.address?.city,
                    telephone: posData?.address?.phone,
                    email: posData?.address?.email,
                    additional: posData?.address?.additional,
                },
                notes: posData?.notes,
                name: posData?.name,
            });
        }
    }, [posData, reset]);

    const addLocation: SubmitHandler<FormTypes> = async data => {
        setSaveLoading(true);
        try {
            const payload = {
                ...posData,
                ...data,
            };
            const result = await API.put(`/pointofsale/editpos/${posData?._id}`, payload);
            const response = await result.json();
            snackbarTools.createToast({ type: 'success', message: t('##Success') });
            navigate.goBack();
        } catch (error) {
            console.error(error);
            snackbarTools.createToast({ type: 'error', message: t('##Failed') });
        } finally {
            setSaveLoading(false);
        }
    };

    return (
        <Box p={3}>
            {loading ? (
                <Backdrop sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })} open={loading}>
                    <CircularProgress color='inherit' />
                </Backdrop>
            ) : posData ? (
                <>
                    <Box height='45px' display='flex' alignItems='center' gap={2} pb={2}>
                        <ArrowBackIcon color='secondary' onClick={() => navigate.goBack()} />
                        <Typography variant='h5' color='black'>
                            {posData.name}
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(addLocation)}>
                        <Grid container justifyContent={'start'} spacing={2}>
                            <Grid item xs={12} pt={1}>
                                <EmpplanSelect
                                    control={control}
                                    name='address.country'
                                    rules={{
                                        required: `${t('##Required')}`,
                                    }}
                                    label={t('##Country')}
                                    options={countryList.map(eachCountry => ({
                                        value: eachCountry._id,
                                        label: eachCountry.name,
                                    }))}
                                />
                            </Grid>
                            <Grid item xs={12} pt={1}>
                                <EmpplanTextField
                                    name='name'
                                    label={t('##PointOfSaleName')}
                                    control={control}
                                    rules={{
                                        required: `${t('##Required')}`,
                                    }}
                                    helperText={`${t('##Required')}`}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <EmpplanTextField name='address.company' label={t('##Company')} control={control} />
                            </Grid>
                            <Grid item xs={12}>
                                <EmpplanTextField
                                    name='address.streetnumber'
                                    label={t('##StreetAndNumber')}
                                    control={control}
                                    rules={{
                                        required: `${t('##Required')}`,
                                    }}
                                    helperText={`${t('##CheckStreetAndNumber')}`}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <EmpplanTextField
                                    control={control}
                                    name='address.additional'
                                    label={t('##MoreAddressStuff')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <EmpplanTextField
                                    name='address.postal'
                                    label={t('##Postal')}
                                    control={control}
                                    rules={{
                                        required: `${t('##Required')}`,
                                    }}
                                    helperText={`${t('##Required')}`}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <EmpplanTextField
                                    name='address.city'
                                    label={t('##City')}
                                    control={control}
                                    rules={{
                                        required: `${t('##Required')}`,
                                    }}
                                    helperText={`${t('##Required')}`}
                                />
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}>
                                <Box display='flex' gap={2}>
                                    <MessageIcon />
                                    <Typography>{t('##MoreData')}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <EmpplanTextField name='address.email' label={t('##Email')} control={control} />
                            </Grid>
                            <Grid item xs={12}>
                                <EmpplanTextField name='address.telephone' label={t('##TelPlaceholder')} control={control} />
                            </Grid>
                            <Grid item xs={12}>
                                <EmpplanTextField name='notes' label={t('##Notes')} control={control} />
                            </Grid>
                            <Grid item xs={12}>
                                {saveLoading ? (
                                    <LoadingButton
                                        loading
                                        loadingPosition='start'
                                        startIcon={<SaveIcon />}
                                        variant='contained'
                                        sx={{
                                            width: '100%',
                                            height: '55px',
                                            borderRadius: '50px',
                                        }}
                                    >
                                        {t('##Update')}
                                    </LoadingButton>
                                ) : (
                                    <Button
                                        type='submit'
                                        disabled={!isDirty}
                                        variant='contained'
                                        color='secondary'
                                        sx={{
                                            width: '100%',
                                            height: '55px',
                                            borderRadius: '50px',
                                        }}
                                    >
                                        {t('##Update')}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </>
            ) : (
                <Box
                    mt='30px'
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    minHeight={200}
                    bgcolor='#f8f8f8'
                    borderRadius={8}
                    boxShadow={2}
                    p={3}
                >
                    <Typography variant='h6' color='primary' align='center'>
                        Oops! Could not the load Location
                    </Typography>
                    <Link href={`/app#/event/${eventId}`} color='secondary'>
                        Go back and try again.
                    </Link>
                </Box>
            )}
        </Box>
    );
};
