import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
    UserLoadMeAction,
    UserStates,
    UserActionTypes,
    UserLoadingAction,
    MeanUser,
    User,
    Userabstraction,
    UserGetById,
    AddUserIdToLoad,
    LegalReturn,
} from './UserStatesTypes';
import { async } from 'q';
//import {User} from "UserModel";
import { LoginData } from '../../pages/Login/fetchLogin';
import DynTableConst from '../../Services/Constants';
import { Project } from '../ProjectInvitaions/ProjectInvitationTypes';
import Dexie from 'dexie';

interface IUserInterface {
    _id?: string;
    user: Userabstraction;
    date: Date;
}

class UserStoreD extends Dexie {
    //typescript needs here an ! to instantiate it only in the super/parent class
    public users!: Dexie.Table<IUserInterface, string>;

    constructor() {
        super('emplUsers');
        //init the tables
        this.version(2).stores({
            users: '_id, user, date',
        });
    }
}

const UserDexieInstance = new UserStoreD();

const loadUserFromDexie = async (userId: string): Promise<Userabstraction | null> => {
    return new Promise<Userabstraction | null>((resolve, reject) => {
        UserDexieInstance.users.get(userId).then(userTuble => {
            if (typeof userTuble !== 'undefined') {
                resolve(userTuble.user);
            } else {
                reject(null);
            }
        });
        /**.catch((rejected) => {

          });**/
    });
};

const addOrUpdateToDexie = async (userId: string, userItem: Userabstraction) => {
    loadUserFromDexie(userId)
        .then(item => {
            //update
            UserDexieInstance.users.update(userId, {
                user: userItem,
                date: new Date(),
            });
        })
        .catch(no => {
            //create
            UserDexieInstance.users.add({
                _id: userId,
                user: userItem,
                date: new Date(),
            });
        });
};

export const fetchLogin = async (): Promise<User> => {
    return new Promise<User>((resolve, rejcect) => {
        var request = new XMLHttpRequest();
        request.open('GET', DynTableConst.EMPPLAN_HOST + '/api/checkauthenticated', true);
        request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        request.withCredentials = true;
        request.onreadystatechange = function() {
            //Call a function when the state changes.
            if (request.readyState == 4 && request.status == 200) {
                const responseText = request.responseText;
                let jsonObject: any;
                try {
                    jsonObject = JSON.parse(responseText);
                } catch (e) {
                    console.log(e);
                    jsonObject = { foo: 'broken' };
                }
                resolve(jsonObject);
            }
            if (request.status === 401 || request.status === 500) {
                rejcect();
            }
        };
        request.send();
    });
};

async function getUserLegalData(): Promise<LegalReturn> {
    return new Promise<LegalReturn>((resolve, rejcect) => {
        var request = new XMLHttpRequest();
        request.open('GET', DynTableConst.EMPPLAN_HOST + '/api/legal', true);
        request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        request.withCredentials = true;
        request.onreadystatechange = function() {
            //Call a function when the state changes.
            if (request.readyState == 4 && request.status == 200) {
                const responseText = request.responseText;
                let jsonObject: any;
                try {
                    jsonObject = JSON.parse(responseText);
                } catch (e) {
                    console.log(e);
                    jsonObject = { foo: 'broken' };
                }
                resolve(jsonObject);
            }
            if (request.status === 401 || request.status === 500) {
                rejcect();
            }
        };
        request.send();
    });
}

export const fetchUserabstraction = async (id: string): Promise<Userabstraction> => {
    return new Promise<Userabstraction>((resolve, rejcect) => {
        var request = new XMLHttpRequest();
        request.open('GET', DynTableConst.EMPPLAN_HOST + '/userTypes/getUserByUserId/' + id, true);
        request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        request.withCredentials = true;
        request.onreadystatechange = function() {
            //Call a function when the state changes.
            if (request.readyState == 4 && request.status == 200) {
                const responseText = request.responseText;
                let jsonObject: any;
                try {
                    jsonObject = JSON.parse(responseText);
                } catch (e) {
                    console.log(e);
                    jsonObject = { foo: 'broken' };
                }
                resolve(jsonObject);
            }
            if (request.status === 401 || request.status === 500) {
                rejcect();
            }
        };
        request.send();
    });
};

async function getUserMehtod(): Promise<User | null> {
    return fetchLogin();
    /**return fetch("//localhost:3002/api/checkauthenticated")
        .then((result)=>  {
            console.log(result, 'RESULT JSON');
            return result.json();
        }).catch((e)=> {
            console.log(e, 'ERROR get user Method');
            throw (e);

        });**/
}

const loading: ActionCreator<UserLoadingAction> = () => ({
    type: UserActionTypes.LOADING,
    userLoading: true,
});

export const getUsers: ActionCreator<ThunkAction<Promise<AnyAction>, UserStates, null, UserLoadMeAction>> = () => {
    return async (dispatch: Dispatch) => {
        dispatch(loading());
        let user = await getUserMehtod();
        let legal: LegalReturn | null = null;
        console.log(user);
        if (user !== null && user.hasOwnProperty('auth')) {
            user = null;
            // redirect user to home page since not logged in
            window.location.href = '/';
        } else {
            legal = await getUserLegalData();
        }

        return dispatch({
            user,
            legal,
            type: UserActionTypes.LOADME,
        });
    };
};

export const addUserToLoadList: ActionCreator<AddUserIdToLoad> = (id: string) => ({
    type: UserActionTypes.ADDUSERTOLOAD,
    userId: id,
});

/**
 * Check in this action first if we current have an load for an given userId
 * @param userId
 */
export const getUserById: ActionCreator<ThunkAction<Promise<AnyAction>, UserStates, null, UserGetById>> = (
    userId: string,
) => {
    return async (dispatch: Dispatch) => {
        //dont load when a current load is happen
        dispatch(loading());
        let user: Userabstraction;
        try {
            let dexieUser = await loadUserFromDexie(userId);
            console.log(dexieUser, 'DEXIE');
            if (dexieUser !== null) {
                user = dexieUser;
            }
        } catch (e) {
            user = await fetchUserabstraction(userId);
            addOrUpdateToDexie(userId, user);
        } finally {
            console.log('FINALLY DISPATCH USER', user!);
            return dispatch({
                user: user!,
                type: UserActionTypes.GETUSERBYID,
            });
        }

        /**fetch("//localhost:3002/api/checkauthenticated")
         .then(response => response.json())
         .then(data => console.log(data));**/
    };
};
