/**
 * For compatability reason, i copy pasta the file from empplan/custom repo : packages/custom/events/server/models/enums.js
 * If something changes there then also change it here.
 * Paste the entire contents of the file below and 'any' type to remove ts errors
 * Change all var to let/const
 * Change `module.exports =eventEnum;` to `export default eventEnum;`
 */

/**
 * defines the static enums for
 * the event model
 */
let eventEnum:any = {
    eventState: ['Planned', 'Published', 'Rejected',
        'Accepted', 'LoggedIn', 'LoggedOut',
        'SalesReport', 'SalesReportUploaded', //missing rejected state for sales report
        'SalesReportAccepted', 'Invoiced', 'Paid', 'SalesReportRejected', 'BrandPlanned','Pending','Sold','WaitingForApproval'],
    invoiceState:['ReadyToInvoice', 'Invoiced', 'Canceled', 'Creditable', 'InProgress' ,'OnHold', 'Paused',
        'CreditableCanceled', 'InvoicedCreditableCanceled', 'CreditInvoicedCorrectedNegate','PossibleFraud'],

    paymentType: ['project', 'individual', 'personal'],
    eventStates: {
        brandplanned: 'BrandPlanned',
        planned: 'Planned',
        published: 'Published',
        rejected: 'Rejected',
        accepted: 'Accepted',
        loggedin: 'LoggedIn',
        loggedout: 'LoggedOut',
        salesreport: 'SalesReport',
        salesreportuploaded: 'SalesReportUploaded',
        salesreportaccepted: 'SalesReportAccepted',
        salesreportrejected:  'SalesReportRejected',
        invoice: 'Invoiced',
        paid: 'Paid',
        sold: 'Sold',
        deleted: 'Deleted',
        waitingForApproval:'WaitingForApproval'
    },
    seriesRepeatStates: {
        daily: 'daily',
        weekly: 'weekly'
    },
    seriesRepeat: ['daily', 'weekly'],
    eventTypes: ['Event', 'SelfManagedDistri', 'SelfManagedLocation','Sellout','FieldManagement','CreditNote'], //Event=default, SelfManagedDistri = User can plan itself events based on locations inside a distribution area
    eventTypesValues: {
        'Event':'Event',
        'SelfManagedDistri':'SelfManagedDistri',
        'SelfManagedLocation': 'SelfManagedLocation',
        'Sellout':'Sellout',
        'FieldManagement':'FieldManagement',
        'CreditNote':'CreditNote'
    },
    eventGroupTypes: ['EventGroup', 'FieldManagement'], //EventGroup=default, FieldManagement = For Field Visit only
    eventGroupValues: {
        'EventGroup':'EventGroup',
        'FieldManagement':'FieldManagement'
    },
    groupHistoryTypes: ['CreatedBy', 'Communication'], //EventGroup=default, FieldManagement = For Field Visit only
    groupHistoryValues: {
        'CreatedBy':'CreatedBy',
        'Communication':'Communication'
    },
    orHigherStates: {},
    languages:{
        'ar':{'slug':'##AR','default':'(Arabian)'},
        'hr':{'slug':'##HR','default':'(Croatian)'},
        'cn':{'slug':'##CN','default':'(Chinese)'},
        'cs':{'slug':'##CS','default':'(Czech)'},
        'da':{'slug':'##DA','default':'(Danish)'},
        'nl':{'slug':'##NL','default':'(Dutch)'},
        'en':{'slug':'##EN','default':'(English)'},
        'fi':{'slug':'##FI','default':'(Finnish)'},
        'fr':{'slug':'##FR','default':'(French)'},
        'de':{'slug':'##DE','default':'(German)'},
        'el':{'slug':'##EL','default':'(Greek)'},
        'hi':{'slug':'##HI','default':'(Hindi)'},
        'hu':{'slug':'##HU','default':'(Hungarian)'},
        'it':{'slug':'##IT','default':'(Italian)'},
        'jp':{'slug':'##JP','default':'(Japanese)'},
        'ko':{'slug':'##KO','default':'(Korean)'},
        'no':{'slug':'##Norway','default':'(Norwegian)'},
        'pl':{'slug':'##PL','default':'(Polish)'},
        'pt':{'slug':'##PT','default':'(Portuguese)'},
        'ro':{'slug':'##RO','default':'(Romanian)'},
        'ru':{'slug':'##RU','default':'(Russian)'},
        'sk':{'slug':'##SK','default':'(Slovak)'},
        'sl':{'slug':'##SL','default':'(Slovenian)'},
        'es':{'slug':'##ES','default':'(Spanish)'},
        'sv':{'slug':'##SV','default':'(Swedish)'},
        'tr':{'slug':'##TR','default':'(Turkish)'}
    }
};

function loadOrHigherStates(eventStates: typeof eventEnum.eventStates) {
    // Please do not change the first element of each array:
    let orHigherStates:any = {};
    try {
        // The very last part
        orHigherStates['PaidOrInvoiced'] = [
            eventStates.invoice,
            eventStates.paid
        ];
        // The second last part
        orHigherStates['ReportAcceptedAndHigher'] = [
            eventStates.salesreportaccepted
        ];
        orHigherStates.ReportAcceptedAndHigher =
            orHigherStates.ReportAcceptedAndHigher.concat(orHigherStates.PaidOrInvoiced);
        // The second last part - 2
        orHigherStates['ReportSubmittedAndHigher'] = [
            eventStates.salesreportuploaded,
            eventStates.salesreportrejected
        ];
        orHigherStates.ReportSubmittedAndHigher =
            orHigherStates.ReportSubmittedAndHigher.concat(orHigherStates.ReportAcceptedAndHigher);
        // The third last part
        orHigherStates['CheckedOutAndHigher'] = [
            eventStates.loggedout,
            eventStates.salesreport
        ];
        orHigherStates.CheckedOutAndHigher =
            orHigherStates.CheckedOutAndHigher.concat(orHigherStates.ReportSubmittedAndHigher);
        // The fourth last part
        orHigherStates['CheckedInAndHigher'] = [
            eventStates.loggedin
            // ,eventStates.loggedout,
            // eventStates.salesreport
        ];
        orHigherStates.CheckedInAndHigher =
            orHigherStates.CheckedInAndHigher.concat(orHigherStates.CheckedOutAndHigher);
            // orHigherStates.CheckedInAndHigher.concat(orHigherStates.ReportSubmittedAndHigher);

        orHigherStates['AcceptedAndHigher'] = [
            eventStates.accepted
        ];
        orHigherStates.AcceptedAndHigher =
            orHigherStates.AcceptedAndHigher.concat(orHigherStates.CheckedInAndHigher);
    } catch (erx) {
        console.log(erx);
    }
    return orHigherStates;
}

eventEnum.orHigherStates = loadOrHigherStates(eventEnum.eventStates);
eventEnum.orHigherStateKeys = [ // Correct ascending order of states
    'AcceptedAndHigher', 'CheckedInAndHigher', 'CheckedOutAndHigher', 'ReportSubmittedAndHigher',
    'ReportAcceptedAndHigher', 'PaidOrInvoiced'
];
eventEnum.orHigherDefault = 'ReportAcceptedAndHigher';

export default eventEnum;
