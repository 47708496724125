import { newEventActionCollectionTypes } from './actions';

export const InitialNewEventActionCollectionState: NewEventActionStates = {
    allNewEventActions: [],
    syncData: {
        state: 'READY',
        lastUpdate: null,
    },
};

export const newEventActionCollectionReducer = (state = InitialNewEventActionCollectionState, { type, payload }: any) => {
    switch (type) {
        case newEventActionCollectionTypes.GET_ALL_EVENT_ACTIONS:
            return {
                ...state,
                allNewEventActions: payload,
            };
        case newEventActionCollectionTypes.UPDATE_EVENT_ACTIONS_SYNC_DATA:
            return {
                ...state,
                syncData: payload,
            };
        case newEventActionCollectionTypes.NEW_EVENT_ACTION:
            return state;
        default:
            return state;
    }
};
