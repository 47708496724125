import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadPendingSyncData, startSyncToServer } from '../Models/NewSync/actions';
import { DetectOnline } from 'reactelements/src/hooks/detectOnline';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import { useHistory } from 'react-router';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Backdrop,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
} from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { ExpandMore } from '@mui/icons-material';
import { createSyncHistoryEntry } from '../Models/NewSync/helpers';
import CountDown from './CountDown';
import ReportGlitchButton from './ReportGlitchButton';
import API from 'reactelements/src/config/api';
import { ReportAllToGlitchService } from '../Components/PWASettings';
import { verifyDeviceSyncHash } from './pwaSyncControlHashHelpers';
import { DeviceHashInfoPromptDialog } from './DeviceHashInfoPromptDialog';
import { trackEvent } from 'reactelements/src/components-app/GAListener';
import SyncDownError from '../Components/SyncDownError';
import { getAllSyncStatuses } from '../Models/helpers';

export const PWASyncControlContext = React.createContext<PWASyncControlContextPayload>({
    isPwa: false,
    isSyncing: false,
    syncDialogOpen: false,
    uploadSyncData: undefined,
    pendingSyncUploadsCount: 0,
    modelSyncData: undefined,
    triggerAutoSync: () => {},
    startFullSync: () => {},
    startUpSync: () => {},
    isHybridSync: false,
    handleHybridModeToggle: (x, y) => {},
    deviceHashInfo: {
        status: 'Init',
        verified: false,
        deviceHash: '',
        lastChecked: 0, // new Date().getTime()
        serverHash: undefined,
    },
});

export const extractSyncDatas = (state: any) => {
    const finalObj: any = {};
    let downloadsNotReady = false;

    for (const key in state) {
        if (Object.prototype.hasOwnProperty.call(state, key)) {
            const slice = state[key];
            if (slice?.syncData?.state) {
                finalObj[key] = slice?.syncData?.state;
                if (slice?.syncData?.state === 'DOWNLOADING') downloadsNotReady = true;
            }
        }
    }

    return [finalObj, downloadsNotReady];
};

const circularAnimationCss = {
    animation: 'spin 2s linear infinite',
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(360deg)',
        },
        '100%': {
            transform: 'rotate(0deg)',
        },
    },
};

const deviceHashInfoInitState: DeviceHashInfoState = {
    status: 'Init',
    verified: false,
    deviceHash: '',
    lastChecked: 0,
    serverHash: undefined,
};

export function PWASyncControlContextWrapper(props: React.PropsWithChildren<PWASyncControlContextWrapperExtraProps>) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch: any = useDispatch();
    const { isOnline, isConnectionStable } = DetectOnline();

    const [deviceHashInfo, setDeviceHashInfo] = React.useState<DeviceHashInfoState>({ ...deviceHashInfoInitState });
    const resetDeviceHashInfo = () => {
        setDeviceHashInfo({ ...deviceHashInfoInitState });
    };

    const [triggerSyncCounter, setTriggerSyncCounter] = React.useState(0);

    const [syncDialogOpen, setSyncDialogOpen] = React.useState(false);
    const [showSyncTakingTooLongMessage, setShowSyncTakingTooLongMessage] = React.useState(false);
    const [isHybridSync, setIsHybridSync] = React.useState(false);

    const stateData = useSelector((s: any) => s);

    const [modelSyncData, downloadsNotReady] = extractSyncDatas(stateData);

    const uploadSyncData = stateData?.syncData;
    const uploadsNotReady = uploadSyncData?.syncing !== 'READY';
    const [pendingSyncUploadsCount, setPendingSyncUploadsCount] = React.useState(0);

    const isSyncing = downloadsNotReady || uploadsNotReady;

    // console.log({ downloadsNotReady, uploadsNotReady });

    const openSyncDialog = () => {
        setSyncDialogOpen(true);
    };
    const redirectToSyncProgressPage = () => {
        if (!window.routeBeforeSync) window.routeBeforeSync = history.location.pathname;
        history.push('/syncprogress');
    };

    const startFullSync = (silent?: boolean) => {
        if (!silent) openSyncDialog();
        dispatch(startSyncToServer({ resetDeviceHashInfo }));
    };
    const startUpSync = (postSyncToServerCallback: any, silent?: boolean) => {
        if (!silent) openSyncDialog();
        dispatch(startSyncToServer({ onlySyncToServer: true, postSyncToServerCallback, resetDeviceHashInfo }));
    };

    const triggerAutoSync = () => {
        setTriggerSyncCounter(triggerSyncCounter + 1);
    };

    const handleHybridModeToggle = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const settings = checked ? false : true;
        API.post(`/user/pwasettingsadd/${window?.userabstraction?._id}`, { settings })
            .then(response => {
                return response.json();
            })
            .then(result => {
                // console.log(result);
                setIsHybridSync(checked);
                createSyncHistoryEntry('ToggleSyncMode', { currentState: checked });
                if (checked === false) {
                    startFullSync();
                } else {
                    triggerAutoSync();
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    const getPwaSettings = async () => {
        let tmp = !!window.empplanSettings.hybridPWA;
        try {
            if (window?.userabstraction?._id) {
                const res = await API.get(`/user/pwasettings/${window.userabstraction._id}`);
                const result = await res.json();
                if (result && result.data) {
                    const settings = result?.data?.PWASettings?.offlineMode;
                    if (settings) {
                        tmp = false;
                    } else {
                        tmp = true;
                    }
                }
            }
            setIsHybridSync(tmp);
        } catch (error) {
            setIsHybridSync(tmp);
        }
    };

    React.useEffect(() => {
        if (isOnline && deviceHashInfo.status === 'Init') {
            verifyDeviceSyncHash().then(value => {
                if (value.verified && typeof value.serverHash === 'number') {
                    if (value.serverHash === 0) {
                        setDeviceHashInfo({
                            ...deviceHashInfo,
                            status: 'PromptNew',
                            serverHash: value.serverHash.toString(),
                        });
                        trackEvent('PWASyncHash', 'SyncHashPromptNew', 'SyncHashPromptNew');
                        return;
                    }
                    setDeviceHashInfo({
                        status: 'Checked',
                        deviceHash: value.deviceHash || '',
                        serverHash: undefined,
                        lastChecked: new Date().getTime(),
                        verified: true,
                    });
                    trackEvent('PWASyncHash', 'SyncHashChecked', 'SyncHashChecked');
                }
                if (value.verified === false && value.serverHash && value.serverHash > 0) {
                    setDeviceHashInfo({
                        ...deviceHashInfo,
                        status: 'PromptExisting',
                        deviceHash: value.deviceHash || '',
                        serverHash: value.serverHash.toString(),
                    });
                    trackEvent('PWASyncHash', 'SyncHashPromptExisting', 'SyncHashPromptExisting');
                    return;
                }
                if (value.verified === false && typeof value.serverHash === 'undefined') {
                    setDeviceHashInfo({ ...deviceHashInfo, status: 'Error', serverHash: undefined });
                    console.error('Critical Failed to verify PWA sync hash', value);
                    trackEvent('PWASyncHash', 'SyncHashFailed', 'SyncHashFailed');
                }
            });
        }
    }, [deviceHashInfo, isOnline]);

    React.useEffect(() => {
        getPwaSettings();
    }, []);

    React.useEffect(() => {
        if (isSyncing && (!isHybridSync || (isHybridSync && downloadsNotReady))) {
            openSyncDialog();
            redirectToSyncProgressPage();
        }
        triggerAutoSync();
    }, [isSyncing, downloadsNotReady]);

    const refreshPendingSyncCount = () => {
        // console.log('Refreshing pending sync count');
        loadPendingSyncData()
            .then(pendingData => {
                const pendingSyncCount =
                    (pendingData?.salesItems || 0) +
                    (pendingData?.expenses || 0) +
                    (pendingData?.customReportResults || 0) +
                    (pendingData?.crmActions || 0) +
                    (pendingData?.eventActions || 0);
                setPendingSyncUploadsCount?.(pendingSyncCount);
            })
            .catch(err => {
                console.error('error at loadPendingSyncData ', err);
            });
    };

    React.useEffect(() => {
        const pendingSyncCountRefreshInterval = setInterval(() => {
            // console.log('Triggered pending sync count');
            refreshPendingSyncCount();
        }, 1000);

        return () => clearInterval(pendingSyncCountRefreshInterval);
    }, []);

    React.useEffect(() => {
        let tmpSyncTimeout: NodeJS.Timeout;

        if (isHybridSync && isOnline) {
            if (!isSyncing && pendingSyncUploadsCount > 0) {
                // console.log('Create tmpSyncTimeout');
                tmpSyncTimeout = setTimeout(() => {
                    console.log('Running tmpSyncTimeout - dispatching only up sync', isSyncing);
                    !isSyncing && isOnline && isConnectionStable() && startUpSync(undefined, true);
                }, 3000);
            } else {
                // console.log('Skipping create tmpSyncTimeout', { isSyncing });
            }
        }

        return () => {
            // console.log('Clearing tmpSyncTimeout if existing', !!tmpSyncTimeout);
            tmpSyncTimeout && clearTimeout(tmpSyncTimeout);
        };
    }, [pendingSyncUploadsCount, triggerSyncCounter]);

    const handleSyncDialogSuccessfulClose = () => {
        setSyncDialogOpen(false);
        if (window.routeBeforeSync && uploadSyncData?.syncErrors?.length === 0) {
            history.push(window.routeBeforeSync);
            window.routeBeforeSync = undefined;
        }
    };

    const isSyncSuccessful = !isSyncing && uploadSyncData?.syncErrors?.length === 0;

    const showSyncTooLongMessage = () => {
        if (syncDialogOpen) {
            // set show message here
            setShowSyncTakingTooLongMessage(true);
        }
    };

    React.useEffect(() => {
        let timeout: NodeJS.Timeout | undefined;

        if (syncDialogOpen) {
            // start a countdown to show the message
            timeout = setTimeout(() => {
                showSyncTooLongMessage?.();
            }, 5 * 60 * 1000); // 5 minutes
        } else {
            // hide the message
            setShowSyncTakingTooLongMessage(false);
            timeout && clearTimeout(timeout);
        }

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [syncDialogOpen]);

    return (
        <PWASyncControlContext.Provider
            key='PWASyncControlContext.Provider'
            value={{
                isPwa: true,
                isSyncing,
                syncDialogOpen,
                uploadSyncData,
                modelSyncData,
                pendingSyncUploadsCount: pendingSyncUploadsCount || 0,
                triggerAutoSync,
                startFullSync,
                startUpSync,
                isHybridSync,
                handleHybridModeToggle,
                deviceHashInfo,
            }}
        >
            <React.Fragment key='main-content'>{props.children}</React.Fragment>
            {syncDialogOpen && (
                <Backdrop key='syncdialogbackdrop' sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open>
                    <Dialog
                        key='syncdialogdialog'
                        open
                        disableEscapeKeyDown
                        disableScrollLock
                        fullWidth
                        aria-labelledby='sync-dialog-title'
                        aria-describedby='sync-dialog-description'
                    >
                        <DialogTitle id='sync-dialog-title'>
                            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                {t('##SyncDialogTitle')}
                                {isSyncing && <SyncIcon sx={circularAnimationCss} />}
                            </Stack>
                        </DialogTitle>
                        <DialogContent>
                            {isSyncing && (
                                <Alert severity='warning'>
                                    <AlertTitle>{t('##Warning')}</AlertTitle>
                                    {t('##SyncingInProgress')} — <strong>{t('##DoNotRefresh')}</strong>
                                </Alert>
                            )}

                            {uploadSyncData?.syncErrors?.length > 0 && (
                                <Alert severity='error'>
                                    <AlertTitle>{t('##Error')}</AlertTitle>
                                    <strong>{t('##ErrorOccuredWhileSyncingYourDataTitle')}</strong>
                                    <br />
                                    {t('##ErrorOccuredWhileSyncingYourDataHelpMessage')}
                                </Alert>
                            )}

                            {isSyncSuccessful && (
                                <Alert severity='success'>
                                    <AlertTitle>{t('##Success')}</AlertTitle>
                                    {t('##SyncSuccessMessage')}
                                </Alert>
                            )}

                            <DialogContentText id='alert-dialog-description'>
                                {t('##Success')}: {uploadSyncData?.syncLogs?.length}
                                <br />
                                {t('##Error')}: {uploadSyncData?.syncErrors?.length}
                                {localStorage && localStorage.getItem('debug') === 'true' && (
                                    <Accordion key='sync-debug-data' TransitionProps={{ unmountOnExit: true }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            aria-controls='debugdata-content'
                                            id='debugdata-header'
                                        >
                                            More debug data
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <pre>{JSON.stringify(modelSyncData, null, 4)}</pre>
                                            <pre>{JSON.stringify(uploadSyncData, null, 4)}</pre>
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                            </DialogContentText>

                            {showSyncTakingTooLongMessage && isSyncing && (
                                <Alert sx={{ mt: 1 }} severity='warning'>
                                    <AlertTitle>{t('##SyncTakesTooLong')}</AlertTitle>
                                    <strong>{t('##SyncTakesTooLongPressHere')}</strong>
                                    <ReportAllToGlitchService />
                                </Alert>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Box display='flex' flexDirection='column' textAlign='right' gap={2}>
                                <Box>
                                    {!isSyncing && uploadSyncData?.syncErrors?.length > 0 && (
                                        <ReportGlitchButton
                                            key='report-glitch-button'
                                            payload={JSON.stringify({
                                                modelSyncData,
                                                uploadSyncData,
                                                version: window.pwaAppVersion,
                                                user: window.userabstraction,
                                                origin: window.location.origin,
                                                syncTimestamps: getAllSyncStatuses(),
                                            })}
                                        />
                                    )}
                                </Box>
                                <Box display='flex' gap={2}>
                                    <Button
                                        color='secondary'
                                        variant='contained'
                                        onClick={() => {
                                            navigator.clipboard
                                                .writeText(
                                                    JSON.stringify({
                                                        modelSyncData,
                                                        uploadSyncData,
                                                        version: window.pwaAppVersion,
                                                        user: window.userabstraction,
                                                    }),
                                                )
                                                .then(
                                                    () => {
                                                        /* success */
                                                        console.log('Copied to clipboard');
                                                    },
                                                    () => {
                                                        /* failure */
                                                        console.log('Failed copying to clipboard');
                                                    },
                                                );
                                        }}
                                    >
                                        {t('##CopyLogs')}
                                    </Button>
                                    <>
                                        {!isSyncing && (
                                            <Button
                                                color='error'
                                                variant='contained'
                                                key='sync-dialog-close-button'
                                                onClick={() => {
                                                    handleSyncDialogSuccessfulClose();
                                                }}
                                                autoFocus
                                            >
                                                {isSyncSuccessful ? (
                                                    <CountDown
                                                        key='syncDialogCloseCountdownTimer'
                                                        label={t('##ClosingIn')}
                                                        onZeroCallback={handleSyncDialogSuccessfulClose}
                                                    />
                                                ) : (
                                                    <>{t('##Close')}</>
                                                )}
                                            </Button>
                                        )}
                                    </>
                                </Box>
                            </Box>
                        </DialogActions>
                    </Dialog>
                </Backdrop>
            )}
            {(deviceHashInfo.status.includes('Prompt') || deviceHashInfo.status.includes('Error')) && (
                <Backdrop key='synchashpromptbackdrop' sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 2 }} open>
                    <DeviceHashInfoPromptDialog {...{ deviceHashInfo, setDeviceHashInfo }} />
                </Backdrop>
            )}
            {!isSyncing && !syncDialogOpen && deviceHashInfo.verified && <SyncDownError />}
        </PWASyncControlContext.Provider>
    );
}
