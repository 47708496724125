import * as React from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Close, Fullscreen, HideSourceOutlined, InfoOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import EXIFParser from './EXIFParser';
import { useStyles } from '../xGridDashboard/components/styles';
import API from '../../config/api';
import { hasEventAddRole } from '../../utils/helpers/roleHelper';
import EmpplanSnackbarWrapper, { EmpplanSnackbarContext } from '../Snackbar';

interface ImageDisplayDialogProps {
    imageSrc: string; // the path / base64 string of the image
    imageData?: VFileSystemEntry; // contains the exif, distance, ... othere data, also gps data
    pwaLogo?: boolean;
}

function ImageDisplayWithDialog({ imageSrc, imageData, pwaLogo }: ImageDisplayDialogProps) {
    const { t } = useTranslation();
    const [imageDialogOpen, setImageDialogOpen] = React.useState(false);
    const [exifDataOpen, setExifDataOpen] = React.useState(false);
    const [fullscreen, setFullscreen] = React.useState(false);

    const isAdmin = hasEventAddRole();

    const classes = useStyles();

    const handleDialogClose = () => {
        setImageDialogOpen(false);
        setExifDataOpen(false);
    };

    return (
        <EmpplanSnackbarWrapper>
            <Box
                display='grid'
                alignItems='center'
                justifyContent='center'
                minWidth='150px'
                maxWidth='400px'
                height={pwaLogo ? undefined : '100%'}
            >
                <Box
                    component='img'
                    maxHeight='150px'
                    height='100%'
                    maxWidth='100%'
                    src={imageSrc}
                    alt={imageData?.originalName}
                    onClick={() => {
                        setImageDialogOpen(!imageDialogOpen);
                    }}
                    sx={{ cursor: 'pointer' }}
                />
                {isAdmin && imageData?.context?.contextType === 'events' && (imageData?.imageExtra?.isCombined || imageData?.imageExtra?.isMinified) && (
                    <ReProcessImageButton key='ReProcessImageButton' fileId={imageData._id} />
                )}
                {imageDialogOpen && (
                    <Dialog
                        onClose={() => {
                            handleDialogClose();
                        }}
                        open={imageDialogOpen}
                        fullWidth
                        fullScreen={fullscreen}
                        maxWidth='lg'
                    >
                        <DialogTitle className={classes.appTitle}>
                            <div style={{ color: '#FFFFFF' }}>{t`##ImageViewer`}</div>
                            <div style={{ display: 'flex' }}>
                                <Button
                                    className={classes.iconButton}
                                    sx={{ color: '#FFFFFF' }}
                                    onClick={() => {
                                        setFullscreen(!fullscreen);
                                    }}
                                >
                                    <Fullscreen />
                                </Button>
                                {imageData && (
                                    <Button
                                        className={classes.iconButton}
                                        sx={{ color: '#FFFFFF' }}
                                        onClick={() => {
                                            setExifDataOpen(!exifDataOpen);
                                        }}
                                    >
                                        {!exifDataOpen ? <InfoOutlined /> : <HideSourceOutlined />}
                                    </Button>
                                )}
                                <Button
                                    className={classes.iconButton}
                                    sx={{ color: '#FFFFFF' }}
                                    onClick={() => {
                                        handleDialogClose();
                                    }}
                                >
                                    <Close />
                                </Button>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} height='85vh' paddingTop='50px'>
                                <Grid
                                    item
                                    xs
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                        backgroundPosition: 'center',
                                        backgroundImage: `url('${imageSrc}')`,
                                    }}
                                />
                                {exifDataOpen && (
                                    <Grid item md={6} xs={12} height='100%' overflow='auto'>
                                        <EXIFParser imageData={imageData} />
                                    </Grid>
                                )}
                            </Grid>
                        </DialogContent>
                    </Dialog>
                )}
            </Box>
        </EmpplanSnackbarWrapper>
    );
}

export default ImageDisplayWithDialog;

function ReProcessImageButton({ fileId }: { fileId: string }) {
    const { t } = useTranslation();
    const snackbarTools = React.useContext(EmpplanSnackbarContext);

    const [loading, setLoading] = React.useState(false);

    if (!fileId) return null;

    return (
        <Button
            sx={{ position: 'fixed', right: 0 }}
            disabled={loading}
            onClick={() => {
                if (fileId) {
                    setLoading(true);
                    API.post('/customreportresult/upload/fix/' + fileId, {})
                        .then(res => res.json())
                        .then(jsonData => {
                            if (jsonData?.success) {
                                snackbarTools.createToast({
                                    type: 'success',
                                    message: t`##Success` + ` : Refresh page to see changes`,
                                });
                            } else {
                                snackbarTools.createToast({ type: 'warning', message: t`##Failed` });
                            }
                        })
                        .catch(err => {
                            console.error(err);
                            snackbarTools.createToast({ type: 'warning', message: t`##Failed` });
                        })
                        .finally(() => {
                            // reset button
                            setLoading(false);
                        });
                }
            }}
        >
            {t`##ReProcessImage`}
        </Button>
    );
}
