import { newCustomModelFieldDb } from './newCustomModelFieldDexie';
import { syncAllCustomModelFields } from './helpers';

const newCustomModelFieldActionTypes = {
    NEW_CUSTOMMODELFIELD: 'NEW_CUSTOMMODELFIELD',
    LOAD_CUSTOMMODELFIELD_LIST: 'LOAD_CUSTOMMODELFIELD_LIST',
    LOAD_CUSTOMMODELFIELD: 'LOAD_CUSTOMMODELFIELD',
    UPDATE_CUSTOMMODELFIELD_SYNC_DATA: 'UPDATE_CUSTOMMODELFIELD_SYNC_DATA',
    CLEAR_CUSTOMMODELFIELD_LIST: 'CLEAR_CUSTOMMODELFIELD_LIST',
};

export default newCustomModelFieldActionTypes;

export const createNewCustomModelFieldAction = (customModelField: DexieCustomModelField) => {
    newCustomModelFieldDb.customModelFields.add(customModelField);
    return {
        type: newCustomModelFieldActionTypes.NEW_CUSTOMMODELFIELD,
        payload: customModelField,
    };
};

export const customModelFieldAddAction = ({ type, payload }: { type: string; payload: any }) => {
    return { type, payload };
};

export const loadCustomModelFieldAction = async (dispatch: any, customModelFieldId: string | null) => {
    if (customModelFieldId !== null && customModelFieldId) {
        const customModelFields = await newCustomModelFieldDb.customModelFields.get(customModelFieldId);
        return dispatch({
            type: newCustomModelFieldActionTypes.LOAD_CUSTOMMODELFIELD,
            payload: customModelFields,
        });
    } else {
        return dispatch({
            type: newCustomModelFieldActionTypes.LOAD_CUSTOMMODELFIELD,
            payload: undefined,
        });
    }
};

export const loadCustomModelFieldListAction =
    (filters: { pathname: string; projectId?: string }) => async (dispatch: any) => {
        try {
            let customModels = await newCustomModelFieldDb.customModelFields
                .where({ 'customModelField.pathname': filters.pathname })
                .toArray();
            if (filters.projectId) {
                customModels = customModels.filter(cm => {
                    if (
                        Array.isArray(cm.customModelField?.projectVisibility) &&
                        cm.customModelField?.projectVisibility.length > 0
                    ) {
                        return cm.customModelField?.projectVisibility.some(
                            (project: any) => project._id === filters.projectId,
                        );
                    }
                    return false;
                });
            }
            dispatch?.(
                customModelFieldAddAction({
                    type: newCustomModelFieldActionTypes.LOAD_CUSTOMMODELFIELD_LIST,
                    payload: customModels,
                }),
            );
            return customModels.map(cm => cm.customModelField);
        } catch (error) {
            console.error('error while fetching customModelField for event details', error);
            dispatch?.(
                customModelFieldAddAction({ type: newCustomModelFieldActionTypes.LOAD_CUSTOMMODELFIELD_LIST, payload: [] }),
            );
            return [];
        }
    };

export const startCustomModelFieldSyncAction = (projectIds: string) => (dispatch: any) => {
    dispatch({
        type: newCustomModelFieldActionTypes.UPDATE_CUSTOMMODELFIELD_SYNC_DATA,
        payload: {
            state: 'DOWNLOADING',
            lastUpdate: new Date(),
        },
    });
    syncAllCustomModelFields(dispatch, projectIds);
};

export const clearCustomModelFieldList = () => {
    return {
        type: newCustomModelFieldActionTypes.CLEAR_CUSTOMMODELFIELD_LIST,
        payload: [],
    };
};
