import Dexie, { Table } from 'dexie';

class ProductReportsDB extends Dexie {
    productReports!: Table<DexieProductReport>;

    constructor() {
        super('productReportsDB');
        this.version(1).stores({
            productReports: `_id`,
        });
    }
}

export const newProductReportDb = new ProductReportsDB();
