import React from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PWASyncControlContext } from '../../Services/pwaSyncControlContext';
import { addDownloadTimeToDexie, addUploadTimeToDexie, getSyncTime } from '../../Models/NewSync/actions';
import { DetectOnline } from '../../../reactelements/src/hooks/detectOnline';
import SignalCellularNodataIcon from '@mui/icons-material/SignalCellularNodata';

function SyncWarning() {
    const timer = useSelector((store: any) => store?.syncData?.syncTimes);

    const { t } = useTranslation();
    const dispatch: any = useDispatch();
    const { startFullSync, deviceHashInfo, isSyncing, syncDialogOpen } = React.useContext(PWASyncControlContext);
    const { isOnline } = DetectOnline();

    const [openWarnings, setOpenWarning] = React.useState(false);

    React.useEffect(() => {
        if (window.userabstraction?._id) {
            dispatch(getSyncTime(window.userabstraction?._id));
        }
    }, []);

    React.useEffect(() => {
        if (deviceHashInfo.status !== 'Checked') return;

        const downloadEpoch = localStorage.getItem('syncdown-status-eventsDB');
        let downloadTime: number | undefined;
        if (downloadEpoch) downloadTime = parseInt(downloadEpoch);

        const uploadTime = timer?.uploadTime;
        const uploadTimeDiff = uploadTime instanceof Date ? new Date().getTime() - uploadTime.getTime() : 0;
        const downloadTimeDiff = downloadTime ? new Date().getTime() - downloadTime : 0;
        const uploadDiffHours = uploadTimeDiff / (1000 * 60 * 60);
        const downloadDiffHours = downloadTimeDiff / (1000 * 60 * 60);

        if (uploadDiffHours >= 4 || downloadDiffHours >= 4) {
            setOpenWarning(true);
        }
    }, [timer, deviceHashInfo.status]);

    const handleCloseDialog = () => {
        if (window.userabstraction?._id) {
            Promise.all([
                dispatch(addDownloadTimeToDexie(window.userabstraction?._id)),
                dispatch(addUploadTimeToDexie(window.userabstraction?._id)),
                dispatch(getSyncTime(window.userabstraction?._id)),
            ]).then(() => {
                setTimeout(() => {
                    setOpenWarning(false);
                }, 1);
            });
        } else {
            setOpenWarning(false);
        }
    };

    return (
        <Dialog fullWidth open={openWarnings && isOnline && !isSyncing && !syncDialogOpen}>
            <DialogTitle>{t('##SyncNow')}</DialogTitle>
            <DialogContent dividers>
                <Alert severity='warning'>{t('##PleaseSyncYourData')}</Alert>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='error' onClick={handleCloseDialog}>
                    {t('##Close')}
                </Button>
                {isOnline ? (
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => {
                            startFullSync();
                            setOpenWarning(false);
                        }}
                    >
                        {t('##Sync')}
                    </Button>
                ) : (
                    <Button disabled startIcon={<SignalCellularNodataIcon />}>
                        {t('##Offline')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default SyncWarning;
