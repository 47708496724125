
import { ControllerPropsWithoutRender, EmpplanFormField } from './utils'
import { Controller, ControllerFieldState, ControllerProps, ControllerRenderProps, FieldValues, UseFormStateReturn } from 'react-hook-form'
import { Checkbox, CheckboxProps, FormControl, FormControlLabel, FormHelperText, MenuItem, Select, SelectProps, Switch, SwitchProps } from '@mui/material'

interface EmpplanCheckboxProps extends ControllerPropsWithoutRender {
    renderProps?: (field?: ControllerRenderProps<FieldValues, string>,
        fieldState?: ControllerFieldState,
        formState?: UseFormStateReturn<FieldValues>) => CheckboxProps,
    helperText?: string,
    label: string,
    disabled?: boolean,

}

export default function EmpplanCheckbox(props: EmpplanCheckboxProps) {


    return (
        <Controller
            {...props}
            render={({ field, fieldState, formState }) => {
                const rProps = props?.renderProps?.(field, fieldState, formState)
                return <>

                    <Controller
                        {...props}

                        render={({ field, fieldState, formState }) => {
                            
                            return <>
                                <FormControl disabled={props.disabled}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...field}
                                                {...rProps}
                                                checked={!!field.value}
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    field.onChange(e.target.checked);
                                                }}
                                            />
                                        }
                                        label={props.label}
                                    />

                                    <FormHelperText>{props.helperText}</FormHelperText>

                                    <FormHelperText error={true}>{fieldState?.error?.message}</FormHelperText>
                                </FormControl>
                            </>
                        }}

                    />





                </>
            }}
        ></Controller>
    )
}

