import * as React from 'react';
import { UploadRenderView } from '../../modules/CustomReport/UploadRenderView';
import { Alert, Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AxiosProgressEvent } from 'axios';
import CircularProgressWithLabel from '../../components-ui/CircularProgressWithLabel';
import {
    deleteFileFromServer,
    getFileSystemDataFromDexie,
    getFileFromServer,
    uploadFile,
    uploadFileDexie,
    shouldImageOptimize,
} from './uploadHelper';
import { detectBrowser, fileToBase64, getExif, getLocationCoordinates, getOS, minifyImage } from './fileHelper';
import { generateRandomKey } from '../../modules/CustomReportDependentFields/keygen';
import DisplayElementWrapper from './DisplayElementWrapper';
import * as ReactDOM from 'react-dom';
import { PWAContext } from '../../PWA/pwaContext';

function sanitizeInitialValue(value?: string | string[]): string[] {
    if (value === 'false') value = '';
    return Array.isArray(value) ? value : typeof value === 'string' ? [value] : [];
}

/**
 * TODO: finish writing this
 *
 * initialValue :
 *
 * remotePath :
 *
 * onChange :
 *
 * setUploadStatus :
 *
 * contextObjForUpload :
 *
 * options :
 *
 * manual :
 *
 * manualState :
 *
 * multiple :
 *
 * @param {UseFileUploadProps}
 * @returns {UseFileUploadReturnArray}
 */
const useFileUpload = function ({
    initialValue,
    resetCount,
    onChange,
    setUploadStatus,
    remotePath,
    remoteDeletePath,
    contextObjForUpload,
    options,
    multiple,
    manualControl,
    mapFileIdFromUploadResponse,
}: UseFileUploadProps): UseFileUploadReturnArray {
    const { t } = useTranslation();

    // we can use this to check if the upload field is inside the PWA and then somehow use that information to direct the upload flow
    const pwaContext = React.useContext(PWAContext);

    const [fileUpdates, setFileUpdates] = React.useState<number>(0);
    const [fileData, setFileData] = React.useState<Array<UseFileUploadFileData>>(
        sanitizeInitialValue(initialValue)
            .filter(i => i)
            .map(
                id =>
                    ({
                        uid: id,
                        location: 'server',
                        state: 'toLoad',
                        displayElement: null,
                    } as UseFileUploadFileData),
            ),
    );
    const [errorElement, setErrorElement] = React.useState<JSX.Element | null>(null);

    // console.log(
    //     '[Debug] fileData',
    //     fileData.map(fd => ({
    //         state: fd.state,
    //         uid: fd.uid,
    //         location: fd.location,
    //         data: fd.data,
    //         displayElement: !!fd.displayElement,
    //     })),
    // );

    React.useEffect(() => {
        // console.log('Running initial value effect hook');
        const fileIds = sanitizeInitialValue(initialValue);
        if (resetCount && resetCount > 0) {
            if (fileIds.length > 0) {
                // console.log('Setting file upload data');
                setFileData([
                    ...fileIds
                        .filter(i => i)
                        .map(
                            id =>
                                ({
                                    uid: id,
                                    location: 'server',
                                    state: 'toLoad',
                                    displayElement: null,
                                } as UseFileUploadFileData),
                        ),
                ]);
            } else {
                // console.log('Resetting upload file data');
                setFileData([]);
            }
        }
    }, [resetCount]);

    React.useEffect(() => {
        if (fileData.length > 0) {
            fileData.forEach(data => {
                if (data.location === 'server' && data.state === 'toLoad') {
                    // load the data from the server
                    setFileData(d =>
                        d.map(fd =>
                            fd.uid === data.uid
                                ? {
                                      ...fd,
                                      state: 'loading',
                                      displayElement: <CircularProgress key={data.uid} />,
                                  }
                                : fd,
                        ),
                    );
                    loadFileFromServer(data.uid);
                }
                if (data.location === 'server' && data.state === 'ready') {
                    !data.displayElement && setDisplayElement(data.uid);
                }
                if (data.location === 'local' && data.state === 'toUpload') {
                    !data.displayElement &&
                        manualControl?.setReadyState(s => ({
                            ...s,
                            completedState: false,
                        }));
                    !data.displayElement && setFileUpdates(n => n + 1);
                    !data.displayElement && setDisplayElement(data.uid);
                    // handle upload here based on manual setting
                    if (manualControl?.readyState?.holdState === 'hold') {
                        // do nothing at this point
                    } else {
                        setFileData(d => {
                            const fileData = d.find(f => f.uid === data.uid);
                            if (fileData) {
                                fileData.state = 'uploading';
                                return [...d.filter(f => f.uid !== data.uid), fileData];
                            } else {
                                return d;
                            }
                        });
                        handleFileUpload(data.uid);
                    }
                }
                if (data.location === 'server' && data.state === 'toDelete') {
                    // handle delete API call
                    setFileData(d => [...d.filter(f => f.uid !== data.uid)]);
                    setFileUpdates(n => n + 1);
                    callFileDeleteAPI(data.uid);
                }
                if (data.location === 'local' && data.state === 'toDelete') {
                    setFileData(d => [...d.filter(f => f.uid !== data.uid)]);
                    setFileUpdates(n => n + 1);
                }
                if (data.state === 'uploadFailed') {
                    setTimeout(() => {
                        setErrorElement?.(null);
                        setFileData?.(d => [...d.filter(f => f.uid !== data.uid)]);
                        setFileUpdates?.(n => n + 1);
                    }, 3000);
                }
            });
        }
    }, [fileData, manualControl?.readyState]);

    React.useEffect(() => {
        fileUpdates > 0 && onChange(fileData.filter(f => f.location === 'server').map(f => f.uid));
        manualControl?.readyState?.holdState === 'resume' &&
            !fileData.find(fd => ['toUpload', 'uploading'].includes(fd.state)) &&
            manualControl?.setReadyState(s => ({
                ...s,
                completedState: true,
            }));
    }, [fileUpdates]);

    const loadFileFromServer = (fileId: string) => {
        let fileLoader: Promise<any>;

        if (pwaContext?.pwa && typeof fileId === 'string' && fileId.startsWith('dexie.')) {
            fileLoader = getFileSystemDataFromDexie(fileId, pwaContext);
        } else {
            fileLoader = getFileFromServer(fileId);
        }

        fileLoader
            .then((data: any) => {
                setFileData(d =>
                    d.map(fd =>
                        fd.uid === fileId
                            ? {
                                  ...fd,
                                  data: data,
                                  state: 'ready',
                                  displayElement: null,
                              }
                            : fd,
                    ),
                );
            })
            .catch(() => {
                setFileData(d =>
                    d.map(fd =>
                        fd.uid === fileId
                            ? {
                                  ...fd,
                                  state: 'errorLoading',
                                  displayElement: (
                                      <DisplayElementWrapper
                                          key={fileId}
                                          height='150px'
                                          isReadOnly={options?.isReadonly}
                                          handleDelete={() => {
                                              handleFileDelete(fileId);
                                          }}
                                      >
                                          <UploadRenderView
                                              type='other'
                                              fileSource={fileId}
                                              responseData={{
                                                  originalName: 'File not available' + (pwaContext?.pwa ? ' offline' : ''),
                                              }}
                                          />
                                      </DisplayElementWrapper>
                                  ),
                              }
                            : fd,
                    ),
                );
            });
    };

    const setDisplayElement = (fileId: string) => {
        const uidFileData = fileData.find(f => f.uid === fileId);

        if (uidFileData?.location === 'server' && uidFileData?.data) {
            const fileExtension: string | undefined = uidFileData.data?.fileExtension?.toLowerCase();
            switch (fileExtension) {
                case 'jpeg':
                case 'gif':
                case 'jfif':
                case 'jped':
                case 'webp':
                case 'svg':
                case 'png':
                case 'jpg':
                    renderPreviewForFiles('image', fileId, uidFileData.data);
                    break;
                case 'pdf':
                    renderPreviewForFiles('pdf', fileId, uidFileData.data);
                    break;
                case 'webm':
                case 'mp4':
                case 'ogg':
                case 'mp3':
                    renderPreviewForFiles('video', fileId, uidFileData.data);
                    break;
                default:
                    renderPreviewForFiles('other', fileId, uidFileData.data);
                    break;
            }
        }
        if (uidFileData?.location === 'local' && uidFileData?.data) {
            const fileName: string | undefined = uidFileData.data?.name?.name;
            const fileExtension: string | undefined = fileName?.slice(fileName.lastIndexOf('.') + 1)?.toLowerCase();
            switch (fileExtension) {
                case 'jpeg':
                case 'gif':
                case 'jfif':
                case 'jped':
                case 'webp':
                case 'svg':
                case 'png':
                case 'jpg':
                    renderPreviewForLocalFiles('image', fileId, uidFileData.data);
                    break;
                // case 'pdf':
                //     renderPreviewForLocalFiles('pdf', fileId, uidFileData.data);
                //     break;
                // case 'webm':
                // case 'mp4':
                // case 'ogg':
                // case 'mp3':
                //     renderPreviewForLocalFiles('video', fileId, uidFileData.data);
                //     break;
                default:
                    renderPreviewForLocalFiles('other', fileId, uidFileData.data);
                    break;
            }
        }
    };

    const renderPreviewForFiles = (type: string, fileId: string, imageData?: any) => {
        let fileLink: string;

        if (imageData) {
            if (pwaContext?.pwa && typeof fileId === 'string' && fileId.startsWith('dexie.')) {
                fileLink = imageData.base64;
            } else {
                fileLink = `/upload/files/get/${fileId}`;
            }
        } else {
            fileLink = fileId;
        }

        const displayEl = (
            <DisplayElementWrapper
                key={fileId}
                height='150px'
                isReadOnly={options?.isReadonly}
                handleDelete={() => {
                    handleFileDelete(fileId);
                }}
                link={fileLink}
                name={imageData?.name}
            >
                <UploadRenderView type={type} fileSource={fileLink} responseData={imageData} />
            </DisplayElementWrapper>
        );

        updateUidDisplayElement(fileId, displayEl);
    };

    const renderPreviewForLocalFiles = (type: string, fileId: string, data?: any) => {
        const displayEl = (
            <DisplayElementWrapper
                key={fileId}
                height='150px'
                isReadOnly={options?.isReadonly}
                handleDelete={() => {
                    handleFileDelete(fileId);
                }}
                name={data?.name?.name}
            >
                <UploadRenderView type={type} fileSource={data.base64} responseData={{ originalName: data.name.name }} />
            </DisplayElementWrapper>
        );
        updateUidDisplayElement(fileId, displayEl);
    };

    // this function is returned to allow adding multiple files
    const handleFilesToUpload = async (fileList: FileList | Array<File>) => {
        if (!(fileList && Array.isArray(fileList))) {
            console.log('Something wrong with filelist', { fileList });
            return;
        }
        // check for GPS permissions
        setErrorElement(null);
        let userLocation: null | any = null;
        let device = navigator.userAgent;
        let isMobile = false; //initiate as false
        // device detection
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
                navigator.userAgent,
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                navigator.userAgent.substr(0, 4),
            )
        ) {
            isMobile = true;
        }
        if (options?.forceGps) {
            userLocation = await getLocationCoordinates().catch(err => {
                console.log('error gps', err);
                return null;
            });
        } else {
            userLocation = {
                latitude: 0,
                longitude: 0,
                isGpsoffinFeild: true,
            };
            // console.log('this is called', userLocation);
        }

        if (options?.forceGps && !userLocation) {
            setErrorElement(
                <Alert key='image_alert' severity='error'>
                    {t('##PleaseShareYourLocationToProceed')}
                </Alert>,
            );
            return;
        }

        // add these files to the fileData
        fileList.forEach(async file => {
            // check if file is an image
            const acceptedMinifyImageTypes = ['image/jpeg', 'image/png'];
            let minifiedFile: File | null | void = null;
            console.log('minify', { filetype: file.type });

            // check if inside pwa
            // check minify setting here
            if (pwaContext?.pwa && shouldImageOptimize(options) && acceptedMinifyImageTypes.includes(file.type)) {
                if (true) {
                    // file is an image
                    console.log('Minify file started');
                    minifiedFile = await minifyImage(file).catch(err => {
                        console.error('failed to minify image', err);
                    });
                    console.log('Minify file completed');
                }
            }

            let deviceData: any = {};
            //add device ,os to metaData
            deviceData.device = device;
            deviceData.os = getOS();
            deviceData.browser = detectBrowser();
            deviceData.isMobile = isMobile;

            const filePayload: FileReaderType = {
                base64: await fileToBase64(minifiedFile || file),
                name: {
                    name: file.name,
                    type: file.type,
                    lastModifiedDate: file.lastModified,
                    size: file.size,
                },
                context: contextObjForUpload,
            };

            if (userLocation) {
                if (!filePayload.context) {
                    filePayload.context = {};
                }
                filePayload.context.gpsLocation = userLocation;
            }

            filePayload.context.metaData = deviceData;

            if (minifiedFile) {
                filePayload.context.isFrontendMinified = true;

                try {
                    const exif = await getExif(file);
                    // console.log('Minify extacted exif', { exif });
                    filePayload.metaData = exif;
                } catch (e) {
                    console.log(e);
                }
            }

            setFileData(d => [
                ...d,
                {
                    uid: generateRandomKey(),
                    location: 'local',
                    state: 'toUpload',
                    data: filePayload,
                    displayElement: null,
                },
            ]);
        });
    };

    const handleFileUpload = (uid: string) => {
        const uidFileData = fileData.find(f => f.uid === uid);
        if (uidFileData) {
            const handleUploadProgress = (progressEvent: AxiosProgressEvent) => {
                updateUidDisplayElement(
                    uid,
                    <Box key={uid} display='grid' alignItems='center' justifyContent='center' minWidth='100px'>
                        <CircularProgressWithLabel
                            key={uid}
                            value={progressEvent.total ? (progressEvent.loaded * 100) / progressEvent.total : 80}
                        />
                    </Box>,
                );
            };

            const uploadHandler = pwaContext?.pwa
                ? uploadFileDexie(uidFileData.data, remotePath, pwaContext)
                : uploadFile(uidFileData.data, remotePath, handleUploadProgress, mapFileIdFromUploadResponse);

            uploadHandler
                .then((uploadedFileName: string) => {
                    // handle successful upload
                    setFileData(d =>
                        d.map(fd =>
                            fd.uid === uid
                                ? {
                                      uid: uploadedFileName,
                                      location: 'server',
                                      state: 'toLoad',
                                      displayElement: null,
                                  }
                                : fd,
                        ),
                    );
                    setFileUpdates(n => n + 1);
                })
                .catch(err => {
                    console.error(err);
                    // handle failed upload
                    setErrorElement(
                        <Alert key='failed_alert' severity='error'>
                            {t('##ErroronUpload')}
                        </Alert>,
                    );
                    // add a toast here maybe
                    setFileData(d =>
                        d.map(fd =>
                            fd.uid === uid
                                ? {
                                      ...fd,
                                      state: 'uploadFailed',
                                      displayElement: null,
                                  }
                                : fd,
                        ),
                    );
                });
        }
    };

    const callFileDeleteAPI = (fileId: string) => {
        remoteDeletePath && deleteFileFromServer(remoteDeletePath(fileId));
    };

    const updateUidDisplayElement = (fileId: string, dispEl: JSX.Element | null) => {
        setFileData(d =>
            d.map(fd =>
                fd.uid === fileId
                    ? {
                          ...fd,
                          displayElement: dispEl,
                      }
                    : fd,
            ),
        );
    };

    const handleFileDelete = (uid: string) => {
        let node = document.querySelector('.biggerButton');
        if (node) {
            let dom = ReactDOM.findDOMNode(node) as HTMLButtonElement;
            if (dom) {
                dom.disabled = true;
            }
        }

        setFileData(d =>
            d.map(fd =>
                fd.uid === uid
                    ? {
                          ...fd,
                          state: 'toDelete',
                      }
                    : fd,
            ),
        );
    };

    const displayElements = fileData.map(d => d.displayElement);

    const isReady = fileData.filter(fd => ['ready', 'toLoad', 'errorLoading', 'uploadFailed'].includes(fd.state)).length;
    const pendingUploads = fileData.filter(fd => fd.location === 'local').length;

    return [
        !isReady,
        fileData.filter(f => f.location === 'server').map(f => f.uid),
        handleFilesToUpload,
        true, // to be decrepated
        displayElements,
        errorElement,
        null, // to be decrepated
    ];
};

export default useFileUpload;
