import React, { useContext } from 'react';
import moment from 'moment';
import { TextField, Typography } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import EditIcon from '@mui/icons-material/Edit';
import { EmpplanSnackbarContext } from '../../../modules/Snackbar';
import { eventStatesToAllowEditDate, handleEventDateChange, handleDateChangePWA } from '../eventHelpers';
import { useTranslation } from 'react-i18next';
import { PWAContext } from '../../../PWA/pwaContext';

interface EventDateProps {
    event: any;
    setEvent: any;
}

function EventDate({ event, setEvent }: EventDateProps) {
    const { t } = useTranslation();
    const pwaContext = useContext(PWAContext);
    const [date, setDate] = React.useState<moment.Moment | null>(moment(event?.date));
    const [loading, setLoading] = React.useState(false);
    const snackbarTools = React.useContext(EmpplanSnackbarContext);

    const promoterChangeDate = event.meta?.promoterChangeDate && eventStatesToAllowEditDate.includes(event?.eventState);

    React.useEffect(() => {
        // listen for changes in date from the checkin module
        // and reinintialize the state on this component
        setDate(moment(event?.date));
    }, [event?.date]);

    return (
        <>
            {event?.date && (
                <MobileDatePicker
                    closeOnSelect
                    value={date}
                    format='DD.MM.YYYY'
                    readOnly={!promoterChangeDate || loading}
                    onChange={newValue => {
                        setDate(newValue);
                        if (newValue?.isValid()) {
                            setLoading(true);
                            if (pwaContext?.pwa) {
                                handleDateChangePWA(event, newValue, pwaContext)
                                    .then((result: any) => {
                                        if (result.success) {
                                            setEvent((e: any) => ({ ...e, date: newValue }));
                                        } else {
                                            setDate(moment(event?.date));
                                            snackbarTools.createToast({
                                                type: 'error',
                                                message:
                                                    (result.message && t(result.message)) || 'Failed to change event date',
                                            });
                                        }
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            } else {
                                handleEventDateChange(event, newValue)
                                    .then(result => {
                                        if (result.success) {
                                            // set the parent event state date
                                            setEvent((e: any) => ({ ...e, date: newValue }));
                                        } else {
                                            setDate(moment(event?.date));
                                            snackbarTools.createToast({
                                                type: 'error',
                                                message:
                                                    (result.message && t(result.message)) || 'Failed to change event date',
                                            });
                                        }
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            }
                        }
                    }}
                    slots={{
                        textField: textFieldProps => (
                            <TextField
                                {...textFieldProps}
                                sx={{ width: 130 }}
                                variant='filled'
                                hiddenLabel
                                size='small'
                                InputProps={{
                                    ...textFieldProps.InputProps,
                                    disableUnderline: true,
                                    sx: { borderRadius: 3 },
                                    endAdornment: promoterChangeDate ? <EditIcon fontSize='small' /> : undefined,
                                }}
                            />
                        ),
                    }}
                />
            )}
        </>
    );
}

export default EventDate;
