import moment from 'moment';
import { t } from 'reactelements/node_modules/i18next';

export const renderDate = (isoDate: moment.MomentInput) => {
    if (!isoDate) return '';

    const parsedDate = moment(isoDate);
    if (!parsedDate.isValid()) return '';

    if (parsedDate.isSameOrAfter(moment().startOf('day')) && parsedDate.isSameOrBefore(moment().endOf('day'))) {
        return t('##Today') + ' ' + moment(isoDate).format('HH:mm');
    }

    if (parsedDate.isSameOrAfter(moment().startOf('year')) && parsedDate.isSameOrBefore(moment().endOf('year'))) {
        return moment(isoDate).format('DD.MM. HH:mm');
    } else {
        return moment(isoDate).format('DD.MM.YY HH:mm');
    }
};
