import { AnyAction } from 'redux';
import newCustomModelFieldActionTypes from './actions';

// INITIAL STATE
const initialState: CustomModelFieldStates = {
    loadedCustomModelFields: [],
    loadedCustomModelField: undefined,
    syncData: {
        state: 'READY',
        lastUpdate: null,
    },
};

// REDUCER

const customModelFieldReducer = (state = { ...initialState }, action: AnyAction) => {
    if (action.type === newCustomModelFieldActionTypes.LOAD_CUSTOMMODELFIELD_LIST) {
        return { ...state, loadedCustomModelFields: action.payload };
    }
    if (action.type === newCustomModelFieldActionTypes.LOAD_CUSTOMMODELFIELD) {
        return { ...state, loadedCustomModelField: action.payload };
    }
    if (action.type === newCustomModelFieldActionTypes.CLEAR_CUSTOMMODELFIELD_LIST) {
        return { ...state, loadedCustomModelFields: action.payload };
    }
    if (action.type === newCustomModelFieldActionTypes.UPDATE_CUSTOMMODELFIELD_SYNC_DATA) {
        return {
            ...state,
            syncData: action.payload,
        };
    }

    return state;
};

export default customModelFieldReducer;
