import React, { useState } from 'react'
import { IProductGroup } from '../../utils/ProductGroup';
import { Box, Button, Chip } from '@mui/material';
import { t } from 'i18next';
import { Done } from '@mui/icons-material';


interface FieldFilterProps {
  availableProductGroups: Array<IProductGroup>,
  availableCustomLabels: Array<CustomLabel>
}

export interface FieldFilter {
  productGroups: Array<IProductGroup>,
  customLabels: Array<CustomLabel>
}


export default function useFieldFilter(availableProductGroups?: Array<IProductGroup>, availableCustomLabels?: Array<CustomLabel>) {

  const [fieldFilterValue, setFieldFilterValue] = useState<FieldFilter>({ productGroups: [], customLabels: [] });


  const onChipClick = (item: IProductGroup | CustomLabel, key: 'productGroups' | 'customLabels') => {

    let oldValue = fieldFilterValue[key] as Array<any>

    if (oldValue.find((arrayItem: any) => arrayItem._id === item._id)) {
      setFieldFilterValue({
        ...fieldFilterValue,
        [key]: oldValue.filter((arrayItem: any) => arrayItem._id !== item._id)
      })
    } else {
      setFieldFilterValue({
        ...fieldFilterValue,
        [key]: [...oldValue, item]
      })
    }


  }

  const resetFilters = () => {
    setFieldFilterValue({ productGroups: [], customLabels: [] })
  }


  const FieldFilterComponent = () => {
    return <>

      {
      ((availableCustomLabels && availableCustomLabels.length>0) || 
      (availableProductGroups && availableProductGroups.length>0)) &&
      <Box display={"flex"} marginY={1} alignItems={"center"}>
        {fieldFilterValue.customLabels.length > 0 || fieldFilterValue.productGroups.length > 0 ? <Button color="error" onClick={resetFilters}>{t("##ResetFilters")}</Button> : t("##ClickToFilter") + " :"}

        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} flex={1}>
          <Box display={"inline-block"} >

            {

              availableProductGroups?.map(pg =>
                <FieldFilterChip
                  key={pg._id}
                  value={!!fieldFilterValue.productGroups.map(pg => pg._id).includes(pg._id)}
                  onClick={() => onChipClick(pg, 'productGroups')}
                  item={pg}
                ></FieldFilterChip>)


            }
          </Box>

          <Box>
            {
              availableCustomLabels?.map(cl =>
                <FieldFilterChip
                  value={!!fieldFilterValue.customLabels.map(cl => cl._id).includes(cl._id)}
                  onClick={() => onChipClick(cl, 'customLabels')}
                  item={cl}
                ></FieldFilterChip>)


            }
          </Box>

        </Box>


      </Box>}

    </>
  }

  return [
    FieldFilterComponent, fieldFilterValue] as const

}


interface FieldFilterChipProps {
  value: boolean,
  onClick: () => void,
  item: IProductGroup | CustomLabel
}


function FieldFilterChip({ value, onClick, item }: FieldFilterChipProps) {
  return (
    <Chip
      key={item._id}
      label={<Box display={"flex"} alignItems={"center"} justifyContent={"space-around"}>
        {item.slug ? t(item.slug) : item.name}
        {value && <Done color={item.textColor}></Done>}
      </Box>}
      onClick={onClick}
      sx={{
        marginX: 0.5,
        marginY:0.5,
        backgroundColor: item.bgColor,
        color: item.textColor,
        border: value ? '2px solid ' + item.textColor : ""
      }}
    />


  )
}
