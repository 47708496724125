import * as React from 'react';
import { CountryList } from '../pages/MyEvents/components/types';
import API from '../config/api';

function useCountries() {
    const [countries, setCountries] = React.useState<CountryList[]>([]);

    const fetchAllCountryList = async () => {
        try {
            if (window.countries && Array.isArray(window.countries)) {
                setCountries(window.countries);
                return;
            }
            const countryListResponse = await API.get('/countries/all');
            const countryListData = await countryListResponse.json();
            if (Array.isArray(countryListData)) {
                setCountries(countryListData);
                window.countries = countryListData;
            } else {
                throw new Error('bad country list response');
            }
        } catch (error) {
            console.error('failed to fetch all country list', error);
        }
    };

    React.useEffect(() => {
        fetchAllCountryList();
    }, []);

    return { countries };
}

export default useCountries;
