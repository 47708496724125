import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro'
import { te } from 'date-fns/locale';
import React, { useEffect, useRef } from 'react'

export default function ColorPicker(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
    const textInput = useRef(null);

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value; 
        apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 500 },);
    };

    const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        apiRef.current.stopCellEditMode({ id, field })
    }

    useEffect(() => {
       
        //@ts-ignore
        textInput?.current?.click()
        

        // textInput?.current?.on
    }, [])
    

    return (
        <div style={{
            width: "100%",
            padding: "0 16px 0 16px"
        }}>
            <input type="color" value={value}  ref={textInput} onChange={handleValueChange} onBlur={onBlur} style={{
                width: "100%"
               
            }}></input>

        </div>
    );

}
