import React from 'react';
import { Box, DialogTitle, Link } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download'
import { useStyles } from '../xGridDashboard/components/styles';

interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: (e: any) => void;
    src?: string;
    name?: string;
}

export const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;
    const classes = useStyles();

    return (
        <DialogTitle className={classes.appTitle} {...other}>
            {children}
            {onClose ? (
                <Box gap={2}>
                    <IconButton
                        aria-label='close'
                        sx={{ color: '#FFFFFF' }}
                        className={classes.iconButton}
                        component={Link}
                        href={props.src}
                        download={props.name || true}
                    >
                        <DownloadIcon />
                    </IconButton>
                    <IconButton
                        aria-label='close'
                        onClick={onClose}
                        sx={{ color: '#FFFFFF' }}
                        className={classes.iconButton}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            ) : null}
        </DialogTitle>
    );
};
