import React from 'react';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { createNewFileSystemAction, getFileSystemDataFromDexie } from './Models/NewFileSystem/actions';
import { newSalesItemDeleteAction, newSalesItemPostAction, newSalesItemPutAction } from './Models/NewSalesItem/actions';
import { changeLeadStatusAction, newCrmItemDeleteAction, newCrmItemPostAction, newCrmItemPutAction } from './Models/NewCRM/actions';
import { newExpensePostAction, newExpensePutAction } from './Models/NewExpense/actions';
import { addEventActionCollection } from './Models/NewEventActionCollection/actions';
import { closeEventAction, updateNewEvent, returnDexieEvent } from './Models/NewEvent/actions';
import { EditLocation } from './Components/EventDetails/EditLocation';

import SyncProgressHelpPage from './Components/SyncProgress';
import Drawer from './Components/LeftMenu/DrawerMenu';
import AppBar from './Components/Header/AppBarComponent';
import MyEvents from './Components/MyEvents/myEvents';
import AddNewEvent from './Components/AddEvent/addEvent';
import EventDetailPage from './Components/EventDetails/EventDetailPage';
import PWASettings from './Components/PWASettings';

import { PWAContextWrapper } from 'reactelements/src/PWA/pwaContext';
import { PWASyncControlContext } from './Services/pwaSyncControlContext';
import PwaMessenger from './Components/Messenger/Messenger';
import AddLocationContactForm from './Components/LocationContact/AddLocationContactForm';
import { LeadDetail } from './Components/DashboardItems/LeadDetailPage';
import { CrmFormWrapper } from './Components/DashboardItems/LeadMenuItems/CRMFormWrapper';
import { LeadsMessenger } from './Components/DashboardItems/LeadMenuItems/LeadsMessenger';
import SyncWarning from './Components/SyncWarning/SyncWarning';
import { Disclaimer } from './Components/Disclaimer/Disclaimer';

const HomePage = React.lazy(() => import(/* webpackChunkName: 'homepage' */ './pages/HomePage/HomePage'));

const SentryRoute = Sentry.withSentryRouting(Route);

function AppRoutes() {
    const dispatch: any = useDispatch(); // so we can use it from reactelements components
    const { startFullSync, triggerAutoSync, pendingSyncUploadsCount, isHybridSync } = React.useContext(PWASyncControlContext);

    const autoSyncWrapper =
        (fn: any) =>
        (...args: any) => {
            const result = fn(...args);
            triggerAutoSync();
            return result;
        };

    const pwaHelperFunctions = {
        createNewFileSystemAction,
        getFileSystemDataFromDexie,
        pendingSyncUploadsCount,
        salesItems: {
            add: autoSyncWrapper(newSalesItemPostAction),
            update: autoSyncWrapper(newSalesItemPutAction),
            delete: autoSyncWrapper(newSalesItemDeleteAction),
        },
        crmItems: {
            add: autoSyncWrapper(newCrmItemPostAction),
            update: autoSyncWrapper(newCrmItemPutAction),
            delete: autoSyncWrapper(newCrmItemDeleteAction),
            changeLeadStatus: autoSyncWrapper(changeLeadStatusAction),
        },
        expenses: {
            add: autoSyncWrapper(newExpensePostAction),
            update: autoSyncWrapper(newExpensePutAction),
        },
        events: {
            update: autoSyncWrapper(updateNewEvent),
            closeEvent: autoSyncWrapper(closeEventAction),
            returnDexieEvent,
        },
        eventActions: {
            add: autoSyncWrapper(addEventActionCollection),
        },
        startSync: {
            start: startFullSync,
        },
    };

    return (
        <PWAContextWrapper reduxDispatch={dispatch} reduxSelector={useSelector} pwaHelpers={pwaHelperFunctions} isHybridSync={isHybridSync}>
            <AppBar />
            <SyncWarning />
            <Disclaimer />
            <Switch>
                <SentryRoute exact path='/event/add' component={AddNewEvent} />
                <SentryRoute
                    exact
                    path={['/event/:eventId', '/event/:eventId/:reportType', '/event/:eventId/:reportType/:leadId']}
                    component={EventDetailPage}
                />
                <SentryRoute exact path='/pwaSettings' component={PWASettings} />
                <SentryRoute exact path='/syncprogress' component={SyncProgressHelpPage} />
                <SentryRoute
                    exact
                    path={['/messenger', '/messenger/:messengerView', '/messenger/:messengerView/:internalId']}
                    component={PwaMessenger}
                />
                <SentryRoute exact path='/:eventId/addLocationContact/:mode?' component={AddLocationContactForm} />
                <SentryRoute exact path='/:eventId/:posId/locationDetails' component={EditLocation} />
                <SentryRoute exact path='/leadDetail/:leadId/:projectId' component={LeadDetail} />
                <SentryRoute exact path='/crmEdit/:leadId/:projectId/:mode?' component={CrmFormWrapper} />
                <SentryRoute exact path='/crmMessenger/:leadId' component={LeadsMessenger} />
                <SentryRoute exact path='/myevents' component={MyEvents} />
                <React.Suspense fallback={<div>Loading..... Components</div>}>
                    <SentryRoute
                        exact
                        path={['/', '/dashboard', '/dashboard/:viewContent', '/dashboard/:viewContent/:subContent']}
                        component={HomePage}
                    />
                </React.Suspense>
            </Switch>
            <Drawer />
        </PWAContextWrapper>
    );
}

export default AppRoutes;
