import React from 'react';
import { Box, Fab, Grid, IconButton, Paper, TextField } from '@mui/material';
import { generateRandomKey } from '../CustomReportDependentFields/keygen';
import { useTranslation } from 'react-i18next';
import FieldConditionComponent from '../CustomReportDependentFields/FieldConditionComponent';
import { FieldConfigContext } from '../CustomReportDependentFields';
import { AddSharp, Delete } from '@mui/icons-material';

interface TargetDefinitionsHandlerProps {
    targets: Array<TargetDefinition>;
    onChange: (v: Array<TargetDefinition>) => void;
}

export const TargetDefinitionsDataContext = React.createContext<{
    selectedProducts: Array<any>;
    selectedProductGroups: Array<any>;
}>({ selectedProducts: [], selectedProductGroups: [] });

function TargetDefinitionsControl({ targets, onChange }: TargetDefinitionsHandlerProps) {
    const [selectedData, setSelectedData] = React.useState<{
        selectedProducts: Array<any>;
        selectedProductGroups: Array<any>;
    }>({ selectedProducts: [], selectedProductGroups: [] });

    const targetDefinitionChangeHandler = (reactId: string) => (value: TargetDefinition) => {
        onChange(
            targets.map(t => {
                if (t.reactId === reactId) {
                    return value;
                } else {
                    return t;
                }
            }),
        );
    };

    const targetDefinitionDeleteHandler = (reactId: string) => () => {
        onChange(targets.filter(t => t.reactId !== reactId));
    };

    React.useEffect(() => {
        const selectedProducts: Array<any> = [];
        const selectedProductGroups: Array<any> = [];
        targets.forEach(t => {
            try {
                if (
                    t.rule.filters.conditions[0].filterType === 'product' &&
                    t.rule.filters.conditions[0].condition.value[0]._id
                ) {
                    selectedProducts.push(t.rule.filters.conditions[0].condition.value[0]._id);
                }
                if (
                    t.rule.filters.conditions[0].filterType === 'product_group' &&
                    t.rule.filters.conditions[0].condition.value[0]._id
                ) {
                    selectedProductGroups.push(t.rule.filters.conditions[0].condition.value[0]._id);
                }
            } catch (e) {}
        });
        setSelectedData({ selectedProducts, selectedProductGroups });
    }, [targets]);

    return (
        <TargetDefinitionsDataContext.Provider value={selectedData}>
            <Box my={1}>
                {targets.map(target => (
                    <Paper key={'paper' + target.reactId} elevation={0}>
                        <Grid container gap={1} alignItems='center'>
                            <Grid container item xs alignItems='baseline'>
                                <TargetDefinitionControl
                                    key={'control' + target.reactId}
                                    target={target}
                                    onChange={targetDefinitionChangeHandler(target.reactId)}
                                />
                            </Grid>
                            <Grid item xs='auto'>
                                <IconButton
                                    key={'delete' + target.reactId}
                                    color='error'
                                    onClick={targetDefinitionDeleteHandler(target.reactId)}
                                    aria-label='delete'
                                >
                                    <Delete />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Paper>
                ))}
                <Fab
                    size='small'
                    color='secondary'
                    aria-label='new-target-fab'
                    onClick={() => {
                        onChange([
                            ...targets,
                            {
                                reactId: generateRandomKey(),
                                rule: {},
                                targetGoal: 0,
                            },
                        ]);
                    }}
                >
                    <AddSharp />
                </Fab>
            </Box>
        </TargetDefinitionsDataContext.Provider>
    );
}

interface TargetDefinitionControlProps {
    target: TargetDefinition;
    onChange: (v: TargetDefinition) => void;
}

function TargetDefinitionControl({ target, onChange }: TargetDefinitionControlProps) {
    const { t } = useTranslation();
    const selectedData = React.useContext(TargetDefinitionsDataContext);

    const [rule, setRule] = React.useState<any>(null);

    React.useEffect(() => {
        if (rule !== null) {
            onChange &&
                onChange({
                    ...target,
                    rule: {
                        type: 'total_qty_of_salesItem',
                        name: 'Total transactions qty',
                        filters: {
                            type: 'group',
                            operator: '$or',
                            rKey: generateRandomKey(),
                            conditions: [{ ...rule }],
                        },
                    },
                });
        } else {
            // initialize rule
            if (
                target.rule?.filters?.conditions &&
                target.rule?.filters?.conditions[0] &&
                target.rule?.filters?.conditions[0].rKey
            ) {
                setRule({ ...target.rule?.filters?.conditions[0] });
            } else {
                setRule({
                    type: 'field',
                    rKey: generateRandomKey(),
                    ftype: 'widget',
                    feildValue: 'number_of_events',
                    refField: 'product',
                    condition: {
                        value: [],
                        operator: '$in',
                    },
                    filterType: 'product',
                });
            }
        }
    }, [rule]);

    return (
        <>
            <Grid item xs={10}>
                <FieldConfigContext.Provider value={{ wrapperType: 'targets', payload: [] }}>
                    {rule && (
                        <FieldConditionComponent
                            key='target-goal'
                            ftype='widget'
                            currentValue={rule as FieldCondition}
                            feildValue={rule.feildValue}
                            onChange={(newValue: FieldCondition) => {
                                setRule(newValue);
                            }}
                            disabledOptions={
                                rule.filterType === 'product'
                                    ? selectedData.selectedProducts
                                    : rule.filterType === 'product_group'
                                    ? selectedData.selectedProductGroups
                                    : []
                            }
                        />
                    )}
                </FieldConfigContext.Provider>
            </Grid>
            <Grid item xs={2}>
                <TextField
                    fullWidth
                    variant='standard'
                    key='target-goal'
                    label={t`##TargetGoal`}
                    type='number'
                    inputProps={{ type: 'number', min: 0, step: 1 }}
                    value={target.targetGoal}
                    onChange={e => {
                        onChange({ ...target, targetGoal: parseInt(e.target.value) });
                    }}
                />
            </Grid>
        </>
    );
}

export default TargetDefinitionsControl;
