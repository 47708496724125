import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ParamsContext } from './params';
export interface IPaginationContext {
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    total: number;
    setTotal: (total: number) => void;
    rowsPerPageOptions: number[];
    setRowsPerPageOptions: (rowsPerPageOptions: number[]) => void;
    pagination: boolean;
    setPagination: (pagination: boolean) => void;
}
export const PaginationContext = createContext<IPaginationContext>({} as any);
export const PaginationContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([5, 10, 20, 50, 100]);
    const { gridProps } = useContext(ParamsContext);
    const [pagination, setPagination] = useState(typeof gridProps?.pagination === 'undefined' || gridProps?.pagination);
    const setPageSizeFn = (pageSize: number) => {
      if (rowsPerPageOptions.includes(pageSize)) {
        setPageSize(pageSize);
      } else {
        throw new Error(`pageSize ${pageSize} is not in current rowsPerPageOptions array ${rowsPerPageOptions}`);
      }
    }
    useEffect(() => {
        if (typeof gridProps?.pagination === 'boolean') {
            setPagination(gridProps?.pagination);
        }
    }, [gridProps?.pagination]);
    const contextValue:IPaginationContext = useMemo(
        () => ({
            page,
            setPage,
            pageSize,
            setPageSize: setPageSizeFn,
            total,
            setTotal,
            rowsPerPageOptions,
            setRowsPerPageOptions,
            pagination,
            setPagination,
        }),
        [page, pageSize, total, rowsPerPageOptions, pagination],
    );
    return <PaginationContext.Provider value={contextValue}>{children}</PaginationContext.Provider>;
};
export const usePagination = () => useContext(PaginationContext);