import { newProjectLocationsDb } from './newProjectLocationsDexie';
import newProjectLocationActionTypes, { createNewProjectLocationAction } from './actions';
import DynTableConst from '../../Services/Constants';
import newSyncDataActionTypes from '../NewSync/actions';
import axios from 'axios';
import { setDbSyncedDown } from '../helpers';

const PaginationLimit = 100;

export const createNewprojectLocationWrapperForDexie = (projLoc: any) =>
    ({
        _id: projLoc._id,
        projectLocation: projLoc,
        syncDate: new Date(),
    } as DexieProjectLocation);

export const syncAllProjectLocations = async (dispatch: any, projectIds: string, pointOfSaleIds: string) => {
    try {
        // drop the projects in the database
        await newProjectLocationsDb.transaction('rw', newProjectLocationsDb.tables, async () => {
            await Promise.all(newProjectLocationsDb.tables.map(table => table.clear()));
        });

        // get the new projects and load them to the database
        let count = 1;
        for (let idx = 0; idx < count; idx += PaginationLimit) {
            const projectLocationsResponse = await getProjectLocationsFetch({ projectIds, pointOfSaleIds }, idx);
            if (!projectLocationsResponse.success) throw new Error('Failed to sync project Locations');
            count = projectLocationsResponse?.count || 0;
            // console.log({ idx, projectLocationsResponse, count });

            Array.isArray(projectLocationsResponse?.data) &&
                projectLocationsResponse.data.forEach((projLoc: any) => {
                    projLoc &&
                        projLoc._id &&
                        dispatch(createNewProjectLocationAction(createNewprojectLocationWrapperForDexie(projLoc)));
                });

            dispatch({
                type: newSyncDataActionTypes.ADD_SYNC_LOG,
                payload: { action: 'Sync newProjectLocationsDb', idx, count },
            });
        }

        dispatch({
            type: newProjectLocationActionTypes.UPDATE_PROJECT_LOCATION_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
        setDbSyncedDown('ProjectLocationsDB');
    } catch (error) {
        console.log(error);
        dispatch({
            type: newSyncDataActionTypes.ADD_SYNC_ERROR,
            payload: error || 'Error handled but no log available',
        });
        dispatch({
            type: newProjectLocationActionTypes.UPDATE_PROJECT_LOCATION_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
    }
};

export const getProjectLocationsFetch = async (
    filter: { projectIds: string; pointOfSaleIds?: string },
    skip: number,
): Promise<any> => {
    return axios
        .post(
            DynTableConst.EMPPLAN_HOST + `/projects/pwa/sync/projectLocations?limit=${PaginationLimit}&skip=${skip}`,
            { _id: filter.projectIds, pointOfSaleIds: filter.pointOfSaleIds },
            {
                withCredentials: true, // for local dev
                // for local dev
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            },
        )
        .then(response => response.data)
        .catch(err => {
            console.error(err);
            return { foo: 'broken' };
        });
};
