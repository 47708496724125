import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TransactionExpenseForm from '../../../reactelements/src/modules/TransactionExpenseForm';
import { clearAutoCompleteLoadProducts, loadProductListAction } from '../../Models/NewProduct/actions';
import { Typography } from '@mui/material';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import EventDetailTransactionList from './EventDetailTransactionList';
import { loadExpenseListAction } from '../../Models/NewExpense/actions';

interface IProps {
    customReportConfig: CustomReportConfig;
    eventAndProject: {
        eventId: string;
        projectId: any;
        eventState: string;
    };
    reference:any;
}

function ExpenseProductReportParserPWA({ customReportConfig, eventAndProject, reference }: IProps) {
    const { t } = useTranslation();
    const dispatch: any = useDispatch();
    const dexieExpenseProducts = useSelector((store: any) => store?.product?.loadedProductsForAutoComplete);
    const user = useSelector((store: any) => store?.User?.user);
    const expenseItems = useSelector((store: any) => store?.expense?.loadedExpenses);

    const [currentUserCanEditEventUser, setCurrentUserCanEditEventUser] = useState<boolean>(false);

    const [updateExpenseObject, setUpdateExpenseObject] = useState(undefined);

    useEffect(() => {
        dispatch(
            loadProductListAction({
                productType: 'Expenses',
                projectId: eventAndProject?.projectId?._id,
                forAutoComplete: true,
            }),
        );

        return () => {
            dispatch(clearAutoCompleteLoadProducts());
        };
    }, []);

    useEffect(() => {
        if (user && user?.user?.roles && Array.isArray(user?.user?.roles)) {
            const roles = user.user.roles;
            if (roles.includes('EventEdit') || roles.includes('currentUserCanEditEventUser')) {
                setCurrentUserCanEditEventUser(true);
            }
        }
    }, [user]);

    const updateCb = (state: any) => {
        // console.log('called', state);
        loadExpenseListAction(dispatch, {});
        loadExpenseListAction(dispatch, { eventId: eventAndProject.eventId });
        // may be we need this update call back to render the table on later iteration
    };

    const expenseProducts = Array.isArray(dexieExpenseProducts)
        ? dexieExpenseProducts.map((each: DexieProduct) => each.product)
        : [];

    const handleUpdateExpenseObj = (obj: any) => {
        const newobj = { ...obj };
        if (newobj?._id && newobj._customReport?._configcreatedTime?.type === 'expense') {
            if (typeof newobj.productId === 'string') {
                const prod = expenseProducts.find(exp => exp._id === newobj.productId);
                newobj.productId = prod;
            }
        }
        setUpdateExpenseObject(newobj);
        reference.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            {expenseProducts.length > 0 ? (
                <TransactionExpenseForm
                    event={{ _id: eventAndProject.eventId, project_id: eventAndProject.projectId }}
                    updateExpenseCallback={updateCb}
                    customReports={[customReportConfig]}
                    eventState={eventAndProject.eventState}
                    currentUserCanEditEventUser={currentUserCanEditEventUser}
                    pwaOfflineOptions={expenseProducts}
                    updateSaleObject={updateExpenseObject}
                />
            ) : (
                <Typography>{t('##NoProductsFound')}</Typography>
            )}
            {Array.isArray(expenseItems) && expenseItems.length > 0 && (
                <EventDetailTransactionList
                    handleUpdateSaleObj={handleUpdateExpenseObj}
                    soldItems={expenseItems}
                    eventId={eventAndProject.eventId}
                    type='expense'
                    setReRenderList={updateCb}
                    products={expenseProducts}
                />
            )}
        </>
    );
}

export default ExpenseProductReportParserPWA;
