import * as React from 'react';
import * as Sentry from '@sentry/react';
import { HashRouter, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@mui/material/styles';

import { init } from 'reactelements/src/config/translate';
import { myTheme } from 'reactelements/src/modules/xGridDashboard/components/styles';
import EmpplanTheme from 'reactelements/src/modules/elements/ThemeWrapper/EmpplanTheme';

import DynTableConst from './Services/Constants';
import { getUsers } from './Models/UserStates/UserActions';
import { LoadAgencySettings } from './Services/LoadAgencySettings';
import { apiLoadAgencySettings as apiLoadEmpplanSettings } from 'reactelements/src/hooks/useCurrency';
import { PWASyncControlContextWrapper } from './Services/pwaSyncControlContext';
import { PWAScheduledTasksContextWrapper } from './Services/pwaScheduledTasksContext';
import AppRoutes from './routes';
import pwaVersion from './commit.json';

import IframeHolder from './pages/IframeHolder';
import Splash from './pages/Splash';

import { CRMMapShowLeadListSettingLocalstorageKey } from './Components/PWASettings';

const history = createBrowserHistory();

history.listen(({ location }) => {
    if (typeof window.reactGA !== 'undefined') {
        // console.log('Page change detected', location.hash, location.pathname, location.search);

        // window.reactGA.set({ page: location.pathname + location.hash });
        window.reactGA.send({
            hitType: 'pageview',
            page: location.pathname + location.hash,
            title: window.location.host,
        });
    }
});

if (!window.location?.href?.includes('localhost')) {
    Sentry.init({
        dsn: DynTableConst.SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration()
        ],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        release: pwaVersion?.version,
    });
}

//<!-- This routes are only available when the user isnt loggedin -->
//App Bar should render only when the application is ready
export const AppRouter: React.FunctionComponent<{}> = () => {
    const [loggedIn, setLoggedIn] = React.useState(false);
    const [translationsReady, setTranslationsReady] = React.useState(false);

    const dispatch: any = useDispatch(); // so we can use it from reactelements components
    const user = useSelector((store: any) => store.User);

    React.useEffect(() => {
        if (!!(user?.user?.user && user?.user?.userabstraction)) {
            init().then(() => {
                console.log('translate init');
                setTranslationsReady(true);
            });
        }
    }, [user]);

    React.useEffect(() => {
        // dispatch this after a timeout of 1.5 seconds to see that loading screen
        // @ts-ignore
        dispatch(getUsers());

        // load empplan settings
        if (!(window.empplanSettings && window.empplanSettings.currency)) {
            apiLoadEmpplanSettings().catch(console.error);
        }
        
        //removing leadlist page and scrollposition data
        console.log('removing Page and ScrollPosition Data');
        localStorage.removeItem('scrollPosition');
        localStorage.removeItem('page');
        localStorage.removeItem('listData');

        // setting true show leadlist key for map if null  
        if (!localStorage.getItem(CRMMapShowLeadListSettingLocalstorageKey)) {
            localStorage.setItem(CRMMapShowLeadListSettingLocalstorageKey, 'true');
        }

        LoadAgencySettings().catch(console.error);
    }, []);

    React.useEffect(() => {
        if (user?.user?.user) {
            window.user = user.user.user;
            window.userabstraction = user.user.userabstraction;
            setLoggedIn(true);
            window.reactGA?.set({ userId: window.userabstraction._id });
            Sentry.setUser({
                id: window.userabstraction._id,
                // email: window.userabstraction.email,
                ip_address: '{{auto}}',
            });
        } else {
            setLoggedIn(false);
        }
    }, [user]);

    return (
        <ThemeProvider theme={myTheme}>
            <EmpplanTheme>
                <HashRouter>
                    {loggedIn && window.empplanSettings && translationsReady ? (
                        <>
                            <PWAScheduledTasksContextWrapper>
                                <PWASyncControlContextWrapper>
                                    <AppRoutes />
                                </PWASyncControlContextWrapper>
                            </PWAScheduledTasksContextWrapper>
                            <Route exact path={['/myProfile', '/invoice', '/calendar']} component={IframeHolder} />
                        </>
                    ) : (
                        <Splash />
                    )}
                </HashRouter>
            </EmpplanTheme>
        </ThemeProvider>
    );
};

export default AppRouter;
