import Dexie, { Table } from 'dexie';

class EventsDB extends Dexie {
    events!: Table<DexieEvent>;

    constructor() {
        super('eventsDB');
        this.version(1).stores({
            events: `_id, [lat+lon], projectId`,
        });

        this.version(2)
            .stores({ events: '_id, [lon+lat], projectId' })
            .upgrade(tx => {
                // An upgrade function for version 2 will upgrade data based on version 1
                return tx
                    .table('events')
                    .toCollection()
                    .modify(event => {
                        const tmp = event.lon;
                        event.lon = event.lat;
                        event.lat = tmp;
                    });
            });
    }
}

export const newEventDb = new EventsDB();
