import React from 'react';
import { getFileFromServer } from '../../hooks/useFileUpload/uploadHelper';
import { UploadRenderView } from '../CustomReport/UploadRenderView';
import { Box, Typography } from '@mui/material';

function GenericFilePresentation({ file, height, disableInteraction, pwaLogo, sxcss }: GenericFilePresentationProps) {
    const [fileInfo, setFileInfo] = React.useState<any>(null);
    const [renderComponent, setRenderComponent] = React.useState<React.ReactNode>(null);

    React.useEffect(() => {
        // here we load the file
        if (file) {
            if ((file as VFileSystemEntry)._id) {
                setFileInfo(file);
            } else {
                getFileFromServer(file as string)
                    .then(fileInfoRes => setFileInfo(fileInfoRes))
                    .catch(err => {
                        // console.error("Error loading file from server", err)
                        setRenderComponent(<Typography>Cannot load the file</Typography>);
                    });
            }
        }
    }, [file]);

    React.useEffect(() => {
        if (fileInfo) {
            // here we decide what kind of file and how to render it
            let fileType = 'other';
            const fileExtension: string | undefined = fileInfo.fileExtension?.toLowerCase();
            switch (fileExtension) {
                case 'jpeg':
                case 'gif':
                case 'jfif':
                case 'jped':
                case 'webp':
                case 'svg':
                case 'png':
                case 'jpg':
                    fileType = 'image';
                    break;
                case 'pdf':
                    fileType = 'pdf';
                    break;
                case 'webm':
                case 'mp4':
                case 'ogg':
                case 'mp3':
                    fileType = 'video';
                    break;
                default:
                    fileType = 'other';
                    break;
            }

            // now we know the type
            setRenderComponent(
                <UploadRenderView
                    type={fileType}
                    fileSource={'/upload/files/get/' + fileInfo._id}
                    key={fileInfo._id}
                    responseData={fileInfo}
                    pwaLogo={pwaLogo ? true : false}
                    sxcss={sxcss}
                />,
            );
        }
    }, [fileInfo]);

    return (
        <Box
            key='image_render_area'
            height={height || '150px'}
            width='100%'
            sx={{ pointerEvents: disableInteraction ? 'none' : undefined }}
        >
            {renderComponent}
        </Box>
    );
}

export default GenericFilePresentation;
