import * as React from 'react';
import useFileUpload from '../../hooks/useFileUpload';
import { Box } from '@mui/system';
import UploadArea from '../../hooks/useFileUpload/UploadArea';

interface UploadFieldProps {
    value: string | string[]; // the current value
    resetCount?: number;
    remotePath: string; // the path on the server to upload the file
    remoteDeletePath?: (fileId: string) => string; // generate the delete API url called when file is deleted
    mapFileIdFromUploadResponse?: (jsonResponse: any) => string | undefined; // get the fileId from the upload response
    onChange: (value: string[]) => void; // handler to update the value after upload is complete
    setUploadStatus?: (status: boolean) => void; // true when uploading / pending uploads
    contextObjForUpload?: {
        // additional context object attached to the upload post payload (can be extended here)
        contextType?: string;
        contextId?: string;
        preferMetaGpsLocationData?: boolean;
    };
    multiple?: boolean; // enable multiple file uploads
    manualControl?: UseFileUploadManualControl; // pause uploads for when saving the file
    options?: {
        fieldName?: string; // label name on the dropzone
        isReadonly?: boolean;
        isRequired?: boolean;
        enableCapture?: boolean;
        forceGps?: boolean;
        accept?: string;
    };
}

function EmpplanGenericUpload({
    value,
    resetCount,
    contextObjForUpload,
    onChange,
    remotePath,
    remoteDeletePath,
    setUploadStatus,
    multiple,
    manualControl,
    options,
    mapFileIdFromUploadResponse,
}: UploadFieldProps) {
    const [isLoading, currentValue, onImageChange, isChanged, displayElement, errorElement, loadingElement] =
        useFileUpload({
            initialValue: value,
            resetCount,
            remotePath,
            remoteDeletePath,
            onChange,
            setUploadStatus,
            contextObjForUpload,
            options,
            multiple,
            manualControl,
            mapFileIdFromUploadResponse,
        });

    const fieldHeight = '150px';

    return (
        <>
            {errorElement}
            <Box display='flex' flexDirection='column' alignItems='center'>
                {(multiple || (!multiple && !value.length && displayElement.length === 0)) && (
                    <Box height={fieldHeight} width='100%'>
                        <UploadArea
                            key='image_upload_area'
                            fieldId={'image_upload_area' + options?.fieldName}
                            label={options?.fieldName || 'Upload field'}
                            multiple={multiple}
                            onFileDrop={(files: Array<File>) => {
                                if (files?.length) {
                                    onImageChange(files);
                                }
                            }}
                            options={options}
                        />
                    </Box>
                )}
                {displayElement.length > 0 && (
                    <Box
                        key='image_render_area'
                        height={fieldHeight}
                        width='100%'
                        display='inline-flex'
                        mt={1}
                        gap={2}
                        sx={{
                            overflowX: 'auto',
                            overflowY: 'hidden',
                        }}
                    >
                        {displayElement}
                    </Box>
                )}
            </Box>
        </>
    );
}

export default EmpplanGenericUpload;
