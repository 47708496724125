import i18next from 'i18next';
import LanguageDetector, { CustomDetector } from 'i18next-browser-languagedetector';
import {initReactI18next, useTranslation} from 'react-i18next';
import i18nextBackend from 'i18next-http-backend';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

/**
 *  LANGUAGE INIT SECTION
 */
const UserAbstractionLanguageDetector: CustomDetector = {
    name: 'UserAbstractionLanguageDetector',
    lookup() {
        return getCurrentLocale()[0];
    },
};
const languageDetector = new LanguageDetector();
languageDetector.addDetector(UserAbstractionLanguageDetector);
export function init() {
    return i18next
        .use(i18nextBackend)
        .use(languageDetector)
        .use(initReactI18next) // bind react-i18next to the instance
        .init({
            fallbackLng: 'en',
            debug: window.env === 'development',
            nsSeparator: false,
            backend: {
                loadPath: `${window.BASE_URL}/i18n/assets/translations/locale-{{lng}}.json`,
            },
            detection: {
                order: ['UserAbstractionLanguageDetector', 'navigator'],
            },
            react: {
                bindI18n: 'languageChanged',
                bindI18nStore: '',
                transEmptyNodeValue: '',
                transSupportBasicHtmlNodes: true,
                transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
                useSuspense: false,
            },
        });
}

export const getCurrentLocale = () => {
    const langCode = window.userabstraction?.language || navigator.language || moment.locale() || 'en';
    let [lang, countryCode] = langCode.split('-') as [string, string | ''];
    if (lang.length > 2) {
        [lang, countryCode] = [lang.substring(0, 2), lang.substring(2)];
    }
    if (!countryCode) {
        countryCode = getLanguageInfo(lang)?.country_code || '';
    }
    if (!countryCode) {
        return [lang];
    } else {
        return [lang, countryCode];
    }
};
export const getCurrentLocaleString = (sep = '-') => {
    return getCurrentLocale().join(sep);
};
export const useCurrentLocale = () => {
    const [, reRender] = useState<boolean>();
    useEffect(() => {
        ensureLanguageInfo().then(() => reRender(c => !c));
    }, []);
    const [lang, countryCode] = getCurrentLocale();
    return [lang, countryCode];
};

let tmp_language_info_map: typeof window.language_info_map;
export const ensureLanguageInfo = ():Promise<{[key: string]: ILanguageInfo}[]> => {
    return new Promise((resolve, reject) => {
        // will make request to get language info and wont make request if already made
        if (tmp_language_info_map === undefined) {
            tmp_language_info_map = fetch(`${window.BASE_URL}/i18n/assets/locale-info.json`).then(res =>
                res.json(),
            ) as any;
        }
        if (tmp_language_info_map instanceof Promise) {
            tmp_language_info_map
                .then(res => {
                    window.language_info_map = res;
                    window.language_info_map_loaded = true;
                    resolve(res);
                })
                .catch(e => {
                    window.language_info_map = undefined;
                    window.language_info_map_loaded = false;
                    reject(e);
                });
        } else {
            // @ts-ignore
            resolve(window.language_info_map);
        }
    });
};
if(typeof window !== 'undefined') {

window.f = ensureLanguageInfo
}
export const getLanguageInfo = (lang: string) => {
    if (window.language_info_map_loaded) {
        return window.language_info_map?.[lang];
    }
};
// this is to prevent this pitfall https://github.com/i18next/react-i18next/issues/1228
/**
 * Returns stable `t`
 * @param args
 */
export const useTranslationSafe : typeof useTranslation = (...args) => {
    const trans = useTranslation(...args);
    const {t,...rest} = trans
    const tt = ((key:string) => key) as typeof t
    const [stableT,setStableT] = useState<typeof t>(()=>tt)
    useEffect(()=>{
        if(rest.ready) {
            setStableT(()=>t)
        } else {
            setStableT(()=>tt)
        }
    },[t,rest.ready])
    return {t,...rest} as typeof trans
}
export const i18n = i18next;
