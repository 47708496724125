import { isFieldHidden } from './fieldVisibility';

export const parseSavedReportResults: (x: APIReportData) => ReportData = reportResults => {
    const tempObj = {} as ReportData;
    for (const key in reportResults) {
        if (key !== '__emp' && Object.prototype.hasOwnProperty.call(reportResults, key)) {
            const fieldData = reportResults[key];
            //This key might be a productGroup Id not field Id 
          
            if (typeof fieldData.value !== 'undefined') {
                tempObj[key] = fieldData.value
            }else if(typeof fieldData === 'object'){

                tempObj[key] = {};
                Object.keys(fieldData).forEach(key1=>{
                    //@ts-ignore
                    tempObj[key][key1] = fieldData[key1].value as any
                });
                
            };
        }
    };
    
    return tempObj;
};

export const formatReportResultsToSave: (
    x: ReportData,
    y: CustomReportConfig,
    z?: ReportConfiguration, // if provided, the hidden values will be removed from the final payload
) => APIReportData = (reportData, reportConfig, reportConfigData) => {
    const tempObj = {} as APIReportData;
    for (const key in reportData) {
        if (Object.prototype.hasOwnProperty.call(reportData, key)) {
            const value = reportData[key];
            const fieldConfig = reportConfig.customFields.find(f => f.config._id === key);
            if (fieldConfig) {
                
                if (
                    reportConfigData?.productId &&
                    isFieldHidden(fieldConfig, reportConfig, reportData, { productId: reportConfigData.productId },reportConfigData.isFieldHiddenCustomCondition)
                ) {
                    // we don't add it to the object
                } else {
                    tempObj[key] = {
                        value,
                        name: fieldConfig.config.name,
                        fieldId: key,
                    };
                }
            }
        }
    }
    return tempObj;
};
