import {Box} from "@mui/material";
import {useTranslationSafe} from "../../config/translate";
import {DivCenteredContent} from "../styled";

export interface ITextProps {
  value: string;
}
export function TextView({value}: ITextProps) {
  const {t} = useTranslationSafe()
  return <Box component={'span'} sx={{
    whiteSpace: 'pre-wrap',
    display: 'flex',
    height:'100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }}>{t(value?.toString())}</Box>;
}