import React from 'react';
import { Controller, UseFormStateReturn, ControllerFieldState, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { RadioGroup, FormControlLabel, Radio, FormHelperText, RadioGroupProps, FormLabel, Box } from '@mui/material';
import { ControllerPropsWithoutRender } from './utils';
import { useTranslation } from 'react-i18next';

interface EmpplanRadioGroupProps extends ControllerPropsWithoutRender {
    renderProps?: (
        field?: ControllerRenderProps<FieldValues, string>,
        fieldState?: ControllerFieldState,
        formState?: UseFormStateReturn<FieldValues>,
    ) => RadioGroupProps;

    helperText?: string;
    options: Array<{label:string,value:string}|string>;
    label: string;
    disabled?: boolean;
    onBlur?: (e: any) => void;
    onChange?: (e: any) => void;
    header?: string;
    sx?: React.CSSProperties | undefined;
}

export default function EmpplanRadioGroup(props: EmpplanRadioGroupProps) {
    const { t } = useTranslation();
    return (
        <Controller
            {...props}
            render={({ field, fieldState, formState }) => {
                const rProps = props?.renderProps?.(field, fieldState, formState);

                return (
                    <>
                        {props?.header && <FormLabel id='radio-buttons-group-label'>{t(props.header)}</FormLabel>}
                        <RadioGroup
                            key={props.name}
                            aria-label={props.label}
                            name={props.name}
                            value={field.value}

                            onChange={(e) => {
                                field.onChange(e.target.value);
                                props?.onChange?.(e);
                            }}
                            onBlur={(e) => {
                                field.onBlur();
                                props?.onBlur?.(e);
                            }}
                            {...rProps}
                        >
                            <Box style={{...props?.sx}}>
                                {props?.options.map((option) => {

                                    return <FormControlLabel
                                        key={typeof option !== "string" ? option?.value :option}
                                        value={typeof option !== "string" ? option?.value : option}
                                        control={<Radio />}
                                        label={typeof option !== "string"?option.label:t(option)}
                                        disabled={props?.disabled}
                                    />
                                })}
                            </Box>
                        </RadioGroup>
                        {fieldState.error?.message && (
                            <FormHelperText error>{fieldState.error.message}</FormHelperText>
                        )}
                        {props.helperText && !fieldState.error?.message && (
                            <FormHelperText>{props.helperText}</FormHelperText>
                        )}
                    </>
                );
            }}
        />
    );
}
