import {Avatar, Skeleton} from "@mui/material";
import {Suspense}  from "react";
import {stringToColor} from "../../utils";
import * as React from "react";

export interface IDisplayPicProps {
  value: string|undefined;
}
export function DisplayPic({value}: IDisplayPicProps) {
  return <Avatar
    src={value}
    sx={{
      bgcolor: stringToColor(value || ''),
      width: '32px',
      height: '32px',
      transition: 'all 0.3s ease-in-out',
      position: 'fixed',
      // drop shaow
      boxShadow: '0 0 0 0.1rem rgba(0, 0, 0, 0.1), 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1)',
    }}/>
}