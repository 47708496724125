export const TransactionSVG = ({ dark, height }: { dark?: boolean; height?: number }) => {
    const backgroundColor = dark ? '#424242' : 'white';
    const calculatedHeight = height ?? 74;
    const calculatedWidth = (65 / 74) * calculatedHeight;
    return (
        <svg
            width={calculatedWidth}
            height={calculatedHeight}
            viewBox='0 0 65 74'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <circle cx='32.5' cy='32.5' r='32.5' fill={backgroundColor} />
            <path
                d='M60.384 39.089C61.317 35.338 61.421 31.539 60.811 27.899L55.048 33.71L60.384 39.089Z'
                fill='#00A4C0'
            />
            <path
                d='M12.092 35.575L5.711 42.01C6.106 43.09 6.57 44.162 7.108 45.221C8.359 47.679 9.903 50.031 11.586 52.219C14.696 56.266 18.209 59.922 21.831 63.475L30.795 54.437L12.092 35.575Z'
                fill='#00A4C0'
            />
            <path d='M4.375 27.792C3.765 31.495 3.888 35.354 4.839 39.157L10.243 33.709L4.375 27.792Z' fill='#00A4C0' />
            <path
                d='M34.495 54.436L43.282 63.297C45.53 61.054 47.764 58.798 49.965 56.508C54.035 52.273 57.458 47.527 59.509 41.939L53.198 35.575L34.495 54.436Z'
                fill='#1479BC'
            />
            <path
                d='M30.796 12.984L23.353 5.47998C14.076 8.59498 7.49901 16.027 5.05101 24.744L12.092 31.845L30.796 12.984Z'
                fill='#18B2AC'
            />
            <path
                d='M53.198 31.843L60.128 24.857C57.632 16.042 50.939 8.54901 41.94 5.47601L34.495 12.984L53.198 31.843Z'
                fill='#00A4C0'
            />
            <path
                d='M26.619 4.59102C26.496 4.61702 26.374 4.64302 26.251 4.67102L32.645 11.118L39.05 4.66002C38.333 4.50002 37.602 4.37002 36.862 4.26602C32.074 3.61202 27.855 4.33802 26.619 4.59102Z'
                fill='#00A4C0'
            />
            <path d='M41.4945 65.1486L32.6451 56.2993L23.795 65.1493L32.6444 73.9987L41.4945 65.1486Z' fill='#18B2AC' />
            <path
                d='M51.116 33.709L32.413 14.849L13.709 33.709L22.133 42.204L28.181 36.107C27.78 35.399 27.547 34.583 27.547 33.71C27.547 31.022 29.724 28.844 32.412 28.844C35.1 28.844 37.278 31.022 37.278 33.71C37.278 36.398 35.1 38.577 32.412 38.577C31.552 38.577 30.745 38.35 30.044 37.958L23.983 44.069L32.412 52.57L51.116 33.709Z'
                fill={backgroundColor}
            />
            <path
                d='M31.4405 31.7143H32.9286V29.5714H35.1607V28.1429H32.9286V26H31.4405V28.1429H29.2083V29.5714H31.4405V31.7143ZM28.4643 38.1429C27.6458 38.1429 26.9836 38.7857 26.9836 39.5714C26.9836 40.3571 27.6458 41 28.4643 41C29.2827 41 29.9524 40.3571 29.9524 39.5714C29.9524 38.7857 29.2827 38.1429 28.4643 38.1429ZM35.9048 38.1429C35.0863 38.1429 34.4241 38.7857 34.4241 39.5714C34.4241 40.3571 35.0863 41 35.9048 41C36.7232 41 37.3929 40.3571 37.3929 39.5714C37.3929 38.7857 36.7232 38.1429 35.9048 38.1429ZM29.2827 34.5714H34.8259C35.3839 34.5714 35.875 34.2786 36.128 33.8357L39 28.8286L37.7054 28.1429L34.8259 33.1429H29.6027L26.433 26.7143H24V28.1429H25.4881L28.1667 33.5643L25.9494 37.4286H37.3929V36H28.4643L29.2827 34.5714Z'
                fill='#27B3AE'
            />
        </svg>
    );
};
