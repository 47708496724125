// copied from /empplan/packages/custom/customreports/public/services/customreports.js

export default function ReportAvailable() {

    const isArrayContains = function (userDataArray: any[], arrayToCompare: any[]) {
        let found = false;
        if (Array.isArray(userDataArray) && userDataArray.length > 0 && Array.isArray(arrayToCompare) && arrayToCompare.length > 0) {
            userDataArray.forEach(function (userDataArrayItem, i) {
                let userDataItemID = userDataArrayItem;
                if (typeof userDataArrayItem !== "undefined" && userDataArrayItem !== null && typeof userDataArrayItem._id !== "undefined" && userDataArrayItem._id !== null) {
                    userDataItemID = userDataArrayItem._id.toString();
                }
                arrayToCompare.forEach(function (arrayToCompareItem, j) {
                    let compareItemID = arrayToCompareItem;
                    if (typeof arrayToCompareItem !== "undefined" && arrayToCompareItem !== null && typeof arrayToCompareItem._id !== "undefined" && arrayToCompareItem._id !== null) {
                        compareItemID = arrayToCompareItem._id.toString();
                    }
                    if (compareItemID === userDataItemID) {
                        found = true;
                    }
                });
            });
        }
        return found;
    };

    const isReportAvailable = function (report: CustomReportConfig, currentUserabstraction: Record<any, any>, isAdmin: boolean, userAreas: any[],locationGroups:Array<string>) {
        let showReportToUser = true;
        let showHideAction = 'show';
        let userEligibilities = [];
        if (isAdmin === true) {
            return true;
        }
        if (!currentUserabstraction) {
            return false;
        }
        if (typeof currentUserabstraction.relations !== "undefined" && currentUserabstraction.relations !== null &&
            typeof currentUserabstraction.relations.productGroups !== "undefined" && currentUserabstraction.relations.productGroups !== null) {
            userEligibilities = currentUserabstraction.relations.productGroups;
        }

        if (typeof report !== "undefined" && report !== null && typeof report.extraVisibility !== "undefined" && report.extraVisibility !== null) {
            if (typeof report.extraVisibility.rules !== "undefined" && report.extraVisibility.rules !== null) {
                if (typeof report.extraVisibility.rules.hideReport !== "undefined" && report.extraVisibility.rules.hideReport === true) {
                    showHideAction = 'hide';
                }
                let conditionsChecked = null;
                if (conditionsChecked !== true && typeof report.extraVisibility.rules.teams !== "undefined" && Array.isArray(report.extraVisibility.rules.teams) && report.extraVisibility.rules.teams.length > 0) {
                    conditionsChecked = isArrayContains(currentUserabstraction.team, report.extraVisibility.rules.teams);
                }
                if (conditionsChecked !== true && typeof report.extraVisibility.rules.users !== "undefined" && Array.isArray(report.extraVisibility.rules.users) && report.extraVisibility.rules.users.length > 0) {
                    conditionsChecked = isArrayContains([currentUserabstraction._id], report.extraVisibility.rules.users);
                }
                if (conditionsChecked !== true && typeof report.extraVisibility.rules.areas !== "undefined" && Array.isArray(report.extraVisibility.rules.areas) && report.extraVisibility.rules.areas.length > 0) {
                    conditionsChecked = isArrayContains(userAreas, report.extraVisibility.rules.areas);
                }
                if (conditionsChecked !== true && typeof report.extraVisibility.rules.productGroups !== "undefined" && Array.isArray(report.extraVisibility.rules.productGroups) && report.extraVisibility.rules.productGroups.length > 0) {
                    conditionsChecked = isArrayContains(userEligibilities, report.extraVisibility.rules.productGroups);
                }
                if (conditionsChecked !== true && typeof report.extraVisibility.rules.locationGroups !== "undefined" && Array.isArray(report.extraVisibility.rules.locationGroups) && report.extraVisibility.rules.locationGroups.length > 0) {
                    conditionsChecked = isArrayContains(locationGroups, report.extraVisibility.rules.locationGroups);
                }
                if (conditionsChecked === true) {
                    if (showHideAction === 'hide') {
                        showReportToUser = false;
                    } else {
                        showReportToUser = true;
                    }
                } else if (conditionsChecked === false) {
                    if (showHideAction === 'hide') {
                        showReportToUser = true;
                    } else {
                        showReportToUser = false;
                    }
                }
            }
        }


        return showReportToUser;
    };
    return {
        isReportAvailable,
        isArrayContains
    };
}