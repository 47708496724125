import { newExpenseDb } from './newExpenseDexie';
import { createNewExpenseWrapperForDexie, getExpensesFetch, syncAllExpenses } from './helpers';
import ObjectID from 'bson-objectid';
import { isDeviceOnline } from '../../Services/OnlineStatus';
import { deleteExpenseItemsFetch } from '../NewExpense/helpers';

const newExpenseActionTypes = {
    NEW_EXPENSE: 'NEW_EXPENSE',
    LOAD_EXPENSE_LIST: 'LOAD_EXPENSE_LIST',
    LOAD_EXPENSE: 'LOAD_EXPENSE',
    UPDATE_EXPENSE_SYNC_DATA: 'UPDATE_EXPENSE_SYNC_DATA',
    DELETE_EXPENSE: 'DELETE_EXPENSE',
};

export default newExpenseActionTypes;

export const createNewExpenseAction = (expense: DexieExpense) => {
    newExpenseDb.expenses.add(expense);
    return {
        type: newExpenseActionTypes.NEW_EXPENSE,
        payload: expense,
    };
};

export const newExpensePostAction = async (expense: any) => {
    if (expense) {
        if (!expense._id) {
            expense._id = new ObjectID().str;
        }
        newExpenseDb.expenses.add({
            _id: expense._id,
            expense,
            event: expense.eventId,
            isNewDoc: 1,
            syncPending: 1,
            syncDate: new Date(),
            syncError: 0,
        });
    }
    return {
        type: newExpenseActionTypes.NEW_EXPENSE,
        payload: expense,
    };
};

export const newExpensePutAction = async (expense: any) => {
    if (expense?._id) {
        newExpenseDb.expenses.update(expense._id, {
            expense: expense,
            syncPending: 1,
        });
    }
    return {
        type: newExpenseActionTypes.NEW_EXPENSE,
        payload: expense,
    };
};

export const loadExpenseAction = async (dispatch: any, expenseId: string | null) => {
    if (expenseId !== null && expenseId) {
        const expenses = await newExpenseDb.expenses.get(expenseId);
        return dispatch({
            type: newExpenseActionTypes.LOAD_EXPENSE,
            payload: expenses,
        });
    } else {
        return dispatch({
            type: newExpenseActionTypes.LOAD_EXPENSE,
            payload: undefined,
        });
    }
};

export const loadExpenseListAction = async (dispatch: any, filterOptions: { eventId?: string }) => {
    if (filterOptions?.eventId !== undefined) {
        const expenses = await newExpenseDb.expenses
            .where('event')
            .equals(filterOptions.eventId)
            .toArray();

        if (expenses.length === 0 && isDeviceOnline()) {
            const apiExpenses = await getExpensesFetch([filterOptions.eventId], 0);
            if (apiExpenses.success && apiExpenses.count && Array.isArray(apiExpenses.data)) {
                return dispatch({
                    type: newExpenseActionTypes.LOAD_EXPENSE_LIST,
                    payload: apiExpenses.data.map((exp: any) => createNewExpenseWrapperForDexie(exp)),
                });
            }
        }

        return dispatch({
            type: newExpenseActionTypes.LOAD_EXPENSE_LIST,
            payload: expenses,
        });
    } else {
        return dispatch({
            type: newExpenseActionTypes.LOAD_EXPENSE_LIST,
            payload: [],
        });
    }
};

export const newExpenseItemDeleteAction = async (expense: any, successCallback?: any, errorCallback?: any) => {
    try {
        if (!expense?._id) throw new Error('no _id in expense on function call');
        // find the salesItem in the database
        const expenseItem = await newExpenseDb.expenses.get(expense._id);
        if (!expenseItem) throw new Error('Cannot find expense in database');
        // we check if doc is already on the server
        if (!expenseItem.isNewDoc) {
            if (!isDeviceOnline()) throw new Error('Cannot perform action when device is offline');
            const data = await deleteExpenseItemsFetch(expense._id);
            if (!data || data?.errors) {
                throw new Error('Failed to delete from server');
            }
        }
        // we delete from local database now
        await newExpenseDb.expenses.delete(expense._id);
        successCallback && successCallback();
    } catch (error) {
        console.log(error);
        errorCallback && errorCallback();
    }
    return {
        type: newExpenseActionTypes.DELETE_EXPENSE,
        payload: expense,
    };
};

export const startExpenseSyncAction = () => (dispatch: any) => {
    dispatch({
        type: newExpenseActionTypes.UPDATE_EXPENSE_SYNC_DATA,
        payload: {
            state: 'DOWNLOADING',
            lastUpdate: new Date(),
        },
    });
    syncAllExpenses(dispatch);
};
