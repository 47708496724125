import {EmailOutlined} from "@mui/icons-material";
import React from "react";
import {Typography,Link} from "@mui/material";
import {EquallySpacedRow} from "../styled";

interface EmailViewProps {
  value: string;
}
export function EmailView({value}: EmailViewProps) {
  return value ?  <EquallySpacedRow>
    <EmailOutlined/>
    <Link title={"send email in new tab"} target={'_blank'} underline={'hover'} href={`mailto:${value}`}>
      <Typography >{value}</Typography>
    </Link>
  </EquallySpacedRow> : null
}