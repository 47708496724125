import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { themeOptions } from '../../../config/theme';
import * as locales from '@mui/material/locale';
import { getCurrentLanguage } from '../utils';

//change colors?
const currentLang = getCurrentLanguage();
//@ts-ignore
export const myTheme = createTheme(themeOptions, locales[currentLang]);

export const useStyles = makeStyles(theme => ({
    rootContainerInAngular: {
        height: '80vh',
        width: '100%',
    },
    root: {
        height: '80vh',
        width: '100%',
        '& .super-app-theme--header': {
            //  backgroundColor: (props: IOptions) => props.headerColor,
        },
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        elevation: 3,
    },
    overlay: {
        position: 'absolute',
        top: 0,
        width: '100%',
    },
    headerLabel: {
        padding: '20px 0 0 30px !important',
    },
    plusButton: {
        position: 'absolute',
        top: -20,
        left: 10,
        width: '40px !important',
        height: '40px !important',
        cursor: 'pointer',
        borderRadius: '50%',
        background: '#24c0b1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    backArrow: {
        cursor: 'pointer',
        padding: '10px 20px',
    },
    snackbar: {
        bottom: '-300px !important',
        left: '24px',
        right: 'auto',
    },
    fab: {
        position: 'absolute',
        zIndex: '1 !important',
        backgroundColor: '#68c2b1 !important',
        width: '40px !important',
        height: '40px !important',
        borderRadius: '50%',
        marginTop: '25px !important',
        marginLeft: '5px !important',
    },
    indicator:{
        color:'white'
    },
    tabLabel:{
        fontSize: '16px'
    },
    palette: {
        slate: {
            darker: '#e0e0e0',
            dark: '#de5d53',
            light: '#a33615',
            lighter: '#f8d516',
            main: '#59a17e',
            contrastText: '#ffffff', // <------------------ Add this line to fix
        },
    },
    primaryButton: {
        backgroundColor: '#24c0b1',
        color: '#FFFFFF',
        '&.MuiButtonBase-root:hover': {
            backgroundColor: '#24c0b1',
            color: '#FFFFFF',
        },
        padding: 1,
    },
    iconButton: {
        color: '#FFFFFF',
    },
    appTitle: {
        backgroundColor: '#424242',
        color: '#FFFFFF',
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

declare module '@mui/material/styles' {
    interface PaletteColor {
        lightest?: string;
        lighter?: string;
        darker?: string;
    }

    interface PaletteOptions {
        slate: any;
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        slate: true;
    }
}
