import React from 'react'
import { XDataGridPro2 } from '../../components-ui/table';
import { IParams } from '../../components-ui/table/contexts/params';
import { DealerRankResource } from '../../resources/DealerRanks';

import { InterfaceFrom } from 'types-joi';
import { DealerRankTableConfig } from './config';

export default function DealerRankTable(props: Partial<IParams>) {

    const buildConfig = {...DealerRankTableConfig,...props?.data?.buildConfig}

    const params: IParams = {
        moduleName: 'DealerRank:Table',
        ...props,
        buildConfig:buildConfig,

        data: {
            filters: Object.fromEntries(Object.entries(props.data?.filters || {})
              .filter(([key])=>!['queryCount',
              'skip','limit',
              'page'].includes(key))),
        },
        viewName: 'DealerRank', 
        
        gridStyles: {
            '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'white',
            },
            '& .MuiDataGrid-columnHeader' :{
                outline: 'none !important',
            },
            '& .MuiDataGrid-cell--withRenderer':{
                // overflow:'visible !important',
            }
        },
        extraOptions: {
            touchBottom: true,
            ...props.extraOptions,
        },
        
    };
    return <>
    <XDataGridPro2<InterfaceFrom<typeof params.buildConfig.schema>> params={params} data={DealerRankResource} />
    </>
}
