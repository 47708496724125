import React from 'react';
import { Typography, IconButton, Box, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { HomeSVG } from './svg/HomeIcon';
import { TransactionSVG } from './svg/TransactionPin';
import { MessageSVG } from './svg/MessagePin';
import { CRMData } from 'reactelements/src/modules/ViewEditCrm/types';
import { useHistory } from 'react-router-dom';

export const LeadHeader = ({ crmData, titleText, svgPin }: { crmData?: CRMData; titleText?: String; svgPin?: 'main' | 'msg' | 'txn' }) => {
    const navigate = useHistory();

    return (
        <Box key='lead-header' sx={theme => ({ position: 'fixed', width: '100%', zIndex: theme.zIndex.appBar })}>
            <Grid
                container
                px={4}
                sx={{
                    backgroundColor: '#424242',
                }}
            >
                <Grid item xs='auto'>
                    <IconButton sx={{ color: 'white', width: '24px' }} onClick={() => navigate.goBack()}>
                        <ArrowBackIosIcon />
                    </IconButton>
                </Grid>
                <Grid item xs>
                    {crmData && (
                        <Typography
                            align='center'
                            sx={{
                                color: 'white',
                            }}
                        >
                            <b>
                                {crmData?.address?.streetNumber
                                    ? `${crmData?.address?.streetName} ${crmData?.address?.streetNumber}${crmData?.address?.streetAppendix}`
                                    : crmData?.address?.streetName}
                            </b>
                            <br />
                            {crmData.address.zipCode} {crmData.address.city}
                        </Typography>
                    )}
                    {titleText && (
                        <Typography
                            align='center'
                            sx={{
                                color: 'white',
                            }}
                        >
                            <b>{titleText}</b>
                            <br />
                        </Typography>
                    )}
                </Grid>

                <Grid item xs='auto' alignItems='center' justifyContent='center'>
                    <Box sx={{ width: '24px' }}>{/* <HomeSVG dark height={32} /> */}</Box>
                </Grid>
            </Grid>
            <Grid
                container
                sx={{
                    background: 'linear-gradient(180deg, #424242 50%, #ffffff00 50%);',
                }}
                alignItems='center'
                justifyContent='center'
            >
                <Grid item xs='auto'>
                    {svgPin === 'main' ? (
                        <HomeSVG />
                    ) : svgPin === 'txn' ? (
                        <TransactionSVG />
                    ) : svgPin === 'msg' ? (
                        <MessageSVG />
                    ) : null}
                </Grid>
            </Grid>
        </Box>
    );
};
