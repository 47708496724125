import React, { useContext } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { EmpplanSnackbarContext } from '../../../modules/Snackbar';
import { useTranslation } from 'react-i18next';
import { checkInEventHandler, checkIsEventDate, handleEventDateChange, handleDateChangePWA } from '../eventHelpers';
import moment from 'moment';
import { PWAContext } from '../../../PWA/pwaContext';
import { DetectOnline } from '../../../hooks/detectOnline';

interface EventCheckInProps {
    event: any;
    setEvent: any;
    setBottom?: (value: string) => void;
}

function EventCheckIn({ event, setEvent, setBottom }: EventCheckInProps) {
    const { t } = useTranslation();
    const { isOnline } = DetectOnline();
    const pwaContext = useContext(PWAContext);
    const snackbarTools = React.useContext(EmpplanSnackbarContext);
    const [checkingIn, setCheckingIn] = React.useState<'loading' | 'checkedIn' | ''>('');

    if (checkingIn === 'loading')
        return (
            <Box sx={{ pr: 2 }}>
                <CircularProgress color='secondary' size={25} />
            </Box>
        );

    if (checkingIn === 'checkedIn')
        return (
            <Box sx={{ pr: 2 }}>
                <Typography variant='body1' color='secondary'>
                    {t('##LoggedIn')}
                </Typography>
            </Box>
        );

    if (event.eventState !== 'Accepted') return null;

    const isEventToday = checkIsEventDate(event);

    if (isEventToday) {
        return (
            <Box>
                <Button
                    fullWidth
                    variant='contained'
                    disabled={event.area && !isOnline}
                    color='secondary'
                    onClick={() => {
                        setCheckingIn('loading');
                        checkInEventHandler(event, pwaContext).then((result: any) => {
                            if (result.success) {
                                setCheckingIn('checkedIn');
                                setEvent((e: any) => ({ ...e, eventState: 'LoggedIn' }));
                                if (pwaContext?.pwa) {
                                    if (event.area && setBottom) {
                                        setBottom('LoggedIn,LoggedOut,SalesReportRejected');
                                        if (pwaContext.pwaHelpers?.startSync?.start) {
                                            pwaContext.pwaHelpers?.startSync?.start();
                                        }
                                    } else {
                                        window.location.href = `/app#/event/${event._id}`;
                                    }
                                } else {
                                    window.location.href = `/#!/event/${event._id}`;
                                }
                            } else {
                                setCheckingIn('');
                                snackbarTools.createToast({
                                    type: 'error',
                                    message: t(result.message) || 'Error checking in event',
                                });
                            }
                        });
                    }}
                >
                    {t('##LoginEvent')}
                </Button>
            </Box>
        );
    }

    if (event.meta?.promoterChangeDate) {
        return (
            <Box>
                <Button
                    fullWidth
                    disabled={event.area && !isOnline}
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                        setCheckingIn('loading');
                        // change date to today
                        // check in event
                        if (pwaContext?.pwa) {
                            handleDateChangePWA(event, moment().startOf('day'), pwaContext, true)
                                .then((result: any) => {
                                    if (result.success) {
                                        let newEvent;
                                        setEvent((e: any) => {
                                            newEvent = { ...e, date: moment() };
                                            return newEvent;
                                        });
                                        return checkInEventHandler(newEvent, pwaContext);
                                    } else {
                                        setCheckingIn('');
                                        snackbarTools.createToast({
                                            type: 'error',
                                            message: (result.message && t(result.message)) || 'Error checking in event',
                                        });
                                        throw new Error('Failed to check in1');
                                    }
                                })
                                .then((result: any) => {
                                    if (result.success) {
                                        // success check in
                                        setEvent((e: any) => ({ ...e, eventState: 'LoggedIn' }));
                                        setCheckingIn('checkedIn');
                                        if (event.area && setBottom) {
                                            setBottom('LoggedIn,LoggedOut,SalesReportRejected');
                                            if (pwaContext.pwaHelpers?.startSync?.start) {
                                                pwaContext.pwaHelpers?.startSync?.start();
                                            }
                                        } else {
                                            window.location.href = `/app#/event/${event._id}`;
                                        }
                                    } else {
                                        setCheckingIn('');
                                        snackbarTools.createToast({
                                            type: 'error',
                                            message: (result.message && t(result.message)) || 'Error checking in event',
                                        });
                                        throw new Error('Failed to check in2');
                                    }
                                });
                        } else {
                            handleEventDateChange(event, moment().startOf('day'))
                                .then(result => {
                                    if (result.success) {
                                        let newEvent;
                                        setEvent((e: any) => {
                                            newEvent = { ...e, date: moment() };
                                            return newEvent;
                                        });
                                        return checkInEventHandler(newEvent);
                                    } else {
                                        snackbarTools.createToast({
                                            type: 'error',
                                            message: (result.message && t(result.message)) || 'Error checking in event',
                                        });
                                        throw new Error('Failed to check in1');
                                    }
                                })
                                .then((result: any) => {
                                    if (result.success) {
                                        // success check in
                                        setEvent((e: any) => ({ ...e, eventState: 'LoggedIn' }));
                                        setCheckingIn('checkedIn');
                                        window.location.href = `/#!/event/${event._id}`;
                                    } else {
                                        setCheckingIn('');
                                        snackbarTools.createToast({
                                            type: 'error',
                                            message: (result.message && t(result.message)) || 'Error checking in event',
                                        });
                                        throw new Error('Failed to check in2');
                                    }
                                })
                                .catch(err => {
                                    console.log(err);
                                });
                        }
                    }}
                >
                    {t('##LoginEventNow')}
                </Button>
            </Box>
        );
    }

    return null;
}

export default EventCheckIn;
