import React, { useContext, useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import TagIcon from '@mui/icons-material/Tag';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import Badge from '@mui/material/Badge';
import { PWAContext } from '../../../PWA/pwaContext';
import DeviceDetect from '../../../hooks/deviceDetect';

interface FilterProps {
    value: string;
    onChange: (event: React.SyntheticEvent<Element, Event>, value: 'Channels' | 'Inbox' | 'Tasks') => void;
    channelsCount: number;
    inboxCount: number;
    isSticky: boolean;
}

function BottomFilterMessenger({ value, onChange, channelsCount, inboxCount, isSticky }: FilterProps) {
    const { t } = useTranslation();
    const pwaContext = useContext(PWAContext);

    const BOTTOM_LABELS = [
        {
            filterValue: 'Channels',
            label: '##Channels',
            Icon: TagIcon,
            disabled: false,
        },
        {
            filterValue: 'Inbox',
            label: '##Inbox',
            Icon: MarkunreadMailboxIcon,
            disabled: false,
        },
        {
            filterValue: 'Tasks',
            label: '##Tasks',
            Icon: TaskAltIcon,
            disabled: true,
        },
    ];

    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: isSticky ? '64px' : pwaContext?.pwa ? 0 : 44,
                left: 0,
                right: 0,
                zIndex: 1000,
                width: isSticky ? '25%' : 'auto',
                boxShadow: isSticky ? '0px 2px 16px 1px rgba(0,0,0,0.12)' : 'auto',
            }}
            elevation={7}
        >
            <BottomNavigation showLabels value={value} onChange={onChange} sx={{ height: '90px' }}>
                {BOTTOM_LABELS.map(({ filterValue, label, Icon, disabled }) => {
                    return (
                        <BottomNavigationAction
                            key={filterValue}
                            value={filterValue}
                            label={t(label)}
                            disabled={disabled}
                            icon={
                                <Badge
                                    max={1000}
                                    badgeContent={
                                        filterValue === 'Channels'
                                            ? channelsCount
                                            : filterValue === 'Inbox'
                                            ? inboxCount
                                            : 0
                                    }
                                    color='secondary'
                                >
                                    <Icon />
                                </Badge>
                            }
                            sx={theme => ({
                                '&.Mui-selected': {
                                    color: theme.palette.secondary.main,
                                },
                            })}
                        />
                    );
                })}
            </BottomNavigation>
        </Paper>
    );
}

export default BottomFilterMessenger;
