import React, { useContext } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip,
    Typography,
    Button,
    TextField,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SalesItem } from '../../utils/salesitem';

import { ReportConfigurationContext } from '../CustomReport';
import { CustomReportComponent } from '../CustomReport/customReportComponent';
import { CheckCircleSharp } from '@mui/icons-material';
import API from '../../config/api';
import { useTranslation } from 'react-i18next';
import customReportValidator from '../CustomReport/utils/validator';
import { isCustomReportDisabled, ReportType } from '../CustomReport/utils/reportDisableControl';
import { formatReportResultsToSave, parseSavedReportResults } from '../CustomReport/utils/reportHelpers';
import { PWAContext } from '../../PWA/pwaContext';
import { DetectOnline } from '../../hooks/detectOnline';

interface TransactionProductFormProps {
    mandatory?: boolean;
    product: any;
    customReportConfig: CustomReportConfig;
    savedTransactionData?: SalesItem;
    savePayload: { eventId: string; productId: string };
    updateCallback?: any;
    eventState?: string;
    currentUserCanEditEventUser?: boolean;
    requiredTracker?: {
        add: (slug: string) => void;
        remove: (slug: string) => void;
    };
    expandedAccordion?: string | boolean;
    handleChangeAccordion?: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

function TransactionProductForm({
    mandatory,
    product,
    customReportConfig,
    savedTransactionData,
    savePayload,
    updateCallback,
    eventState,
    currentUserCanEditEventUser,
    requiredTracker,
    expandedAccordion,
    handleChangeAccordion,
}: TransactionProductFormProps) {
    const { t } = useTranslation();
    const pwaContext = React.useContext(PWAContext);
    const { isOnline } = DetectOnline();
    const [formState, setFormState] = React.useState<'resetting' | 'ready' | 'saved' | 'changed' | 'saving' | 'error'>(
        'ready',
    );
    const [taskTracker, setTaskTracker] = React.useState<Array<any>>([]); // array of ongoing tasks like uploads, etc.
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const { isDisabled } = useContext(ReportConfigurationContext);

    const [salesItem, setSalesItem] = React.useState<SalesItem | undefined>(savedTransactionData);

    const [qty, setQty] = React.useState(salesItem?.qty || 0);
    const [reportData, setReportData] = React.useState<ReportData>(
        parseSavedReportResults(salesItem?._customReport?.reportResults || {}),
    );
    const [formHasErrors, setFormHasErrors] = React.useState<boolean>(true);

    const reportConfig =
        (salesItem?._customReport?._configcreatedTime as unknown as CustomReportConfig) || customReportConfig;

    React.useEffect(() => {
        if (salesItem?._customReport) {
            setFormState('saved');
            requiredTracker?.remove(product._id);
        } else {
            setFormState('ready');
            mandatory && requiredTracker?.add(product._id);
        }
    }, [salesItem]);

    const onChangeHandler = (key: ReportFieldKey, value: ReportFieldValue) => {
        setReportData(s => ({ ...s, [key]: value }));
        setFormState(s => 'changed');
    };

    // calculates the qty from the config and report data
    // if there is an error, it returns 0 as default
    const calculateQtyFromReportData = () => {
        if (!reportConfig.transactionConfig) {
            console.error('Invalid report config - missing transaction config', { reportConfig });
            return 0;
        }
        // return 1 if `useQty1` is true
        if (reportConfig.transactionConfig?.useQty1) return 1;
        // return endField - startField
        if (reportConfig.transactionConfig?.startField && reportConfig.transactionConfig.endField) {
            const startFieldValue = reportData[reportConfig.transactionConfig.startField] || 0;
            const endFieldValue = reportData[reportConfig.transactionConfig.endField] || 0;
            return endFieldValue - startFieldValue;
        } else {
            console.error('Invalid report config - missing start and end fields', { reportConfig });
            return 0;
        }
    };

    const formSubmitHandler = async () => {
        setFormState('saving');
        if (salesItem?._id) {
            const putPayload = salesItem;
            if ((putPayload?._customReport?._configcreatedTime as any)['$$hashKey']) {
                delete (putPayload?._customReport?._configcreatedTime as any)['$$hashKey'];
            }
            putPayload.qty = qty;
            if (putPayload._customReport) {
                putPayload._customReport.reportResults = formatReportResultsToSave(reportData, reportConfig);
                console.log('Update Payload PUT', putPayload);
                if (pwaContext?.pwa) {
                    if (pwaContext.pwaHelpers?.salesItems?.update) {
                        await pwaContext.pwaHelpers?.salesItems?.update(putPayload);
                        setSalesItem(putPayload);
                        setFormState('saved');
                    } else {
                        console.log('Failed to update salesitem to dexie');
                    }
                } else {
                    API.put(`salesItems/${salesItem._id}`, putPayload)
                        .then(resp => resp.json())
                        .then((data: any) => {
                            if (data && data.eventId && data.productId) {
                                const sale = data as SalesItem;
                                setSalesItem(sale);
                                setQty(sale.qty as number);
                                setFormState('saved');
                                updateCallback && updateCallback();
                            } else {
                                console.log('Error updating the transaction', { data });
                                setFormState('error');
                            }
                        });
                }
            } else {
                console.error('Failed to update report date when updating transaction', { salesItem });
            }
        } else {
            const postPayload = {
                ...savePayload,
                _customReport: {
                    reportResults: formatReportResultsToSave(reportData, reportConfig),
                    _configcreatedTime: reportConfig,
                    _customReportConfig: reportConfig._id,
                },
                qty,
                price: product.price || 0,
            };
            console.log('Save Payload POST', postPayload);
            if (pwaContext?.pwa) {
                // handle pwa salesitem add new
                if (pwaContext.pwaHelpers?.salesItems?.add) {
                    await pwaContext.pwaHelpers?.salesItems?.add(postPayload);
                    (postPayload as any).isNewDoc = 1;
                    setSalesItem(postPayload as unknown as SalesItem);
                    setFormState('saved');
                } else {
                    console.log('Failed to update salesitem to dexie');
                }
            } else {
                API.post('salesItems', postPayload)
                    .then(resp => resp.json())
                    .then((data: any) => {
                        if (data && data.eventId && data.productId) {
                            const sale = data as SalesItem;
                            setSalesItem(sale);
                            setQty(sale.qty as number);
                            setFormState('saved');
                            updateCallback && updateCallback();
                        } else {
                            console.log('Error saving the transaction', { data });
                            setFormState('error');
                        }
                    });
            }
        }
    };

    const resetFormData = () => {
        setSalesItem && setSalesItem(undefined);
        setQty && setQty(0);
        setReportData && setReportData({});
    };
    const resetForm = () => {
        resetFormData();
        setFormState && setFormState('resetting');
    };

    React.useEffect(() => {
        if (formState === 'resetting') {
            setFormState && setFormState('ready');
        }
    }, [formState]);

    const saleDeleteHandler = () => {
        setFormState('saving');
        if (pwaContext?.pwa) {
            if (salesItem && pwaContext.pwaHelpers?.salesItems?.delete) {
                const successCallback = () => {
                    resetForm();
                    updateCallback && updateCallback();
                };
                const errorCallback = () => {
                    setFormState && setFormState('error');
                };
                pwaContext.pwaHelpers?.salesItems?.delete(salesItem, successCallback, errorCallback);
            } else {
                console.log('Failed to update salesitem to dexie');
            }
        } else {
            salesItem &&
                API.delete(`salesItems/${salesItem._id}`)
                    .then(resp => resp.json())
                    .then(data => {
                        if (!data.errors) {
                            resetForm();
                            updateCallback && updateCallback();
                        } else {
                            setFormState && setFormState('error');
                        }
                    });
        }
    };

    // used to prevent saving before uploads complete
    const taskControlHandler = {
        addTask: (s: string) => {
            setTaskTracker && setTaskTracker(t => [...t.filter(t => t !== s), s]);
        },
        removeTask: (s: string) => {
            setTaskTracker && setTaskTracker(t => t.filter(t => t !== s));
        },
    };

    React.useEffect(() => {
        // recalaulate the qty whenever the report data changes
        setQty(calculateQtyFromReportData());
        if (customReportConfig?.customFields && reportData) {
            // run the validator to update the errors
            const { hasErrors } = customReportValidator(customReportConfig, reportData, {
                productId: product._id,
            });
            // console.log('Run error checks', { hasErrors });
            // call the function to handle invalidated report state
            setFormHasErrors(hasErrors);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData]);

    const isDisabledButton = () => {
        return isCustomReportDisabled(reportConfig.type as ReportType, eventState, currentUserCanEditEventUser) ||
            ['saving', 'error'].includes(formState);
    }

    return (
        <ReportConfigurationContext.Provider
            value={{
                isDisabled:
                    isCustomReportDisabled(reportConfig.type as ReportType, eventState, currentUserCanEditEventUser) ||
                    ['saving', 'error'].includes(formState),
                productId: product._id,
            }}
        >
            <Accordion
                expanded={expandedAccordion !== undefined ? expandedAccordion === savePayload.productId : undefined}
                onChange={handleChangeAccordion?.(savePayload.productId)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='transaction-product-accordian-content'
                    id='transaction-product-accordian-header'
                >
                    <Box display='flex' justifyContent='space-between' width='100%'>
                        <Typography>{product.name}</Typography>
                        <Box component='span' display='flex' justifyContent='end' marginRight={3}>
                            {mandatory && <Chip size='small' color='warning' label={t('##FieldIsRequired')} />}
                            {formState === 'changed' && (
                                <Chip size='small' color='warning' label={t('##UnsavedChanges')} />
                            )}
                            {formState === 'error' && <Chip size='small' color='error' label={t('##Error')} />}
                            {formState === 'saved' && <CheckCircleSharp color='success' />}
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {formState !== 'resetting' && (
                        <CustomReportComponent
                            reportData={reportData}
                            contextObjForUpload={{
                                contextId: savePayload.eventId,
                                contextType: 'events',
                            }}
                            onChangeHandler={onChangeHandler}
                            taskControl={taskControlHandler}
                            reportConfig={reportConfig}
                        />
                    )}
                    {window.user?.roles?.indexOf('EventAdd') !== -1 && (
                        <Box paddingX={2}>
                            <TextField
                                type='number'
                                label={t('##AdminOverrideQuantity')}
                                variant='standard'
                                value={qty}
                                onChange={e => {
                                    setQty(Number(e.target.value));
                                    setFormState(s => 'changed');
                                }}
                            />
                        </Box>
                    )}
                    <Box display='flex' justifyContent='flex-end' paddingRight={6} gap={2}>
                        <Button
                            color='error'
                            sx={{ mt: 2 }}
                            disabled={isDisabledButton() || !salesItem || (!isOnline && !(salesItem as any)?.isNewDoc)}
                            onClick={() => {
                                // console.log('Deleting sale', { salesItem });
                                setDeleteDialogOpen(true);
                            }}
                        >
                            {t('##Delete')}
                        </Button>
                        <Dialog
                            open={deleteDialogOpen}
                            onClose={() => {
                                setDeleteDialogOpen(false);
                            }}
                            aria-labelledby='alert-dialog-title'
                            aria-describedby='alert-dialog-description'
                        >
                            <DialogTitle id='alert-dialog-title'>{t('##Confirmation')}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id='alert-dialog-description'>
                                    {t('##AreYouSureToDelete')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    autoFocus
                                    onClick={() => {
                                        setDeleteDialogOpen(false);
                                    }}
                                >
                                    {t('##No')}
                                </Button>
                                <Button
                                    onClick={() => {
                                        saleDeleteHandler();
                                        setDeleteDialogOpen(false);
                                    }}
                                >
                                    {t('##Yes')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Button
                            color='secondary'
                            variant='contained'
                            sx={{ mt: 2 }}
                            disabled={taskTracker.length > 0 || formState !== 'changed' || formHasErrors}
                            onClick={() => {
                                console.log('Submitting form', { reportData, taskTracker, product });
                                formSubmitHandler();
                            }}
                        >
                            {salesItem ? t('##Update') : t('##Save')}
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </ReportConfigurationContext.Provider>
    );
}

export default TransactionProductForm;
