import React from 'react';
import { checkSyncDownIsComplete } from '../../Models/helpers';
import { PWASyncControlContext } from '../../Services/pwaSyncControlContext';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';
import { useTranslation } from 'reactelements/node_modules/react-i18next';

function SyncDownError() {
    const { t } = useTranslation();
    const { startFullSync, deviceHashInfo, isSyncing } = React.useContext(PWASyncControlContext);
    const [showError, setShowError] = React.useState(false);

    React.useEffect(() => {
        const legalDisclaimer = localStorage.getItem('legalDisclaimer');
        if (!legalDisclaimer) return;

        const syncDownGood = checkSyncDownIsComplete();
        if (!syncDownGood) {
            setShowError(true);
        }
    }, [isSyncing, deviceHashInfo]);

    return (
        <>
            {showError && (
                <Dialog
                    key='legalDisclaimerDialog'
                    fullWidth
                    open={showError}
                    sx={{
                        padding: '2px',
                    }}
                >
                    <DialogTitle>{t('##CriticalSyncErrorHeadline')}</DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>{t('##CriticalSyncErrorInfo')}</Typography>
                        <Alert severity='info'>
                            <Link
                                target='_blank'
                                underline='hover'
                                rel='noreferrer'
                                href='https://www.empplan.de/training-center/empplan-app-quickstart/'
                            >
                                {t('##EmpplanAppQuickStartGuide')}
                            </Link>
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={() => {
                                setShowError(false);
                                startFullSync();
                            }}
                        >
                            {t('##Sync')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

export default SyncDownError;
