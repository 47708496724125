import React, { useContext, useEffect, useState } from 'react';
import API from '../../config/api';
import { useTranslation } from 'react-i18next';
import { EmpplanSnackbarContext } from '../Snackbar';
import { Box, Button, Grid, MenuItem, Modal, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { MomentInput } from 'moment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DetectOnline } from '../../hooks/detectOnline';
import { PWAContext } from '../../PWA/pwaContext';

export interface LeadList {
    _id: '##Lead' | '##FollowUp' | '##NotInterested' | '##NotYetVisited' | '##NotMarketable' | '##NotReached' | '##Blacklist';
    value: '##Lead' | '##FollowUp' | '##NotInterested' | '##NotYetVisited' | '##NotMarketable' | '##NotReached' | '##Blacklist';
    name: '##Lead' | '##FollowUp' | '##NotInterested' | '##NotYetVisited' | '##NotMarketable' | '##NotReached' | '##Blacklist';
    color: string;
}

interface IProps {
    row: any;
    cbAfterUpdate?: (data: any) => void;
    isList?: boolean;
    options?: LeadList[];
    projectId: string;
}

function LeadStatusSelect({ row, cbAfterUpdate, isList, options, projectId }: IProps) {
    const { t } = useTranslation();
    const snackbarTools = useContext(EmpplanSnackbarContext);
    const pwaContext = useContext(PWAContext);
    const { isOnline } = DetectOnline();

    const [openModal, setOpenModal] = useState(false);
    const [leadStatus, setLeadStatus] = useState(row?.status?._id || '');
    const [leadStatusList, setLeadStatusList] = useState<any>(options || []);
    const [loading, setLoading] = useState(false);
    const [followdate, setFollowdate] = useState<MomentInput>(null);
    const [leadStatusData, setLeadStatusData] = useState<any>({});

    const color = leadStatusList?.find((lead: any) => leadStatus === lead?._id)?.color;
    const textcolor = leadStatusList?.find((lead: any) => leadStatus === lead?._id)?.textColor;
    const label = leadStatusList?.find((lead: any) => leadStatus === lead?._id);
    const defaultLabel = leadStatusList?.find((lead: any) => '##NotYetVisited' === lead?.slangText);
    const defaultColor = leadStatusList?.find((lead: any) => '##NotYetVisited' === lead?.slangText)?.color;

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    useEffect(() => {
        if (Array.isArray(options) && options.length) {
            return;
        }
        (async () => {
            setLoading(true);
            const res = await API.get(`/crmCustomLeadStatusList`);
            const leadStaList = await res.json();

            if (Array.isArray(leadStaList)) {
                setLeadStatusList(leadStaList);
                setLeadStatusData(leadStaList?.find((lead: any) => leadStatus === lead?._id));
                setLoading(false);
            }
        })().catch(() => {
            setLoading(false);
        });
    }, []);

    // const handleStatusChange = (value: string) => {
    //     setLeadStatus(value);
    //     updateCrmData(value);
    //     handleClose();
    //   };

    const handleStatusChange = (value: any) => {
        setLeadStatusData(value);
        setLeadStatus(value._id);
        if (!value.isShowDateSelect) {
            setFollowdate(null);
            updateCrmData(value._id);
            handleClose();
        }
    };

    const handleDateChange = (newDate: moment.MomentInput) => {
        setFollowdate(newDate);
    };

    const handleDateAccept = (newDate: moment.MomentInput) => {
        if (leadStatusData?.isShowDateSelect) {
            updateCrmData(leadStatus);
            handleClose();
        }
    };

    const updateCrmData = async (leadStatus: string) => {
        try {
            const payload = {
                status: leadStatus,
                followUp: [{ date: followdate }],
                changedField: [
                    {
                        fieldName: '##LeadStatus',
                        fieldType: 'select',
                        newFieldData: leadStatus,
                        oldFieldData: '',
                    },
                ],
            };
            if (pwaContext?.pwa && !pwaContext.isHybridSync) {
                const result = await pwaContext?.pwaHelpers?.crmItems?.changeLeadStatus?.(
                    row._id,
                    projectId,
                    payload,
                    leadStatusList?.find((lead: any) => leadStatus === lead?._id),
                );

                if (result?.success && result.data._id) {
                    snackbarTools.createToast({ type: 'success', message: t`##CRMUpdated` });
                    cbAfterUpdate?.(result.data);
                } else {
                    console.error('failed to save crm', result);
                    throw new Error('failed to save crm');
                }
            } else {
                const result = await API.put(`/crm/${row?._id}`, { ...row, ...payload });
                const jsonData = await result.json();

                if (jsonData?.success && jsonData.data._id) {
                    snackbarTools.createToast({ type: 'success', message: t`##CRMUpdated` });
                    cbAfterUpdate?.(jsonData.data);
                } else {
                    console.error('failed to save crm', jsonData);
                    throw new Error('failed to save crm');
                }
            }
        } catch (error) {
            snackbarTools.createToast({ type: 'error', message: t`##CRMFailed` });
            console.error(error);
        }
    };

    return (
        <>
            {/* Button to open modal */}
            <Button
                variant='contained'
                onClick={e => {
                    e.stopPropagation();
                    handleOpen();
                }}
                sx={{
                    backgroundColor: color || defaultColor,
                    '&:hover': { backgroundColor: color || defaultColor },
                    borderRadius: isList ? '3px' : '10px',
                    // width: 'calc(100% - 32px)',
                    ...(isList ? { fontWeight: 'bold' } : { width: 'calc(100% - 32px)', minWidth: 'auto' }),
                    margin: isList ? '0' : '0 16px',
                    padding: isList ? '4px 12px' : '16px',
                    fontSize: isList ? '13px' : '16px',
                    position: 'relative',
                    textAlign: 'center',
                    color: textcolor || 'white',
                }}
            >
                <Box sx={{ flex: 1, textAlign: 'center' }}>
                    {/* {t(leadStatus || '##NotYetVisited')} */}
                    {label?.statusLabel
                        ? label?.type == 'EMPPLAN'
                            ? t(label?.slangText)
                            : t(label?.statusLabel)
                        : t(label?.custom_status) || defaultLabel?.statusLabel
                        ? defaultLabel?.type == 'EMPPLAN'
                            ? t(defaultLabel?.slangText)
                            : t(defaultLabel?.statusLabel)
                        : t(defaultLabel?.custom_status)}
                </Box>
                {!isList && (
                    <ArrowForwardIosIcon
                        sx={{
                            position: 'absolute',
                            right: '16px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}
                    />
                )}
            </Button>

            {/* Modal containing button-type list */}
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby='lead-status-modal'
                aria-describedby='lead-status-options'
                className="custom-modal"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        p: 3,
                        boxShadow: 24,
                        borderRadius: 2,
                        width: '95%',
                        maxHeight: '80vh',
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        sx={{
                            maxHeight: 'calc(80vh - 24px)',
                            overflowY: 'auto',
                            margin: 1,
                            paddingRight: '5px',
                        }}
                    >
                        <Grid container spacing={2}>
                            {leadStatusList.length === 0 ? (
                                <Grid item xs={12}>
                                    <Button fullWidth disabled>
                                        {t('No statuses available')}
                                    </Button>
                                </Grid>
                            ) : (
                                leadStatusList
                                    .filter((lead: any) => lead.active)
                                    .filter((lead: any) => lead._id !== leadStatus)
                                    .map((option: any, index: number) => (
                                        <Grid item xs={12} key={option._id}>
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    handleStatusChange(option);
                                                }}
                                                sx={{
                                                    backgroundColor: option.color,
                                                    '&:hover': { backgroundColor: option.color },
                                                    color: option.textColor || 'white',
                                                    padding: 2,
                                                }}
                                            >
                                                {/* {t(option.name)} */}
                                                {option?.statusLabel
                                                    ? option?.type == 'EMPPLAN'
                                                        ? t(option?.slangText)
                                                        : t(option?.statusLabel)
                                                    : t(option?.custom_status)}
                                            </Button>
                                        </Grid>
                                    ))
                            )}
                        </Grid>
                        {leadStatusData?.isShowDateSelect && (
                            <Grid item xs={12} marginTop='15px'>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DateTimePicker
                                        minDate={row?.followUp[0]?.date ? moment(row?.followUp[0]?.date) : undefined}
                                        format='DD.MM.YYYY HH:mm'
                                        openTo='day'
                                        views={['year', 'month', 'day', 'hours', 'minutes']}
                                        value={row?.followUp[0]?.date ? moment(row?.followUp[0]?.date) : null}
                                        onChange={handleDateChange}
                                        onAccept={handleDateAccept}
                                        label={t('##FollowUpDate')}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        )}
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

export default LeadStatusSelect;