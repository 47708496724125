import { newEventActionsDb } from './newEventActionCollectionDexie';
import ObjectID from 'bson-objectid';
import {tz} from 'moment-timezone';

// action types
export const newEventActionCollectionTypes = {
    NEW_EVENT_ACTION: 'NEW_EVENT_ACTION',
    GET_ALL_EVENT_ACTIONS: 'GET_ALL_EVENT_ACTIONS',
    UPDATE_EVENT_ACTIONS_SYNC_DATA: 'UPDATE_EVENT_ACTIONS_SYNC_DATA',
};

// actions
export const addEventActionCollection = (payload: any, optionalPayload?: any) => {
    newEventActionsDb.eventActions.add({
        _id: new ObjectID().str,
        action: {
            _id: payload._id,
            type: payload.type,
            payload: optionalPayload ? optionalPayload : {},
        },
        timeOfaction: JSON.stringify(tz().tz('Europe/Berlin')),
        lastChangeDate: new Date(),
        synced: false,
    });
};

export const getAllEventActionCollection = () => async (dispatch: any) => {
    const eventActions = await newEventActionsDb.eventActions.toArray();

    return dispatch({
        type: newEventActionCollectionTypes.GET_ALL_EVENT_ACTIONS,
        payload: eventActions,
    });
};
