import React, { useEffect, useState } from 'react';
import { Box, Button, CssBaseline, Grid, Link, Typography } from '@mui/material';
import PwaWrapper from 'reactelements/src/PWA/pwaWrapper';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import ProjectSelect from '../../../reactelements/src/modules/ProjectSelect';
import { BookmarkBorderSharp, DateRangeSharp, EditSharp, PlaceSharp, ScheduleSharp } from '@mui/icons-material';
import moment from 'moment';
import { MobileDatePicker, MobileTimePicker } from 'reactelements/node_modules/@mui/x-date-pickers';
import { TextField } from 'reactelements/node_modules/@mui/material';
import { useHistory } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import API from '../../../reactelements/src/config/api';
import TempExistingAreaEvents from '../../../reactelements/src/pages/MyEvents/components/TempExistingAreaEvents';
import { DistributionArea } from 'reactelements/src/utils/event';
import { EmpplanSnackbarContext } from 'reactelements/src/modules/Snackbar/index';
import { PWAContext } from '../../../reactelements/src/PWA/pwaContext';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import useViewMagicSettings from '../../../reactelements/src/hooks/useViewMagicSettings';
import { SmallProject } from 'reactelements/src/pages/Connector/Plugins/EventStatisticsExport/index';

interface ViewSettings {
    project?: SmallProject;
}

function AddNewEvent() {
    const { t } = useTranslation();
    const history = useHistory();
    const snackbarTools = React.useContext(EmpplanSnackbarContext);
    const pwaContext = React.useContext(PWAContext);

    const { viewMagicSettings, updateViewMagicSettings, viewSettingsReady } = useViewMagicSettings<ViewSettings>({
        view: 'fieldManagerPWAEventAdd',
        module: 'fieldManagerPWAEventAdd',
    });

    const [area, setArea] = useState<string | null>(null);
    const [posValue, setPosValue] = React.useState<DistributionArea[]>([]);
    const [project, setProject] = React.useState<SmallProject | undefined>(viewMagicSettings?.settingObject?.project);
    const [date, setDate] = React.useState<moment.Moment | null>(moment());
    const [startTime, setStartTime] = React.useState<moment.Moment | null>(moment());
    const [endTime, setEndTime] = React.useState<moment.Moment | null>(moment().add(30, 'minute'));
    const [areaLoading, setAreaLoading] = React.useState<boolean>(false);
    const [saveLoading, setSaveLoading] = React.useState<boolean>(false);

    useEffect(() => {
        // if no user right we will send them back to the myevents page
        if (!window?.user?.roles?.includes('FieldmanagerPWA')) {
            history.push('/myevents');
        }
        fetchUserAssociatedAreas();
    }, []);

    useEffect(() => {
        updateViewMagicSettings({
            project: project,
        });
    }, [project]);

    useEffect(() => {
        if (viewSettingsReady && viewMagicSettings?.settingObject?.project) {
            setProject(viewMagicSettings.settingObject.project);
        }
    }, [viewSettingsReady, viewMagicSettings]);

    const fetchUserAssociatedAreas = async () => {
        setAreaLoading(true);
        try {
            const data = await API.get(`/distributionarea/getUserAreas`);
            const response = await data.json();
            if (Array.isArray(response.areas) && response.areas.length > 0) {
                // for now we will set the first area...
                // we will change this in future on v2 by adding a dropdown for user to select area
                setArea(response.areas[0]);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setAreaLoading(false);
        }
    };

    const createEventForFieldManager = async (optionalPOS?: DistributionArea) => {
        if (!project?._id || (!optionalPOS && posValue?.length === 0) || !area || !window?.userabstraction) {
            return;
        }
        setSaveLoading(true);
        try {
            let promises: Iterable<Response | PromiseLike<Response>> = [];

            if (optionalPOS) {
                const payload = generatePayload(optionalPOS);
                promises = [API.post('/event/group', payload)];
            } else {
                promises = posValue.map(value => {
                    const payload = generatePayload(value);
                    return API.post('/event/group', payload);
                });
            }

            const responses = await Promise.all(promises);
            // Optionally, handle the responses
            // console.log('All requests completed:', responses);
            snackbarTools.createToast({ type: 'success', message: t('##Success') });
            if (pwaContext?.pwaHelpers?.startSync?.start) {
                pwaContext.pwaHelpers?.startSync?.start();
                history.push('/myevents');
            }
        } catch (error) {
            snackbarTools.createToast({ type: 'error', message: t('##Failed') });
            console.error('Error while creating events:', error);
        } finally {
            setSaveLoading(false);
        }
    };

    const generatePayload = (pos: DistributionArea) => {
        return {
            neededUsers: 1,
            project_id: project?._id,
            times: [
                {
                    start: startTime,
                    stop: endTime,
                },
            ],
            point_of_sale: {
                location: pos._id,
                _id: pos._id,
                location_type: 'location',
            },
            meta: {
                promoterChangeDate: true,
                promoterCanDeleteEvent: true,
            },
            eventsInfo: [
                {
                    agency_user: [
                        {
                            user_id: window.userabstraction,
                        },
                    ],
                    area: null,
                    meta: {
                        promoterChangeDate: true,
                        promoterCanDeleteEvent: true,
                    },
                },
            ],
            isAdd: true,
            selectedDistArea: null,
            eventState: 'Accepted',
            groupType: 'EventGroup',
            date: date,
            timetables: [
                {
                    date: date,
                    times: [
                        {
                            start: startTime,
                            stop: endTime,
                        },
                    ],
                },
            ],
            eventDate: date,
            stopDate: date,
            fieldReport: {},
            customeReport: {},
            inventoryConfig: {
                sourceType: 'event_user',
            },
            detailedPos: pos,
        };
    };

    return (
        <PwaWrapper>
            <CssBaseline></CssBaseline>

            {viewSettingsReady && area ? (
                <>
                    <Box mt={4} mb={2} mx={2}>
                        <Typography variant='h6' fontWeight={600}>
                            {t('##AddNewEvent')}
                        </Typography>
                    </Box>

                    <Box m={2} display='flex' alignItems='center'>
                        <BookmarkBorderSharp sx={{ mr: 1 }} />
                        <ProjectSelect initialValue={project} onChange={setProject} preloadOptions />
                    </Box>

                    <Grid container m={2} gap={2}>
                        <Grid item xs={12} display='flex' alignItems='center'>
                            <DateRangeSharp sx={{ mr: 1 }} />
                            <MobileDatePicker
                                closeOnSelect
                                value={date}
                                disablePast
                                format='DD.MM.YYYY'
                                onChange={newValue => {
                                    setDate(newValue);
                                }}
                                slots={{
                                    textField: textFieldProps => (
                                        <TextField
                                            {...textFieldProps}
                                            sx={{ width: 130 }}
                                            variant='filled'
                                            hiddenLabel
                                            size='small'
                                            InputProps={{
                                                ...textFieldProps.InputProps,
                                                disableUnderline: true,
                                                sx: { borderRadius: 3 },
                                                endAdornment: <EditSharp fontSize='small' />,
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} display='flex' alignItems='center'>
                            <ScheduleSharp sx={{ mr: 1 }} />
                            <Box display='flex' alignItems='center'>
                                <MobileTimePicker
                                    closeOnSelect
                                    value={startTime}
                                    ampm={false}
                                    onChange={newValue => {
                                        setStartTime(newValue);
                                    }}
                                    slots={{
                                        textField: textFieldProps => (
                                            <TextField
                                                {...textFieldProps}
                                                sx={{ width: 130 }}
                                                variant='filled'
                                                hiddenLabel
                                                size='small'
                                                InputProps={{
                                                    ...textFieldProps.InputProps,
                                                    disableUnderline: true,
                                                    sx: { borderRadius: 3 },
                                                    endAdornment: <EditSharp fontSize='small' />,
                                                }}
                                            />
                                        ),
                                    }}
                                />
                                <Typography marginX={1}>-</Typography>
                                <MobileTimePicker
                                    closeOnSelect
                                    value={endTime}
                                    ampm={false}
                                    onChange={newValue => {
                                        setEndTime(newValue);
                                    }}
                                    slots={{
                                        textField: textFieldProps => (
                                            <TextField
                                                {...textFieldProps}
                                                sx={{ width: 130 }}
                                                variant='filled'
                                                hiddenLabel
                                                size='small'
                                                InputProps={{
                                                    ...textFieldProps.InputProps,
                                                    disableUnderline: true,
                                                    sx: { borderRadius: 3 },
                                                    endAdornment: <EditSharp fontSize='small' />,
                                                }}
                                            />
                                        ),
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    {project ? (
                        <Box m={2} display='flex' alignItems='center'>
                            <PlaceSharp sx={{ mr: 1 }} />
                            <TempExistingAreaEvents
                                eventGroupId={''}
                                areaId={area}
                                projectId={project ? project._id : ''}
                                projectData={project ? project : null}
                                value={posValue}
                                setValue={setPosValue}
                                allowPromoterToAddNewLocation={true}
                                createDirectEvent={createEventForFieldManager}
                            />
                        </Box>
                    ) : null}

                    <Box position='absolute' bottom={0} width='100%'>
                        <Grid container alignItems='center' justifyContent='space-between' spacing={2} px={4} pb={4}>
                            <Grid item xs={4}>
                                <Button
                                    fullWidth
                                    sx={{ borderRadius: 50 }}
                                    color='error'
                                    variant='text'
                                    onClick={() => history.goBack()}
                                >
                                    {t`##Cancel`}
                                </Button>
                            </Grid>
                            <Grid item xs={8}>
                                {saveLoading ? (
                                    <LoadingButton
                                        fullWidth
                                        loading
                                        sx={{ borderRadius: 50 }}
                                        loadingPosition='start'
                                        startIcon={<SaveIcon />}
                                        variant='contained'
                                    >
                                        {t`##Add`}
                                    </LoadingButton>
                                ) : (
                                    <Button
                                        fullWidth
                                        disabled={posValue.length === 0}
                                        sx={{ borderRadius: 50 }}
                                        color='secondary'
                                        variant='contained'
                                        onClick={() => createEventForFieldManager()}
                                    >
                                        {t`##Add`}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </>
            ) : areaLoading ? (
                <Box p={2}>
                    <Typography component='div' key={'h1-skeleton'} variant='h1'>
                        <Skeleton />
                    </Typography>
                    <Typography component='div' key={'h1-skeleton'} variant='h1'>
                        <Skeleton />
                    </Typography>
                </Box>
            ) : (
                <Typography color='secondary' textAlign='center' mt={5} p={2}>
                    {t('##PleaseAssignAreaOrChannel')}
                    <br />
                    <Link component='button' color='secondary' onClick={() => history.goBack()}>
                        {t('##GoBack')}
                    </Link>
                </Typography>
            )}
        </PwaWrapper>
    );
}

export default AddNewEvent;
