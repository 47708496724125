import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';

/* TYPES */
// import { IChipElement, IChipsViewProps } from './types';

export default function ChipsViewEdit(props: IChipsViewProps) {
  if (!props.data || !Array.isArray(props.data)) props.data = [];
  const [chipData, setChipData] = React.useState(props.data);

  useEffect(() => {
    if (props.data) setChipData(props.data);
  }, [props.data]);

  // Custom list item for wrapping chips as `<li>`
  const ListItemChip = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));
  const isValidCSSColor = (attr: any) =>
    typeof attr === 'string' && attr.match(/^#[0-9a-f]{6}$/i) !== null
      ? attr
      : undefined;

  const handleClick =
    typeof props.handleChipClick === 'function'
      ? props.handleChipClick
      : (el: IChipElement) => {};
  const handleDelete =
    (typeof props.handleChipDelete === 'function' && props.handleChipDelete) ||
    ((el: IChipElement) => {});


  const isChipSelected = (chipItem: IChipElement): boolean => {
    let isSelected = false;
    if (Array.isArray(props.selectedChipsIds)) {
      props.selectedChipsIds.map(idItem => {
        let chipId = chipItem.id || chipItem._id;
        if (chipId.toString() === idItem.toString()) {
          //not best pattern becasue it needs to walk over all items.
          isSelected = true;
        }
      });
    }
    return isSelected;
  };

  /**
   * @param {IChipElement[]} data_
   * @param {any} getCustomizedTagsFunc
   */
  const renderChips = (data_: IChipElement[], getCustomizedTagsFunc: any) => {
    return data_.map((el: IChipElement, ix: number) => {
      let chipSelected = isChipSelected(el);
      const sxArray = [];
      if (chipSelected) {
        sxArray.push({
          '&:hover': {
            // This is only when chip is selected. Otherwise show hover bg
            backgroundColor: isValidCSSColor(el.bgColor)
              ? el.bgColor
              : '#e0e0e0',
          },
        });
      }
      sxArray.push({
        backgroundColor:
          isValidCSSColor(el.bgColor) &&
          (!props.setBgColorForSelectedOnly || chipSelected)
            ? el.bgColor
            : undefined,
        color: isValidCSSColor(el.textColor) ? el.textColor : undefined,
        border:
          '2px solid ' + (isValidCSSColor(el.bgColor) ? el.bgColor : '#e0e0e0'),
      });
      return (
        <Chip
          key={el._id}
          id={'' + (typeof el._id === 'undefined' ? ix : el._id)}
          label={ ((typeof props.getChipLabel === 'function' && props.getChipLabel?.(el)) ||
              ((el:any): string => (el && el?.name) || '')) as (option: IChipElement|string)=>string}
          {...getCustomizedTagsFunc({ index: ix })}
          onClick={
            props.allowChipClick === true ? () => handleClick(el) : undefined
          }
          onDelete={
            props.allowChipDelete === true ? () => handleDelete(el) : undefined
          }
          disabled={false}
          sx={sxArray}
        />
      );
    });
  };

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      freeSolo={true} // This prevents the dropdown button
      disableClearable={props.allowChipDelete !== true}
      disabled={props.allowChipDelete !== true && props.allowChipAdd !== true}
      renderInput={params => {
        return <TextField {...params} variant={'standard'} />;
      }}
      renderTags={renderChips}
      onChange={(event: any, newValue: any | null,reason) => {
        props?.onChange?.(newValue)
      }}
      options={chipData}
      defaultValue={props.noDefaltValue? props.default : chipData}
      getOptionLabel={ ((typeof props.getChipLabel === 'function' && props.getChipLabel) ||
          ((el:any): string => (el && el?.name) || '')) as (option: IChipElement|string)=>string}
      sx={{ minWidth: 0.25 }}
    />
  );
}
