import React, { useState } from 'react';
import { Box } from '@mui/material';
import Inbox from '../pages/Inbox';
import { Channel, Message } from '../types';
import ChatSection from './ ChatSection';
import ChannelHeader from './ChannelHeader';
import ChannelMenu from './ChannelMenu';
import API from '../../../config/api';

function ReactAssociatedChat({
    channel,
    cb,
    disableChannelNameLink,
    noMaxHeight = false,
    writeAtBottom,
}: {
    channel: Channel;
    cb: () => void;
    disableChannelNameLink?: boolean;
    noMaxHeight?: boolean;
    writeAtBottom?: boolean;
}) {
    const [reloadMesssages, setReloadMessages] = useState<Message | null>(null);
    const [openChannel, setOpenChannel] = useState<{ status: boolean; currentChannel: Channel | null }>({
        status: false,
        currentChannel: channel,
    });
    const [selectMode, setSelectMode] = useState<boolean>(false);

    const fetchChannel = async () => {
        try {
            const channelData = await API.get(`/chatchannel/${channel._id}`);
            const data = await channelData.json();
            setOpenChannel({
                ...openChannel,
                currentChannel: data,
            });
        } catch (error) {
            console.error(error);
        }
    };

    const callBackAfterDelete = () => {
        setOpenChannel({
            status: false,
            currentChannel: null,
        });
        cb && cb();
        // window.location.reload();
    };

    return (
        <>
            {openChannel.currentChannel && (
                <Box
                    sx={{ background: `linear-gradient(#e8a2ff -200%, #ffffff 100%);` }}
                    maxHeight={!noMaxHeight ? '500px' : undefined}
                    display='flex'
                    flexDirection='column'
                >
                    <Box mt={1} px={2} display='flex' justifyContent='space-between' alignItems='center'>
                        <ChannelHeader channel={channel} disableChannelNameLink={disableChannelNameLink} />
                        <ChannelMenu
                            openChannel={openChannel}
                            setOpenChannel={setOpenChannel}
                            setSelectMode={setSelectMode}
                            cbAfterDelete={callBackAfterDelete}
                            fetchChannel={fetchChannel}
                        />
                    </Box>
                    <Box height={!noMaxHeight ? '400px' : undefined} sx={{ overflowX: 'none', overflowY: 'scroll' }}>
                        <Inbox
                            inboxChannelId={channel._id as string}
                            disableScrolling={true}
                            selectMode={[selectMode, setSelectMode]}
                            reloadMessage={reloadMesssages}
                            setreloadMessage={setReloadMessages}
                            isReadOnly={channel.readOnly}
                        />
                    </Box>
                    <Box
                        sx={
                            writeAtBottom
                                ? {
                                      position: 'fixed',
                                      width: '100%',
                                      bottom: 0,
                                  }
                                : {}
                        }
                    >
                        <ChatSection channel={channel} setReloadMessages={setReloadMessages} shortChat={true} />
                    </Box>
                </Box>
            )}
        </>
    );
}

export default ReactAssociatedChat;
