import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Box,
    CircularProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    TextField,
    TextFieldProps,
} from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useTranslation } from 'react-i18next';
import { mapkey } from '../../../conf/constants';
import { request } from 'http';
import { trackEvent } from '../../../components-app/GAListener';
import API from '../../../config/api';

interface AutocompletePlacesGPSProps {
    defaultValue: string;
    setListFilters: any;
    latlong?: any;
    istime?: any;
    clear?: boolean;
    isnearby?: boolean;
    preloading?: boolean;
    setIsnearby?: any;
    setClear?: any;
    isDisabled?: boolean;
    isOnline?: any;
}

function AutoCompletePlaceGeo({
    defaultValue,
    setListFilters,
    latlong,
    istime,
    clear,
    isnearby,
    preloading,
    setIsnearby,
    setClear,
    isDisabled,
    isOnline,
}: AutocompletePlacesGPSProps) {
    ///save/api/allgoogleapi
    const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
        apiKey: mapkey,
    });
    const [value, setValue] = useState<any>({});
    const [inputValue, setInputValue] = useState(defaultValue);
    const [loading, setLoading] = React.useState(false);
    const [options, setOptions] = useState<any>([]);
    const [open, setOpen] = React.useState(false);
    const autoC = useRef<any>(null);

    const { t } = useTranslation();
    const updateLog = (response: any, type: any) => {
        let paylaod = {
            type: type,
            status: true,
            response: response,
        };
        API.post(`projects/save/api/allgoogleapi`, paylaod)
            .then(response => {
                //console.log(response);
            })
            .catch(err => {
                //showSnackBar('##EventGroupUpdatedFailed', 'error');
                //console.log(err);
            });
    };

    React.useEffect(() => {
        //console.log(value);
        if (value) {
            if (value?.geometry) {
                if (Object.keys(value).length !== 0) {
                    setListFilters((f: any) => ({
                        ...f,
                        formatted: istime
                            ? value?.description + ' - ' + new Date().toLocaleTimeString()
                            : value?.description,
                        place_id: value?.place_id,
                        timestamp: Date.now(),
                        response: value,
                    }));
                }
            } else {
                //for geo data enable this but it will cost high
                placesService?.getDetails({ placeId: value?.place_id }, details => {
                    updateLog(details, 'getPlaceDetails');
                    updateLog(details, 'contactData');
                    updateLog(details, 'atomsphereData');
                    // console.log('Place Details',details);
                    if (details && Object.keys(details).length !== 0) {
                        setListFilters((f: any) => ({
                            ...f,
                            formatted: istime
                                ? details?.name +
                                  ', ' +
                                  details?.formatted_address +
                                  ' - ' +
                                  new Date().toLocaleTimeString()
                                : details?.name + ', ' + details?.formatted_address,
                            place_id: details?.place_id,
                            timestamp: Date.now(),
                            response: details,
                        }));
                    }
                });
            }
        }
    }, [value]);

    React.useEffect(() => {
        //console.log(value);
        if (preloading) {
            setLoading(true);
        }
    }, [preloading]);

    React.useEffect(() => {
        //console.log("lol",clear);
        const ele = autoC?.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
        if (ele) ele.click();
        setClear(false);
    }, [clear]);
    const callback = (results: any, status: any) => {
        //console.log(results);
        if (status === google.maps.places.PlacesServiceStatus.OK) {
            updateLog(results, 'nearbySearch');
            updateLog(results, 'contactData');
            updateLog(results, 'atomsphereData');
            // console.log('tt',results);
            let optionarray = [];
            for (var i = 0; i < results.length; i++) {
                if (results[i]?.formatted_address) {
                    results[i].description = results[i]?.formatted_address;
                } else {
                    results[i].description = results[i]?.name + ', ' + results[i]?.vicinity;
                    results[i].formatted_address = results[i]?.name + ', ' + results[i]?.vicinity;
                }

                var singleres = results[i];
                optionarray.push(singleres);
            }
            setOptions(optionarray);
            if (inputValue.length === 0) {
                setLoading(false);
                setOpen(true);
            }
        }
    };
    React.useEffect(() => {
        //console.log("lol",clear);
        var request = {
            bounds: new google.maps.LatLngBounds(
                new google.maps.LatLng(latlong.lat - 1, latlong.long - 1),
                new google.maps.LatLng(latlong.lat + 1, latlong.long + 1),
            ),
            keyword: inputValue,
        };
        if (isnearby) {
            trackEvent('GooglePlaceField', 'GooglePlaceField NearbyIcon', 'NearbyIcon');
            placesService?.nearbySearch(request, callback);
        }
    }, [isnearby]);

    // React.useEffect(() => {
    //     //console.log("lol",clear);
    //     var request = {
    //         bounds: new google.maps.LatLngBounds(
    //                         new google.maps.LatLng(latlong.lat -1,latlong.long - 1),
    //                         new google.maps.LatLng(latlong.lat + 1,latlong.long + 1)
    //                     ),
    //         keyword :inputValue
    //       };
    //     if(isnearby){
    //         placesService?.nearbySearch(request,callback);
    //         }
    // }, [inputValue]);

    useEffect(() => {
        // fetch place details for the first element in placePredictions array
        //console.log("hook called",placePredictions);
        if (placePredictions.length > 0) {
            setOptions(placePredictions);
        }
    }, [placePredictions]);

    return (
        <div style={{ overflowX: 'hidden', overflowY: 'auto' }}>
            <Autocomplete
                ref={autoC}
                disabled={isDisabled || !isOnline}
                id='geo-box'
                options={options}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                getOptionLabel={(option: { description: any }) => option.description}
                inputValue={inputValue}
                loading={loading}
                onChange={(event: any, newValue: any) => {
                    if (isnearby) {
                        setIsnearby(false);
                    }
                    setValue(newValue);
                    setOptions([]);
                }}
                filterOptions={options => options}
                onInputChange={(event, newInputValue) => {
                    setLoading(true);
                    getPlacePredictions({ input: newInputValue });
                    if (newInputValue.length > 0 && event.type == 'change') {
                        updateLog(placePredictions, 'getPredictions');
                    }
                    trackEvent('GooglePlaceField', 'GooglePlaceField TextSearch', 'TextSearch');
                    //placesService?.textSearch({query :inputValue},callback)
                    setLoading(false);
                    setInputValue(newInputValue);
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={t('##SearchByAddress')}
                        variant='standard'
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color='inherit' size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </div>
    );
}

export default AutoCompletePlaceGeo;
