import Dexie, { Table } from 'dexie';


class EventActionDB extends Dexie {
    eventActions!: Table<DexieEventAction>

    constructor() {
        super('eventActionsDB');
        
        this.version(1).stores({
            eventActions: `_id, lastChangeDate`
        });
    }
}

export const newEventActionsDb = new EventActionDB();