import { newFileSystemDb } from './newFileSystemDexie';
// import { syncAllFileSystems } from './helpers';
import ObjectID from 'bson-objectid';

const newFileSystemActionTypes = {
    // CREATE_NEW_FILESYSTEM: 'CREATE_NEW_FILESYSTEM',
    NEW_FILESYSTEM: 'NEW_FILESYSTEM',
    UPDATE_FILESYSTEM: 'UPDATE_FILESYSTEM',
    LOAD_FILESYSTEM_LIST: 'LOAD_FILESYSTEM_LIST',
    LOAD_FILESYSTEM: 'LOAD_FILESYSTEM',
    UPDATE_FILESYSTEM_SYNC_DATA: 'UPDATE_FILESYSTEM_SYNC_DATA',
};

export default newFileSystemActionTypes;

// takes the base64 and other details and creates a filesystem doc in dexie
export const createNewFileSystemAction = (fileSystem: DexieFileSystem, optionalCallback?: any) => {
    if (fileSystem) {
        if (!fileSystem._id) {
            fileSystem._id = new ObjectID().str;
        }
        fileSystem.syncPending = 1;
        fileSystem.isNewDoc = 1;
        newFileSystemDb.fileSystems.add(fileSystem);
        optionalCallback && optionalCallback(fileSystem._id);
        return {
            type: newFileSystemActionTypes.NEW_FILESYSTEM,
            payload: fileSystem,
        };
    }
};

export const getFileSystemDataFromDexie = async (fileId: string) => {
    // match the API response, without base64
    const dexieFile = await newFileSystemDb.fileSystems.get(fileId);
    if (dexieFile && dexieFile._id && dexieFile.fileSystemPayload?.base64) {
        return {
            context: dexieFile.fileSystemPayload.context, // as sent during upload
            fileExtension: dexieFile.fileSystemPayload?.name?.name?.split('.')?.pop(),
            base64: dexieFile.fileSystemPayload?.base64,
            metaData: dexieFile.fileSystemPayload?.metaData,
            // there are more but i ignore for now
        };
    }
    throw new Error('cannot load file from dexie');
};
// takes an id and uses that to load the images to an array in the redux store
// this needs to be cleared when the event is not rendered anymore

// or maybe we use the function below instead of this to load all the images related to the event and show what is there and don't care about what is not there
export const loadFileSystemAction = async (dispatch: any, fileSystemId: string | null) => {
    if (fileSystemId !== null && fileSystemId) {
        const fileSystems = await newFileSystemDb.fileSystems.get(fileSystemId);
        return dispatch({
            type: newFileSystemActionTypes.LOAD_FILESYSTEM,
            payload: fileSystems,
        });
    } else {
        return dispatch({
            type: newFileSystemActionTypes.LOAD_FILESYSTEM,
            payload: undefined,
        });
    }
};
