import Chip, { ChipProps } from '@mui/material/Chip';
import { ClassNameMap, makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import {useTranslationSafe} from "../../config/translate";
// TODO: fix this label component, its messy
const useStyles = makeStyles({
    label_event_state_planned: {
        background: '#FFC926 !important',
    },
    label_event_state_brandplanned: {
        background: '#de0062 !important',
    },
    label_event_state_published: {
        background: '#99ACB8 !important',
    },
    label_event_state_rejected: {
        background: '#DE5D53 !important',
    },
    label_event_state_salesreport_rejected: {
        background: '#DE5D53 !important',
    },
    label_event_state_accepted: {
        background: '#59A17E !important',
    },
    label_event_state_loggedIn: {
        background: '#D65AF4 !important',
    },
    label_event_state_loggedOut: {
        background: '#883B9B !important',
    },
    label_event_state_salesReport: {
        background: '#7A55D6 !important',
    },
    label_event_state_salesReport_uploaded: {
        background: '#7A55D6 !important',
    },
    label_jobs_dark: {
        background: 'rgb(66,66,66)',
    },
    label_event_state_salesreport_accepted: {
        background: '#23ABA6 !important',
    },
    label_event_state_creditable: {
        background: '#4CAF50 !important',
    },
    label_event_state_invoiced: {
        background: '#B3C601 !important',
    },
    label_event_state_notinvoiced: {
        background: '#424242 !important',
    },
    label_event_state_new: {
        background: '#9662D0 !important',
    },
    team: {
        background: '#e0e0e0 !important',
    },
    blocked: {
        background: '#de5d53 !important',
    },
    legallydeleted: {
        background: '#a33615 !important',
    },
    application: {
        background: '#f8d516 !important',
    },
    Invoiced: {
        background: '#616161 !important',
    },
    ReadyToInvoice: {
        background: '#5CB85B !important',
    },
    Cancelled: {
        background: '#FF5252 !important',
    },
    uppercase: {
        textTransform: 'uppercase',
    },
    text: {
        color: '#fff !important',
        fontWeight: 'bold',
        borderRadius: '3px !important',
        marginRight: '3px !important',
        marginBottom: '3px !important',
    },
    default: {
        background: '#8f9d9d !important',
    },
});
const stateToClassName = {
    BrandPlanned: 'label_event_state_brandplanned',
    Published: 'label_event_state_published',
    Rejected: 'label_event_state_rejected',
    Accepted: 'label_event_state_accepted',
    LoggedOut: 'label_event_state_loggedOut',
    SalesReport: 'label_event_state_salesReport',
    LoggedIn: 'label_event_state_loggedIn',
    Planned: 'label_event_state_planned',
    Pending: 'label_event_state_planned',
    Sold: 'label_event_state_accepted',
    Paid: 'label_event_state_accepted',
    Deleted: 'label_event_state_rejected',
    Canceled: 'label_event_state_rejected',
    SalesReportUploaded: 'label_event_state_salesReport_uploaded',
    SalesReportAccepted: 'label_event_state_salesreport_accepted',
    SalesReportRejected: 'label_event_state_salesreport_rejected',
    Invoiced: 'label_event_state_salesreport_accepted',
    New: 'label_event_state_new',
    VideoApplication: 'label_event_state_accepted',
    WUNDERWORKER: 'label_event_state_loggedIn',
} as Record<string, string>;

export function LabelChip({value}: {value: string;}) {
    const classes: ClassNameMap = useStyles();
    const { t } = useTranslationSafe();

    if(!value) return null;
    const classNameFromValue: string = classes[stateToClassName[value] || value?.toLowerCase()] || classes.default;
    let translatedLabel: string = t('##' + value).replace('##', ''); //replace ## from translation, fix for eventType

    const className = [
        classNameFromValue,
        classes.uppercase,
        classes.text,
    ].join(' ');
    return <Chip className={className} label={translatedLabel} />;
}
