/**
 * Instance to create a get request
 * @param url The relative path to the resource
 * @returns An awaitable fetch promise
 */
export const getRequest = (url: any, params?: URLSearchParams | string) => {
    const _url = `${url}${params ? '?' + params : ''}`;
    return fetch(_url, {
        method: 'GET',
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
        redirect: 'follow',
    });
};
export const postRequest = (url: any, dataObject: Object) =>
    fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json, text/plain, */*',
        },
        redirect: 'follow',
        body: JSON.stringify(dataObject),
    });
export const postDataRequest = (url: any, dataObject: Object) =>
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json, text/plain, */*',
        },
        redirect: 'follow',
        body: JSON.stringify(dataObject),
    });
export const deleteRequest = (url: any, params?: URLSearchParams | string, dataObject?: Object) => {
    const _url = `${url}${params ? '?' + params : ''}`;
    return fetch(_url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json, text/plain, */*',
        },
        redirect: 'follow',
        body: JSON.stringify(dataObject),
    });
};
export interface RequestParams {
    url: String;
    params: Record<string, any>;
}

export const getReq = ({ url, params }: RequestParams, signal?: any) => {
    let paramString = params ? '?' + new URLSearchParams(params).toString() : '';
    console.log(paramString);

    const _url = `${url}${paramString}`;

    return new Promise((resolve, reject) => {
        fetch(_url, {
            method: 'GET',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            redirect: 'follow',
            signal: signal,
        })
            .then(r => {
                resolve(r.json());
            })
            .catch(e => {
                reject(e);
            });
    });
};
const API = {
    get: getRequest,
    post: postDataRequest,
    put: postRequest,
    delete: deleteRequest,
    getReqestWithParams: getReq,
};

export default API;
