import { newProductReportDb } from './newProductReportDexie';
import newProductReportActionTypes, { createNewProductReportAction } from './actions';
import DynTableConst from '../../Services/Constants';
import newSyncDataActionTypes from '../NewSync/actions';
import axios from 'axios';
import { setDbSyncedDown } from '../helpers';

const PaginationLimit = 20;

export const createNewProductReportWrapperForDexie = (productReport: any) =>
    ({
        _id: productReport._id,
        productReport: productReport,
        syncDate: new Date(),
    } as DexieProductReport);

export const syncAllProductReports = async (dispatch: any, projectIds: string) => {
    try {
        // drop the projects in the database
        await newProductReportDb.transaction('rw', newProductReportDb.tables, async () => {
            await Promise.all(newProductReportDb.tables.map(table => table.clear()));
        });

        // get the new projects and load them to the database
        let count = 1;
        for (let idx = 0; idx < count; idx += PaginationLimit) {
            const productReportsResponse = await getProductReportsFetch({ projectIds }, idx);
            if (!productReportsResponse.success) throw new Error('Failed to sync custom reports');
            count = productReportsResponse?.count || 0;
            // console.log({ idx, projectsResponse, count });

            Array.isArray(productReportsResponse?.data) &&
                productReportsResponse.data.forEach((productReport: any) => {
                    productReport &&
                        productReport._id &&
                        dispatch(createNewProductReportAction(createNewProductReportWrapperForDexie(productReport)));
                });

            dispatch({
                type: newSyncDataActionTypes.ADD_SYNC_LOG,
                payload: { action: 'Sync newProductReportDb', idx, count },
            });
        }

        dispatch({
            type: newProductReportActionTypes.UPDATE_PRODUCTREPORT_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
        setDbSyncedDown('productReportsDB');
    } catch (error) {
        console.log(error);
        dispatch({
            type: newSyncDataActionTypes.ADD_SYNC_ERROR,
            payload: error || 'Error handled but no log available',
        });
        dispatch({
            type: newProductReportActionTypes.UPDATE_PRODUCTREPORT_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
    }
};

export const getProductReportsFetch = async (
    filter: { projectIds?: string; customReportId?: string },
    skip: number,
): Promise<any> => {
    return axios
        .post(
            DynTableConst.EMPPLAN_HOST + `/api/productreports/pwa/sync?limit=${PaginationLimit}&skip=${skip}`,
            { projectId: filter.projectIds, customReportId: filter.customReportId },
            {
                withCredentials: true, // for local dev
                // for local dev
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            },
        )
        .then(response => response.data)
        .catch(err => {
            console.error(err);
            return { foo: 'broken' };
        });
};
