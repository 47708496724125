import React, { useEffect, useState } from 'react';
import CustomModelFieldsForm from './CustomModelFieldsForm';
import useModal from '../../components-ui/useModal';
import { Box, Fab, IconButton, Modal, Paper, Portal, SpeedDial, SpeedDialAction, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import { t } from 'i18next'
import CustomFieldsComponent from './CustomFieldsComponent';
import API from '../../config/api';
import { Console } from 'console';
import CloseIcon from '@mui/icons-material/Close';

function GenericCustomModelFields({
    customModelData,
    modelName,
    optionalCantAddNewFields,
    showFieldViewAccess,
    agencySettings,
    showProfileOnly,
    showFieldTypes,
    position,
    showCustomFields,
    disableMe,
    tabName,
    errorField,
    setGenericFields,
    setRerenderComponent,
}:{
    customModelData?: any,
    modelName?: string,
    optionalCantAddNewFields?: boolean,
    showFieldViewAccess?: boolean,
    agencySettings?: any,
    showProfileOnly?: boolean,
    showFieldTypes?: boolean,
    position?: any,
    showCustomFields?: boolean,
    disableMe?: boolean,
    tabName?: string,
    errorField?: any,
    setGenericFields?: any,
    setRerenderComponent?:any;
}) {
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [customFieldsData, setCustomFieldsData] = useState<any>([]);
    const [currentModelCustomFieldConfig, setCurrentModelCustomFieldConfig] = useState<any>([]);
    const [open, setOpen] = useState(false);

    const [CustomModal, openModal, closeModal] = useModal();
    
    let currentModelCustomFieldConfigdata : any = [];
    let currentConfigAttributesKeys : any = [];
    let customModelDataForm : any = [];

    showFieldViewAccess = typeof showFieldViewAccess === 'undefined' ? false : showFieldViewAccess;
    tabName = typeof tabName === 'undefined' ? '' : tabName;
    errorField = typeof errorField === 'undefined' ? {} : errorField;
    showFieldTypes = typeof showFieldTypes === 'undefined' ? false : showFieldTypes;
    showCustomFields = typeof showCustomFields === 'undefined' ? true : showCustomFields;
    disableMe = typeof disableMe === 'undefined' ? true : disableMe;
    position = typeof position === 'undefined' ? {
        location:'MoreData',
        column:0
    } : position;

    useEffect(() => {
        (async () => {
            setLoading(true);
            const res = await API.get(`api/customModelField/field/?model=${modelName}`);
            const result = await res.json();
            if (Array.isArray(result) && result.length > 0) {
                const usersList = result;
                if (Array.isArray(usersList)) {
                    setCustomFieldsData(usersList);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        })().catch(() => {
            setLoading(false);
        });
    },[]);

    

    useEffect(() => {
        getIterationKeys();
    }, [customFieldsData])


    function getIterationKeys(){
        customModelDataForm = customModelData;
        var positionColumn = 1;
        if(customFieldsData.length > 0){
            customFieldsData.forEach((configObject:any, key:any) => {
                if(customFieldsData.hasOwnProperty(key)){
                    if(configObject.hasOwnProperty('fieldAttributeName')){
                        if(!customModelData.hasOwnProperty(configObject.fieldAttributeName)){
                            customModelDataForm[configObject.fieldAttributeName] = null;
                            customModelData[configObject.fieldAttributeName] = null;
                        }
                        if(modelName == 'Userabstraction'){
                            if(agencySettings?.AccessToProfileFields?.fields?.genericFields.hasOwnProperty(configObject.fieldAttributeName)){
                                agencySettings.AccessToProfileFields.fields.genericFields[configObject.fieldAttributeName] = {
                                    showInSedCard : false,
                                    showInProfile : true,
                                    truncateInSedCard : false
                                };
                            }
                            if(positionColumn > 3){
                                positionColumn = 1;
                            }
                            if (configObject.hasOwnProperty('position')) {
                                if (!configObject.position.hasOwnProperty('column') || configObject.position.column === "0" || configObject.position.column === 0) {
                                    configObject.position.column = positionColumn;
                                    positionColumn++;
                                }else{
                                    var columnStr = configObject.position.column;
                                    configObject.position.column = parseInt(columnStr);
                                }
                            }else{
                                configObject.position = {
                                    location : 'MoreData',
                                    column : positionColumn
                                };
                                positionColumn++;
                            }
                        }
                        if(currentConfigAttributesKeys.indexOf(configObject.fieldAttributeName) === -1){
                            currentConfigAttributesKeys.push(configObject.fieldAttributeName);
                            currentModelCustomFieldConfigdata.push(configObject);
                        }
                    }
                }
            })
            setCurrentModelCustomFieldConfig(currentModelCustomFieldConfigdata);
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {/* <CustomModal></CustomModal> */}
            <Portal>
                <Modal open={open}>
                    <>
                        <Box 
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%', // Full width
                                maxWidth: '100vw', // Maximum width to fit viewport
                                minWidth: '100vw', // Ensures full width
                            }}
                        >
                            <Box display={"flex"} justifyContent={"space-between"} bgcolor={"rgb(66,66,66)"} color={"whitesmoke"} p={2}>
                                <Typography variant='h5'>
                                    {t('##AddNewField')}
                                </Typography>
                                <IconButton onClick={() => handleClose()} sx={{color:"whitesmoke"}}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Paper sx={{ p: 3 }}>
                                <Box padding={2} sx={{ overflowY: 'scroll' }}>
                                    <CustomModelFieldsForm 
                                        modelName={modelName}
                                        showFieldTypes={showFieldTypes}
                                        position={position}
                                        onFormSubmit={() => { setRerenderComponent(Symbol(' ')); handleClose(); }}
                                    />
                                </Box>
                            </Paper>
                        </Box>
                    </>
                </Modal>
            </Portal>
            <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1, padding: 1 }}>
            {(modelName === 'Userabstraction' && showFieldViewAccess===true) || (modelName !== 'Userabstraction' && !optionalCantAddNewFields) &&
                <SpeedDial
                    ariaLabel="SpeedDial"
                    icon={
                        <Fab color='secondary'>
                            <MenuIcon />
                        </Fab>
                    }
                    direction='right'
                    color='secondary'
                >
                    <SpeedDialAction
                        key={'add'}
                        icon={<AddIcon />}
                        tooltipTitle={'Add'}
                        onClick={() => { setOpen(true); }}
                    />
                    {!(modelName === 'Userabstraction' && showFieldViewAccess===true) && 
                        <SpeedDialAction
                            key={'edit'}
                            icon={<EditIcon />}
                            tooltipTitle={'Edit'}
                            onClick={() => setEditMode(!editMode)}
                        />
                    }
                </SpeedDial>}
                {showCustomFields &&
                    <CustomFieldsComponent 
                        currentModelCustomFieldConfig={currentModelCustomFieldConfig}
                        modelName={modelName}
                        editMode={editMode}
                        showFieldTypes={showFieldTypes}
                        position={position}
                        customModelDataForm={customModelData}
                        agencySettings={agencySettings}
                        showProfileOnly={showProfileOnly}
                        disableMe={disableMe}
                        setGenericFields={setGenericFields}
                        tabName={tabName}
                        showFieldViewAccess={showFieldViewAccess}
                    />
                }
            </Box>
            {/*  */}
        </>
    );
}

export default GenericCustomModelFields;