import {Reducer} from "redux";
import {
    ProjectInvitationActions,
    ProjectInvitationTypes,
    ProjectStateTypeStateContainer
} from "./ProjectInvitationTypes";

export const initialEventContainerStateProjectInvitation:ProjectStateTypeStateContainer = {
    loading:false,
    projects:null
};

export const ProjectInvitationReducer: Reducer<ProjectStateTypeStateContainer, ProjectInvitationActions> = (
    state = initialEventContainerStateProjectInvitation,
    action
) => {
    switch (action.type) {
        case ProjectInvitationTypes.LOADING: {
            return {
                ...state,
                loading: action.loading
            };
        }
        case ProjectInvitationTypes.LOADMYINVITATIONS: {
            return {
                ...state,
                projects:action.projects,
                loading:false
            };
        }

    }
    return state;
};
