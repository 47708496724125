export interface ViewMagicSettings<T> {
    _id: string;
    version: string;
    view: string;
    module: string;
    settingObject?: T;
    synced: boolean | undefined;
    isSettingsSynced: any;
}

export async function updateViewMagicSettingsAPI({ _id, settingObject }: { _id: string; settingObject: any }) {
    return fetch(`/viewSettings/get/list/${_id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ _id, settingObject }),
    }).then(res => res.json()) as Promise<ViewMagicSettings<any>>;
}

export function createViewMagicSettingsAPI({ view, module, settingObject }: any) {
    return fetch(`/viewSettings`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ view, module, settingObject }),
    }).then(res => res.json()) as Promise<ViewMagicSettings<any>>;
}

export async function getViewMagicSettingsAPI({ view, module }: any) {
    const response: ViewMagicSettings<any>[] = await fetch(
        `/viewSettings/get/list?${new URLSearchParams({ view, module })}`,
    ).then(res => res.json());
    return response.pop();
}

export async function getOrCreateViewMagicSettingsAPI({ view, module, setIsFirstLoad }: any) {
    return getViewMagicSettingsAPI({ view, module }).then(settings => {
        if (settings) {
            return settings;
        }
        setIsFirstLoad(true)
        return createViewMagicSettingsAPI({ view, module, settingObject: {} });
    });
}
