import { AnyAction } from 'redux';
import newCustomReportActionTypes from './actions';

// INITIAL STATE
const initialState: CustomReportStates = {
    loadedCustomReports: [],
    loadedCustomReport: undefined,
    syncData: {
        state: 'READY',
        lastUpdate: null,
    },
};

// REDUCER

const customReportReducer = (state = { ...initialState }, action: AnyAction) => {
    if (action.type === newCustomReportActionTypes.LOAD_CUSTOMREPORT_LIST) {
        return { ...state, loadedCustomReports: action.payload };
    }
    if (action.type === newCustomReportActionTypes.LOAD_CUSTOMREPORT) {
        return { ...state, loadedCustomReport: action.payload };
    }
    if (action.type === newCustomReportActionTypes.CLEAR_LOAD_CUSTOMREPORT_LIST) {
        return { ...state, loadedCustomReports: [] };
    }
    if (action.type === newCustomReportActionTypes.UPDATE_CUSTOMREPORT_SYNC_DATA) {
        return {
            ...state,
            syncData: action.payload,
        };
    }

    return state;
};

export default customReportReducer;
