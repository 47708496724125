import { ActionCreator, AnyAction, Dispatch } from "redux";

import { ThunkAction } from "redux-thunk";

import {
    MenuActionTypes,
    MenuOpenActions,
    MenuCloseActions,
    MenuStates,
    DashboardNavigationChange, ModuleLoaded, EventPageNavigationChange
} from "./MenuStatesTypes";


export const openMenu: ActionCreator<MenuOpenActions> = () => {
    return {
        type: MenuActionTypes.OPENMENU
    }
};

export const closeMenu: ActionCreator<MenuCloseActions> = () => {
    return {
        type: MenuActionTypes.CLOSEMENU
    }
};

export const changeDashboardNavigation: ActionCreator<DashboardNavigationChange> = (dashboardNavPosition:number) => {
    return {
        type: MenuActionTypes.DASHBOARDNAVCHANGE,
        dashboardNavPosition:dashboardNavPosition
    }
};

export const changeEventNavigation: ActionCreator<EventPageNavigationChange> = (eventNavPosition:number) => {
    return {
        type: MenuActionTypes.EVENTNAVCHANGE,
        eventPageNavPosition:eventNavPosition
    }
};

export const loadedModule: ActionCreator<ModuleLoaded> = (moduleName:string) => {
    return {
        type: MenuActionTypes.MODULELOADED,
        moduleName:moduleName
    }
};

/**export function addTodo(text) {
    return { type: ADD_TODO, text }
}**/
/**export const openMenu: ActionCreator<AnyAction<Promise<AnyAction>, IProductsState, null, IProductsGetAllAction>> = () => {
    return async (dispatch: Dispatch) => {

    };
};**/


