import React from 'react';
import { Box } from '@mui/material';
import { ReportConfigurationContext } from '../../../reactelements/src/modules/CustomReport';
import { CustomReportComponent } from '../../../reactelements/src/modules/CustomReport/customReportComponent';
import { parseSavedReportResults } from '../../../reactelements/src/modules/CustomReport/utils/reportHelpers';

interface IProps {
    reportConfig: CustomReportConfig;
    reportData: ReportData;
}

function CustomReportReadOnly({ reportData, reportConfig }: IProps) {
    return (
        <Box>
            <ReportConfigurationContext.Provider
                value={{
                    isDisabled: true,
                }}
            >
                {reportConfig && (
                    <CustomReportComponent
                        reportData={parseSavedReportResults(reportData || {})}
                        reportConfig={reportConfig}
                        onChangeHandler={data => console.log(data)}
                    />
                )}
            </ReportConfigurationContext.Provider>
        </Box>
    );
}

export default CustomReportReadOnly;
