import * as React from 'react';
import CustomerDataForm from '../../../../reactelements/src/modules/CustomerDataForm';
import { CRMData } from 'reactelements/src/modules/ViewEditCrm/types';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { EmpplanSnackbarContext } from 'reactelements/src/modules/Snackbar';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePOS } from '../LeadDetailPage';
import { loadCrmDataForEditing, createCrmUpdateAction } from '../../../Models/NewCRM/actions';
import { PWASyncControlContext } from '../../../Services/pwaSyncControlContext';
import { updateResidentialUnit } from 'reactelements/src/modules/ViewEditCrm/index'; 

export const CRMEditForm = ({ projectId, leadId, mode }: { projectId: string; leadId: string; mode?: string }) => {
    const mainCRMData: CRMData = useSelector((store: any) => store?.crmItem?.crmItemForEditing);
    const addMode = mode === 'add';
    const navigate = useHistory<any>();
    const dispatch = useDispatch<any>();
    const { t } = useTranslation();
    const { isHybridSync } = React.useContext(PWASyncControlContext);
    const snackBarTools = React.useContext(EmpplanSnackbarContext);

    const [expandedPanel, setExpandedPanel] = React.useState<boolean>(false);
    const [crmResetCount, setCrmResetCount] = React.useState<number>(0);

    const extractCRMData = (data: CRMData): CRMData => {
        return {
            ...data,
            formOfAddress: '',
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            tel: '',
            dateOfBirth: null,
            dataProtectionAccepted: undefined,
            followUp: [],
            genericFields: {},
        };
    };

    const cancelCallback = () => {
        navigate.goBack();
    };

    const updateCrmDataToServer = async (data: CRMData) => {
        try {
            if (addMode) {
                let modifiedCRMData: CRMData = {
                    ...data,
                    userId: window.userabstraction,
                    transactionId: [],
                    projectId: [projectId],
                };

                const payload = {
                    crmItem: modifiedCRMData,
                    project_id: projectId,
                    point_of_sale: generatePOS(data),
                    uniqueCheckFailed: false,
                    placeId: modifiedCRMData?.address?.placeId,
                };

                const res = await axios.post(`/crm/checkin`, payload);
                const results = res.data;
                if (results.event) {
                    await updateResidentialUnit(data);
                    snackBarTools.createToast({ type: 'success', message: t`##CRMAdded` });
                    if (results.cmrId) {
                        navigate.push(`/leadDetail/${results.cmrId}/${projectId}`);
                        return;
                    } else {
                        navigate.push(`/event/${results.event._id}/TransactionReport?crmadded=true`);
                    }
                } else {
                    snackBarTools.createToast({ type: 'error', message: t`##Failed` });
                }
            } else {
                // check for offline mode!!!!!
                if (isHybridSync) {
                    const response = await axios.put(`/crm/${leadId}`, data);
                    if (response.data && response.data.success) {
                        snackBarTools.createToast({ type: 'success', message: t('##Success') });
                        cancelCallback();
                    } else {
                        // show snackbar
                        snackBarTools.createToast({ type: 'error', message: t('##Failed') });
                    }
                } else {
                    createCrmUpdateAction(leadId, { ...data })
                        .then(() => {
                            dispatch(loadCrmDataForEditing(undefined, leadId));
                        })
                        .catch(err => console.error(err))
                        .finally(cancelCallback);
                }
            }
        } catch (error) {
            snackBarTools.createToast({ type: 'error', message: t('##Failed') });
            console.error(error);
        }
    };

    return (
        <CustomerDataForm
            key='customer-data-form'
            customReports={mainCRMData?.customReportId as CustomReportConfig[]}
            crmItems={[
                { crmItem: addMode ? extractCRMData(mainCRMData) : mainCRMData, _id: addMode ? '' : mainCRMData?._id },
            ]}
            expandedPanel={expandedPanel}
            setExpandedPanel={setExpandedPanel}
            setCrmData={updateCrmDataToServer}
            reRender={crmResetCount}
            pointOfSale={''}
            isDirectCrm={false}
            googleData={false}
            crmAdded={false}
            projectId={projectId}
            forEditingCB={cancelCallback}
        />
    );
};
