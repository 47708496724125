import newProjectLocationActionTypes from './actions';

// INITIAL STATE
const initialState: ProjectLocationsStates = {
    loadedProjectLocations: [],
    loadedProjectLocation: undefined,
    loadedBrandsOfProject: undefined,
    syncData: {
        state: 'READY',
        lastUpdate: null,
    },
};

// REDUCER

const projectLocationReducer = (state = { ...initialState }, action: any) => {
    if (action.type === newProjectLocationActionTypes.LOAD_PROJECT_LOCATION_LIST) {
        return { ...state, loadedProjectLocations: action.payload };
    }
    if (action.type === newProjectLocationActionTypes.LOAD_PROJECT_LOCATION) {
        return { ...state, loadedProjectLocation: action.payload };
    }
    if (action.type === newProjectLocationActionTypes.UPDATE_PROJECT_LOCATION_SYNC_DATA) {
        return { ...state, syncData: action.payload };
    }
    if (action.type === newProjectLocationActionTypes.LOAD_BRANDS_OF_PROJECT) {
        return { ...state, loadedBrandsOfProject: action.payload };
    }
    if (action.type === newProjectLocationActionTypes.CLEAR_BRANDS_OF_PROJECT) {
        return { ...state, loadedBrandsOfProject: undefined };
    }
    if (action.type === newProjectLocationActionTypes.CLEAR_PROJECT_LOCATION_LIST) {
        return { ...state, loadedProjectLocations: [] };
    }

    return state;
};

export default projectLocationReducer;
