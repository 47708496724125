import React, { useEffect } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    Skeleton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { ExpandMore, TrendingUp } from '@mui/icons-material';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch, useSelector } from 'react-redux';
import { getEventEarningsAction, clearEventEarnings } from '../../Models/NewEventEarnings/actions';
import { DetectOnline } from 'reactelements/src/hooks/detectOnline';
import { isVatIdValidAndInUse } from './taxHelper';
import { makeStyles } from '@mui/styles';
import { formatCurrency } from '../../../reactelements/src/modules/EventOverviewGrid/utils/utils';

const useStyles = makeStyles({
    // Override the root style to remove borders
    accordionRoot: {
        border: 'none',
        background: 'white !important',
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
    },
});

function EventEarnings({ eventId }: { eventId: string }) {
    const { t } = useTranslation();
    const dispatch: any = useDispatch();
    const { isOnline } = DetectOnline();
    const classes = useStyles();

    const eventEarnings = useSelector((store: any) => store?.eventEarnings?.earnings);
    const project: DexieProject = useSelector((store: any) => store?.project?.loadedProject);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [invoiceItems, setInvoiceItems] = React.useState<any>({});

    const earnings = invoiceItems?.eventEarnings ? invoiceItems?.eventEarnings[0] : null;

    const InvoiceItemAccordianSummary = ({ title, amount }: { title: string; amount: number }) => (
        <Stack width='95%' direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
            <Typography sx={theme => ({ color: theme.palette.secondary.main })}>{title}</Typography>
            <Typography sx={{ color: 'white', background: '#424242', px: 1 }}>{formatCurrency(amount)}</Typography>
        </Stack>
    );

    useEffect(() => {
        setInvoiceItems && setInvoiceItems(eventEarnings);
    }, [eventEarnings]);

    const loadInvoiceItems = async () => {
        setIsLoading(true);
        dispatch(getEventEarningsAction(eventId));
        setIsLoading(false);
    };

    React.useEffect(() => {
        eventId && loadInvoiceItems();
        return () => {
            dispatch(clearEventEarnings());
        };
    }, []);

    const returnExpensePrice = (id: string, ogPrice: number, ogQty: number) => {
        if (earnings && Array.isArray(earnings.expenseProvision) && earnings.expenseProvision.length > 0) {
            const item = earnings.expenseProvision.find((each: any) => each.soldItemId === id);
            if (item.sum !== 0) {
                const sum = item.sum;
                return formatCurrency((ogPrice * ogQty + sum) / ogQty);
            } else {
                return formatCurrency(ogPrice);
            }
        } else {
            return formatCurrency(ogPrice);
        }
    };

    return (
        <Box mb={10}>
            <Box m={2} display='flex' justifyContent='space-between' alignItems='center' gap={1}>
                <Box display='flex' alignItems='center' gap={1}>
                    <TrendingUp />
                    <Typography variant='h5' fontWeight={500}>
                        {t('##Earnings')}
                    </Typography>
                </Box>
                <Tooltip title={t('##Refresh')}>
                    <IconButton
                        disabled={!isOnline}
                        onClick={() => {
                            loadInvoiceItems();
                        }}
                    >
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            {isLoading && (
                <>
                    <Skeleton height={66} />
                    <Skeleton height={66} />
                    <Skeleton height={66} />
                    <Skeleton height={66} />
                </>
            )}

            {!isLoading && !invoiceItems && <Typography>Could not load invoice items</Typography>}

            {!isLoading && Object.keys(invoiceItems).length > 0 ? (
                <>
                    {earnings &&
                        (earnings?.fixumExplained.length > 0 ||
                            earnings?.bonusExplained?.applied === true ||
                            earnings?.reportingBonusExplained?.applied === true ||
                            earnings?.customFieldReportingBonusExplained.length > 0) && (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore sx={theme => ({ color: theme.palette.secondary.main })} />}
                                    aria-controls='panel1a-content'
                                    id='panel1a-header'
                                >
                                    <InvoiceItemAccordianSummary
                                        title={t('##InvoiceProjectProvisions')}
                                        amount={
                                            earnings.base +
                                            earnings.bonus +
                                            earnings.reportingBonus +
                                            earnings.customFieldReportingBonusAmount
                                        }
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    {earnings.fixumExplained.length > 0 &&
                                        earnings.fixumExplained.map((eachFixum: any, idx: number) => {
                                            return (
                                                <Typography key={eachFixum.name + idx}>
                                                    {t(eachFixum.name)}: {formatCurrency(eachFixum.sum)}
                                                    {eachFixum.qty && (
                                                        <span style={{ float: 'right', paddingRight: '11%' }}>
                                                            {eachFixum.qty.toString().replace(/\./g, ',') +
                                                                'h  x ' +
                                                                formatCurrency(eachFixum.ruleAmount)}
                                                        </span>
                                                    )}
                                                </Typography>
                                            );
                                        })}
                                    {earnings?.bonusExplained?.applied === true && (
                                        <Typography>
                                            {t('##CheckInBonus')}: {formatCurrency(earnings.bonus)}
                                        </Typography>
                                    )}
                                    {earnings?.reportingBonusExplained?.applied === true && (
                                        <Typography>
                                            {t('##ReportingBonus')}: {formatCurrency(earnings.reportingBonus)}
                                        </Typography>
                                    )}
                                    {earnings?.customFieldReportingBonusExplained.length > 0 &&
                                        earnings.customFieldReportingBonusExplained.map((eachCustom: any, idx: number) => {
                                            return (
                                                <React.Fragment key={eachCustom.label + idx}>
                                                    {eachCustom.applied === true && (
                                                        <Typography>
                                                            {eachCustom.label}: {formatCurrency(eachCustom.sum)}
                                                        </Typography>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                </AccordionDetails>
                            </Accordion>
                        )}
                    {earnings && earnings.provision?.length > 0 && (
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore sx={theme => ({ color: theme.palette.secondary.main })} />}
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                            >
                                <InvoiceItemAccordianSummary
                                    title={t('##InvoiceTransactionalProvisions')}
                                    amount={earnings.provisionTotal}
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                {earnings.provision.map((eachProvision: any, idx: number) => {
                                    return (
                                        <Typography key={eachProvision.name + idx}>
                                            {eachProvision.name}: {formatCurrency(eachProvision.sum)}
                                            {eachProvision.saleId ? ` (${t`##SaleId`}: ${eachProvision.saleId})` : ''}
                                        </Typography>
                                    );
                                })}
                            </AccordionDetails>
                        </Accordion>
                    )}

                    {earnings && Array.isArray(earnings.expenses) && earnings.expenses.length > 0 && (
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore sx={theme => ({ color: theme.palette.secondary.main })} />}
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                            >
                                <InvoiceItemAccordianSummary
                                    title={t('##InvoiceExpenses')}
                                    amount={earnings.expenseTotal}
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                {earnings.expenses.map((exp: any, idx: number) => {
                                    return (
                                        <Typography key={exp?.productId?.name + idx}>
                                            {exp?.productId?.name}: {exp.qty} x{' '}
                                            {returnExpensePrice(exp._id, exp.price, exp.qty)}
                                        </Typography>
                                    );
                                })}
                            </AccordionDetails>
                        </Accordion>
                    )}
                    {earnings && earnings.genericBonusExplained.length > 0 && (
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore sx={theme => ({ color: theme.palette.secondary.main })} />}
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                            >
                                <InvoiceItemAccordianSummary
                                    title={t('##InvoiceCorrectionItems')}
                                    amount={earnings.genericBonusAmount}
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                {earnings.genericBonusExplained.map((genericBonus: any, idx: number) => {
                                    return (
                                        <Typography key={genericBonus.reason + idx}>
                                            {genericBonus.reason}: {formatCurrency(genericBonus.sum)}
                                            <span style={{ float: 'right', paddingRight: '11%' }}>
                                                {genericBonus.provisionType === 'Hour' &&
                                                    genericBonus.hours.toString().replace(/\./g, ',') +
                                                        'h  x ' +
                                                        formatCurrency(genericBonus.pricePerHour)}
                                            </span>
                                        </Typography>
                                    );
                                })}
                            </AccordionDetails>
                        </Accordion>
                    )}
                    {Array.isArray(invoiceItems.taxAmount) && invoiceItems.taxAmount.length > 0 && (
                        <Accordion
                            expanded={false}
                            onChange={event => event.stopPropagation()}
                            classes={{ root: classes.accordionRoot }}
                            sx={{ mt: 1, background: 'white !important' }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore sx={{ color: 'white' }} />}
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                            >
                                <Stack
                                    width='95%'
                                    direction='row'
                                    justifyContent='space-between'
                                    alignItems='center'
                                    spacing={2}
                                >
                                    <Typography sx={theme => ({ color: theme.palette.secondary.main })}></Typography>
                                    {invoiceItems.taxAmount.map((tax: any, idx: number) => {
                                        return (
                                            <Box key={idx + invoiceItems.brutto} display='flex' flexDirection='column'>
                                                <Box
                                                    gap={2}
                                                    textAlign='right'
                                                    display='flex'
                                                    justifyContent='space-between'
                                                >
                                                    <Typography>
                                                        <b>{t('##Netto')}</b>
                                                    </Typography>
                                                    <Typography>{formatCurrency(tax.sum)}</Typography>
                                                </Box>
                                                {isVatIdValidAndInUse(invoiceItems.event_user) && (
                                                    <>
                                                        <Box
                                                            textAlign='right'
                                                            display='flex'
                                                            justifyContent='space-between'
                                                            gap={2}
                                                        >
                                                            <Typography>
                                                                <b>
                                                                    {t('##Tax')} ({tax.vatPercentage}%)
                                                                </b>
                                                            </Typography>
                                                            <Typography>{formatCurrency(tax.taxAmount)}</Typography>
                                                        </Box>

                                                        <Box
                                                            textAlign='right'
                                                            display='flex'
                                                            justifyContent='space-between'
                                                            gap={2}
                                                        >
                                                            <Typography>
                                                                <b>{t('##Brutto')}</b>
                                                            </Typography>
                                                            <Typography>{formatCurrency(invoiceItems.brutto)}</Typography>
                                                        </Box>
                                                    </>
                                                )}
                                            </Box>
                                        );
                                    })}
                                </Stack>
                            </AccordionSummary>
                        </Accordion>
                    )}
                </>
            ) : (
                <>
                    <Skeleton height={66} />
                    <Skeleton height={66} />
                    <Skeleton height={66} />
                    <Skeleton height={66} />
                </>
            )}
        </Box>
    );
}

export default EventEarnings;
