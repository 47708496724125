import Dexie, { Table } from 'dexie';

class SalesItemsDB extends Dexie {
    salesItems!: Table<DexieSalesItem>;

    constructor() {
        super('salesItemsDB');
        this.version(1).stores({
            salesItems: `_id, event`,
        });
    }
}

export const newSalesItemDb = new SalesItemsDB();
