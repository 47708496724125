import { MobileDatePicker } from '@mui/x-date-pickers';
import { Box, TextField, Typography } from '@mui/material';
import moment from 'moment-timezone';
import { defaultDateFormat, formatDate } from '../../utils/date';
import { DivCenteredContent } from '../styled';

export interface IDateViewProps {
    value: Date;
}
export function DateView({ value }: IDateViewProps) {
    if (!value) return null;
    const date = formatDate(value);
    // const offsetFromGMT = moment.tz(date, defaultDateFormat).format('Z');
    return (
        <DivCenteredContent sx={{ justifyContent: 'start' }} title={/*offsetFromGMT*/ ''}>
            <Typography variant={'body2'}>{date}</Typography>
        </DivCenteredContent>
    );
}
