import { IViewConfig } from "../../components-ui/table/hooks/build";
import joi from '../../config/JoiExtension'

export const DealerRankTableConfig:IViewConfig = {


    schema:joi.object().keys({
        _id:joi.string().required().tag('mongoid','hideInTable'),
        location:joi.string().label("##Location"),
        productGroup: joi.string().label('##ProductGroup'),
        rank:joi.string().label('##Rank'),

    }),

    view: "DealerRankTable:List",
    module: "DealerRank",   
    filterable: [],
    sortable: ['rank'],
    
    userTypes: new Map([
        ['string::##Location', {
            tags: new Set(['string', '##Location']),
            value: (props: any) => {
              return props?.name && `${props?.name} (${props?.number})`
            },
          },
          ],
          ['string::##ProductGroup', {
            tags: new Set(['string', '##ProductGroup']),
            value: (props: any) => {
              return props?.name
            },
          },
          ],
    ])

}