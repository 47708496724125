import React, { useContext, useEffect, useState, useRef } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, Button, Typography, Box, Autocomplete, TextField } from '@mui/material';
import EmpplanTextField from '../../../modules/EmpplanFormComponents/EmpplanTextField';
import { t } from 'i18next';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import MessageIcon from '@mui/icons-material/Message';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { mapkey } from '../../../conf/constants';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { getLocationCoordinates } from '../../../hooks/useFileUpload/fileHelper';
import EmpplanAsynchronousAutoComplete from '../../../modules/EmpplanFormComponents/EmpplanAsynchronousAutoComplete';
import API from '../../../config/api';
import moment from 'moment';
import { EmpplanSnackbarContext } from '../../../modules/Snackbar';
import { CountryList, FormInputs, POSResponse } from './types';
import CircularProgress from '@mui/material/CircularProgress';
import EmpplanSelect from '../../../modules/EmpplanFormComponents/EmpplanSelect';
import { PWAContext } from '../../../PWA/pwaContext';
import { DistributionArea } from '../../../utils/event';

interface Props {
    open: boolean;
    close: (value: boolean) => void;
    areaId: string;
    initialLocation: { places: google.maps.places.AutocompletePrediction[] | null; value: string };
    eventGroupId: string;
    projectId: string;
    createDirectEvent?: ((pos: DistributionArea) => void) | null;
}

function AddLocationForm({
    open,
    close,
    initialLocation,
    areaId,
    eventGroupId,
    projectId,
    createDirectEvent = null,
}: Props) {
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        reset,
    } = useForm<any>({
        defaultValues: {
            locationAddress: {
                google: { description: initialLocation.value },
                name: '',
                postal: '',
                city: '',
                company: '',
                streetnumber: '',
            },
            moreDetails: {
                telephone: '',
            },
        },
    });

    const { placesService, getPlacePredictions, placePredictions, isPlacePredictionsLoading } = usePlacesService({
        apiKey: mapkey,
        libraries: ['places'],
    });
    const autoCompleteRef = useRef<any>(null);
    const pwaContext = useContext(PWAContext);

    const [dialogOpen, setDialogOpen] = useState<boolean>(open);
    const [inputValue, setInputValue] = useState<string>('');
    const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>(
        initialLocation.places ? initialLocation.places : [],
    );
    const [userLocation, setUserLocation] = useState({
        latitude: 0,
        longitude: 0,
    });
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const snackbarTools = useContext(EmpplanSnackbarContext);
    const [openAutoComplete, setOpenAutoComplete] = useState(true);
    const [countryList, setCountryList] = useState<CountryList[]>([]);

    const fetchUserLocation = async () => {
        try {
            const { latitude, longitude } = await getLocationCoordinates();
            setUserLocation({ latitude, longitude });
        } catch (e) {
            console.log(e);
            // error fetching user location
        }
    };

    const fetchAllCountryList = async () => {
        try {
            const countryListResponse = await API.get('/countries/all');
            const countryListData = await countryListResponse.json();
            setCountryList(countryListData);
        } catch (error) {
            console.error(error);
            // failed to fetch all country list
        }
    };

    useEffect(() => {
        if (initialLocation && initialLocation?.places && initialLocation?.places.length > 0) {
            setOptions(initialLocation.places);
            setOpenAutoComplete(true);
            setInputValue(initialLocation.value);
        }
        return () => {
            setOptions([]);
            setOpenAutoComplete(false);
            setInputValue('');
        };
    }, []);

    useEffect(() => {
        fetchAllCountryList();
        fetchUserLocation();
    }, []);

    const onSubmit: SubmitHandler<FormInputs> = async data => {
        setLoadingSave(true);
        try {
            // separate endpoint for promoters in creating POS
            const endpoint = '/pointofsale/addpos';
            const lat = data?.locationAddress?.googlePlaceResult?.geometry?.location?.lat();
            const lng = data?.locationAddress?.googlePlaceResult?.geometry?.location?.lng();
            const locationPayload: any = {
                address: {
                    country: data?.locationAddress?.country,
                    city: data?.locationAddress?.city,
                    postal: data?.locationAddress?.postal,
                    streetnumber: data?.locationAddress?.streetnumber,
                },
                distributionAreas: [areaId],
                genericFields: {},
                name: data?.locationAddress?.name,
                active: true,
                associatedUser: [],
                locationGroups: [],
                locationContacts: [],
                associatedProductGroups: [],
            };
            if (data?.locationAddress?.company) {
                locationPayload.address.company = data?.locationAddress?.company;
            }
            if (data?.locationAddress?.additional) {
                locationPayload.address.additional = data?.locationAddress?.additional;
            }
            if (data?.moreDetails?.email) {
                locationPayload.address.email = data?.moreDetails?.email;
            }
            if (data?.moreDetails?.telephone) {
                locationPayload.address.phone = data?.moreDetails?.telephone;
            }
            if (data?.locationAddress?.googlePlaceResult?.place_id) {
                locationPayload.googlePlaceId = data?.locationAddress?.googlePlaceResult?.place_id;
            }
            if (data?.locationAddress?.googlePlaceResult) {
                locationPayload.googleResult = data?.locationAddress?.googlePlaceResult;
            }
            if (data?.moreDetails?.notes) {
                locationPayload.notes = data?.moreDetails?.notes;
            }
            if (lat && lng) {
                locationPayload.location = [lng, lat];
            }
            // creating new location
            const postresponse = await API.post(endpoint, locationPayload);
            const response = await postresponse.json();
            // console.log(response);
            if (response._id) {
                try {
                    // we need entire populated POS data to add to projectLocation
                    const point_of_sale_data = await API.get(`/pointofsale/${response._id}`);
                    const point_of_sale = await point_of_sale_data.json();
                    // updating lat and long
                    // this is not responding 502
                    if (!data?.locationAddress?.googlePlaceResult) {
                        try {
                            const latnlong = await API.post(`/pointofsale/updatelatandlong`, { pos: point_of_sale });
                        } catch (err) {
                            console.error(err);
                            // catch sentry
                        }
                    }
                    // finding the structured result for the project from projectLocations
                    const pointOfSalesConfig = await API.get(`/projects/all/channel/areas/structured/${projectId}`);
                    const posConfigResult: POSResponse = await pointOfSalesConfig.json();
                    // here we will take the index[0] document which has the same area included from pointOfSaleConfig structure
                    const corresPondingProjectLocationConfig = posConfigResult?.pointOfSalesConfig.find(innerpos =>
                        innerpos?.distributionAreaIds?.includes(areaId),
                    );
                    // taking the channel and brand from the index[0]/first document that found
                    if (
                        corresPondingProjectLocationConfig?._id.brandId &&
                        corresPondingProjectLocationConfig._id.channelId
                    ) {
                        const projectLocationPayload = {
                            brand: corresPondingProjectLocationConfig?._id?.brandId,
                            channel: corresPondingProjectLocationConfig?._id?.channelId,
                            locations: [point_of_sale],
                            project: projectId,
                            distributionAreas: areaId,
                        };
                        // adding the newly added location to projectLocations collection
                        const projectLocationResponse = await API.put(
                            `/projects/${projectId}/pointofsales`,
                            projectLocationPayload,
                        );
                        const projectLocationResult = await projectLocationResponse.json();
                        // adding new location visit
                        if (projectLocationResult?.status === 'success' && projectLocationResult?.statuscode === 200) {
                            try {
                                if (createDirectEvent) {
                                    createDirectEvent(point_of_sale);
                                } else {
                                    const posId = response._id;
                                    const payload = {
                                        day: new Date(),
                                        hours: moment().hours(),
                                        minutes: moment().minutes(),
                                        eventState: 'Accepted',
                                        geoloc: {
                                            latitude: userLocation?.latitude || 0,
                                            longitude: userLocation?.longitude || 0,
                                        },
                                        disableDefaultTimes: true,
                                    };
                                    const locationVisitResponse = await API.post(
                                        `/event/reporting/new/${eventGroupId}/${posId}`,
                                        payload,
                                    );
                                    const locationVisitResult = await locationVisitResponse.json();
                                    // console.log(locationVisitResult);
                                    snackbarTools.createToast({
                                        type: 'success',
                                        message: t('##LocationAndLocationVisitAdded'),
                                    });
                                }
                                close(false);
                                setDialogOpen(false);
                                if (!createDirectEvent && pwaContext?.pwaHelpers?.startSync?.start) {
                                    pwaContext.pwaHelpers?.startSync?.start();
                                }
                            } catch (eventError) {
                                console.log(eventError);
                                snackbarTools.createToast({ type: 'error', message: t('##FailedToAddEvent') });
                            }
                        }
                    } else {
                        snackbarTools.createToast({
                            type: 'error',
                            message: t('##FailedToAddLocationToProjectContactAdmin'),
                        });
                    }
                } catch (err) {
                    console.log(err);
                    snackbarTools.createToast({ type: 'error', message: t('##FailedToAddLocationToProjectContactAdmin') });
                }
            }
        } catch (e) {
            console.log(e);
            snackbarTools.createToast({ type: 'error', message: t('##FailedToAddLocation') });
        } finally {
            setLoadingSave(false);
        }
    };

    const fetchOptions = (input: string) => {
        setInputValue(input);
        if (!input) {
            setOptions([]); // Clear options if input is empty
            return;
        }
        getPlacePredictions({ input });
    };

    useEffect(() => {
        if (placePredictions.length > 0) {
            setOptions(placePredictions);
        }
    }, [placePredictions, isPlacePredictionsLoading]);

    const extractAddressDetails = (address: google.maps.GeocoderAddressComponent[] | undefined) => {
        const getValue = (type: string) =>
            address?.find(innerAddress => innerAddress?.types?.includes(type))?.long_name || '';

        const city = getValue('locality');
        const postal = getValue('postal_code');
        const street = getValue('route');
        const streetNumber = getValue('street_number');
        const subPremise = getValue('subpremise');
        const streetAndNumber = `${street} ${streetNumber}`.trim();
        const country = address?.find(innerAddress => innerAddress?.types?.includes('country'))?.short_name || null;
        const formattedCountry = country ? countryList.find(formatList => formatList?.iso === country) : null;

        return { city, postal, streetAndNumber, formattedCountry, subPremise };
    };

    const handlePlaceSelect = async (selectedPlace: google.maps.places.AutocompletePrediction | null) => {
        if (selectedPlace) {
            placesService?.getDetails({ placeId: selectedPlace.place_id }, allPlaceDetails => {
                setValue('locationAddress.google', selectedPlace);
                setValue('locationAddress.googlePlaceResult', allPlaceDetails);
                const { city, postal, streetAndNumber, formattedCountry, subPremise } = extractAddressDetails(
                    allPlaceDetails?.address_components,
                );
                setValue('locationAddress.city', city || '');
                setValue('locationAddress.postal', postal || '');
                setValue('locationAddress.name', allPlaceDetails?.name || '');
                // if location not equal to street we can set the company address
                if (allPlaceDetails !== null && allPlaceDetails?.name?.trim() !== streetAndNumber.trim()) {
                    setValue('locationAddress.company', allPlaceDetails?.name || '');
                }
                setValue('locationAddress.streetnumber', streetAndNumber + subPremise || '');
                setValue('moreDetails.telephone', allPlaceDetails?.formatted_phone_number || '');
                if (formattedCountry) {
                    setValue('locationAddress.country', formattedCountry._id);
                }
                setOptions([]);
            });
        } else {
            setValue('locationAddress.google', null);
            setValue('locationAddress.googlePlaceResult', null);
            setInputValue('');
            return;
        }
    };

    return (
        <Dialog
            open={dialogOpen}
            onClose={() => {
                close(false);
                setDialogOpen(false);
            }}
            fullWidth
            fullScreen
        >
            <DialogTitle sx={{ backgroundColor: '#424242' }}>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography color='white'>{t('##AddNewPos')}</Typography>
                    <IconButton
                        onClick={() => {
                            close(false);
                            setDialogOpen(false);
                        }}
                    >
                        <CloseIcon sx={{ color: 'white' }} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justifyContent={'start'} spacing={2} sx={{ pt: 2 }}>
                        <Grid item xs={12}>
                            <Controller
                                name='locationAddress.google'
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Autocomplete
                                        {...field}
                                        ref={autoCompleteRef}
                                        defaultValue={initialLocation}
                                        options={options}
                                        open={openAutoComplete}
                                        onOpen={() => {
                                            setOpenAutoComplete(true);
                                        }}
                                        onClose={() => {
                                            setOpenAutoComplete(false);
                                        }}
                                        loading={isPlacePredictionsLoading}
                                        getOptionLabel={option => option?.description}
                                        filterOptions={options => options}
                                        inputValue={inputValue}
                                        onInputChange={(_, newInputValue) => fetchOptions(newInputValue)}
                                        onChange={(_, newValue, reason) => {
                                            if (reason === 'clear') {
                                                reset(
                                                    { 'locationAddress.google': { description: '' } },
                                                    { keepDefaultValues: false },
                                                );
                                                return;
                                            }
                                            handlePlaceSelect(newValue);
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label={t('##Location')}
                                                variant='standard'
                                                fullWidth
                                                error={fieldState.error !== undefined}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {isPlacePredictionsLoading ? (
                                                                <CircularProgress color='inherit' size={20} />
                                                            ) : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                    startAdornment: <LocationOnIcon fontSize='small' sx={{ mr: 1 }} />,
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box display='flex' gap={2}>
                                <LocationOnIcon />
                                <Typography>{t('##PosAddress')}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} pt={1}>
                            <EmpplanSelect
                                control={control}
                                name='locationAddress.country'
                                rules={{
                                    required: `${t('##Required')}`,
                                }}
                                label={t('##Country')}
                                options={countryList.map(eachCountry => ({
                                    value: eachCountry._id,
                                    label: eachCountry.name,
                                }))}
                            />
                        </Grid>
                        <Grid item xs={12} pt={1}>
                            <EmpplanTextField
                                name='locationAddress.name'
                                label={t('##PointOfSaleName')}
                                control={control}
                                rules={{
                                    required: `${t('##Required')}`,
                                }}
                                helperText={`${t('##Required')}`}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EmpplanTextField name='locationAddress.company' label={t('##Company')} control={control} />
                        </Grid>
                        <Grid item xs={12}>
                            <EmpplanTextField
                                name='locationAddress.streetnumber'
                                label={t('##StreetAndNumber')}
                                control={control}
                                rules={{
                                    required: `${t('##Required')}`,
                                }}
                                helperText={`${t('##CheckStreetAndNumber')}`}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EmpplanTextField
                                control={control}
                                name='locationAddress.additional'
                                label={t('##MoreAddressStuff')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EmpplanTextField
                                name='locationAddress.postal'
                                label={t('##Postal')}
                                control={control}
                                rules={{
                                    required: `${t('##Required')}`,
                                }}
                                helperText={`${t('##Required')}`}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EmpplanTextField
                                name='locationAddress.city'
                                label={t('##City')}
                                control={control}
                                rules={{
                                    required: `${t('##Required')}`,
                                }}
                                helperText={`${t('##Required')}`}
                            />
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            <Box display='flex' gap={2}>
                                <MessageIcon />
                                <Typography>{t('##MoreData')}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <EmpplanTextField name='moreDetails.email' label={t('##Email')} control={control} />
                        </Grid>
                        <Grid item xs={12}>
                            <EmpplanTextField
                                name='moreDetails.telephone'
                                label={t('##TelPlaceholder')}
                                control={control}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EmpplanTextField name='moreDetails.notes' label={t('##Notes')} control={control} />
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            <Button type='submit' variant='contained' color='secondary' fullWidth disabled={loadingSave}>
                                {t('##AddLocationAndLocationVisit')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
}

export default AddLocationForm;
