import {User, UserActionTypes} from "../UserStates/UserStatesTypes";
import {SmallEvent} from "../Events/SmallEventType";

const EventAreaLocations="EventAreaLocations";
export enum EventAreaLocationsActionTypes  {
    LOADING=  "EventAreaLocations/LOADING",
    GETBYEVENTID= "EventAreaLocations/GETBYEVENTID",
    LOAD_LOCATION_VISIT ="EventAreaLocations/LOADLOCATIONVISIT"
};


/**
 * TODO: move this to an general location type definition!
 */
export type EventLocation = {
    _id:string,
    address: {
        country:string,
        company:string,
        city:string,
        postal:string,
        streetnumber:string,
        additional:string,
    },
    name:string,
    number:string,
    location:[number, number]
}


export type LocationVisitResponse = {
    events:SmallEvent[],
    location:string,
}

export type EventAreaLocationsResponse = {
    success:boolean,
    projectLocations?:EventLocation[],
}


/**
 * here we store the different area locations for different projects inside an store object
 * this element is used only to store the items inside action and reducers!
 * We need another action or helper to return the correct needed item to the view
 */
export type EventProjectAreaLocationStore = {

        [key: string] : { //projectId is the key
                            [key: string] : EventLocation[] // area Id is the key
                        }

}
/**
 * type to store the old location visits!
 */
export type EventProjectAreaLocationVistis = {

    [key: string] : { //projectId is the key
        [key: string] : SmallEvent[] // location Id is the key
    }

}



export interface EventAreaLocationLoadOldLocationVisits {
    type: EventAreaLocationsActionTypes.LOAD_LOCATION_VISIT,
    events:SmallEvent[],
    location:string,
    projectId:string
}

export interface EventAreaLoadLocationsByProjectIdArea {
    type: EventAreaLocationsActionTypes.GETBYEVENTID,
    areaLocations: EventLocation[]|null,
    projectId:string,
    areaId:string
}




export interface EventStateTypeContainer {
    readonly projectAreaLocations: EventProjectAreaLocationStore| null;
    readonly loading: boolean;
    readonly areaLocations:EventLocation[]|null;
    readonly projectLocationVisits: EventProjectAreaLocationVistis|null;
}



export interface EventAreaLoadingAction {
    type:EventAreaLocationsActionTypes.LOADING,
    loading:boolean
}


export type EventAreaLocationActions =
    | EventAreaLoadLocationsByProjectIdArea
    | EventAreaLoadingAction
    | EventAreaLocationLoadOldLocationVisits;





