import Dexie, { Table } from 'dexie';

class ExpensesDB extends Dexie {
    expenses!: Table<DexieExpense>;

    constructor() {
        super('expensesDB');
        this.version(1).stores({
            expenses: `_id, event`,
        });
    }
}

export const newExpenseDb = new ExpensesDB();
