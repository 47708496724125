import { newProductDb } from './newProductDexie';
import { syncAllProducts } from './helpers';
import { Collection, IndexableType, Table } from 'dexie';

const newProductActionTypes = {
    NEW_PRODUCT: 'NEW_PRODUCT',
    LOAD_PRODUCT_LIST: 'LOAD_PRODUCT_LIST',
    LOAD_PRODUCT: 'LOAD_PRODUCT',
    UPDATE_PRODUCT_SYNC_DATA: 'UPDATE_PRODUCT_SYNC_DATA',
    LOAD_REPORT_PRODUCTS_LIST: 'LOAD_REPORT_PRODUCTS_LIST',
    LOAD_PRODUCTS_AUTO_COMPLETE: 'LOAD_PRODUCTS_AUTO_COMPLETE',
    CLEAR_AUTO_COMPLETE_LOAD: 'CLEAR_AUTO_COMPLETE_LOAD',
    LOAD_PRODUCTS_FOR_TRANSACTION: 'LOAD_PRODUCTS_FOR_TRANSACTION',
    CLEAR_FOR_TRANSACTION: 'CLEAR_FOR_TRANSACTION',
};

export default newProductActionTypes;

export const createNewProductAction = (product: DexieProduct) => {
    newProductDb.products.add(product);
    return {
        type: newProductActionTypes.NEW_PRODUCT,
        payload: product,
    };
};

export const loadProductAction = async (dispatch: any, productId: string | null) => {
    if (productId !== null && productId) {
        const products = await newProductDb.products.get(productId);
        return dispatch({
            type: newProductActionTypes.LOAD_PRODUCT,
            payload: products,
        });
    } else {
        return dispatch({
            type: newProductActionTypes.LOAD_PRODUCT,
            payload: undefined,
        });
    }
};

export const loadProductListAction = (filterOptions: {
    name?: string;
    ids?: string[] | string;
    projectId?: string;
    productType?: string;
    forAutoComplete?: boolean;
}) => async (dispatch: any) => {
    type DexieEventQueryBuilder = Collection<DexieProduct, IndexableType> | Table<DexieProduct, IndexableType>;
    let productsQuery: DexieEventQueryBuilder = newProductDb.products;

    if (filterOptions?.name !== undefined) {
        const nameRegex = new RegExp('.*' + filterOptions.name + '.*', 'i');
        productsQuery = productsQuery.filter((prod: any) => {
            // use between instead of filter to use compound index
            return nameRegex.test(prod.product.name);
        });
    }

    if (filterOptions.productType && filterOptions.productType !== undefined) {
        productsQuery = productsQuery.filter((pr: DexieProduct) => pr.product.productType === filterOptions.productType);
    }

    if (filterOptions.projectId && filterOptions.projectId !== undefined) {
        // if productType Expenses and if projects are empty we will add that product
        if (filterOptions.productType === 'Expenses') {
            productsQuery = productsQuery.filter((pr: DexieProduct) => {
                const hasproject = pr.product?.projects?.some((obj: any) => obj?._id === filterOptions.projectId);
                return !pr?.product?.projects || pr?.product?.projects.length === 0 || hasproject;
            });
        } else {
            productsQuery = productsQuery.filter((pr: DexieProduct) =>
                pr.product?.projects?.some((obj: any) => obj?._id === filterOptions.projectId),
            );
        }
    }

    if (filterOptions.forAutoComplete) {
        return dispatch({
            type: newProductActionTypes.LOAD_PRODUCTS_AUTO_COMPLETE,
            payload: await productsQuery.toArray(),
        });
    } else {
        return dispatch({
            type: newProductActionTypes.LOAD_PRODUCT_LIST,
            payload: await productsQuery.toArray(),
        });
    }
};

// this is only for transaction products fetching 
export const loadForTransactionsAction = (filterOptions: { productType?: string; brandsArray?: string[] }) => async (
    dispatch: any,
) => {
    type DexieEventQueryBuilder = Collection<DexieProduct, IndexableType> | Table<DexieProduct, IndexableType>;
    let productsQuery: DexieEventQueryBuilder = newProductDb.products;

    // we need to load all products whose product type is null or empty or the filtered type
    if (filterOptions.productType && filterOptions.productType !== undefined) {
        productsQuery = productsQuery.filter((pr: DexieProduct) => {
            return !pr?.product?.productType || pr?.product.productType === filterOptions.productType
        });
    }

    // brand relation filter
    if (Array.isArray(filterOptions.brandsArray) && filterOptions.brandsArray.length > 0) {
        productsQuery = productsQuery.filter(
            (pr: DexieProduct) => filterOptions.brandsArray?.indexOf(pr.product.brand?._id) !== -1,
        );
    }

    return dispatch({
        type: newProductActionTypes.LOAD_PRODUCTS_FOR_TRANSACTION,
        payload: await productsQuery.toArray(),
    });
};

export const clearAutoCompleteLoadProducts = () => {
    return {
        type: newProductActionTypes.CLEAR_AUTO_COMPLETE_LOAD,
    };
};

export const clearForTransaction = () => {
    return {
        type: newProductActionTypes.CLEAR_FOR_TRANSACTION,
    };
};

export const loadReportProductsListByIdsAction = (filterOptions: { ids: string[] }) => async (dispatch: any) => {
    if (filterOptions?.ids !== undefined) {
        const products = await newProductDb.products
            .where('_id')
            .anyOf(filterOptions?.ids)
            .toArray();
        return dispatch({
            type: newProductActionTypes.LOAD_REPORT_PRODUCTS_LIST,
            payload: products,
        });
    } else {
        return dispatch({
            type: newProductActionTypes.LOAD_REPORT_PRODUCTS_LIST,
            payload: [],
        });
    }
};

export const clearReportProductsListByIdsAction = (dispatch: any) => {
    return dispatch({
        type: newProductActionTypes.LOAD_REPORT_PRODUCTS_LIST,
        payload: null,
    });
};

export const startProductSyncAction = (projectIds: string) => (dispatch: any) => {
    dispatch({
        type: newProductActionTypes.UPDATE_PRODUCT_SYNC_DATA,
        payload: {
            state: 'DOWNLOADING',
            lastUpdate: new Date(),
        },
    });
    syncAllProducts(dispatch, projectIds);
};
