import {Done,Close} from '@mui/icons-material';
export interface BooleanViewProps {
  value: boolean;
}
export function BooleanView({value}: BooleanViewProps) {
  const val = Boolean(value);
  if(val) {
    return <Done />;
  } else {
    return <Close />;
  }
}