import React, { useEffect, useState, useRef, useContext } from 'react';
import { Badge, Box, Grid, Icon, SvgIcon, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import BottomFilterMessenger from './components/BottomFilter';
import Inbox from './pages/Inbox';
import API from '../../config/api';
import Channels from './pages/Channels';
import { Channel, SingleChannelUnreadCount } from './types';
import Tasks from './pages/Tasks';
import { returnUserCanSeeMessage } from './utils/helpers';
import { t } from 'i18next';
import { PWAContext } from '../../PWA/pwaContext';
import EmpplanSnackbarWrapper from '../../modules/Snackbar';
import io from 'socket.io-client';
import { trackEvent } from '../../components-app/GAListener';
import DeviceDetect from '../../hooks/deviceDetect';

interface ReactMessengerContextProps {
    messengerView?: 'Channels' | 'Inbox' | 'Tasks';
    internalId?: string;
    navigateTo?: any;
}

function ReactMessenger() {
    const [activeChannelGroup ,setActiveChannelGroup] = useState<string>('message');

    const reactMessengerContext = React.useContext(ReactMessengerContext);
    const messengerView: 'Channels' | 'Inbox' | 'Tasks' = reactMessengerContext.messengerView || 'Channels';

    const [channelList, setChannelList] = useState<Channel[]>([]);
    const [inboxChannel, setInboxChannel] = useState<string | undefined>(undefined);
    const [isChannelOpened, setIsChannelOpened] = useState<boolean>(false);
    const [unreadChannelsCountList, setUnreadChannelsCountList] = useState<SingleChannelUnreadCount[]>([]);

    const [unreadChannelsCount, setUnreadChannelsCount] = useState(0);
    const [unreadPesonalChannelsCount, setUnreadPesonalChannelsCount] = useState(0);
    const [unreadCRMChannelsCount, setUnreadCRMChannelsCount] = useState(0);
    const [unreadeventChannelsCount, setUnreadeventChannelsCount] = useState(0);
    const [unreadeventGroupChannelsCount, setUnreadeventGroupChannelsCount] = useState(0);




    const [unreadInboxCount, setUnreadInboxCount] = useState(0);
    const endRef = useRef<HTMLDivElement | null>(null);
    const pwaContext = useContext(PWAContext);
    const [isMobile, setIsMobile] = useState(DeviceDetect().isMobile);
    useEffect(() => {
        if (DeviceDetect().isMobile === false) {
            const handleResize = () => {
                if (window.innerWidth < 900) {
                    setIsMobile(true);
                } else {
                    setIsMobile(false);
                }
            };
            handleResize();
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    });

    useEffect(() => {
        trackEvent('Messenger', 'Messenger #!/yakichat/chats', 'visit');
        if (pwaContext?.pwa) {
            var socket = io({ transports: ['websocket'] }).connect();
            window.socketio = socket;
        }
        return () => {
            if (socket) {
                socket.disconnect();
            }
        };
        //     endRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const fetchChannelList = async () => {
        const response = await API.get(`/chatchannel?groupBy=${activeChannelGroup}`);
        const data: Channel[] = await response.json();
        const inbox = data.find(
            eachChannel => eachChannel.isInbox === true && eachChannel.user === window?.userabstraction?._id,
        );
        let inBoxFilteredChannels = data;
        if (inbox && inbox._id) {
            inBoxFilteredChannels = data.filter(channels => channels._id !== inbox._id);
        }
        setInboxChannel(inbox?._id ? inbox?._id : undefined);
        setChannelList(inBoxFilteredChannels);
    };

    const unreadChannelCountDetailed = async () => {
        try {
            const countsData = await API.get('/chatchannel/unread/channels/user/detailed');
            const result: SingleChannelUnreadCount[] = await countsData.json();
            if (result && Array.isArray(result)) {
                setUnreadChannelsCountList(result);
            }
        } catch (error) {
            console.log(error, 'Error while fetching unread count');
        }
    };

    const fetchUnreadCount = async () => {
        try {
            const res = await API.get('/chatchannel/unread/channels/user');
            const jsonRes = await res.json();
            if (Array.isArray(jsonRes) && jsonRes[0] && typeof jsonRes[0].unread === 'number') {
                const inboxTotalCount = unreadChannelsCountList.find(
                    eachChannelToRead => eachChannelToRead._id === inboxChannel,
                );

                if (inboxTotalCount && inboxTotalCount.countOfMessage) {
                    setUnreadInboxCount && setUnreadInboxCount(inboxTotalCount.countOfMessage);
                    setUnreadChannelsCount && setUnreadChannelsCount(jsonRes[0].unread - inboxTotalCount.countOfMessage);
                    return;
                }
                setUnreadInboxCount && setUnreadInboxCount(0);
                setUnreadChannelsCount && setUnreadChannelsCount(jsonRes[0].unread);
                return;
            }
            setUnreadChannelsCount && setUnreadChannelsCount(0);
            setUnreadInboxCount && setUnreadInboxCount(0);
        } catch (error) {
            console.error('failed to load unread notifications count', error);
            setUnreadChannelsCount && setUnreadChannelsCount(0);
            setUnreadInboxCount && setUnreadInboxCount(0);
        }
    };

    const fetchUnreadCountGroup = async () => {
        try {
            const res = await API.get('/chatchannel/unread/channels/user?groupBy=true');
            const jsonRes = await res.json();
            if (Array.isArray(jsonRes) && jsonRes[0] && typeof jsonRes[0].unread === 'number') {
                jsonRes.forEach(item=>{
                    if(item._id == "message" || item._id == "inbox" || item._id == "unknown"  ){
                        setUnreadPesonalChannelsCount( item.unread )
                    }
                    if(item._id == "crm" ){
                        setUnreadCRMChannelsCount( item.unread )
                    }
                    if(item._id == "event" ){
                        setUnreadeventChannelsCount( item.unread )
                    }
                    if(item._id == "'eventGroup'" ){
                        setUnreadeventGroupChannelsCount( item.unread )
                    }
                })
                return;
                
              
            }
            // setUnreadPesonalChannelsCount(0)
            // setUnreadCRMChannelsCount(0);
            // setUnreadeventChannelsCount(0)
            // setUnreadeventGroupChannelsCount(0)
           
           
        } catch (error) {
            console.error('failed to load unread notifications count', error);
            setUnreadPesonalChannelsCount(0)
            setUnreadCRMChannelsCount(0);
            setUnreadeventChannelsCount(0)
            setUnreadeventGroupChannelsCount(0)
        }
    };

    const handleSwitchToggle = (event: any) => {
            console.log('type',event)
            setActiveChannelGroup(event.target.value);

    };

    useEffect(() => {
        fetchUnreadCount();
        fetchUnreadCountGroup()
        
    }, [unreadChannelsCountList, inboxChannel ,activeChannelGroup]);

    useEffect(() => {
        if (messengerView !== 'Channels') return;
        unreadChannelCountDetailed();
    }, [messengerView, isChannelOpened]);

    useEffect(() => {
        fetchChannelList();
    }, [reactMessengerContext.messengerView,activeChannelGroup]);

    return (
        <EmpplanSnackbarWrapper>
            <Box
                ref={endRef}
                sx={{ background: `linear-gradient(#e8a2ff -200%, #ffffff 100%);` }}
                height={pwaContext?.pwa ? 'undefined' : '100vh'}
            >
                {messengerView === 'Channels' && channelList && (
                    <Box
                        sx={{
                            overflowY: isChannelOpened ? undefined : 'auto',
                            height: isChannelOpened ? undefined : 'calc(100vh - 150px)',
                        }}
                    >
                          <Grid container spacing={0}>
                    <Grid item xs={12} md={3}>
                    <ToggleButtonGroup
                        sx={{background:'white'}}
                        fullWidth
                        color='secondary'
                        value={activeChannelGroup}
                        exclusive
                        onChange={handleSwitchToggle}
                        aria-label='Platform'
                    >
                        <ToggleButton sx={{height: 50, fontSize:'0.695rem',letterSpacing:'0.01857em' }} value={'message'}>
                             {t('##Personal')}
                             <Badge
                                max={1000}
                                sx={{marginTop:'-6px',marginLeft:'20px'}}
                                badgeContent={
                                   
                                    unreadPesonalChannelsCount
                                }
                                color='secondary'
                            >
                              
                            </Badge>
                        </ToggleButton>
                        <ToggleButton sx={{ height: 50, fontSize:'0.695rem',letterSpacing:'0.01857em' }} value={'crm'}>
                      
                           
                      {t('##CRM')}<Badge
                                max={1000}
                                sx={{marginTop:'-6px',marginLeft:'12px'}}
                                badgeContent={
                                   
                                    unreadCRMChannelsCount
                                }
                                color='secondary'
                            >
                               
                            </Badge>
                        </ToggleButton>
                        <ToggleButton sx={{ height: 50, fontSize:'0.695rem',letterSpacing:'0.01857em' }} value={'event'}>
                            {t('##Event')}<Badge
                                max={1000}
                                sx={{marginTop:'-6px',marginLeft:'20px'}}
                                badgeContent={
                                   
                                    unreadeventChannelsCount
                                }
                                color='secondary'
                            >
                               
                            </Badge>
                        </ToggleButton>
                        <ToggleButton sx={{height: 50, fontSize:'0.695rem',letterSpacing:'0.01857em' }} value={"'eventGroup'"}>
                            {t('##EventGroup')}<Badge
                                max={1000}
                                sx={{marginTop:'-6px',marginLeft:'20px'}}
                                badgeContent={
                                   
                                    unreadeventGroupChannelsCount
                                }
                                color='secondary'
                            >
                               
                            </Badge>
                        </ToggleButton>
                        
                        
                   
                    </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        </Grid>
                    </Grid>
                        <Channels
                            allChannels={channelList}
                            setChannelOpen={setIsChannelOpened}
                            fetchChannel={fetchChannelList}
                            unreadDetails={unreadChannelsCountList}
                        />
                    </Box>
                )}

                {messengerView === 'Inbox' && inboxChannel !== undefined && (
                    <Box sx={{ overflowY: 'auto', height: !pwaContext?.pwa ? 'calc(100vh - 90px)' : undefined }}>
                        {returnUserCanSeeMessage(window?.user?.roles) ? (
                            <Inbox inboxChannelId={inboxChannel} isReadOnly={true} />
                        ) : (
                            <Typography>{t('##NotEnoughRights')}</Typography>
                        )}
                    </Box>
                )}

                {messengerView === 'Tasks' && <Tasks />}

                {isChannelOpened === false ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <BottomFilterMessenger
                                value={messengerView}
                                onChange={(event, value) => reactMessengerContext.navigateTo?.(value)}
                                channelsCount={unreadChannelsCount}
                                inboxCount={unreadInboxCount}
                                isSticky={isMobile ? false : messengerView === 'Channels' ? true : false}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    !isMobile && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <BottomFilterMessenger
                                    value={messengerView}
                                    onChange={(event, value) => reactMessengerContext.navigateTo?.(value)}
                                    channelsCount={unreadChannelsCount}
                                    inboxCount={unreadInboxCount}
                                    isSticky={isMobile ? false : messengerView === 'Channels' ? true : false}
                                />
                            </Grid>
                        </Grid>
                    )
                )}
            </Box>
        </EmpplanSnackbarWrapper>
    );
}

export const ReactMessengerContext = React.createContext<ReactMessengerContextProps>({});

function ReactMessengerWrapper(props: ReactMessengerContextProps) {
    return (
        <>
            <ReactMessengerContext.Provider value={props}>
                <ReactMessenger />
            </ReactMessengerContext.Provider>
        </>
    );
}

export default ReactMessengerWrapper;
