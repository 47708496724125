import { newCRMActionTypes } from './actions';

export const InitialNewCRMActionState: NewCRMActionStates = {
    allNewCRMActions: [],
    syncData: {
        state: 'READY',
        lastUpdate: null,
    },
};

export const newCRMActionReducer = (state = InitialNewCRMActionState, { type, payload }: any) => {
    switch (type) {
        case newCRMActionTypes.GET_ALL_CRM_ACTIONS:
            return {
                ...state,
                allNewCRMActions: payload,
            };
        case newCRMActionTypes.UPDATE_CRM_ACTIONS_SYNC_DATA:
            return {
                ...state,
                syncData: payload,
            };
        case newCRMActionTypes.NEW_CRM_ACTION:
            return state;
        default:
            return state;
    }
};
