import { Autocomplete, Box, Chip, CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import * as turf from '@turf/turf';
import { LocationByDistri } from "./types";
import { formatDate } from "../../../utils/date";
import { getFullAddress } from "../../../components-app/location-view";
import { MyLocationSharp } from "@mui/icons-material";
import { getLocationCoordinates } from "../../../hooks/useFileUpload/fileHelper";
import AreaEventGoogleSearch from "./AreaEventGoogleSearch";
import API from "../../../config/api";

export const calculateDistance = (p1: [number, number], p2: [number, number], units?: turf.Units) => {
    const point1 = turf.point([p1[0], p1[1]]);
    const point2 = turf.point([p2[0], p2[1]]);
    const distance = turf.distance(point1, point2, { units: units || 'kilometers' });
    return distance;
};

export interface CustomLocationByDistri extends LocationByDistri {
    distanceFromUser?: number;
}

export default function PointOfSaleAutocompleteScroll({
    projectId,
    areaId,
    value,
    setValue,
    openLocationDialog,
    allowPromoterToAddNewLocation,
    limit = 10,
}: {
    projectId: string;
    areaId: string;
    value: any[];
    setValue: React.Dispatch<React.SetStateAction<any[]>>;
    openLocationDialog?: (googlePlaceValue: {
        places: google.maps.places.AutocompletePrediction[] | null;
        value: string;
    }) => void;
    allowPromoterToAddNewLocation: boolean;
    limit?: number;
}) {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [posLocations, setPosLocations] = React.useState<CustomLocationByDistri[]>([]);
    const [userLocation, setUserLocation] = React.useState({
        longitude: 0,
        latitude: 0,
    });
    const [textFieldValue, setTextFieldValue] = React.useState<string>('');
    const [openSearch, setOpenSearch] = React.useState<boolean>(false);

    const [skip, setSkip] = React.useState(0);
    const [hasMore, setHasMore] = React.useState(true);

    const getUserLocation = async () => {
        const currentLocation = await getLocationCoordinates().catch(err => {
            console.log('error gps', err);
            return null;
        });

        setUserLocation({
            longitude: currentLocation?.longitude || 0,
            latitude: currentLocation?.latitude || 0,
        })
    };

    const fetchLocations = async (isNewSearch = false,  searchValue = '') => {
        if (loading) return;
        setLoading(true);

        const currentSkip = isNewSearch ? 0 : skip;
        const baseQuery = `projects/locationsfromdistrisearchfieman/${projectId}/${areaId}`;
        const activeParam = `?active=true&skip=${currentSkip}&limit=${limit}`;
        const searchParam = searchValue ? `&name=${searchValue}&searchField=all` : '';
        const query = `${baseQuery}${activeParam}${searchParam}`;
        
        try {
            const resp = await API.get(query);
            const jsonResp = await resp.json();

            if (jsonResp?.projectLocations && Array.isArray(jsonResp.projectLocations)) {
                let updatedLocations = jsonResp.projectLocations;

                if (userLocation.latitude && userLocation.longitude) {
                    updatedLocations = jsonResp.projectLocations.map((loc:any) => {
                        if (Array.isArray(loc.location)) {
                            loc.distanceFromUser = calculateDistance(loc.location, [userLocation.longitude, userLocation.latitude]);
                        }
                        return loc;
                    });

                    updatedLocations.sort((a:any, b:any) => {
                        const distanceA = a.distanceFromUser ?? Number.POSITIVE_INFINITY;
                        const distanceB = b.distanceFromUser ?? Number.POSITIVE_INFINITY;
                        return distanceA - distanceB;
                    });
                }

                setPosLocations(prevLocations =>
                    isNewSearch ? updatedLocations : [...prevLocations, ...updatedLocations]
                );

                if (updatedLocations.length < limit) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                    setSkip(prevSkip => prevSkip + limit);
                }
            }else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error loading locations:', error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (open && !openSearch) {
            setSkip(0);
            fetchLocations(true);
        }
    }, [open, projectId, areaId, userLocation]);

    React.useEffect(() => {
        if  (textFieldValue === '') {
            setOpenSearch(false);
            setPosLocations([]);
            setSkip(0);
            fetchLocations(true);
            return;
        }
        const timer = setTimeout(() => {
            if (textFieldValue.trim() !== '') {
                setOpenSearch(true);
                setSkip(0);
                fetchLocations(true, textFieldValue);
            }
        }, 500);

        return () => clearTimeout(timer);
    }, [textFieldValue]);

    const handleScroll = (event: React.SyntheticEvent) => {
        if (!hasMore || loading) return;

        const listboxNode = event.currentTarget as HTMLElement;
        const scrollTopBeforeFetch = listboxNode.scrollTop;

        if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight) {
            const timer = setTimeout(() => {
                fetchLocations(false, textFieldValue).then(() => {
                    requestAnimationFrame(() => {
                        listboxNode.scrollTop = scrollTopBeforeFetch; // Restore scroll position
                    });
                });
            }, 500);
    
            return () => clearTimeout(timer);
        }
    };


    return (
        <Autocomplete
            multiple
            filterSelectedOptions
            id='posLocationsAutocomplete'
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setTextFieldValue('');
                setPosLocations([]);
                setOpen(false);
            }}
            value={value}
            onChange={(event, newValue) => {
                if (limit && newValue.length > limit) return;
                setValue(newValue);
            }}
            sx={{ width: '100%' }}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            getOptionLabel={option => option.name}
            renderOption={(props, option) => (
                <Box component='li' style={{ display: 'block' }} {...props}>
                    {option.distanceFromUser ? option.distanceFromUser.toFixed(1) + ' km' : ''} {getFullAddress(option)}
                    {option.lastvisitdate ? (
                        <span style={{ float: 'right', color: '#068EC2' }}>
                            {t('##LastLocationVisit')}: {formatDate(option.lastvisitdate)}
                        </span>
                    ) : (
                        ''
                    )}
                </Box>
            )}
            renderTags={(value: any[], getTagProps) =>
                value.map((option: any, index: number) => <Chip label={option.name} {...getTagProps({ index })} />)
            }
            options={posLocations}
            filterOptions={options => options}
            loading={loading}
            ListboxProps={{
                onScroll: handleScroll,
                style: { maxHeight: '300px', overflowY: 'auto' }
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label={t`##AddReportingEvents`}
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        setTextFieldValue(event.target.value)
                    }
                    variant='standard'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                                {!(userLocation.latitude || userLocation.longitude) && (
                                    <IconButton
                                        onClick={() => {
                                            getUserLocation();
                                        }}
                                    >
                                        <MyLocationSharp color='secondary' />
                                    </IconButton>
                                )}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            noOptionsText={
                allowPromoterToAddNewLocation ? (
                    <AreaEventGoogleSearch value={textFieldValue} openLocationDialog={openLocationDialog} />
                ) : (
                    <Typography>{t('##NoLocationsFound')}</Typography>
                )
            }
        />
    );
}