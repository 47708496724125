import { Box, Button, Checkbox, FormControlLabel, FormLabel, Grid, IconButton, Modal, Paper, Portal, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import EmpplanTextField from '../EmpplanFormComponents/EmpplanTextField';
import { Controller, useForm } from 'react-hook-form';
import { Edit } from '@mui/icons-material';
import { t } from 'i18next';
import CustomModelFieldsForm from './CustomModelFieldsForm';
import useModal from '../../components-ui/useModal';
import CloseIcon from '@mui/icons-material/Close';

function CustomFieldsComponent({
    currentModelCustomFieldConfig,
    modelName,
    customModelDataForm,
    editMode,
    showFieldTypes,
    position,
    customModelData,
    agencySettings,
    showProfileOnly,
    disableMe,
    setGenericFields,
    tabName,
    showFieldViewAccess,
}:{
    currentModelCustomFieldConfig?: any,
    modelName?: string,
    customModelDataForm?: any,
    editMode?: boolean,
    showFieldTypes?: boolean,
    position?: any,
    customModelData?: any,
    agencySettings?: any,
    showProfileOnly?: boolean,
    disableMe?:boolean,
    setGenericFields?:any,
    tabName?: string,
    showFieldViewAccess?: boolean,
}) {
    const [CustomModal, openModal, closeModal] = useModal();
    const [open, setOpen] = useState(false);
    const [selectedKeyConfig, setSelectedKeyConfig] = useState<any>(null);
    const {
        handleSubmit,
        control,
        watch,
        formState: { errors, isValid },
        reset,
        setValue,
    } = useForm<any>({
        defaultValues: {},
        mode: 'all',
        reValidateMode: 'onChange',
    });
    const checkForDefaultType = ['textarea','Radio','Checkbox', 'sequence'];

    const handleCheckboxChange = (field:any, value:any, checked:any) => {
        setGenericFields((prevState:any) => {
            const currentValues = prevState[field] || [];
            const newValues = checked
                ? [...currentValues, value]
                : currentValues.filter((item:any) => item !== value);
            return {
                ...prevState,
                [field]: newValues,
            };
        });
    };
    
    const handleInputChange = (field:any, value:any) => {
        setGenericFields((prevState:any) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleOpen = (keyConfig: any) => {
        setSelectedKeyConfig(keyConfig);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedKeyConfig(null);
    };

    return (
        <>
            {/* <CustomModal></CustomModal> */}
            <Portal>
                <Modal open={open}>
                    <>
                        <Box 
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%', // Full width
                                maxWidth: '100vw', // Maximum width to fit viewport
                                minWidth: '100vw', // Ensures full width
                            }}
                        >
                            <Box display={"flex"} justifyContent={"space-between"} bgcolor={"rgb(66,66,66)"} color={"whitesmoke"} p={2}>
                                <Typography variant='h5'>
                                    {t('##Edit')}
                                </Typography>
                                <IconButton onClick={() => handleClose()} sx={{color:"whitesmoke"}}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Paper sx={{ p: 3 }}>
                                <Box padding={2} sx={{ overflowY: 'scroll' }}>
                                    <CustomModelFieldsForm 
                                        modelName={modelName}
                                        showFieldTypes={showFieldTypes}
                                        position={position}
                                        data={selectedKeyConfig}
                                        onFormSubmit={() => { handleClose(); }}
                                    />
                                </Box>
                            </Paper>
                        </Box>
                    </>
                </Modal>
            </Portal>
            {modelName == 'CRM' && currentModelCustomFieldConfig.length > 0 && <Typography variant='h6' gutterBottom>
                    {t('##MoreData')}
                </Typography>
            }
            {(currentModelCustomFieldConfig && modelName!== 'Userabstraction') &&
                <Grid container spacing={1}>
                    {currentModelCustomFieldConfig.map((keyConfig:any) => {
                        return (<Grid item xs={12} sx={{ mx: 1 }} key={keyConfig._id}>
                            {checkForDefaultType.indexOf(keyConfig.fieldType)===-1 && 
                                <React.Fragment key={keyConfig._id}>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        label={keyConfig.fieldLabelName}
                                        value={customModelDataForm[keyConfig.fieldAttributeName] || ''}
                                        onChange={e => handleInputChange(keyConfig.fieldAttributeName, e.target.value)}
                                        disabled={!disableMe}
                                    />
                                </React.Fragment>
                            }
                            {keyConfig.fieldType === 'textarea' && 
                                <React.Fragment key={keyConfig._id}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        maxRows={4}
                                        variant='standard'
                                        label={keyConfig.fieldLabelName}
                                        disabled={!disableMe}
                                        value={customModelDataForm[keyConfig.fieldAttributeName] || ''}
                                        onChange={e => handleInputChange(keyConfig.fieldAttributeName, e.target.value)}
                                    />
                                </React.Fragment> 
                            }
                            {keyConfig.fieldType === 'sequence' && 
                                <React.Fragment key={keyConfig._id}>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        label={keyConfig.fieldLabelName}
                                        value={customModelDataForm[keyConfig.fieldAttributeName] || ''}
                                        onChange={e => handleInputChange(keyConfig.fieldAttributeName, e.target.value)}
                                        disabled={!disableMe}
                                    />
                                </React.Fragment>
                            }
                            {keyConfig.fieldType === 'Checkbox' &&
                                <React.Fragment key={keyConfig._id}>
                                    <FormLabel component="legend">{keyConfig.fieldLabelName}</FormLabel>
                                    {keyConfig.fieldObject?.options?.map((checkboxItem: string) => (
                                        <FormControlLabel
                                            key={checkboxItem}
                                            label={checkboxItem}
                                            control={
                                                <Checkbox
                                                    name={checkboxItem}
                                                    data-testid={checkboxItem}
                                                    checked={customModelDataForm && customModelDataForm[keyConfig.fieldAttributeName]?.includes(checkboxItem) || false}
                                                    disabled={!disableMe}
                                                    onChange={(e) => handleCheckboxChange(keyConfig.fieldAttributeName, checkboxItem, e.target.checked)}
                                                />
                                            }
                                        />
                                    ))}
                                </React.Fragment>
                            }
                            {keyConfig.fieldType === 'Radio' &&
                                <React.Fragment key={keyConfig._id}>
                                    <FormLabel component="legend">{keyConfig.fieldLabelName}</FormLabel>
                                    <RadioGroup
                                        row
                                        value={customModelDataForm && customModelDataForm[keyConfig.fieldAttributeName] || ''}
                                        onChange={(e) => handleInputChange(keyConfig.fieldAttributeName, e.target.value)}
                                    >
                                        {keyConfig.fieldObject?.options?.map((radioItem:string) => (
                                            <FormControlLabel
                                                key={radioItem}
                                                value={radioItem}
                                                control={<Radio />}
                                                label={radioItem}
                                                disabled={!disableMe}
                                            />
                                        ))}
                                    </RadioGroup>
                                </React.Fragment>
                            }
                            {editMode && 
                                <Button
                                    type='button'
                                    variant='text'
                                    color='primary'
                                    onClick={() => handleOpen(keyConfig)}
                                >
                                    <Edit />
                                    {t('##Edit')}
                                </Button>
                            }
                        </Grid>)
                    }
                    )}
                </Grid>
            }
            {(currentModelCustomFieldConfig && modelName == 'Userabstraction') &&
                <Grid container spacing={1}>
                    {currentModelCustomFieldConfig.map((keyConfig:any) => {
                        if(modelName !== 'Userabstraction' || 
                            (modelName === 'Userabstraction' 
                                && position.location === keyConfig.position.location 
                                && position.column === keyConfig.position.column 
                                && (showProfileOnly===false 
                                    || (showProfileOnly===true 
                                        && agencySettings?.AccessToProfileFields?.fields?.genericFields[keyConfig.fieldAttributeName].showInProfile===true)) 
                                        && agencySettings?.AccessToProfileFields?.fields?.genericFields[keyConfig.fieldAttributeName].trash !== true
                                        ))
                            {
                                return (<Grid item xs={12} sx={{ mx: 1 }} key={keyConfig._id}>
                                    {checkForDefaultType.indexOf(keyConfig.fieldType)===-1 && 
                                        <React.Fragment key={keyConfig._id}>
                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                label={keyConfig.fieldLabelName}
                                                value={customModelDataForm[keyConfig.fieldAttributeName] || ''}
                                                onChange={e => handleInputChange(keyConfig.fieldAttributeName, e.target.value)}
                                                disabled={!disableMe}
                                            />
                                        </React.Fragment>
                                    }
                                    {keyConfig.fieldType === 'textarea' && 
                                        <React.Fragment key={keyConfig._id}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                maxRows={4}
                                                variant='standard'
                                                label={keyConfig.fieldLabelName}
                                                disabled={!disableMe}
                                                value={customModelDataForm[keyConfig.fieldAttributeName] || ''}
                                                onChange={e => handleInputChange(keyConfig.fieldAttributeName, e.target.value)}
                                            />
                                        </React.Fragment> 
                                    }
                                    {keyConfig.fieldType === 'sequence' && 
                                        <React.Fragment key={keyConfig._id}>
                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                label={keyConfig.fieldLabelName}
                                                value={customModelDataForm[keyConfig.fieldAttributeName] || ''}
                                                onChange={e => handleInputChange(keyConfig.fieldAttributeName, e.target.value)}
                                                disabled={!disableMe}
                                            />
                                        </React.Fragment>
                                    }
                                    {keyConfig.fieldType === 'Checkbox' &&
                                        <React.Fragment key={keyConfig._id}>
                                            <FormLabel component="legend">{keyConfig.fieldLabelName}</FormLabel>
                                            {keyConfig.fieldObject?.options?.map((checkboxItem: string) => (
                                                <FormControlLabel
                                                    key={checkboxItem}
                                                    label={checkboxItem}
                                                    control={
                                                        <Checkbox
                                                            name={checkboxItem}
                                                            data-testid={checkboxItem}
                                                            checked={customModelDataForm && customModelDataForm[keyConfig.fieldAttributeName]?.includes(checkboxItem) || false}
                                                            disabled={!disableMe}
                                                            onChange={(e) => handleCheckboxChange(keyConfig.fieldAttributeName, checkboxItem, e.target.checked)}
                                                        />
                                                    }
                                                />
                                            ))}
                                        </React.Fragment>
                                    }
                                    {keyConfig.fieldType === 'Radio' &&
                                        <React.Fragment key={keyConfig._id}>
                                            <FormLabel component="legend">{keyConfig.fieldLabelName}</FormLabel>
                                            <RadioGroup
                                                row
                                                value={customModelDataForm && customModelDataForm[keyConfig.fieldAttributeName] || ''}
                                                onChange={(e) => handleInputChange(keyConfig.fieldAttributeName, e.target.value)}
                                            >
                                                {keyConfig.fieldObject?.options?.map((radioItem:string) => (
                                                    <FormControlLabel
                                                        key={radioItem}
                                                        value={radioItem}
                                                        control={<Radio />}
                                                        label={radioItem}
                                                        disabled={!disableMe}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </React.Fragment>
                                    }
                                </Grid>)
                            }
                        })}
                </Grid>
            }
        </>
    );
}

export default CustomFieldsComponent;