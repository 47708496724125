import {Reducer} from "redux";
import {GalleryActionsItems, GalleryActionTypes, GalleryState} from "./GalleryStateTypes";

export const initialGalleryState:GalleryState= {
    galleryLoading:false,
    userGallery:{data:[]}
};

export const GalleryReducer: Reducer<GalleryState, GalleryActionsItems> = (
    state = initialGalleryState,
    action
) => {
    switch (action.type) {
        //TODO CHANGE THE STATE
        case GalleryActionTypes.LOADING: {
            return {
                ...state,
                galleryLoading: true
            };
        };
        case GalleryActionTypes.LOADBYUSERID: {
            return {
                ...state,
                userGallery:action.gallery,
                galleryLoading:false
            };
        };
    }
    return state;
}
