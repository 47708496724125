import React, { useContext, useEffect, useRef, useState } from 'react';
import { Autocomplete, Chip, TextField, useMediaQuery } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import DateRangePickerWrapper from './DateRangePickerWrapper';
import AutocompleteWrapper from './AutocompleteWrapper';
import moment, { Moment } from 'moment';
import { generateRandomKey } from '../../CustomReportDependentFields/keygen';
import { PWAContext } from '../../../PWA/pwaContext';

const EditableChip = ({
    chipIndex,
    onEdit,
    updateEditChip,
    inputValue,
    setInputValue,
    setFilterEditMode,
    commonChipProps,
    sx,
    style,
    clickable,
    onDelete,
    onClick,
    isDateRangeVisible,
    openCalender,
    calenderCloseUpdate,
    deleteFromSteptwo,
    setOpenCalender,
    filteredList,
    isAutocompleteVisible,
    innerInputValue,
    innerValue,
    setInnerInputValue,
    setInnerValue,
    setMultiFieldValue,
    innerAutosuggestRef,
    filterBarRef,
    filterBarInputRef,
    filterStep,
    updateCursorPosition,
    filterType,
    loading,
    filterEditMode,
    handleOpenToast,
    editChipIndex,
    disableRemoveFilter,
    currentValue,
    setDateFromMobile,
}: any) => {
    const pwaContext = useContext(PWAContext);
    const [editMode, setEditMode] = useState(false);

    const matches1 = useMediaQuery('(min-width:992px)');
    const chipRef = useRef<any>(null);
    const getChipLabel = (el: any) => (el && el.label ? el.label : el?.name ? el?.name : '') || '';
    const [open, setOpen] = useState(true);

    const handleClick = () => {
        if (!editMode) {
            setEditMode(true);

            onEdit(chipIndex);
            setOpen(true);

            // if (!firstClick && filterType === 'daterange') {
            //     setOpenCalender(true);
            //     seFirstClick(true);
            // } else {
            //     setOpenCalender(false);
            // }
        }
    };

    const handleBlur = () => {
        if (editMode && filterType !== 'daterange') {
            setEditMode(false);
            setFilterEditMode(false);
            setOpenCalender(false);
            //seFirstClick(false);
        }
        setOpenCalender(false);
    };

    const deleteFromSteptwoEdit = () => {
        if (editMode) {
            setEditMode(false);
            setFilterEditMode(false);
            deleteFromSteptwo();
        }
    };

    const { t } = useTranslation();
    return (
        <div
            ref={chipRef}
            onClick={
                chipIndex % 3 !== 1 && chipIndex % 3 !== 0 && !filterEditMode
                    ? handleClick
                    : chipIndex % 3 !== 1 && chipIndex % 3 !== 0 && editChipIndex !== chipIndex - 3
                    ? handleOpenToast
                    : undefined
            }
            onBlur={handleBlur}
            tabIndex={0}
            style={{ outline: 'none' }}
        >
            {editMode ? (
                <>
                    <Autocomplete
                        multiple
                        autoHighlight={true}
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        onBlur={handleBlur}
                        getOptionDisabled={option => option?.disabled}
                        filterSelectedOptions
                        freeSolo={true} // This prevents the dropdown button
                        noOptionsText={
                            innerInputValue.length < 3
                                ? t('##StartTypingToSearch')
                                : loading
                                ? `${t('##Loading')}...`
                                : t('##NothingFound')
                        }
                        loading={loading}
                        renderInput={params => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <TextField
                                    {...params}
                                    onBlur={handleBlur}
                                    ref={filterBarRef}
                                    autoFocus
                                    variant='standard'
                                    inputRef={filterBarInputRef}
                                    onKeyDown={(event: any) => {
                                        if (event.key === 'Backspace' || event.key === 'Delete') {
                                            event.stopPropagation();
                                        }
                                    }}
                                    inputProps={{
                                        ...params?.inputProps,

                                        style: {
                                            position:
                                                isDateRangeVisible || isAutocompleteVisible ? 'absolute' : 'relative',
                                            minWidth: isDateRangeVisible || isAutocompleteVisible ? 'auto' : '150px',
                                            flexGrow: '0',

                                            visibility: isDateRangeVisible || isAutocompleteVisible ? 'hidden' : 'visible',

                                            borderTopRightRadius:
                                                !isDateRangeVisible && !isAutocompleteVisible ? '50px' : 'none',
                                            borderBottomRightRadius:
                                                !isDateRangeVisible && !isAutocompleteVisible ? '50px' : 'none',
                                            background:
                                                !isDateRangeVisible && !isAutocompleteVisible ? '#949E9E' : 'transparent',
                                            color: !isDateRangeVisible && !isAutocompleteVisible ? 'white' : 'black',
                                            marginTop: !isDateRangeVisible && !isAutocompleteVisible ? '1.5px' : 'none',
                                            marginLeft: !isDateRangeVisible && !isAutocompleteVisible ? '2px' : 'none',
                                            paddingLeft: !isDateRangeVisible && !isAutocompleteVisible ? '7px' : 'none',
                                        },
                                    }}
                                    InputProps={{
                                        ...params?.InputProps,
                                        onClick: e => updateCursorPosition(e),
                                        onFocus: e => updateCursorPosition(e),
                                        onChange: e => updateCursorPosition(e),
                                        endAdornment: (
                                            <React.Fragment>
                                                {!isDateRangeVisible && !isAutocompleteVisible && !disableRemoveFilter && (
                                                    <CancelIcon
                                                        style={{
                                                            cursor: 'pointer',
                                                            marginLeft: '-27px',
                                                            zIndex: '999999999',
                                                            color: 'white',
                                                            marginTop: '0px',
                                                        }}
                                                        onClick={deleteFromSteptwoEdit}
                                                    />
                                                )}
                                                <DateRangePickerWrapper
                                                    params={params}
                                                    setEditMode={setEditMode}
                                                    editMode={editMode}
                                                    value={[
                                                        currentValue?.date_start
                                                            ? moment(currentValue?.date_start)
                                                            : moment().add(-30, 'days'),
                                                        currentValue?.date_end ? moment(currentValue?.date_end) : moment(),
                                                    ]}
                                                    open={openCalender}
                                                    isDateRangeVisible={isDateRangeVisible}
                                                    onClose={() => {
                                                        //calenderClose();
                                                        setOpenCalender(false);
                                                    }}
                                                    onChange={date => {
                                                        calenderCloseUpdate(date);
                                                        setEditMode(false);
                                                        setFilterEditMode(false);
                                                    }}
                                                    deleteFromSteptwo={deleteFromSteptwoEdit}
                                                    onOpen={() => {
                                                        setOpenCalender(true);
                                                    }}
                                                    disableRemoveFilter={disableRemoveFilter}
                                                />

                                                <AutocompleteWrapper
                                                    filteredList={filteredList}
                                                    isAutocompleteVisible={isAutocompleteVisible}
                                                    innerInputValue={innerInputValue}
                                                    innerValue={innerValue}
                                                    setInnerInputValue={setInnerInputValue}
                                                    setInnerValue={setInnerValue}
                                                    setMultiFieldValue={setMultiFieldValue}
                                                    deleteFromSteptwo={deleteFromSteptwoEdit}
                                                    innerAutosuggestRef={innerAutosuggestRef}
                                                    disableRemoveFilter={disableRemoveFilter}
                                                />
                                                {/* 
                                                {!editMode && (
                                                    <div
                                                        style={{
                                                            display: filterType === 'autosugget' ? 'hidden' : 'visible',
                                                        }}
                                                    >
                                                        {loading ? <CircularProgress color='inherit' size={20} /> : null}
                                                        {params?.InputProps?.endAdornment}
                                                    </div>
                                                )} */}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            </LocalizationProvider>
                        )}
                        inputValue={innerInputValue}
                        onInputChange={(event, newInputValue) => {
                            setInnerInputValue(newInputValue);
                        }}
                        onChange={(event: any, newValue: any | null, reason) => {
                            if (filterType === 'text') {
                                newValue[0] = {
                                    _id: generateRandomKey(),
                                    label: newValue[0],
                                    value: newValue[0],
                                };
                            }
                            updateEditChip(newValue[0]);
                            setEditMode(false);
                            setFilterEditMode(false);
                        }}
                        options={filterType === 'autosuggest' || filterType === 'select' ? filteredList : []}
                        getOptionLabel={getChipLabel}
                        sx={{
                            '& .MuiInput-root::before': {
                                borderBottom: 'none !important',
                            },
                            '& .MuiInput-root::after': {
                                borderBottom: 'none !important',
                            },
                            minWidth: 0.25,
                        }}
                        componentsProps={{
                            paper: {
                                sx: {
                                    display: isAutocompleteVisible ? 'none' : 'auto',
                                    border: '1px solid lightgray',
                                    width: pwaContext?.pwa ? 190 : 384,
                                },
                            },
                        }}
                    />
                </>
            ) : (
                <Chip
                    {...commonChipProps}
                    sx={sx}
                    style={style}
                    clickable={clickable}
                    onDelete={onDelete}
                    onClick={onClick}
                />
            )}
        </div>
    );
};

export default EditableChip;
