import { AnyAction } from 'redux';
import newSalesItemActionTypes from './actions';

// INITIAL STATE
const initialState: SalesItemStates = {
    loadedSalesItems: [],
    loadedSalesItem: undefined,
    syncData: {
        state: 'READY', // ready, pending, syncing, ready
        lastUpdate: null,
    },
};

// REDUCER

const salesItemReducer = (state = { ...initialState }, action: AnyAction) => {
    if (action.type === newSalesItemActionTypes.LOAD_SALESITEM_LIST) {
        return { ...state, loadedSalesItems: action.payload };
    }
    if (action.type === newSalesItemActionTypes.LOAD_SALESITEM) {
        return { ...state, loadedSalesItem: action.payload };
    }
    if (action.type === newSalesItemActionTypes.UPDATE_SALESITEM_SYNC_DATA) {
        return { ...state, syncData: action.payload };
    }

    return state;
};

export default salesItemReducer;
