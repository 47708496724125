import React, { useContext } from 'react';
import { Box, Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import { CheckBoxComponentProps } from './CheckBoxComponent';
import { useTranslation } from 'react-i18next';
import { ReportConfigurationContext } from '../index';
import ToolTipComponent from '../ToolTipComponent';
import { requireHelper } from '../utils/requireHelper';

export const RadioComponent = ({ field, reportData, onChangeHandler }: CheckBoxComponentProps) => {
    const { t } = useTranslation();
    const { isDisabled } = useContext(ReportConfigurationContext);
    const requiredCondition = requireHelper(field, reportData);

    return (
        <FormControl data-testid='radio' key={field.config._id}>
            <FormLabel id='radio-buttons-group-label'>
                {field.config.slug ? t(field.config.slug) : field.config.name}
                {requiredCondition && (
                    <Box component='span' sx={{ color: 'red' }}>
                        {'  '}({t('##TheFieldIsRequired')})
                    </Box>
                )}
                {field.config?.toolTipTxt !== '' && (
                    <ToolTipComponent
                        enableHintOnClick={field.config.enableHintOnClick}
                        tooltipText={field.config.toolTipTxt}
                    />
                )}
            </FormLabel>
            <RadioGroup aria-labelledby='radio-buttons-group-label' name='radio-buttons-group'>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: field.showInSameLine ? 'row' : 'column',
                    }}
                >
                    {field.config?.fieldObject?.options?.map((radioItem: string, idx: any) => {
                        return (
                            <FormControlLabel
                                key={radioItem}
                                label={t(radioItem)}
                                value={radioItem}
                                disabled={isDisabled || field.isReadonly}
                                checked={radioItem === reportData[field.config._id]}
                                control={
                                    <Radio
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            e.stopPropagation();
                                            let isFilter = field?.config?.isFilterable || false;
                                            let filterData = {
                                                name: field.config.name,
                                                id: field.config._id,
                                                value: e.target.value,
                                                options: field.config?.fieldObject?.options,
                                            };
                                            onChangeHandler(field.config._id, e.target.value, isFilter, filterData);
                                        }}
                                    />
                                }
                            />
                        );
                    })}
                </div>
                {field.hintText && reportData[field.config._id] ? (
                    <div
                        style={{
                            color: field.hintTextColor ? field.hintTextColor : undefined,
                        }}
                    >
                        {field.hintText}
                    </div>
                ) : null}
            </RadioGroup>
            {reportData[field.config._id] && (
                <Button
                    variant='text'
                    disabled={isDisabled || field.isReadonly}
                    sx={{
                        color: '#24c0b1',
                        fontWeight: 'bold',
                        justifyContent: 'start',
                    }}
                    onClick={() => onChangeHandler(field.config._id, '')}
                >
                    {t('##ClearInput')}
                </Button>
            )}
        </FormControl>
    );
};
