// Move element from one position to another
export const insertAndShift = (arr: String[], from: number, to: number) => {
  let cutOut = arr.splice(from, 1)[0]; // cut the element at index 'from'
  arr.splice(to, 0, cutOut); // insert it at index 'to'

  return arr;
};

export const getCurrentLanguage = () => {
  var currentLang = 'deDE';
  if (window.userabstraction) {
    if (window.userabstraction.locale) {
      switch (window.userabstraction.locale) {
        case 'en':
          currentLang = 'enUS';
          break;
        case 'de':
          currentLang = 'deDE';
          break;
      }
    }
  }
};
