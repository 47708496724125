import * as React from 'react';
import { useContext } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { SimpleTreeView } from '@mui/x-tree-view';
import { TreeItemProps, TreeItemSlotProps, TreeItem } from '@mui/x-tree-view/TreeItem';

import Collapse from '@mui/material/Collapse';
// web.cjs is required for IE11 support
import { animated, useSpring } from 'react-spring';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Checkbox, IconButton } from '@mui/material';

import {
    KeyboardArrowDownOutlined,
    KeyboardArrowRightOutlined,
    KeyboardArrowUp,
    UnfoldLessOutlined,
} from '@mui/icons-material';
import { ColumnsPanelContext } from '../contexts/columns';
import { ParamsContext } from '../contexts/params';
// import { treeItemClasses } from '@mui/lab';

function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)',
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}
//ContentComponent={forwardRef(props,ref)=> }
const StyledTreeItem = styled((props: TreeItemProps) => <TreeItem {...props} slots={{ groupTransition: TransitionComponent, ...props.slots }} />)(
    ({ theme }) => ({
        [`&.MuiTreeItem-iconContainer`]: {
            '& .close': {
                opacity: 0.3,
            },
        },
        [`&.MuiTreeItem-group`]: {
            marginLeft: 15,
            paddingLeft: 18,
            borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
        },
    }),
);
const isKey = (key: string) => key.startsWith('.');
const getColumnVisibility = (
    tree: Record<string, any>,
    keyPath: string,
    hiddenColumns: Set<string>,
): 'full' | 'none' | 'partial' => {
    if (tree === null || Object.keys(tree).length === 1) {
        return !hiddenColumns.has(keyPath) ? 'full' : 'none';
    }
    const subColumnVisibility: boolean[] = Object.entries(tree)
        .filter(([key]) => isKey(key))
        .map(([key, value]) => {
            return !hiddenColumns.has(key);
        });
    return subColumnVisibility.every(e => e) ? 'full' : subColumnVisibility.some(e => e) ? 'partial' : 'none';
};
const ColumnSelectionRow = ({
    gridColDefs,
    keyPath,
    tree,
    onchange,
}: {
    gridColDefs: any;
    keyPath: string;
    tree: Record<string, any>;
    onchange: (keyPath: string, checked: boolean, object: typeof tree) => void;
}) => {
    const { hiddenColumns, collapsedColumnsModel, toggleColumnCollapsed } = useContext(ColumnsPanelContext);
    if (tree === null || collapsedColumnsModel.has(keyPath)) {
        return null;
    };

    return (
        <>
            {Object.entries(tree).map(([key, value]: [string, typeof tree]) => {
                if (!isKey(key)) return null;
                const haveChildren = !!(value && !collapsedColumnsModel.has(key));
                const icons: TreeItemSlotProps = {
                    collapseIcon: haveChildren ? <KeyboardArrowUp /> : <KeyboardArrowRightOutlined color={'disabled'} />,
                    expandIcon: haveChildren ? (
                        <KeyboardArrowDownOutlined />
                    ) : (
                        <KeyboardArrowRightOutlined color={'disabled'} />
                    ),
                };
                const columnVisibilityStatus = getColumnVisibility(value, key, hiddenColumns);
                let headerName = key.split('.').pop();
                gridColDefs?.forEach((singleColumn: { field: string; headerName: string | undefined }) => {
                    if (singleColumn.field === key) {
                        headerName = singleColumn.headerName;
                    }
                });
                // console.log({ tree, columnVisibilityStatus, haveChildren, collapsedColumnsModel, key });
                const label = (
                    <>
                        <Checkbox
                            indeterminate={columnVisibilityStatus === 'partial'}
                            checked={columnVisibilityStatus === 'full' ? true : false}
                            onClick={event => {
                                event.stopPropagation();
                                // @ts-ignore
                                onchange(key, event.target.checked, value);
                            }}
                        />
                        {headerName}
                        {haveChildren || collapsedColumnsModel.has(key) ? (
                            <IconButton
                                onClick={() => {
                                    toggleColumnCollapsed(key);
                                }}
                                sx={{ transform: 'rotate(90deg)' }}
                            >
                                <UnfoldLessOutlined />
                            </IconButton>
                        ) : null}
                    </>
                );
                return (
                    <StyledTreeItem {...icons} key={key} label={label} itemId={key}>
                        <ColumnSelectionRow gridColDefs={gridColDefs} keyPath={key} tree={value} onchange={onchange} />
                    </StyledTreeItem>
                );
            })}
        </>
    );
};

export function ColumnsPanel({ gridColDefs }: { gridColDefs?: any }) {
    const { buildConfig } = useContext(ParamsContext);
    const { columnTree, hideColumn, showColumn } = useContext(ColumnsPanelContext);
    // const gridColDefs = useGridBuilder(buildConfig, []);
    const setColumnVisibilityHandler = (keyPath: string, checked: boolean, object: typeof columnTree) => {
        checked ? showColumn(keyPath) : hideColumn(keyPath);
        if (!(object === null || Object.keys(object).length === 0)) {
            Object.keys(object).forEach(key => {
                checked ? showColumn(key) : hideColumn(key);
            });
        }
    };
    return (
        <Box
            sx={{
                padding: '1em',
                overflow: 'auto',
                width: '100%',
            }}
        >
            <SimpleTreeView>
                <ColumnSelectionRow
                    keyPath={''}
                    gridColDefs={gridColDefs}
                    tree={columnTree}
                    onchange={setColumnVisibilityHandler}
                />
            </SimpleTreeView>
        </Box>
    );
}
