import LaunchIcon from '@mui/icons-material/Launch';
import { makeStyles } from '@mui/styles';
import { formatDate, defaultDateFormat } from '../utils/date';
import { Cell } from '../components-ui/table/components-renderers/Cell';

export type DateGridCellProps = {
    date: Date;
    link?: string;
    format?: string;
};

const useStyles = makeStyles({
    greenlink: {
        color: 'rgb(36,192,177)!important',
        textDecoration: 'none',
        fontWeight: 'bold',
        cursor: 'pointer',
        background: 'transparent',
        border: 'none',
        display: 'flex',
        placeContent: 'center',
        placeItems: 'center',
        gap: '0.5rem',
        width: '100%',
        height: '100%',
    },
});

/**
 *
 * @param date
 * @param link
 * @param format
 * Edit this component to allow react routing
 */
export function DateGridCell({ date = new Date(), link, format = defaultDateFormat }: DateGridCellProps) {
    const classes = useStyles();
    const dateString = formatDate(date, format);
    return (
        <Cell data-testid='cell'>
            <a href={link} target='_blank' className={classes.greenlink} rel='noreferrer'>
                {dateString}
                <LaunchIcon />
            </a>
        </Cell>
    );
}
