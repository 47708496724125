import { newExpenseDb } from './newExpenseDexie';
import { newEventDb } from '../NewEvent/newEventsDexie';
import newExpenseActionTypes, { createNewExpenseAction } from './actions';
import DynTableConst from '../../Services/Constants';
import newSyncDataActionTypes from '../NewSync/actions';
import axios from 'axios';
import { setDbSyncedDown } from '../helpers';

const PaginationLimit = 20;

export const createNewExpenseWrapperForDexie = (expense: any) =>
    ({
        _id: expense._id,
        expense: expense,
        syncDate: new Date(),
        event: expense.eventId,
        syncPending: 0,
        isNewDoc: 0,
    } as DexieExpense);

export const syncAllExpenses = async (dispatch: any) => {
    try {
        // drop the expenses in the database
        await newExpenseDb.transaction('rw', newExpenseDb.tables, async () => {
            await Promise.all(newExpenseDb.tables.map(table => table.clear()));
        });

        const eventIds = await newEventDb.events
            .where('_id')
            .notEqual('')
            .uniqueKeys();

        // console.log({ eventIds });

        // get the new expenses and load them to the database
        let count = 1;
        for (let idx = 0; idx < count; idx += PaginationLimit) {
            const expensesResponse = await getExpensesFetch(eventIds as Array<string>, idx);
            if (!expensesResponse.success) throw new Error('Failed to sync custom reports');
            count = expensesResponse?.count || 0;
            // console.log({ idx, projectsResponse, count });

            Array.isArray(expensesResponse?.data) &&
                expensesResponse.data.forEach((expense: any) => {
                    expense && expense._id && dispatch(createNewExpenseAction(createNewExpenseWrapperForDexie(expense)));
                });

            dispatch({
                type: newSyncDataActionTypes.ADD_SYNC_LOG,
                payload: { action: 'Sync newExpenseDb', idx, count },
            });
        }

        dispatch({
            type: newExpenseActionTypes.UPDATE_EXPENSE_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
        setDbSyncedDown('expensesDB');
    } catch (error) {
        console.log(error);
        dispatch({
            type: newSyncDataActionTypes.ADD_SYNC_ERROR,
            payload: error || 'Error handled but no log available',
        });
        dispatch({
            type: newExpenseActionTypes.UPDATE_EXPENSE_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
    }
};

export const getExpensesFetch = async (eventIds: Array<string>, skip: number): Promise<any> => {
    return axios
        .post(
            DynTableConst.EMPPLAN_HOST + `/expense/pwa/sync?limit=${PaginationLimit}&skip=${skip}`,
            { eventIds },
            {
                withCredentials: true, // for local dev
                // for local dev
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            },
        )
        .then(response => response.data)
        .catch(err => {
            console.error(err);
            return { foo: 'broken' };
        });
};

export const deleteExpenseItemsFetch = async (salesItemId: string): Promise<any> => {
    return axios
        .delete(DynTableConst.EMPPLAN_HOST + `/expense/${salesItemId}`, {
            withCredentials: true, // for local dev
            // for local dev
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
        })
        .then(response => response.data)
        .catch(err => {
            console.error(err);
            return { foo: 'broken' };
        });
};
