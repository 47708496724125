import { IParams } from '../contexts/params';
import { useMemo } from 'react';

export function useDefaultParams(params: IParams) {
    return useMemo(
        () => ({
            ...params,
            data: { ...params.data },
        }),
        [params],
    );
}
