import {
  Grid,
  Box,
  Typography,
  Divider,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Button, Slider, TableFooter, IconButton, Chip, ListItem
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useTheme} from "@mui/material";
import {GridCellValue, GridColDef, useGridApiContext, useGridState} from "@mui/x-data-grid-pro";
import React, {FC, useContext, useEffect, useMemo, useState} from "react";
import {ColumnsPanelContext} from "../table/contexts/columns";
import {columnDefaults} from "../table/hooks/model";
import {UnfoldLessOutlined} from "@mui/icons-material";
import {useTranslationSafe} from "../../config/translate";
const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxSizing: 'border-box',
  flexWrap:'nowrap',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  maxWidth: '100%',
  maxHeight: '100%',
}));

const StyledGrid  = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxSizing: 'border-box',
  flexWrap:'nowrap',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  maxWidth: '100%',
  maxHeight: '100%',
  })
)
export interface InnerGridProps {
  sx?: any;
  value: Record<string, any>
  options?: {
    columns:GridColDef[],
    keyPath:string
  }
}
const getColumnWidthsArray/*return %*/ = (columns:GridColDef[],columnsWidthModel:Record<string, number>,keyPath:string) => {
  const getValues = (values:number[])=>values.reduce((acc, cur) => [...acc, (acc[acc.length - 1] || 0) + cur], [] as number[]);
  const widthArray =  columns.map((column) =>  {
    if(columnsWidthModel[column.field]) {
      return columnsWidthModel[column.field];
    } else if(column.width) {
      return (column.width/columnDefaults.width)*(100/columns.length);
    } else {
      return 100/columns.length;
    }
  })
  return getValues(widthArray);
}
export function InnerGrid(props: InnerGridProps) {
  const {t} = useTranslationSafe()
  let gridRowHeight = 52;
  const gridApiRef = useGridApiContext();
  const {columnsWidthModel,hiddenColumns} = useContext(ColumnsPanelContext)
  const gridColumnWidth = columnsWidthModel[props.options?.keyPath as string] || columnDefaults.width;
  const [columnWidths, setColumnWidths] = useState<number[]>([]);
  const columnsTobeShown:GridColDef[]|undefined = useMemo(()=>props.options?.columns.filter(col=>!hiddenColumns.has(col.field)),[props.options?.columns,hiddenColumns])
  const getLeftValues = (widths:number[])=>widths.reduce((acc, cur) => [...acc, (acc[acc.length - 1] || 0) + cur], [] as number[]);

  const defaultColumnWidth = 1/(columnsTobeShown?.length||1)*100;
  function updateColumnWidth() {
    if(columnsTobeShown) {
      const totalWidth = columnsTobeShown.reduce((acc, cur) => acc + (columnsWidthModel[cur.field] || defaultColumnWidth), 0);
      const columnWidths = columnsTobeShown.map((column) => (columnsWidthModel[column.field] || defaultColumnWidth) / totalWidth * 100).slice(0, -1)
      setColumnWidths(getLeftValues(columnWidths))
    }
  }

  function generateChipsForObject(value:any){

    // if(! (value !== null && 
    //   typeof value === 'object' &&
    //   Object.getPrototypeOf(value).isPrototypeOf(Object))){
    //   return !value?"":value.toString()
    // }
    if(typeof value !== 'string'){
      return value?value.toString():""
    }

    let parsedValue;
    try {
        // value is sometimes "" which causes a parse unhandled error
        parsedValue = JSON.parse(value);
    } catch (error) {
        return '';
    }

    
    return Object.entries(parsedValue).filter(en => !!en[1]).map(([key,val])=>{

       return (
        <Chip style={{marginRight:"5px"}} label={<Box display={"flex"} sx={{
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
         // border: (theme) => `1px solid ${theme.palette.divider}`,
          // borderRadius: 1,
          // bgcolor: 'background.paper',
          // color: 'text.secondary',
          '& svg': {

            m: 1.5,
          },
          '& hr': {
            mx: 0.5,
            my:0
          },
        }}>
          {t(key)}
          <Divider orientation="vertical" variant="middle" flexItem ></Divider>
          {JSON.stringify(val).replaceAll(`"`,"") }
        </Box>}></Chip>
       )

    })
     
  }

  useEffect(updateColumnWidth,[hiddenColumns,columnsWidthModel])
    return useMemo(()=>props.value && props.options ?(
      <Box sx={{
        position: 'relative',
        overflow: 'hidden',
        height:`${gridRowHeight}px`,
        width:'100%',
      }}>
        {columnsTobeShown?.map((columnDef,index) => {
                        let cellValue:GridCellValue = props.value[columnDef.field];
                        let cellRender:any;
                        if(columnDef.valueGetter) {
                          // @ts-ignore
                          cellValue = columnDef.valueGetter({row:props.value,colDef:columnDef,value:cellValue});
                        }
                        if(columnDef.renderCell) {
                          const CellRenderer = columnDef.renderCell as FC<{value:any}>;
                          cellRender = ()=> <CellRenderer value={cellValue} />;
                        }
                    return <Box key={columnDef.field} sx={{
                      left: (columnWidths[index-1] || 0)+'%',
                      width: ((columnWidths[index] || gridColumnWidth)-(columnWidths[index-1] || 0))+'%',
                      position: 'absolute',
                      padding:'4px',
                      height:'100%',
                      lineHeight:'1.5em',
                      overflow:'hidden',
                      boxSizing:'border-box',
                      // backgroundColor: '#fff',
                      display: 'flex',
                      alignItems: 'center'
                    }} >
                      {cellRender ? cellRender() :  generateChipsForObject(cellValue)}
                    </Box>
                  })
                  }
      </Box>
  ):null, [props.value,props.options,hiddenColumns,columnWidths]);
}

export interface InnerGridHeaderProps {
  options?: {
    title?: string,
    allColumns: GridColDef[]
    aggregateColumn: GridColDef
    keyPath: string
  }
}
function ColumnsResizer(props: { values: number[]/*%*/, sx?:any; onChange: (newSizes: number[]) => void , onChangeCommitted: (newSizes: number[]) => void }) {
  // each elemnt sum of all previous elements
  // const getValues = ()=>props.values.reduce((acc, cur) => [...acc, (acc[acc.length - 1] || 0) + cur], [] as number[]);
  // const valuesToWidths  = (values: number[]) => values.map((v, i) => (i === 0 ? v : v - values[i - 1]));
  const handleChange = (event: any, newValue: number | number[]) => {
    if(props.onChange) {
      props.onChange(newValue as number[]);
    }
  }
  const handleChangeCommitted = (event: any, newValue: number | number[]) => {
    if(props.onChangeCommitted) {
      props.onChangeCommitted(newValue as number[]);
    }
  }
  return <Slider
    sx={{
      margin: '0px',
      width:'100%',
      background:'transparent',
      color: 'transparent',
      height:'50%',
      cursor:'col-resize',
      padding: '0px',
      bottom:'0px',
      position:'absolute',
      '& .MuiSlider-thumb': {
        width: 2,
        background: 'gray',
        border: 'none',
        borderRadius: 0
      },
      ...props.sx,
    }}
    track={false}
    value={props.values}
    // disableSwap
    onChange={handleChange}
    onChangeCommitted={handleChangeCommitted}
  />
}
export function InnerGridHeader(props: InnerGridHeaderProps) {
  const columnFieldName = props.options?.keyPath as string
  const gridApiRef = useGridApiContext();
  const {collapsedColumnsModel,columnsWidthModel,hiddenColumns,updateColumnWidthModel,toggleColumnCollapsed} = useContext(ColumnsPanelContext)
  const {t} = useTranslationSafe();
  // @ts-ignore
  const fieldHeaderPairs = Object.fromEntries(props.options?.allColumns.map((item) => [item.field,item.headerName || item.field]));
  const columnsTobeShown:GridColDef[]|undefined = useMemo(()=>props.options?.allColumns.filter(col=>!hiddenColumns.has(col.field)),[props.options?.allColumns,hiddenColumns])
  if(!columnsTobeShown) {
    throw new Error('columnsTobeShown is undefined, this is not a bug, check schema.')
  }
  const getLeftValues = (widths:number[])=>widths.reduce((acc, cur) => [...acc, (acc[acc.length - 1] || 0) + cur], [] as number[]);
  const leftsToWidths  = (values: number[]) => values.map((v, i) => (i === 0 ? v : v - values[i - 1]));
  useEffect(() => {
    if(gridApiRef?.current) {
      if(!columnsWidthModel[columnFieldName] || columnsWidthModel[columnFieldName] === columnDefaults.width) {
        columnsWidthModel[columnFieldName] = columnDefaults.width*columnsTobeShown.length;
      }
    }
  }, [gridApiRef])
  const defaultColumnWidth = 1/(columnsTobeShown.length)*100;
  const [columnWidths, setColumnWidths] = useState<number[]>([]);
  useEffect(() => {
    const width = (collapsedColumnsModel.has(columnFieldName) ? columnsWidthModel[`${columnFieldName}[collapsed]`] : columnsWidthModel[columnFieldName]) || columnDefaults.width;
    gridApiRef.current.setColumnWidth(columnFieldName, width);
  },[collapsedColumnsModel.has(columnFieldName)])
  function updateColumnWidth() {
    if(columnsTobeShown) {
      const totalWidth = columnsTobeShown.reduce((acc, cur) => acc + (columnsWidthModel[cur.field] || defaultColumnWidth), 0);
      const columnWidths = columnsTobeShown.map((column) => (columnsWidthModel[column.field] || defaultColumnWidth) / totalWidth * 100).slice(0, -1)
      setColumnWidths(getLeftValues(columnWidths))
    }
  }
  useEffect(updateColumnWidth,[hiddenColumns,columnsWidthModel])

  const handleWidthChangeCommit = (newSizes: number[]) => {
    const hiddenColumnsTotalWidth = props.options?.allColumns.filter(col=>hiddenColumns.has(col.field)).reduce((acc,cur)=>acc+(columnsWidthModel[cur.field] || columnDefaults.width),0)||0
    const widths = leftsToWidths([...newSizes,100]).map((width)=>width/100*(100-hiddenColumnsTotalWidth));
    const newWidthModel = Object.fromEntries(
      columnsTobeShown.map((column,index) =>
                [column.field,widths[index]]));
    updateColumnWidthModel(newWidthModel);
  }
   return useMemo(()=>
     collapsedColumnsModel.has(props?.options?.keyPath as string)  ?

       <><Typography>{props.options?.title}</Typography>
         { <IconButton onClick={()=>{toggleColumnCollapsed(columnFieldName)}} sx={{transform:'rotate(90deg)'}}><UnfoldLessOutlined/></IconButton>}
       </>
       :
     <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      placeItems: 'center',
      gap: '2px',
      width: '100%',
      height: '100%',
      position: 'absolute',
    }}
  ><Box component={'span'} sx={{
        display:'flex',placeItems:'center',gap:'1em',
        // position:'absolute',
        // top: '-1em',
   }} ><Typography>{props.options?.title}</Typography>
     { <IconButton size={'small'} onClick={()=>{toggleColumnCollapsed(columnFieldName)}} sx={{transform:'rotate(90deg)'}}><UnfoldLessOutlined/></IconButton>}
   </Box>

      <Box sx={{
        position: 'absolute',
        overflow: 'hidden',
        height:'100%',
        width:'100%',
        bottom:'-50%',
      }}>
          {columnsTobeShown.map((item,index) => <Box key={item.field} sx={{
              left: (columnWidths[index-1] || 0)+'%',
              width: columnWidths[index] - (columnWidths[index-1] || 100)+'%',
              position: 'absolute',
              padding:'4px',
              height:'100%',
              lineHeight:'1.5em',
              overflow:'hidden',
              boxSizing:'border-box',
              backgroundColor: 'white',
            }} >{t(fieldHeaderPairs[item.field])}
            </Box>)}
      </Box>
     <ColumnsResizer values={columnWidths} onChange={setColumnWidths} onChangeCommitted={handleWidthChangeCommit}/>
  </Box>,[columnWidths,columnsTobeShown,hiddenColumns,collapsedColumnsModel,props.options])
  }