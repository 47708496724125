import React, { useContext, useState } from 'react';
import {
    Box,
    IconButton,
    Tooltip,
    Menu,
    MenuItem,
    ListItemIcon,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import { Channel } from '../types';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupsIcon from '@mui/icons-material/Groups';
import API from '../../../config/api';
import {
    returnChatChannelEdit,
    returnChatUserCanViewUsers,
} from '../utils/helpers';
import MessageSharp from '@mui/icons-material/MessageSharp';
import { AddEditChannelDetails } from '../components/AddEditChannelDetails';


interface ChannelMenuProps {
    openChannel: { status: boolean; currentChannel: Channel | null | undefined };
    fetchChannel?: any;
    setOpenChannel: any;
    setChannelOpen?: any;
    setSelectMode: any;
    cbAfterDelete?: () => void;
}

function ChannelMenu({ fetchChannel, setChannelOpen, setOpenChannel, setSelectMode, openChannel, cbAfterDelete }: ChannelMenuProps) {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openAddUserModel, setOpenAddUserModel] = useState<boolean>(false);
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteChannel = (channelId: string) => {
        API.delete(`/chatchannel/${channelId}`)
            .then(res => {
                fetchChannel && fetchChannel();
                setOpenChannel({
                    status: false,
                    currentChannel: null,
                });
                setChannelOpen && setChannelOpen(false);
                setDeleteDialog && setDeleteDialog(false);
                cbAfterDelete && cbAfterDelete();
            })
            .catch(error => {
                console.error(error);
            });
    };
    return (
        <Box display='flex' alignItems='center' gap={1}>
            <Tooltip title='Channel settings'>
                <IconButton
                    onClick={handleClick}
                    size='small'
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                >
                    <MoreVertIcon color='secondary' />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id='account-menu'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {returnChatChannelEdit(window?.user?.roles) &&
                    returnChatUserCanViewUsers(window?.user?.roles) && (
                        <MenuItem
                            onClick={() => {
                                setOpenAddUserModel(true);
                                handleClose();
                            }}
                        >
                            <ListItemIcon>
                                <GroupsIcon />
                            </ListItemIcon>
                            {t('##EditChannel')}
                        </MenuItem>
                    )}
                {returnChatChannelEdit(window?.user?.roles) && (
                    <MenuItem
                        onClick={() => {
                            setDeleteDialog(true);
                            handleClose();
                        }}
                    >
                        <ListItemIcon>
                            <DeleteIcon />
                        </ListItemIcon>
                        {t('##Delete')}
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        setSelectMode(true);
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <MessageSharp />
                    </ListItemIcon>
                    {t('##SelectMessages')}
                </MenuItem>
            </Menu>
            {deleteDialog && (
                <Dialog fullWidth open={deleteDialog}>
                    <DialogTitle>{t('##Delete')}</DialogTitle>
                    <DialogContent>{t('##AreYouSureWantToDelete')}</DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDeleteDialog(false)}>{t('##Cancel')}</Button>
                        <Button
                            onClick={() => {
                                handleDeleteChannel(openChannel.currentChannel?._id as string);
                            }}
                        >
                            {t('##Delete')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {openAddUserModel && openChannel.currentChannel && (
                <AddEditChannelDetails
                    mode='edit'
                    open={openAddUserModel}
                    fetchChannelList={fetchChannel}
                    close={() => setOpenAddUserModel(false)}
                    channel={openChannel.currentChannel}
                    updateChannelMembers={value => {
                        setOpenChannel({
                            ...openChannel,
                            currentChannel: {
                                ...openChannel.currentChannel,
                                members: value.initialUsers,
                                readOnly: value.readOnly,
                                name: value.channelName,
                            },
                        });
                    }}
                />
            )}
        </Box>
    )
}


export default ChannelMenu;