import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import { GroupConditionComponent } from './GroupConditionComponent';
import { generateRandomKey } from './keygen';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EmpplanTheme from '../elements/ThemeWrapper/EmpplanTheme';

export const FieldConfigContext = React.createContext<{ wrapperType?: string; payload: any }>({
    payload: null,
});

interface AddDependentFieldsReactProps {
    customReport: any; // => will be used as the custom report
    currentId: string; // => the id of the current custom report
    updateCallback: any; // updating the custom report in angular
    modal?: any;
}

const defaultVisibilityObject = {
    type: 'group',
    operator: 'AND',
    rKey: generateRandomKey(),
    conditions: [],
};

const getInitVisibilityValue = (fieldConfig: CustomReportField | undefined) => {
    const fieldVisibilityConfig = fieldConfig?.visibility?.conditions?.field;
    if (
        fieldVisibilityConfig &&
        fieldVisibilityConfig?.conditions &&
        fieldVisibilityConfig?.operator &&
        fieldVisibilityConfig?.type === 'group'
    ) {
        return fieldVisibilityConfig as GroupCondition;
    }
    return defaultVisibilityObject;
};

function AddDependentFieldsReact({ customReport, currentId, updateCallback, modal }: AddDependentFieldsReactProps) {
    // console.log('AddDependentFieldsReact Props', { customReport, currentId, updateCallback });

    const { t } = useTranslation();
    // This context contains the custom report data and current report_id used by another component inside
    const contextObject = {
        customReport,
        currentId,
    };

    // defaultVisibilityObject should be the fallback value incase the value is an empty array or an empty object
    const [data, setData] = useState<GroupCondition>(getInitVisibilityValue(customReport[currentId]) as GroupCondition);
    const [debug, setDebug] = useState<boolean>(false);

    useEffect(() => {
        //console.log('updated value', modal, data);
        updateCallback(modal?.toString(), data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // useEffect(() => {
    //     //console.log('updated value', modal, data);
    //     setData(getInitVisibilityValue(customReport[currentId]) as GroupCondition);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [customReport[currentId]]);

    return (
        <EmpplanTheme>
            <div>
                <Accordion sx={{ my: 1 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                        <Typography>{t(`##VisibilityConditionField`)}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FieldConfigContext.Provider value={{ payload: contextObject }}>
                            <GroupConditionComponent currentValue={data} onChange={setData} />
                        </FieldConfigContext.Provider>
                    </AccordionDetails>
                </Accordion>
                <Button
                    onClick={() => {
                        setDebug(!debug);
                    }}
                >
                    {debug ? 'Hide visibility debug info' : 'Show visibility debug info'}
                </Button>
                {debug && <pre>{JSON.stringify(data, null, 4)}</pre>}
            </div>
        </EmpplanTheme>
    );
}

export default AddDependentFieldsReact;
