

/**
 *
 * @param field The field configuration
 * @param reportData The entire report data object
 * @returns true if the field should be hidden / not considered for required verification
 */


//PRIORITY:
// 1. Product Group
// 2. Custom Function
// 3. Visibility Condition

export const isFieldHidden: (
    fieldConfig: CustomReportField,
    reportConfig: CustomReportConfig,
    reportData: ReportData,
    optionalPayload?: OptionalConditionPayload,
    isFieldHiddenCustomCondition?:isFieldHiddenCustomCondition
) => boolean = (fieldConfig, reportConfig, reportData, optionalPayload,isFieldHiddenCustomCondition) => {
    const fieldType = fieldConfig?.config?.fieldType || '';
    const fieldVisibilityConfig: GroupCondition | undefined = fieldConfig?.visibility?.conditions?.field;
    

    if(optionalPayload?.productGroup && fieldConfig?.visibility?.conditions?.productGroups.length>0 && !fieldConfig?.visibility?.conditions?.productGroups.find(pg=>pg._id === optionalPayload?.productGroup)){
        //if productGroup is not present in the visibility's product Group Array, the field should be hidden
        return true
    }
    
   

    if(isFieldHiddenCustomCondition?.(fieldConfig, reportConfig, reportData, optionalPayload)){
        return true
    }

    

    if (
        fieldType &&
        fieldVisibilityConfig &&
        fieldVisibilityConfig.conditions &&
        fieldVisibilityConfig.operator &&
        fieldVisibilityConfig.type === 'group'
    ) {
        const sanitizedReportConfig = sanitizeReportConfig(reportConfig);
      

        const testResult = testGroupCondition(
            fieldType,
            fieldVisibilityConfig,
            sanitizedReportConfig,
            reportData,
            optionalPayload,
        );
        
        // if(fieldVisibilityConfig.conditions.length>0){
        //     console.log(fieldConfig.config.name,!testResult)
        // }
        
        
    
        return !testResult;
    } else {
        // console.log('Invalid  visibility config, not testing field');
        return false;
    }
};

interface SanitizedFieldConfig {
    refId: string; // field.config._id
    fieldType: string; // field.config.fieldType
    fieldVisibilityConditions?: GroupCondition; // field.visibility.conditions.field
}

type SanitizedReportConfig = Array<SanitizedFieldConfig>;

export const sanitizeReportConfig: (reportConfig: CustomReportConfig) => SanitizedReportConfig = reportConfig => {
    const sanitizedFields =
        reportConfig?.customFields?.map(field => ({
            refId: field.config._id,
            fieldType: field.config.fieldType,
            fieldVisibilityConditions: field.visibility?.conditions?.field,
        })) || [];
    return sanitizedFields;
};

const testGroupCondition: (
    fieldType: string,
    groupCondition: GroupCondition,
    sanitizedReportConfig: SanitizedReportConfig,
    reportData: ReportData,
    optionalPayload?: OptionalConditionPayload,
) => boolean = (fieldType, groupCondition, sanitizedReportConfig, reportData, optionalPayload) => {
    if (groupCondition.conditions.length === 0) return true;

    const conditions = [...groupCondition.conditions];
    let result = false;

    // this sort orders the conditions so that the field are checked before the groups
    conditions.sort((a, b) => (a.type === 'field' ? -1 : 1));

    for (let index = 0; index < conditions.length; index++) {
        const condition = conditions[index];

        if (condition.type === 'field') {
            result = testFieldCondition(fieldType, condition, sanitizedReportConfig, reportData, optionalPayload);
        } else if (condition.type === 'group') {
            result = testGroupCondition(fieldType, condition, sanitizedReportConfig, reportData, optionalPayload);
        } else if (condition.type === 'product') {
            result = testProductCondition(fieldType, condition, sanitizedReportConfig, reportData, optionalPayload);
        } else {
            console.log('Error: invalid condition type. Defaulting to true');
            result = true;
        }

        if (groupCondition.operator === 'AND' && result === false) {
            // does not make sense to check more if the current value is false
            break;
        }

        if (groupCondition.operator === 'OR' && result === true) {
            // does not make sense to check more if the current value is true
            break;
        }
    }

    return result;
};

const stringFieldTypes = ['stringSmall', 'stringBig', 'Checkbox', 'Dropdown', 'Radio', 'FreeText', 'Date','upload','Signature'];
const numberFieldTypes = ['number', 'Slider', 'Time'];

type TestFieldConditionFn = (
    fieldType: string,
    fcond: FieldCondition,
    sanitizedReportConfig: SanitizedReportConfig,
    reportData: ReportData,
    optionalPayload?: OptionalConditionPayload,
) => boolean;

const testFieldCondition: TestFieldConditionFn = (fieldType, fcond, sanitizedReportConfig, reportData) => {
    let valueAtRef = reportData[fcond.refField];
    let expectedValue = fcond?.condition?.value;

    const operator = fcond.condition.operator;
    const sRefFieldConfig = sanitizedReportConfig.find(f => f.refId === fcond.refField);
    const refFieldType = sRefFieldConfig?.fieldType;

    if (refFieldType === 'FreeText') {
        valueAtRef = (valueAtRef?.value === '#FreeText#' ? valueAtRef?.FreeText : valueAtRef?.value) || '';
    }



    if (!(operator && sRefFieldConfig && refFieldType)) {
        console.warn('Invalid field condition object', { fcond },{operator , sRefFieldConfig , refFieldType},sanitizedReportConfig);
        return true;
    }

    if (stringFieldTypes.includes(refFieldType)) {
        // checks for string types
        if (!getValidOperatorsForFieldType(refFieldType).includes(operator)) {
            console.warn('Invalid operator for string type comparison, defaulting to true');
            return true;
        }

        if (operator === '=') {
            if (Array.isArray(valueAtRef)) {
                return valueAtRef.includes(expectedValue);
            }
            return valueAtRef === expectedValue;
        }else if(operator === '!='){
            if (Array.isArray(valueAtRef)) {
                return !valueAtRef.includes(expectedValue);
            }
            return valueAtRef !== expectedValue;
        }else if(operator === '?'){
            if (Array.isArray(valueAtRef)) {
                return !(valueAtRef.length > 0)
            }
            return !valueAtRef
        }else if(operator === '!?'){
            if (Array.isArray(valueAtRef)) {
                return !!(valueAtRef.length > 0)
            }
            return !!valueAtRef
        }else {
            console.warn('Check implementation of string comparison in testFieldCondition, defaulting to true');
            return true;
        }
    } else if (numberFieldTypes.includes(refFieldType)) {
        // checks for number types
        if (!getValidOperatorsForFieldType(refFieldType).includes(operator)) {
            console.warn('Invalid operator for number type comparison, defaulting to true');
            return true;
        }

        valueAtRef = Number(valueAtRef); // parses ints and floats too
        expectedValue = Number(expectedValue);

        if (operator === '=') {
            return valueAtRef === expectedValue;
        } else if (operator === '!=') {
            return valueAtRef !== expectedValue;
        } else if (operator === '>') {
            return valueAtRef > expectedValue;
        } else if (operator === '>=') {
            return valueAtRef >= expectedValue;
        } else if (operator === '<=') {
            return valueAtRef <= expectedValue;
        } else if (operator === '<') {
            return valueAtRef < expectedValue;
        }else if (operator === '?') {
            return valueAtRef===0?false:!valueAtRef 
        }else if (operator === '!?') {
            return valueAtRef===0?true:!!valueAtRef 
        } else {
            console.warn('Check implementation of string comparison in testFieldCondition, defaulting to true');
            return true;
        }
    } else {
        console.warn('invalid/unimplemented field type here, defaulting to true');
        return true;
    }
};

const getValidOperatorsForFieldType: (fieldType: string) => Array<string> = fieldType => {
    if (numberFieldTypes.includes(fieldType)) return ['=', '!=', '>', '<', '>=', '<=','?','!?'];
    if (stringFieldTypes.includes(fieldType)) return ['=', '!=','?','!?'];
    return [];
};

type TestProductConditionFn = (
    fieldType: string,
    fcond: ProductCondition,
    sanitizedReportConfig: SanitizedReportConfig,
    reportData: ReportData,
    optionalPayload?: OptionalConditionPayload,
) => boolean;

const testProductCondition: TestProductConditionFn = (
    fieldType,
    fcond,
    sanitizedReportConfig,
    reportData,
    optionalPayload,
) => {
    const operator = fcond.condition.operator;
    const productToCompare = fcond.condition.product?._id;

    const currentProduct = optionalPayload?.productId;
    const currentProductIsArray = Array.isArray(currentProduct);

    // console.log('testing the product condition', { expecting: currentProduct, toBe: operator, productToCompare });

    if (currentProduct && operator && productToCompare) {
        if (operator === '=') {
            if (currentProductIsArray) {
                return currentProduct.includes(productToCompare);
            } else {
                return productToCompare === currentProduct;
            }
        } else if (operator === '!=') {
            if (currentProductIsArray) {
                return !currentProduct.includes(productToCompare);
            } else {
                return productToCompare !== currentProduct;
            }
        } else {
            console.warn('Check implementation of string comparison in testProductCondition, defaulting to true');
            return true;
        }
    } else {
        console.warn('Invalid condition for product, defaulting to true', {
            expecting: currentProduct,
            toBe: operator,
            productToCompare,
        });
        return true;
    }
};


