import React from 'react';
import { Grid, Typography } from "@mui/material";
import { renderDate } from '../helpers/dateHelpers';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function LeadTransactionItemCard({ item,handleEditAndDelete }: any){

    return (
        <>
            <Grid container spacing={2} padding='5px'>
                <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center' gap={1}>
                    <Typography variant='body1'>
                        {item.qty} x {item.productId?.name} {item?.saleId ? `(`+ item.saleId +`)` : ''} {`(`+ renderDate(item.saleTime) +`)`}
                    </Typography>
                    <EditIcon
                        onClick={() => handleEditAndDelete('edit', item)}
                        style={{ cursor: 'pointer' }}
                    />
                    <DeleteIcon
                        onClick={() => handleEditAndDelete('delete', item)}
                        style={{ cursor: 'pointer' }}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default LeadTransactionItemCard