import * as React from 'react';
import { CRMData } from 'reactelements/src/modules/ViewEditCrm/types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LeadHeader } from '../LeadHeader';
import { clearCrmDataForEditing, loadCrmDataForEditing } from '../../../Models/NewCRM/actions';
import { CRMEditForm } from './CRMEditForm';
import { Grid } from '@mui/material';

export const CrmFormWrapper = () => {
    const dispatch = useDispatch<any>();

    const { leadId, projectId, mode } = useParams<{ leadId: string; projectId: string; mode?: string }>();
    const mainCRMData: CRMData = useSelector((store: any) => store?.crmItem?.crmItemForEditing);

    React.useEffect(() => {
        // if reloaded we need to fetch crm data online with leadId
        if (!mainCRMData) {
            dispatch(loadCrmDataForEditing(undefined, leadId));
        }
        return () => {
            // dispatch clear crmEdit items
            dispatch(clearCrmDataForEditing());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {mainCRMData && (
                <>
                    <LeadHeader crmData={mainCRMData} svgPin='main' />
                    <Grid container direction='column' sx={{ minHeight: '90vh', marginTop: '130px' }}>
                        <Grid item xs={12}>
                            <CRMEditForm leadId={leadId} projectId={projectId} mode={mode}/>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};
