import { AnyAction } from 'redux';
import newCustomReportResultActionTypes from './actions';

// INITIAL STATE
const initialState: CustomReportResultStates = {
    loadedCustomReportResults: [],
    loadedCustomReportResult: undefined,
    syncData: {
        state: 'READY',
        lastUpdate: null,
    },
};

// REDUCER

const customReportResultReducer = (state = { ...initialState }, action: AnyAction) => {
    if (action.type === newCustomReportResultActionTypes.LOAD_CUSTOMREPORTRESULT_LIST) {
        return { ...state, loadedCustomReportResults: action.payload };
    }
    if (action.type === newCustomReportResultActionTypes.LOAD_CUSTOMREPORTRESULT) {
        return { ...state, loadedCustomReportResult: action.payload };
    }
    if (action.type === newCustomReportResultActionTypes.UPDATE_CUSTOMREPORTRESULT_SYNC_DATA) {
        return { ...state, syncData: action.payload };
    }
    if (action.type === newCustomReportResultActionTypes.CLEAR_LOAD_CUSTOMREPORTRESULT_LIST) {
        return { ...state, loadedCustomReportResults: [] };
    }

    return state;
};

export default customReportResultReducer;
