import { newEventDb } from './newEventsDexie';
import newEventActionTypes, { createNewEventAction } from './actions';
import DynTableConst from '../../Services/Constants';
import { startProjectSyncAction } from '../NewProject/actions';
import { startCustomReportSyncAction } from '../NewCustomReport/actions';
import { startProductSyncAction } from '../NewProduct/actions';
import { startProductReportSyncAction } from '../NewProductReport/actions';
import { startCustomModelFieldSyncAction } from '../NewCustomModelField/actions';
import { startCustomReportResultSyncAction } from '../NewCustomReportResult/actions';
import { startExpenseSyncAction } from '../NewExpense/actions';
import { startSalesItemSyncAction } from '../NewSalesItem/actions';
import { startCrmItemSyncAction } from '../NewCRM/actions';
import { startProjectLocationSyncAction } from '../NewProjectLocations/actions';
import newSyncDataActionTypes, { addDownloadTimeToDexie } from '../NewSync/actions';
import axios from 'axios';
import { createSyncHistoryEntry } from '../NewSync/helpers';
import { callCRMProjectRelatedEndpointsForServiceWorkerSync } from '../NewCRM/helpers';
import { setDbSyncedDown } from '../helpers';

const PaginationLimit = 50;

export const eventStatesToSync = ['LoggedIn', 'LoggedOut', 'Accepted', 'SalesReportRejected'];

export const createNewEventWrapperForDexie = (event: any) =>
    ({
        _id: event._id,
        event: event,
        projectId: event.project_id,
        lon: event && event?.point_of_sale && event?.point_of_sale?.location ? event.point_of_sale?.location[0] : 0,
        lat: event && event?.point_of_sale && event?.point_of_sale?.location ? event.point_of_sale?.location[1] : 0,
        syncDate: new Date(),
        eventState: event.eventState,
    } as DexieEvent);

export const syncAllEvents = async (dispatch: any, userId: string) => {
    try {
        // drop the events in the database
        await newEventDb.transaction('rw', newEventDb.tables, async () => {
            await Promise.all(newEventDb.tables.map(table => table.clear()));
        });

        const projectIdsResponse = await axios.post(DynTableConst.EMPPLAN_HOST + `/projects/pwa/crmprojects/sync`);
        const crmBasedProjectIds: string[] = projectIdsResponse?.data?.data || [];

        const ongoingEventIds: Array<string> = [];
        const project_id_set = new Set([...crmBasedProjectIds]);
        const point_of_sale_set = new Set();

        // get the new events and load them to the database
        let eventsCount = 1;
        for (let idx = 0; idx < eventsCount; idx += PaginationLimit) {
            const eventResponse = await getEventsFetch({ skip: idx, limit: PaginationLimit }, userId, {
                eventState: eventStatesToSync.join(','),
            });
            if (!eventResponse.success) throw new Error('Failed to sync events');
            eventsCount = eventResponse.count || 0;
            // console.log({ count });
            // console.log({ idx, events });

            Array.isArray(eventResponse.data) &&
                eventResponse.data.forEach((event: any) => {
                    if (event && event._id) {
                        dispatch(createNewEventAction(createNewEventWrapperForDexie(event)));
                        ongoingEventIds.push(event._id);
                        // ev.point_of_sale._id
                        // ev.project_id
                        if (event.project_id) {
                            project_id_set.add(event.project_id);
                        }
                        if (event.point_of_sale?._id) {
                            point_of_sale_set.add(event.point_of_sale._id);
                        }
                    }
                });

            dispatch({
                type: newSyncDataActionTypes.ADD_SYNC_LOG,
                payload: { action: 'Sync newEventDb', idx, count: eventsCount },
            });
        }

        setDbSyncedDown('eventsDB');
        dispatch({
            type: newEventActionTypes.UPDATE_EVENT_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });

        const eventProjectsIds = [...project_id_set];
        // console.log({ eventProjectsIds });
        if (eventProjectsIds.length > 0) {
            dispatch(startProjectSyncAction(eventProjectsIds.join(',')));
        } else {
            setDbSyncedDown('projectsDB');
        }
        if (eventProjectsIds.length > 0) {
            dispatch(startProjectLocationSyncAction(eventProjectsIds.join(','), [...point_of_sale_set].join(',')));
        } else {
            setDbSyncedDown('ProjectLocationsDB');
        }
        if (eventProjectsIds.length > 0) {
            dispatch(startCustomReportSyncAction(eventProjectsIds.join(',')));
        } else {
            setDbSyncedDown('customReportsDB');
        }
        if (eventProjectsIds.length > 0) {
            dispatch(startProductSyncAction(eventProjectsIds.join(',')));
        } else {
            setDbSyncedDown('productsDB');
        }
        if (eventProjectsIds.length > 0) {
            dispatch(startProductReportSyncAction(eventProjectsIds.join(',')));
        } else {
            setDbSyncedDown('productReportsDB');
        }
        if (eventProjectsIds.length > 0) {
            dispatch(startCustomModelFieldSyncAction(eventProjectsIds.join(',')));
        } else {
            setDbSyncedDown('customModelFieldsDB');
        }
        // add the download done timestamp here
        if (window?.userabstraction?._id) {
            dispatch(addDownloadTimeToDexie(window.userabstraction._id));
        }

        if (ongoingEventIds.length > 0) {
            dispatch(startCustomReportResultSyncAction(ongoingEventIds));
        } else {
            setDbSyncedDown('customReportResultsDB');
        }
        if (eventsCount > 0) {
            dispatch(startSalesItemSyncAction());
        } else {
            setDbSyncedDown('salesItemsDB');
        }
        if (crmBasedProjectIds.length > 0) {
            dispatch(startCrmItemSyncAction(crmBasedProjectIds));
            callCRMProjectRelatedEndpointsForServiceWorkerSync(crmBasedProjectIds);
        } else {
            setDbSyncedDown('crmDB');
        }
        if (eventsCount > 0) {
            dispatch(startExpenseSyncAction());
        } else {
            setDbSyncedDown('expensesDB');
        }
        createSyncHistoryEntry('SyncDownEnd', { eventsCount });
    } catch (error) {
        console.log(error);
        dispatch({
            type: newSyncDataActionTypes.ADD_SYNC_ERROR,
            payload: error || 'Error handled but no log available',
        });
        dispatch({
            type: newEventActionTypes.UPDATE_EVENT_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
    }
};

// Published, Accepted, CheckedIn, Checkedout
// limit 10
export const getEventsFetch = async (
    pagination: { skip: number; limit: number },
    userId: string,
    filter: { eventState?: string; eventId?: string },
    sort?: string,
): Promise<any> => {
    return axios
        .post(
            DynTableConst.EMPPLAN_HOST +
                `/event/pwa/sync?limit=${pagination.limit}&skip=${pagination.skip}${
                    filter.eventState ? `&eventState=${filter.eventState}` : ''
                }${filter.eventId ? `&_id=${filter.eventId}` : ''}${sort ? `&sort=${sort}` : ''}`,
            {},
            {
                withCredentials: true, // for local dev
                // for local dev
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            },
        )
        .then(response => response.data)
        .catch(err => {
            console.error(err);
            return { foo: 'broken' };
        });
};
