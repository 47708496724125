import * as React from 'react';
import EmpplanSnackbarWrapper from '../modules/Snackbar';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

function PwaWrapper(props: React.PropsWithChildren<{}>): JSX.Element {
    return (
        <EmpplanSnackbarWrapper>
            <LocalizationProvider dateAdapter={AdapterMoment}>{props.children}</LocalizationProvider>
        </EmpplanSnackbarWrapper>
    );
}

export default PwaWrapper;
