import React, { useContext, useState, useRef, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    FormLabel,
    Typography,
    CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ToolTipComponent from '../ToolTipComponent';
import { ReportConfigurationContext } from '../index';
import { requireHelper } from '../utils/requireHelper';
import CloseIcon from '@mui/icons-material/Close';
import useFileUpload from '../../../hooks/useFileUpload';
import { sanitizeUploadFieldValue } from './UploadField';
import { trackEvent } from '../../../components-app/GAListener';
import API from '../../../config/api';

interface IProps {
    field: CustomReportField;
    onChange: any;
    reportData: ReportData;
    eventId: string | undefined;
}

function IDNowFieldComponent({ field, reportData, onChange, eventId }: IProps) {
    const { t } = useTranslation();
    const { isDisabled } = useContext(ReportConfigurationContext);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [identID, setIdentID] = useState<string>('');
    const [iframehost, setIframehost] = useState<string>('');
    const [customer, setCustomer] = useState<string>('');
    const [identData, setIdentData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [loadingAgeCheck, setLoadingAgeCheck] = useState(false);
    const [ageCheck, setAgeCheck] = useState(false);
    const [underAge, setUnderAge] = useState(false);
    const [ageCheckMsg, setAgeCheckMsg] = useState<string>('');

    const requiredCondition = requireHelper(field, reportData);
    const value = sanitizeUploadFieldValue(reportData[field.config._id] as string);
    const fieldObject = field.config.fieldObject;

    // const eventID = '668fe3cb4a9c2400166ce0cf';
    // const customFormFieldID = '5bf6b50183e1b100506a3658';

    const eventID = eventId;
    const customFormFieldID = field.config._id;

    //get idnow plugin data
    const getIdNowPluginData = async () => {
        const response = await API.get(`/connector/${fieldObject?.connectorId}`);
        const data = await response.json();
        setIframehost(data.settings.plugin.iframehost);
        setCustomer(data.settings.plugin.customer);
    };

    //create new identId
    const initIdentID = () => {
        //call backedn API[][]
        const payload = {
            eventId: eventID,
            customFormFieldId: customFormFieldID,
        };
        API.post('/idnow/createident', payload)
            .then(response => response.json())
            .then(res => {
                if (res.success) {
                    if (res.result.length > 0) {
                        setIdentID(res.result[0].identId);
                    } else {
                        getIdentIDWithRetry();
                    }
                } else {
                    //setErr(true);
                }
            })
            .catch(err => {
                // setErr(true);
            });
    };

    //get identId
    const getIdentID = () => {
        return API.get(`/idnow/getident/${eventID}/${customFormFieldID}`)
            .then(response => response.json())
            .then(res => {
                if (res.success) {
                    setIdentID(res.result[0].identId);
                    return true;
                }
                return false;
            })
            .catch(err => {
                // setErr(true);
                return false;
            });
    };

    //callback loop till data not received
    const getIdentIDWithRetry = () => {
        setTimeout(() => {
            getIdentID().then(success => {
                if (!success) {
                    getIdentIDWithRetry(); // Retry if data was not received
                }
            });
        }, 1000); // Delay of 1 seconds
    };

    //get data from table by identid
    const getIdNowDataByIdentID = (identID: any) => {
        return API.get(`/idnow/getidentdata/${identID}`)
            .then(response => response.json())
            .then(res => {
                if (res.success) {
                    setIdentData(res.result[0].customerData);
                    return true;
                }
                return false;
            })
            .catch(err => {
                return false;
            });
    };

    //update age check
    const updateAgeCheck = () => {
        const payload = {
            identId: identID,
            ageCheck: ageCheck,
            underAge: underAge,
        };
        API.post('/idnow/agecheck', payload)
            .then(response => response.json())
            .then(res => {
                console.log('res', res);
            })
            .catch(err => {
                // setErr(true);
            });
    };

    //getting age check
    const getAgeCheck = () => {
        if (identData?.identificationprocess?.result && identData?.identificationprocess?.result === 'REVIEW_PENDING') {
            if (identData?.userdata?.birthday && identData?.userdata?.birthday?.value) {
                const today = new Date();
                const birthDate = new Date(identData?.userdata?.birthday?.value);
                let age = today.getFullYear() - birthDate.getFullYear();
                const monthDifference = today.getMonth() - birthDate.getMonth();
                if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }
                setAgeCheck(true);
                if (age > Number(fieldObject?.minimumAge)) {
                    setUnderAge(false);
                    setAgeCheckMsg(t('##AgeVerified'));
                } else {
                    setUnderAge(true);
                    setAgeCheckMsg(t('##AgeNotVerified'));
                }
                setLoadingAgeCheck(false);
            }
        } else {
            const result = identData?.identificationprocess?.result;
            const reason = identData?.identificationprocess?.reason;
            setAgeCheckMsg(`${result} Reason: ${reason}`);
            setLoadingAgeCheck(false);
            // setUnderAge(true);
        }
    };

    useEffect(() => {
        if (identData?.identificationprocess?.result && identData?.identificationprocess?.result === 'REVIEW_PENDING') {
            updateAgeCheck();
        }       
        onChange(field.config?._id, underAge);
    }, [ageCheck, underAge]);

    useEffect(() => {
        const fetchData = () => {
            setLoading(true);
            getIdNowDataByIdentID(identID).then(data => {
                if (data) {
                    setLoading(false);
                    setOpenDialog(false);
                    trackEvent('IDNow', 'IDNow CancelFlow', 'CancelIDNowFlow');
                } else {
                    setTimeout(fetchData, 40000);
                }
            });
        };

        if (typeof identID !== undefined && identID !== '') {
            setOpenDialog(true);

            if (Object.keys(identData).length == 0) {
                fetchData();
            }
        }
    }, [identID, identData]);

    useEffect(() => {
        getIdNowPluginData();
    }, []);

    useEffect(() => {
        if (Object.keys(identData).length > 0 && fieldObject?.isAgeCheck) {
            getAgeCheck();
        } else {
            setLoadingAgeCheck(false);
        }
    }, [identData]);

    return (
        <Box display='flex' gap={1} flexDirection='column'>
            <FormLabel id='radio-buttons-group-label1'>
                {field.config.slug ? t(field.config.slug) : field.config.name}
                {(!!requiredCondition || !!fieldObject?.isAgeCheck) && (
                    <Box component='span' sx={{ color: 'red' }}>
                        {'  '}({t('##TheFieldIsRequired')})
                    </Box>
                )}
            </FormLabel>
            <Box height='150px' display='flex' alignItems='center' gap={1}>
                {/* {!value && ( */}
                <>
                    <Button
                        disabled={isDisabled || loadingAgeCheck}
                        variant='contained'
                        color='secondary'
                        onClick={() => {
                            initIdentID();
                            trackEvent('IDNow', 'IDNow StartingFlow', 'StartinIDNowFlow');
                            setLoadingAgeCheck(true);
                        }}
                    >
                        {t(field.config.slug)}
                    </Button>
                    <Box display="flex" flexDirection="column" mt={1}> {/* Ensure the message appears below the button */}
                        <Typography variant="body2">
                            {ageCheckMsg.split('Reason:')[0]} {/* Display result */}
                        </Typography>
                        {ageCheckMsg.includes('Reason:') && (
                            <Typography variant="body2" color="textSecondary">
                                {t('##Reason')}: {ageCheckMsg.split('Reason:')[1]} {/* Display reason */}
                            </Typography>
                        )}
                    </Box>
                    {loadingAgeCheck ? <CircularProgress color='inherit' size={20} sx={{ marginLeft: '5px' }} /> : null}
                    <Dialog open={openDialog} fullWidth fullScreen disableEscapeKeyDown>
                        <DialogTitle
                            sx={{
                                backgroundColor: '#424242',
                            }}
                        >
                            <Grid
                                color='white'
                                container
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                <Grid>
                                    {t(field.config.slug)}
                                    {loading ? (
                                        <CircularProgress color='inherit' size={20} sx={{ marginLeft: '5px' }} />
                                    ) : null}
                                </Grid>
                                <Grid>
                                    <IconButton
                                        onClick={() => {
                                            setOpenDialog(false);
                                            trackEvent('IDNow', 'IDNow CancelFlow', 'CancelIDNowFlow');
                                            setIdentID('');
                                        }}
                                    >
                                        <CloseIcon sx={{ color: 'white' }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent dividers sx={{ display: 'flex', gap: 1 }}>
                            <Grid item xs={12}>
                                <Box>
                                    <iframe
                                        title='IDNowVerification'
                                        src={`${iframehost}${customer}/identifications/${identID}/identification/start`}
                                        style={{ width: '100vw', height: '800px' }}
                                        allow='camera *; microphone *'
                                        allowFullScreen={true}
                                    ></iframe>
                                </Box>
                            </Grid>
                        </DialogContent>
                        {/* <DialogActions>
                                <Button color='error' onClick={cancelFlow}>
                                    {t('##Cancel')}
                                </Button>
                            </DialogActions> */}
                    </Dialog>
                </>
                {/* )} */}

                {field.config?.toolTipTxt !== '' && (
                    <ToolTipComponent
                        key='image_tooltip'
                        enableHintOnClick={field.config.enableHintOnClick}
                        tooltipText={field.config.toolTipTxt}
                    />
                )}
            </Box>
        </Box>
    );
}

export default IDNowFieldComponent;
