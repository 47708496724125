import Dexie, { Table } from 'dexie';

class ProjectLocationsDB extends Dexie {
    projectLocations!: Table<DexieProjectLocation>;

    constructor() {
        super('ProjectLocationsDB');
        this.version(1).stores({
            projectLocations: `_id`,
        });
    }
}

export const newProjectLocationsDb = new ProjectLocationsDB();
