import React, { useState } from 'react';
import {
    Alert,
    AppBar,
    Backdrop,
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Toolbar,
    Typography,
} from '@mui/material';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import CancelIcon from '@mui/icons-material/Cancel';
import { trackEvent } from '../../components-app/GAListener';
import { useTranslation } from 'react-i18next';
import API from '../../config/api';
import EmpplanSnackbarWrapper, { EmpplanSnackbarContext } from '../Snackbar';

type ScannerProps = {
    onChange: (newValue: any, resultAfterScan?: any) => void;
};

function BarCodeScanner({ onChange }: ScannerProps) {
    const { t } = useTranslation();
    const snackbarTools = React.useContext(EmpplanSnackbarContext);
    const [open, setOpen] = useState(false);
    const [stopStream, setStopStream] = useState(false);
    const [err, setErr] = useState<Record<string, null | boolean | string | DOMException>>({ status: false, msg: null });
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setStopStream(true);
        setOpen(false);
    };

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        trackEvent('Scan', 'Scan Button', 'Button');
        setOpen(true);
    };
    const onUpdateValue = (err: any, result: any) => {
        if (result) {
            setLoading(true);
            const resultAfterScan = result.text || result.getText();
            API.get(`/products/search/list?ean=${resultAfterScan}`)
                .then(result => result.json())
                .then(data => {
                    if (Array.isArray(data) && data.length > 0) {
                        onChange && onChange(data, resultAfterScan);
                        setErr({ status: false, msg: null });
                    } else {
                        snackbarTools.createToast({ type: 'error', message: t('##CanNotFindAnyProductBasedOnScan') });
                        setErr({ status: true, msg: t('##CanNotFindAnyProductBasedOnScan') });
                    }
                })
                .catch(err => {
                    setErr({ status: true, msg: err.message || t('##ServerErrorOccuredWhileFetchingData') });
                })
                .finally(() => {
                    setLoading(false);
                });
            trackEvent('Scan', 'Scan Screen', 'Camera');
            handleClose();
        } else {
            setErr({ status: true, msg: t('##PleaseScanAValidBarCodeORQRCode') });
        }
    };

    return (
        <Box>
            <EmpplanSnackbarWrapper>
                <IconButton
                    onClick={event => handleOpen(event)}
                    size='small'
                    edge='start'
                    color='inherit'
                    aria-label='menu'
                >
                    <DocumentScannerIcon />
                </IconButton>
            </EmpplanSnackbarWrapper>

            <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color='inherit' />
            </Backdrop>

            <Dialog fullScreen fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <Box sx={{ flexGrow: 1 }}>
                        <AppBar position='static'>
                            <Toolbar>
                                <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
                                    {t('##QRORBARCodeScanner')}
                                </Typography>
                                <IconButton
                                    onClick={handleClose}
                                    size='large'
                                    edge='start'
                                    color='inherit'
                                    aria-label='menu'
                                >
                                    <CancelIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                    <Paper elevation={3} sx={{ height: 400 }}>
                        <>
                            <BarcodeScannerComponent
                                width='100%'
                                height='100%'
                                onUpdate={(err, result) => onUpdateValue(err, result)}
                                onError={(error: any) => {
                                    setErr({ status: true, msg: t('##PleaseProvideCameraAccess') });
                                }}
                                torch={true}
                                stopStream={stopStream}
                            />
                        </>
                    </Paper>

                    <Box>{err.status && <Alert severity='error'>{err.msg}</Alert>}</Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default BarCodeScanner;
