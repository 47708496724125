import { IProjectSchema } from "../../../resources/projects";

export type ReportType = 'event' | 'eventgroup' | 'transactionFixedProduct' | 'sales' | 'expense';

export function isCustomReportDisabled(
    reportType?: ReportType,
    eventState?: string,
    currentUserCanEditEventUser?: boolean,
    project?:IProjectSchema
): boolean {
    // this maybe weird but it makes the logic below easier to read
    const FormNotDisabled = false;
    const FormDisabled = true;

    

    if (eventState && reportType) {
        if (reportType === 'eventgroup') {
       
            if(project?.eventSettings?.isFieldManagement){
                if (['SalesReportUploaded', 'SalesReportAccepted'].includes(eventState||'')) {
                    if (currentUserCanEditEventUser) return FormNotDisabled;
                    return FormDisabled;
                }
                
            }
            if (currentUserCanEditEventUser) return FormNotDisabled;
            
            return FormNotDisabled;
        }
        if (eventState === 'SalesReportAccepted') return FormDisabled;
        if (['event', 'transactionFixedProduct', 'sales', 'expense'].includes(reportType||'')) {
            if (['LoggedIn', 'LoggedOut', 'SalesReportRejected', 'Pending','Accepted'].includes(eventState||'')) {
                return FormNotDisabled;
            } else {
                if (currentUserCanEditEventUser) return FormNotDisabled;
                return FormDisabled;
            }
        }
    }

    if (!(eventState && reportType)) {
        console.error(
            'Event state / report type not being passed to Custom Report Component, please check implementation',
        );
    }
    return FormDisabled;
}
