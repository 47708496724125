import {createContext, ReactNode, useContext, useEffect, useState} from 'react';
export interface ILoaderContext {
    loading: boolean;
    setLoading: (loading: boolean) => void;
}
export const LoaderContext = createContext<ILoaderContext>({
    loading: false,
    setLoading: () => {},
});
export const LoaderContextProvider = ({ children }: { children: ReactNode }) => {
    const [loading, setLoading] = useState(true);
    const [contextValue, setContextValue] = useState<ILoaderContext>({ loading, setLoading });
    useEffect(() => {
        setContextValue({ loading, setLoading });
    }, [loading]);
    return <LoaderContext.Provider value={contextValue}>{children}</LoaderContext.Provider>;
};

export const useLoader = () => useContext(LoaderContext);