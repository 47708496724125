import * as React from 'react';
import API from '../../config/api';
import { useTranslation } from 'react-i18next';
import BarCodeScanner from '../BarCodeScanner';
import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';

const productUrlGenerator = (searchText?: string, options?: any) => {
    /**
     * may be in future
     */
    let urlPath = `/products/search/list?name=${searchText}&active=true`;
    if (options?.filterProject) {
        if (options.filterProject?._id) {
            urlPath = urlPath + '&project=' + options.filterProject._id;
        } else {
            urlPath = urlPath + '&project=' + options.filterProject;
        }
    }
    return urlPath;
};

const expenseUrlGenerator = (searchText?: string, options?: any) => {
    /**
     * may be in future
     */
    // let filterClient = '';
    let urlPath = `/product?name=${searchText}&active=true&productType=Expenses`;
    if (options?.filterProject) {
        if (options.filterProject?._id) {
            urlPath = urlPath + '&project=' + options.filterProject._id;
        } else {
            urlPath = urlPath + '&project=' + options.filterProject;
        }
    }

    return urlPath;
};

const productTransactionUrlGenerator = (brandsArray: Array<string>) => (searchText?: string) => {
    // GET /product?active=true&brandsArray=5b955294bd2134001f764d7f&brandsArray=5be20760c0f6f5001f45ba8a&brandsArray=606f2e9d3fd52b00115de65a&nameandnumber=moo&productType=products
    return (
        `/product?active=true&limit=50&nameandnumber=${searchText}&productType=products` +
        brandsArray.map(b => `&brandsArray=${b}`).join('')
    );
};

interface AsynchronousAutocompleteProps {
    label: string;
    onChangeHandler?: any;
    filters: {
        filterProject?: string;
    };
    urlGenerator: (input: string, options?: {}) => string;
    initialValue?: any;
    multiple?: boolean;
    isDisabled?: boolean;
    showScanner?: boolean;
    preLoadingCompleted: boolean;
    disabledOptions?: Array<string>
}

function AsynchronousAutocomplete({
    label,
    onChangeHandler,
    filters,
    urlGenerator,
    initialValue,
    showScanner,
    preLoadingCompleted,
    multiple,
    isDisabled,
    disabledOptions
}: AsynchronousAutocompleteProps) {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [filteredProducts, setFilteredProducts] = React.useState<any>([]);
    const [value, setValue] = React.useState<any>(initialValue);
    const [inputValue, setInputValue] = React.useState('');

    React.useEffect(() => {
        if (!preLoadingCompleted) return;
        if (value && (inputValue === '' || inputValue === value.name)) {
            return;
        }
        let active = true;

        (async () => {
            setLoading && setLoading(true);
            let res;
            if (filters && filters?.filterProject) {
                res = await API.get(urlGenerator(inputValue, { filterProject: filters.filterProject }));
            } else {
                res = await API.get(urlGenerator(inputValue));
            }
            let productsList = await res.json();

            if (active && Array.isArray(productsList)) {
                if (disabledOptions && disabledOptions.length > 0) {
                    productsList = productsList.filter(o => {
                        if (o && o._id) {
                            return !disabledOptions.includes(o._id);
                        }
                        return true;
                    });
                }
                setFilteredProducts && setFilteredProducts(productsList);
                setLoading && setLoading(false);
            }
        })().catch(() => {
            setLoading && setLoading(false);
        });

        return () => {
            active = false;
        };
    }, [inputValue, preLoadingCompleted]);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <Autocomplete
            multiple={multiple ? true : false}
            fullWidth
            disabled={isDisabled}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={value}
            onChange={(event: any, newValue: any) => {
                setValue(newValue);
                onChangeHandler && onChangeHandler(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            getOptionLabel={option => `${option.name}`}
            options={filteredProducts}
            filterOptions={options => options}
            noOptionsText={
                inputValue.length < 3 ? t('##StartTypingToSearch') : loading ? `${t('##Loading')}...` : t('##NothingFound')
            }
            loading={loading}
            renderOption={(props, option) => (
                <Box {...props} key={option._id} component='li' display='flex' flexDirection='column'>
                    <Typography variant='body1' width='100%'>
                        {option.name}
                    </Typography>
                    {option.productGroup?.name && (
                        <Typography variant='body2' width='100%'>
                            {t('##ProductGroup')}: {option.productGroup?.name}
                        </Typography>
                    )}
                </Box>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    variant='standard'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                                {showScanner && (
                                    <BarCodeScanner
                                        onChange={(val, ean) => {
                                            if (val.length === 1) {
                                                setValue(val[0]);
                                                onChangeHandler && onChangeHandler(val[0]);
                                            } else {
                                                setFilteredProducts(val);
                                                setOpen(true);
                                            }
                                        }}
                                    />
                                )}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

interface ProductSelectProps {
    onChange?: any;
    initialValue?: any; // initial or current value
    projectId?: string;
    forTransaction?: boolean;
    type?: string;
    scanner?: boolean;
    multi?: boolean;
    disabled?: boolean;
    disabledOptions?: Array<string>
}
export interface Brand {
    _id: string;
    name: string;
}

function ProductSelect({ onChange, projectId, initialValue, forTransaction, type, scanner, multi, disabled, disabledOptions }: ProductSelectProps) {
    const { t } = useTranslation();

    const [brands, setBrands] = React.useState<Array<Brand>>([]);
    const [brandsLoaded, setBrandsLoaded] = React.useState<boolean>(false);

    const loadBrands = async () => {
        !brandsLoaded &&
            API.get(`/projects/project/get/brands/${projectId}`)
                .then(response => response.json())
                .then(data => {
                    setBrands && Array.isArray(data) && setBrands(data);
                    setBrandsLoaded(true);
                });
    };

    React.useEffect(() => {
        // load the brands if forTransaction
        if (forTransaction && projectId) {
            loadBrands();
        } else {
            setBrandsLoaded(true);
        }
    }, []);

    return (
        <Box display='flex' flexDirection='row'>
            <AsynchronousAutocomplete
                multiple={multi ? true : false}
                label={t('##SearchByProduct')}
                isDisabled={disabled}
                onChangeHandler={onChange}
                filters={projectId ? { filterProject: projectId } : {}}
                urlGenerator={
                    type && type === 'expense'
                        ? expenseUrlGenerator
                        : forTransaction
                        ? productTransactionUrlGenerator(brands.map(b => b._id))
                        : productUrlGenerator
                }
                initialValue={initialValue} // initial/current value
                showScanner={!!scanner}
                preLoadingCompleted={brandsLoaded}
                disabledOptions={disabledOptions}
            />
        </Box>
    );
}

export default ProductSelect;
