import React from 'react';
import { Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { t } from 'reactelements/node_modules/i18next';
import { useHistory } from 'react-router-dom';

function AddLocationContactHeader({ mode }: { mode?: string }) {
    const navigate = useHistory();
    return (
        <Box sx={{ backgroundColor: '#424242' }} height='45px' display='flex' alignItems='center' p='12px' gap={2}>
            <ArrowBackIcon sx={{ color: 'white' }} onClick={() => navigate.goBack()} />
            <Typography color='white'>{mode === 'edit' ? t('##EditLocationContact') : t('##AddLocationContact')}</Typography>
        </Box>
    );
}

export default AddLocationContactHeader;
