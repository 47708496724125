import {Reducer} from "redux";

import {
    CustomReportActions,
    CustomReportActionTypes,
    CustomReportResultResponse,
    CustomReportStateTypeContainer
} from "./CustomReportTypes";

export const initialEventContainerState:CustomReportStateTypeContainer = {
    loading:false,
    customReportResults: null,
    customReportLoadingIds:[]
};

export const CustomReportReducer: Reducer<CustomReportStateTypeContainer, CustomReportActions> = (
    state = initialEventContainerState,
    action
) => {

    /**
     * identify existing results! And push it into the array or update the array at position X
     * This wont work well, these actions wont update the react element!
     * SHoudl use splice, concat etc, pp
     *
     * These method should return operations todo for use with splice!
     *
     * Operators: 0, add new item!
     * Operators: 1 replace item
     * splice syntax: (PositionInteger, OperatorInteger, Element)
     */
    function findExistingResults(state:CustomReportResultResponse[]|null, actionResult:CustomReportResultResponse) {
        let copyState = state;
        let updateMode=false;
        let returnObject= {
            operator:0, //default add
            position:0, //default first
            value:null, //default null will do nothing!
        };
        if(copyState!==null){
            for(var i in copyState) {
                if(copyState[i].report !==null && actionResult.report!==null){

                    let actionEventId = (actionResult.report._event.hasOwnProperty('_id')?actionResult.report._event._id:actionResult.report._event);
                    let cpstateEventId = (copyState[i].report._event .hasOwnProperty('_id')?copyState[i].report._event._id:copyState[i].report._event._event);

                    if(copyState[i].report._customReport._refconfig.toString() ===
                        actionResult.report._customReport._refconfig.toString()
                        &&
                        actionEventId === cpstateEventId
                    ) {
                        //here is the eventId check missing!
                        copyState[i]=actionResult;
                        updateMode=true;
                        returnObject.operator = 1;
                        returnObject.position = parseInt(i);

                    }
                }

            }
        }
        if(updateMode===false && actionResult.report!==null){
            if(copyState!==null){
                //copyState.push(actionResult);
                returnObject.operator=0;
                returnObject.position=copyState.length;
            }
        }
        return returnObject;
        //return copyState;
    }

    switch (action.type) {
        //TODO CHANGE THE STATE
        case CustomReportActionTypes.CUSTOMREPORTLOADING: {
            return {
                ...state,
                loading:action.loading
            };
        }

        case CustomReportActionTypes.CUSTOMREPORTLOADINGIDADD: {
            let customReportLoadingIdsCopy = state.customReportLoadingIds;

            customReportLoadingIdsCopy.push(action.loadingId);
            return {
                ...state,
                loading:action.loading,
                customReportLoadingIds:customReportLoadingIdsCopy
            }
        }


        case CustomReportActionTypes.CLEARCUSTOMREPORTSTATE: {
            let tmpState=initialEventContainerState;
            return tmpState;
        }

        case CustomReportActionTypes.CUSTOMREPORTTEMPSAVE: {
            let resultTemp = action.reportResult;

            var  resultsTempContainer = state.customReportResults;
            let editOperator={position:0, operator:0};
            if(action.reportResult !== null ){
                if(resultsTempContainer===null) {
                    resultsTempContainer=[];
                }

                editOperator=findExistingResults(resultsTempContainer, action.reportResult);
                //bug! was here! WTF
                //resultsTempContainer.push(action.reportResult);
            }
            if(state.customReportResults !== null && action.reportResult!==null) {
                switch(editOperator.operator) {
                    case 0: {
                        return {
                            ...state,
                            customReportResults:state.customReportResults.concat(action.reportResult),
                            loading:false
                        }
                        break;
                    }
                    //update slice the old position and add a new one via concat!
                    case 1: {
                        return {
                            ...state,
                            customReportResults: [
                                ...state.customReportResults.slice(0,editOperator.position ),
                                action.reportResult,
                                ...state.customReportResults.slice(editOperator.position+1 ),
                            ],
                            loading:false
                        };
                        break;
                    }

                }

            } else if(action.reportResult!==null) {
                return {
                    ...state,
                    customReportResults:[action.reportResult],
                    loading:false
                }
            }

            break;
        }

        case CustomReportActionTypes.LOADRESULT: {
            var  resultsTempContainer = state.customReportResults;
            let editOperator={position:0, operator:0};
            if(action.reportResult !== null ){
                if(resultsTempContainer===null) {
                    resultsTempContainer=[];
                }

                editOperator=findExistingResults(resultsTempContainer, action.reportResult);
                //bug! was here! WTF
                //resultsTempContainer.push(action.reportResult);
            }
            if(state.customReportResults !== null && action.reportResult!==null) {
                switch(editOperator.operator) {
                    case 0: {
                        return {
                            ...state,
                            customReportResults:state.customReportResults.concat(action.reportResult),
                            loading:false
                        }
                        break;
                    }
                    //update slice the old position and add a new one via concat!
                    case 1: {
                        return {
                            ...state,
                            customReportResults:state.customReportResults.slice(editOperator.position).concat(action.reportResult),
                            loading:false
                        }
                        break;
                    }

                }

            } else if(action.reportResult!==null) {
                return {
                    ...state,
                    customReportResults:[action.reportResult],
                    loading:false
                }
            }

        }

    }
    return state;
}
