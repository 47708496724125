import { isFieldHidden } from './fieldVisibility';
import { requireHelper } from './requireHelper';
import {t} from "i18next";

type CustomReportValidatorFunction = (
    reportConfig: CustomReportConfig,
    reportData: ReportData,
    optionalValidationPayload?: OptionalValidationPayload,
) => ErrorObject;

type OptionalValidationPayload = {
    productId?: string | string[]; // required for checking product based conditions
    productGroup?:string;
    isFieldHiddenCustomCondition?:isFieldHiddenCustomCondition;
    areProductGroupsPresent?:boolean;
};

export type ErrorData = { [key: string]: Array<string> };

export type ErrorObject = { hasErrors: boolean; errors: ErrorData; errorCount: number };

export const validFieldTypes = [
    'Headline',
    'stringSmall',
    'number',
    'stringBig',
    'Time',
    'Checkbox',
    'Dropdown',
    'Slider',
    'upload',
    'Radio',
    'Date',
    'FreeText',
    'Signature'
];

let count =0

export const hasRegexErrorInCustomField = (fieldValue:string,fieldConfig:CustomReportFieldConfig,country?:string)=>{
    if(country && fieldConfig?.regexValidation?.perCountry){
        const cIndex = fieldConfig?.regexValidation.perCountry.findIndex(function (c) {
            return c.country === country;
        });
        if(cIndex !== -1){
            const regexValidator = new RegExp(fieldConfig.regexValidation.perCountry[cIndex].regex,fieldConfig.regexValidation.perCountry[cIndex].regexOption);
            return !regexValidator.test(fieldValue)?fieldConfig?.regexValidation.perCountry[cIndex].errorMessage || t('##Invalid') : false ;
        }
    }
    if(fieldConfig?.regexValidation?.regex){
        const regexValidator = new RegExp(fieldConfig?.regexValidation.regex,fieldConfig?.regexValidation.regexOption);
        return !regexValidator.test(fieldValue)?fieldConfig?.regexValidation.errorMessage || t('##Invalid') : false;
    }

    return false
}


const customReportValidator: CustomReportValidatorFunction = (reportConfig, reportData, optionalValidationPayload) => {
 
    const errors = {} as ErrorData;

    const validationIterator = (field:CustomReportField,reportData:ReportData,optionalValidationPayload?:OptionalValidationPayload) => {
        const fieldID = field.config._id;
        const fieldType = field.config.fieldType;
        const fieldValue = reportData[fieldID];
        let key = fieldID;
        if(optionalValidationPayload?.productGroup){
            delete errors[fieldID]
            key= key + "_" + optionalValidationPayload?.productGroup
        };
        
        errors[key] = [];

        // console.log({ fieldID, fieldType, fieldValue });

        const pushFieldError = (errorMessage: string) => {
           if(errors[key]){

               errors[key] = [...errors[key], errorMessage];
           }
        };

        // skip check if it's not an expected field type
        if (!validFieldTypes.includes(fieldType)) return;

        // skip checks for these fieldTypes
        if (['Headline'].includes(fieldType)) return;

        // skip check if visibilty is hidden and skip other checks
        // dont add more logic on this if cause same is used on the render to hide fields
        if (isFieldHidden(field, reportConfig, reportData, { productId: optionalValidationPayload?.productId, productGroup:optionalValidationPayload?.productGroup })) return;

        if (field?.visibility?.config?.multiplyForEachCondition && field?.visibility?.conditions?.productGroups?.length>0){
            Object.keys(reportData).forEach(productGroupId=>{
                let temp_field = JSON.parse(JSON.stringify(field));
               
                temp_field.visibility.config.multiplyForEachCondition = false;
                validationIterator(temp_field,reportData[productGroupId],{...optionalValidationPayload,productGroup:productGroupId})
            })
        }



        // other field checks
        switch (fieldType) {
            case 'stringSmall':
                if (
                    fieldValue &&
                    field.config?.fieldObject?.lengthMaximum &&
                    Number(field.config?.fieldObject?.lengthMaximum) > 0 &&
                    (fieldValue as string).length > Number(field.config?.fieldObject?.lengthMaximum)
                ) {
                    pushFieldError('Exceeds max length');
                }
                if (
                    field.config?.fieldObject?.lengthMinimum &&
                    Number(field.config?.fieldObject?.lengthMinimum) > 0 &&
                    (fieldValue as string)?.length < Number(field.config?.fieldObject?.lengthMinimum)
                ) {
                    pushFieldError('String smaller than limit');
                }

                if(hasRegexErrorInCustomField(fieldValue,field.config)){
                    pushFieldError('Regex Pattern Validation Failed')
                }
                break;

            case 'number':
                break;

            case 'stringBig':
                break;

            case 'Time':
                break;

            case 'Checkbox':
                break;

            case 'Dropdown':
                break;

            case 'Slider':
                break;

            case 'upload':
                break;

            case 'Radio':
                break;

            case 'Date':
                break;

            case 'FreeText':
                break;
            case 'Signature':
                break;
        }

        if (requireHelper(field, reportData)) {
            if (Array.isArray(fieldValue)) {
                if (fieldValue.length === 0) {
                    pushFieldError('This field is required');
                }
            } else if (!fieldValue) {
                pushFieldError('This field is required');
            }
        }

        return errors[key];
    }

    reportConfig.customFields.forEach((field)=>validationIterator(field,reportData,optionalValidationPayload));

    // check for errors on the fields parsed
    let hasErrors = false;
    let errorCount = 0;
    for (const errorKey in errors) {
        if (Object.prototype.hasOwnProperty.call(errors, errorKey)) {
            const errorArray = errors[errorKey];
            if (errorArray.length > 0) {
                hasErrors = true;
                errorCount++;
            }
        }
    }

    console.log(reportConfig.name,'Form errors [DEBUG]', { hasErrors, errors, errorCount });

    return { hasErrors, errors, errorCount };
};

export default customReportValidator;







