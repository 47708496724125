import { dexieDatabaseNames, dexieDatabases, syncDownIndexDbNames } from './dexieHelpers';
import { sendPayloadToGlitchGuruService } from './NewSync/actions';

export const clearAllDbSyncStatuses = () => {
    syncDownIndexDbNames.forEach(async dbName => {
        localStorage.removeItem('syncdown-status-' + dbName);
        // localStorage.removeItem('syncup-status-' + dbName);
    });
};

export const setDbSyncedDown = (dbName: string) => {
    localStorage.setItem('syncdown-status-' + dbName, Date.now().toString());
};

// export const setDbSyncedUp = (dbName: string) => {
//     localStorage.setItem('syncup-status-' + dbName, Date.now().toString());
// };

export const checkSyncDownIsComplete = () => {
    // const times: number[] = [];
    const allExist = syncDownIndexDbNames.every(dbName => {
        const val = localStorage.getItem('syncdown-status-' + dbName);
        // if (val) times.push(parseInt(val));
        return val;
    });
    return allExist;

    // // check if the difference between min and max time is not more than 5 minutes
    // if (allExist) {
    //     const min = Math.min(...times);
    //     const max = Math.max(...times);
    //     if (max - min < 300000) {
    //         return true;
    //     }
    // }
    // return false;
};

export const getAllSyncStatuses = () => {
    const statuses: { [key: string]: any } = {};
    try {
        syncDownIndexDbNames.forEach(dbName => {
            const syncdown = localStorage.getItem('syncdown-status-' + dbName);
            // const syncup = localStorage.getItem('syncup-status-' + dbName);
            statuses[dbName] = {
                down: syncdown,
                // up: syncup,
            };
        });
    } catch (error) {
        console.error(error);
    }
    return statuses;
};

export const downloadAllIndexdbDatabases = async (optionalPayload: any) => {
    try {
        let hasErrors = false;
        const hash = Date.now().toString();
        const payload: any = {
            syncTimestamps: getAllSyncStatuses(),
            version: window.pwaAppVersion,
            user: window.userabstraction,
            origin: window.location.origin,
            optionalPayload,
            allData: [],
        };

        const dbs = Object.keys(dexieDatabases) as dexieDatabaseNames[];
        for (let i = 0; i < dbs.length; i++) {
            const dbName = dbs[i];
            const dexieDb = dexieDatabases[dbName];
            for (let j = 0; j < dexieDb.tables.length; j++) {
                const table = dexieDb.tables[j];
                const data = await table.toArray();
                payload.allData.push({ dbName, table: table.name, data });
            }
        }

        const errorWhileReporting = () => {
            hasErrors = true;
        };

        // debugging
        // console.log('payload', payload);

        // report allData to glitchguru
        await sendPayloadToGlitchGuruService(Date.now().toString(), 'allData', JSON.stringify(payload), errorWhileReporting);

        return { hash: hasErrors ? '' : hash };
    } catch (error) {
        console.error(error);
        // window.alert('failed to upload all db data');
        return { hash: '' };
    }
};
