import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { Typography, Box } from '@mui/material';
import { t } from 'i18next';
import { PWAContext } from '../../../PWA/pwaContext';

interface AutocompleteWrapperType {
    isAutocompleteVisible: boolean;
    filteredList: any;
    innerValue: any;
    innerInputValue: string;
    setInnerInputValue: Dispatch<SetStateAction<string>>;
    setInnerValue: Dispatch<SetStateAction<IChipElement[] | undefined>>;
    setMultiFieldValue: () => void;
    deleteFromSteptwo: () => void;
    innerAutosuggestRef: any;
    disableRemoveFilter?: boolean;
}

export const AutocompleteWrapper = ({
    isAutocompleteVisible,
    filteredList,
    innerInputValue,
    innerValue,
    setInnerInputValue,
    setInnerValue,
    setMultiFieldValue,
    deleteFromSteptwo,
    innerAutosuggestRef,
    disableRemoveFilter = false,
}: AutocompleteWrapperType) => {
    const pwaContext = useContext(PWAContext);
    const textinputRef = React.useRef<any>(null);

    return (
        <div
            style={{
                ...(isAutocompleteVisible && {
                    visibility: 'visible',
                    width: 'auto',
                    height: 'auto',
                    margin: '0px',
                }),
                ...(!isAutocompleteVisible && {
                    visibility: 'hidden',
                    width: '0',
                    height: '0',
                    margin: '5px',
                }),
            }}
        >
            <Autocomplete
                ref={innerAutosuggestRef}
                limitTags={2}
                id='inner-multi-true'
                multiple
                freeSolo={true}
                autoHighlight
                options={filteredList}
                inputValue={innerInputValue}
                getOptionDisabled={option => option?.disabled}
                groupBy={option => option}
                value={innerValue}
                clearIcon={false}
                disableCloseOnSelect
                getOptionLabel={option => option.name}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        paddingRight: isAutocompleteVisible ? '20px !important' : 0,
                        borderTopRightRadius: '50px',
                        borderBottomRightRadius: '50px',
                        borderTopLeftRadius: '0px',
                        borderBottomLeftRadius: '0px',
                        marginBottom: '5px',
                        marginLeft: '2px',
                        borderColor: '#949E9E',
                    },
                    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#949E9E !important',
                    },
                    width: pwaContext?.pwa ? 250 : isAutocompleteVisible ? 450 : 0,
                }}
                onInputChange={(event, newInputValue) => {
                    setInnerInputValue(newInputValue);
                }}
                onChange={(event, newValue) => {
                    console.log('newValue', newValue);
                    setInnerValue(newValue);
                }}
                renderGroup={params => (
                    <Box key={params.key}>
                        {params.key == '0' && isAutocompleteVisible && (
                            <Typography textAlign={'right'} paddingRight={'5px'} color={'grey'} fontSize={10}>
                                {t('##CombinedResults')}
                            </Typography>
                        )}
                        {params.children}
                    </Box>
                )}
                renderInput={tparams => (
                    <TextField
                        {...tparams}
                        placeholder={''}
                        ref={textinputRef}
                        onKeyDown={(event: any) => {
                            if (event.key === 'Backspace' || event.key === 'Delete') {
                                event.stopPropagation();
                            }
                        }}
                        InputProps={{
                            ...tparams.InputProps,
                            sx: {
                                display: !isAutocompleteVisible ? 'none' : 'flex',
                            },
                            endAdornment: (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            right: '15%',
                                            display: isAutocompleteVisible ? 'inline' : 'none',
                                        }}
                                    >
                                        {' '}
                                        {tparams.InputProps.endAdornment}
                                    </div>
                                    <div>
                                        <CheckIcon
                                            style={{
                                                cursor: 'pointer',
                                                color: 'rgb(36, 192, 177)',
                                                display: isAutocompleteVisible ? 'inline' : 'none',
                                            }}
                                            onClick={setMultiFieldValue}
                                        />
                                        &nbsp;{' '}
                                        {!disableRemoveFilter && (
                                            <CancelIcon
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#949E9E',
                                                    display: isAutocompleteVisible ? 'inline' : 'none',
                                                }}
                                                onClick={()=> {deleteFromSteptwo()}}
                                            />
                                        )}
                                    </div>
                                </>
                            ),
                        }}
                    />
                )}
            />
        </div>
    );
};

export default AutocompleteWrapper;
