import React, { useContext } from 'react';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReportConfigurationContext } from '../index';
import ToolTipComponent from '../ToolTipComponent';
import { requireHelper } from '../utils/requireHelper';

export interface FreeTextComponentProps {
    field: CustomReportField;
    reportData: any;
    onChangeHandler: any;
}

export const FreeTextComponent = ({ field, reportData, onChangeHandler }: FreeTextComponentProps) => {
    const { t } = useTranslation();
    const { isDisabled } = useContext(ReportConfigurationContext);
    const requiredCondition = requireHelper(field, reportData);

    const fieldValue = reportData[field.config._id] as ReportFreeTextValue;

    return (
        <FormControl data-testid='freetestId'>
            <FormLabel id='radio-buttons-group-label'>
                {field.config.slug ? t(field.config.slug) : field.config.name}
                {!!requiredCondition && (
                    <Box component='span' sx={{ color: 'red' }}>
                        {'  '}({t('##TheFieldIsRequired')})
                    </Box>
                )}
                {field.config?.toolTipTxt !== '' && (
                    <ToolTipComponent
                        enableHintOnClick={field.config.enableHintOnClick}
                        tooltipText={field.config.toolTipTxt}
                    />
                )}
            </FormLabel>
            <RadioGroup aria-labelledby='radio-buttons-group-label' name='radio-buttons-group'>
                <Box sx={{ display: 'flex', flexDirection: field.showInSameLine ? 'row' : 'column' }}>
                    {field.config?.fieldObject?.options?.length > 0 &&
                        field.config?.fieldObject?.options?.map((radioItem: string) => {
                            return (
                                <FormControlLabel
                                    data-testid={radioItem}
                                    key={radioItem}
                                    label={t(radioItem)}
                                    value={radioItem}
                                    checked={radioItem === fieldValue?.value && fieldValue?.value !== '#FreeText#'}
                                    control={
                                        <Radio
                                            key={radioItem}
                                            name='value'
                                            disabled={isDisabled || field.isReadonly}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>{
                                                e.stopPropagation();
                                                onChangeHandler(field.config._id, {
                                                    value: e.target.value,
                                                    FreeText: fieldValue?.FreeText,
                                                })
                                            }}
                                        />
                                    }
                                />
                            );
                        })}

                    <FormControlLabel
                        data-testid='freeTextRadio'
                        label={
                            <TextField
                                data-testid='freeTextField'
                                variant='standard'
                                value={fieldValue?.FreeText}
                                disabled={fieldValue?.value !== '#FreeText#' || isDisabled}
                                onChange={event =>
                                    onChangeHandler(field.config._id, {
                                        value: '#FreeText#',
                                        FreeText: event.target.value,
                                    })
                                }
                                // sx={{
                                //     '& .MuiInputBase-root': {
                                //         height: 35,
                                //     },
                                //     '.Mui-disabled': {
                                //         background: '#fafafa',
                                //     },
                                //     input: {
                                //         background: 'white',
                                //         height: ' 10px',
                                //     },
                                // }}
                            />
                        }
                        value={fieldValue?.FreeText}
                        checked={fieldValue?.value === '#FreeText#'}
                        control={
                            <Radio
                                key='FreeText'
                                name='FreeText'
                                disabled={isDisabled || field.isReadonly}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    onChangeHandler(field.config._id, {
                                        value: '#FreeText#',
                                        FreeText: e.target.value,
                                    })
                                }
                            />
                        }
                    />

                    {(fieldValue?.value || fieldValue?.FreeText) && (
                        <Button
                            variant='text'
                            sx={{
                                color: '#24c0b1',
                                fontWeight: 'bold',
                                justifyContent: 'start',
                            }}
                            disabled={isDisabled || field.isReadonly}
                            onClick={() =>
                                onChangeHandler(field.config._id, { value: '', FreeText: fieldValue?.FreeText })
                            }
                        >
                            {t('##ClearInput')}
                        </Button>
                    )}
                </Box>
            </RadioGroup>
        </FormControl>
    );
};
