import EXIF from 'exif-js';

export const getLocationCoordinates = () => {
    return new Promise<{ latitude: number; longitude: number }>((resolve, reject) => {
        let userLocation = { latitude: 0, longitude: 0, accuracy: 0, enableHighAccuracy: false };
        if (navigator && navigator.geolocation) {
            let options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            };
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    //console.log('GPS location success', { position });

                    userLocation = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        accuracy: position.coords.accuracy,
                        enableHighAccuracy: true,
                    };
                    resolve(userLocation);
                },
                function (err) {
                    console.error('error getting gps location1', err);

                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            //console.log('GPS location success', { position });

                            userLocation = {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude,
                                accuracy: position.coords.accuracy,
                                enableHighAccuracy: false,
                            };
                            resolve(userLocation);
                        },
                        function (err) {
                            console.error('error getting gps location2', err);

                            reject(err);
                        },
                        {
                            timeout: 5000,
                        },
                    );
                },
                options,
            );
        } else {
            console.error('no navigator geolocation available');
            resolve(userLocation);
        }
    });
};

export const fileToBase64: (file: File) => Promise<string> = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });

export const minifyImage: (file: File) => Promise<File> = file => {
    return new Promise((res, rej) => {
        const reader = new FileReader();

        reader.onerror = error => rej(error);
        reader.onload = () => {
            if (!reader.result) return rej('failed to load image');
            const image = new Image();
            image.src = reader.result as string;

            image.onload = () => {
                const canvas = document.createElement('canvas');
                const maxDimension = window.empplanSettings?.imageOptimizeDimensionPWA || 1000;
                let width = image.width;
                let height = image.height;

                if (width > maxDimension || height > maxDimension) {
                    if (width > height) {
                        height *= maxDimension / width;
                        width = maxDimension;
                    } else {
                        width *= maxDimension / height;
                        height = maxDimension;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                if (!ctx) return rej('failed to minify image');
                ctx.drawImage(image, 0, 0, width, height);

                canvas.toBlob(blob => {
                    if (!blob) return rej('failed to write minified image');
                    const resizedFile = new File([blob], file.name, {
                        type: file.type,
                        lastModified: file.lastModified,
                    });

                    // Reapply EXIF data to the resized image
                    // EXIF.setData(resizedFile, exifData);

                    res(resizedFile);
                }, file.type);
            };
        };

        reader.readAsDataURL(file);
    });
};

export const detectBrowser = () => {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = 'Chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = 'Firefox';
    } else if (userAgent.match(/safari/i)) {
        browserName = 'Safari';
    } else if (userAgent.match(/opr\//i)) {
        browserName = 'Opera';
    } else if (userAgent.match(/edg/i)) {
        browserName = 'Edge';
    } else if (userAgent.match(/android/i)) {
        browserName = 'Android';
    } else if (userAgent.match(/iphone/i)) {
        browserName = 'iPhone';
    } else {
        browserName = 'Unknown';
    }

    return browserName;
};

export const getOS = () => {
    var uA = navigator.userAgent || navigator.vendor;
    if ((/iPad|iPhone|iPod/.test(uA) && !window?.stream) || (uA.includes('Mac') && 'ontouchend' in document)) return 'iOS';

    var i,
        os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
    for (i = 0; i < os.length; i++) if (new RegExp(os[i], 'i').test(uA)) return os[i];
};

export const getExif = (file: any) => {
    return new Promise((res, reg) => {
        try {
            EXIF.getData(file, function () {
                // @ts-ignore
                let exifData = EXIF.getAllTags(this);
                exifData = JSON.parse(JSON.stringify(exifData));
                // console.log(JSON.parse(JSON.stringify(exifData)));

                const finalExifData: any = {
                    gps: {},
                    exif: {},
                };

                for (const key in exifData) {
                    if (Object.prototype.hasOwnProperty.call(exifData, key)) {
                        const value = exifData[key];
                        if (key.startsWith('GPS')) {
                            finalExifData.gps[key] = value;
                        } else {
                            finalExifData.exif[key] = value;
                        }
                    }
                }

                res(finalExifData);
            });
        } catch (error) {
            console.error('Minify - Error parsing exif data', error);
        }
    });
};
