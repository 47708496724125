import { Box, CircularProgress, CircularProgressProps, Typography } from '@mui/material';

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number; size?: string | number; labelEnd?: string },
) {
    const labelEnd = props.labelEnd || '%';

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant='determinate' {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: props.size || undefined,
                    width: props.size || undefined,
                }}
            >
                <Typography variant='caption' component='div' color='text.secondary'>
                    {`${Math.round(props.value)}`}
                    {labelEnd}
                </Typography>
            </Box>
        </Box>
    );
}

export default CircularProgressWithLabel;
