import {
    GridColDef,
    GridRowHeightParams,
    GridRowParams,
    GridRenderCellParams,
    GridCellValue,
    GridCellParams,
    GridValueGetterParams,
    GridRowModel
} from "@mui/x-data-grid-pro";
import { ComponentType, memo, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../contexts/config';
import * as React$1 from "react";
import { IGridResource } from "../index";
import { Resource } from "@rest-hooks/rest";
import {UserAbstractionResource} from "../../../resources/UserAbstraction";
import {Cell} from "../components-renderers/Cell";

export interface IModelHook {
    (rows:any|undefined): {
        colDefs: GridColDef[]; // Column definitions
    };
}
interface _IResourceGridColDefHelper<T extends Resource, FieldKey extends string>  {
    field: FieldKey;
    valueGetter?:(params:GridValueGetterParams<FieldKey extends keyof T?T[FieldKey]:any,T>) => GridCellValue;
    valueSetter?:(params:{value: GridCellValue;row:GridRowModel<T>}) => GridRowModel<T>;
    valueParser?:(value: GridCellValue, params?: GridCellParams<FieldKey extends keyof T?T[FieldKey]:any,T>) => GridCellValue;
    renderHeader?:(params:{
        field: FieldKey | string;
        colDef: IResourceGridColDef<T>;
    }) => ReactNode;
    renderCell?: (params: GridRenderCellParams<FieldKey extends keyof T?T[FieldKey]:any,T>) =>  ReactNode
}
export type IResourceGridColDef<T extends Resource,FieldKey=Exclude<keyof T,symbol>> = FieldKey extends string
    ?  _IResourceGridColDefHelper<T, FieldKey> & Omit<GridColDef, keyof _IResourceGridColDefHelper<T, FieldKey>>
    : never

export type IGridCellRendererParams<T extends  Resource,Field extends string = string> = Parameters<Exclude<IResourceGridColDef<T,Field>['renderCell'],undefined>>[0]
export interface IModelHook2<T extends Resource> {
    (): {
        colDefs: IResourceGridColDef<T>[]; // Column definitions
        getRowClassName?: (row: GridRowParams) => string; // can be used to add custom class based on grid data, example implmetaion in `EventOverView` , and `User Peromance` table.
        getRowHeight?: (row: GridRowHeightParams) => number;
    };
}
export const columnDefaults = {
    resizable: true,
    filterable: false,
    sortable: false,
    width: 200,
} as const;
/**
 *  Build full column definition from default and saved column definitions
 */
export function  useExtendColDef(colDefs: GridColDef[], deps: any[] = []) {
    const { viewSavedSettings } = useContext(ConfigContext);
    const [columnDefinitions, setColumnDefinitions] = useState<GridColDef[]>([]);
    useEffect(() => {
        const newColDefs = colDefs.map(colDef => {
            const column: GridColDef = {
                ...columnDefaults,
                ...colDef,
            };
            if (viewSavedSettings?.settingObject?.columnWidthModel?.[column.field]) {
                column.width = viewSavedSettings.settingObject.columnWidthModel[column.field];
            }
            if (typeof column.renderCell === 'function') {
                const MemoizedRenderCell = memo(
                  column.renderCell as ComponentType<GridRenderCellParams>,
                  (prev, next) => prev.row === next.row,
                );
                column.renderCell = params => <MemoizedRenderCell {...params}></MemoizedRenderCell>;
            }
            return column;
        });
        setColumnDefinitions(newColDefs);
    }, [viewSavedSettings?.settingObject?.columnWidthModel, ...deps]);
    return columnDefinitions;
};
function getColumnSortedBySavedOrder<T extends Array<any>>(columnDefinitions: T, savedOrder: string[]) {
    return [...columnDefinitions].sort((a, b) => {
        const aIndex = savedOrder.indexOf(a.field);
        const bIndex = savedOrder.indexOf(b.field);
        return aIndex - bIndex;
    })
}

/**
 *  Build full column definition from default and saved column definitions
 */
export function  useExtendColDef2<T extends Resource>(colDefs: IResourceGridColDef<T>[], deps: any[] = []) {
    const { viewSavedSettings } = useContext(ConfigContext);
    const [columnDefinitions, setColumnDefinitions] = useState<IResourceGridColDef<T>[]>([]);
    useEffect(() => {
        const sortedColumns = getColumnSortedBySavedOrder(colDefs, viewSavedSettings?.settingObject?.columnOrderModel || []);
        const newColDefs = sortedColumns.map(colDef => {
            const column: IResourceGridColDef<T> = {
                ...columnDefaults,
                ...colDef,
            };
            if (viewSavedSettings?.settingObject?.columnWidthModel?.[column.field]) {
                column.width = viewSavedSettings.settingObject.columnWidthModel[column.field];
            }
            if (typeof column.renderCell === 'function') {
                const MemoizedRenderCell = memo(
                  column.renderCell as ComponentType<GridRenderCellParams>,
                  (prev, next) => prev.row === next.row,
                );
                column.renderCell = params => <MemoizedRenderCell {...params}></MemoizedRenderCell>;
            }
            else {
                column.renderCell = ({value}) => <Cell>{value}</Cell>
            }
            return column;
        });
        setColumnDefinitions(newColDefs);
    }, [viewSavedSettings?.settingObject?.columnWidthModel,viewSavedSettings?.settingObject?.columnOrderModel, ...deps]);
    return columnDefinitions;
}
