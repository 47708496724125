import React, { useContext } from 'react';
import { PWAContext } from '../../../PWA/pwaContext';
import { Channel } from '../types';
import { Link, Typography } from '@mui/material';
import { returnUserCanCreateEvent, returnUserCanCreateCRM } from '../utils/helpers';

function ChannelHeader({ channel, disableChannelNameLink }: { channel: Channel; disableChannelNameLink?: boolean }) {
    const pwaContext = useContext(PWAContext);

    if (disableChannelNameLink) {
        return (
            <Typography fontSize='medium' gutterBottom>
                <strong>{channel.name}</strong>
            </Typography>
        );
    }

    return (
        <>
            {channel.context && channel.context.contextObject === 'event' ? (
                <Link
                    target='_blank'
                    underline='hover'
                    fontSize='medium'
                    color='secondary'
                    onClick={(e: any) => e.stopPropagation()}
                    href={
                        pwaContext?.pwa
                            ? `#/event/${channel.context.contextObjectId}`
                            : `/#!/event/${channel.context.contextObjectId}`
                    }
                >
                    <strong>{channel.name}</strong>
                </Link>
            ) : channel.context &&
              channel.context.contextObject === "'eventGroup'" &&
              returnUserCanCreateEvent(window?.user?.roles) ? (
                <Link
                    target='_blank'
                    underline='hover'
                    fontSize='medium'
                    color='secondary'
                    onClick={(e: any) => e.stopPropagation()}
                    href={`/#!/eventgroup/${channel.context.contextObjectId}`}
                >
                    <strong>{channel.name}</strong>
                </Link>
            ) : channel.context &&
                channel.context.contextObject === "crm" ? (
                <Link
                    target='_blank'
                    underline='hover'
                    fontSize='medium'
                    color='secondary'
                    onClick={(e: any) => e.stopPropagation()}
                    href={`/#!/crm/${channel.context.contextObjectId}`}
                >
                    <strong>{channel.name}</strong>
                </Link>
            ) : (
                <Typography fontSize='medium' gutterBottom>
                    <strong>{channel.name}</strong>
                </Typography>
            )}
        </>
    );
}

export default ChannelHeader;
