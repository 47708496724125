import {Phone} from "@mui/icons-material";
import React from "react";
import {Typography, Link, Box} from "@mui/material";
import {EquallySpacedRow} from "../styled";

interface EmailViewProps {
  value: string;
}
export function PhoneView({value}: EmailViewProps) {
  return value ? <EquallySpacedRow>
    <Phone color={'primary'}/>
    <Link title={"click to call"} color={'primary'} underline={'hover'} href={`tel:${value}`}>
      <Typography >{value}</Typography>
    </Link>
  </EquallySpacedRow> : null
}