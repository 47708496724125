import joi from "joi"
import {isValidIBAN,electronicFormatIBAN,isValidBIC} from 'ibantools';

export const IbanExtension:joi.ExtensionFactory = (joi) => {

  return {
    base: joi.string(),
    type: 'string',
    messages: {
    'iban.invalid': '"{{#label}}" did not seem to be a valid IBAN value',
    'ibanSWIFTBIC.invalid': '"{{#label}}" did not seem to be a valid BIC (SWIFT code)',
  },
    rules: {
      iban: {
        method() {
           return this.$_addRule({name: 'iban'});
        },
        validate(value, {error,options}, args) {
          let newVal = value

              // If strict mode is NOT on, we can convert the input into the machine format
              // If strict mode is on, do not convert, which will throw errors if it's not already in machine format
              if (args.options.strict !== true) {
                newVal = electronicFormatIBAN(value)
              }

              if (isValidIBAN(newVal)) return newVal
              return error('iban.invalid');
      }
    },
    bic:{
      validate(value, {prefs, error,options}, args) {
        if (isValidBIC(value)) return value
        return error('string.invalidSWIFTBIC')
      },
    }
  }
}
}