import React, { useContext } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Moment } from 'moment/moment';
import { Box, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CheckBoxComponentProps } from './CheckBoxComponent';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ReportConfigurationContext } from '../index';
import ToolTipComponent from '../ToolTipComponent';
import { requireHelper } from '../utils/requireHelper';

export const DateComponent = ({ field, reportData, onChangeHandler }: CheckBoxComponentProps) => {
    const { t } = useTranslation();
    const { isDisabled } = useContext(ReportConfigurationContext);
    const requiredCondition = requireHelper(field, reportData);
    const handleDateChange = (newValue: Moment | null, key: any) => {
        if (newValue === undefined || newValue === null) {
            onChangeHandler(key, '');
        } else {
            onChangeHandler(key, newValue?.toISOString());
        }
    };

    let fromDate: any = undefined;

    if (typeof field.config.fieldObject?.useExactDate !== 'undefined' && field.config.fieldObject?.useExactDate === true) {
        if (
            typeof field.config.fieldObject?.startFromDate !== 'undefined' &&
            field.config.fieldObject?.startFromDate !== null &&
            field.config.fieldObject?.startFromDate !== ''
        ) {
            fromDate = field.config?.fieldObject?.startFromDate;
        }
    } else {
        if (
            typeof field.config.fieldObject?.startFromToday !== 'undefined' &&
            field.config.fieldObject?.startFromToday !== null &&
            field.config.fieldObject?.startFromToday !== ''
        ) {
            fromDate = moment().add(field.config.fieldObject?.startFromToday, 'Days');
        }
    }

    const labelElement = (
        <>
            {field.config.fieldObject.datePlaceHolder ? field.config.fieldObject.datePlaceHolder : t('##Date')}{' '}
            {!!requiredCondition && (
                <Box component='span' sx={{ color: 'red' }}>
                    {'  '}({t('##TheFieldIsRequired')})
                </Box>
            )}
        </>
    );

    return (
        <Box display='flex' alignItems='center'>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    minDate={fromDate}
                    value={reportData[field.config._id] ? moment(reportData[field.config._id]) : null}
                    format='DD.MM.YYYY'
                    disabled={isDisabled || field.isReadonly}
                    label={labelElement}
                    onChange={(newValue: Moment | null) => handleDateChange(newValue, field.config._id)}
                />
            </LocalizationProvider>
            {field.config?.toolTipTxt !== '' && (
                <ToolTipComponent
                    enableHintOnClick={field.config.enableHintOnClick}
                    tooltipText={field.config.toolTipTxt}
                />
            )}
        </Box>
    );
};
