import Dexie, { Table } from 'dexie';

class CustomReportResultsDB extends Dexie {
    customReportResults!: Table<DexieCustomReportResult>;

    constructor() {
        super('customReportResultsDB');
        this.version(1).stores({
            customReportResults: `_id, event, customReportId`,
        });
    }
}

export const newCustomReportResultDb = new CustomReportResultsDB();
