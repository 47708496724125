import newProjectActionTypes from './actions';

// INITIAL STATE
const initialState: ProjectStates = {
    loadedProjects: [],
    loadedProject: undefined,
    syncData: {
        state: 'READY',
        lastUpdate: null,
    },
};

// REDUCER

const projectReducer = (state = { ...initialState }, action: any) => {
    if (action.type === newProjectActionTypes.LOAD_PROJECT_LIST) {
        return { ...state, loadedProjects: action.payload };
    }
    if (action.type === newProjectActionTypes.LOAD_PROJECT) {
        return { ...state, loadedProject: action.payload };
    }
    if (action.type === newProjectActionTypes.UPDATE_PROJECT_SYNC_DATA) {
        return { ...state, syncData: action.payload };
    }
    if (action.type === newProjectActionTypes.CLEAR_PROJECT_LIST) {
        return { ...state, loadedProjects: [] };
    }

    return state;
};

export default projectReducer;
