import Dexie, { Table } from 'dexie';

class ProjectsDB extends Dexie {
    projects!: Table<DexieProject>;

    constructor() {
        super('projectsDB');
        this.version(1).stores({
            projects: `_id, project.name`,
        });
    }
}

export const newProjectDb = new ProjectsDB();
