import { LabelChip } from '../../../components-app/label.chip';
import { Cell } from '../../../components-ui/table/components-renderers/Cell';
import * as React from 'react';
export function EventStatusChipCell({ row: event }: any) {
    return (
        <Cell>
            <span className='userStatus'>
                <LabelChip>{event.eventState}</LabelChip>
            </span>
        </Cell>
    );
}
