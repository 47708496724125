import React, { useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Link, Typography } from '@mui/material';
import API from '../../../config/api';
import PointOfSaleAutocomplete from './PointOfSaleAutocomplete';
import { PWAContext } from '../../../PWA/pwaContext';
import { getLocationCoordinates } from '../../../hooks/useFileUpload/fileHelper';
import { EmpplanSnackbarContext } from '../../../modules/Snackbar';
import moment from 'moment';
import { getShortAddress } from '../../../components-app/location-view';
import { useTranslation } from 'react-i18next';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import LaunchIcon from '@mui/icons-material/Launch';
import { checkUsersLocationInsideRadius } from '../eventHelpers';
import DeleteEventComponent from './DeleteEventComponent';
import { IEvent } from '../../../utils/event';
import AddLocationForm from './AddLocationForm';
import PointOfSaleAutocompleteScroll from './PointOfSaleAutoCompleteScroll';

function TempExistingAreaEvents({
    eventGroupId,
    projectId,
    projectData,
    areaId,
    value,
    setValue,
    allowPromoterToAddNewLocation,
    createDirectEvent = null,
}: {
    eventGroupId: string;
    projectId: string;
    projectData: any;
    areaId: string;
    value: any[];
    setValue: React.Dispatch<React.SetStateAction<any[]>>;
    allowPromoterToAddNewLocation: boolean;
    createDirectEvent?: (() => void) | null;
}) {
    const pwaContext = React.useContext(PWAContext);
    const snackbarTools = React.useContext(EmpplanSnackbarContext);
    const [loadingState, setLoadingState] = React.useState<'done' | 'loading' | 'error'>('loading');
    const [relatedEvents, setRelatedEvents] = React.useState<Array<any>>([]);
    const [runningSave, setRunningSave] = React.useState<boolean>(false);
    const [shouldEventsBeCheckedIn, setShouldEventsBeCheckedIn] = React.useState<boolean>(false);
    const [pendingCount, setPendingCount] = React.useState(1);
    const eventState = ['Published', 'Rejected', 'Accepted', 'BrandPlanned', 'Pending'];
    const [openAddLocationDialog, setOpenAddLocationDialog] = useState(false);
    const [initialGooglePlace, setInitialGooglePlace] = useState<{
        places: google.maps.places.AutocompletePrediction[] | null;
        value: string;
    }>({
        places: null,
        value: '',
    });

    const { t } = useTranslation();

    React.useEffect(() => {
        const fetchSyncPendingCount = async () => {
            if (pwaContext?.pwa) {
                try {
                    const updatedCount = pwaContext?.pwaHelpers?.pendingSyncUploadsCount;
                    setPendingCount(updatedCount);
                } catch (error) {
                    console.error(error);
                }
            }
        };

        const timerId = setTimeout(fetchSyncPendingCount, 1000);

        return () => clearTimeout(timerId);
    }, [pwaContext?.pwaHelpers?.pendingSyncUploadsCount]);

    const loadRelatedEvents = async () => {
        try {
            const resp = await API.get(`event?eventGroupId=${eventGroupId}&order=date`);
            const jsonResp = await resp.json();
            // console.log({ jsonResp });
            if (Array.isArray(jsonResp)) setRelatedEvents(jsonResp);
            setLoadingState('done');
        } catch (error) {
            console.error('error loading related events for area event', error);
            setLoadingState('error');
        }
    };

    React.useEffect(() => {
        if (eventGroupId) {
            loadRelatedEvents();
        }
    }, [eventGroupId, pendingCount]);

    if (!eventGroupId && !createDirectEvent) {
        console.error('Failed to load existing events related to area event. Check implementation');
        return (
            <Typography key='relatedAreaEventsBadImplementation'>
                Failed to load existing events related to area event. Check implementation
            </Typography>
        );
    }

    if (loadingState === 'loading' && !createDirectEvent) {
        return <Typography key='relatedAreaEventsLoading'>{t('##LoadingExistingEvents')}</Typography>;
    }

    if (loadingState === 'error' && !createDirectEvent) {
        return <Typography key='relatedAreaEventsErrorLoading'>Error loading existing events</Typography>;
    }

    const createLocationVisits = async (posData: any[]) => {
        setRunningSave(true);

        const currentLocation: any = await getLocationCoordinates().catch(err => {
            console.error('Error fetching user location before creating location visits', err);
            return {
                latitude: 0,
                longitude: 0,
            };
        });

        const canUserCheckInNowIntoLocation = (pos: any) => {
            if (projectData?.checkinCondition?.forceNearByLocation && projectData.checkinCondition?.nearByKm > 0) {
                if (!pos.location || !Array.isArray(pos.location)) { 
                    return true;
                }
                if (!(currentLocation?.latitude && currentLocation?.longitude)) return false;
                return checkUsersLocationInsideRadius(
                    { lat: currentLocation?.latitude || 0, lon: currentLocation?.longitude || 0 },
                    { lat: pos.location?.[1], lon: pos.location?.[0] },
                    Number(projectData.checkinCondition?.nearByKm),
                    pos.location,
                );
            } else {
                return true;
            }
        };

        const posIds = posData.map(pos => ({
            _id: pos._id,
            canUserCheckInNow: canUserCheckInNowIntoLocation(pos),
        }));

        const allPromise: Promise<any>[] = [];

        for (const pos of posIds) {
            const payload = {
                day: new Date(),
                hours: moment().hours(),
                minutes: moment().minutes(),
                eventState: shouldEventsBeCheckedIn && pos.canUserCheckInNow ? 'LoggedIn' : 'Accepted',
                geoloc: {
                    latitude: currentLocation?.latitude || 0,
                    longitude: currentLocation?.longitude || 0,
                },
                disableDefaultTimes: true,
            };
            const innerPromise = API.post(`/event/reporting/new/${eventGroupId}/${pos._id}`, payload)
                .then(response => response.json())
                .then(result => {
                    // console.log(result, 'from server');
                });
            allPromise.push(innerPromise);
        }
        Promise.all(allPromise)
            .then(() => {
                snackbarTools.createToast({ type: 'success', message: 'Location Visit Added' });
            })
            .catch(err => {
                snackbarTools.createToast({ type: 'error', message: 'error adding location visit' });
            })
            .finally(() => {
                setRunningSave(false);
                if (pwaContext?.pwaHelpers?.startSync?.start) {
                    pwaContext.pwaHelpers?.startSync?.start();
                }
            });
    };

    const returnFirstCheckInTime = (event: IEvent) => {
        const checkin = event?.eventStateHistory?.find((item: any) => item.stateNew === 'LoggedIn');
        return checkin && checkin?.date ? moment(checkin?.date).format('HH:mm') : '';
    };

    const openLocationDialog = (googlePlaceValue: { places: google.maps.places.AutocompletePrediction[] | null; value: string }) => {
        setInitialGooglePlace(googlePlaceValue);
        setOpenAddLocationDialog(true);
    };
    const closeLocationDialog = () => {
        setOpenAddLocationDialog(false);
    };

    return (
        <Box width='100%'>
            {pwaContext?.pwa && pendingCount > 0 ? (
                <Typography>{t`##PleaseSyncYourDataToWorkWithAreaEvents`}</Typography>
            ) : (
                <>
                    {/* <PointOfSaleAutocomplete
                        projectId={projectId}
                        areaId={areaId}
                        value={value}
                        setValue={setValue}
                        openLocationDialog={openLocationDialog}
                        allowPromoterToAddNewLocation={allowPromoterToAddNewLocation}
                    /> */}
                    <PointOfSaleAutocompleteScroll
                        projectId={projectId}
                        areaId={areaId}
                        value={value}
                        setValue={setValue}
                        openLocationDialog={openLocationDialog}
                        allowPromoterToAddNewLocation={allowPromoterToAddNewLocation}
                    />
                    {openAddLocationDialog && (
                        <AddLocationForm
                            open={openAddLocationDialog}
                            close={closeLocationDialog}
                            areaId={areaId}
                            initialLocation={initialGooglePlace}
                            eventGroupId={eventGroupId}
                            projectId={projectId}
                            createDirectEvent={createDirectEvent}
                        />
                    )}
                    {!createDirectEvent && value.length > 0 && (
                        <Box display='flex' flexDirection='column'>
                            <Button
                                key='relatedAreaEventCreateEventsButton'
                                variant='contained'
                                disabled={runningSave}
                                fullWidth
                                sx={{ my: 1 }}
                                color='secondary'
                                onClick={() => createLocationVisits(value)}
                            >
                                {t`##AddReportingEvents`}
                            </Button>
                            <FormControlLabel
                                disabled={runningSave}
                                control={
                                    <Checkbox
                                        checked={shouldEventsBeCheckedIn}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setShouldEventsBeCheckedIn(event.target.checked);
                                        }}
                                    />
                                }
                                label={t('##CheckInLocationVisitsAlready')}
                            />
                        </Box>
                    )}
                </>
            )}
            <br />
            {relatedEvents.length > 0 ? (
                <Typography fontWeight='bold' fontSize='small' key='relatedAreaEventFound'>
                    {t('##AlreadyExistingEvents')}:
                </Typography>
            ) : (
                !createDirectEvent && (
                    <Typography fontWeight='bold' fontSize='small' key='relatedAreaEventNoneFound'>
                        {t('##NoExistingEventsFound')}
                    </Typography>
                )
            )}
            {relatedEvents.map(event =>
                event._id && event.point_of_sale ? (
                    <Button
                        LinkComponent={Link}
                        fullWidth
                        key={event._id}
                        color='secondary'
                        style={{
                            display: 'flex',
                            borderRadius: '40px',
                            background: 'rgb(36 192 177 / 20%)',
                            padding: '5px',
                        }}
                        sx={{ my: 0.5, p: 0, textTransform: 'none', justifyContent: 'start' }}
                    >
                        {event.eventState === 'LoggedIn' ? (
                            <>
                                <AccessTimeFilledIcon style={{ cursor: 'pointer', color: 'grey' }} sx={{ mr: 1 }} />
                                {returnFirstCheckInTime(event)}
                            </>
                        ) : (
                            ''
                        )}{' '}
                        &nbsp;&nbsp;
                        <a
                            style={{ textDecoration: 'none', color: 'rgb(36 192 177)' }}
                            href={
                                pwaContext?.areWeInPwa === 'yes' && pwaContext.pwa
                                    ? `#/event/${event._id}`
                                    : `/#!/event/${event._id}`
                            }
                        >
                            {getShortAddress(event.point_of_sale)}{' '}
                        </a>
                        {eventState.includes(event.eventState) ? (
                            <DeleteEventComponent eventId={event._id} setRunningSave={setRunningSave} />
                        ) : (
                            <a
                                style={{ marginLeft: 'auto', color: 'rgb(36 192 177)' }}
                                href={
                                    pwaContext?.areWeInPwa === 'yes' && pwaContext.pwa
                                        ? `#/event/${event._id}`
                                        : `/#!/event/${event._id}`
                                }
                            >
                                <LaunchIcon style={{ marginLeft: 'auto', paddingTop: '3px' }} />
                            </a>
                        )}
                    </Button>
                ) : null,
            )}
        </Box>
    );
}

export default TempExistingAreaEvents;
