import { RequestParams } from '../../../config/api';
export const urlTypes: { [key: string]: RequestParams } = {
    project: { url: '/projects/all/XXXX', params: { active: true } },
    distributionarea: { url: '/distributionarea', params: { active: true, orderBy: 'name' } },
    channel: { url: '/channel', params: { active: true } },
    brand: { url: '/brand', params: { active: true } },
    country: { url: '/countries/all', params: {} },
    locationgroups: { url: '/locationgroups', params: { loadsmall: true } },
    eventType: { url: '/eventType/search/list', params: { order: 'label', active: true } },
    eventLabel: { url: '/projects/customstatus/filter', params: { order: 'custom_status' } },
    user: { url: '/users/search/list', params: {} },
    location: { url: '/locations/search/list', params: { active: true } },
    product: { url: '/products/search/list', params: { active: true } },
    productgroup: { url: '/productGroup', params: { active: true } },
    leadStatusList: { url: '/crmCustomLeadStatusList', params: { active: true } },
    crmAddresslist: { url: '/crmsearch/list', params: {} },
    team:{url:'/teams',params:{}},
};
