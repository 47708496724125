import { MyLocation } from '@mui/icons-material';
import React from 'react';
import { Typography, Link, Box } from '@mui/material';
import { DivCenteredContent } from '../styled';

interface EmailViewProps {
    value: [number, number];
}
export function LongLatView({ value }: EmailViewProps) {
    return (
        !!value && (
            <DivCenteredContent title={value?.[1] + ',' + value?.[0] + ' 🔗'}>
                {/*    email icon*/}
                <MyLocation color={'primary'} />
                <Link
                    target={'_blank'}
                    color={'primary'}
                    underline={'hover'}
                    href={`https://www.google.com/maps/search/?api=1&query=${value?.[1] + ',' + value?.[0]}`}
                >
                    <Typography>{String(value?.[1]).slice(0, 5) + ',' + String(value?.[0]).slice(0, 5)}</Typography>
                </Link>
            </DivCenteredContent>
        )
    );
}

export function LatLongView({ value }: EmailViewProps) {
    return (
        !!value && (
            <DivCenteredContent title={value.join?.(',') + ' 🔗'}>
                {/*    email icon*/}
                <MyLocation color={'primary'} />
                <Link
                    target={'_blank'}
                    color={'primary'}
                    underline={'hover'}
                    href={`https://www.google.com/maps/search/?api=1&query=${value.join(',')}`}
                >
                    <Typography>{value.map(s => String(s).slice(0, 5)).join?.(',')}</Typography>
                </Link>
            </DivCenteredContent>
        )
    );
}
