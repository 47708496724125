import { isDeviceOnline } from './Services/OnlineStatus';

export const initPWAServiceWorker = () => {
    //add service worker!
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            loadServiceWorker();
        });
    }
};

export const checkAndLoadPWAServiceWorker = () => {
    if (window.registrationServiceWorker) {
        if (
            window.registrationServiceWorker.active?.scriptURL?.includes('app.sw.js') &&
            window.registrationServiceWorker.active?.state === 'activated' &&
            !window.registrationServiceWorker.waiting?.state
        ) {
            console.log('sw check - skipping');
            return;
        }
    }
    console.log('sw check - re registering');
    loadServiceWorker();
};

export const loadServiceWorker = () => {
    if ('serviceWorker' in navigator && document.readyState === 'complete') {
        var publicVapidKey = 'BH94MnlAKB4ijtgiyG79lFw7ZF2WkY92FxTTeK7i4c06zif1hzaakTHdz38o5aAFTLxJwT4ETjtkZCDbaj3Zalw';
        navigator.serviceWorker
            .register('/app.sw.js')
            .then(async registration => {
                console.log('SW registered: ', registration);
                window.registrationServiceWorker = registration;
                function shouldIShowBanner(): boolean {
                    var returnBoolean: boolean = false;
                    if (Notification) {
                        if (Notification.permission) {
                            if (Notification.permission === 'denied' || Notification.permission === 'default') {
                                returnBoolean = true;
                            }
                        }
                    }
                    return returnBoolean;
                }

                function urlBase64ToUint8Array(base64String: string) {
                    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
                    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

                    const rawData = window.atob(base64);
                    const outputArray = new Uint8Array(rawData.length);

                    for (let i = 0; i < rawData.length; ++i) {
                        outputArray[i] = rawData.charCodeAt(i);
                    }
                    return outputArray;
                }
                async function registerMe() {
                    const subscription = await registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        // The `urlBase64ToUint8Array()` function is the same as in
                        // https://www.npmjs.com/package/web-push#using-vapid-key-for-applicationserverkey
                        applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
                    });
                    console.log('Registered push');

                    console.log('Sending push');

                    isDeviceOnline() &&
                        (await fetch('/subscribe', {
                            method: 'POST',
                            body: JSON.stringify(subscription),
                            headers: {
                                'content-type': 'application/json',
                            },
                        }).catch(err => {
                            console.error('Error inside service worker subscribe user', err);
                        }));
                    console.log('Sent push');
                }
                if (shouldIShowBanner() === false) {
                    registerMe();
                }
            })
            .catch(registrationError => {
                console.log('SW registration failed: ', registrationError);
            });
    }
};
