/**
 * @author Kay Schneider
 * @copyright Kay Schneider 2019 / yakisch.com
 * @license
 */
import CONSTANTS from './Constants';
const SettingsUrl = '/api/settings/public/agency';

/**
 * should be stored one time!!
 *
 * @param url
 */
export const LoadAgencySettings = async (): Promise<AgencySettings> => {
    if (window.agencySettings?._id) {
        return window.agencySettings;
    } else {
        const response = await fetch(CONSTANTS.EMPPLAN_HOST + SettingsUrl, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        const agencySettings = await response.json();
        if (agencySettings?._id) {
            window.agencySettings = agencySettings;
        }
        return agencySettings;
    }
};
