import { AnyAction } from 'redux';
import newSyncDataActionTypes from './actions';

// INITIAL STATE
const initialState: SyncDataStates = {
    loadedSyncData: null,
    syncing: 'READY',
    syncLogs: [],
    syncErrors: [],
    syncTimes: {
        uploadTime: null,
        downloadTime: null,
    }
};

// REDUCER
const syncDataReducer = (state = { ...initialState }, action: AnyAction) => {
    if (action.type === newSyncDataActionTypes.LOAD_SYNCDATA) {
        return { ...state, loadedSyncData: action.payload };
    }
    if (action.type === newSyncDataActionTypes.RESET_SYNC_LOGS) {
        return { ...state, syncLogs: [], syncErrors: [] };
    }
    if (action.type === newSyncDataActionTypes.UPDATE_SYNCING) {
        return { ...state, syncing: action.payload };
    }
    if (action.type === newSyncDataActionTypes.ADD_SYNC_LOG) {
        return { ...state, syncLogs: [...state.syncLogs, action.payload] };
    }
    if (action.type === newSyncDataActionTypes.ADD_SYNC_ERROR) {
        return { ...state, syncErrors: [...state.syncErrors, action.payload] };
    }

    if (action.type === newSyncDataActionTypes.ADD_SYNC_DATA) {
        return {
            ...state, 
            syncTimes: {
                uploadTime: action.payload?.uploadTime || null,
                downloadTime: action.payload?.downloadTime || null,
            }
        };
    }
    if (action.type === newSyncDataActionTypes.START_SYNC_UPLOAD_TIME) {
        return {
            ...state,
            syncTimes: {
                ...state.syncTimes,
                uploadTime: action.payload
            }
        }
    }
    if (action.type === newSyncDataActionTypes.START_SYNC_DOWNLOAD_TIME) {
        return {
            ...state,
            syncTimes: {
                ...state.syncTimes,
                downloadTime: action.payload,
            }
        }
    }

    return state;
};

export default syncDataReducer;

// SELECTORS
// export const syncDataSelectors = {
//     pendingUploadsCount: (state: any) => {
//         return (
//             (state?.pendingSyncData?.salesItems || 0) +
//             (state?.pendingSyncData?.expenses || 0) +
//             (state?.pendingSyncData?.customReportResults || 0) +
//             (state?.pendingSyncData?.eventActions || 0)
//         );
//     },
// };
