import {copyToClipboard} from "../../utils";
import {CopyAllOutlined} from "@mui/icons-material";
import {Box,IconButton} from "@mui/material";
import {useState} from "react";

export interface IdentifierViewProps {
    value: string;
}
export function IdentifierView(props: IdentifierViewProps) {
  // copy value when clicked on copy icon
  const [showCopyButton, setShowCopyButton] = useState(false);
  const copy = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    copyToClipboard(JSON.stringify(props.value));

  }
  return <Box
      sx={{
        width:'100%',
        height:'100%',
        position:'relative',
        display:'flex',
        placeItems:'center',
      }}
      onMouseEnter={() => setShowCopyButton(true)}
      onMouseLeave={() => setShowCopyButton(false)}
    className="identifier" onClick={copy}>
      <Box
        component={'span'}
        sx={{
          width:'100%',
          height:'100%',
          display:'flex',
          placeItems:'center',
          placeContent:'center',
          backgroundColor:'#f5f5f5',
        }}
      >
        {props.value}
      </Box>
    <IconButton sx={{
      display: showCopyButton ? 'flex' : 'none',
      position:'absolute',
      right:0,
      background:'rgba(255,255,255,0.1)',
      borderRadius:'1px'
    }}>
      <CopyAllOutlined />
    </IconButton>

    </Box>
}