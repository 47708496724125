import React, { useContext, useEffect } from 'react';
import { Box, Collapse, Grid, GridSize, IconButton, Link, Stack, TextField, Typography } from '@mui/material';
import GpsFixed from '@mui/icons-material/GpsFixed';
import GroupWork from '@mui/icons-material/GroupWork';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Button } from '@mui/material'; // v4
//TODO: In future shift to @mui/material-icons - in v4 we use style={{}}, in v5 we use sx={{}}
// import GpsFixedIcon from '@mui/icons-material/GpsFixed';
// import GroupWorkIcon from '@mui/icons-material/GroupWork';
import ChipsViewEdit from '../../elements/Chips/ChipsView';
import DealerRankTable from '../../DealerRankTable';
import API from '../../../config/api';
import { useTranslation } from 'react-i18next';
import { PWAContext } from '../../../PWA/pwaContext';
import { hasAccess } from '../../../utils/helpers/roleHelper';

const INPUT_TYPES = ['text', 'number'];

/* TYPES */
// import { ILocationDetailViewProps } from '../types';
// import { IChipElement } from '../../elements/Chips/types';

/* CONSTANTS FOR TEST */
// import { LOCATION_DATA } from '../constants';

/**
 * Update the wiki when change something in code that will change the external API of this component!
 * wiki page:
 * https://git.madpat-services.com/empplan/empplan/-/wikis/development/reactToAngu/Location-details
 * Location Details for Event Group Edit view with product chips, location number
 * @param props
 */
export default function LocationDetailView(props: ILocationDetailViewProps) {
    const { t } = useTranslation();
    const [showMore, setShowMore] = React.useState(props.showMore);
    const SlugMapping = (typeof props.slugMapping === 'object' && props.slugMapping) || {};
    const pwaContext = useContext(PWAContext);

    const [dealerRankCollapse, setDealerRankCollapse] = React.useState(false);

    const [showDealerRankTable, setshowDealerRankTable] = React.useState(false);

    let linkUrl =
        'https://maps.google.com/?q=' +
        ((props.data?.address?.postal && props.data?.address?.postal + ' ') || '') +
        ((props.data?.address?.city && props.data?.address?.city + ' ') || '') +
        ((props.data?.address?.streetnumber && props.data?.address?.streetnumber + ' ') || '');
    const smallText = {
        fontSize: 'small',
    };
    const checkGridVal = (num_: any, def_: number): GridSize =>
        (Number.isInteger(num_) && num_ > 0 && num_ <= 12 && num_) || def_;
    const posDetailsCols = {
        xs: checkGridVal(props?.locDetailCols?.xs, 12),
        sm: checkGridVal(props?.locDetailCols?.sm, 6),
        md: checkGridVal(props?.locDetailCols?.sm, 4),
    };
    const posDetailsButtonText = props?.moreDropdownSlug || t('##LocationDetails');
    const hideProductGroupData = props?.hideProductGroups || false;
    const hideMoreBtn = props?.hideMoreButton || false;
    const renderFields = function (
        data_: ILocationDetailViewProps['data'],
        fieldsToRender_: ILocationDetailViewProps['fieldsToRender'],
    ) {
        if (Array.isArray(fieldsToRender_) && fieldsToRender_.length) {
            return fieldsToRender_.map((field_: IBrandedField, ix_) => {
                return (
                    <Grid
                        key={'fieldsToRenderLocaDetail' + ix_}
                        item
                        xs={posDetailsCols.xs || 6}
                        md={posDetailsCols.md || 4}
                        p={2}
                    >
                        <TextField
                            fullWidth
                            id={'' + (field_?._id || ix_)}
                            type={INPUT_TYPES.indexOf(field_?.fieldType) === -1 ? 'text' : field_.fieldType}
                            variant={'standard'}
                            value={(data_?.genericFields && data_?.genericFields[field_.fieldAttributeName]) || ''}
                            // label={t('##' + field_?.fieldLabelName) || ''}
                            label={t(field_?.fieldLabelName) || ''}
                            disabled={props.allowEdit !== true}
                            InputProps={{
                                readOnly: props.allowEdit !== true,
                            }}
                        />
                    </Grid>
                );
            });
        }
        return '';
    };
    /**
     * update selected chips
     */

    useEffect(() => {
        API.getReqestWithParams({ url: 'settings/empplan', params: {} }).then((response: any) => {
            setshowDealerRankTable(response?.showDealerRanksInfo);
        });
    }, []);

    const locationEditUser = hasAccess(['POSEdit']);

    return (
        <Box>
            <Grid container>
                <Grid item xs={12} sm={8}>
                    <Stack direction={'row'} spacing={2}>
                        <Box
                            sx={{
                                padding: 0,
                                margin: 0,
                            }}
                        >
                            <IconButton aria-label='gps-location' component='a' target={'_blank'} href={linkUrl}>
                                <GpsFixed color={'secondary'} />
                            </IconButton>
                        </Box>

                        <Box>
                            {!pwaContext?.pwa && <Typography>{t('##PointOfSale')}</Typography>}
                            {props.openLocationTab && locationEditUser ? (
                                <Link
                                    underline='none'
                                    color='secondary'
                                    href={`/#!/posmanagement/pointofsale/${props?.data?._id}/edit`}
                                    target='_blank'
                                >
                                    <Typography>{props.data?.name || props.data?.address?.company || ''}</Typography>
                                    <Typography>{props.data?.address?.streetnumber || ''}</Typography>
                                    <Typography>
                                        {props.data?.address?.postal || ''} {props.data?.address?.city || ''}
                                    </Typography>
                                </Link>
                            ) : (
                                <>
                                    <Typography>{props.data?.name || props.data?.address?.company || ''}</Typography>
                                    <Typography>{props.data?.address?.streetnumber || ''}</Typography>
                                    <Typography>
                                        {props.data?.address?.postal || ''} {props.data?.address?.city || ''}
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </Stack>
                </Grid>
                {!pwaContext?.pwa && (
                    <Grid item xs={4} md={2}>
                        <p style={smallText}>{t('##PointOfSaleNumber')}</p>
                        <Typography>{props.data?.number || ''}</Typography>
                    </Grid>
                )}
            </Grid>
            {!pwaContext?.pwa && !hideMoreBtn && (
                <Button onClick={() => setShowMore(!showMore)} color={'secondary'}>
                    {showMore !== true ? <ExpandMore /> : <ExpandLess />}
                    {t('' + posDetailsButtonText)}
                </Button>
            )}
            {!pwaContext?.pwa && !hideMoreBtn && showMore === true ? (
                <>
                    <Grid container>
                        {!hideProductGroupData && (
                            <Grid item xs={8} sm={12} p={1}>
                                <p style={smallText}>{t('##ProductGroups')}</p>

                                <Stack direction={'row'} spacing={1}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: 1,
                                            margin: 0,
                                        }}
                                    >
                                        <GroupWork />
                                    </Box>

                                    <ChipsViewEdit
                                        allowChipDelete={props.allowChipDelete || false}
                                        allowChipClick={props.allowChipClick || false}
                                        allowChipAdd={props.allowChipAdd || false}
                                        data={props.chipData || []}
                                        getChipLabel={
                                            props.getChipLabel || ((el: IChipElement): string => (el && el.name) || '')
                                        }
                                        noDefaltValue={false}
                                        handleChipClick={props.handleChipClick}
                                        handleChipDelete={props.handleChipClick}
                                        selectedChipsIds={props.selectedProductGroupChipsIds}
                                        setBgColorForSelectedOnly={props.setBgColorForSelectedOnly}
                                    />
                                </Stack>
                            </Grid>
                        )}
                        {renderFields(props.data, props.fieldsToRender)}
                    </Grid>

                    {showDealerRankTable && (
                        <Box>
                            {!hideMoreBtn && (
                                <Button onClick={() => setDealerRankCollapse(!dealerRankCollapse)} color={'secondary'}>
                                    {dealerRankCollapse !== true ? <ExpandMore /> : <ExpandLess />}
                                    {t('##DealerRanks')}
                                </Button>
                            )}
                            <Collapse in={dealerRankCollapse}>
                                <DealerRankTable
                                    data={{
                                        filters: { location: props?.data?._id },
                                        buildConfig: {
                                            hiddenColumns: ['.location'],
                                            view: 'DealerRankTable:EventGroupLocationDetails',
                                        },
                                    }}
                                    moduleName='DealerRank:EventGroupLocationDetails'
                                ></DealerRankTable>
                            </Collapse>
                        </Box>
                    )}
                </>
            ) : (
                ''
            )}
        </Box>
    );
}
