import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TransactionReport from 'reactelements/src/modules/TransactionReport';
import CustomerDataForm from 'reactelements/src/modules/CustomerDataForm';
import { clearForTransaction, loadForTransactionsAction } from '../../Models/NewProduct/actions';
import {
    clearProjectLocationForBrandsAction,
    loadProjectLocationForBrandsAction,
} from '../../Models/NewProjectLocations/actions';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import EventDetailTransactionList from './EventDetailTransactionList';
import { loadSalesItemListAction, clearSalesItemListAction } from '../../Models/NewSalesItem/actions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import API from 'reactelements/src/config/api';
import { EmpplanSnackbarContext } from 'reactelements/src/modules/Snackbar';
import { PWAContext } from 'reactelements/src/PWA/pwaContext';
import { loadCrmItemListAction } from '../../Models/NewCRM/actions';
import { DetectOnline } from 'reactelements/src/hooks/detectOnline';
import { checkOfflineUniqueness } from '../../Models/NewCRM/helpers';
import { useLocation } from 'react-router-dom';

export interface TransactionReportProps {
    eventAndProject: {
        eventId: string;
        projectId: any;
        eventState: string;
        pointOfSale: any;
        leadId?: string;
    };
    customReport?: CustomReportConfig;
    reference?: any;
}

function TransactionReportParserPWA({ eventAndProject, customReport, reference }: TransactionReportProps) {
    const { t } = useTranslation();
    const dispatch: any = useDispatch();
    const dexieProducts = useSelector((store: any) => store?.product?.forTransaction);
    const brands = useSelector((store: any) => store?.projectLocation?.loadedBrandsOfProject);
    const user = useSelector((store: any) => store?.User?.user);
    const salesItems = useSelector((store: any) => store?.salesItem?.loadedSalesItems);
    const crmItems = useSelector((store: any) => store?.crmItem?.loadedCrmItems);

    const { isOnline } = DetectOnline();
    const [currentUserCanEditEventUser, setCurrentUserCanEditEventUser] = useState<boolean>(false);
    const [updateSaleObject, setUpdateSaleObject] = useState(undefined);

    const [expandedPanel, setExpandedPanel] = useState('panel1');
    const [crmResetCount, setCrmResetCount] = useState<number>(0);
    const [crmData, setCrmData] = useState<any>({});
    const [uniqueCheck, setUniqueCheck] = React.useState<any>({});
    const crmConfig = customReport?.crmConfig;
    const snackbarTools = React.useContext(EmpplanSnackbarContext);
    const pwaContext = React.useContext(PWAContext);

    const location = useLocation();
    const [crmField, setCrmField] = useState<boolean>(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const crmAdded = params.get('crmadded');

        if (crmAdded === 'true' || crmItems.length > 0) {
            setCrmField(true);
            setExpandedPanel('panel2');
        }
    }, [location.search, crmItems]);

    const handleChange = (panel: any) => (event: any, isExpanded: any) => {
        setExpandedPanel(isExpanded ? panel : '');
    };

    useEffect(() => {
        if (eventAndProject?.projectId?.project?._id) {
            dispatch(loadProjectLocationForBrandsAction(eventAndProject?.projectId?.project?._id));
            dispatch(loadSalesItemListAction({ eventId: eventAndProject.eventId }));
            // dispatch(loadCrmItemListAction({ eventId: eventAndProject.eventId }));
            loadCrmItemListAction(dispatch, { eventId: eventAndProject.eventId });
        }

        return () => {
            dispatch(clearProjectLocationForBrandsAction());
        };
    }, []);

    useEffect(() => {
        if (Array.isArray(brands) && brands.length > 0) {
            dispatch(
                loadForTransactionsAction({
                    productType: 'PromotionProducts',
                    brandsArray: brands,
                }),
            );
        }

        return () => {
            dispatch(clearForTransaction());
        };
    }, [brands]);

    useEffect(() => {
        if (user && user?.user?.roles && Array.isArray(user?.user?.roles)) {
            const roles = user.user.roles;
            if (roles.includes('EventEdit') || roles.includes('currentUserCanEditEventUser')) {
                setCurrentUserCanEditEventUser(true);
            }
        }
    }, [user]);

    const updateCb = () => {
        //console.log('called', state);
        clearSalesItemListAction(dispatch);
        dispatch(loadSalesItemListAction({ eventId: eventAndProject.eventId }));
        // may be we need this update call back to render the table on later iteration
    };

    const allProducts = Array.isArray(dexieProducts) ? dexieProducts.map((each: DexieProduct) => each.product) : [];
    const transactionSalesitems = Array.isArray(salesItems)
        ? salesItems.filter(
              ({ salesItem: s }: any) =>
                  !(
                      s._customReport &&
                      s._customReport._configcreatedTime &&
                      s._customReport._configcreatedTime.type === 'transactionFixedProduct'
                  ),
          )
        : [];

    const handleUpdateSaleObj = (obj: any) => {
        const newobj = { ...obj };
        if (newobj?._id && !(newobj._customReport?._configcreatedTime?.type === 'transactionFixedProduct')) {
            if (typeof newobj.productId === 'string') {
                const prod = allProducts.find(prod => prod._id === newobj.productId);
                newobj.productId = prod;
            }
        }
        setUpdateSaleObject(newobj);
        if (customReport?.crmConfig?.isCRMForm) setExpandedPanel('panel2');
        reference.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        setUniqueCheck({
            firstName: crmConfig?.crm_firstnameUnique || false,
            lastName: crmConfig?.crm_lastnameUnique || false,
            email: crmConfig?.crm_emailUnique || false,
            mobile: crmConfig?.crm_mobileUnique || false,
            dateOfBirth: crmConfig?.crm_dobUnique || false,
            // projectId: crmConfig?.crm_projectUnique || false,
        });
    }, []);

    useEffect(() => {
        if (crmResetCount > 0) {
            setExpandedPanel('panel1');
        }
    }, [crmResetCount]);

    const crmDataSave = async () => {
        let uniqueCheckAll = true;
        let savedCrmId = '';

        const uniqueCheckObj: any = {};
        Object.entries(uniqueCheck).forEach(([key, value]) => {
            if (value) {
                // if(key=='projectId'){
                //     uniqueObj[key] == eventAndProject?.projectId?.project?._id
                // }else{
                uniqueCheckObj[key] = crmData[key];
                // }
            }
        });

        if (isOnline) {
            if (Object.keys(uniqueCheckObj).length > 0) {
                try {
                    const jsonResponse = await (await API.post(`/crm/uniqueCheck`, uniqueCheckObj)).json();
                    if (jsonResponse?.success && !jsonResponse?.unique) {
                        uniqueCheckAll = false;
                    }
                    if (!jsonResponse?.success) {
                        console.error(jsonResponse);
                    }
                } catch (error) {
                    console.error('crmDataSave uniqueness online check failed', error);
                }
            }
        } else {
            if (Object.keys(uniqueCheckObj).length > 0) {
                try {
                    const checkResult = await checkOfflineUniqueness(uniqueCheckObj);
                    if (checkResult === false) {
                        uniqueCheckAll = false;
                    }
                } catch (error) {
                    console.error('crmDataSave uniqueness offline dexie check failed', error);
                }
            }
        }

        if (uniqueCheckAll) {
            if (pwaContext?.pwa) {
                if (crmData._id) {
                    let cdata = {
                        ...crmData,
                        eventId: [eventAndProject.eventId],
                        customReportId: [customReport?._id],
                        transactionId: [],
                        projectId: [eventAndProject?.projectId?.project?._id],
                    };
                    console.log('cdata', cdata);

                    if (pwaContext.pwaHelpers?.crmItems?.update) {
                        const result = await pwaContext.pwaHelpers?.crmItems?.update(cdata);
                        if (result?.payload?._id) {
                            savedCrmId = result.payload._id as string;
                        }
                    }

                    setCrmResetCount(crmResetCount + 1);
                    snackbarTools.createToast({ type: 'success', message: t`##CRMAdded` });
                } else {
                    let cdata = {
                        ...crmData,
                        eventId: [eventAndProject.eventId],
                        customReportId: [customReport?._id],
                        transactionId: [],
                        projectId: [eventAndProject?.projectId?.project?._id],
                    };
                    console.log('cdata', cdata);

                    if (pwaContext.pwaHelpers?.crmItems?.add) {
                        const result = await pwaContext.pwaHelpers?.crmItems?.add(cdata);
                        if (result?.payload?._id) {
                            savedCrmId = result.payload._id as string;
                        }
                    }

                    setCrmResetCount(crmResetCount + 1);
                    snackbarTools.createToast({ type: 'success', message: t`##CRMAdded` });
                }
            } else {
                const cdata = {
                    ...crmData,
                    eventId: [eventAndProject.eventId],
                    customReportId: [customReport?._id],
                    transactionId: [],
                    projectId: [eventAndProject?.projectId?.project?._id],
                };
                // console.log('cdata',cdata);

                const res = await API.post(`/crm/save`, cdata);
                const results = await res.json();
                if (results.success) {
                    setCrmResetCount(crmResetCount + 1);
                    snackbarTools.createToast({ type: 'success', message: t`##CRMAdded` });
                    // savedCrmId = results.data._id;
                } else {
                    setCrmResetCount(crmResetCount + 1);
                    snackbarTools.createToast({ type: 'error', message: t`##CRMFailed` });
                }
            }
            return savedCrmId;
        } else {
            setCrmResetCount(crmResetCount + 1);
            return false;
        }
    };

    return (
        <React.Fragment>
            {customReport?.crmConfig?.isCRMForm ? (
                <React.Fragment key='transaction-crm-form'>
                    <Accordion expanded={expandedPanel === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon color='secondary' />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                        >
                            <Typography>1. {t('##CustomerData')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CustomerDataForm
                                key='customer-data-form'
                                customReports={[customReport] as CustomReportConfig[]}
                                crmItems={crmItems}
                                expandedPanel={expandedPanel}
                                setExpandedPanel={setExpandedPanel}
                                pointOfSale={eventAndProject.pointOfSale}
                                setCrmData={setCrmData}
                                reRender={crmResetCount}
                                isDirectCrm={false}
                                googleData={true}
                                crmAdded={crmField}
                                projectId={eventAndProject?.projectId?.project?._id}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expandedPanel === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon color='secondary' />}
                            aria-controls='panel2-content'
                            id='panel2-header'
                        >
                            <Typography>2. {t('##Products')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {allProducts.length > 0 ? (
                                <TransactionReport
                                    key='transaction-report'
                                    updateCallback={updateCb}
                                    eventState={eventAndProject.eventState}
                                    event={{
                                        _id: eventAndProject.eventId,
                                        project_id: eventAndProject.projectId?.project,
                                    }}
                                    customReports={[customReport] as CustomReportConfig[]}
                                    currentUserCanEditEventUser={currentUserCanEditEventUser}
                                    pwaOfflineOptions={allProducts}
                                    updateSaleObject={updateSaleObject}
                                    crmDataSave={crmDataSave}
                                    crmAdded={crmField}
                                />
                            ) : (
                                <Typography>{t('##NoProductsFound')}</Typography>
                            )}
                        </AccordionDetails>
                    </Accordion>
                    {transactionSalesitems.length > 0 && (
                        <EventDetailTransactionList
                            key='event-transaction-list'
                            handleUpdateSaleObj={handleUpdateSaleObj}
                            soldItems={transactionSalesitems}
                            eventId={eventAndProject.eventId}
                            setReRenderList={updateCb}
                            type='sales'
                            products={allProducts}
                        />
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment key='transaction-form'>
                    {allProducts.length > 0 ? (
                        <TransactionReport
                            key='transaction-report'
                            updateCallback={updateCb}
                            eventState={eventAndProject.eventState}
                            event={{ _id: eventAndProject.eventId, project_id: eventAndProject.projectId?.project }}
                            customReports={[customReport] as CustomReportConfig[]}
                            currentUserCanEditEventUser={currentUserCanEditEventUser}
                            pwaOfflineOptions={allProducts}
                            updateSaleObject={updateSaleObject}
                            crmAdded={crmField}
                        />
                    ) : (
                        <Typography>{t('##NoProductsFound')}</Typography>
                    )}
                    {transactionSalesitems.length > 0 && (
                        <EventDetailTransactionList
                            key='event-transaction-list'
                            handleUpdateSaleObj={handleUpdateSaleObj}
                            soldItems={transactionSalesitems}
                            eventId={eventAndProject.eventId}
                            setReRenderList={updateCb}
                            type='sales'
                            products={allProducts}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default TransactionReportParserPWA;
