import Dexie, { Table } from 'dexie';
import { DexieCRMItem } from './types';

class CRMDB extends Dexie {
    crmItems!: Table<DexieCRMItem>;

    constructor() {
        super('crmDB');
        this.version(1).stores({ crmItems: `_id, event` });
        this.version(2).stores({ crmItems: '_id, [lon+lat]' });
    }
}

export const newCRMDb = new CRMDB();
