import { AnyAction } from 'redux';
import newExpenseActionTypes from './actions';

// INITIAL STATE
const initialState: ExpenseStates = {
    loadedExpenses: [],
    loadedExpense: undefined,
    syncData: {
        state: 'READY',
        lastUpdate: null,
    },
};

// REDUCER

const expenseReducer = (state = { ...initialState }, action: AnyAction) => {
    if (action.type === newExpenseActionTypes.LOAD_EXPENSE_LIST) {
        return { ...state, loadedExpenses: action.payload };
    }
    if (action.type === newExpenseActionTypes.LOAD_EXPENSE) {
        return { ...state, loadedExpense: action.payload };
    }
    if (action.type === newExpenseActionTypes.UPDATE_EXPENSE_SYNC_DATA) {
        return { ...state, syncData: action.payload };
    }

    return state;
};

export default expenseReducer;
