import { newCustomModelFieldDb } from './newCustomModelFieldDexie';
import newCustomModelFieldActionTypes, { createNewCustomModelFieldAction } from './actions';
import DynTableConst from '../../Services/Constants';
import newSyncDataActionTypes from '../NewSync/actions';
import axios from 'axios';
import { setDbSyncedDown } from '../helpers';

const PaginationLimit = 20;

export const syncAllCustomModelFields = async (dispatch: any, projectIds: string) => {
    try {
        // drop the projects in the database
        await newCustomModelFieldDb.transaction('rw', newCustomModelFieldDb.tables, async () => {
            await Promise.all(newCustomModelFieldDb.tables.map(table => table.clear()));
        });

        // get the new projects and load them to the database
        let count = 1;
        for (let idx = 0; idx < count; idx += PaginationLimit) {
            const customModelFieldsResponse = await getCustomModelFieldsFetch(projectIds);
            if (!Array.isArray(customModelFieldsResponse)) throw new Error('Failed to sync custom model fields');
            // count = customModelFieldsResponse?.count || 0;
            // console.log({ idx, projectsResponse, count });

            Array.isArray(customModelFieldsResponse) &&
                customModelFieldsResponse.forEach((customModelField: any) => {
                    customModelField &&
                        customModelField._id &&
                        dispatch(
                            createNewCustomModelFieldAction({
                                _id: customModelField._id,
                                customModelField: customModelField,
                                syncDate: new Date(),
                            }),
                        );
                });

            dispatch({
                type: newSyncDataActionTypes.ADD_SYNC_LOG,
                payload: { action: 'Sync newCustomModelFieldDb', idx, count },
            });
        }

        dispatch({
            type: newCustomModelFieldActionTypes.UPDATE_CUSTOMMODELFIELD_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
        setDbSyncedDown('customModelFieldsDB');
    } catch (error) {
        console.log(error);
        dispatch({
            type: newSyncDataActionTypes.ADD_SYNC_ERROR,
            payload: error || 'Error handled but no log available',
        });
        dispatch({
            type: newCustomModelFieldActionTypes.UPDATE_CUSTOMMODELFIELD_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
    }
};

export const getCustomModelFieldsFetch = async (projectIds: string): Promise<any> => {
    return axios
        .post(
            DynTableConst.EMPPLAN_HOST + `/api/customModelField/field/pwa/sync?model=PointOfSale`,
            { project: projectIds },
            {
                withCredentials: true, // for local dev
                // for local dev
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            },
        )
        .then(response => response.data)
        .catch(err => {
            console.error(err);
            return { foo: 'broken' };
        });
};
