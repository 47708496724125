import { ActionCreator, AnyAction, Dispatch } from "redux";
import  SYSTEM_CONSTANTS from '../../Services/Constants';
import { ThunkAction } from "redux-thunk";
import {
    AcceptEventAction,
    EventActionTypes,
    EventCheckInAction, EventDetailPageIdAction, EventGetOneByIdAction,
    EventLoadingAction,
    EventStates,
    EventStateTypeContainer, GetAllMyEventsAction, GetTodaysEvents,
    SmallEvent
} from "./../Events/SmallEventType";
import {async} from "q";
import {MenuActionTypes, ModuleLoaded} from "../MenuStates/MenuStatesTypes";
import {Project, ProjectDocument} from "../ProjectInvitaions/ProjectInvitationTypes";
import {ProjectNativeLoadingAction, ProjectNativeStateContainer, ProjectTypes} from "./ProjectsTypes";
import {MeanUser, User} from "../UserStates/UserStatesTypes";
import Dexie from 'dexie';


type ProjectGetResponseSingle = {
    project:Project

}


interface IProjectInterface {
    _id?:string;
    project: Project;
    date:Date,
}

class ProjectStoreage extends Dexie {

    //typescript needs here an ! to instantiate it only in the super/parent class
    public projects!: Dexie.Table<IProjectInterface, number>;

    constructor() {
        super("emplProjects");
        //init the tables
        this.version(2).stores({
            projects: '_id, project, date',
        });
    }
}

/**
 //here we should store the return documents?
interface IProjectDocumentInterface {

}

class ProjectDocumentStore extends  Dexie {
    public projectDocuments!: Dexie.Table<>
}
 *
 */
const ProjectDexieInstance=new ProjectStoreage();


const loadProjectFromDexie= async(projectId:any):Promise<Project|null> => {
  return new Promise<Project|null>(( resolve,reject) => {
      ProjectDexieInstance.projects.get(projectId)
          .then((project)=> {
              if(typeof project!== 'undefined') {
                  resolve(project.project);
              } else {
                  reject(null)
              }
          });
          /**.catch((rejected) => {

          });**/
  });
};

/**const loadProjectDocumentsFromDexie = async (userId:any):Promise<ProjectDocument[]|null> => {
    return new Promise<Project|null>(( resolve,reject) => {
        ProjectDexieInstance.projects.get(projectId)
            .then((project)=> {
                if(typeof project!== 'undefined') {
                    resolve(project.project);
                } else {
                    reject(null)
                }
            });

    });
}**/

const addOrUpdateToDexie =async(projectId:any, project:Project) => {
    loadProjectFromDexie(projectId)
        .then((item)=> {
            //update
            ProjectDexieInstance.projects.update(projectId,{
                project:project,
                date:new Date()
            });
        }).catch((no)=>{
        //create
        ProjectDexieInstance.projects.add({
            _id:projectId,
            project:project,
            date:new Date()
        });
    });
};


export const loadProject = async(projectId:string):Promise<ProjectGetResponseSingle> =>{
    return new Promise<ProjectGetResponseSingle>((resolve,reject) => {
        var request = new XMLHttpRequest();
        request.open('GET', SYSTEM_CONSTANTS.EMPPLAN_HOST + "/projects/" + projectId +'?ts='+Date.now(), true);
        request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        request.withCredentials = true;
        request.onreadystatechange = function() {//Call a function when the state changes.
            if(request.readyState == 4 && request.status == 200) {
                const responseText = request.responseText;
                let jsonObject:any;
                try {
                    jsonObject = JSON.parse(responseText);
                }catch(e) {
                    console.log(e);
                    jsonObject={'foo':'broken'};
                }
                resolve(jsonObject);
            }
            if(request.status === 401 || request.status === 500 ) {
                reject();
            }
        };
        request.send();
    });
};

export const loadProjectDocument = async(userabstractionId:string):Promise<ProjectDocument[]> =>{
    return new Promise<ProjectDocument[]>((resolve,reject) => {
        var request = new XMLHttpRequest();
        request.open('GET', SYSTEM_CONSTANTS.EMPPLAN_HOST + "/projects/all/project/user/documents/" + userabstractionId +'?ts='+Date.now(), true); //prevent caching!
        request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        request.withCredentials = true;
        request.onreadystatechange = function() {//Call a function when the state changes.
            if(request.readyState == 4 && request.status == 200) {
                const responseText = request.responseText;
                let jsonObject:any;
                try {
                    jsonObject = JSON.parse(responseText);
                }catch(e) {
                    console.log(e);
                    jsonObject={'foo':'broken'};
                }
                resolve(jsonObject);
            }
            if(request.status === 401 || request.status === 500 ) {
                reject();
            }
        };
        request.send();
    });
};


const loading: ActionCreator<ProjectNativeLoadingAction> = (loadingState:boolean) => ({
    type: ProjectTypes.LOADING,
    loading:loadingState
});

//Load Documents for Users related Projects //
export const loadProjectDocumentsForUser:ActionCreator<ThunkAction<Promise<AnyAction>, ProjectNativeStateContainer, null, ProjectNativeLoadingAction>> = (userId:string) => {
    return async(dispatch: Dispatch) => {
        dispatch(loading(true));

        let returnStatement:ProjectDocument[]|null;
        returnStatement=null;
        let projectDocuments = await loadProjectDocument(userId);
        console.log(projectDocuments, ' loaded documents');
        if(Array.isArray(projectDocuments)) {
            if(projectDocuments.length!==0) {
                returnStatement=projectDocuments;
            }
        }

        /**try {
            let dexieProject = await loadProjectFromDexie(projectId);
            console.log(dexieProject, 'DEXIE');
            returnStatement = dexieProject;
        } catch(e) {
            //console.log(e, 'CATCH STATE;ENT');
            projectItem = await loadProject(projectId);
            if(projectItem.hasOwnProperty('project')) {
                returnStatement=projectItem.project;
                addOrUpdateToDexie(projectId, returnStatement);
                console.log('FOUND PROJEcTS');
            }else  {
                console.log('DIDNT FOUND PROJECTS');
            }
        }**/

        return (dispatch({
            projectDocuments:returnStatement,
            type:ProjectTypes.LOAD_USERS_PROJECT_DOCUMENTS
        }));
    };
};





export const getProjectById:ActionCreator<ThunkAction<Promise<AnyAction>, ProjectNativeStateContainer, null, ProjectNativeLoadingAction>> = (projectId:string) => {
    return async(dispatch: Dispatch) => {
        dispatch(loading(true));
        let returnStatement:Project|null;
        let projectItem:ProjectGetResponseSingle;
        returnStatement=null;
        try {
            let dexieProject = await loadProjectFromDexie(projectId);
            console.log(dexieProject, 'DEXIE');
            returnStatement = dexieProject;
        } catch(e) {
            //console.log(e, 'CATCH STATE;ENT');
            projectItem = await loadProject(projectId);
            if(projectItem.hasOwnProperty('project')) {
                returnStatement=projectItem.project;
                addOrUpdateToDexie(projectId, returnStatement);
                console.log('FOUND PROJEcTS');
            }else  {
                console.log('DIDNT FOUND PROJECTS');
            }
        }

        return (dispatch({
            project:returnStatement,
            type:ProjectTypes.LOADINGPROJECT
        }))
    };
};



