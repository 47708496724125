export const hasAccess = (requiredRoles: Array<string>, condition: 'and' | 'or' = 'or') => {
    //@ts-ignore
    const userRoles = window?.user?.roles||[] as Array<string>;

    if (requiredRoles.includes('admin')) {
        return true;
    }

    switch (condition) {
        case 'and':
            return requiredRoles.every(r => userRoles.includes(r));

        case 'or':
            return requiredRoles.some(r => userRoles.includes(r));
    }
};

export const hasEventAddRole = () => {
    return hasAccess(['EventAdd']);
};

export const hasDashboardViewRole = () => {
    return hasAccess(['DashboardView']);
};

export const hasDashboardEditRole = () => {
    return hasAccess(['DashboardEdit']);
};

export const hasDashboardAddRole = () => {
    return hasAccess(['DashboardAdd']);
};
