import React, { useState } from 'react';
import { Paper, Dialog, Button } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { styled } from '@mui/material/styles';
import { BootstrapDialogTitle } from './BootstrapDialogTitle';

interface IProps {
    link?: string;
    fileName?: string | undefined;
    sxcss?: any;
}

export const iOS = () => {
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
};

function PdfDisplayWithDialog({ link, fileName, sxcss }: IProps) {
    const [openViewMode, setOpenViewMode] = useState<boolean>(false);
    const isIOS = iOS();

    const handleOpenPopUpForPdf = (event: any) => {
        setOpenViewMode(true);
    };

    const handleClosePopUpForPdf = (event: any) => {
        setOpenViewMode(false);
    };

    return (
        <>
            <Paper sx={styles.uploadPaper} style={{ width: sxcss && sxcss.width ? sxcss.width : '300px' }}>
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={handleOpenPopUpForPdf}
                    startIcon={<PictureAsPdfIcon />}
                >
                    {fileName}
                </Button>
            </Paper>
            <BootstrapDialog
                fullScreen
                onClose={handleClosePopUpForPdf}
                aria-labelledby='customized-dialog-title'
                open={openViewMode}
            >
                <BootstrapDialogTitle
                    id='customized-dialog-title'
                    onClose={handleClosePopUpForPdf}
                    src={link}
                    name={fileName}
                >
                    {fileName}
                </BootstrapDialogTitle>
                <object
                    data={link}
                    type={isIOS ? undefined : 'application/pdf'}
                    style={styles.pdf}
                    aria-label='pdfRenderer'
                ></object>
            </BootstrapDialog>
        </>
    );
}

export default PdfDisplayWithDialog;

const styles = {
    uploadPaper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    pdf: {
        height: '100%',
        width: '100%',
    },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
