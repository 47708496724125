var UNIQUEKEYSREADY = 'UNIQUEKEYSREADY';

let PRODUCTION = true;

var DynTableConst = {
    BASE_DOMAIN_LOAD_LANGUAGE: '',
    UNIQUEKEYSREADY: 'UNIQUEKEYSREADY',
    UPDATETABLECONFIG: 'UPDATETABLECONFIG',
    TABLE_CONFIG_LOADED: 'TABLE_CONFIG_LOADED',
    LANGUAGE_PATH_REMOTE: '/i18n/assets/',
    //@ts-ignore
    //EMPPLAN_HOST: (PRODUCTION?'https://'+window.location.hostname:'http://'+window.location.hostname+':3002' ),
    // EMPPLAN_HOST: 'http://localhost:3000', // for local development
    EMPPLAN_HOST: window.location.origin, // for production build
    //@ts-ignore
    //EMPPLAN_HOST: (PRODUCTION?'https://'+window.location.hostname:'https://monk-batman.empplan.com' ),
    CONFIG_PATH_PREFIX: '/',
    CONFIG_CHANGED: 'CONFIGCHANGED',
    NEW_CONFIG_ACTIVE: 'NEW_CONFIG_ACTIVE',
    CHANGE_TO_STORED_CONFIG: 'CHANGE_TO_STORED_CONFIG',
    CHANGE_STATISTICS_PROJECT: 'CHANGE_PROJECT_STATISTICS',
    OPEN_DATE_MODAL: 'OPEN_DATE_MODAL',
    CLOSE_DATE_MODAL: 'CLOSE_DATE_MODAL',
    SELECT_DATE_MODAL: 'SELECT_DATE_MODAL',
    DEVMODE: false,
    NOLEAGALROUTES: ['forgot-password', 'register', 'reset', 'videoregister'],
    SENTRY_DSN: 'https://cfe2420088a847c18d30c319f4b7e405@o4504535852580864.ingest.sentry.io/4505590236184576',
};

export default DynTableConst;
