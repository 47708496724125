import {Reducer} from "redux";
import {EventActionTypes, EventStateTypeContainer, SmallEvent, SmallEventActions} from "./SmallEventType";

export const initialEventContainerState:EventStateTypeContainer = {
    loading:false,
    smallEvent:null,
    todayEventList:null,
    currentDetailPageEventId:null,
    currentDetailPageEvent:null,
    myEvents:null
};



export const SmallEventReducer: Reducer<EventStateTypeContainer, SmallEventActions> = (
    state = initialEventContainerState,
    action
) => {

    const sortInAllMyEvents = (state:EventStateTypeContainer, event:SmallEvent) => {
        const myEvents:SmallEvent[]|null=state.myEvents;

        if(myEvents!==null) {
            for(var i in myEvents)  {
                if(myEvents[i]._id ===event._id) {
                    myEvents[i]=event;
                    break;
                }
            }
        }
        return myEvents;
    };

    const sortEventIntoTodaysEvent = (state:EventStateTypeContainer, event:SmallEvent) => {
        const today:SmallEvent[]|null=state.todayEventList;

        if(today!==null) {
            for(var i in today)  {
                if(today[i]._id ===event._id) {
                    today[i]=event;
                }
            }
        }
        return today;
    };

    switch (action.type) {
        //TODO CHANGE THE STATE
        case EventActionTypes.LOADING: {
            return {
                ...state,
                loading: action.loading
            };
        }
        case EventActionTypes.CHECKIN: {
            let newEventList:SmallEvent[]|null=sortEventIntoTodaysEvent(state, action.event);
            let myEvents:SmallEvent[]|null=sortInAllMyEvents(state, action.event);
            return {
                ...state,
                smallEvent:action.event,
                todayEventList:newEventList,
                myEvents:myEvents,
                loading:false
            };
        }
        case EventActionTypes.CHECKOUT: {
            let newEventList:SmallEvent[]|null=sortEventIntoTodaysEvent(state, action.event);
            let myEvents:SmallEvent[]|null=sortInAllMyEvents(state, action.event);
            return {
                ...state,
                smallEvent:action.event,
                todayEventList:newEventList,
                myEvents:myEvents,
                loading:false
            };
        }
        case EventActionTypes.GETTODAYSEVENTS: {
            return {
                ...state,
                todayEventList:action.todayEventList,
                loading:false
            }
        }
        case EventActionTypes.ACCEPTEVENT: {
            let newEventList:SmallEvent[]|null=sortEventIntoTodaysEvent(state, action.event);
            let myEvents:SmallEvent[]|null=sortInAllMyEvents(state, action.event);
            return {
                ...state,
                smallEvent:action.event,
                todayEventList:newEventList,
                myEvents:myEvents,
                loading:false
            }
        }
        case EventActionTypes.REJECTEVENT: {
            let newEventList:SmallEvent[]|null=sortEventIntoTodaysEvent(state, action.event);
            let myEvents:SmallEvent[]|null=sortInAllMyEvents(state, action.event);
            return {
                ...state,
                smallEvent:action.event,
                todayEventList:newEventList,
                myEvents:myEvents,
                loading:false
            }
        }
        case EventActionTypes.EVENTDETAILPAGEID: {
            return {
                ...state,
                currentDetailPageEventId:action.currentDetailPageEventId
            }
        }
        case EventActionTypes.GETONEEVENTBYID: {
            return {
                ...state,
                currentDetailPageEvent:action.currentDetailPageEvent,
                loading:false
            }
        }
        case EventActionTypes.GETALLMYEVENTS: {
            return {
                ...state,
                myEvents:action.myEvents,
                loading:false
            }
        }
    }
    return state;
}
