import { newSalesItemDb } from './newSalesItemDexie';
import { newEventDb } from '../NewEvent/newEventsDexie';
import newSalesItemActionTypes, { createNewSalesItemAction } from './actions';
import DynTableConst from '../../Services/Constants';
import newSyncDataActionTypes from '../NewSync/actions';
import axios from 'axios';
import { setDbSyncedDown } from '../helpers';

const PaginationLimit = 20;

export const createNewSalesItemWrapperForDexie = (salesItem: any) =>
    ({
        _id: salesItem._id,
        salesItem: salesItem,
        syncDate: new Date(),
        event: salesItem.eventId,
        syncPending: 0,
        isNewDoc: 0,
    } as DexieSalesItem);

export const syncAllSalesItems = async (dispatch: any) => {
    try {
        // drop the projects in the database
        await newSalesItemDb.transaction('rw', newSalesItemDb.tables, async () => {
            await Promise.all(newSalesItemDb.tables.map(table => table.clear()));
        });

        const eventIds = await newEventDb.events
            .where('_id')
            .notEqual('')
            .uniqueKeys();

        // console.log({ eventIds });

        // get the new projects and load them to the database
        let count = 1;
        for (let idx = 0; idx < count; idx += PaginationLimit) {
            const salesItemsResponse = await getSalesItemsFetch(eventIds as Array<string>, idx);
            if (!salesItemsResponse.success) throw new Error('Failed to sync salesitems');
            count = salesItemsResponse?.count || 0;
            // console.log({ idx, projectsResponse, count });

            Array.isArray(salesItemsResponse?.data) &&
                salesItemsResponse.data.forEach((salesItem: any) => {
                    salesItem &&
                        salesItem._id &&
                        dispatch(createNewSalesItemAction(createNewSalesItemWrapperForDexie(salesItem)));
                });

            dispatch({
                type: newSyncDataActionTypes.ADD_SYNC_LOG,
                payload: { action: 'Sync newSalesItemDb', idx, count },
            });
        }

        dispatch({
            type: newSalesItemActionTypes.UPDATE_SALESITEM_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
        setDbSyncedDown('salesItemsDB');
    } catch (error) {
        console.log(error);
        dispatch({
            type: newSyncDataActionTypes.ADD_SYNC_ERROR,
            payload: error || 'Error handled but no log available',
        });
        dispatch({
            type: newSalesItemActionTypes.UPDATE_SALESITEM_SYNC_DATA,
            payload: {
                state: 'READY',
                lastUpdate: new Date(),
            },
        });
    }
};

export const getSalesItemsFetch = async (eventIds: Array<string>, skip: number): Promise<any> => {
    return axios
        .post(
            DynTableConst.EMPPLAN_HOST + `/salesItems/pwa/sync?limit=${PaginationLimit}&skip=${skip}`,
            { eventIds },
            {
                withCredentials: true, // for local dev
                // for local dev
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            },
        )
        .then(response => response.data)
        .catch(err => {
            console.error(err);
            return { foo: 'broken' };
        });
};

export const deleteSalesItemsFetch = async (salesItemId: string): Promise<any> => {
    return axios
        .delete(DynTableConst.EMPPLAN_HOST + `/salesItems/${salesItemId}`, {
            withCredentials: true, // for local dev
            // for local dev
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
        })
        .then(response => response.data)
        .catch(err => {
            console.error(err);
            return { foo: 'broken' };
        });
};
