import React, { useMemo, useState } from 'react';
import {
    Box,
    Avatar,
    Typography,
    Paper,
    Link,
    Grid,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemAvatar,
    ListItemText,
    LinearProgress,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
} from '@mui/material';
import { Channel, Message } from '../types';
import { t } from 'i18next';
import moment from 'moment';
import GenericFilePresentation from '../../../modules/GenericFilePresentation';
import DisplayElementWrapper from '../../../hooks/useFileUpload/DisplayElementWrapper';
import { detectSomeUrl, getYouTubeLink, isYoutubeLink, urlify } from '../utils/helpers';
import { useTranslation } from 'react-i18next';
import API from '../../../config/api';
import { hasEventAddRole } from '../../../utils/helpers/roleHelper';
import { IUser } from '../../../utils/event';
import ReplyIcon from '@mui/icons-material/Reply';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// import { PWAContext } from '../../../PWA/pwaContext';

const PROFILE_PIC_SIZE = 40;
const MESSAGE_HEADING_TEXT_SIZE = 10;

function SingleMessageBox({
    message,
    isReadOnly,
    setOpenChannel,
}: {
    message: Message;
    isReadOnly?: boolean;
    setOpenChannel?: (result: Channel) => void;
}) {
    // const pwaContext = React.useContext(PWAContext);
    const [openReadByDialog, setOpenReadByDialog] = React.useState<string | null>(null);
    const isMyMessage = message?.sender?._id === window?.userabstraction?._id;
    const isAdmin = useMemo(() => {
        const user = message?.sender._user as IUser;
        return user && user?.roles?.includes('EventAdd');
    }, [message]);
    const [backDropOpen, setBackDropOpen] = useState(false);

    const replayToAdmin = async (messageData: Message) => {
        setBackDropOpen(true);
        try {
            const currentUser = window?.userabstraction?.firstName + ' ' + window?.userabstraction?.lastName;
            const messagedUser = messageData?.sender?.firstName + ' ' + messageData?.sender?.lastName;
            const body = {
                name: messagedUser + ' x ' + currentUser,
                locationContact: messageData?.sender?._id,
                replayTo: true,
                replayToMessageId: messageData._id,
            };
            const response = await API.post('/chatchannel/add/promoterLocationContactChannel', body);
            const result = await response.json();
            if (result && result._id) {
                setOpenChannel && setOpenChannel(result);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setBackDropOpen(false);
        }
    };

    return (
        <>
            <Grid container p={2} gap={1} direction={isMyMessage ? 'row-reverse' : 'row'}>
                <Grid item xs='auto'>
                    {message?.sender?.profilePicture ? (
                        <Avatar
                            sx={{ width: PROFILE_PIC_SIZE, height: PROFILE_PIC_SIZE }}
                            alt={message?.sender?.firstName?.charAt(0)}
                            src={`/upload/files/getthumb/50x50/${message.sender.profilePicture}`}
                        />
                    ) : (
                        <Avatar sx={{ width: PROFILE_PIC_SIZE, height: PROFILE_PIC_SIZE }}>
                            {message?.sender?.firstName?.charAt(0)}
                        </Avatar>
                    )}
                </Grid>
                <Grid container item xs>
                    <Grid
                        item
                        xs={12}
                        mb={1}
                        minHeight={(PROFILE_PIC_SIZE + MESSAGE_HEADING_TEXT_SIZE) / 2}
                        display='flex'
                        alignItems='flex-end'
                    >
                        <Typography
                            variant='body2'
                            fontSize={MESSAGE_HEADING_TEXT_SIZE}
                            align={isMyMessage ? 'right' : 'left'}
                            width='100%'
                        >
                            {!isMyMessage && !message.readBy?.includes(window?.userabstraction?._id as string) && (
                                <Box
                                    sx={{
                                        display: 'inline-block',
                                        backgroundColor: '#d32294',
                                        color: 'white',
                                        borderRadius: '50%',
                                        width: '8px',
                                        height: '8px',
                                        mx: 1,
                                    }}
                                ></Box>
                            )}
                            {isMyMessage ? (
                                <>{t`##Me`}</>
                            ) : (
                                <>
                                    {message.sender.firstName} {message.sender.lastName}
                                </>
                            )}{' '}
                            | {moment(message.created).format('DD.MM.YYYY | HH:mm')}
                            {
                                <Box
                                    hidden={isReadOnly && !hasEventAddRole()}
                                    component='span'
                                    sx={theme => ({ color: theme.palette.secondary.main })}
                                    onClick={() => {
                                        setOpenReadByDialog(message._id);
                                    }}
                                >
                                    {' '}
                                    | {t('##ReadBy')} {message.readBy?.length || 0}
                                </Box>
                            }
                            {!isMyMessage && (isAdmin || window?.user?.roles?.includes('EventAdd')) && (
                                <IconButton aria-label='reply' color='secondary' onClick={() => replayToAdmin(message)}>
                                    <ReplyIcon />
                                </IconButton>
                            )}
                        </Typography>
                        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={backDropOpen}>
                            <CircularProgress color='inherit' />
                        </Backdrop>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper
                            elevation={0}
                            sx={{
                                width: 'auto',
                                p: 2,
                                borderRadius: '10px',
                                borderTopLeftRadius: isMyMessage ? undefined : 0,
                                borderTopRightRadius: isMyMessage ? 0 : undefined,
                            }}
                        >
                            {message.subject && (
                                <Typography gutterBottom>
                                    <strong> {message.subject}</strong>
                                </Typography>
                            )}

                            {/* do action button here */}
                            {message.type && message.message && message.type[0] === 'mail' ? (
                                <div
                                    style={{ wordWrap: 'break-word', maxWidth: '100%' }}
                                    className='message-content'
                                    dangerouslySetInnerHTML={{ __html: t(message.message) }}
                                ></div>
                            ) : null}

                            {message.type && message.message && message.type[0] === 'text' ? (
                                detectSomeUrl(message.message) ? (
                                    isYoutubeLink(message.message) ? (
                                        <>
                                            <Link
                                                color='secondary'
                                                href={urlify(message.message)}
                                                target='_blank'
                                                underline='always'
                                            >
                                                {message.message}
                                            </Link>
                                            <iframe
                                                src={getYouTubeLink(message.message)}
                                                width='100%'
                                                height='700'
                                                frameBorder='0'
                                                title='Youtube video'
                                                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                                                allowFullScreen
                                            ></iframe>
                                        </>
                                    ) : (
                                        <Link
                                            sx={{
                                                overflowY: 'auto',
                                                wordWrap: 'break-word',
                                                whiteSpace: 'pre-wrap',
                                            }}
                                            color='secondary'
                                            href={urlify(message.message)}
                                            target='_blank'
                                            underline='always'
                                        >
                                            {message.message}
                                        </Link>
                                    )
                                ) : (
                                    <Typography
                                        sx={{
                                            maxWidth: '100%',
                                            overflowY: 'auto',
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {message.message}
                                    </Typography>
                                )
                            ) : null}

                            {message.type && message.message && message.type[0] === 'file' ? (
                                <DisplayElementWrapper
                                    key={message.message}
                                    height='150px'
                                    isReadOnly={false}
                                    link={`/upload/files/get/${message.message}`}
                                >
                                    <GenericFilePresentation file={message.message} sxcss={{ width: '100%' }} />
                                </DisplayElementWrapper>
                            ) : null}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            {openReadByDialog && (
                <MessageReadByDialog
                    key={'message-read-by-dialog-' + message._id}
                    messageId={openReadByDialog}
                    setOpenReadByDialog={setOpenReadByDialog}
                />
            )}
        </>
    );
}

function MessageReadByDialog({ messageId, setOpenReadByDialog }: { messageId: string; setOpenReadByDialog: any }) {
    const { t } = useTranslation();
    const isAdmin = hasEventAddRole();
    const [loading, setLoading] = React.useState(true);
    const [messageDetails, setMessageDetails] = React.useState<any>(null);
    const handleClose = () => {
        setOpenReadByDialog && setOpenReadByDialog(null);
    };

    React.useEffect(() => {
        loadMessageDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageId]);

    const loadMessageDetails = async () => {
        try {
            const response = await API.get(`/chatmessages/messagedetail/${messageId}`);
            const jsonData = await response.json();
            if (jsonData.success && jsonData.data?._id && Array.isArray(jsonData.data.readBy)) {
                setMessageDetails && setMessageDetails(jsonData.data);
            } else {
                throw new Error('malformed data');
            }
        } catch (error) {
            console.error('Error fetching message details', error);
        }
        setLoading(false);
    };

    return (
        <Dialog onClose={handleClose} open fullWidth>
            <DialogTitle sx={{ textTransform: 'capitalize' }}>{t`##ReadBy`}</DialogTitle>
            <DialogContent>
                {loading && <LinearProgress />}
                {!loading && (
                    <List sx={{ pt: 0 }}>
                        {messageDetails?.readBy?.length > 0 &&
                            messageDetails.readBy.map((user: any) => (
                                <ListItem disableGutters key={user._id}>
                                    <ListItemButton onClick={() => {}}>
                                        <ListItemAvatar>
                                            {user?.profilePicture ? (
                                                <Avatar
                                                    sx={{ width: PROFILE_PIC_SIZE, height: PROFILE_PIC_SIZE }}
                                                    alt={user?.firstName?.charAt(0)}
                                                    src={`/upload/files/getthumb/50x50/${user.profilePicture}`}
                                                />
                                            ) : (
                                                <Avatar sx={{ width: PROFILE_PIC_SIZE, height: PROFILE_PIC_SIZE }}>
                                                    {user?.firstName?.charAt(0)}
                                                </Avatar>
                                            )}
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={user.firstName + user.lastName}
                                            secondary={isAdmin ? user.email : undefined}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                    </List>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t`##Close`}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default SingleMessageBox;
