import {GridColDef, useGridApiContext} from "@mui/x-data-grid-pro";
import {useContext, useEffect} from "react";
import {ColumnsPanelContext} from "../table/contexts/columns";
import {InnerGrid, InnerGridProps} from "./innerGrid";

export interface IObjectViewProps {
  value: Record<string, any>;
  options?: {
    keyPath: string
    allColumns: GridColDef[];
    aggregateColumn: GridColDef;
    aggregator: (value: any) => any;
  }
}
const baseOfKeyPath = (keyPath:string) => keyPath.split('.').pop()
export function ObjectView({value, options}: IObjectViewProps) {
  const {collapsedColumnsModel,columnsWidthModel} = useContext(ColumnsPanelContext)
  const gridApi = useGridApiContext()
  const columnState = collapsedColumnsModel.has(options?.keyPath as string) ? 'collapsed' : 'expanded'
  let innerGridOptions:InnerGridProps;
  if(collapsedColumnsModel.has(options?.keyPath as string)) {
    try {
      // @ts-ignore
      const aggregatedValue = {[baseOfKeyPath(options?.aggregateColumn?.field as string)]:options?.aggregator(value)};
      
      innerGridOptions ={
        value:aggregatedValue,
        options: {
          columns: [options?.aggregateColumn].filter(c=>c) as GridColDef[],
          keyPath: options?.keyPath || ''
        }
      }
    } catch (e) {
      innerGridOptions ={
        value: {error:"Error!"},
        options: {
          columns: [options?.aggregateColumn].filter(c=>c) as GridColDef[],
          keyPath: options?.keyPath || ''
        }
      }
    }
  } else {
    innerGridOptions ={
      value,
      options: {
        columns: options?.allColumns as GridColDef[],
        keyPath: options?.keyPath || ''
      }
    }
  }
  if(!innerGridOptions.options?.columns) debugger
  return <InnerGrid {...innerGridOptions}/>
}