import ReactGA from 'react-ga4';

const GA_KEY = 'G-SCYGJE2BYH';

export const GAInitialize = () => {
    if (!window.reactGA) {
        window.reactGA = ReactGA;

        if (!window.location.href.includes('localhost')) {
            window.reactGA.initialize(GA_KEY);
        }

        if (window.userabstraction?._id) window.reactGA?.set({ userId: window.userabstraction._id });

        console.log('Initialized google analytics');
    }
};

export const trackEvent = (category: any, action: any, label: any) => {
    try {
        window.reactGA?.event({ category, action, label });
    } catch (error) {
        console.error('Error traching GA event', error);
    }
};
