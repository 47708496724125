import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import EmpplanSelect from '../EmpplanFormComponents/EmpplanSelect';
import EmpplanTextField from '../EmpplanFormComponents/EmpplanTextField';
import EmpplanCheckbox from '../EmpplanFormComponents/EmpplanCheckbox';
import EmpplanRadioGroup from '../EmpplanFormComponents/EmpplanRadio';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import SignatureComponent from '../CustomReport/FormComponents/SignatureComponent';
import { ReportConfigurationContext } from '../CustomReport';
import { PWAContext } from '../../../src/PWA/pwaContext';
import useCountries from '../../hooks/useCountries';
import GenericCustomModelFields from '../GenericCustomModelFields';
import API from '../../config/api';
import { LocationByDistri } from '../../pages/MyEvents/components/types';
import { DetectOnline } from '../../hooks/detectOnline';
import { ICustomStatuses } from '../SideBarContentForEvents/types';

type crmItems = {
    crmItem: any;
    _id: string;
};
interface IProps {
    customReports: CustomReportConfig[];
    crmItems: crmItems[];
    expandedPanel: string | boolean | null;
    setExpandedPanel: (key: any) => void;
    pointOfSale: LocationByDistri | string;
    setCrmData: (crmItems: any, leadStatusData?: ICustomStatuses) => void;
    reRender: number | boolean;
    isDirectCrm: boolean;
    googleData: boolean;
    crmAdded: boolean;
    projectId: string;
    forEditingCB?: boolean | (() => void);
    leadDetails?: {
        forLeadDetails: boolean;
        disableButton: boolean;
    };
    dispatch?: any;
    useSelector? : any;
}

function CustomerDataForm({
    customReports,
    crmItems,
    expandedPanel,
    setExpandedPanel,
    pointOfSale,
    setCrmData,
    reRender,
    isDirectCrm,
    googleData,
    crmAdded,
    projectId,
    forEditingCB = false,
    leadDetails,
    dispatch,
    useSelector,
}: IProps) {
    const { t } = useTranslation();
    const { isOnline } = DetectOnline();

    const {
        handleSubmit,
        watch,
        control,
        formState: { errors },
        setError,
        setValue,
        reset,
    } = useForm<any>({
        defaultValues: {
            formOfAddress: '',
            firstname: '',
            lastname: '',
            email: '',
            mobile: '',
            phone: '',
            dateofbirth: '',
            dataProtectionAccepted: undefined,
            dataProtectionContent: '',
            country: '',
            company: '',
            streetnumber: '',
            streetname:'',
            additional: '',
            postal: '',
            city: '',
            loc: [],
            overtakeeventlocation: false,
            datapolicylink: false,
        },
        mode: 'all',
        reValidateMode: 'onChange',
    });

    const { countries: countryList } = useCountries();
    const [reportDatas, setReportDatas] = React.useState<ReportData>({});
    const [taskTracker, setTaskTracker] = React.useState<Array<any>>([]);
    const [formState, setFormState] = React.useState<'resetting' | 'ready' | 'saved' | 'changed' | 'saving' | 'error'>(
        'ready',
    );
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(false);
    const [filteredLabels, setFilteredLabels] = React.useState<any>([]);
    const [genericFields, setGenericFields] = useState([]);

    const FormOfAddress = ['##MaleFormOfAddress', '##FemaleFormOfAddress', '##DiversFormOfAddress'];
    const crmConfig = customReports[0]?.crmConfig;
    const data = watch();
    const pwaContext = React.useContext(PWAContext);

    const isFirstRender = useRef(true);
    const config = {
        forceGps: pwaContext?.pwa ? false : true,
        uploadTypeCapture: pwaContext?.pwa ? false : true,
        preferMetaGpsLocationData: pwaContext?.pwa ? false : true,
    };

    const signatureField = {
        _id: '659e2c307006bf00340cc6c9',
        canHasBonus: false,
        clientVisible: false,
        config: {
            __v: 0,
            _id: '659e2c307006bf00340cc6c9',
            active: true,
            created: '2024-01-10T05:33:36.454Z',
            description: '',
            enableHintOnClick: true,
            fieldObject: {
                options: [],
                config: config,
            },
            fieldType: 'Signature',
            name: '',
            slug: '',
            toolTipTxt: '',
        },
        customLabel: [],
        disableForStates: [],
        enableReportForm: false,
        hideFieldAfterReportSubmitted: false,
        hideLabel: false,
        hintText: '',
        hintTextColor: '',
        isReadonly: false,
        layout: { cols: 1, rows: 1, inline: 100 },
        pdf: true,
        relatedReport: null,
        required: false,
        requiredByCondition: false,
        requiredByFieldId: null,
        showInSameLine: false,
        visibility: {
            conditions: {
                hideForDealerRanks: [],
                productGroups: [],
                products: [],
            },
            config: {
                groupByConditions: false,
                multiplyForEachCondition: false,
            },
        },
    };

    const onChangeHandler = (key: ReportFieldKey, value: ReportFieldValue) => {
        setReportDatas(s => ({ ...s, [key]: value }));
        setFormState(s => 'changed');
    };

    const taskControlHandler = {
        addTask: (s: string) => {
            setTaskTracker(t => [...t.filter(t => t !== s), s]);
        },
        removeTask: (s: string) => {
            setTaskTracker(t => t.filter(t => t !== s));
        },
    };

    const [leadType, setLeadType] = useState<string>('');
    const [leadStatusType, setLeadStatusType] = useState<ICustomStatuses[]>([]);
    const [leadStatusData, setLeadStatusData] = useState<ICustomStatuses | undefined>(undefined);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const res = await API.get(`/crmCustomLeadStatusList`);
            let leadStaList: ICustomStatuses[] = await res.json();

            if (Array.isArray(leadStaList) && leadStaList.length > 0) {
                leadStaList = (() => {
                    const hasValidSortOrder = leadStaList.some(
                        (c: any) => c.sortOrder !== null && c.sortOrder !== 0
                    );
                
                    const mappedList = leadStaList.map((c: any) => ({
                        value: c._id,
                        label: c.statusLabel
                            ? c.type === 'EMPPLAN'
                                ? t('' + c.slangText)
                                : t('' + c.statusLabel)
                            : t('' + c.custom_status),
                        ...c,
                    }));
                
                    return hasValidSortOrder
                        ? mappedList
                        : mappedList.sort((a: { label: string }, b: { label: any }) => a.label.localeCompare(b.label));
                })();
                setLeadStatusType(leadStaList);
                const status = crmItems?.[0]?.crmItem?.status;
                setLeadType(
                    status?._id
                        ? status._id
                        : status
                        ? status
                        : leadStaList?.find(lead => lead.slangText === '##NotYetVisited')?._id,
                );
                setLoading(false);
            }
        })().catch(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setLeadStatusData(leadStatusType?.find(lead => leadType === lead?._id));
    }, [leadType]);

    const extractStreetNumber = (fullStreetNumber: string) => {
        // Regular expression to handle various German street number formats
        if (!fullStreetNumber) return { streetNumber: 0, appendix: '' };
        fullStreetNumber = fullStreetNumber + '';
        const streetNumberMatch = fullStreetNumber.match(/^(\d+)([A-Za-z0-9/-]*\S*)?$/);

        if (streetNumberMatch) {
            const streetNumber = streetNumberMatch[1];
            const appendix = streetNumberMatch[2] || '';
            return { streetNumber, appendix };
        } else {
            return { streetNumber: parseInt(fullStreetNumber), appendix: '' };
        }
    };

    const onSubmit = async (data: any) => {
        if (isDirectCrm || forEditingCB) {
            const resultStreetNumber = extractStreetNumber(data?.streetnumber);
            const crmsavedata = {
                formOfAddress: data.formOfAddress,
                firstName: data.firstname,
                lastName: data.lastname,
                email: data.email,
                mobile: data.mobile,
                tel: data.phone,
                dateOfBirth: new Date(moment(data.dateofbirth).format('YYYY-MM-DD HH:mm')),
                dataProtectionAccepted: data.dataProtectionAccepted,
                dataProtectionContent: data.dataProtectionContent,
                address: {
                    _country: data.country ? data.country : null, // setting this to null to bypass the castId check in mongodb
                    company: data.company,
                    address: data.address,
                    streetNumber: resultStreetNumber.streetNumber,
                    streetName: data.streetname,
                    streetAppendix: resultStreetNumber.appendix,
                    placeId: data?.placeId,
                    additionalAddressline: data.additional,
                    zipCode: data.postal,
                    city: data.city,
                    loc: data?.loc ? data?.loc : [],
                },
                signId: reportDatas[signatureField._id],
                userId: window?.userabstraction?._id,
                status: leadType,
                customStatuses: data.labels,
                followUp: [
                    {
                        userId: window?.userabstraction?._id,
                        date: data.followUpDate,
                        done: false,
                    },
                ],
                genericFields: genericFields,
            };
            setCrmData(crmsavedata, leadStatusData);
            setExpandedPanel(true); // if direct crm true setting expandedpanel true to save crm data
        } else {
            setExpandedPanel('panel2'); // if direct crm false setting expandedpanel panel2 to expand panel2
        }
    };

    useEffect(() => {
        if (forEditingCB) return;
        const resultStreetNumber = extractStreetNumber(data?.streetnumber);
        const crmsavedata = {
            _id: crmItems.length > 0 ? crmItems[0]._id : '',
            formOfAddress: data.formOfAddress,
            firstName: data.firstname,
            lastName: data.lastname,
            email: data.email,
            mobile: data.mobile,
            tel: data.phone,
            dateOfBirth: new Date(moment(data.dateofbirth).format('YYYY-MM-DD HH:mm')),
            dataProtectionAccepted: data.dataProtectionAccepted,
            dataProtectionContent: data.dataProtectionContent,
            address: {
                _country: data.country ? data.country : null, // setting this to null to bypass the castId check in mongodb
                company: data.company,
                address: data.address,
                streetNumber: resultStreetNumber.streetNumber,
                streetName: data.streetname,
                streetAppendix: resultStreetNumber.appendix,
                placeId: data?.placeId,
                additionalAddressline: data.additional,
                zipCode: data.postal,
                city: data.city,
                loc: data?.loc ? data?.loc : [],
            },
            signId: reportDatas[signatureField._id],
            userId: window?.userabstraction?._id,
            status: leadType,
            customStatuses: data.labels,
            followUp: [
                {
                    userId: window?.userabstraction?._id,
                    date: data.followUpDate,
                    done: false,
                },
            ],
        };
        setCrmData(crmsavedata);
    }, [expandedPanel]);

    const resetForm = () => {
        reset();
        setReportDatas({});
        setFormState('resetting');
        setCrmData({});
    };

    useEffect(() => {
        if (formState === 'resetting') {
            setFormState('ready');
        }
    }, [formState]);

    useEffect(() => {
        if (reRender) resetForm();
    }, [reRender]);

    useEffect(() => {
        let usedForValue = 'CRM';
        (async () => {
            setLoading(true);
            try {
                if (projectId) {
                    const resp = await API.get(`/projects/customstatus/filter?&skip=0&project_id=${projectId}`);
                    const result = await resp.json();
                    if (result.success) {
                        const customList = result.data.customs;
                        if (Array.isArray(customList)) {
                            const filteredLabels = customList.filter((label: any) => {
                                const usedFor = label?.usedFor || [];
                                return usedFor.includes(usedForValue);
                            });
                            setFilteredLabels(filteredLabels);
                            setLoading(false);
                        }
                    } else {
                        setLoading(false);
                    }
                }
            } catch (error) {
                console.error('error loading projects', error);
            }
        })().catch(() => {
            setLoading(false);
        });
    }, [projectId]);

    useEffect(() => {
        if (data.overtakeeventlocation && pointOfSale && typeof pointOfSale !== 'string') {
            setValue('additional', pointOfSale.address.additional);
            setValue('city', pointOfSale.address.city);
            setValue('company', pointOfSale.address.company);
            setValue('country', pointOfSale.address.country);
            setValue('postal', pointOfSale.address.postal);
            setValue('streetnumber', pointOfSale.address.streetnumber);
            setValue('loc', pointOfSale.location);
        }
    }, [data.overtakeeventlocation]);

    useEffect(() => {
        if (data.datapolicylink) {
            setOpenDialog(true);
            setValue('dataProtectionAccepted', new Date());
            setValue('dataProtectionContent', crmConfig?.crm_dataPolicytext);
        }
    }, [data.datapolicylink]);

    useEffect(() => {
        // console.log('crmItems11',crmItems);
        if (isFirstRender.current) {
            if (crmItems.length > 0 && crmItems[0].crmItem) {
                const crmItem = crmItems[0].crmItem;
                setValue('additional', crmItem.address.additionalAddressline);
                setValue('city', crmItem.address.city);
                setValue('company', crmItem.address.company);
                setValue('country', crmItem.address._country);
                setValue('postal', crmItem.address.zipCode);
                setValue('streetname', crmItem.address.streetName);
                setValue('streetnumber', crmItem.address.streetAppendix ? crmItem.address.streetNumber + crmItem.address.streetAppendix : crmItem.address.streetNumber );
                // setValue('address', crmItem.address.address );
                setValue('placeId', crmItem.address.placeId );
                setValue('loc', crmItem.address.loc);

                setValue('formOfAddress', crmItem.formOfAddress);
                setValue('firstname', crmItem.firstName);
                setValue('lastname', crmItem.lastName);
                setValue('email', crmItem.email);
                setValue('mobile', crmItem.mobile);
                setValue('phone', crmItem.tel);
                setValue('dateofbirth', crmItem.dateOfBirth);
                setValue('dataProtectionAccepted', crmItem.dataProtectionAccepted);
                setValue('dataProtectionContent', crmItem.dataProtectionContent);

                setValue('overtakeeventlocation', false);
                setValue('datapolicylink', false);
                const newData = { '659e2c307006bf00340cc6c9': crmItem.signId };
                setReportDatas([newData]);
                setLeadType(crmItem.status);
                setValue('followUpDate', crmItem?.followUp?.[0]?.date);
                setValue('labels', crmItem?.customStatuses);
            } else {
                setValue('additional', '');
                setValue('city', '');
                setValue('company', '');
                setValue('country', '');
                setValue('postal', '');
                setValue('address', '');
                setValue('streetnumber', '');
                setValue('streetname', '');
                setValue('placeId', '' );
                setValue('loc', '');

                setValue('formOfAddress', '');
                setValue('firstname', '');
                setValue('lastname', '');
                setValue('email', '');
                setValue('mobile', '');
                setValue('phone', '');
                setValue('dateofbirth', '');
                setValue('dataProtectionAccepted', undefined);
                setValue('dataProtectionContent', '');

                setValue('overtakeeventlocation', false);
                setValue('datapolicylink', false);
                setReportDatas([]);
            }
            // Mark the first render as complete
            isFirstRender.current = false;
        } else if (!isDirectCrm) {
            if (leadDetails?.forLeadDetails || forEditingCB) return;
            if (crmItems.length > 0 && crmItems[0].crmItem) {
                const crmItem = crmItems[0].crmItem;
                setValue('additional', crmItem.address.additionalAddressline);
                setValue('city', crmItem.address.city);
                setValue('company', crmItem.address.company);
                setValue('country', crmItem.address._country);
                setValue('postal', crmItem.address.zipCode);
                // setValue('address', crmItem.address.address);
                setValue('streetname', crmItem.address.streetName);
                setValue('streetnumber', crmItem.address.streetAppendix ? crmItem.address.streetNumber + crmItem.address.streetAppendix : crmItem.address.streetNumber );
                setValue('placeId', crmItem.address.placeId );
                setValue('loc', crmItem.address.loc);

                setValue('formOfAddress', crmItem.formOfAddress);
                setValue('firstname', crmItem.firstName);
                setValue('lastname', crmItem.lastName);
                setValue('email', crmItem.email);
                setValue('mobile', crmItem.mobile);
                setValue('phone', crmItem.tel);
                setValue('dateofbirth', crmItem.dateOfBirth);
                setValue('dataProtectionAccepted', crmItem.dataProtectionAccepted);
                setValue('dataProtectionContent', crmItem.dataProtectionContent);

                setValue('overtakeeventlocation', false);
                setValue('datapolicylink', false);
                const newData = { '659e2c307006bf00340cc6c9': crmItem.signId };
                setReportDatas([newData]);
                setLeadType(crmItem.status);
                setValue('followUpDate', crmItem?.followUp?.[0]?.date);
                setValue('labels', crmItem?.customStatuses);
            } else {
                setValue('additional', '');
                setValue('city', '');
                setValue('company', '');
                setValue('country', '');
                setValue('postal', '');
                setValue('address', '');
                setValue('streetnumber', '');
                setValue('streetname', '');
                setValue('placeId','' );
                setValue('loc', '');

                setValue('formOfAddress', '');
                setValue('firstname', '');
                setValue('lastname', '');
                setValue('email', '');
                setValue('mobile', '');
                setValue('phone', '');
                setValue('dateofbirth', '');
                setValue('dataProtectionAccepted', undefined);
                setValue('dataProtectionContent', '');

                setValue('overtakeeventlocation', false);
                setValue('datapolicylink', false);
                setReportDatas([]);
            }
        }
    }, [crmItems, isDirectCrm]);

    const getGenericFields = () => {
        API.getReqestWithParams({ url: '/api/customModelField/field', params: { model: 'CRM' } })
            .then(result => {
                const fields: any = result;
                const transformedFields: Record<string, any> = {};
                const crmItem = crmItems?.[0]?.crmItem
                fields.forEach((field: any) => {
                    transformedFields[field.fieldAttributeName] = crmItem?.genericFields?.[field.fieldAttributeName] || null;
                });
                setGenericFields(transformedFields as []);
            })
            .catch(e => {});
    };

    useEffect(() => {
        getGenericFields();
    }, []);

    return (
        <>
            {formState !== 'resetting' && (
                <Box>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {isDirectCrm && !googleData && (
                            <Typography ml={2} gutterBottom>
                                {t('##NoGoogleDataAvailable')}
                            </Typography>
                        )}
                        {/* address data */}
                        {(false ||
                            !crmConfig?.crm_overtakeaddressHide ||
                            !crmConfig?.crm_countryHide ||
                            !crmConfig?.crm_companyHide ||
                            !crmConfig?.crm_streetHide ||
                            !crmConfig?.crm_addressHide ||
                            !crmConfig?.crm_pincodeHide ||
                            !crmConfig?.crm_cityHide) && (
                            <Grid container justifyContent={'start'} spacing={2} sx={{ p: 2 }}>
                                <Typography ml={2} variant='h6' gutterBottom>
                                    {t('##Address')}
                                </Typography>
                                {!forEditingCB && !crmConfig?.crm_overtakeaddressHide && !isDirectCrm && (
                                    <Grid item xs={12}>
                                        <EmpplanCheckbox
                                            control={control}
                                            name='overtakeeventlocation'
                                            label={t('##OvertakeAddress')}
                                            disabled={crmAdded}
                                        />
                                    </Grid>
                                )}
                                {!crmConfig?.crm_countryHide && (
                                    <Grid item xs={12}>
                                        <EmpplanSelect
                                            control={control}
                                            name='country'
                                            rules={{
                                                required:
                                                    !crmConfig?.crm_countryHide && !crmConfig?.crm_countryRequired
                                                        ? ''
                                                        : `${t('##Required')}`,
                                            }}
                                            label={t('##Country')}
                                            options={countryList.map(eachCountry => ({
                                                value: eachCountry._id,
                                                label: eachCountry.name,
                                            }))}
                                            helperText={crmConfig?.crm_countryRequired ? t('##Required') : ''}
                                            disabled={crmAdded}
                                        />
                                    </Grid>
                                )}
                                {!crmConfig?.crm_companyHide && (
                                    <Grid item xs={12}>
                                        <EmpplanTextField
                                            name='company'
                                            label={t('##Company')}
                                            rules={{
                                                required:
                                                    !crmConfig?.crm_companyHide && !crmConfig?.crm_companyRequired
                                                        ? ''
                                                        : `${t('##Required')}`,
                                            }}
                                            control={control}
                                            renderProps={() => ({
                                                InputLabelProps: { shrink: !!data.company },
                                            })}
                                            helperText={crmConfig?.crm_companyRequired ? t('##Required') : ''}
                                            disabled={crmAdded}
                                        />
                                    </Grid>
                                )}
                                {!crmConfig?.crm_streetHide && (
                                    <Grid item xs={12}  display='flex'>
                                        <Grid item xs={5}>
                                            <EmpplanTextField
                                                name='streetname'
                                                label={t('##StreetName')}
                                                control={control}
                                                rules={{
                                                    required:
                                                        !crmConfig?.crm_streetHide && !crmConfig?.crm_streetRequired
                                                            ? ''
                                                            : `${t('##Required')}`,
                                                }}
                                                renderProps={() => ({
                                                    InputLabelProps: { shrink: !!data.streetname },
                                                })}
                                                helperText={crmConfig?.crm_streetRequired ? t('##Required') : ''}
                                                disabled={crmAdded}
                                            />      
                                        </Grid>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={5}>
                                            <EmpplanTextField
                                                name='streetnumber'
                                                label={t('##StreetNumber')}
                                                control={control}
                                                rules={{
                                                    required:
                                                        !crmConfig?.crm_streetHide && !crmConfig?.crm_streetRequired
                                                            ? ''
                                                            : `${t('##Required')}`,
                                                }}
                                                renderProps={() => ({
                                                    InputLabelProps: { shrink: !!data.streetnumber },
                                                })}
                                                helperText={crmConfig?.crm_streetRequired ? t('##Required') : ''}
                                                disabled={crmAdded}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {!crmConfig?.crm_addressHide && (
                                    <Grid item xs={12}>
                                        <EmpplanTextField
                                            control={control}
                                            name='additional'
                                            label={t('##MoreAddressStuff')}
                                            rules={{
                                                required:
                                                    !crmConfig?.crm_addressHide && !crmConfig?.crm_addressRequired
                                                        ? ''
                                                        : `${t('##Required')}`,
                                            }}
                                            renderProps={() => ({
                                                InputLabelProps: { shrink: !!data.additional },
                                            })}
                                            helperText={crmConfig?.crm_addressRequired ? t('##Required') : ''}
                                            disabled={crmAdded}
                                        />
                                    </Grid>
                                )}
                                {!crmConfig?.crm_pincodeHide && (
                                    <Grid item xs={12}>
                                        <EmpplanTextField
                                            name='postal'
                                            label={t('##Postal')}
                                            control={control}
                                            rules={{
                                                required:
                                                    !crmConfig?.crm_pincodeHide && !crmConfig?.crm_pincodeRequired
                                                        ? ''
                                                        : `${t('##Required')}`,
                                            }}
                                            renderProps={() => ({
                                                InputLabelProps: { shrink: !!data.postal },
                                            })}
                                            helperText={crmConfig?.crm_pincodeRequired ? t('##Required') : ''}
                                            disabled={crmAdded}
                                        />
                                    </Grid>
                                )}
                                {!crmConfig?.crm_cityHide && (
                                    <Grid item xs={12}>
                                        <EmpplanTextField
                                            name='city'
                                            label={t('##City')}
                                            control={control}
                                            rules={{
                                                required:
                                                    !crmConfig?.crm_cityHide && !crmConfig?.crm_cityRequired
                                                        ? ''
                                                        : `${t('##Required')}`,
                                            }}
                                            renderProps={() => ({
                                                InputLabelProps: { shrink: !!data.city },
                                            })}
                                            helperText={crmConfig?.crm_cityRequired ? t('##Required') : ''}
                                            disabled={crmAdded}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        {/* contact data */}
                        <Grid container justifyContent={'start'} spacing={2} sx={{ p: 2 }}>
                            <Typography ml={2} variant='h6' gutterBottom>
                                {t('##ContactData')}
                            </Typography>
                            {!crmConfig?.crm_formofaddressHide && (
                                <Grid item xs={12}>
                                    <EmpplanRadioGroup
                                        header='##formOfAddress'
                                        control={control}
                                        label='##formOfAddress'
                                        name='formOfAddress'
                                        rules={{
                                            required:
                                                !crmConfig?.crm_formofaddressHide && !crmConfig?.crm_formofaddressRequired
                                                    ? ''
                                                    : `${t('##Required')}`,
                                        }}
                                        helperText={crmConfig?.crm_formofaddressRequired ? t('##Required') : ''}
                                        options={FormOfAddress}
                                        sx={{ display: 'flex', flexDirection: 'row' }}
                                        disabled={crmAdded}
                                    />
                                </Grid>
                            )}
                            {!crmConfig?.crm_firstnameHide && (
                                <Grid item xs={12}>
                                    <EmpplanTextField
                                        name='firstname'
                                        label={t('##firstName')}
                                        control={control}
                                        rules={{
                                            required:
                                                !crmConfig?.crm_firstnameHide && !crmConfig?.crm_firstnameRequired
                                                    ? ''
                                                    : `${t('##Required')}`,
                                        }}
                                        helperText={crmConfig?.crm_firstnameRequired ? t('##Required') : ''}
                                        disabled={crmAdded}
                                    />
                                </Grid>
                            )}
                            {!crmConfig?.crm_lastnameHide && (
                                <Grid item xs={12}>
                                    <EmpplanTextField
                                        name='lastname'
                                        label={t('##lastName')}
                                        control={control}
                                        rules={{
                                            required:
                                                !crmConfig?.crm_lastnameHide && !crmConfig?.crm_lastnameRequired
                                                    ? ''
                                                    : `${t('##Required')}`,
                                        }}
                                        helperText={crmConfig?.crm_lastnameRequired ? t('##Required') : ''}
                                        disabled={crmAdded}
                                    />
                                </Grid>
                            )}
                            {!crmConfig?.crm_dobHide && (
                                <Grid item xs={12}>
                                    <Controller
                                        name='dateofbirth'
                                        control={control}
                                        rules={{
                                            required:
                                                !crmConfig?.crm_dobHide && !crmConfig?.crm_dobRequired
                                                    ? ''
                                                    : `${t('##Required')}`,
                                        }}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => {
                                            return (
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker
                                                        sx={{ width: '100%' }}
                                                        disableFuture
                                                        format='DD/MM/YYYY'
                                                        openTo='day'
                                                        views={['year', 'month', 'day']}
                                                        value={value ? moment(value) : null}
                                                        onChange={(newValue: any) => {
                                                            onChange(newValue);
                                                        }}
                                                        disabled={crmAdded}
                                                        label={t('##dateofbirth')}
                                                        slotProps={{
                                                            textField: {
                                                                variant: 'standard', // Apply standard variant for bottom line
                                                                fullWidth: true,
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            );
                                        }}
                                    />
                                </Grid>
                            )}
                            {!crmConfig?.crm_emailHide && (
                                <Grid item xs={12}>
                                    <EmpplanTextField
                                        control={control}
                                        name='email'
                                        label={t('##Email')}
                                        rules={{
                                            required:
                                                !crmConfig?.crm_emailHide && !crmConfig?.crm_emailRequired
                                                    ? ''
                                                    : `${t('##Required')}`,
                                        }}
                                        helperText={crmConfig?.crm_emailRequired ? t('##Required') : ''}
                                        disabled={crmAdded}
                                    />
                                </Grid>
                            )}
                            {!crmConfig?.crm_mobileHide && (
                                <Grid item xs={12}>
                                    <EmpplanTextField
                                        name='mobile'
                                        label={t('##Mobile')}
                                        type='number'
                                        control={control}
                                        rules={{
                                            required:
                                                !crmConfig?.crm_mobileHide && !crmConfig?.crm_mobileRequired
                                                    ? ''
                                                    : `${t('##Required')}`,
                                        }}
                                        helperText={crmConfig?.crm_mobileRequired ? t('##Required') : ''}
                                        disabled={crmAdded}
                                    />
                                </Grid>
                            )}
                            {!crmConfig?.crm_phoneHide && (
                                <Grid item xs={12}>
                                    <EmpplanTextField
                                        name='phone'
                                        label={t('##Phone')}
                                        type='number'
                                        control={control}
                                        rules={{
                                            required:
                                                !crmConfig?.crm_phoneHide && !crmConfig?.crm_phoneRequired
                                                    ? ''
                                                    : `${t('##Required')}`,
                                        }}
                                        helperText={crmConfig?.crm_phoneRequired ? t('##Required') : ''}
                                        disabled={crmAdded}
                                    />
                                </Grid>
                            )}
                            {!crmConfig?.crm_leadstatusHide && (
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            select
                                            variant={'standard'}
                                            fullWidth
                                            label={t('##LeadStatus')}
                                            value={leadType}
                                            onChange={e => {
                                                setLeadType(e.target.value);
                                            }}
                                            disabled={crmAdded}
                                        >
                                            {leadStatusType.map(value => {
                                                return (
                                                    <MenuItem key={value._id} value={value._id} disabled={!value.active}>
                                                        {value.statusLabel
                                                            ? value.type === 'EMPPLAN'
                                                                ? t('' + value.slangText)
                                                                : t('' + value.statusLabel)
                                                            : t('' + value.custom_status)}
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    </Grid>
                                    {!crmConfig?.crm_followupdateHide && leadStatusData?.isShowDateSelect && (
                                        <Grid item xs={12}>
                                            <Controller
                                                name='followUpDate'
                                                control={control}
                                                rules={{
                                                    required:
                                                        !crmConfig?.crm_followupdateHide &&
                                                        !crmConfig?.crm_followupdateRequired
                                                            ? ''
                                                            : `${t('##Required')}`,
                                                }}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => {
                                                    return (
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DateTimePicker
                                                                sx={{ width: '100%' }}
                                                                disablePast
                                                                format='DD/MM/YYYY HH:mm'
                                                                openTo='day'
                                                                views={['year', 'month', 'day', 'hours', 'minutes']}
                                                                value={value ? moment(value) : null}
                                                                onChange={(newValue: any) => {
                                                                    onChange(newValue);
                                                                }}
                                                                disabled={crmAdded}
                                                                label={t('##FollowUpDate')}
                                                                slotProps={{
                                                                    textField: {
                                                                        variant: 'standard', // Apply standard variant for bottom line
                                                                        fullWidth: true,
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    )}
                                </>
                            )}
                            {filteredLabels.length > 0 && (
                                <Grid item xs={12}>
                                    <EmpplanSelect
                                        multiple
                                        control={control}
                                        name='labels'
                                        label={t('##Labels')}
                                        options={filteredLabels.map((eachCountry: any) => ({
                                            value: eachCountry._id,
                                            label: eachCountry.statusLabel
                                                ? eachCountry.type === 'EMPPLAN'
                                                    ? t('' + eachCountry.slangText)
                                                    : t('' + eachCountry.statusLabel)
                                                : t('' + eachCountry.custom_status),
                                        }))}
                                        // helperText={crmConfig?.crm_countryRequired ? t('##Required') : ''}
                                        disabled={crmAdded}
                                    />
                                </Grid>
                            )}
                            {crmConfig?.crm_dataPolicytext && crmConfig?.crm_dataPolicytext?.trim() && (
                                <>
                                    <Grid item xs={12}>
                                        <EmpplanCheckbox
                                            control={control}
                                            name='datapolicylink'
                                            label={t('##DataPolicyLink')}
                                            disabled={crmAdded}
                                        />
                                        <Dialog
                                            open={openDialog}
                                            onClose={() => {
                                                setOpenDialog(false);
                                            }}
                                            fullWidth
                                        >
                                            <DialogTitle>{t('##DataPolicyLink')}</DialogTitle>
                                            <DialogContent style={{ paddingTop: 0 }}>
                                                <Box
                                                    sx={{
                                                        border: '1px solid gray',
                                                        overflow: 'scroll',
                                                        padding: '10px',
                                                    }}
                                                >
                                                    <Typography>{crmConfig?.crm_dataPolicytext}</Typography>
                                                </Box>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => {
                                                        setOpenDialog(false);
                                                    }}
                                                >
                                                    {t('##OK')}
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </Grid>
                                    {!crmConfig?.crm_signatureHide && (
                                        <Grid item xs={12}>
                                            <PWAContext.Provider
                                                value={{
                                                    areWeInPwa: 'yes',
                                                    pwa: isOnline ? false : true,
                                                    dispatch: dispatch,
                                                    selector: useSelector,
                                                    pwaHelpers: {},
                                                    isHybridSync: true
                                                }}
                                            >
                                            <ReportConfigurationContext.Provider
                                                value={{
                                                    isDisabled: crmAdded,
                                                }}
                                            >
                                                <SignatureComponent
                                                    field={signatureField}
                                                    reportData={reportDatas}
                                                    contextObjForUpload={{}}
                                                    onChange={val => {
                                                        onChangeHandler(signatureField.config._id, val);
                                                    }}
                                                    endpoint='/customreportresult/upload/data'
                                                    setUploadStatus={(status: boolean) => {
                                                        if (status) {
                                                            // set loader since uploading
                                                            taskControlHandler?.addTask(signatureField.config._id);
                                                        } else {
                                                            // remove from loader
                                                            taskControlHandler?.removeTask(signatureField.config._id);
                                                        }
                                                    }}
                                                />
                                            </ReportConfigurationContext.Provider>
                                            </PWAContext.Provider>
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                        {/* More Data */}
                        <Grid container justifyContent={'start'} spacing={2} sx={{ p: 2 }}>
                            <GenericCustomModelFields
                                modelName={'CRM'}
                                customModelData={genericFields}
                                optionalCantAddNewFields={true}
                                setGenericFields={setGenericFields}
                            />
                        </Grid>
                        {forEditingCB ? (
                            leadDetails?.forLeadDetails ? (
                                <Box display='flex' px={3} pb={2}>
                                    <Button
                                        disabled={leadDetails?.disableButton || Object.keys(errors).length > 0}
                                        type='submit'
                                        fullWidth
                                        sx={{ borderRadius: 30, height: 50 }}
                                        variant='contained'
                                        color='secondary'
                                    >
                                        {t('##CloseSale')}
                                    </Button>
                                </Box>
                            ) : (
                                <Box display='flex' px={3} pb={2}>
                                    <Button
                                        fullWidth
                                        color='error'
                                        onClick={() => {
                                            if (typeof forEditingCB === 'function') {
                                                forEditingCB();
                                            }
                                        }}
                                    >
                                        {t('##Cancel')}
                                    </Button>
                                    <Button
                                        type='submit'
                                        fullWidth
                                        sx={{ borderRadius: 30 }}
                                        variant='contained'
                                        color='secondary'
                                    >
                                        {t('##Update')}
                                    </Button>
                                </Box>
                            )
                        ) : (
                            <Grid item xs={12} container justifyContent={'end'} spacing={2} sx={{ p: 2 }}>
                                <Button type='submit' variant='contained' color='secondary' disabled={crmAdded}>
                                    {t('##NextStep')}
                                </Button>
                            </Grid>
                        )}
                    </form>
                </Box>
            )}
        </>
    );
}

export default CustomerDataForm;
