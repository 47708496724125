import moment from 'moment-timezone';
import { getCurrentLocaleString } from '../../../config/translate';
import { apiLoadAgencySettings } from '../../../hooks/useCurrency';

export function getRowClasses(event: any) {
    let currTime: Record<any, any> = {};
    if (event.times && event.times.length > 0) {
        currTime = event.times[0];
    }
    let startHr = 0,
        startMin = 0;
    let stopHr = 23,
        stopMin = 59;

    let plannedTimeClass = '';

    if (currTime.start) {
        startHr = moment(currTime.start).tz('Europe/Berlin').hour();
        startMin = moment(currTime.start).tz('Europe/Berlin').minutes();
    }

    if (currTime.stop) {
        stopHr = moment(currTime.stop).tz('Europe/Berlin').hour();
        stopMin = moment(currTime.stop).tz('Europe/Berlin').minutes();
    }

    let momentEvStartTime = moment(event.date).tz('Europe/Berlin').startOf('day').hours(startHr).minutes(startMin);
    let momentEvStopTime = moment(event.date).tz('Europe/Berlin').startOf('day').hours(stopHr).minutes(stopMin);

    let loggIn = [] as any[],
        loggOut = [],
        i = 0,
        j = 0;
    if (event.eventStateHistory && event.eventStateHistory.length > 0) {
        event.eventStateHistory.forEach(function (History: { stateNew: string; date: moment.MomentInput }, key: any) {
            if (History.stateNew === 'LoggedIn') {
                let MDate = moment(History.date).tz('Europe/Berlin');
                loggIn[i] = MDate;
                i++;
            } else if (History.stateNew === 'LoggedOut') {
                let MDate = moment(History.date).tz('Europe/Berlin');
                loggOut[j] = MDate;
                j++;
            }
        });
    }

    if (loggIn.length > 0) {
        let momentStart = loggIn[0];
        /*let momentStop = loggOut[0];*/

        if (
            momentStart.isBefore(momentEvStartTime) ||
            momentStart.isSame(momentEvStartTime) ||
            momentStart.isBetween(momentEvStartTime, momentEvStopTime)
        ) {
            plannedTimeClass = 'onTime';
        } else if (momentStart.isAfter(momentEvStopTime)) {
            plannedTimeClass = 'afterTime';
        }
    } else {
        let today = moment().tz('Europe/Berlin');
        if (
            today.isSame(momentEvStartTime) ||
            today.isBetween(momentEvStartTime, momentEvStopTime) ||
            today.isAfter(momentEvStopTime)
        ) {
            plannedTimeClass = 'notInTime';
        }
    }

    return plannedTimeClass;
}

const checkForStructredISOCode = (currency: string) => {
    const structuredCurrencyISOCodes: Record<string, string> = {
        Euro: 'EUR',
        euro: 'EUR',
        us: 'USD',
        chf: 'CHF',
        UsDollar: 'USD',
        SwissFranc: 'CHF',
    };

    if (currency in structuredCurrencyISOCodes) {
        return structuredCurrencyISOCodes[currency];
    }
    return currency;
};
apiLoadAgencySettings();
export function formatCurrency(val: number, locale?: string, currency?: string) {
    locale = locale || getCurrentLocaleString();
    let OginalLocale = locale === 'D-Raw' ? 'de' : locale;

    const getCurrencyCode = (code: string) => checkForStructredISOCode(code);

    const formatWithCurrency = (currencyCode: string) => {
        return Intl.NumberFormat(OginalLocale, {
            style: 'currency',
            currency: currencyCode,
        }).format(val);
    };

    if (currency) {
        return formatWithCurrency(getCurrencyCode(currency));
    }

    if (window?.empplanSettings?.currency) {
        return formatWithCurrency(getCurrencyCode(window?.empplanSettings?.currency));
    }
    // apiLoadAgencySettings()
    //     .then(settings => {
    //         return formatWithCurrency(getCurrencyCode(settings?.currency || 'EUR'));
    //     })
    //     .catch(err => {
    //         return formatWithCurrency('EUR');
    //     });
}
