import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import LeadTransactionItemCard from './LeadMenuItems/LeadTransactionItemCard';
import API from 'reactelements/src/config/api';
import { renderDate } from './helpers/dateHelpers';
import { EmpplanSnackbarContext } from '../../../reactelements/src/modules/Snackbar';

function LeadDetailTransactionList({ transactionData, leadId, crmData }: any) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [currentChannel, setCurrentChannel] = useState<any>({});
    const snackbarTools = useContext(EmpplanSnackbarContext);

    const membersList: any = [];

    // For Send Message On Edit and Delete on Transaction
    const getChannels = () => {
        setLoading(true);
        API.get(`/chatchannel?context={"contextObject":"crm","contextObjectId":"${leadId}"}`)
            .then(res => res.json())
            .then(jsonData => {
                if (jsonData.length > 0) {
                    setCurrentChannel(jsonData[0]);
                } else {
                    createChannel();
                }
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const createChannel = () => {
        setLoading(true);
        if (membersList.indexOf(window.userabstraction._id) === -1) {
            membersList.push(window.userabstraction._id);
        }

        const channelName = crmData.firstName || crmData.lastName
            ? `${crmData.firstName} ${crmData.lastName}, ${crmData.address?.streetName} ${crmData.address?.streetNumber}${crmData.address?.streetAppendix ? '-' + crmData.address?.streetAppendix : ''}, ${crmData.address?.city}`.trim()
            : `${crmData.address?.streetName} ${crmData.address?.streetNumber}${crmData.address?.streetAppendix ? '-' + crmData.address?.streetAppendix : ''}, ${crmData.address?.city}`.trim();

        var data = {
            name: channelName,
            context: {
                contextObject: 'crm',
                contextObjectId: leadId,
            },
            members: membersList,
        };

        API.post(`/chatchannel`, data)
            .then(res => res.json())
            .then(jsonData => {
                getChannels();
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const sendMessage = (message: string) => {
        const trimmedMessage = message.trim();
        if (trimmedMessage.length === 0) {
            return;
        }
        const payload = {
            message: trimmedMessage,
            type: ['mail'],
            channel: currentChannel?._id,
            members: currentChannel?.members,
            readBy: window?.userabstraction?._id ? [window?.userabstraction?._id] : [],
        };
        API.post('/chatmessages', payload)
            .then(res => {
                return res.json();
            })
            .then(data => {
                snackbarTools.createToast({ type: 'success', message: t`##YourRequestHasSendForChange` });
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleEditAndDelete = (type: string, data:any) => {
        let message = '';
        if (type === 'edit') {
            message = `
                <p><b>${t('##PleaseEditTransaction')}</b></p>
                <p>${t('##FromUser')}: <b>${crmData.userId.firstName} ${crmData.userId.lastName} (${crmData.userId.email})</b></p>
                <p>${t('##TransactionDetails')}</p>
                    <p>${t('##TransactionId')}: <b>${data.saleId}</b></p>
                    <p>${t('##SaleTime')}: <b>${renderDate(data.saleTime)}</b></p>
                    <p>${t('##Product')}: <b>${data.qty} x ${data.productId?.name} </b></p>
                    <p><b>
                        <a href="${`/#!/event/${data.eventId}`}" target="_blank" rel="noopener noreferrer">
                            ${t('##OpenEvent')}
                        </a></b>
                    </p>
            `;
        } else {
            message = `
                <p><b>${t('##PleaseDeleteTransaction')}</b></p>
                <p>${t('##FromUser')}: <b>${crmData.userId.firstName} ${crmData.userId.lastName} (${crmData.userId.email})</b></p>
                <p>${t('##TransactionDetails')}</p>
                    <p>${t('##TransactionId')}: <b>${data.saleId}</b></p>
                    <p>${t('##SaleTime')}: <b>${renderDate(data.saleTime)}</b></p>
                    <p>${t('##Product')}: <b>${data.qty} x ${data.productId?.name} </b></p>
                    <p><b>
                        <a href="${`/#!/event/${data.eventId}`}" target="_blank" rel="noopener noreferrer">
                            ${t('##OpenEvent')}
                        </a></b>
                    </p>
            `;
        }
        // Call sendMessage function with the constructed message
        sendMessage(message);
    }

    useEffect(() => {
        getChannels();
    },[]);
    
    return (
        <>
            <Box sx={{
                width: '100vw',
                overflow: 'auto', // To handle scrolling if needed
                padding: '5px',
                boxSizing: 'border-box'
            }}>
                <Box
                    padding={'3px'}
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Typography fontWeight={700} color={'grey'}>
                        {t('##TransactionsDone')}
                    </Typography>
                </Box>
                {transactionData.map((item: any, idx: number) => {
                    return (
                        <React.Fragment key={idx}>
                            <LeadTransactionItemCard 
                                item={item}
                                handleEditAndDelete={handleEditAndDelete}
                            />
                        </React.Fragment>
                    );
                })}
            </Box>
        </>
    )
}

export default LeadDetailTransactionList;