import {UserActionTypes} from "../UserStates/UserStatesTypes";

export enum UserLanguages {
    DE="de",
    EN="en",
    RU="ru",
    FR="fr",
    NL="nl"
}


export type GalleryItem = {
    created:string,
    image:string,
    isVideo:boolean,
    profilePicture:number,
    user:string,
    __v:number,
    _id:string,
    imageAddOn?: {
        orientation?:string,
        size?: {width:number, height:number}
    }
};

export type UserGallery = {
    data: GalleryItem[]
};


export enum GalleryActionTypes  {
    LOADING = "GALLERY/LOADING",
    LOADBYUSERID = "GALLERY/LOADING/BYUSERID",
};

export interface GetGalleryByUserId {
    type: GalleryActionTypes.LOADBYUSERID,
    gallery: UserGallery
}

export interface GalleryLoadingAction {
    type:GalleryActionTypes.LOADING,
    galleryLoading:boolean
}

export interface GalleryState {
    readonly galleryLoading: boolean;
    readonly userGallery: UserGallery|{data:[]};

}


export type GalleryActionsItems =
    | GetGalleryByUserId
    | GalleryLoadingAction
