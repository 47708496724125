import React, { useContext, useState } from 'react';
import { Box, FormControl, FormLabel, Slider, Typography } from '@mui/material';
import { ReportConfigurationContext } from '../index';
import { useTranslation } from 'react-i18next';
import ToolTipComponent from '../ToolTipComponent';
import { requireHelper } from '../utils/requireHelper';

export interface SliderComponentProps {
    field: CustomReportField;
    reportData: ReportData;
    onChangeHandler: any;
}

export const SliderComponent = ({ field, reportData, onChangeHandler }: SliderComponentProps) => {
    const { isDisabled } = useContext(ReportConfigurationContext);
    const requiredCondition = requireHelper(field, reportData);
    const { t } = useTranslation();
    const initialsliderValue =
        reportData[field.config._id] !== undefined && reportData[field.config._id] !== null
            ? (reportData[field.config._id] as number)
            : Number(field?.config?.fieldObject?.minimum);
    const [sliderValue, setSliderValue] = useState(initialsliderValue);

    return (
        <Box>
            <FormControl fullWidth data-testid='slider'>
                <FormLabel id='slider-label'>
                    {field.config.slug ? t(field.config.slug) : field.config.name}
                    {requiredCondition && (
                        <Box component='span' sx={{ color: 'red' }}>
                            {'  '}({t('##TheFieldIsRequired')})
                        </Box>
                    )}
                    {field.config?.toolTipTxt !== '' && (
                        <ToolTipComponent
                            enableHintOnClick={field.config.enableHintOnClick}
                            tooltipText={field.config.toolTipTxt}
                        />
                    )}
                </FormLabel>
                <Slider
                    key={field.config._id}
                    aria-label={field.config.name}
                    valueLabelDisplay='auto'
                    marks
                    step={Number(field?.config?.fieldObject?.step)}
                    min={Number(field?.config?.fieldObject?.minimum)}
                    max={Number(field?.config?.fieldObject?.maximum)}
                    disabled={isDisabled || field.isReadonly}
                    value={sliderValue}
                    onChange={(event: Event, newValue: any) => setSliderValue(newValue)}
                    onChangeCommitted={(_, value) => onChangeHandler(field.config._id, value)}
                />
            </FormControl>
        </Box>
    );
};
