import { Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext } from 'react';
import { LoaderContext } from '../contexts/loader';

const useStyles = makeStyles(() => ({
    skeleton: {
        width: '90%',
        height: '90%',
        placeSelf: 'center',
    },
}));
export function Cell({ children }: any) {
    const classes = useStyles();
    const { loading } = useContext(LoaderContext);
    return <>{loading ? <Skeleton className={classes.skeleton} variant='rectangular' animation='wave' /> : children}</>;
}
