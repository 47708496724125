import { AnyAction } from 'redux';
import newCrmItemActionTypes from './actions';
import { CRMItemStates } from './types';

// INITIAL STATE
const initialState: CRMItemStates = {
    loadedCrmItems: [],
    loadedCrmItem: undefined,
    crmItemForEditing: undefined,
    syncData: {
        state: 'READY', // ready, pending, syncing, ready
        lastUpdate: null,
    },
};

// REDUCER

const crmItemReducer = (state = { ...initialState }, action: AnyAction) => {
    if (action.type === newCrmItemActionTypes.LOAD_CRMITEM_LIST) {
        return { ...state, loadedCrmItems: action.payload };
    }
    if (action.type === newCrmItemActionTypes.LOAD_CRMITEM) {
        return { ...state, loadedCrmItem: action.payload };
    }
    if (action.type === newCrmItemActionTypes.UPDATE_CRMITEM_SYNC_DATA) {
        return { ...state, syncData: action.payload };
    }
    if (action.type === newCrmItemActionTypes.UPDATE_EDIT_CRMITEM) {
        return { ...state, crmItemForEditing: action.payload };
    }

    return state;
};

export default crmItemReducer;
