import React, { useMemo } from 'react';
import { Chip, Grid, InputAdornment, Tooltip, Typography } from '@mui/material';
import EmpplanTheme from '../elements/ThemeWrapper/EmpplanTheme';
import UploadField from './FormComponents/UploadField';
import TextFieldComponent from './FormComponents/TextFieldComponent';
import { CheckBoxComponent } from './FormComponents/CheckBoxComponent';
import { DropDownComponent } from './FormComponents/DropDownComponent';
import { SliderComponent } from './FormComponents/SliderComponent';
import { RadioComponent } from './FormComponents/RadioComponent';
import { FreeTextComponent } from './FormComponents/FreeTextComponent';
import { DateComponent } from './FormComponents/DateComponent';
import { isFieldHidden } from './utils/fieldVisibility';
import { groupBy } from 'lodash';
import { ReportConfigurationContext } from '.';
import CloseEventComponent from './FormComponents/CloseEventComponent';
import UrlFieldComponent from './FormComponents/UrlFieldComponent';
import GplacesFieldComponent from './FormComponents/GplacesFieldComponent';
import IDNowFieldComponent from './FormComponents/IDNowFieldComponent';
import SignatureComponent from './FormComponents/SignatureComponent';
import { IProductGroup } from '../../utils/ProductGroup';
import ToolTipComponent from './ToolTipComponent';
import { t } from 'i18next';
import { InfoOutlined, InfoRounded } from '@mui/icons-material';
import { FieldFilter } from './FieldFilter';

interface CustomReportComponentProps {
    reportConfig: CustomReportConfig;
    reportData: ReportData;
    onChangeHandler: (
        key: ReportFieldKey,
        value: ReportFieldValue,
        isFilterable?: boolean,
        filterData?: any,
        productGroup?: IProductGroup,
    ) => void;
    taskControl?: {
        addTask: (slug: string) => void;
        removeTask: (slug: string) => void;
    };
    contextObjForUpload?: {
        contextType?: string;
        contextId?: string;
    };
    productGroups?: Array<IProductGroup>;
    fieldFilterValue?: FieldFilter;
    eventId?: string;
}

interface FieldWrapperProps {
    field: CustomReportField;
    children: React.ReactNode;
    productGroup?: IProductGroup;
}

const FieldWrapper = ({ field, children, productGroup }: FieldWrapperProps) => {
    let flexSize = 12;
    if (productGroup) {
        flexSize = flexSize - 2;
    }
    if (Array.isArray(field.customLabel) && field.customLabel.length > 0) {
        flexSize = flexSize - 2;
    }
    const reportContext = React.useContext(ReportConfigurationContext);

    let visitguideline =
        reportContext.visitGuidelines &&
        reportContext.visitGuidelines.find(
            vg =>
                (productGroup && vg.productGroup === productGroup?._id && vg.fieldId === field.config._id) ||
                (!productGroup && vg.fieldId === field.config._id),
        )?.commentslug;

    return (
        <Grid
            container
            item
            xs={12}
            lg={(field.layout?.inline / 100) * 12}
            order={field.layout?.rows || undefined}
            alignItems={'center'}
        >
            {productGroup && (
                <Grid item xs={2}>
                    <Chip
                        label={productGroup?.name}
                        key={productGroup._id}
                        sx={{
                            whiteSpace: 'pre-wrap',
                            width: '90%',
                            height: '60px',
                            backgroundColor: productGroup.bgColor,
                            color: productGroup.textColor ? productGroup.textColor : undefined,

                            '& .MuiChip-label': {
                                whiteSpace: 'pre-wrap',
                            },
                        }}
                    ></Chip>
                </Grid>
            )}

            <Grid item xs={flexSize}>
                {!visitguideline && children}
                {visitguideline && (
                    <Grid container>
                        <Grid item maxWidth={'92%'}>
                            {children}
                        </Grid>

                        <Tooltip title={t(visitguideline) || ''}>
                            <InfoRounded color='disabled'></InfoRounded>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>

            {Array.isArray(field.customLabel) && field.customLabel.length > 0 && (
                <Grid item xs={2}>
                    {field.customLabel.map(cl => (
                        <Tooltip title={cl.toolTipText || ''}>
                            <Chip
                                key={cl._id}
                                label={cl.slug ? t(cl.slug) : cl.name}
                                sx={{
                                    backgroundColor: cl.bgColor,
                                    maxWidth: '75px',
                                    maxHeight: '60px',
                                    color: cl.textColor ? cl.textColor : undefined,
                                    '& .MuiChip-label': {
                                        whiteSpace: 'pre-wrap',
                                    },
                                    margin: 0.25,
                                }}
                            ></Chip>
                        </Tooltip>
                    ))}
                </Grid>
            )}
        </Grid>
    );
};

export const CustomReportComponent = ({
    contextObjForUpload,
    reportConfig,
    reportData,
    onChangeHandler,
    taskControl,
    productGroups,
    fieldFilterValue,
    eventId,
}: CustomReportComponentProps) => {
    const groupedColumns = useMemo(() => {
        return groupBy(reportConfig?.customFields, cf => cf?.layout?.cols || 1);
    }, [reportConfig]);

    const reportContext = React.useContext(ReportConfigurationContext);
    // console.log({ groupedColumns });

    const isFieldHiddenByFieldFilter = (field: CustomReportField, productGroup?: IProductGroup) => {
        if (fieldFilterValue?.customLabels.length === 0 && fieldFilterValue?.productGroups.length === 0) {
            return false;
        }

        let isHiddenByProductGroup = false;
        let isHiddenByCustomLabel = false;

        if (fieldFilterValue?.productGroups && fieldFilterValue?.productGroups.length > 0) {
            if (!fieldFilterValue?.productGroups.find(pg => pg._id === productGroup?._id)) {
                isHiddenByProductGroup = true;
            }
        }

        if (
            fieldFilterValue?.customLabels &&
            fieldFilterValue?.customLabels.length > 0 &&
            !field.customLabel?.find(cl => fieldFilterValue?.customLabels.find(cl1 => cl._id === cl1._id))
        ) {
            isHiddenByCustomLabel = true;
        }

        return isHiddenByProductGroup || isHiddenByCustomLabel;
    };

    return (
        <EmpplanTheme>
            <Grid container spacing={2} padding={1}>
                {Object.keys(groupedColumns).map(key => {
                    return (
                        <Grid item key={key} lg={12 / Object.keys(groupedColumns).length} xs={12}>
                            <Grid
                                container
                                spacing={2}
                                padding={1}
                                sx={{ flexDirection: { xs: 'column', md: 'row' }, display: 'block !important' }}
                            >
                                {groupedColumns[key].map(field => {
                                    if (
                                        field?.visibility?.config?.multiplyForEachCondition &&
                                        productGroups &&
                                        productGroups?.length > 0 &&
                                        field?.config.fieldType !== 'Headline' &&
                                        field?.visibility.conditions.productGroups.length > 0
                                    ) {
                                        return (
                                            <>
                                                {productGroups.map(productGroup => {
                                                    if (
                                                        isFieldHidden(
                                                            field,
                                                            reportConfig,
                                                            reportData[productGroup._id] || {},
                                                            {
                                                                productId: reportContext.productId,
                                                                productGroup: productGroup._id,
                                                            },
                                                            reportContext.isFieldHiddenCustomCondition,
                                                        ) ||
                                                        isFieldHiddenByFieldFilter(field, productGroup)
                                                    ) {
                                                        return null;
                                                    }

                                                    return FieldSwitcher(
                                                        field,
                                                        reportData,
                                                        (...val) => {
                                                            const [keyVal, valueVal, isFilter = false, filterData = null] =
                                                                val;
                                                            onChangeHandler(
                                                                keyVal,
                                                                valueVal,
                                                                isFilter,
                                                                filterData,
                                                                productGroup,
                                                            );
                                                        },
                                                        contextObjForUpload,
                                                        taskControl,
                                                        reportContext.isDisabled,
                                                        eventId,
                                                        productGroup,
                                                    );
                                                })}
                                            </>
                                        );
                                    } else {
                                        if (
                                            isFieldHidden(
                                                field,
                                                reportConfig,
                                                reportData,
                                                {
                                                    productId: reportContext.productId,
                                                },
                                                reportContext.isFieldHiddenCustomCondition,
                                            ) ||
                                            isFieldHiddenByFieldFilter(field)
                                        )
                                            return null;

                                        return FieldSwitcher(
                                            field,
                                            reportData,
                                            onChangeHandler,
                                            contextObjForUpload,
                                            taskControl,
                                            reportContext.isDisabled,
                                            eventId,
                                        );
                                    }
                                })}
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </EmpplanTheme>
    );
};

function FieldSwitcher(
    field: CustomReportField,
    reportData: ReportData,
    onChangeHandler: (key: ReportFieldKey, value: ReportFieldValue, isFilterable?: boolean, filterData?: any) => void,
    contextObjForUpload: { contextType?: string | undefined; contextId?: string | undefined } | undefined,
    taskControl: { addTask: (slug: string) => void; removeTask: (slug: string) => void } | undefined,
    isDisabled: Boolean,
    eventId?: string,
    productGroup?: IProductGroup,
) {
    const key = field.config._id + (productGroup?._id || '');
    const _reportData = !productGroup ? reportData : reportData[productGroup._id] || {};
    // console.log({ type: field.config.fieldType });
    switch (field.config.fieldType) {
        case 'stringSmall':
            return (
                <FieldWrapper key={key} productGroup={productGroup} field={field}>
                    <TextFieldComponent
                        field={field}
                        type='text'
                        reportData={_reportData}
                        onChange={(e: any) => {
                            onChangeHandler(field.config._id, e.target.value);
                        }}
                        inputProps={
                            field.config?.fieldObject?.lengthMaximum &&
                            Number(field.config?.fieldObject?.lengthMaximum) > 0
                                ? { maxLength: field.config?.fieldObject?.lengthMaximum }
                                : null
                        }
                    />
                </FieldWrapper>
            );
        case 'number':
            return (
                <FieldWrapper key={key} productGroup={productGroup} field={field}>
                    <TextFieldComponent
                        field={field}
                        type='number'
                        reportData={_reportData}
                        onChange={(e: any) => {
                            onChangeHandler(field.config._id, e.target.value);
                        }}
                    />
                </FieldWrapper>
            );
        case 'stringBig':
            return (
                <FieldWrapper key={key} productGroup={productGroup} field={field}>
                    <TextFieldComponent
                        field={field}
                        type='textarea'
                        reportData={_reportData}
                        onChange={(e: any) => {
                            onChangeHandler(field.config._id, e.target.value);
                        }}
                    />
                </FieldWrapper>
            );
        case 'Checkbox':
            return (
                <FieldWrapper key={key} productGroup={productGroup} field={field}>
                    <CheckBoxComponent field={field} reportData={_reportData} onChangeHandler={onChangeHandler} />
                </FieldWrapper>
            );
        case 'Dropdown':
            return (
                <FieldWrapper key={key} productGroup={productGroup} field={field}>
                    <DropDownComponent field={field} reportData={_reportData} onChangeHandler={onChangeHandler} />
                </FieldWrapper>
            );
        case 'Slider':
            return (
                <FieldWrapper key={key} productGroup={productGroup} field={field}>
                    <SliderComponent field={field} reportData={_reportData} onChangeHandler={onChangeHandler} />
                </FieldWrapper>
            );

        case 'upload':
            return (
                <FieldWrapper key={key} productGroup={productGroup} field={field}>
                    <UploadField
                        contextObjForUpload={contextObjForUpload}
                        field={field}
                        reportData={_reportData}
                        onChange={val => {
                            onChangeHandler(field.config._id, val);
                        }}
                        endpoint='/customreportresult/upload/data'
                        setUploadStatus={(status: boolean) => {
                            if (status) {
                                // set loader since uploading
                                taskControl?.addTask(field.config._id);
                            } else {
                                // remove from loader
                                taskControl?.removeTask(field.config._id);
                            }
                        }}
                    />
                </FieldWrapper>
            );

        case 'Radio':
            return (
                <FieldWrapper key={key} productGroup={productGroup} field={field}>
                    <RadioComponent field={field} reportData={_reportData} onChangeHandler={onChangeHandler} />
                </FieldWrapper>
            );

        // case 'reportOnlyText':
        //     return '';
        // case 'Modelbased': // used by Yamaha only
        //     return <ModelBasedField field={field}productGroup={productGroup}  key={key} />;
        // case 'ManualTimeTracking':
        //     return <div key=productGroup={productGroup} {key}> </div>;

        // this case is used for emergency close event if the location is not available or shop is closed
        case 'CloseEventWithoutChecks':
            return (
                <FieldWrapper key={key} productGroup={productGroup} field={field}>
                    <CloseEventComponent
                        field={field}
                        reportData={_reportData}
                        onChange={(e: any) => {
                            onChangeHandler(field.config._id, e.target.value);
                        }}
                        eventIdObj={contextObjForUpload}
                    />
                </FieldWrapper>
            );
        case 'FreeText':
            return (
                <FieldWrapper key={key} productGroup={productGroup} field={field}>
                    <FreeTextComponent field={field} reportData={_reportData} onChangeHandler={onChangeHandler} />
                </FieldWrapper>
            );
        case 'Time':
            return (
                <FieldWrapper key={key} productGroup={productGroup} field={field}>
                    <TextFieldComponent
                        field={field}
                        type='number'
                        reportData={_reportData}
                        onChange={(e: any) => {
                            onChangeHandler(field.config._id, e.target.value === '' ? null : Number(e.target.value));
                        }}
                        inputProps={{
                            step: '0.05',
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>H</InputAdornment>,
                        }}
                    />
                </FieldWrapper>
            );
        case 'Headline':
            return (
                <FieldWrapper key={key} productGroup={productGroup} field={field}>
                    <Typography
                        data-testid='headline'
                        fontWeight={600}
                        fontSize={16}
                        color={isDisabled ? '#808080' : 'black'}
                    >
                        {field.config.slug ? t(field.config.slug) : field.config.name}
                    </Typography>
                </FieldWrapper>
            );
        case 'Date':
            return (
                <FieldWrapper key={key} productGroup={productGroup} field={field}>
                    <DateComponent field={field} reportData={_reportData} onChangeHandler={onChangeHandler} />
                </FieldWrapper>
            );
        case 'Url':
            return (
                <FieldWrapper key={key} productGroup={productGroup} field={field}>
                    <UrlFieldComponent
                        eventIdObj={contextObjForUpload}
                        field={field}
                        type='url'
                        reportData={_reportData}
                        onChangeHandler={onChangeHandler}
                    />
                </FieldWrapper>
            );
        case 'GooglePlaces':
            return (
                <FieldWrapper key={key} productGroup={productGroup} field={field}>
                    <GplacesFieldComponent
                        field={field}
                        type='text'
                        reportData={_reportData}
                        onChange={onChangeHandler}
                        inputProps={
                            field.config?.fieldObject?.lengthMaximum
                                ? { maxLength: field.config?.fieldObject?.lengthMaximum }
                                : null
                        }
                    />
                </FieldWrapper>
            );
        case 'IDNow':
            return (
                <FieldWrapper key={key} productGroup={productGroup} field={field}>
                    <IDNowFieldComponent
                        field={field}
                        reportData={_reportData}
                        onChange={onChangeHandler}
                        eventId={eventId}
                    />
                </FieldWrapper>
            );
        case 'Signature':
            return (
                <FieldWrapper key={field.config._id} field={field}>
                    <SignatureComponent
                        field={field}
                        reportData={_reportData}
                        contextObjForUpload={contextObjForUpload}
                        onChange={val => {
                            onChangeHandler(field.config._id, val);
                        }}
                        endpoint='/customreportresult/upload/data'
                        setUploadStatus={(status: boolean) => {
                            if (status) {
                                // set loader since uploading
                                taskControl?.addTask(field.config._id);
                            } else {
                                // remove from loader
                                taskControl?.removeTask(field.config._id);
                            }
                        }}
                    />
                </FieldWrapper>
            );
        default:
            return null;
    }
}
