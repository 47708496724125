import newEventActionTypes from './actions';

// INITIAL STATE
const initialState: EventStates = {
    loadedEvents: [],
    loadedEventsCount: 0,
    loadedEvent: undefined,
    locationContact: {},
    editLocationContact: null,
    loadingLocationContact: false,
    syncData: {
        state: 'READY',
        lastUpdate: null,
    },
    promoterLocationContactChannel: {},
    myEventsToday: [],
};

// REDUCER

const eventReducer = (state = { ...initialState }, action: any) => {
    if (action.type === newEventActionTypes.LOAD_EVENT_LIST) {
        return {
            ...state,
            loadedEvents: action.payload.events,
            loadedEventsCount: action.payload.count,
        };
    }
    if (action.type === newEventActionTypes.LOAD_EVENT) {
        return { ...state, loadedEvent: action.payload };
    }
    if (action.type === newEventActionTypes.UPDATE_EVENT_SYNC_DATA) {
        return { ...state, syncData: action.payload };
    }
    if (action.type === newEventActionTypes.CLEAR_EVENT_LIST) {
        return { ...state, loadedEvents: [], loadedEventsCount: 0 };
    }
    if (action.type === newEventActionTypes.CLEAR_LOAD_EVENT) {
        return { ...state, loadedEvent: undefined };
    }
    if (action.type === newEventActionTypes.LOAD_LOCATION_CONTACT) {
        return { ...state, locationContact: action.payload, loadingLocationContact: false };
    }
    if (action.type === newEventActionTypes.CLEAR_LOCATION_CONTACT) {
        return { ...state, locationContact: {} };
    }
    if (action.type === newEventActionTypes.MY_EVENTS_TODAY) {
        return { ...state, myEventsToday: action.payload };
    }
    if (action.type === newEventActionTypes.LOADING_LOCATION_CONTACT) {
        return { ...state, loadingLocationContact: true };
    }
    if (action.type === newEventActionTypes.ADD_PROMOTER_LOCATION_CONTACT_CHANNEL) {
        return { ...state, promoterLocationContactChannel: action.payload };
    }
    if (action.type === newEventActionTypes.EDIT_LOCATION_CONTACT) {
        return { ...state, editLocationContact: action.payload };
    }

    return state;
};

export default eventReducer;
