import { chipFieldTypes, numberFieldTypes, productFieldTypes, productGroupFieldTypes, selectFieldTypes, stringFieldTypes, uploadFieldTypes } from './constants';

export const getValidOperatorsForFieldType: (
    fieldType: string,
) => Array<{ operator: string; slug: string ;mongooseOperator: string }> = fieldType => {
    if (numberFieldTypes.includes(fieldType))
        return [
            { slug: '##OperatorEquals', operator: '=', mongooseOperator: '$eq' },
            { slug: '##OperatorNotEquals', operator: '!=', mongooseOperator: '$ne'  },
            { slug: '##OperatorGreaterThan', operator: '>', mongooseOperator: '$gt' },
            { slug: '##OperatorGreaterThanEqual', operator: '>=', mongooseOperator: '$gte' },
            { slug: '##OperatorLessThan', operator: '<', mongooseOperator: '$lt'  },
            { slug: '##OperatorLessThanEqual', operator: '<=', mongooseOperator: '$lte' },
            { slug: '##IsEmpty', operator: '?', mongooseOperator: '$em' },//Invalid Mongoose operator
            { slug: '##IsNotEmpty', operator: '!?', mongooseOperator: '$nem' },//Invalid Mongoose operator
          
        ];
    if ([...stringFieldTypes, ...selectFieldTypes].includes(fieldType))
        return [
        { slug: '##OperatorEquals', operator: '=', mongooseOperator: '$eq' },
        { slug: '##OperatorNotEquals', operator: '!=', mongooseOperator: '$ne' },
        { slug: '##IsEmpty', operator: '?', mongooseOperator: '$em' },//Invalid Mongoose operator
        { slug: '##IsNotEmpty', operator: '!?', mongooseOperator: '$nem' },//Invalid Mongoose operator
    ];

    if(uploadFieldTypes.includes(fieldType)){
        return [          
            { slug: '##IsEmpty', operator: '?', mongooseOperator: '$em' },//Invalid Mongoose operator
            { slug: '##IsNotEmpty', operator: '!?', mongooseOperator: '$nem' },//Invalid Mongoose operator
        ];
    }
    if ([...productFieldTypes, ...chipFieldTypes, ...productGroupFieldTypes].includes(fieldType))
        return [
        { slug: '##OperatorIN', operator: 'in', mongooseOperator: '$in' } , 
        { slug: '##OperatorNotIN', operator: 'not in', mongooseOperator: '$nin' },
        { slug: '##IsEmpty', operator: '?', mongooseOperator: '$em' },//Invalid Mongoose operator
        { slug: '##IsNotEmpty', operator: '!?', mongooseOperator: '$nem' },//Invalid Mongoose operator
    ];
    return [];
};
