import {User, UserActionTypes} from "../UserStates/UserStatesTypes";

export enum CustomReportActionTypes {
    SAVE= "CUSTOMREPORT/SAVE",
    LOADRESULT= "CUSTOMREPORT/LOAD/RESULT",
    CUSTOMREPORTLOADING = "CUSTOMREPORT/LOADING",
    CUSTOMREPORTLOADINGCOPY = "CUSTOMREPORT/LOADING/COPY",
    CUSTOMREPORTLOADINGIDADD = "CUSTOMREPORT/LOADING/ID/ADD",
    CUSTOMREPORTLOADINGIDREMOVE = "CUSTOMREPORT/LOADING/ID/REMOVE",
    CLEARCUSTOMREPORTSTATE = "CUSTOMREPORT/CLEAR/STATE",
    CUSTOMREPORTADDACTION = "CUSTOMREPORT/ADD/RESULT",
    CUSTOMREPORTTEMPSAVE = "CUSTOMREPORT/TEMPORARY/CHANGE/SAVE"
};


export type CustomReportResultsType ={
    name: string,
    value:string|null|any[]
}

/**
 * this is only an argument type!
 */
export type SaveCustomReportResults = {
    reportResults:{
        [key: string]: {
            name: string,
            value:string|null
        }
    },
    _customReport:{
        _refconfig:string, //config id for customReport
        _createdTimeConfig: {
            name:string,
            type:string,
            __v:Number,
            _id:string,
        }
    },
    _event:string, //event ID
    _project: string, //project id
    _id?:string, //optional can be not presend, needs only to be present when updating an custom report result!
}

export type GenerateCustomReportPutObject = {
    reportResults: {
        [key: string]:  string|null
    },
    _customReport: {
        _refconfig:string,
        _createdTimeConfig: {
            name:string,
            type:string,
            __v?:Number,
            _id:string,
        }
    } //the same customreport we done need this information here?
    _event:any,
    _project:string
}

export type SavedCustomReportResultReponse = {
    created: Date,
    reportResults: {
        [key: string]: {
            name: string,
            value:string|null
        }
    },
    _id:string,
    _customReport: {
        _refconfig:string,
        _createdTimeConfig: {
            name:string,
            type:string,
            __v:Number,
            _id:string,
        }
    } //the same customreport we done need this information here?
    _event:any,
    _project:string
}

export type CustomReportResultResponse = {
    created: Date,
    report: {
        reportResults: {
            [key: string]: {
                name: string,
                value:string|null
            }
        },
        _id:string,
        _customReport: {
            _refconfig:string,
            _createdTimeConfig: {
                name:string,
                type:string,
                __v:Number,
                _id:string,
            }
        } //the same customreport we done need this information here?
        _event:any,
        _project:string
    },
    saved?:boolean

}

export interface CustomReportSaveAction {
    type: CustomReportActionTypes.SAVE,
    reportResult: CustomReportResultResponse
}

export interface CustomReportAddReportResultAction {
    type:CustomReportActionTypes.CUSTOMREPORTADDACTION,
    reportResult: CustomReportResultResponse
}

export interface CustomReportLoadAction {
    type: CustomReportActionTypes.LOADRESULT
    reportResult: CustomReportResultResponse|null
}

export interface CustomReportLoadingActionState {
    type: CustomReportActionTypes.CUSTOMREPORTLOADING,
    loading: boolean
}

export interface AddCustomReportLoadingId {
    type: CustomReportActionTypes.CUSTOMREPORTLOADINGIDADD,
    loading:boolean,
    loadingId:string,
}

export interface RemoveCustomReportLoadingId {
    type: CustomReportActionTypes.CUSTOMREPORTLOADINGIDREMOVE
    loading:boolean,
    loadingId:string,
}

/**
 * the intention for this action is, to store the temporary changes inside
 * an users form!
 * The save action will be handled by the controller itself!
 * After the controller save action is done, we will load the form again via the controller
 */
export interface TemporaryChangeCustomReportResultAction {
    type:CustomReportActionTypes.CUSTOMREPORTTEMPSAVE,
    reportResult: CustomReportResultResponse
}

export interface ClearCustomReportState {
    type:CustomReportActionTypes.CLEARCUSTOMREPORTSTATE,
}

/**
 * MAYBE CUSTOM REPORTS DONT NEED SUCH A COMPLICATED STORE AND UPDATE STUFF? ;_)
 */
export interface CustomReportStateTypeContainer {
    readonly customReportResults:CustomReportResultResponse[]|null;
    readonly customReportLoadingIds:string[],
    //readonly customReportsSaved
    readonly loading: boolean;
}


export type  CustomReportActions=
    | CustomReportSaveAction
    | CustomReportLoadAction
    | AddCustomReportLoadingId
    | ClearCustomReportState
    | CustomReportAddReportResultAction
    | TemporaryChangeCustomReportResultAction
    | CustomReportLoadingActionState;





