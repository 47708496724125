import { Box, CircularProgress, Typography } from '@mui/material';
import { CheckCircleSharp } from '@mui/icons-material';
import { CustomReportComponent } from '../../../reactelements/src/modules/CustomReport/customReportComponent';
import customReportValidator from '../../../reactelements/src/modules/CustomReport/utils/validator';
import {
    formatReportResultsToSave,
    parseSavedReportResults,
} from '../../../reactelements/src/modules/CustomReport/utils/reportHelpers';
import * as React from 'react';
import { ReportConfigurationContext, getFilteredProductGroupIds } from '../../../reactelements/src/modules/CustomReport';
import { useDispatch } from 'react-redux';
import { updateNewCustomReportResultAction } from '../../Models/NewCustomReportResult/actions';
import { useEffect } from 'react';
import { useTranslation } from 'reactelements/node_modules/react-i18next';
import { PWASyncControlContext } from '../../Services/pwaSyncControlContext';
import { IProductGroup } from '../../../reactelements/src/utils/ProductGroup';
import useFieldFilter from '../../../reactelements/src/modules/CustomReport/FieldFilter';

interface CustomReportParserReactProps {
    customReport: CustomReportConfig;
    reportResults: DexieCustomReportResult;
    eventAndProject: {
        eventId: string;
        projectId: string;
    };
    validationCallback?: (hasErrors: boolean) => void;
    productGroupsInfo?: {
        locationProductGroups?: Array<IProductGroup>;
        eventGroupProductGroups?: Array<IProductGroup>;
        userProductGroups?: Array<string>;
    };
    updateReportState?: (payload: { [key: string]: string }) => void;
}

interface CustomReportFormState {
    current: 'none' | 'ready' | 'changed' | 'submitting' | 'error';
    tasks: Array<string>;
    error?: any;
}

const FormStateVisualGuide = ({ formState }: { formState: CustomReportFormState }) => {
    const { t } = useTranslation();
    const success = formState.current === 'ready';
    return (
        <Box display='flex' alignItems='center'>
            <Box sx={{ mx: 1, mt: 0.5 }}>
                {success && <CheckCircleSharp color='success' />}
                {!success && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: 'green',
                            top: -6,
                            left: -6,
                            zIndex: 1,
                        }}
                    />
                )}
            </Box>
            <Typography fontSize={'small'} variant={'caption'}>
                {formState.current === 'ready' ? t('##CustomReportReady') : null}
                {formState.current === 'changed' ? t('##CustomReportChanged') : null}
                {formState.current === 'submitting' ? t('##CustomReportSubmitting') : null}
                {formState.current === 'error' ? t('##CustomReportError') : null}
            </Typography>
        </Box>
    );
};

const CustomReportParserPWA = ({
    customReport,
    reportResults,
    eventAndProject,
    validationCallback,
    productGroupsInfo,
    updateReportState,
}: CustomReportParserReactProps) => {
    const { t } = useTranslation();
    const { triggerAutoSync, isSyncing } = React.useContext(PWASyncControlContext);

    const reportResultRedux = reportResults;

    const [apiCustomReportData, setApiCustomReportData] = React.useState<CustomReport>({} as CustomReport);
    const [reportData, setReportData] = React.useState<ReportData>({});
    const [customReportResultId, setCustomReportResultId] = React.useState<string>('');
    const [customReportFormState, setCustomReportFormState] = React.useState<CustomReportFormState>({
        current: 'none',
        tasks: [],
    });

    const dispatch: any = useDispatch();
    const customReportConfig = apiCustomReportData?._createdTimeConfig;
    const reportConfigContext = React.useContext(ReportConfigurationContext);
    const contextObjForUpload = {
        contextType: 'events',
        contextId: eventAndProject.eventId,
    };

    const onChangeHandler = (key: ReportFieldKey, value: ReportFieldValue) => {
        setReportData(s => ({ ...s, [key]: value }));
        setCustomReportFormState(s => ({ ...s, current: 'changed' }));
    };

    const taskControl = {
        addTask: (slug: string) => {
            setCustomReportFormState(s => ({ ...s, tasks: [...s.tasks, slug] }));
        },
        removeTask: (slug: string) => {
            setCustomReportFormState(s => ({ ...s, tasks: s.tasks.filter(k => k !== slug) }));
        },
    };

    /** this will generate payload for report results to save **/
    const generateSavePayload = (
        reportData: any,
        customReportPayload: {
            _createdTimeConfig: CustomReportConfig;
            _refconfig: string;
        },
    ) => {
        return {
            reportResults: formatReportResultsToSave(reportData, customReportConfig),
            _customReport: customReportPayload,
            _event: eventAndProject.eventId,
            _project: eventAndProject.projectId,
        };
    };

    useEffect(() => {
        if (reportResultRedux) {
            setApiCustomReportData &&
                setApiCustomReportData(reportResultRedux?.customReportResult?._customReport as CustomReport);
            setCustomReportResultId && setCustomReportResultId(reportResultRedux?._id as string);
            setReportData(parseSavedReportResults(reportResultRedux?.customReportResult?.reportResults));
            setCustomReportFormState({
                current: 'ready',
                tasks: [],
            });
        }
    }, [reportResultRedux]);

    /** update the dexie dispatch **/
    const saveCustomFormAPI = (reportData: ReportData, customReportObj: CustomReport) => {
        return new Promise((resolve, reject) => {
            const payload = {
                ...generateSavePayload(reportData, customReportObj),
                _id: customReportResultId,
            };
            dispatch(updateNewCustomReportResultAction(payload));
            return resolve({ done: true });
        });
    };

    /** validates the form and update data to dexie **/
    React.useEffect(() => {
        const submitReport = setTimeout(async () => {
            /** validate **/
            if (customReportConfig?.customFields && reportData) {
                /** run the validator to update the errors **/
                const { hasErrors } = customReportValidator(customReportConfig, reportData, {
                    productId: reportConfigContext?.productId,
                });
                /** call this function to disable the submit form button if it has errors on the parent file **/
                validationCallback && validationCallback(hasErrors);
            }
            /** autosaving **/
            if (customReportFormState.current === 'changed' && customReportFormState.tasks.length === 0 && !isSyncing) {
                setCustomReportFormState(s => ({ ...s, current: 'submitting' }));
                saveCustomFormAPI(reportData, apiCustomReportData).then(() => {
                    setCustomReportFormState && setCustomReportFormState({ ...customReportFormState, current: 'ready' });
                });
                triggerAutoSync();
            }
        }, 1000);

        return () => clearTimeout(submitReport);
    }, [customReportFormState, isSyncing]);

    /** prevents the page from closing till the upload and save is completed **/
    React.useEffect(() => {
        updateReportState && updateReportState({ [customReport?._id || 'tmp']: customReportFormState?.current || '' });
        const beforeUnloadListener = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            return (event.returnValue = t('##DoYouWantToProceedNotSavedDataWillGetLost'));
        };

        if (customReportFormState.tasks.length > 0 || ['changed', 'submitting'].includes(customReportFormState.current)) {
            window.addEventListener('beforeunload', beforeUnloadListener, { capture: true });
        } else {
            window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
        }

        return () => {
            window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
        };
    }, [customReportFormState]);

    const filteredPgs = getFilteredProductGroupIds(
        customReport,
        productGroupsInfo?.eventGroupProductGroups?.map(pg => pg._id),
        productGroupsInfo?.userProductGroups,
        productGroupsInfo?.locationProductGroups?.map(pg => pg._id),
        false,
    );

    const customLabels: Array<CustomLabel> = customReportConfig
        ? customReportConfig?.customFields.reduce((ov, nv) => {
            if (nv.customLabel) {
                return [...ov, ...nv.customLabel];
            } else {
                return [...ov];
            }
          }, [] as Array<CustomLabel>)
        : [];

    const uniqCustomLabelsIds = Array.from(new Set(customLabels.map(cl => cl._id)));

    const productGroups = customReport?.customFields?.find(cf =>
        cf.visibility?.conditions?.productGroups?.some(pg => filteredPgs?.includes(pg?._id)),
    )
        ? productGroupsInfo?.eventGroupProductGroups?.filter(pg => filteredPgs?.includes(pg._id))
        : [];
    const [FieldFilter, fieldFilterValue] = useFieldFilter(
        productGroups || [],
        uniqCustomLabelsIds.map(id => customLabels.find(cl => cl._id === id)) as Array<CustomLabel>,
    );

    return (
        <div>
            {customReportFormState.current === 'none' ? null : (
                <div>
                    {
                        <Box px={2}>
                            <Box display={'flex'} alignItems='center'>
                                <Typography
                                    fontWeight={600}
                                    fontSize={20}
                                    color={reportConfigContext.isDisabled ? '#808080' : 'black'}
                                >
                                    {customReportConfig?.name}{' '}
                                </Typography>
                                {!reportConfigContext.isDisabled && (
                                    <FormStateVisualGuide formState={customReportFormState} />
                                )}
                            </Box>
                            <FieldFilter key={'12212'} />
                        </Box>
                    }

                    {reportResultRedux && customReportConfig?.customFields ? (
                        <CustomReportComponent
                            contextObjForUpload={contextObjForUpload}
                            reportConfig={customReportConfig}
                            reportData={reportData}
                            onChangeHandler={onChangeHandler}
                            taskControl={taskControl}
                            productGroups={productGroups}
                            fieldFilterValue={fieldFilterValue}
                        />
                    ) : customReportFormState.current === 'error' ? (
                        <div>Error: {customReportFormState.error}</div>
                    ) : (
                        <></>
                    )}
                </div>
            )}
        </div>
    );
};

export default CustomReportParserPWA;
