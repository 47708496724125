import * as React from 'react';

import { useTranslation } from 'reactelements/node_modules/react-i18next';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { initializeDeviceSyncHash } from './pwaSyncControlHashHelpers';
import { handlePWALogout } from '../Components/LeftMenu/DrawerMenu';
import { formatDateTime } from 'reactelements/src/utils/date';

interface DeviceHashInfoPromptDialogProps {
    deviceHashInfo: DeviceHashInfoState;
    setDeviceHashInfo: React.Dispatch<React.SetStateAction<DeviceHashInfoState>>;
}

export const DeviceHashInfoPromptDialog = ({ deviceHashInfo, setDeviceHashInfo }: DeviceHashInfoPromptDialogProps) => {
    const { t } = useTranslation();

    const allowRegister = () => {
        if (deviceHashInfo.deviceHash && deviceHashInfo.serverHash) {
            if (Number(deviceHashInfo.deviceHash) === 0 || Number(deviceHashInfo.serverHash) === 0) {
                return true;
            }
            if (Number(deviceHashInfo.deviceHash) < Number(deviceHashInfo.serverHash)) {
                return false;
            }
        }
        return true;
    };

    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableScrollLock
            fullWidth
            aria-labelledby='sync-hash-prompt-title'
            aria-describedby='sync-hash-prompt-description'
        >
            <DialogTitle id='sync-hash-prompt-title'>{t('##SyncHashPromptTitle')}</DialogTitle>

            <DialogContent>
                {deviceHashInfo.status === 'PromptNew' && (
                    <DialogContentText id='PromptNew-description'>{t`##PWANewDeviceShouldRegister`}</DialogContentText>
                )}
                {deviceHashInfo.status === 'PromptExisting' && (
                    <DialogContentText id='PromptExisting-description'>
                        {allowRegister() ? (
                            <>
                                {t`##PWAOlderDeviceExistingOverride`}
                                <br />
                                <br />
                                {t`##PWALastDeviceRegisterTimestamp`}{' '}
                                {formatDateTime(new Date(Number(deviceHashInfo.serverHash)))}
                            </>
                        ) : (
                            t`##PWANewerDeviceAlreadyRegistered`
                        )}
                    </DialogContentText>
                )}
                {deviceHashInfo.status === 'Error' && (
                    <DialogContentText id='PromptExisting-description'>{t`##PWAErrorVerifySyncHash`}</DialogContentText>
                )}
            </DialogContent>

            <DialogActions>
                <Box display='flex' flexDirection='column' textAlign='right' gap={2} p={2}>
                    {allowRegister() && deviceHashInfo.status !== 'Error' && (
                        <Button
                            key='sync-hash-prompt-register-button'
                            color='secondary'
                            variant='contained'
                            onClick={() => {
                                initializeDeviceSyncHash().then(hash => {
                                    if (hash) {
                                        setDeviceHashInfo({ ...deviceHashInfo, status: 'Checked' });
                                    } else {
                                        setDeviceHashInfo({ ...deviceHashInfo, status: 'Error' });
                                    }
                                });
                            }}
                        >
                            {t('##Register&Continue')}
                        </Button>
                    )}

                    {deviceHashInfo.status !== 'Error' && (
                        <Button
                            color='error'
                            variant='contained'
                            key='sync-hash-prompt-close-button'
                            onClick={() => {
                                handlePWALogout();
                            }}
                        >
                            {t`##Cancel`} {t`##And`} {t`##Logout`}
                        </Button>
                    )}

                    {deviceHashInfo.status === 'Error' && (
                        <Button
                            color='info'
                            variant='contained'
                            key='sync-hash-prompt-reload-button'
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            {t('##Refresh')}
                        </Button>
                    )}
                </Box>
            </DialogActions>
        </Dialog>
    );
};
