export const requireHelper: (field: CustomReportField, reportData: ReportData) => boolean = (field, reportData) => {
    
    let requiredCondition: boolean = field.required;
    if (field.required && !field.requiredByCondition) {
        requiredCondition = true;
    } else if (field.required && field.requiredByCondition && field.requiredByFieldId === null) {
        requiredCondition = true;
    } else if (
        field.required &&
        field.requiredByCondition &&
        field.requiredByFieldId !== null &&
        field?.requiredByFieldValue
    ) {
        if (Array.isArray(reportData[field.requiredByFieldId])) {
            requiredCondition = reportData[field.requiredByFieldId].includes(field?.requiredByFieldValue);
        } else {
            requiredCondition = reportData[field.requiredByFieldId] === field?.requiredByFieldValue;
        }
    }
   
    return requiredCondition;
};
