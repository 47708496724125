import React from 'react'

export default function ColorView({value}:{value:string}) {
  return (
    <div style={{display:"flex", gap:'0.5em', alignItems:'center',width:'100%',
        height:'100%',}}>
            
            <div style={{ width: "20px", height: "20px", backgroundColor:value }}></div>
            <span>{value}  </span>
        </div>
  )
}
