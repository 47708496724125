import {Reducer} from "redux";
import {UserTodoAction, UserTodoActionTypes, UserTodoStates} from "./UserTodoTypes";
import {SmallEvent} from "../Events/SmallEventType";

export const initialEventContainerState:UserTodoStates = {
    loading:false,
    todos:null,
};



export const UserTodoReducer: Reducer<UserTodoStates, UserTodoAction> = (
    state = initialEventContainerState,
    action
) => {
    switch (action.type) {
        //TODO CHANGE THE STATE
        case UserTodoActionTypes.GETMYTODOS: {
            return {
                ...state,
                todos:action.todos,
                loading:false
            };
        }
        case UserTodoActionTypes.LOADING: {
            return {
                ...state,
                loading:action.loading
            };
        }
    }
    return state;
}
