import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal, Paper, Portal, Typography } from '@mui/material';
import React, { ReactNode, useState } from 'react'

export default function useModal(onClose?: () => {}) {
  const [open, setOpen] = useState(false);
  const [children, setChildren] = useState<ReactNode>(null)
  const [title, setTitle] = useState<ReactNode>("")

  const style = {
    position: 'absolute' as 'absolute',
    zIndex:99
    
    


  };

  const comp = () => <Portal>
    <Modal open={open} sx={style}>
      <>
        <Box display={"flex"} justifyContent={"space-between"} bgcolor={"rgb(66,66,66)"} color={"whitesmoke"} p={2}>
          <Typography variant='h5'>
            {title}
          </Typography>
          <IconButton onClick={() => closeModal()} sx={{color:"whitesmoke"}}>
            <Close ></Close>
          </IconButton>
        </Box>
        <Paper sx={{ p: 3 }}>

          {children}
        </Paper>
      </>

    </Modal>
  </Portal>;

  const openModal = (children: ReactNode, title = "") => {
    setOpen(true);
    setTitle(title)
    setChildren(children);

  };

  const closeModal = () => {
    setOpen(false);
    onClose?.();
  };

  return [comp, openModal, closeModal] as const



}
