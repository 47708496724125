import {DivCenteredContent, EquallySpacedRow} from "../styled";
import {Link as Link_} from "@mui/icons-material";
import {Link, Typography} from "@mui/material";
import React from "react";

export interface IExternalLinkProps {
  value: string;
}
export function ExternalLink({value}: IExternalLinkProps) {
  return value ? <DivCenteredContent>
    {/*    email icon*/}
      <Link_/>
      <Link target={'_blank'}  href={value}>
          <Typography >{value}</Typography>
      </Link>
  </DivCenteredContent> : null
}